import { Route, Routes } from "react-router-dom";

import { List} from "../pages";

import { ProjectsLayout } from "../layouts/ProjectsLayout";

export const ProjectsRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<ProjectsLayout />}>
        <Route index element={<List />} />
       </Route>
    </Routes>
  );
};
