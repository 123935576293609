

import { SupportDomainLayout } from "../layouts";
import { SupportRouter } from "../subdomains/support/routes/SupportRouter";
import { supportDomainRoutes } from "./support.base.route";
import { Route, Routes } from "react-router-dom";


export const SupportDomainRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<SupportDomainLayout />}>
        <Route index element={<SupportRouter />} />
        <Route path="*" element={<SupportRouter />} />
      </Route>
    </Routes>
  );
};