/* eslint-disable react-hooks/exhaustive-deps */
import {
    Stack,
    Box,
    Tooltip,
    IconButton,
  } from "@mui/material";
  import {
    Add,
    AssignmentReturnOutlined,
    DeleteOutlineRounded,
    FileUpload,
  } from "@mui/icons-material";
  import { memo, useEffect, useState } from "react";
  import {
    ILeadListInput,
    LeadBusinessType,
    LeadQualification,
    LeadType,
  } from "corede-common-cocrm";
  import { useNavigate, useParams } from "react-router-dom";
  import { enqueueSnackbar } from "notistack";
  import { IGraphqlVariables, Language, unknownError } from "corede-common";
  import { getCurrentLanguage } from "localization";
  import { useTranslation } from "react-i18next";
  import EmptyState from "components/emptyState/EmptyState";
  import { BaseDataGrid } from "components/baseDataGrid/BaseDataGrid";
  import { BaseGridColType } from "components/baseDataGrid/infra/enums";
  import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { useLazyLeadListQuery, useLeadListQuery, useLeadUpdateMutation } from "apps/crm/domains/03-lead/subdomains/lead";
import { importLeadRoute } from "apps/crm/domains/03-lead/routes/lead.base.route";
import { useCustomerEstimateListQuery } from "../context/customers.api";

export interface ICustomerListView {
  // lead
  customerStatusData?: any | undefined;
  customerData?: any | undefined;
  selectedDetail?: any | undefined;
  customerStatusesLoading?: boolean;
  customerSourcesLoading?: boolean;
  setOpenLeadCreateDrawer?: (value: boolean) => void | undefined;
  customerStatusCreateOpen?: boolean;
  setDetailStatusCreateOpen?: (value: boolean) => void;
  customerSourceCreateOpen?: boolean;
  setDetailSourceCreateOpen?: (value: boolean) => void;

  // departments
  roleListData?: any | undefined;
  rolesPopupId?: any;
  openRolesPopup?: any;
  setOpenRolesPopup?: (value: boolean) => void;
  anchorElRolesPopup?: any;
  onCloseRolesPopup?: any;
  handleClickRolesPopup?: any; // (event: React.MouseEvent<HTMLButtonElement>) => void
  setAnchorElRolesPopup?: any;

  //customer
  customerCategoryListData?: any | undefined;

  // common
  setSelectedRow?: (value: any) => void;
  setOpenDelete?: (value: boolean) => void;
  setOpenUpdate?: (value: boolean) => void;
  setOpenDetail?: (value: boolean) => void;
}
  
  const CustomerListView = memo((props: ICustomerListView) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const currentLanguage = getCurrentLanguage();
    const [leadListFilter, setLeadListFilter] = useState<
      IGraphqlVariables<ILeadListInput>
    >({
      input: {
        filter: {
          email: undefined,
          organizationId: undefined,
          role: undefined,
          statusIds: undefined,
          createdById: undefined,
          types: undefined,
          qualifications: undefined,
          businessTypes: [LeadBusinessType.b2b],
        },
        pagination: { page: 1, pageSize: 10 },
      },
    });
    const [businessType, setBusinessType] = useState<LeadBusinessType>(
      LeadBusinessType.b2b
    );
    const { id } = useParams();
  
    const {
      data: customerListData,
      isLoading: customerListLoading,
      isFetching: customerListFetch,
      error: customerListError,
      refetch,
    } = useCustomerEstimateListQuery({
      input: {
        filter: {
          customerId: id || ""
        }
      }
    });
    const [
      leadListLazy,
      {
        data: leadListLazyData,
        isLoading: leadListLazyLoading,
        isFetching: leadListLazyFetching,
        error: leadListLazyError,
      },
    ] = useLazyLeadListQuery();
  
    const [leadUpdate, { isLoading: leadUpdateLoading, error: leadUpdateError }] =
      useLeadUpdateMutation();
  
    useEffect(() => {
      // leadListLazy(leadListFilter);
      const filter = {
        ...leadListFilter,
        input: {
          ...leadListFilter?.input,
          filter: {
            businessTypes: businessType ? [businessType] : undefined,
          },
        },
      };
      leadListLazy(filter);
    }, [leadListFilter, businessType]);
  
    useEffect(() => {
      if (customerListError) {
        enqueueSnackbar(
          (customerListError as any)?.error?.message[currentLanguage] ??
            unknownError.message[currentLanguage],
          { variant: "error" }
        );
      }
    }, [customerListError]);
  
    useEffect(() => {
      if (leadListLazyError) {
        enqueueSnackbar(
          (leadListLazyError as any)?.error?.message[currentLanguage] ??
            unknownError.message[currentLanguage],
          { variant: "error" }
        );
      }
    }, [leadListLazyError]);
  
    useEffect(() => {
      if (leadUpdateError) {
        enqueueSnackbar(
          (leadUpdateError as any)?.error?.message[currentLanguage] ??
            unknownError.message[currentLanguage],
          { variant: "error" }
        );
      }
    }, [leadUpdateError]);
  
    return (
      <Stack
        direction="column"
        justifyContent="space-between"
        mt={2}
        height={{ xs: "700px", md: "calc(100% - 200px)" }}
        sx={{ overflow: "auto" }}
      >
        {props.customerData?.count !== 0 ||
        (props.customerData?.count === 0 && leadListFilter) ? (
          <BaseDataGrid
            rows={
              customerListData?.data?.map((customer, index) => ({
                    id:
                      (index + 1) * (leadListFilter.input?.pagination?.page || 0),
                    _id: customer._id,
                    prospectName: customer.prospectName || "-",
                    prospectAddress: customer.prospectCity + " " + customer.prospectCountry || "-",
                    prospectContactName: customer.prospectContactFirstName + " " + customer.prospectContactLastName || "-",
                    date: customer.date || "-",
                    expireDate: customer.expireDate || "-",
                    totalPrice: customer.totalPrice.total || "-",
                    status: customer.status || "-",
                    prospectType: customer.prospectType || null,
                    currency: customer.currency || "-",
                    country: customer.status || "-",
                    state: customer.status || "-",
                    city: customer.status || "-",
                    language: customer.language || "-",
                    leadValue: customer.status || "-",
                    organization: customer.organization._id || "-",
                    department: customer.department || "-",
                  })) || []
            }
            columns={[
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: "id",
                    disableColumnMenu: true,
                    filterable: false,
                    width: 40,
                  },
                  headerConfig: {
                    name: "#",
                  },
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: "prospectName",
                    editable: true,
                  },
                  headerConfig: {
                    name: t("crm.customer.customers.prospectName"),
                  },
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: "prospectAddress",
                    editable: true,
                  },
                  headerConfig: {
                    name: t("crm.customer.customers.prospectAddress"),
                  },
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: "prospectContactName",
                    editable: true,
                  },
                  headerConfig: {
                    name: t("crm.customer.customers.prospectContactName"),
                  },
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: "date",
                    filterable: false,
                    editable: true,
                  },
                  headerConfig: {
                    name: t("crm.customer.customers.date"),
                  },
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: "expireDate",
                    editable: true,
                  },
                  headerConfig: {
                    name: t("crm.customer.customers.expireDate"),
                  },
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: "totalPrice",
                    editable: true,
                  },
                  headerConfig: {
                    name: t("crm.customer.customers.totalPrice"),
                  },
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: "status",
                    width: 160,
                    filterable: false,
                    editable: true,
                  },
                  headerConfig: {
                    name: t("crm.customer.customers.status"),
                  },
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: "prospectType",
                    editable: true,
                  },
                  headerConfig: {
                    name: t("crm.customer.customers.prospectType"),
                  },
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: "sector",
                    editable: true,
                  },
                  headerConfig: {
                    name: t("crm.customer.customers.sector"),
                  },
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.enum,
                  column: {
                    field: "type",
                    editable: true,
                  },
                  headerConfig: {
                    name: t("crm.customer.customers.type"),
                  },
                  filterConfig: {
                    customFieldName: `types`,
                    isArrayFilter: true,
                  },
                },
                data: LeadType,
                //   changed: ["valueOptions", "cell", "editCell"],
              },
              {
                config: {
                  baseGridColType: BaseGridColType.enum,
                  column: {
                    field: "currency",
                    editable: true,
                  },
                  headerConfig: {
                    name: t("crm.customer.customers.currency"),
                  },
                  filterConfig: {
                    customFieldName: `currency`,
                    isArrayFilter: true,
                  },
                },
                data: LeadQualification,
                //   changed: ["valueOptions", "cell", "editCell"],
              },
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: "country",
                    editable: true,
                  },
                  headerConfig: {
                    name: t("crm.customer.customers.country"),
                  },
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: "state",
                    editable: true,
                  },
                  headerConfig: {
                    name: t("crm.customer.customers.state"),
                  },
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: "city",
                    editable: true,
                  },
                  headerConfig: {
                    name: t("crm.customer.customers.city"),
                  },
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.enum,
                  column: {
                    field: "language",
                    width: 100,
                    editable: true,
                  },
                  headerConfig: {
                    name: t("crm.customer.customers.language"),
                  },
                  filterConfig: {
                    customFieldName: `languages`,
                    isArrayFilter: true,
                  },
                },
                data: Language,
                //   changed: ["valueOptions", "cell"],
              },
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: "leadValue",
                    width: 150,
                    filterable: false,
                    editable: true,
                  },
                  headerConfig: {
                    name: t("crm.customer.customers.leadValue"),
                  },
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: "department",
                    width: 150,
                    editable: false,
                  },
                  headerConfig: {
                    name: t("crm.customer.customers.department"),
                  },
                  filterConfig: {
                    customFieldName: `departmentIds`,
                    isArrayFilter: true,
                  },
                },
              },
            ]}
            actionColumn={{
              width: 150,
              defaultActions: {
                view: {
                  clickConfig: {
                    setOpenAction: props.setOpenDetail,
                  },
                },
                edit: {
                  clickConfig: {
                    setOpenAction: props.setOpenUpdate,
                  },
                },
                delete: {
                  clickConfig: {
                    setOpenAction: props.setOpenDelete,
                  },
                },
              },
              customCellItems: [],
            }}
            loading={
              customerListLoading ||
              props.customerStatusesLoading ||
              leadUpdateLoading ||
              props.customerSourcesLoading ||
              customerListFetch ||
              leadListLazyLoading ||
              leadListLazyFetching
            }
            listFilter={{
              filterInput: leadListFilter,
              setFilterInput: setLeadListFilter,
            }}
            update={{
              updateQuery: leadUpdate,
            }}
            count={customerListData?.count || 0}
            //   rowUpdate={leadUpdate}
            config={{
              columnVisibilityModel: {
                website: false,
                company: false,
                sector: false,
                country: false,
                state: false,
                city: false,
                language: false,
                leadValue: false,
                department: false,
              },
              features: [],
            }}
            toolbar={{
              customActions: [],
            }}
          />
        ) : (
          <EmptyState
            content1={t("crm.customer.customers.emptyState1")}
            content2={t("crm.customer.customers.emptyState2")}
            button1={{
              title: t("crm.customer.customers.createLead"),
              onClick: () => (props?.setOpenLeadCreateDrawer ?? (() => {}))(true),
              leftIcon: <Add sx={{ mr: 1 }} fontSize="small" />,
            }}
            button2={{
              title: t("crm.customer.customers.importLead"),
              onClick: () => navigate(importLeadRoute()),
              leftIcon: <FileUpload sx={{ mr: 1 }} fontSize="small" />,
            }}
          />
        )}
  
        <Box sx={{ width: "100%", height: "10px" }} />
      </Stack>
    );
  });
  
  export default CustomerListView;
  