const locale = {
  legalAgreement: "Agreement",
  create: "Agreement Oluştur",
  update: "Agreement Güncelle",
  delete: "Sil",
  view: "Agreement'i Görüntüle",
  list: "Agreement Listesi",
  createdSuccessfully: "Agreement başarıyla oluşturuldu",
  updatedSuccessfully: "Agreement başarıyla güncellendi",
  deleteConfirm:
    "Kaydı silmek istediğinizden emin misiniz? Bu işlem geri alınamaz.",
  deletedSuccessfully: "Agreement başarıyla silindi",
  type: "Tip",
  name: "Ad",
  content: "İçerik",
  description: "Açıklama",
  createInfo: "Bu bölümden yeni kayıt oluşturabilirsiniz.",
  emptyState2:
    "Hemen legalAgreement oluşturabilir ya da içeriye aktarabilirsiniz.",
  createAgreement: "Agreement Oluştur",
  importAgreement: "Agreement İçe Aktar",
  importTitle: "Agreementleri İçe Aktar",
  downloadExample: "Örnek Formu İndir",
  importText1: "Bu sayfadan legalAgreementleri içeriye aktarabilirsiniz.",
  importText2:
    "Agreementlerinizi yayınlayabilmeniz için aşağıdaki örnek formu kullanabilirsiniz.",
  importExport: "İçeri/Dışarı Aktar",
  exportData: "Veriyi Dışarı Aktar",
  importData: "Veriyi İçe Aktar",
  b2b: "B2B",
  b2c: "B2C",
  viewAgreement: "Görüntüle",
  edit: "Düzenle",
  junk: "Spam",
  unJunk: "Spami Kaldır",
  addNewRole: "Yeni Rol Ekle",
  actions: "İşlemler",
  legalAgreements: "Sözlşemeler",
  businessName: "Firma Adı",
  governmentAgency: "Devlet Dairesi",
  fullName: "Tam Adı",
  VAT: "Vergi Dairesi",
  phoneNumber: "Telefon Numarası",
  country: "İl",
  state: "İlçe",
  city: "İlçe",
  ZIP: "Posta Kodu",
  address: "Adres",
  contactFirstName: "İletişim Adı",
  contactLastName: "İletişim Soyadı",
  contactEmail: "İletişim E-postası",
  sentDate: "Giden Tarih",
  sendEmailOnExpiration: "E-posta Gönder"
};

export default locale;
