import { getTranslatedText } from "localization";
import { ValidationHelper } from "validations/validation.helper";

export const validateCustomerCreateFromLeadInput =
  ValidationHelper.BuildObjectValidationSchema({
    input: ValidationHelper.BuildObjectValidationSchema({
      primaryContact: ValidationHelper.BuildObjectValidationSchema({
        email: ValidationHelper.RequiredEmail(
          getTranslatedText("emailIsInvalid"),
          getTranslatedText("emailIsRequired")
        ),
        firstName: ValidationHelper.RequiredString(
          getTranslatedText("nameIsRequired")
        ),
        lastName: ValidationHelper.RequiredString(
          getTranslatedText("surnameIsRequired")
        ),
      }
      ),
    }),
  });