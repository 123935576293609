
import notesLocale from "../subdomains/notes/locales/tr";

const locale = {

  notes: {
    ...notesLocale
  }
};

export default locale;

