const locale = {
  demo: "Demo",
  title: "CoCRM Lead+ Nedir?",
  description: "CoCRM olarak, yalnızca iş ve müşteri süreçlerinizi yönetmeniz için destek vermek ile kalmıyor aynı zamanda yeni müşteriler edinmeniz için de sizlere yardımcı olmaktan mutluluk duyuyoruz.",
  featuresTitle: "CoCRM Lead+ ile;",
  features1: "Pazarlama için hedeflediğiniz ülke, şehir ve/veya sektör parametlerine göre,",
  features2: "Satış hedeflerinizi spesifikleştirdiğiniz alanlara göre,",
  features3: "Marka bilinirliğinizi artırmak istediğiniz pazarlara göre,",
  features4: "Aradığınız potansiyel müşterin public verilerini sizlerle paylaşmaktan memnuniyet duyarız!",
  buttonTitle: "Teklif Al",
  formDescription: "Bu formu doldurarak, bizimle iletisime geçebilirsiniz.",
};

export default locale;
