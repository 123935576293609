

import { DashboardRouter } from "../subdomains/dashboard/routes/DashboardRouter";
import { DashboardDomainLayout } from "../layouts";
import { Route, Routes } from "react-router-dom";

export const DashboardDomainRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<DashboardDomainLayout />}>
        <Route index element={<DashboardRouter />} />
        <Route path="*" element={<DashboardRouter />} />
      </Route>
    </Routes>
  );
};
