
import demoLocale from "../subdomains/demo/locales/tr";

const locale = {

  demo: {
    ...demoLocale,
  },
};

export default locale;

