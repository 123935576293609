/* eslint-disable react-hooks/exhaustive-deps */
import {
  Grid,
  Stack,
  useMediaQuery,
} from "@mui/material";
import { useAppDispatch } from "context";
import { organizationalChartActions } from "../../../context";
import { createUsersRoute } from "../../../routes/organizationalChart.base.route";
import { useTranslation } from "react-i18next";
import { memo, useEffect, useState } from "react";
import {
  useUserListQuery,
  useUserUpdateMutation,
} from "../context/users.api";
import { IUser, IUserListInput } from "corede-common-cocrm";
import {
  IBaseError,
  IGraphqlVariables,
  UserConfirmationStatus,
} from "corede-common";
import { getCurrentLanguage } from "localization";
import { DeleteDialog } from "./Delete";
import { BaseDataGrid } from "components/baseDataGrid/BaseDataGrid";
import { BaseGridColType } from "components/baseDataGrid/infra/enums";
import EmptyState from "components/emptyState/EmptyState";
import {
  representArrayOfObjectAsString,
  representString,
  representStringDateAsString,
  representBooleanAsString,
  representDateAsString,
} from "utils/representationHelper";
import { DefaultErrorHandlerUseEffect } from "utils/useEffect.helper";
import UserUpdateWrapper from "./UserUpdateWrapper";

interface IUserList {
  setOpenRoles: (open: boolean) => void
  setOpenUserDetail: (open: boolean) => void
  selectedUser: IUser | undefined
  setSelectedUser: (user: IUser | undefined) => void
  setOpenDepartmentDialog: (open: boolean) => void
}

const UserList = memo((props: IUserList) => {
  // init

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const downMd = useMediaQuery((theme: any) => theme.breakpoints.down("md"));
  const currentLanguage = getCurrentLanguage();

  // states
  const [openUserUpdate, setOpenUserUpdate] = useState(false);
  const [openUserDelete, setOpenUserDelete] = useState(false);
  const [userListFilter, setUserListFilter] = useState<
    IGraphqlVariables<IUserListInput>
  >({
    input: {
      filter: {
        email: undefined,
        organizationId: undefined,
        role: undefined,
        status: undefined,
        createdById: undefined,
      },
      pagination: { page: 1, pageSize: 10 },
    },
  });

  // queries
  const {
    data: userListData,
    isLoading: userListLoading,
    error: userListError,
    refetch,
  } = useUserListQuery(userListFilter);

  const [userUpdate, { isLoading: userUpdateLoading, error: userUpdateError }] = useUserUpdateMutation();

  // effects

  useEffect(() => {
    refetch();
  }, [userListFilter]);

  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      userListError as IBaseError,
      currentLanguage
    );
  }, [userListError]);

  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      userUpdateError as IBaseError,
      currentLanguage
    );
  }, [userUpdateError]);

  useEffect(() => {
    dispatch(
      organizationalChartActions.setRightButton({
        title: t(`crm.organizationalChart.users.create`),
        path: createUsersRoute(),
        icon: null,
      })
    );
  }, [dispatch]);

  // handlers

  function handleSelectUser(direction: "next" | "back") {
    if (!props.selectedUser || !userListData?.data?.length) return;

    const currentIndex = userListData.data.findIndex(
      (lead) => lead._id === props.selectedUser?._id
    );

    let newIndex = currentIndex;
    if (direction === "next") {
      newIndex = currentIndex + 1;
    } else if (direction === "back") {
      newIndex = currentIndex - 1;
    }

    if (newIndex >= 0 && newIndex < userListData.data?.length) {
      const newSelectedLead = {
        ...userListData.data[newIndex],
        id: newIndex + 1,
      };
      props.setSelectedUser(newSelectedLead);
    }
  }

  return (
    <Grid item xs={12} height={"calc(100vh - 300px)"} mt={1}>
      <Stack direction="row" justifyContent="space-between" height={"100%"}>
        {true ? (
          <BaseDataGrid
            rows={
              userListData?.data?.map((user, index) => ({
                id: (index + 1) * (userListFilter.input?.pagination?.page ?? 0),
                _id: user._id,
                name: user.name,
                surname: user.surname,
                roles: representArrayOfObjectAsString({ dataList: user.roles }),
                email: user.email,
                status: user.status,
                phone: representString(user.phoneNumber),
                lastLoginDate: representStringDateAsString(
                  (user as any).lastLoginDate
                ),
                emailVerified: representBooleanAsString({
                  data: user.emailVerified,
                  trueValue: t("crm.organizationalChart.users.yes"),
                  falseValue: t("crm.organizationalChart.users.no"),
                }),
                country: representString(user.country),
                city: representString(user.city),
                createdAt: representDateAsString(user.createdAt),
              })) ?? []
            }
            columns={[
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: "id",
                    disableColumnMenu: true,
                    filterable: false,
                    width: 40,
                  },
                  headerConfig: {
                    name: "#",
                  },
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: "name",
                    editable: true,
                    width: 100,
                  },
                  headerConfig: {
                    name: t("crm.organizationalChart.users.name"),
                  },
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: "surname",
                    editable: true,
                    width: 100,
                  },
                  headerConfig: {
                    name: t("crm.organizationalChart.users.surname"),
                  },
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: "roles",
                    editable: false, // TODO: should be editable
                    sortable: false,
                    width: 150,
                  },
                  headerConfig: {
                    name: t("crm.organizationalChart.users.roles"),
                  },
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: "email",
                    filterable: true,
                    width: 160,
                  },
                  headerConfig: {
                    name: t("crm.organizationalChart.users.emailAddress"),
                  },
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.enum,
                  column: {
                    field: "status",
                    editable: false,
                    filterable: true,
                    width: 120,
                  },
                  headerConfig: {
                    name: t("crm.organizationalChart.users.status"),
                  },
                  filterConfig: {
                    customFieldName: `statuses`,
                    isArrayFilter: true,
                  },
                },
                data: UserConfirmationStatus,
                metaData: {
                  enumColor: {
                    [UserConfirmationStatus.active]: "info",
                    [UserConfirmationStatus.pending]: "warning",
                  },
                },
              },
              // TODO: below boolean can be componentise
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: "emailVerified",
                    filterable: true,
                    width: 120,
                  },
                  headerConfig: {
                    name: t("crm.organizationalChart.users.emailVerified"),
                  },
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: "phone",
                    filterable: true,
                    width: 120,
                  },
                  headerConfig: {
                    name: t("crm.organizationalChart.users.phone"),
                  },
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: "country",
                    filterable: true,
                    width: 120,
                  },
                  headerConfig: {
                    name: t("crm.organizationalChart.users.country"),
                  },
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: "city",
                    filterable: true,
                    width: 120,
                  },
                  headerConfig: {
                    name: t("crm.organizationalChart.users.city"),
                  },
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: "lastLoginDate",
                    filterable: false,
                    width: 120,
                  },
                  headerConfig: {
                    name: t("crm.organizationalChart.users.lastLoginDate"),
                  },
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: "createdAt",
                    filterable: false,
                    width: 120,
                  },
                  headerConfig: {
                    name: t("crm.organizationalChart.users.createdAt"),
                  },
                },
              },
            ]}
            actionColumn={{
              width: downMd ? 80 : 120,
              defaultActions: {
                view: {
                  config: {
                    setDisableFunc: (renderCellCallBackParams) => {
                      return renderCellCallBackParams.row.status === UserConfirmationStatus.pending;
                    }
                  },
                  clickConfig: {
                    setSelectedRow: props.setSelectedUser,
                    setOpenAction: props.setOpenUserDetail,
                  },
                },
                edit: {
                  config: {
                    setDisableFunc: (renderCellCallBackParams) => {
                      return renderCellCallBackParams.row.status === UserConfirmationStatus.pending;
                    }
                  },
                  clickConfig: {
                    setSelectedRow: props.setSelectedUser,
                    setOpenAction: setOpenUserUpdate,
                  },
                },
                delete: {
                  clickConfig: {
                    setSelectedRow: props.setSelectedUser,
                    setOpenAction: setOpenUserDelete,
                  },
                },
              },
              actionHeaderName: t("crm.actions"),
            }}
            loading={userListLoading}
            listFilter={{
              filterInput: userListFilter,
              setFilterInput: setUserListFilter,
            }}
            count={userListData?.count ?? 0}
            config={{
              columnVisibilityModel: {
                roles: false,
              },
              features: [],
            }}
            update={{
              updateQuery: userUpdate,
            }}
          />
        ) : (
          <EmptyState
            content1={t("crm.organizationalChart.users.emptyState1")}
          />
        )}
      </Stack>

      {/* <UserDetailWrapper
        open={props.openUserDetail}
        setOpen={props.setOpenUserDetail}
        selectedUser={selectedUser}
        handleSelect={handleSelectUser}
      /> */}

      <UserUpdateWrapper
        open={openUserUpdate}
        setOpen={setOpenUserUpdate}
        setOpenRoles={props.setOpenRoles}
        setOpenDepartments={props.setOpenDepartmentDialog}
        selectedUser={props.selectedUser}
        setSelectedUser={props.setSelectedUser}
      />

      <DeleteDialog
        open={openUserDelete}
        setItem={props.setSelectedUser}
        item={props.selectedUser}
        onClose={() => {
          props.setSelectedUser(undefined);
          setOpenUserDelete(false);
        }}
      />
    </Grid>
  );
});

export default UserList;
