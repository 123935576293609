const locale = {
  legalAgreement: "Legal Agreement",
  legalAgreements: "Legal Agreements",
  create: "Create LegalAgreement",
  update: "Update LegalAgreement",
  delete: "Delete",
  view: "View LegalAgreement",
  list: "List LegalAgreements",
  createdSuccessfully: "LegalAgreement created successfully",
  updatedSuccessfully: "LegalAgreement updated successfully",
  deleteConfirm:
    "Are you sure you want to delete? This action cannot be undone.",
  deletedSuccessfully: "LegalAgreement deleted successfully",
  createInfo: "You can create new legalAgreements here.",
  type: "Type",
  name: "Name",
  content: "Content",
  description: "Description",
  listInfo: "List of all legalAgreements.",
  importedError: "Imported error",
  importedSuccessfully: "Imported successfully",
  downloadFormError: "Download form error",
  downloadFormSuccessfully: "Download form successfully",
  downloadExampleFormSuccessfully: "Download example form successfully",
  downloadExampleFormError: "Download example form error",
  title: "List LegalAgreements",
  emptyState1: "No legalAgreements found here.",
  emptyState2: "You can create or import legalAgreements immediately.",
  createLegalAgreement: "Create LegalAgreement",
  importLegalAgreement: "Import LegalAgreement",
  importTitle: "Import LegalAgreements",
  downloadExample: "Download Example",
  importText1: "You can import legalAgreements from this page.",
  importText2:
    "You can use the sample form below to publish your legalAgreements.",
  importExport: "Import/Export",
  exportData: "Export Data",
  importData: "Import Data",
  b2b: "B2B",
  b2c: "B2C",
  viewLegalAgreement: "View",
  edit: "Edit",
  junk: "Junk",
  unJunk: "Un-Junk",
  addNewRole: "Add New Role",
  actions: "Actions",
  legalAgreementCreateSuccess: "LegalAgreement created successfully",
  legalAgreementUpdateSuccess: "LegalAgreement updated successfully",
};

export default locale;
