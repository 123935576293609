import { getTranslatedText } from "localization";
import { ValidationHelper } from "validations/validation.helper";

export const validateCreateOrganizationOwnInput =
  ValidationHelper.BuildObjectValidationSchema({
    input: ValidationHelper.BuildObjectValidationSchema({
      name: ValidationHelper.RequiredString(
        getTranslatedText("nameIsRequired")
      ).min(2, getTranslatedText("nameIsTooShort2")).max(50, getTranslatedText("nameIsTooLong50")),
    }),
  });