/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Grid, Typography, CircularProgress, Stack, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { useFormik } from "formik";
import { forgotPasswordValidationSchema } from 'utils/validations';
import { AuthLayout } from "../layouts";
import { useRequestResetPasswordMutation, setUser, useResendRegistrationByUserMutation } from "../context";
import { useSnackbar } from "notistack";
import { useAppDispatch } from "context";
import { useEffect, useState } from "react";
import { MyField } from "components";
import LoginCo from "assets/images/login-co.png";
import { useNavigate } from "react-router-dom";
import { routes } from "../routes/routes";
import { useTranslation } from 'react-i18next';
import { unknownError } from "corede-common";
import { getCurrentLanguage } from "localization";

const ForgotPassword = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentLanguage = getCurrentLanguage();
  const [resendActive, setResendActive] = useState(false);
  const [resendCodeDialog, setResendCodeDialog] = useState(false);
  const [email, setEmail] = useState('');
  const [getRequestResetPassword, { data, isLoading, error }] = useRequestResetPasswordMutation();
  const [getResendEmail, { data: resendData, isLoading: resendLoading, error: resendError }] = useResendRegistrationByUserMutation();


  const { values, handleSubmit, setFieldValue, errors, handleBlur, touched } = useFormik({
    enableReinitialize: true,
    validationSchema: forgotPasswordValidationSchema,
    initialValues: {
      email: ''
    },
    onSubmit: async (values) => {
      try {
        await getRequestResetPassword({
          input: { email: values.email }
        });
      } catch (error) {
        enqueueSnackbar(t("auth.somethingWentWrong"), { variant: 'error' });
      }
    }
  });

  const handleResendCode = async () => {
    try {
      await getResendEmail({
        email: email
      });
    } catch (error) {
      enqueueSnackbar(t("auth.somethingWentWrong"), { variant: 'error' });
    }
  };

  useEffect(() => {
    if ((error as any)?.error?.name === "registrationConfirmationIsRequiredError") {
      setResendActive(true);
    }
    if (error) {
      enqueueSnackbar((error as any)?.error?.message?.[currentLanguage] ?? unknownError.message[currentLanguage], { variant: 'error' });
    }
  }, [error, enqueueSnackbar]);

  useEffect(() => {
    if (resendError) {
      enqueueSnackbar((resendError as any)?.error?.message?.[currentLanguage] ?? unknownError.message[currentLanguage], { variant: 'error' });
    }
  }, [resendError, enqueueSnackbar]);


  useEffect(() => {
    if (data) {
      enqueueSnackbar(t("auth.resetPasswordRequestSuccess"), { variant: 'success' });
      navigate(routes.auth.forgotPasswordSuccessfull);
      dispatch(setUser(data));
    }
  }, [data, enqueueSnackbar, navigate, dispatch]);

  useEffect(() => {
    if (resendData) {
      enqueueSnackbar(t("auth.resentCodeSuccess"), { variant: 'success' });
      setResendCodeDialog(false);
      navigate(routes.auth.resendConfirmationSuccessfull);
    }
  }, [resendData]);

  useEffect(() => {
    setEmail(values.email)
  }, [values.email])


  return <AuthLayout>
    <Stack direction={"column"} justifyContent={"space-between"} alignItems={"center"}>
      <Typography fontSize={14} fontWeight={400} textAlign={"center"} color={"GrayText"} py={2}>
        {/* Optional intro text */}
      </Typography>
      <Grid item xs={12} display={"flex"} height={"100vh"} width={"100%"} justifyContent={'center'} alignItems={'center'}>
        <Box display={{ xs: 'flex', md: 'none' }} height={100} />
        <Box
          sx={{
            width: { xs: '100%', md: '80%', lg: '60%' },
            height: "80vh",
            display: 'flex',
            flexDirection: 'column',
            justifyContent: { xs: 'flex-start', md: 'center' },
            alignItems: 'center',
            gap: 2,
          }}
          px={{ xs: 1, md: 7 }}
        >
          <img src={LoginCo} alt={t("auth.logoAlt")} width={120} />
          <Box display={"flex"} flexDirection={"column"} gap={2} alignItems={"center"} pt={2}>
            <Typography fontSize={32} fontWeight={500} textAlign={"center"}>
              {t("auth.forgotPasswordTitle")}
            </Typography>
          </Box>
          <Box display={"flex"} flexDirection={"column"} gap={2} alignItems={"center"} pb={2}>
            <Typography fontSize={14} fontWeight={400} textAlign={"center"}>
              {t("auth.resetPasswordInstructions")}
            </Typography>
          </Box>

          <Box display={"flex"} sx={{ width: '100%' }} flexDirection={"column"} alignItems={"center"}>
            <form onSubmit={handleSubmit as any} style={{ width: '100%' }}>
              <Box display={"flex"} flexDirection={"column"} gap={1.5} alignItems={"center"} >
                <MyField
                  placeholder={t("auth.emailAddress")}
                  type="email"
                  values={values}
                  setFieldValue={setFieldValue}
                  name="email"
                  errors={errors}
                  handleBlur={handleBlur}
                  touched={touched}
                />
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={handleSubmit as any}
                  disabled={isLoading || !values.email || !!errors.email}
                  sx={{ fontSize: 14, height: 56, mt: 2 }}
                >
                  {(isLoading) ? <CircularProgress size="1rem" /> : t("auth.resetPassword")}
                </Button>
              </Box>
            </form>
          </Box>
          {resendActive && <Typography fontSize={14} fontWeight={400} textAlign={"center"} color={"error.light"} py={2} sx={{ cursor: "pointer" }}>
            {t("auth.didNotReceiveCode")} <span style={{ color: "#961650", fontWeight: 600 }} onClick={() => setResendCodeDialog(true)}> {t("auth.resendCode")}</span>
          </Typography>}
        </Box>
      </Grid>

      <Typography fontSize={14} fontWeight={400} textAlign={"center"} color={"GrayText"} py={2} sx={{ cursor: "pointer" }}>
        {t("auth.rememberPassword")} <span style={{ color: "#000" }} onClick={() => navigate(routes.auth.login)}> {t("auth.login")}</span>
      </Typography>
    </Stack>

    <Dialog open={resendCodeDialog} onClose={() => setResendCodeDialog(false)}>
      <DialogTitle sx={{ px: 5, pt: 5 }}>{t("auth.resendCodeTitle")}</DialogTitle>
      <DialogContent sx={{ minWidth: 400, minHeight: 100, px: 5 }}>
        <Stack sx={{ pt: 1 }}>
          <Typography variant="body1" color="textSecondary" mb={4}>
            {t("auth.enterEmailToResend")}
          </Typography>
          <TextField
            name="email"
            label={t("auth.email")}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="example@gmail.com"
            InputLabelProps={{ shrink: true }}
          />
        </Stack>
      </DialogContent>
      <DialogActions sx={{ px: 5, pb: 3 }}>
        <Button onClick={() => setResendCodeDialog(false)}>{t("auth.close")}</Button>
        <Button onClick={handleResendCode} variant="contained" disabled={!email}>
          {t("auth.send")}
        </Button>
      </DialogActions>
    </Dialog>
  </AuthLayout>
}

export default ForgotPassword;
