import { useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import axios from "axios"
import { t } from "i18next"
import { enqueueSnackbar } from "notistack"
import { setUser } from "../context"
import { useAppDispatch } from "context"


const RedirectSuccess = () => {

    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const token = queryParams.get('token')
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    let data = JSON.stringify({
        query: `mutation LoginResult {
  loginResult {
    accessExpiresIn
    accessToken
    devMetaData {
      twoFactorCode
    }
    refreshExpiresIn
    refreshToken
    twoFactorAuth
    twoFactorToken
    user {
      _id
      country
      department {
        _id
        name
      }
      email
      language
      name
      organization {
        _id
        name
      }
      profileImage {
        createdAt
        customName
        fileId
        isPrivate
        mimeType
        name
        publicUrl
        s3Key
        thumbnailPublicUrl
        thumbnailS3Key
        updatedAt
      }
      status
      surname
      type
    }
  }
}`
    });



    useEffect(() => {
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_BASE_URL}/graphql`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            data: data
        };

        async function postData() {
            await axios.request(config)
                .then((response) => {
                    console.log(JSON.stringify(response?.data?.data?.loginResult));
                    if (response?.data?.data?.loginResult) {
                        enqueueSnackbar(t("auth.loginSuccess"), { variant: 'success' });
                        dispatch(setUser(response?.data?.data?.loginResult));
                        navigate('/');
                      }
                })
                .catch((error) => {
                    console.log(error);
                });
        }

        postData()

    }, [token, data])


    return null
}

export default RedirectSuccess