import {
  // IUserListInput, IUserListRequest, IUserListResponse, IUserListResult, userListQuery,
  userProfileImageCreateQuery, IUserProfileImageCreateRequest, IUserProfileImageCreateResponse,
  userProfileImageAssignQuery, IUserProfileImageAssignRequest, IUserProfileImageAssignResponse,
  userPermissionListOwnQuery, IPermissionListItemResult, IUserPermissionListOwnRequest,
  IUserPermissionListOwnResponse
} from "corede-common-cocrm";
import {
  IDomainFileCreateResult,
  IEntityFileAssignResult, IEntityFileAssignOwnInput,
  IDomainFileCreateOwnInputWithMimeType
} from "corede-common"
import { commonApi } from "context/commonApi";
import { EndpointQueryBuilder } from "apps/helper/endpoint-query.builder";
import { demoTags } from "./profile.tags";
import { IGraphqlVariables, } from "corede-common";




export const demoApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({
    // queries
    // leadTags: builder.query<
    //     ILeadTagsResult,
    //     IGraphqlVariables<ILeadTagsFilterInput>
    // >(
    //     EndpointQueryBuilder.BuildGraphqlQuery<
    //         ILeadTagsRequest,
    //         ILeadTagsResponse,
    //         ILeadTagsResult,
    //         ILeadTagsFilterInput,
    //     >({
    //         query: leadTagsQuery,
    //         providesTags: [leadTags.leadTags],
    //     })),

    userPermissionListOwn: builder.query<
      IPermissionListItemResult[],
      IGraphqlVariables<undefined>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IUserPermissionListOwnRequest,
        IUserPermissionListOwnResponse,
        IPermissionListItemResult[],
        undefined
      >({
        query: userPermissionListOwnQuery,
        providesTags: ["user"],
      })),



    // mutation
    // userProfileImageCreate: builder.mutation<
    //   any,
    //   IGraphqlVariables<any>
    // >(
    //   EndpointQueryBuilder.BuildGraphqlQuery<
    //     IUserProfileImageCreateRequest,
    //     IUserProfileImageCreateResponse,
    //     any,
    //     any
    //   >({
    //     query: userProfileImageCreateQuery,
    //     invalidatesTags: [],
    //   })),

    userProfileImageCreate: builder.mutation<
      IDomainFileCreateResult,
      IGraphqlVariables<IDomainFileCreateOwnInputWithMimeType>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IUserProfileImageCreateRequest,
        IUserProfileImageCreateResponse,
        IDomainFileCreateResult,
        IDomainFileCreateOwnInputWithMimeType
      >({
        query: userProfileImageCreateQuery,
        invalidatesTags: ["user"],
      })),

    userProfileImageAssign: builder.mutation<
      IEntityFileAssignResult,
      IGraphqlVariables<IEntityFileAssignOwnInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IUserProfileImageAssignRequest,
        IUserProfileImageAssignResponse,
        IEntityFileAssignResult,
        IEntityFileAssignOwnInput
      >({
        query: userProfileImageAssignQuery,
        invalidatesTags: ["user"],
      })),


  }),
});

export const {

  useUserPermissionListOwnQuery,
  useLazyUserPermissionListOwnQuery,

  useUserProfileImageCreateMutation,
  useUserProfileImageAssignMutation,
} = demoApi;
