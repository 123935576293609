import { Outlet } from "react-router";
import MainLayout from "layouts/MainLayout";


const DiskLayout = () => {

  return <MainLayout treeItems={[]}>
    <Outlet />
  </MainLayout>
}

export default DiskLayout;
