import { Outlet } from "react-router";
import MainLayout from "layouts/MainLayout";
import { useTranslation } from "react-i18next";
import { useOrganizationDetailOwnQuery } from "apps/auth/context";
import { baseCommunityRoute, baseCrmRoute, baseIntegrationsRoute, baseLeadPlusRoute, baseLegalAgreementsRoute, baseMarketingRoute, baseMenuRoute, baseNotificationRoute, basePaymentRoute, baseSettingsRoute, baseUserRoute, baseWindowRoute } from "../domains/settings/routes/demo.base.route";


const SettingsLayout = () => {

  const { t } = useTranslation();
  const { data: organizationDetailOwn } = useOrganizationDetailOwnQuery({});

  const status = organizationDetailOwn?.status;

  const treeItems = [
    {
      itemId: "generalSettings",
      label: t("settings.generalSettings"),
      children: [
        {
          itemId: baseSettingsRoute(),
          label: t("settings.myOrganization"),
          href: baseSettingsRoute(),
        },
        {
          itemId: basePaymentRoute(),
          label: t("settings.paymentSettings"),
          href: basePaymentRoute()
        },
        {
          itemId: baseNotificationRoute(),
          label: t("settings.notificationSettings"),
          href: baseNotificationRoute(),
        },
      ]
    },
    {
      itemId: "moduleSettings",
      label: t("settings.moduleSettings"),
      children: [
        {
          itemId: baseCrmRoute(),
          label: t("settings.crmSettings"),
          href: baseCrmRoute(),
        },
        {
          itemId: baseLeadPlusRoute(),
          label: t("settings.leadPlusSettings"),
          href: baseLeadPlusRoute(),
        },
        {
          itemId: baseMarketingRoute(),
          label: t("settings.marketingSettings"),
          href: baseMarketingRoute(),
        },
        {
          itemId: baseWindowRoute(),
          label: t("settings.windowSettings"),
          href: baseWindowRoute(),
        },
        {
          itemId: baseCommunityRoute(),
          label: t("settings.communitySettings"),
          href: baseCommunityRoute(),
        }
      ]
    },
    {
      itemId: baseIntegrationsRoute(),
      label: t("settings.integrations"),
      href: baseIntegrationsRoute()
    },
    {
      itemId: baseUserRoute(),
      label: t("settings.userSettings"),
      href: baseUserRoute()
    },
    {
      itemId: baseMenuRoute(),
      label: t("settings.menuSettings"),
      href: baseMenuRoute()
    },
    {
      itemId: baseLegalAgreementsRoute(),
      label: t("settings.legalAgreements"),
      href: baseLegalAgreementsRoute()
    },
    
  ];

  const restrictedTreeItems = [
    {
      itemId: "generalSettings",
      label: t("settings.generalSettings"),
      children: [
        {
          itemId: baseSettingsRoute(),
          label: t("settings.myOrganization"),
          href: baseSettingsRoute(),
        },
        {
          itemId: basePaymentRoute(),
          label: t("settings.paymentSettings"),
          href: basePaymentRoute()
        },
        {
          itemId: baseNotificationRoute(),
          label: t("settings.notificationSettings"),
          href: baseNotificationRoute(),
        },
      ]
    },
    {
      itemId: "moduleSettings",
      label: t("settings.moduleSettings"),
      children: [
        {
          itemId: baseCrmRoute(),
          label: t("settings.crmSettings"),
          href: baseCrmRoute(),
        },
        {
          itemId: baseLeadPlusRoute(),
          label: t("settings.leadPlusSettings"),
          href: baseLeadPlusRoute(),
        },
        {
          itemId: baseMarketingRoute(),
          label: t("settings.marketingSettings"),
          href: baseMarketingRoute(),
        },
        {
          itemId: baseWindowRoute(),
          label: t("settings.windowSettings"),
          href: baseWindowRoute(),
        },
        {
          itemId: baseCommunityRoute(),
          label: t("settings.communitySettings"),
          href: baseCommunityRoute(),
        }
      ]
    },
    {
      itemId: baseMenuRoute(),
      label: t("settings.menuSettings"),
      href: baseMenuRoute()
    },
    {
      itemId: baseUserRoute(),
      label: t("settings.userSettings"),
      href: baseUserRoute()
    }
  ];


  return <MainLayout 
  treeItems={status !== "active" ? restrictedTreeItems : treeItems}
  expandedMenu={["generalSettings", "moduleSettings"]}
  >
    <Outlet />
  </MainLayout>
}

export default SettingsLayout;
