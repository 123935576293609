/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Grid, Typography, CircularProgress, Stack, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { useFormik } from "formik";
import { confirmRegistrationValidationSchema } from 'utils/validations';
import { AuthLayout } from "../layouts";
import { useConfirmRegistrationMutation, useResendRegistrationByUserMutation, setUser } from "../context";
import { useSnackbar } from "notistack";
import { useAppDispatch } from "context";
import { useEffect, useState } from "react";
import { MuiOtpInput } from 'mui-one-time-password-input';
import LoginCo from "assets/images/login-co.png";
import { useNavigate, useLocation } from "react-router-dom";
import { routes } from "../routes/routes";
import { useTranslation } from 'react-i18next';
import { unknownError } from "corede-common";
import { getCurrentLanguage } from "localization";

const Confirmation = () => {
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();
  const [getConfirmRegistration, { data, isLoading, error }] = useConfirmRegistrationMutation();
  const [getResendEmail, { data: resendData, isLoading: resendLoading, error: resendError }] = useResendRegistrationByUserMutation();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [otp, setOtp] = useState('');
  const [email, setEmail] = useState('');
  const [resentCodeDialog, setResentCodeDialog] = useState(false);
  const token = new URLSearchParams(useLocation().search).get('token');
  // const code = new URLSearchParams(useLocation().search).get('code');

  const handleChange = (newValue: string) => {
    setOtp(newValue);
  };

  const handleResendCode = async () => {
    try {
      await getResendEmail({
        email: email
      });
    } catch (error) {
      enqueueSnackbar(t("auth.somethingWentWrong"), { variant: 'error' });
    }
  };

  const { handleSubmit, setFieldValue, } = useFormik({
    enableReinitialize: true,
    validationSchema: confirmRegistrationValidationSchema,
    initialValues: {
      code: "",
      token: token
    },
    onSubmit: async (values) => {
      try {
        await getConfirmRegistration({
          input: { code: otp, token: token }
        });
      } catch (error) {
        enqueueSnackbar(t("auth.somethingWentWrong"), { variant: 'error' });
      }
    }
  });


  useEffect(() => {
    if (error) {
      enqueueSnackbar((error as any)?.error?.message?.[currentLanguage] ?? unknownError.message[currentLanguage], { variant: 'error' });
    }
  }, [error, enqueueSnackbar]);

  useEffect(() => {
    if (resendError) {
      enqueueSnackbar((resendError as any)?.error?.message?.[currentLanguage] ?? unknownError.message[currentLanguage], { variant: 'error' });
    }
  }, [resendError, enqueueSnackbar]);

  useEffect(() => {
    if (data) {
      enqueueSnackbar(t("auth.userConfirmedSuccess"), { variant: 'success' });
      dispatch(setUser(data));
      navigate(routes.auth.login);
    }
  }, [data, dispatch, enqueueSnackbar]);

  useEffect(() => {
    if (resendData) {
      enqueueSnackbar(t("auth.resentCodeSuccess"), { variant: 'success' });
      setResentCodeDialog(false);
      navigate(routes.auth.resendConfirmationSuccessfull);
    }
  }, [resendData]);


  useEffect(() => {
    if (otp) {
      setFieldValue("code", otp);
    }
  },[otp])
  return (
    <AuthLayout>
      <Stack direction={"column"} justifyContent={"space-between"} alignItems={"center"}>
        <Typography fontSize={14} fontWeight={400} textAlign={"center"} color={"GrayText"} py={2}>
          {/* Optional intro text */}
        </Typography>
        <Grid item xs={12} display={"flex"} height={"100vh"} width={"100%"} justifyContent={'center'} alignItems={'center'}>
          <Box display={{ xs: 'flex', md: 'none' }} height={100} />
          <Box
            sx={{
              width: { xs: '100%', md: '80%', lg: '60%' },
              height: "80vh",
              display: 'flex',
              flexDirection: 'column',
              justifyContent: { xs: 'flex-start', md: 'center' },
              alignItems: 'center',
              gap: 2,
            }}
            px={{ xs: 1, md: 7 }}
          >
            <img src={LoginCo} alt={t("auth.logoAlt")} width={120} />
            <Box display={"flex"} flexDirection={"column"} gap={2} alignItems={"center"} pt={2}>
              <Typography fontSize={32} fontWeight={500} textAlign={"center"}>
                {t("auth.emailConfirmationTitle")}
              </Typography>
            </Box>
            <Box display={"flex"} flexDirection={"column"} gap={2} alignItems={"center"} pb={2}>
              <Typography fontSize={14} fontWeight={400} textAlign={"center"}>
                {t("auth.enterOtp")}
              </Typography>
            </Box>
            <Box display={"flex"} flexDirection={"column"} gap={2} alignItems={"center"} pb={2}>
              <MuiOtpInput value={otp}
                onChange={handleChange}
                length={6} inputMode="numeric" autoFocus={true} />
            </Box>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleSubmit as any}
              disabled={isLoading || otp.length < 6}
              sx={{ fontSize: 14, height: 56 }}
            >
              {(isLoading) ? <CircularProgress size="1rem" /> : t("auth.submit")}
            </Button>
            <Typography fontSize={14} fontWeight={400} textAlign={"center"} color={"GrayText"} py={2} sx={{ cursor: "pointer" }}>
              {t("auth.didNotReceiveCode")} <span style={{ color: "#000" }} onClick={() => setResentCodeDialog(true)}> {t("auth.resendCode")}</span>
            </Typography>
          </Box>
        </Grid>

        <Typography fontSize={14} fontWeight={400} textAlign={"center"} color={"GrayText"} py={2} sx={{ cursor: "pointer" }}>
          {t("auth.alreadyHaveAccount")} <span style={{ color: "#000" }} onClick={() => navigate(routes.auth.login)}> {t("auth.login")}</span>
        </Typography>
      </Stack>

      <Dialog open={resentCodeDialog} onClose={() => setResentCodeDialog(false)}>
        <DialogTitle sx={{ px: 5, pt: 5 }}>{t("auth.resendCodeTitle")}</DialogTitle>
        <DialogContent sx={{ minWidth: 400, minHeight: 100, px: 5 }}>
          <Stack sx={{ pt: 1 }}>
            <Typography variant="body1" color="textSecondary" mb={4}>
              {t("auth.enterEmailToResend")}
            </Typography>
            <TextField
              name="email"
              label={t("auth.email")}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="example@gmail.com"
              InputLabelProps={{ shrink: true }}
            />
          </Stack>
        </DialogContent>
        <DialogActions sx={{ px: 5, pb: 3 }}>
          <Button onClick={() => setResentCodeDialog(false)}>{t("auth.close")}</Button>
          <Button onClick={handleResendCode} variant="contained" disabled={!email}>
            {t("auth.send")}
          </Button>
        </DialogActions>
      </Dialog>
    </AuthLayout>
  );
}

export default Confirmation;
