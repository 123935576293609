const locale = {
  departments: "Departments",
  delete: "Delete",
  deleteConfirm: "Are you sure you want to delete this department. This action cannot be undone.",
  deletedSuccessfully: "Department deleted successfully",
  createDepartmentTitle: "Create Department",
  departmentNameLabel: "Department Name",
  rolesLabel: "Roles",
  addRole: "Add Role",
  createDepartmentButton: "Create Department",
  createSuccess: "Department created successfully",
  columnId: "#",
  columnName: "Name",
  columnRoles: "Roles",
  columnActions: "Actions",
  headerTitle: "Departments",
  createDepartment: "Create Department",
  rolesButton: "Roles",
  emptyStateContent1: "Departments not found",
  emptyStateContent2: "Please create new department",
  addNewRole: "Add New Role",
  updateDepartmentTitle: "Update Department",
  updateDepartmentButton: "Update",
  updateSuccess: "Department updated successfully",
};

export default locale;
