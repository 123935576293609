/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from "@mui/material";
import { useLazyOrganizationDetailOwnQuery, useUserDetailOwnQuery } from "apps/auth/context";
import { useEffect } from "react";
import HomeForRestricted from "./HomeForRestricted";
import HomeForActive from "./HomeForActive";

const List = () => {

  const { data: userDetailOwnData } = useUserDetailOwnQuery({});
  const [getOrganizationDetailOwn, { data: organizationDetailOwn }] = useLazyOrganizationDetailOwnQuery();

  const status = organizationDetailOwn?.status;

  useEffect(() => {
    if (userDetailOwnData) {
      getOrganizationDetailOwn({})
    }
  }, [userDetailOwnData]);

  return (
    <Grid container spacing={2} p={{ xs: 2, sm: 4 }}>
      <Grid item xs={12}>
        {status !== "active" && organizationDetailOwn && <HomeForRestricted />}
        {status === "active" && organizationDetailOwn && <HomeForActive />}
      </Grid>

    </Grid >
  );
};

export default List;