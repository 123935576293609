import {
  ArticleOutlined,
  FileDownload,
  ImportExport,
  Publish,
} from "@mui/icons-material";
import {
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  GridToolbarProps,
  ToolbarPropsOverrides,
} from "@mui/x-data-grid";
import { t } from "i18next";

export interface IToolbarExcelImportExportParams {
  inputFileRef: React.MutableRefObject<HTMLInputElement | null>;
  handleImportForm: (file: File) => void;
  handleDownloadForm: () => void;
  handleDownloadExampleForm: () => void;
}

export function CustomToolbar(params: {
  customActions: JSX.Element[];
  importExport?: IToolbarExcelImportExportParams;
}) {
  return (_: GridToolbarProps & ToolbarPropsOverrides): JSX.Element => {
    // // const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    let open = false;
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      open = !open;
    };
    const handleClose = () => {
      open = false;
    };
    const handleImportClick = () => {
      if (params.importExport!.inputFileRef.current) {
        params.importExport!.inputFileRef.current.click();
      }
    };

    return (
      <GridToolbarContainer>
        <GridToolbarContainer>
          <GridToolbarColumnsButton />
          <GridToolbarDensitySelector />
          <GridToolbarFilterButton />
          {params.importExport && (
            <Button
              aria-controls={open ? "import-export-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              startIcon={<ImportExport />}
            >
              {t("crm.lead.lead.importExport")}
            </Button>
          )}
          {params.importExport && (
            <Menu
              id="import-export-menu"
              anchorEl={null}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "import-export-button",
              }}
            >
              <MenuItem
                onClick={() => params.importExport!.handleDownloadForm()}
              >
                <ListItemIcon>
                  <FileDownload fontSize="small" />
                </ListItemIcon>
                <ListItemText>{t("crm.lead.lead.exportData")}</ListItemText>
              </MenuItem>
              <MenuItem onClick={() => handleImportClick()}>
                <ListItemIcon>
                  <Publish fontSize="small" />
                </ListItemIcon>
                <ListItemText>{t("crm.lead.lead.importData")}</ListItemText>
              </MenuItem>
              <input
                type="file"
                hidden
                ref={params.importExport!.inputFileRef}
                onChange={(e) => {
                  params.importExport!.handleImportForm(e.target.files![0]);
                }}
              />

              <MenuItem
                onClick={() => params.importExport!.handleDownloadExampleForm()}
              >
                <ListItemIcon>
                  <ArticleOutlined fontSize="small" />
                </ListItemIcon>
                <ListItemText>
                  {t("crm.lead.lead.downloadExample")}
                </ListItemText>
              </MenuItem>
            </Menu>
          )}
          {params.customActions}
        </GridToolbarContainer>
      </GridToolbarContainer>
    );
  };
}
