import { Route, Routes } from "react-router-dom";
import { List, ServiceCategories} from "../pages";
import { ServicesLayout } from "../layouts/ServicesLayout";
import { commonRoutes } from "routes/route.common";

export const ServicesRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<ServicesLayout />}>
        <Route index element={<List />} />
        <Route path={`/${commonRoutes.categories}`} element={<ServiceCategories />} />
       </Route>
    </Routes>
  );
};
