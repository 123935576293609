import { getTranslatedText } from "localization";
import { ValidationHelper } from "validations/validation.helper";

export const validateCreateLeadInput =
  ValidationHelper.BuildObjectValidationSchema({
    input: ValidationHelper.BuildObjectValidationSchema({
      businessType: ValidationHelper.NotRequiredString(),
      fullName: ValidationHelper.RequiredString(
        getTranslatedText("fullNameIsRequired")),
      email: ValidationHelper.RequiredEmail(
        getTranslatedText("emailIsInvalid"),
        getTranslatedText("emailIsRequired")),
      phoneNumber: ValidationHelper.NotRequiredString(),
      qualification: ValidationHelper.NotRequiredString(),
      sourceId: ValidationHelper.RequiredString(
        getTranslatedText("sourceIsRequired")),
      statusId: ValidationHelper.RequiredString(
        getTranslatedText("statusIsRequired")),
      type: ValidationHelper.NotRequiredString(),
      tags: ValidationHelper.NotRequiredArray(),
      isPublic: ValidationHelper.NotRequiredBoolean(),
      isJunk: ValidationHelper.NotRequiredBoolean(),
      company: ValidationHelper.NotRequiredString(),
      sector: ValidationHelper.NotRequiredString(),
      position: ValidationHelper.NotRequiredString(),
      website: ValidationHelper.NotRequiredString(),
      country: ValidationHelper.NotRequiredString(),
      state: ValidationHelper.NotRequiredString(),
      city: ValidationHelper.NotRequiredString(),
      language: ValidationHelper.NotRequiredString(),
      assignedIds: ValidationHelper.NotRequiredArray(),
      note: ValidationHelper.NotRequiredString(),
    }),
  });