import { commonRoutes } from "routes/route.common";

export const productsAndServicesDomainRoutes = {
  base: "productsAndServices",
  subdomains: {
    products: {
      base: "products",
    },
    services: {
      base: "services",
    },
    categories: {
      base: "categories",
    },
  },
};

export const baseProductsAndServicesRoute = (): string =>
  `/${productsAndServicesDomainRoutes.base}/`;
export const listProductsRoute = (): string =>
  `/${productsAndServicesDomainRoutes.base}/${productsAndServicesDomainRoutes.subdomains.products.base}`;
export const listServicesRoute = (): string =>
  `/${productsAndServicesDomainRoutes.base}/${productsAndServicesDomainRoutes.subdomains.services.base}`;
export const listCategoriesRoute = (): string =>
  `/${productsAndServicesDomainRoutes.base}/${productsAndServicesDomainRoutes.subdomains.categories.base}`;
