/* eslint-disable react-hooks/exhaustive-deps */
import { Avatar, Box, Grid, Stack, Typography } from "@mui/material";
import { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Update } from "@mui/icons-material";
import { HeaderBase } from "components/contentHeaders/crm/HeaderBase";
import { MyTab } from "components";
import { listLeadRoute } from "../../../routes/lead.base.route";
import {
  useLeadDetailQuery, useLeadEstimateListQuery, useLeadNoteListQuery,
  useLeadProposalListQuery,
  useNoteUpdateMutation,
} from "../context/lead.api";
import DetailBox from "components/box/DetailBox";
import {
  ILeadDetailResult, INoteListItemResult, IReminderListItemResult, LeadQualification,
  LeadType, NoteTargetType, noteUpdateQuery, ReminderPriority, ReminderStatus,
  ReminderTargetType
} from "corede-common-cocrm";
import { convertEnums } from "localization";
import { Language } from "corede-common";
import * as Icons from '@mui/icons-material';
import LeadUpdateDrawerWrapper from "./LeadUpdateWrapper";
import { LeadSourcesCreateDialog } from "../../leadSources/pages";
import { LeadStatusCreateDialog } from "../../leadStatuses/pages";
import { AddLeadContactDialog } from "./AddLeadContactDialog";
import EstimateCreateWraper from "apps/crm/domains/05-preSales/subdomains/estimates/pages/EstimateCreateWrapper";
import ProposalCreateWraper from "apps/crm/domains/05-preSales/subdomains/proposals/pages/ProposalCreateWrapper";
import { BaseDataGrid } from "components/baseDataGrid/BaseDataGrid";
import { BaseGridColType } from "components/baseDataGrid/infra/enums";
import { representDateAsString, representDateTimeAsString, representHtmlAsString, representString } from "utils/representationHelper";
import EmptyState from "components/emptyState/EmptyState";
import { t } from "i18next";
import ReminderCreateDialog from "apps/crm/domains/12-calendar/subdomains/reminders/pages/ReminderCreateDialog";
import ReminderUpdateDialog from "apps/crm/domains/12-calendar/subdomains/reminders/pages/ReminderUpdateDialog";
import { ReminderDeleteDialog } from "apps/crm/domains/12-calendar/subdomains/reminders/pages/ReminderDeleteDialog";
import NoteCreateDialog from "apps/crm/domains/14-notes/subdomains/notes/pages/NoteCreateDialog";
import NoteUpdateDialog from "apps/crm/domains/14-notes/subdomains/notes/pages/NoteUpdateDialog";
import { NoteDeleteDialog } from "apps/crm/domains/14-notes/subdomains/notes/pages/NoteDeleteDialog";
import { IContactItem, LeadContactDetailDialog } from "./LeadContactDetailDialog";
import BgUserDetail from "assets/images/bg-user-detail.jpg";
import { useReminderListQuery, useReminderUpdateMutation } from "apps/crm/domains/12-calendar/subdomains/reminders";


const LeadDetail = memo(() => {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  if (!id) {
    navigate(listLeadRoute());
  }
  const [selectedTab, setSelectedTab] = useState(t("crm.lead.lead.info"));
  const [openLeadUpdateDrawer, setOpenLeadUpdateDrawer] = useState(false);
  const [openCreateEstimate, setOpenCreateEstimate] = useState(false);
  const [openCreateProposal, setOpenCreateProposal] = useState(false);
  const [openAddLeadContact, setOpenAddLeadContact] = useState(false);

  // queries
  const { data: leadData } = useLeadDetailQuery({
    input: {
      _id: id || ""
    }
  });


  return <Grid item xs={12}>
    <HeaderBase
      title={t("crm.lead.lead.leadDetail")}
      showBackButton={true}
    />

    <ProfileHeader
      leadData={leadData}
      selectedTab={selectedTab}
      setSelectedTab={setSelectedTab}
    />

    <InfoSection
      leadData={leadData}
      selectedTab={selectedTab}
      setOpenLeadUpdateDrawer={setOpenLeadUpdateDrawer}
    />

    <ContactHistorySection
      leadData={leadData}
      selectedTab={selectedTab}
      setOpenAddLeadContact={setOpenAddLeadContact}
    />

    <EstimatesSection
      id={id ?? ""}
      selectedTab={selectedTab}
      setOpenCreateEstimate={setOpenCreateEstimate}
    />

    <ProposalsSection
      id={id ?? ""}
      selectedTab={selectedTab}
      setOpenCreateProposal={setOpenCreateProposal}
    />

    {/* <TasksSection selectedTab={selectedTab} /> */}

    <DocumentsSection
      selectedTab={selectedTab}
    />

    <NotesSection
      leadData={leadData}
      selectedTab={selectedTab}
    />

    <RemindersSection
      selectedTab={selectedTab}
      leadData={leadData}
    />

    <ActivitiesSection
      selectedTab={selectedTab}
    />

    <Overlays
      openLeadUpdateDrawer={openLeadUpdateDrawer}
      setOpenLeadUpdateDrawer={setOpenLeadUpdateDrawer}
      leadData={leadData}
      openAddLeadContact={openAddLeadContact}
      setOpenAddLeadContact={setOpenAddLeadContact}
      openCreateEstimate={openCreateEstimate}
      setOpenCreateEstimate={setOpenCreateEstimate}
      openCreateProposal={openCreateProposal}
      setOpenCreateProposal={setOpenCreateProposal}
    />
  </Grid >
});



const ProfileHeader = (props: {
  leadData: ILeadDetailResult | undefined
  setSelectedTab: (value: string) => void
  selectedTab: string
}) => {

  const { t } = useTranslation();

  return <Stack direction={"column"} mt={2} borderRadius={"20px"} sx={{ boxShadow: "0px 0px 20px #ccc" }} >
    <Box
      width={"100%"}
      component={"img"}
      src={BgUserDetail}
      sx={{ borderTopLeftRadius: "20px", borderTopRightRadius: "20px", height: "200px", filter: "brightness(0.8)", }}
    />
    <Stack direction={{ xs: "column", md: "row" }} justifyContent={"space-between"} alignItems={"center"} mx={2}>
      <Stack direction={"row"} alignItems={"center"} sx={{ mt: { xs: "-150px", md: "-120px" }, mb: { xs: "30px", md: "0px" } }}>
        <Avatar
          sx={{ width: "120px", height: "120px", ml: 2, border: "3px solid #fff", objectFit: "cover", boxShadow: "0px 0px 5px #bbb", fontSize: "40px" }}
          alt="Remy Sharp"
        >
          {props.leadData?.fullName?.split(" ")?.map(n => n[0]).join("").toUpperCase()}
        </Avatar>
        <Stack direction={"column"}>
          <Typography fontSize={"24px"} fontWeight={600} ml={4} sx={{ zIndex: 1 }} color={"#fff"}>{props.leadData?.fullName} </Typography>
          <Typography fontSize={"14px"} fontWeight={600} ml={4} sx={{ zIndex: 1 }} color={"#fff"}>{props.leadData?.department?._id} </Typography>
        </Stack>
      </Stack>
      <Stack width={{ xs: "100%", sm: "50%",  xl: "auto" }}>
        <MyTab
          labels={[
            t("crm.lead.lead.info"),
            t("crm.lead.lead.contactHistory"),
            t("crm.lead.lead.estimates"),
            t("crm.lead.lead.proposals"),
            t("crm.lead.lead.documents"),
            t("crm.lead.lead.notes"),
            t("crm.lead.lead.reminders"),
            t("crm.lead.lead.activities"),
          ]}
          setSelectedTab={props.setSelectedTab}
          selectedTab={props.selectedTab}
        />
      </Stack>

    </Stack >

  </Stack >
}

const InfoSection = (props: {
  leadData: ILeadDetailResult | undefined;
  selectedTab: string;
  setOpenLeadUpdateDrawer: (value: boolean) => void;
}) => {

  const localizedLeadQualifications = convertEnums(LeadQualification);
  const localizedLeadTypes = convertEnums(LeadType);
  const localizedLanguages = convertEnums(Language);

  return props.selectedTab === t("crm.lead.lead.info") ? <Grid container mt={2} gap={2} mb={2}>
    <Grid item xs={12}>
      <DetailBox
        title={t("crm.lead.lead.about")}
        rightButton={{
          title: t("update"),
          icon: <Update sx={{ mr: 1 }} />,
          onClick: () => props.setOpenLeadUpdateDrawer(true)
        }}
      >
        <Grid item display="flex" flexDirection="column" gap={1}>
          {[
            t("crm.lead.lead.email"),
            t("crm.lead.lead.phoneNumber"),
            t("crm.lead.lead.type"),
            t("crm.lead.lead.status"),
            t("crm.lead.lead.source"),
            t("crm.lead.lead.qualification"),
          ].map((label, index) => (
            <Box display="flex" alignItems="center" minHeight={35} key={index}>
              <Typography fontSize={"14px"} fontWeight={600}>
                {label}
              </Typography>
            </Box>
          ))}
        </Grid>
        <Grid item display="flex" flexDirection="column" gap={1}>
          {[
            props.leadData?.email,
            (props.leadData as any)?.phoneNumber,
            localizedLeadTypes[props.leadData?.type as keyof typeof localizedLeadTypes],
            props.leadData?.status?.name,
            props.leadData?.source?.name,
            localizedLeadQualifications[(props.leadData as any)?.qualification as keyof typeof localizedLeadQualifications],
          ].map((value, index) => (
            <Box display="flex" alignItems="center" minHeight={35} key={index}>
              <Typography fontSize={"14px"} fontWeight={400}>
                : {value}
              </Typography>
            </Box>
          ))}
        </Grid>
      </DetailBox>
    </Grid>
    <Grid item xs={12}>
      <DetailBox
        title={t("crm.lead.lead.company")}
        rightButton={{
          title: t("update"),
          icon: <Update sx={{ mr: 1 }} />,
          onClick: () => props.setOpenLeadUpdateDrawer(true)
        }}
      >
        <Grid item display="flex" flexDirection="column" gap={1}>
          {[
            t("crm.lead.lead.companyName"),
            t("crm.lead.lead.position"),
            t("crm.lead.lead.sector"),
            t("crm.lead.lead.website"),
          ].map((label, index) => (
            <Box display="flex" alignItems="center" minHeight={35} key={index}>
              <Typography fontSize={"14px"} fontWeight={600}>
                {label}
              </Typography>
            </Box>
          ))}
        </Grid>

        <Grid item display="flex" flexDirection="column" gap={1}>
          {[
            props.leadData?.company,
            props.leadData?.position,
            props.leadData?.sector,
            props.leadData?.website
          ].map((value, index) => (
            <Box display="flex" alignItems="center" minHeight={35} key={index}>
              <Typography fontSize={"14px"} fontWeight={400}>
                : {value}
              </Typography>
            </Box>
          ))}
        </Grid>
      </DetailBox>
    </Grid>
    <Grid item xs={12}>
      <DetailBox
        title={t("crm.lead.lead.localization")}
        rightButton={{
          title: t("update"),
          icon: <Update sx={{ mr: 1 }} />,
          onClick: () => props.setOpenLeadUpdateDrawer(true)
        }}>
        <Grid item display="flex" flexDirection="column" gap={1}>
          {[
            t("crm.lead.lead.country"),
            t("crm.lead.lead.state"),
            t("crm.lead.lead.city"),
            t("crm.lead.lead.language"),
          ].map((label, index) => (
            <Box display="flex" alignItems="center" minHeight={35} key={index}>
              <Typography fontSize={"14px"} fontWeight={600}>
                {label}
              </Typography>
            </Box>
          ))}
        </Grid>

        <Grid item display="flex" flexDirection="column" gap={1}>
          {[
            props.leadData?.country,
            props.leadData?.state,
            props.leadData?.city,
            localizedLanguages[props.leadData?.language as keyof typeof localizedLanguages]

          ].map((value, index) => (
            <Box display="flex" alignItems="center" minHeight={35} key={index}>
              <Typography fontSize={"14px"} fontWeight={400}>
                : {value}
              </Typography>
            </Box>
          ))}
        </Grid>
      </DetailBox>
    </Grid>
  </Grid > : null
}

const ContactHistorySection = (props: {
  leadData: ILeadDetailResult | undefined,
  selectedTab: string,
  setOpenAddLeadContact: (value: boolean) => void
}) => {

  const { t } = useTranslation();
  const contactHistory = props.leadData?.contactHistory ?? [];
  const [selectedRow, setSelectedRow] = useState<IContactItem | undefined>(undefined);
  const [openContactDetail, setOpenContactDetail] = useState(false);

  console.log(openContactDetail);

  return props.selectedTab === t("crm.lead.lead.contactHistory") ? <Grid container mt={2} gap={2} mb={2}>
    <Grid item xs={12}>
      <DetailBox
        title={t("crm.lead.lead.contactHistory")}
        rightButton={{
          title: t("crm.lead.lead.addContact"),
          icon: <Icons.AddCircle sx={{ mr: 1 }} />,
          onClick: () => props.setOpenAddLeadContact(true)
        }}
      >
        <Grid item xs={12} display="flex" flexDirection="column" gap={1}>
          <Stack direction="row" justifyContent="space-between" height={"100%"}>
            {contactHistory?.length > 0 ? <BaseDataGrid
              rows={
                contactHistory.map((contact, index) => ({
                  id: (index + 1),
                  _id: contact.contacted?._id,
                  name: contact?.contacted?.name + " " + contact?.contacted?.surname,
                  note: representHtmlAsString(contact?.note),
                  contactedDate: representDateTimeAsString(contact?.contactedDate)
                }))
              }
              columns={[
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: "id",
                      disableColumnMenu: true,
                      filterable: false,
                      width: 40,
                    },
                    headerConfig: {
                      name: "#",
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: "name",
                      editable: false,
                      filterable: false,
                      width: 100,
                    },
                    headerConfig: {
                      name: t("crm.lead.lead.name"),
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: "note",
                      editable: false,
                      filterable: false,
                      width: 350,
                    },
                    headerConfig: {
                      name: t("crm.lead.lead.note"),
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: "contactedDate",
                      filterable: false,
                      width: 120,
                    },
                    headerConfig: {
                      name: t("crm.lead.lead.contactedDate"),
                    },
                  },
                },
              ]}
              actionColumn={{
                width: 80,
                defaultActions: {
                  view: {
                    clickConfig: {
                      setSelectedRow: (item) => setSelectedRow(item),
                      setOpenAction: () => setOpenContactDetail(true),
                    },
                  },
                },
                actionHeaderName: t("crm.actions"),
              }}
              loading={false}
              listFilter={{
                filterInput: {},
                setFilterInput: () => null,
              }}
              count={contactHistory.length}
              config={{
                columnVisibilityModel: {},
                features: [],
              }}
              update={{
                updateQuery: () => null,
              }}
              disableColumnFilter={true}
              hideFooterPagination={true}
              sortingMode={"client"}
            /> :
              <EmptyState content1={t("crm.lead.lead.emptyStateContactContent1")} />
            }
          </Stack>
        </Grid>
      </DetailBox>
    </Grid>
    <LeadContactDetailDialog
      open={openContactDetail}
      item={selectedRow}
      onClose={() => setOpenContactDetail(false)}
    />
  </Grid > : null
}

const EstimatesSection = (props: {
  id: string,
  selectedTab: string,
  setOpenCreateEstimate: (value: boolean) => void
}) => {

  const { data: estimateListData, isLoading: estimateListLoading } = useLeadEstimateListQuery({
    input: {
      filter: {
        leadId: props.id
      }
    }
  }, {
    skip: !props.id || props.selectedTab !== t("crm.lead.lead.estimates"),
  });

  return props.selectedTab === t("crm.lead.lead.estimates") ? <Grid container mt={2} gap={2} mb={2}>
    <Grid item xs={12}>
      <DetailBox
        title={t("crm.lead.lead.estimates")}
        rightButton={{
          title: t("crm.lead.lead.addEstimate"),
          icon: <Icons.AddCircle sx={{ mr: 1 }} />,
          onClick: () => props.setOpenCreateEstimate(true)
        }}
      >
        <Grid item xs={12} display="flex" flexDirection="column" gap={1}>
          <Stack direction="row" justifyContent="space-between" pb={2} height={"100%"}>
            {estimateListData ?
              estimateListData?.count > 0
                ? <BaseDataGrid
                  rows={
                    estimateListData?.data?.map((estimate, index) => ({
                      id: (index + 1),
                      prefix: representString(estimate?.prefix),
                      createdAt: representDateAsString(estimate?.createdAt),
                    }))
                  }
                  columns={[
                    {
                      config: {
                        baseGridColType: BaseGridColType.text,
                        column: {
                          field: "id",
                          disableColumnMenu: true,
                          filterable: false,
                          width: 40,
                        },
                        headerConfig: {
                          name: "#",
                        },
                      },
                    },
                    {
                      config: {
                        baseGridColType: BaseGridColType.text,
                        column: {
                          field: "prefix",
                          editable: false,
                          filterable: false,
                          width: 100,
                        },
                        headerConfig: {
                          name: t("crm.lead.lead.prefix"),
                        }
                      }
                    },
                    {
                      config: {
                        baseGridColType: BaseGridColType.text,
                        column: {
                          field: "createdAt",
                          editable: false,
                          filterable: false,
                          width: 140,
                        },
                        headerConfig: {
                          name: t("crm.lead.lead.createdAt"),
                        }
                      }
                    }
                  ]}
                  actionColumn={{
                    width: 80,
                    defaultActions: {
                      view: {
                        clickConfig: {
                          setSelectedRow: () => null,
                          setOpenAction: () => null,
                        },
                      },
                      edit: {
                        clickConfig: {
                          setSelectedRow: () => null,
                          setOpenAction: () => null,
                        },
                      },
                      delete: {
                        clickConfig: {
                          setSelectedRow: () => null,
                          setOpenAction: () => null,
                        },
                      },
                    },
                    actionHeaderName: t("crm.actions"),
                  }}
                  loading={estimateListLoading}
                  listFilter={{
                    filterInput: {},
                    setFilterInput: () => null,
                  }}
                  count={estimateListData?.count ?? 0}
                  config={{
                    columnVisibilityModel: {
                      roles: false,
                    },
                    features: [],
                  }}
                  update={{
                    updateQuery: () => null,
                  }}
                  disableColumnFilter={true}
                  hideFooterPagination={true}
                  sortingMode={"client"}
                />

                : <EmptyState content1={t("crm.lead.lead.noEstimateContent1")} /> : null}
          </Stack>
        </Grid>
      </DetailBox>
    </Grid>
  </Grid > : null
}

const ProposalsSection = (props: {
  id: string,
  selectedTab: string,
  setOpenCreateProposal: (value: boolean) => void
}) => {

  const { data: proposalListData, isLoading: proposalListLoading } = useLeadProposalListQuery({
    input: {
      filter: {
        leadId: props.id
      }
    }
  }, {
    skip: !props.id || props.selectedTab !== t("crm.lead.lead.proposals")
  });


  return props.selectedTab === t("crm.lead.lead.proposals") ? <Grid container mt={2} gap={2} mb={2}>
    <Grid item xs={12}>
      <DetailBox
        title={t("crm.lead.lead.proposals")}
        rightButton={{
          title: t("crm.lead.lead.addProposal"),
          icon: <Icons.AddCircle sx={{ mr: 1 }} />,
          onClick: () => props.setOpenCreateProposal(true)
        }}
      >
        <Grid item xs={12} display="flex" flexDirection="column" gap={1}>
          <Stack direction="row" justifyContent="space-between" pb={2} height={"100%"}>
            {proposalListData ? proposalListData?.count > 0
              ? <BaseDataGrid
                rows={
                  proposalListData?.data?.map((proposal, index) => ({
                    id: (index + 1),
                    prefix: representString(proposal?.prefix),
                    createdAt: representDateAsString(proposal?.createdAt),
                  }))
                }
                columns={[
                  {
                    config: {
                      baseGridColType: BaseGridColType.text,
                      column: {
                        field: "id",
                        disableColumnMenu: true,
                        filterable: false,
                        width: 40,
                      },
                      headerConfig: {
                        name: "#",
                      },
                    },
                  },
                  {
                    config: {
                      baseGridColType: BaseGridColType.text,
                      column: {
                        field: "prefix",
                        editable: false,
                        filterable: false,
                        width: 100,
                      },
                      headerConfig: {
                        name: t("crm.lead.lead.prefix"),
                      }
                    }
                  },
                  {
                    config: {
                      baseGridColType: BaseGridColType.text,
                      column: {
                        field: "createdAt",
                        editable: false,
                        filterable: false,
                        width: 140,
                      },
                      headerConfig: {
                        name: t("crm.lead.lead.createdAt"),
                      }
                    }
                  }
                ]}
                actionColumn={{
                  width: 80,
                  defaultActions: {
                    view: {
                      clickConfig: {
                        setSelectedRow: () => null,
                        setOpenAction: () => null,
                      },
                    },
                    edit: {
                      clickConfig: {
                        setSelectedRow: () => null,
                        setOpenAction: () => null,
                      },
                    },
                    delete: {
                      clickConfig: {
                        setSelectedRow: () => null,
                        setOpenAction: () => null,
                      },
                    },
                  },
                  actionHeaderName: t("crm.actions"),
                }}
                loading={proposalListLoading}
                listFilter={{
                  filterInput: {},
                  setFilterInput: () => null,
                }}
                count={proposalListData?.count ?? 0}
                config={{
                  columnVisibilityModel: {
                    roles: false,
                  },
                  features: [],
                }}
                update={{
                  updateQuery: () => null,
                }}
                disableColumnFilter={true}
                hideFooterPagination={true}
                sortingMode={"client"}
              />

              : <EmptyState content1={t("crm.lead.lead.noProposalContent1")} /> : null}
          </Stack>
        </Grid>
      </DetailBox>
    </Grid>
  </Grid > : null
}

const TasksSection = (props: {
  selectedTab: string,
}) => {
  return props.selectedTab === "Tasks" ? <Grid container mt={2} gap={2} mb={2}>
    <Grid item xs={12}>
      <DetailBox title="Tasks">
        <Grid item display="flex" flexDirection="column" gap={1}>
          <Box height={40} />
        </Grid>
      </DetailBox>
    </Grid>
  </Grid > : null
}

const DocumentsSection = (props: {
  selectedTab: string,
}) => {
  return props.selectedTab === t("crm.lead.lead.documents") ? <Grid container mt={2} gap={2} mb={2}>
    <Grid item xs={12}>
      <DetailBox title={t("crm.lead.lead.documents")}>
        <Grid item xs={12} display="flex" flexDirection="column" gap={1}>
          <EmptyState content1={t("crm.lead.lead.noDocumentContent1")} />
        </Grid>
      </DetailBox>
    </Grid>
  </Grid > : null
}


const NotesSection = (props: {
  selectedTab: string,
  leadData?: ILeadDetailResult
}) => {

  const [openNoteCreateDialog, setOpenNoteCreateDialog] = useState(false);
  const [openNoteUpdateDialog, setOpenNoteUpdateDialog] = useState(false);
  const [openNoteDeleteDialog, setOpenNoteDeleteDialog] = useState(false);
  const [selectedLeadNote, setSelectedLeadNote] = useState<INoteListItemResult>();
  const { data: noteListData, isLoading: noteListLoading } = useLeadNoteListQuery({
    input: {
      filter: {
        targetIds: [props.leadData?._id ?? ""]
      }
    }
  }, {
    skip: !props.leadData?._id || props.selectedTab !== t("crm.lead.lead.notes")
  });
  const [leadNoteUpdate] = useNoteUpdateMutation();


  return props.selectedTab === t("crm.lead.lead.notes") ?
    <Grid container mt={2} gap={2} mb={2}>
      <Grid item xs={12}>
        <DetailBox title={t("crm.lead.lead.notes")}
          rightButton={{
            title: t("crm.lead.lead.addNote"),
            onClick: () => setOpenNoteCreateDialog(true),
            icon: <Icons.AddCircle sx={{ mr: 1 }} />,
          }}>
          <Grid item xs={12} display="flex" flexDirection="column" gap={1}>
            <Stack direction="row" justifyContent="space-between" height={"100%"} mb={2}>
              {noteListData ?
                noteListData?.count > 0 ? <BaseDataGrid
                  rows={
                    noteListData?.data?.map((note, index) => ({
                      id: (index + 1),
                      _id: note?._id,
                      title: representString(note?.title),
                      content: representString(note?.content),
                      createdAt: representDateTimeAsString(note?.createdAt),
                    }))
                  }
                  columns={[
                    {
                      config: {
                        baseGridColType: BaseGridColType.text,
                        column: {
                          field: "id",
                          disableColumnMenu: true,
                          filterable: false,
                          width: 40,
                          align: "center",
                        },
                        headerConfig: {
                          name: "#",
                          align: "center",
                        },
                      },
                    },
                    {
                      config: {
                        baseGridColType: BaseGridColType.text,
                        column: {
                          field: "title",
                          editable: false,
                          filterable: false,
                        },
                        headerConfig: {
                          name: t("crm.lead.lead.noteTitle"),
                        },
                      },
                    },
                    {
                      config: {
                        baseGridColType: BaseGridColType.text,
                        column: {
                          field: "content",
                          editable: false,
                          filterable: false,
                          width: 250,
                        },
                        headerConfig: {
                          name: t("crm.lead.lead.content"),
                        },
                      },
                    },
                    {
                      config: {
                        baseGridColType: BaseGridColType.text,
                        column: {
                          field: "createdAt",
                          editable: false,
                          filterable: false,
                        },
                        headerConfig: {
                          name: t("crm.lead.lead.createdAt"),
                        },
                      },
                    },
                  ]}
                  actionColumn={{
                    width: 70,
                    defaultActions: {
                      edit: {
                        clickConfig: {
                          setSelectedRow: setSelectedLeadNote,
                          setOpenAction: setOpenNoteUpdateDialog,
                        },
                      },
                      delete: {
                        clickConfig: {
                          setSelectedRow: setSelectedLeadNote,
                          setOpenAction: setOpenNoteDeleteDialog,
                        },
                      },
                    },
                    actionHeaderName: t("crm.actions"),
                  }}
                  loading={noteListLoading}
                  listFilter={{
                    filterInput: {},
                    setFilterInput: () => null,
                  }}
                  count={noteListData?.count}
                  config={{
                    columnVisibilityModel: {},
                    features: []
                  }}
                  update={{
                    updateQuery: noteUpdateQuery,
                  }}
                  disableColumnFilter={true}
                  hideFooterPagination={true}
                /> :
                  <EmptyState content1={t("crm.lead.lead.emptyStateNoteList1")} />
                : null
              }
            </Stack>
          </Grid>
        </DetailBox>
      </Grid>

      <NoteCreateDialog
        open={openNoteCreateDialog}
        setOpen={setOpenNoteCreateDialog}
        target={{ id: props.leadData?._id ?? "", name: props.leadData?.fullName ?? "" }}
        targetType={NoteTargetType.Lead}
      />

      <NoteUpdateDialog
        open={openNoteUpdateDialog}
        setOpen={setOpenNoteUpdateDialog}
        target={{ id: props.leadData?._id ?? "", name: props.leadData?.fullName ?? "" }}
        selectedNote={selectedLeadNote}
        targetType={NoteTargetType.Lead}
      />

      <NoteDeleteDialog
        open={openNoteDeleteDialog}
        onClose={() => {
          setSelectedLeadNote(undefined);
          setOpenNoteDeleteDialog(false);
        }}
        item={selectedLeadNote}
      />

    </Grid >
    : null
}

const RemindersSection = (props: {
  selectedTab: string,
  leadData?: ILeadDetailResult
}) => {

  const [openReminderCreateDialog, setOpenReminderCreateDialog] = useState(false);
  const [openReminderUpdateDialog, setOpenReminderUpdateDialog] = useState(false);
  const [openReminderDeleteDialog, setOpenReminderDeleteDialog] = useState(false);
  const [selectedLeadReminder, setSelectedLeadReminder] = useState<IReminderListItemResult>();
  const { data: reminderListData, isLoading: reminderListLoading } = useReminderListQuery({
    input: {
      filter: {
        targetIds: [props.leadData?._id ?? ""],
        targetTypes: [ReminderTargetType.Lead],
      }
    }
  }, {
    skip: !props.leadData?._id || props.selectedTab !== t("crm.lead.lead.reminders")
  });
  const [reminderUpdate] = useReminderUpdateMutation();


  return props.selectedTab === t("crm.lead.lead.reminders") ?
    <Grid container mt={2} gap={2} mb={2}>
      <Grid item xs={12}>
        <DetailBox title={t("crm.lead.lead.reminders")}
          rightButton={{
            title: t("crm.lead.lead.addReminder"),
            onClick: () => setOpenReminderCreateDialog(true),
            icon: <Icons.AddCircle sx={{ mr: 1 }} />,
          }}>
          <Grid item xs={12} display="flex" flexDirection="column" gap={1}>
            <Stack direction="row" justifyContent="space-between" height={"100%"} mb={2}>
              {reminderListData ?
                reminderListData?.count > 0 ? <BaseDataGrid
                  rows={
                    reminderListData?.data?.map((reminder, index) => ({
                      id: (index + 1),
                      _id: reminder?._id,
                      title: representString(reminder?.title),
                      content: representString(reminder?.content),
                      priority: reminder?.priority,
                      remindDate: representDateTimeAsString(reminder?.remindDate),
                      remindUsers: reminder?.remindUsers,
                      status: reminder?.status,
                      tags: reminder?.tags,
                    }))
                  }
                  columns={[
                    {
                      config: {
                        baseGridColType: BaseGridColType.text,
                        column: {
                          field: "id",
                          disableColumnMenu: true,
                          filterable: false,
                          width: 40,
                          align: "center",
                        },
                        headerConfig: {
                          name: "#",
                          align: "center",
                        },
                      },
                    },
                    {
                      config: {
                        baseGridColType: BaseGridColType.text,
                        column: {
                          field: "title",
                          editable: false,
                          filterable: false,
                        },
                        headerConfig: {
                          name: t("crm.lead.lead.remindTitle"),
                        },
                      },
                    },
                    {
                      config: {
                        baseGridColType: BaseGridColType.text,
                        column: {
                          field: "content",
                          editable: false,
                          filterable: false,
                          width: 250,
                        },
                        headerConfig: {
                          name: t("crm.lead.lead.content"),
                        },
                      },
                    },
                    {
                      config: {
                        baseGridColType: BaseGridColType.enum,
                        column: {
                          field: "priority",
                          editable: true,
                          filterable: false,
                          width: 90,
                        },
                        headerConfig: {
                          name: t("crm.lead.lead.priority"),
                          creatable: false,
                        },
                      },
                      data: ReminderPriority,
                    },
                    {
                      config: {
                        baseGridColType: BaseGridColType.text,
                        column: {
                          field: "remindDate",
                          filterable: false,
                          editable: false,
                          width: 120,
                        },
                        headerConfig: {
                          name: t("crm.lead.lead.remindDate"),
                        },
                      },
                    },
                    // {
                    //   config: {
                    //     baseGridColType: BaseGridColType.object,
                    //     column: {
                    //       field: "remindUsers",
                    //       editable: false,
                    //       filterable: false,
                    //       width: 130,
                    //     },
                    //     headerConfig: {
                    //       name: t("crm.lead.lead.remindUsers"),
                    //     },
                    //     filterConfig: {
                    //       customFieldName: `remindUsers`,
                    //       isArrayFilter: true,
                    //     },
                    //   },
                    // },
                    {
                      config: {
                        baseGridColType: BaseGridColType.enum,
                        column: {
                          field: "status",
                          editable: true,
                          filterable: false,
                          width: 120,
                        },
                        headerConfig: {
                          name: t("crm.lead.lead.status"),
                          creatable: false,
                        },
                      },
                      data: ReminderStatus,
                    },
                  ]}
                  actionColumn={{
                    width: 70,
                    defaultActions: {
                      edit: {
                        clickConfig: {
                          setSelectedRow: setSelectedLeadReminder,
                          setOpenAction: setOpenReminderUpdateDialog,
                        },
                      },
                      delete: {
                        clickConfig: {
                          setSelectedRow: setSelectedLeadReminder,
                          setOpenAction: setOpenReminderDeleteDialog,
                        },
                      },
                    },
                    actionHeaderName: t("crm.actions"),
                  }}
                  loading={reminderListLoading}
                  listFilter={{
                    filterInput: {},
                    setFilterInput: () => null,
                  }}
                  count={reminderListData?.count}
                  config={{
                    columnVisibilityModel: {},
                    features: []
                  }}
                  update={{
                    updateQuery: reminderUpdate,
                  }}
                  disableColumnFilter={true}
                  hideFooterPagination={true}
                /> :
                  <EmptyState content1={t("crm.lead.lead.emptyStateReminderList1")} />
                : null
              }



            </Stack>
          </Grid>
        </DetailBox>
      </Grid>

      <ReminderCreateDialog
        open={openReminderCreateDialog}
        setOpen={setOpenReminderCreateDialog}
        target={{ id: props.leadData?._id ?? "", name: props.leadData?.fullName ?? "" }}
        targetType={ReminderTargetType.Lead}
      />

      <ReminderUpdateDialog
        open={openReminderUpdateDialog}
        setOpen={setOpenReminderUpdateDialog}
        target={{ id: props.leadData?._id ?? "", name: props.leadData?.fullName ?? "" }}
        selectedReminder={selectedLeadReminder}
        targetType={ReminderTargetType.Lead}
      />

      <ReminderDeleteDialog
        open={openReminderDeleteDialog}
        onClose={() => {
          setSelectedLeadReminder(undefined);
          setOpenReminderDeleteDialog(false);
        }}
        item={selectedLeadReminder}
      />

    </Grid >
    : null
}

const ActivitiesSection = (props: {
  selectedTab: string
}) => {
  return props.selectedTab === t("crm.lead.lead.activities") ? <Grid container mt={2} gap={2} mb={2}>
    <Grid item xs={12}>
      <DetailBox title={t("crm.lead.lead.activities")}>
        <Grid item xs={12} display="flex" flexDirection="column" gap={1}>
          <EmptyState content1={t("crm.lead.lead.noActivityContent1")} />
        </Grid>
      </DetailBox>
    </Grid>
  </Grid > : null
}


const Overlays = ({
  openLeadUpdateDrawer,
  setOpenLeadUpdateDrawer,
  leadData,
  openAddLeadContact,
  setOpenAddLeadContact,
  openCreateEstimate,
  setOpenCreateEstimate,
  openCreateProposal,
  setOpenCreateProposal
}: any) => {

  const [leadStatusCreateOpen, setLeadStatusCreateOpen] = useState(false);
  const [leadSourceCreateOpen, setLeadSourceCreateOpen] = useState(false);

  return <>
    <LeadUpdateDrawerWrapper
      open={openLeadUpdateDrawer}
      setOpen={setOpenLeadUpdateDrawer}
      selectedLead={leadData}
      leadStatusCreateOpen={leadStatusCreateOpen}
      setLeadStatusCreateOpen={setLeadStatusCreateOpen}
      leadSourceCreateOpen={leadSourceCreateOpen}
      setLeadSourceCreateOpen={setLeadSourceCreateOpen}
    />

    <LeadStatusCreateDialog
      open={leadStatusCreateOpen}
      setOpen={setLeadStatusCreateOpen}
    />

    <LeadSourcesCreateDialog
      open={leadSourceCreateOpen}
      setOpen={setLeadSourceCreateOpen}
    />

    <AddLeadContactDialog
      open={openAddLeadContact}
      onClose={() => setOpenAddLeadContact(false)}
      item={leadData}
    />

    <EstimateCreateWraper
      open={openCreateEstimate}
      setOpen={setOpenCreateEstimate}
      item={leadData}
    />

    <ProposalCreateWraper
      open={openCreateProposal}
      setOpen={setOpenCreateProposal}
    />
  </>
}



export default LeadDetail;