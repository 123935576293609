import { Add, ChevronRight, ExpandMore, Facebook, FileDownload, FilterList, Instagram, IosShareOutlined, LinkedIn, List, RemoveRedEye, Tune, YouTube } from "@mui/icons-material"
import { Box, Card, Divider, Grow, Stack, Switch, SxProps, Theme, Typography, useMediaQuery } from "@mui/material"
import { Male1, Female3, Female10, Male10 } from "assets/images/avatars"
import { MouseEventHandler, useState } from "react"
import LogoMini from "assets/images/logo-mini.png"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import moment from "moment"
import { FilledButton, OutlinedButton } from "components"
import { LineChartPro } from "@mui/x-charts-pro"
import { useOrganizationDetailOwnQuery } from "apps/auth/context"
import { useTranslation } from "react-i18next"

interface IHomeForActive { }


const HomeForActive = (props: IHomeForActive) => {
    return <Stack direction={"column"} gap={2} mr={{ xs: "-16px", md: "0px" }}>
        <TopLine />
        <HeaderSection />
        <RevenueSection />
        <RevenueBottomLine />
        <BodySection />
    </Stack>
}


const TopLine = () => {


    const topLineData = [
        {
            avatar: Male1,
            name: "John Doe",
        },
        {
            avatar: Female3,
            name: "Jane Doe",
        },
        {
            avatar: Female10,
            name: "Marry Doe",
        },
    ]

    const TopLineBox = (props: {
        children: React.ReactNode,
        sx?: SxProps<Theme>,
        handleClick?: MouseEventHandler<HTMLDivElement>
    }) => {
        return <Box
            sx={{
                ...props.sx,
                height: "20px",
                borderRadius: 3,
                border: "1px solid",
                borderColor: "grey.400",
                bgcolor: "background.thirdWithBlur",
                backdropFilter: "blur(3px)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 0.5,
                p: 0.5,
                cursor: "pointer",
                transition: "all 0.5s ease",
                ':hover': {
                    transition: "all 0.5s ease",
                    bgcolor: "primary.secondWithBlur",
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
                },
            }}
            onClick={props.handleClick}
        >
            {props.children}
        </Box>
    }

    return <Grow in={true} timeout={500} >
        <Stack direction={"row"} justifyContent={{ xs: "flex-end", sm: "space-between" }} alignItems={"center"}>
            <Stack display={{ xs: "none", sm: "flex" }} direction={"row"} justifyContent={"flex-start"} alignItems={"center"} gap={1}>
                <TopLineBox sx={{ width: "20px" }} handleClick={() => { }}>
                    <Add sx={{ fontSize: "22px", color: "primary.main" }} />
                </TopLineBox>
                {topLineData?.map((item, index) => (
                    <TopLineBox key={index} handleClick={() => { }}>
                        <img style={{ width: "20px", height: "20px", borderRadius: "20px" }} src={item.avatar} alt={item.name} />
                        <Typography sx={{ display: { xs: "none", sm: "flex" } }}
                            fontSize={"11px"} fontWeight={600}>
                            {item.name}
                        </Typography>
                    </TopLineBox>
                ))}
                <TopLineBox sx={{ width: "20px" }} handleClick={() => { }}>
                    <img style={{ width: "20px", height: "20px", borderRadius: "20px" }} src={LogoMini} alt="logo" />
                </TopLineBox>
            </Stack>

            <Stack direction={"row"} justifyContent={"flex-end"} alignItems={"center"} gap={1} >
                <TopLineBox sx={{ width: "20px" }} handleClick={() => { }}>
                    <Tune sx={{ fontSize: "18px", color: "primary.main" }} />
                </TopLineBox>
                <TopLineBox sx={{ width: "20px" }} handleClick={() => { }}>
                    <FileDownload sx={{ fontSize: "18px", color: "primary.main" }} />
                </TopLineBox>
                <TopLineBox sx={{ width: "20px" }} handleClick={() => { }}>
                    <IosShareOutlined sx={{ fontSize: "18px", color: "primary.main" }} />
                </TopLineBox>
            </Stack>

        </Stack >
    </Grow>
}

const HeaderSection = () => {


    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);


    const { data: organizationDetailData } = useOrganizationDetailOwnQuery({})

    const handleOpenPicker = () => {
        setIsOpen(true);
    };

    const handleClosePicker = () => {
        setIsOpen(false);
    };


    return <Grow in={true} timeout={700} >
        <Stack direction={{xs: "column", md: "row"}} justifyContent={"space-between"} alignItems={"center"}>
            <Stack direction={{xs:"column", md:"row"}} gap={1} alignItems={{xs: "center", md:"flex-end"}} justifyContent={"flex-start"} my={1}>

                <Typography fontSize={"28px"} lineHeight={"28px"} color={"GrayText"} fontWeight={600}> {t("crm.dashboard.dashboard.welcome")} </Typography>
                <Typography fontSize={"36px"} lineHeight={"28px"} color={"text.primary"} fontWeight={600}>{organizationDetailData?.name}</Typography>
                <Typography fontSize={"28px"} lineHeight={"28px"} color={"GrayText"} fontWeight={600}>(Demo)</Typography>

            </Stack>
            <Stack direction={"row"} gap={1} alignItems={"center"} justifyContent={"flex-end"}>
                <Stack direction={"row"} alignItems={"center"}>
                    <Switch defaultChecked size="small" />
                    <Typography fontSize={"12px"}>Timeframe</Typography>
                </Stack>
                <DatePicker
                    open={isOpen}
                    onOpen={handleOpenPicker}
                    onClose={handleClosePicker}
                    slots={{
                        openPickerIcon: (props) => <ExpandMore {...props} />
                    }}
                    slotProps={{
                        textField: {
                            onClick: handleOpenPicker,
                            size: 'small', sx: {
                                cursor: "pointer",
                                width: "150px", bgcolor: 'background.secondWithBlur',
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        border: 'none',
                                    },
                                    '&:hover fieldset': {
                                        border: 'none'
                                    },
                                    '&.Mui-focused fieldset': {
                                        border: 'none'
                                    },
                                },
                            }
                        }
                    }}
                    value={moment()}
                />
            </Stack>
        </Stack>
    </Grow>
}

const RevenueSection = () => {

    const downSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

    const RevenueChip = (props: {
        children: React.ReactNode,
        sx?: SxProps<Theme>,
        color?: string
    }) => {
        return <Box
            sx={{
                ...props.sx,
                height: "14px",
                borderRadius: 3,
                bgcolor: props.color ?? "secondary.main",
                backdropFilter: "blur(3px)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 0.5,
                px: 1,
                py: 0.5,
                cursor: "pointer",
                transition: "all 0.5s ease",
                ':hover': {
                    transition: "all 0.5s ease",
                    bgcolor: "primary.secondWithBlur",
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
                },
            }}
        >
            {props.children}
        </Box>
    }

    const RevenueCard1 = (props: {
        children: React.ReactNode,
        sx?: SxProps<Theme>,
        dark?: boolean
    }) => {
        return <Box
            sx={{
                ...props.sx,
                borderRadius: 2,
                border: "1px solid",
                borderColor: "grey.400",
                bgcolor: !props.dark ? "background.thirdWithBlur" : "background.contrast",
                backdropFilter: "blur(3px)",
                gap: 0.5,
                p: 2,
                width: "120px",
                height: "60px",
                cursor: "pointer",
                boxShadow: "0px 1px 10px rgba(0, 0, 0, 0.25)",
                transition: "all 0.5s ease",
                ':hover': {
                    transition: "all 0.5s ease",
                    bgcolor: "primary.secondWithBlur",
                    boxShadow: "0px 2px 20px rgba(0, 0, 0, 0.25)",
                },
                position: "relative",
            }}
        >
            <Stack direction={"column"} justifyContent={"space-between"} alignItems={"flex-start"} zIndex={2}>
                {props.children}
            </Stack>
            <Box sx={{ bgcolor: 'grey.400', width: "80%", height: "5px", position: "absolute", top: "-6px", left: "10%", borderTopLeftRadius: 20, borderTopRightRadius: 20 }} zIndex={1} />
            <Box sx={{ bgcolor: 'grey.400', width: "80%", height: "5px", position: "absolute", bottom: "-6px", left: "10%", borderBottomLeftRadius: 20, borderBottomRightRadius: 20 }} zIndex={1} />
        </Box>
    }

    const RevenueCard2 = (props: {
        children: React.ReactNode,
        sx?: SxProps<Theme>,
        color?: string
    }) => {
        return <Box
            sx={{
                ...props.sx,
                borderRadius: 2,
                border: "1px solid",
                borderColor: props.color ?? "grey.400",
                bgcolor: "background.thirdWithBlur",
                backdropFilter: "blur(3px)",
                gap: 0.5,
                p: 1,
                width: "50px",
                height: "76px",
                cursor: "pointer",
                transition: "all 0.5s ease",
                ':hover': {
                    transition: "all 0.5s ease",
                    bgcolor: "primary.secondWithBlur",
                    boxShadow: "0px 2px 20px rgba(0, 0, 0, 0.25)",
                },
                position: "relative",
            }}
        >
            <Stack direction={"column"} height={"100%"} justifyContent={"space-between"} alignItems={"center"}>
                {props.children}
            </Stack>
        </Box>
    }

    return <Grow in={true} timeout={700} >
        <Stack direction={{ xs: "column", lg: "row" }} justifyContent={"space-between"} alignItems={"center"}>
            <Stack direction={"column"}  mb={{xs: 2, md: 0}}>
                <Stack>
                    <Typography fontSize={"20px"} color={"text.primary"} fontWeight={600}>
                        Revenue
                    </Typography>
                </Stack>
                <Stack direction={"row"} gap={1} alignItems={"center"}>
                    <Stack direction={"row"} justifyContent={"flex-start"}>
                        <Typography fontSize={"42px"} color={"text.primary"} fontWeight={600}>$343,645</Typography>
                        <Typography fontSize={"42px"} color={"GrayText"} fontWeight={600}>.35</Typography>
                    </Stack>
                    {downSm ? null : <RevenueChip>
                        <Typography fontSize={"11px"} color={"white"} fontWeight={600}>
                            +12.35%
                        </Typography>
                    </RevenueChip>}
                    {downSm ? null : <RevenueChip>
                        <Typography fontSize={"11px"} color={"white"} fontWeight={600}>
                            $343,645
                        </Typography>
                    </RevenueChip>}
                </Stack>
                <Stack direction={"row"}>
                    <Typography fontSize={"12px"} color={"text.primary"} fontWeight={600}>vs prev month</Typography>
                </Stack>
            </Stack >
            <Stack direction={{ xs: "column", md: "row" }} gap={2}>
                <Stack direction={{ xs: "column", sm: "row" }} alignItems={{ xs: "center", md: "left" }} gap={1}>
                    <RevenueCard1 dark={false}>
                        <Typography fontSize={"11px"} color={"GrayText"} fontWeight={600}>
                            Top Sales
                        </Typography>
                        <Typography fontSize={"18px"} color={"text.primary"} fontWeight={600}>
                            74
                        </Typography>
                        <Stack direction={"row"} gap={1} alignItems={"center"} justifyContent={"space-between"} width={"100%"}>
                            <Stack direction={"row"} gap={0.5} alignItems={"center"} justifyContent={"flex-start"}>
                                <img src={Male1} alt="top-sales" style={{ width: "20px", height: "20px", borderRadius: "50%" }} />
                                <Typography fontSize={"12px"} color={"text.primary"} fontWeight={600}>John Doe</Typography>
                            </Stack>
                            <ChevronRight sx={{ fontSize: "14px", color: "text.primary" }} />
                        </Stack>
                    </RevenueCard1>
                    <RevenueCard1 dark={true}>
                        <Typography fontSize={"11px"} color={"GrayText"} fontWeight={600}>
                            Best Deal
                        </Typography>
                        <Typography fontSize={"18px"} color={"text.white"} fontWeight={600}>
                            $54,345
                        </Typography>
                        <Stack direction={"row"} gap={1} alignItems={"center"} justifyContent={"space-between"} width={"100%"}>
                            <Stack direction={"row"} gap={0.5} alignItems={"center"} justifyContent={"flex-start"}>
                                <Typography fontSize={"12px"} color={"text.white"} fontWeight={600}>Netflix Inc.</Typography>
                            </Stack>
                            <ChevronRight sx={{ fontSize: "14px", color: "text.white" }} />
                        </Stack>
                    </RevenueCard1>
                </Stack>
                <Stack direction={"row"} justifyContent={{ xs: "space-between", md: "left" }} gap={1}>
                    <RevenueCard2>
                        <Typography fontSize={"11px"} color={"GrayText"} fontWeight={600}>
                            Deals
                        </Typography>
                        <RevenueChip color={"grey.500"}>
                            <Typography fontSize={"11px"} color={"white"} fontWeight={600}>
                                324
                            </Typography>
                        </RevenueChip>
                        <Typography fontSize={"11px"} color={"text.primary"} fontWeight={600}>
                            42
                        </Typography>
                    </RevenueCard2>
                    <RevenueCard2 color={"secondary.main"}>
                        <Typography fontSize={"11px"} color={"GrayText"} fontWeight={600}>
                            Value
                        </Typography>
                        <RevenueChip>
                            <Typography fontSize={"11px"} color={"white"} fontWeight={600}>
                                321k
                            </Typography>
                        </RevenueChip>
                        <Typography fontSize={"11px"} color={"text.primary"} fontWeight={600}>
                            44%
                        </Typography>
                    </RevenueCard2>
                    <RevenueCard2>
                        <Typography fontSize={"11px"} color={"GrayText"} fontWeight={600}>
                            Win rate
                        </Typography>
                        <RevenueChip color={"grey.500"}>
                            <Typography fontSize={"11px"} color={"white"} fontWeight={600}>
                                35%
                            </Typography>
                        </RevenueChip>
                        <Typography fontSize={"11px"} color={"text.primary"} fontWeight={600}>
                            13.4%
                        </Typography>
                    </RevenueCard2>
                </Stack>
            </Stack >
        </Stack >
    </Grow>

}

const RevenueBottomLine = () => {

    interface IReveneueBottomCard {
        width: string
        value: string
        avatar: string
        noBackground?: boolean
    }

    const revenueBottomData = [
        {
            width: "33.85%",
            value: "$234,344",
            avatar: Male1
        },
        {
            width: "26.23%",
            value: "$105,344",
            avatar: Male10
        },
        {
            width: "15.87%",
            value: "$24,33",
            avatar: Female10
        },
        {
            width: "24.05%",
            value: "$100,344",
            avatar: LogoMini,
            noBackground: true
        },
    ]

    const RevenueBottomCard = (props: IReveneueBottomCard) => {
        return <Box sx={{ bgcolor: props.noBackground ? "none" : "white", width: props.width, borderRadius: 2, p: 0.8 }}>
            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                <Stack direction={"row"} gap={1} alignItems={"center"}>
                    <img src={props.avatar} alt="top-sales" style={{ width: "20px", height: "20px", borderRadius: "50%" }} />
                    <Typography fontSize={"11px"} color={"text.primary"} fontWeight={600}>{props.value}</Typography>
                </Stack>
                <Typography fontSize={"11px"} color={"GrayText"} fontWeight={600}>{props.width}</Typography>
            </Stack>
        </Box>
    }

    return <Grow in={true} timeout={1000} >
        <Stack direction={{ xs: "column", md: "row" }} gap={2} justifyContent={"space-between"} width={"100%"} alignItems={{ xs: "flex-start", md: "center" }}>
            <Stack direction={{ xs: "column", md: "row" }} gap={1} alignItems={{ xs: "flex-start", md: "center" }} width={"100%"} sx={{
                bgcolor: "background.secondWithBlur",
                borderRadius: 2,
                height: "100%",
                p: 0.4
            }}>
                {revenueBottomData.map((item, index) => <RevenueBottomCard
                    key={index}
                    width={item.width}
                    value={item.value}
                    avatar={item.avatar}
                    noBackground={item.noBackground}
                />)}
            </Stack>
            <Stack>
                <FilledButton
                    title="Details"
                />
            </Stack>
        </Stack>
    </Grow>

}

const BodySection = () => {

    return <Grow in={true} timeout={1000} >
        <Stack direction={{ xs: "column", xl: "row" }} gap={4}>
            <BodyLeftSection />
            <BodyRightSection />
        </Stack>
    </Grow>

}

const BodyLeftSection = () => {

    const downSm = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

    const LineChip = (props: {
        children: React.ReactNode,
        sx?: SxProps<Theme>,
        color?: string
    }) => {
        return <Box
            sx={{
                ...props.sx,
                height: "14px",
                borderRadius: 3,
                bgcolor: props.color ?? "secondary.main",
                backdropFilter: "blur(3px)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 0.5,
                px: 1,
                py: 0.5,
                cursor: "pointer",
                transition: "all 0.5s ease",
                ':hover': {
                    transition: "all 0.5s ease",
                    bgcolor: "primary.secondWithBlur",
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
                },
            }}
        >
            {props.children}
        </Box>
    }


    const BodyLeftTopHorizontalSection = () => {
        return <Card sx={{ bgcolor: "background.secondWithBlur", borderRadius: 2, p: 2, width: { xs: "auto", md: '100%' } }}>
            <Stack direction={"column"} gap={2} width={"100%"}>
                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                    <Stack direction={"row"} alignItems={"center"}>
                        <List sx={{ color: "text.primary" }} />
                        <ExpandMore sx={{ color: "text.primary" }} />
                    </Stack>
                    <OutlinedButton
                        rightIcon={<FilterList sx={{ color: "text.primary" }} />}
                        title="Filters"
                    />
                </Stack>
                <Stack direction={"column"} gap={1} width={"100%"}>
                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} gap={1} sx={{ bgcolor: "background.firstWithBlur", borderRadius: 2, p: 1.5 }}>
                        <Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} gap={0.5}>
                            <LinkedIn sx={{ color: "#0077B5" }} />
                            <Typography fontSize={"14px"} color={"GrayText"} fontWeight={600}>
                                LinkedIn
                            </Typography>
                        </Stack>
                        <Stack direction={"row"} justifyContent={"flex-end"} alignItems={"center"} gap={1} >
                            <Typography fontSize={"14px"} color={"text.primary"} fontWeight={600}>
                                $227,441
                            </Typography>
                            <LineChip color={"grey.300"}>
                                <Typography fontSize={"12px"} color={"text.primary"} fontWeight={600}>
                                    45%
                                </Typography>
                            </LineChip>
                        </Stack>
                    </Stack>

                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} gap={1} sx={{ bgcolor: "background.firstWithBlur", borderRadius: 2, p: 1.5 }}>
                        <Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} gap={0.5}>
                            <Instagram sx={{ color: "#fe0b81" }} />
                            <Typography fontSize={"14px"} color={"GrayText"} fontWeight={600}>
                                Instagram
                            </Typography>
                        </Stack>
                        <Stack direction={"row"} justifyContent={"flex-end"} alignItems={"center"} gap={1} >
                            <Typography fontSize={"14px"} color={"text.primary"} fontWeight={600}>
                                $227,441
                            </Typography>
                            <LineChip color={"grey.300"}>
                                <Typography fontSize={"12px"} color={"text.primary"} fontWeight={600}>
                                    45%
                                </Typography>
                            </LineChip>
                        </Stack>
                    </Stack>

                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} gap={1} sx={{ bgcolor: "background.firstWithBlur", borderRadius: 2, p: 1.5 }}>
                        <Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} gap={0.5}>
                            <Facebook sx={{ color: "#1877f2" }} />
                            <Typography fontSize={"14px"} color={"GrayText"} fontWeight={600}>
                                Facebook
                            </Typography>
                        </Stack>
                        <Stack direction={"row"} justifyContent={"flex-end"} alignItems={"center"} gap={1} >
                            <Typography fontSize={"14px"} color={"text.primary"} fontWeight={600}>
                                $227,441
                            </Typography>
                            <LineChip color={"grey.300"}>
                                <Typography fontSize={"12px"} color={"text.primary"} fontWeight={600}>
                                    45%
                                </Typography>
                            </LineChip>
                        </Stack>
                    </Stack>

                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} gap={1} sx={{ bgcolor: "background.firstWithBlur", borderRadius: 2, p: 1.5 }}>
                        <Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} gap={0.5}>
                            <YouTube sx={{ color: "#ff0000" }} />
                            <Typography fontSize={"14px"} color={"GrayText"} fontWeight={600}>
                                Youtube
                            </Typography>
                        </Stack>
                        <Stack direction={"row"} justifyContent={"flex-end"} alignItems={"center"} gap={1} >
                            <Typography fontSize={"14px"} color={"text.primary"} fontWeight={600}>
                                $227,441
                            </Typography>
                            <LineChip color={"grey.300"}>
                                <Typography fontSize={"12px"} color={"text.primary"} fontWeight={600}>
                                    45%
                                </Typography>
                            </LineChip>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
        </Card>
    }

    const BodyLeftTopVerticalSection = () => {
        return <Card sx={{ bgcolor: "background.secondWithBlur", borderRadius: 2, p: 2, width: { xs: "auto", md: '100%' } }}>
            <Stack direction={"column"} gap={2} width={"100%"}>
                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                    <Stack direction={"row"} alignItems={"center"}>
                        <List sx={{ color: "text.primary" }} />
                        <ExpandMore sx={{ color: "text.primary" }} />
                    </Stack>
                    <OutlinedButton
                        rightIcon={<FilterList sx={{ color: "text.primary" }} />}
                        title="Filters"
                    />
                </Stack>
                <Stack direction={"row"} alignItems={"flex-end"} justifyContent={"space-between"} height={"150px"} spacing={1}>
                    <Card sx={{ bgcolor: "background.firstWithBlur", borderRadius: 2, p: 1.5, height: "70%", width: "100%", position: "relative" }}>
                        <Stack direction={"column"} alignItems={"center"} sx={{ zIndex: 2 }}>
                            <Facebook sx={{ color: "#1877f2", zIndex: 2 }} />
                        </Stack>
                        <Stack sx={{ m: "5px", zIndex: 1, position: "absolute", top: 0, left: 0, width: "calc(100% - 10px)", height: "60px", background: "repeating-linear-gradient( -45deg, #bebebe33, #bebebe33 4px, #ffffff 4px, #ffffff 10px )" }} />
                    </Card>
                    <Card sx={{ bgcolor: "background.firstWithBlur", borderRadius: 2, p: 1.5, height: "90%", width: "100%" }}>
                        <Stack direction={"column"} alignItems={"center"}>
                            <YouTube sx={{ color: "#ff0000" }} />
                        </Stack>
                    </Card>
                    <Card sx={{ bgcolor: "background.firstWithBlur", borderRadius: 2, p: 1.5, height: "30%", width: "100%" }}>
                        <Stack direction={"column"} alignItems={"center"}>
                            <LinkedIn sx={{ color: "#0a66c2" }} />
                        </Stack>
                    </Card>
                    <Card sx={{ bgcolor: "background.firstWithBlur", borderRadius: 2, p: 1.5, height: "50%", width: "100%" }}>
                        <Stack direction={"column"} alignItems={"center"}>
                            <Facebook sx={{ color: "#1877f2" }} />
                        </Stack>
                    </Card>
                    <Card sx={{ bgcolor: "background.firstWithBlur", borderRadius: 2, p: 1.5, height: "65%", width: "100%", position: "relative" }}>
                        <Stack direction={"column"} alignItems={"center"} sx={{ zIndex: 2 }}>
                            <YouTube sx={{ color: "#ff0000", zIndex: 2 }} />
                        </Stack>
                        <Stack sx={{ m: "5px", zIndex: 1, position: "absolute", top: 0, left: 0, width: "calc(100% - 10px)", height: "calc(100% - 10px)", background: "repeating-linear-gradient( -45deg, #bebebe33, #bebebe33 4px, #ffffff 4px, #ffffff 10px )" }} />
                    </Card>
                </Stack>
                <Stack direction={"column"} gap={1}>
                    <Typography fontSize={"14px"} color={"GrayText"} mb={-1} fontWeight={600}>
                        Deals amount
                    </Typography>
                    <Typography fontSize={"14px"} color={"text.primary"} fontWeight={600}>
                        by referer category <ExpandMore sx={{ color: "text.primary", mb: -1 }} />
                    </Typography>
                </Stack>
            </Stack>
        </Card>
    }

    const BodyLeftTopSection = () => {
        return <Stack direction={{ xs: "column", md: "row" }} gap={1} width={"100%"}>
            <BodyLeftTopHorizontalSection />
            <BodyLeftTopVerticalSection />
        </Stack>
    }

    const BodyLeftBottomSection = () => {

        return downSm ? null : <Card sx={{ bgcolor: "background.secondWithBlur", borderRadius: 2, position: "relative" }}>
            <Stack direction={"column"} gap={4} width={"100%"} zIndex={2}>
                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                    <Stack direction={"row"} alignItems={"center"}>
                        <YouTube sx={{ color: "#ff0000" }} fontSize="large" />
                        <Stack direction={"column"} ml={1} gap={1}>
                            <Typography fontSize={"14px"} color={"GrayText"} fontWeight={600} mb={-1}>Platform value</Typography>
                            <Typography fontSize={"14px"} color={"text.primary"} fontWeight={600}>Youtube <ExpandMore sx={{ color: "text.primary", mb: -1 }} /></Typography>
                        </Stack>
                    </Stack>
                    <Card sx={{ bgcolor: "background.firstWithBlur", borderRadius: 2, p: 0.5, }}>
                        <Stack direction={"row"} justifyContent={"flex-end"} alignItems={"center"} gap={0.5}>
                            <FilledButton title="Revenue" />
                            <OutlinedButton title="Leads" />
                            <OutlinedButton title="W/L" />
                        </Stack>
                    </Card>
                </Stack>
                <Stack direction={"row"} gap={2} width={"100%"} m={-2} zIndex={2}>
                    <Card sx={{ zIndex: 2, bgcolor: "secondary.main", borderTopRightRadius: 20, borderBottomRightRadius: 0, borderTopLeftRadius: 0, px: 2, py: 4 }}>
                        <Stack direction={"row"} gap={2}>
                            <Stack direction={"column"} gap={1} alignItems={"center"} justifyContent={"center"}>
                                <Typography textAlign={"center"} sx={{ color: 'white', writingMode: "vertical-lr", textOrientation: "mixed", rotate: "180deg" }} fontSize={"14px"}>Average Monthly</Typography>
                            </Stack>
                            <Stack direction={"column"} gap={2}>
                                <Stack direction={"column"}>
                                    <Typography sx={{ color: "white" }} fontSize={"12px"}>Revenue</Typography>
                                    <Typography sx={{ color: "white" }} fontSize={"14px"} fontWeight={"bold"}>$2534</Typography>
                                </Stack>
                                <Stack direction={"column"}>
                                    <Typography sx={{ color: "white" }} fontSize={"12px"}>Leads</Typography>
                                    <Stack direction={"row"} gap={1}>
                                        <Typography sx={{ color: "white" }} fontSize={"14px"} fontWeight={"bold"}>335</Typography>
                                        <Typography sx={{ color: "white", opacity: 0.5 }} fontSize={"14px"} fontWeight={"bold"}>94/241</Typography>
                                    </Stack>
                                </Stack>
                                <Stack direction={"column"}>
                                    <Typography sx={{ color: "white" }} fontSize={"12px"}>Win/Loss</Typography>
                                    <Stack direction={"row"} gap={1}>
                                        <Typography sx={{ color: "white" }} fontSize={"14px"} fontWeight={"bold"}>44%</Typography>
                                        <Typography sx={{ color: "white", opacity: 0.5 }} fontSize={"14px"} fontWeight={"bold"}>22/50</Typography>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Card>
                    <Stack direction={"row"} gap={2} pb={2} zIndex={2} >
                        <Stack direction={"row"} gap={0.5} alignItems={"flex-end"} justifyContent={"center"} position={"relative"}>
                            <Box sx={{ position: "absolute", top: "20%", left: -8 }}>
                                <LineChip >
                                    <Typography sx={{ color: "white" }} fontSize={"12px"} fontWeight={"bold"}>$3,000</Typography>
                                </LineChip>
                            </Box>
                            <Card sx={{ bgcolor: "background.thirdWithBlur", borderRadius: 2, height: "55%", width: "100%", position: "relative" }}>
                                <Stack direction={"column"} alignItems={"center"} justifyContent={"flex-end"} height={"100%"} sx={{ zIndex: 2 }}>
                                    <img src={Male10} width={20} height={20} alt="" style={{ zIndex: 2, borderRadius: "50%", objectFit: "cover" }} />
                                </Stack>
                                <Stack sx={{ m: "5px", zIndex: 1, position: "absolute", top: 0, left: 0, width: "calc(100% - 10px)", height: "calc(100% - 10px)", background: "repeating-linear-gradient( -45deg, #bebebe33, #bebebe33 4px, #ffffff 4px, #ffffff 10px )" }} />
                            </Card>
                            <Card sx={{ bgcolor: "#ccc", borderRadius: 2, height: "35%", width: "100%", position: "relative" }}>
                                <Stack direction={"column"} alignItems={"center"} justifyContent={"flex-end"} height={"100%"} sx={{ zIndex: 2 }}>
                                    <img src={Male1} width={20} height={20} alt="" style={{ zIndex: 2, borderRadius: "50%", objectFit: "cover" }} />
                                </Stack>
                            </Card>
                            <Card sx={{ bgcolor: "#ccc", borderRadius: 2, height: "45%", width: "100%", position: "relative" }}>
                                <Stack direction={"column"} alignItems={"center"} justifyContent={"flex-end"} height={"100%"} sx={{ zIndex: 2 }}>
                                    <img src={Female3} width={20} height={20} alt="" style={{ zIndex: 2, borderRadius: "50%", objectFit: "cover" }} />
                                </Stack>
                            </Card>
                        </Stack>
                        <Stack direction={"row"} gap={0.5} alignItems={"flex-end"} justifyContent={"center"} position={"relative"}>
                            <Box sx={{ position: "absolute", top: "10%", left: -8 }}>
                                <LineChip >
                                    <Typography sx={{ color: "white" }} fontSize={"12px"} fontWeight={"bold"}>$3,000</Typography>
                                </LineChip>
                            </Box>
                            <Card sx={{ bgcolor: "background.thirdWithBlur", borderRadius: 2, height: "65%", width: "100%", position: "relative" }}>
                                <Stack direction={"column"} alignItems={"center"} justifyContent={"flex-end"} height={"100%"} sx={{ zIndex: 2 }}>
                                    <img src={Male10} width={20} height={20} alt="" style={{ zIndex: 2, borderRadius: "50%", objectFit: "cover" }} />
                                </Stack>
                                <Stack sx={{ m: "5px", zIndex: 1, position: "absolute", top: 0, left: 0, width: "calc(100% - 10px)", height: "calc(100% - 10px)", background: "repeating-linear-gradient( -45deg, #bebebe33, #bebebe33 4px, #ffffff 4px, #ffffff 10px )" }} />
                            </Card>
                            <Card sx={{ bgcolor: "#ccc", borderRadius: 2, height: "35%", width: "100%", position: "relative" }}>
                                <Stack direction={"column"} alignItems={"center"} justifyContent={"flex-end"} height={"100%"} sx={{ zIndex: 2 }}>
                                    <img src={Male1} width={20} height={20} alt="" style={{ zIndex: 2, borderRadius: "50%", objectFit: "cover" }} />
                                </Stack>
                            </Card>
                            <Card sx={{ bgcolor: "#ccc", borderRadius: 2, height: "45%", width: "100%", position: "relative" }}>
                                <Stack direction={"column"} alignItems={"center"} justifyContent={"flex-end"} height={"100%"} sx={{ zIndex: 2 }}>
                                    <img src={Female3} width={20} height={20} alt="" style={{ zIndex: 2, borderRadius: "50%", objectFit: "cover" }} />
                                </Stack>
                            </Card>
                        </Stack>
                        <Stack direction={"row"} gap={0.5} alignItems={"flex-end"} justifyContent={"center"} position={"relative"}>
                            <Box sx={{ position: "absolute", top: "30%", left: -8 }}>
                                <LineChip >
                                    <Typography sx={{ color: "white" }} fontSize={"12px"} fontWeight={"bold"}>$3,000</Typography>
                                </LineChip>
                            </Box>
                            <Card sx={{ bgcolor: "background.thirdWithBlur", borderRadius: 2, height: "45%", width: "100%", position: "relative" }}>
                                <Stack direction={"column"} alignItems={"center"} justifyContent={"flex-end"} height={"100%"} sx={{ zIndex: 2 }}>
                                    <img src={Male10} width={20} height={20} alt="" style={{ zIndex: 2, borderRadius: "50%", objectFit: "cover" }} />
                                </Stack>
                                <Stack sx={{ m: "5px", zIndex: 1, position: "absolute", top: 0, left: 0, width: "calc(100% - 10px)", height: "calc(100% - 10px)", background: "repeating-linear-gradient( -45deg, #bebebe33, #bebebe33 4px, #ffffff 4px, #ffffff 10px )" }} />
                            </Card>
                            <Card sx={{ bgcolor: "#ccc", borderRadius: 2, height: "35%", width: "100%", position: "relative" }}>
                                <Stack direction={"column"} alignItems={"center"} justifyContent={"flex-end"} height={"100%"} sx={{ zIndex: 2 }}>
                                    <img src={Male1} width={20} height={20} alt="" style={{ zIndex: 2, borderRadius: "50%", objectFit: "cover" }} />
                                </Stack>
                            </Card>
                            <Card sx={{ bgcolor: "#ccc", borderRadius: 2, height: "45%", width: "100%", position: "relative" }}>
                                <Stack direction={"column"} alignItems={"center"} justifyContent={"flex-end"} height={"100%"} sx={{ zIndex: 2 }}>
                                    <img src={Female3} width={20} height={20} alt="" style={{ zIndex: 2, borderRadius: "50%", objectFit: "cover" }} />
                                </Stack>
                            </Card>
                        </Stack>

                    </Stack>
                </Stack>
            </Stack>
            <Box position={"absolute"} bottom={0} right={0} sx={{ width: "70%", height: "60%", mb: "40px" }}>
                <Stack direction={"column"} justifyContent={"space-between"} height={"100%"} alignItems={"flex-end"} pr={2}>
                    <Divider sx={{ width: '100%', mb: -4 }} />
                    <Typography sx={{ color: "GrayText" }} fontSize={"12px"} fontWeight={"bold"}>$8,000</Typography>
                    <Divider sx={{ width: '100%', mb: -4 }} />
                    <Typography sx={{ color: "GrayText" }} fontSize={"12px"} fontWeight={"bold"}>$5,000</Typography>
                    <Divider sx={{ width: '100%', mb: -4 }} />
                    <Typography sx={{ color: "GrayText" }} fontSize={"12px"} fontWeight={"bold"}>$3,000</Typography>
                    <Divider sx={{ width: '100%', mb: -4 }} />
                    <Typography sx={{ color: "GrayText" }} fontSize={"12px"} fontWeight={"bold"}>$1,000</Typography>
                </Stack>
            </Box>
        </Card >
    }

    return <Grow in={true} timeout={1300} >
        <Stack direction={"column"} width={"100%"} gap={1}>
            <BodyLeftTopSection />
            <BodyLeftBottomSection />
        </Stack>
    </Grow>

}

const BodyRightSection = () => {

    const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

    const BodyRightChip = (props: {
        children: React.ReactNode,
        sx?: SxProps<Theme>,
        color?: string
    }) => {
        return <Box
            sx={{
                ...props.sx,
                height: "14px",
                borderRadius: 3,
                bgcolor: props.color ?? "secondary.main",
                backdropFilter: "blur(3px)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 0.5,
                px: 1,
                py: 0.5,
                cursor: "pointer",
                transition: "all 0.5s ease",
                ':hover': {
                    transition: "all 0.5s ease",
                    bgcolor: "primary.secondWithBlur",
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
                },
            }}
        >
            {props.children}
        </Box>
    }

    const BodyRightWhiteChip = (props: {
        children: React.ReactNode,
        sx?: SxProps<Theme>,
        handleClick?: MouseEventHandler<HTMLDivElement>
    }) => {
        return <Box
            sx={{
                ...props.sx,
                height: "20px",
                borderRadius: 3,
                border: "1px solid",
                borderColor: "grey.400",
                bgcolor: "background.thirdWithBlur",
                backdropFilter: "blur(3px)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 0.5,
                py: 0.5,
                px: 1.5,
                cursor: "pointer",
                transition: "all 0.5s ease",
                ':hover': {
                    transition: "all 0.5s ease",
                    bgcolor: "primary.secondWithBlur",
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
                },
            }}
        >
            {props.children}
        </Box>
    }

    const BodyRightSectionUserCard = () => {
        return <Box sx={{ bgcolor: "none", width: "100%", borderRadius: 2, p: 0.8 }}>
            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} width={"95%"}>
                <Stack direction={"row"} gap={0.5} alignItems={"center"} justifyContent={"flex-start"}>
                    <Typography textAlign={"center"} fontSize={"12px"} color={"GrayText"} fontWeight={600}>{"Sales"}</Typography>
                    <Box width={"20px"} />
                </Stack>
                <Stack direction={"row"} gap={0.5} alignItems={"center"} justifyContent={"flex-start"}>
                    <Box width={"40px"} />
                    <Typography fontSize={"12px"} color={"GrayText"} fontWeight={600}>Revenue</Typography>
                </Stack>
                <Stack direction={"row"} gap={0.5} alignItems={"center"} justifyContent={"flex-start"}>
                    <Typography textAlign={"center"} fontSize={"12px"} color={"GrayText"} fontWeight={600}>{"Leads"}</Typography>
                    <Box width={"40px"} />
                </Stack>
                <Stack direction={"row"} gap={0.5} alignItems={"center"} justifyContent={"flex-start"}>
                    <Typography fontSize={"12px"} color={"GrayText"} fontWeight={600}>{"KPI"}</Typography>
                    <Box width={"14px"} />
                </Stack>
                <Stack direction={"row"} gap={0.5} alignItems={"center"}>
                    <Typography textAlign={"center"} fontSize={"12px"} color={"GrayText"} fontWeight={600}>{"W/L"}</Typography>
                    <Box width={"74px"} />
                </Stack>
            </Stack>
            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} width={"95%"}>
                <Stack direction={"row"} gap={0.5} alignItems={"center"}>
                    <img src={Female10} alt="top-saless" style={{ width: "20px", height: "20px", borderRadius: "50%" }} />
                    <Typography fontSize={"12px"} color={"text.primary"} fontWeight={600}>{"Jaene Doe"}</Typography>
                </Stack>
                <Typography fontSize={"12px"} color={"text.primary"} fontWeight={600}>{"$3,223"}</Typography>
                <Stack direction={"row"} gap={0.5} alignItems={"center"}>
                    <BodyRightChip color="primary.main">
                        <Typography fontSize={"12px"} color={"primary.lighter"} fontWeight={600}>{"43"}</Typography>
                    </BodyRightChip>
                    <BodyRightChip color="primary.lighter">
                        <Typography fontSize={"12px"} color={"primary.darker"} fontWeight={600}>{"345"}</Typography>
                    </BodyRightChip>
                </Stack>
                <Typography fontSize={"12px"} color={"primary.darker"} fontWeight={600}>{"0.54"}</Typography>
                <Stack direction={"row"} gap={0.5} alignItems={"center"}>
                    <Typography fontSize={"12px"} color={"primary.darker"} fontWeight={600}>{"0.54"}</Typography>
                    <BodyRightChip color="primary.main">
                        <Typography fontSize={"12px"} color={"primary.lighter"} fontWeight={600}>{"43"}</Typography>
                    </BodyRightChip>
                    <BodyRightChip color="primary.lighter">
                        <Typography fontSize={"12px"} color={"primary.darker"} fontWeight={600}>{"345"}</Typography>
                    </BodyRightChip>
                </Stack>
            </Stack>
        </Box>
    }


    return <Grow in={true} timeout={1300} >
        <Card sx={{ bgcolor: "background.secondWithBlur", borderRadius: 2, p: 2, width: { xs: "auto", xl: '100%' } }}>
            <Stack direction={"column"} gap={2} width={"100%"}>
                {downMd ? null : <BodyRightSectionUserCard />}
                {downMd ? null : <Stack direction={"row"} gap={2}>
                    <BodyRightWhiteChip>
                        <Typography fontSize={"12px"} color={"text.primary"} fontWeight={600}>{"Top Sales 💪"}</Typography>
                    </BodyRightWhiteChip>
                    <BodyRightWhiteChip>
                        <Typography fontSize={"12px"} color={"text.primary"} fontWeight={600}>{"Sales Streak🔥"}</Typography>
                    </BodyRightWhiteChip>
                    <BodyRightWhiteChip>
                        <Typography fontSize={"12px"} color={"text.primary"} fontWeight={600}>{"Top Review 🌟"}</Typography>
                    </BodyRightWhiteChip>
                </Stack>}
                <Stack direction="column" gap={2}>
                    <Stack direction={"row"} gap={2} justifyContent={"space-between"} width={"100%"}>
                        <Typography fontSize={"14px"} color={"text.primary"} fontWeight={600}>Work with platforms</Typography>
                        <Stack direction={"row"} gap={1}>
                            <BodyRightChip color="secondary.main">
                                <RemoveRedEye sx={{ color: "white", fontSize: "12px" }} />
                                <Typography fontSize={"12px"} color={"white"} fontWeight={600}>{"43"}</Typography>
                            </BodyRightChip>
                            <BodyRightChip color="secondary.main">
                                <Typography fontSize={"12px"} color={"white"} fontWeight={600}>{"$123,349"}</Typography>
                            </BodyRightChip>
                        </Stack>
                    </Stack>
                </Stack>
                <Stack direction={{ xs: "column", md: "row" }} gap={2}>
                    <Stack direction={"column"} gap={2} width={"100%"} height={"100%"}>
                        <Card sx={{ bgcolor: "background.firstWithBlur", borderRadius: 2, p: 2, height: '100% !important' }}>
                            <Stack direction={"column"} gap={2} justifyContent={"space-between"} width={"100%"} height={"100%"}>
                                <Stack direction={"row"} gap={1} alignItems={"center"}>
                                    <Facebook sx={{ color: "#4267B2", fontSize: "22px" }} />
                                    <Typography fontSize={"18px"} color={"text.primary"} fontWeight={600}>{"Facebook"}</Typography>
                                </Stack>
                                <Stack direction={"row"} gap={1}>
                                    <Typography fontSize={"28px"} color={"text.primary"} fontWeight={600}>{"54.32%"}</Typography>
                                    <Typography fontSize={"28px"} color={"GrayText"} fontWeight={600}>{"$34,223"}</Typography>
                                </Stack>
                            </Stack>
                        </Card>
                    </Stack>
                    <Stack direction={"column"} gap={1} width={"100%"} height={"100%"}>
                        <Stack direction={{ xs: "column", sm: "row" }} gap={1} height={"100%"}>
                            <Card sx={{ bgcolor: "background.firstWithBlur", borderRadius: 2, p: 2, width: { xs: "auto", sm: "100%" } }}>
                                <Stack direction={"column"} gap={2} justifyContent={"space-between"} width={"100%"}>
                                    <Stack direction={"row"} gap={1} alignItems={"center"}>
                                        <YouTube sx={{ color: "#FF0000", fontSize: "14px" }} />
                                        <Typography fontSize={"14px"} color={"text.primary"} fontWeight={600}>{"Youtube"}</Typography>
                                    </Stack>
                                    <Stack direction={"row"} gap={1}>
                                        <Typography fontSize={"16px"} color={"text.primary"} fontWeight={600}>{"54.32%"}</Typography>
                                        <Typography fontSize={"16px"} color={"GrayText"} fontWeight={600}>{"$34,223"}</Typography>
                                    </Stack>
                                </Stack>
                            </Card>
                            <Card sx={{ bgcolor: "#00000000", boxShadow: 0, p: 0, width: '100%' }}>
                                <Stack direction={"column"} gap={1} width={"100%"}>
                                    <Card sx={{ bgcolor: "background.firstWithBlur", borderRadius: 2, p: 1, }}>
                                        <Stack direction={"column"} gap={1} justifyContent={"space-between"} width={"100%"}>
                                            <Stack direction={"row"} gap={1} alignItems={"center"}>
                                                <LinkedIn sx={{ color: "#0a66c2", fontSize: "14px" }} />
                                                <Typography fontSize={"14px"} color={"text.primary"} fontWeight={600}>{"LinkedIn"}</Typography>
                                            </Stack>
                                            <Stack direction={"row"} gap={1}>
                                                <Typography fontSize={"13px"} color={"text.primary"} fontWeight={600}>{"54.32%"}</Typography>
                                                <Typography fontSize={"13px"} color={"GrayText"} fontWeight={600}>{"$34,223"}</Typography>
                                            </Stack>
                                        </Stack>
                                    </Card>
                                    <Card sx={{ bgcolor: "background.firstWithBlur", borderRadius: 2, p: 1, }}>
                                        <Stack direction={"row"} gap={1} justifyContent={"space-between"} width={"100%"}>
                                            <Stack direction={"row"} gap={1} alignItems={"center"}>
                                                <LinkedIn sx={{ color: "#0a66c2", fontSize: "14px" }} />
                                                <Typography fontSize={"13px"} color={"text.primary"} fontWeight={600}>{"54.32%"}</Typography>
                                                <Typography fontSize={"13px"} color={"GrayText"} fontWeight={600}>{"$34,223"}</Typography>
                                            </Stack>
                                        </Stack>
                                    </Card>
                                </Stack>
                            </Card>
                        </Stack>
                        <Card sx={{ bgcolor: "background.firstWithBlur", borderRadius: 2, p: 2, }}>
                            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} width={"100%"}>
                                <Stack direction={"row"} gap={1} alignItems={"center"}>
                                    <LinkedIn sx={{ color: "#0a66c2", fontSize: "20px" }} />
                                    <Typography fontSize={"14px"} color={"text.primary"} fontWeight={600}>{"54.32%"}</Typography>
                                </Stack>
                                <Typography fontSize={"14px"} color={"GrayText"} fontWeight={600}>{"$34,223"}</Typography>
                            </Stack>
                        </Card>
                    </Stack>
                </Stack>
                <Stack direction="column" gap={2}>
                    <Typography fontSize={"14px"} color={"text.primary"} fontWeight={600}>Sales dynamics</Typography>
                    <Card sx={{ bgcolor: "background.secondWithBlur", borderRadius: 2, p: 2, }}>
                        <LineChartPro
                            xAxis={[{ data: [1, 2, 3, 5, 8, 10] }]}
                            series={[
                                {
                                    data: [2, 5.5, 2, 8.5, 1.5, 5],
                                    color: "#F43F5E",
                                },
                            ]}
                            height={170}
                            sx={{ mb: -5 }}
                        />
                    </Card>
                </Stack>
            </Stack>
        </Card>
    </Grow >
}


export default HomeForActive