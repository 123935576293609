

import { commonRoutes } from "routes/route.common";

export const contractsDomainRoutes = {
  base: "contracts",
  subdomains: {
    contracts: {
      base: "contracts",
    },
  },
};


export const baseContractsRoute = (): string => `/${contractsDomainRoutes.base}/`;
export const listContractsRoute = (): string =>
  `/${contractsDomainRoutes.base}/`;

