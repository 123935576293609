import {
  GridColumnHeaderParams,
  GridRenderCellParams,
  GridRenderEditCellParams,
  GridSingleSelectColDef,
} from "@mui/x-data-grid-pro";
import { IGridColConfigInput } from "../infra/IGridColConfigInput";
import {
  IconButton,
  MenuItem,
  Select,
  SvgIcon,
  Typography,
} from "@mui/material";
import * as Icons from "@mui/icons-material";
import { Stack } from "@mui/system";
import { generateBaseSingleSelectColDef } from "../infra/GridColDefFactory";
import { TDefaultObjectType } from "../infra/types";
import { Iconify } from "components/iconify";
import BaseChip from "components/chip/BaseChip";

export const BaseObjectGridColumnDef = (params: {
  config: IGridColConfigInput<TDefaultObjectType>;
}): GridSingleSelectColDef => {
  const baseColDef = generateBaseSingleSelectColDef({
    config: params.config,
  });
  // baseColDef.filterOperators = onlyStringEqualsOperator;

  if (params.config.valueOptionFields) {
    baseColDef.valueOptions = params.config.valueOptionFields?.map(
      (objectData) => {
        return {
          label: objectData.name,
          value: objectData._id,
        };
      }
    );
  }

  baseColDef.renderCell = (
    cellParams: GridRenderCellParams<TDefaultObjectType>
  ) => {
    return (
      <BaseChip
        label={cellParams.value.name}
        color={cellParams.value.color}
        icon={cellParams.value.icon}
        borderColor={cellParams.value.color}
      />
    );
  };

  if (params.config.column.editable) {
    baseColDef.renderEditCell = (
      editCellParams: GridRenderEditCellParams<TDefaultObjectType>
    ) => {
      const currentValue = editCellParams.value?._id || "";
      return (
        <Select
          name={params.config.column.field}
          value={currentValue}
          label={
            params.config.editCellConfig?.label || params.config.column.field
          }
          onChange={(e) => {
            // TODO: refactor
            editCellParams.api.setEditCellValue({
              ...editCellParams,
              value: { ...editCellParams.value, _id: e.target.value },
            });
          }}
          sx={{ width: "100%" }}
        >
          {params.config.valueOptionFields?.map((item) => (
            <MenuItem key={item._id} value={item._id}>
              <Stack direction="row" alignItems={"left"}>
                <SvgIcon
                  component={(Icons as any)[item.icon ?? ""]}
                  sx={{ fontSize: "13px", mr: 0.5, color: item.color }}
                />
                <Typography
                  fontSize={"13px"}
                  fontWeight={"bold"}
                  color={item.color}
                >
                  {" "}
                  {item.name}{" "}
                </Typography>
              </Stack>
            </MenuItem>
          ))}
        </Select>
      );
    };
  }

  if (params.config.headerConfig) {
    baseColDef.renderHeader = (headerParams: GridColumnHeaderParams) => (
      <Stack
        direction="row"
        alignItems={"center"}
        justifyContent={params.config.headerConfig.align ?? "left"}
      >
        <Iconify icon={params.config.headerConfig.icon} sx={{ width: '15px', mr: 1, color: 'primary.main' }} />
        <span style={{ fontWeight: 500 }}>
          {headerParams.colDef.headerName}
        </span>
        {params.config.headerConfig.creatable && <IconButton
          size="small"
          onClick={(e: any) => {
            e.stopPropagation();
            if (params.config.headerConfig.createFunc) {
              params.config.headerConfig.createFunc(true);
            }
          }}
        >
          <Icons.Add fontSize="small" />
        </IconButton>}
      </Stack>
    );
  }
  return baseColDef;
};
