import { Route, Routes } from "react-router-dom";

import { List} from "../pages";

import { TasksLayout } from "../layouts/TasksLayout";

export const TasksRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<TasksLayout />}>
        <Route index element={<List />} />
       </Route>
    </Routes>
  );
};
