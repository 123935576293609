


import { IMenuItem } from "interfaces/menu-item.interface";
import { getTranslatedText } from "localization";
import {
  baseCommunityRoute,
} from "../routes/demo.base.route";

export const demoDomainMenuItem: IMenuItem = {
  title: getTranslatedText("demo"),
  path: baseCommunityRoute(),
  children: [

  ],
};
