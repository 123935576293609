import React from 'react';
import ContainerProps from './container.type';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Button, Stack, Typography } from '@mui/material';
import { DragIndicator } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const Container = ({
  id,
  children,
  title,
  description,
  onAddItem,
}: ContainerProps) => {

  const {t} = useTranslation();

  const {
    attributes,
    setNodeRef,
    listeners,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: id,
    data: {
      type: 'container',
    },
  });
  return (
    <Stack
      {...attributes}
      ref={setNodeRef}
      style={{
        transition,
        transform: CSS.Translate.toString(transform),
      }}
      sx={{
        width: "90%",
        height: "100%",
        minHeight: "400px",
        padding: "16px 16px 0 16px",
        borderRadius: 3,
        boxShadow: "12px 12px 24px #d1cfcf, -12px -12px 24px #ffffff",
        backgroundColor: "background.default",
        // border: "1px solid #E2E8F0",
        position: "relative",
        opacity: isDragging ? 0.5 : 1,
      }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction={"column"} gap={1}>
          <Typography fontSize={"24px"} fontWeight={"bold"} color={"text.primary"}>{title}</Typography>
          <Typography variant='body2' >{description}</Typography>
        </Stack>
        <Button
          variant='text'
          size='small'
          {...listeners}
          color='secondary'
        >
          <DragIndicator />
        </Button>
      </Stack>

      {children}
      <Button variant="outlined" color='primary' sx={{ mt: 3 }} onClick={onAddItem}>
        {t("crm.lead.lead.create")}
      </Button>
    </Stack>
  );
};

export default Container;
