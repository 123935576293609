

import { PreSalesDomainLayout } from "../layouts";
import { EstimatesRouter } from "../subdomains/estimates/routes/EstimatesRouter";
import { ProposalsRouter } from "../subdomains/proposals/routes/ProposalsRouter";
import { LegalAgreementsRouter } from "../subdomains/legalAgreement/routes/LegalAgreementsRouter";
import { preSalesDomainRoutes } from "./preSales.base.route";
import { Route, Routes } from "react-router-dom";

export const PreSalesDomainRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<PreSalesDomainLayout />}>
        <Route index element={<EstimatesRouter />} />
        <Route
          path={`${preSalesDomainRoutes.subdomains.estimates.base}/*`}
          element={<EstimatesRouter />} />
        <Route
          path={`${preSalesDomainRoutes.subdomains.proposals.base}/*`}
          element={<ProposalsRouter />} />
        <Route
          path={`${preSalesDomainRoutes.subdomains.legalAgreements.base}/*`}
          element={<LegalAgreementsRouter />} />

      </Route>
    </Routes>
  );
};
