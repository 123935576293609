/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import { useEffect } from "react";

import { IBaseError } from "corede-common"
import { enqueueSnackbar } from "notistack";
import { getCurrentLanguage } from "localization";
import { DefaultErrorHandlerUseEffect } from "utils/useEffect.helper";
import { IBaseInvoiceEntity, IContact } from "corede-common-cocrm";
import { useTranslation } from "react-i18next";
import { useCustomerUpdateMutation } from "../../customers";
import { useCustomerContactListQuery } from "../context/contacts.api";

const Delete = (props: {
  open: boolean;
  item: IBaseInvoiceEntity;
  onClose: () => void;
}) => {
  // general
  const currentLanguage = getCurrentLanguage();
  const { t } = useTranslation();

  const { data: customerContactListData, isLoading: customerContactListLoading, isFetching: customerContactListFetching } = useCustomerContactListQuery({
    input: {
      filter: {
        customerId: props.item?.customer?._id,
        isPrimary: false
      },
      pagination: {
        sort: {
          createdAt: 1
        }
      }
    }
  });

  // queries

  // mutations
  const [customerUpdate, { data: customerUpdateData, isLoading: customerUpdateLoading, error: customerUpdateError }] = useCustomerUpdateMutation();

  // constants

  // action handlers
  const handleDelete = (_id: string) => {
    const contactListAfterDeletion = customerContactListData?.data.filter(contact => contact._id.toString() != _id).map(contact => ({
      _id: contact._id,
      firstName: contact.firstName,
      lastName: contact.lastName,
      email: contact.email,
      phone: contact.phone,
      position: contact.position,
    }))
    customerUpdate({
      filter: {
        _id: props.item?.customer?._id,
      },
      input: {
        otherContactList: contactListAfterDeletion
      }
    });
  };

  // useEffects.success
  useEffect(() => {
    if (customerUpdateData) {
      props?.onClose();
      enqueueSnackbar(t("crm.customer.contacts.deletedSuccessfully"), {
        variant: "success",
      });
    }
  }, [customerUpdateData]);

  // useEffects.error
  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      customerUpdateError as IBaseError,
      currentLanguage
    );
  }, [customerUpdateError, currentLanguage]);

  // useEffects.init

  return (
    <Dialog
      open={props?.open}
      onClose={props?.onClose}
      sx={{
        "& .MuiDialog-paper": {
          padding: 1,
          borderRadius: 2,
        },
      }}
    >
      <DialogTitle>{t("crm.customer.contacts.delete")}</DialogTitle>
      <DialogContent>
        {t("crm.customer.contacts.deleteConfirm")} {props?.item?.prefix}?
      </DialogContent>
      <DialogActions>
        <Button onClick={props?.onClose} color="primary">
          {t("cancel")}
        </Button>
        <Button
          variant="contained"
          color="error"
          disabled={customerUpdateLoading}
          onClick={() => {
            handleDelete(props?.item?._id);
          }}
        >
          {t("delete")}
        </Button>
      </DialogActions>
    </Dialog >
  );
};

export default Delete;
