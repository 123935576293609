import { createSlice } from '@reduxjs/toolkit';

const { actions, reducer } = createSlice({
    name: 'route',
    initialState: {
        route: "crm",
    },
    reducers: {
        setRoute: (state, action) => {
            state.route = action.payload;
            localStorage.setItem('route', action.payload);
        },
    },
});


export const { setRoute } = actions;
export const routeSlice = reducer;