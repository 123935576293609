import { useState, lazy, Suspense } from 'react';


const CategoryCreateDrawer = lazy(() => import('./ProductCategoryCreateDrawer'));


const CategoryCreateWraper = ({
    open,
    setOpen
}: any) => {

    const [mounted, setMounted] = useState(false);

    if (open && !mounted) {
        setMounted(true);
    }

    return (
        <>
            {mounted && (
                <Suspense>
                    < CategoryCreateDrawer
                        open={open}
                        setOpen={setOpen}

                    />
                </Suspense>
            )}
        </>
    );
};

export default CategoryCreateWraper;