/* eslint-disable react-hooks/exhaustive-deps */
import { Autocomplete, Chip, Grid, InputLabel, TextField } from "@mui/material";
import { useState, useEffect, memo } from "react";
import { useForm, Controller } from "react-hook-form";
import { convertEnums, getCurrentLanguage } from "localization";
import { IGraphqlVariables, unknownError } from "corede-common";
import { INoteCreateInput, NoteTargetType } from "corede-common-cocrm";
import { validateCreateNotesInput } from "../validations/create.validation";
import { RequestTransformerHelper } from "validations/request.transformer.helper";
import { enqueueSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import ActionDialog from "components/dialog/ActionDialog";
import { useLazyLeadListQuery, useLeadListQuery, useNoteCreateMutation } from "apps/crm/domains/03-lead/subdomains/lead";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import moment from "moment";
import { useUserListQuery } from "apps/crm/domains/02-organizationalChart/subdomains/users";
import { useUserDetailOwnQuery } from "apps/auth/context";



interface INoteCreateDialog {
    open: boolean;
    setOpen: (open: boolean) => void
    target?: { id: string; name: string }
    targetType?: NoteTargetType
}

const NoteCreateDialog = memo((props: INoteCreateDialog) => {
    // general
    const { t } = useTranslation();
    const currentLanguage = getCurrentLanguage();
    const [loading, setLoading] = useState(false);
    const localizedTargetTypes = convertEnums(NoteTargetType);
    const [inputValueTag, setInputValueTag] = useState('');
    const [targetList, setTargetList] = useState<{ id: string; name: string }[]>([]);
    // queries
    const { data: userListData, isLoading: userListLoading } = useUserListQuery({});
    const { data: userDetailOwnData } = useUserDetailOwnQuery({});
    const { data: leadListData, isLoading: leadListLoading } = useLeadListQuery({});

    // mutations
    const [noteCreate, { data: noteCreateData, isLoading: noteCreateLoading, error: noteCreateError }] = useNoteCreateMutation();




    // form setup
    const {
        control,
        handleSubmit,
        reset,
        formState: { errors, touchedFields, isValid },
    } = useForm<IGraphqlVariables<INoteCreateInput>>({
        defaultValues: {
            input: {
                title: undefined,
                content: undefined,
                targetId: props.target?.id,
                targetType: props.targetType,
            },
        },
        resolver: yupResolver(validateCreateNotesInput),
        mode: "onChange",
    });

    const onSubmit = async (values: IGraphqlVariables<INoteCreateInput>) => {
        setLoading(true);
        const transformedValues = RequestTransformerHelper.TransformAllEmptyStringFieldsToUndefined({
            input: values,
        });
        await noteCreate(transformedValues as IGraphqlVariables<INoteCreateInput>);
        setLoading(false);
    };


    // useEffects.success

    useEffect(() => {
        if (noteCreateData) {
            enqueueSnackbar(t("crm.notes.notes.createNoteSuccess"), { variant: 'success' });
            reset();
            props.setOpen(false);
        }
    }, [noteCreateData, reset]);

    // useEffects.error

    useEffect(() => {
        if (noteCreateError) {
            enqueueSnackbar((noteCreateError as any)?.error?.message[currentLanguage] ?? unknownError.message[currentLanguage], { variant: 'error' });
        }
    }, [noteCreateError]);

    useEffect(() => {
        if (leadListData) {
            setTargetList(leadListData?.data.map((lead) => ({ id: lead._id, name: lead.fullName })));
        }
    }, [leadListData]);


    return <ActionDialog
        open={props.open}
        setOpen={props.setOpen}
        size="small"
        title={t("crm.notes.notes.createNote")}
        handleClick={handleSubmit(onSubmit)}
        disabled={loading || !isValid || noteCreateLoading}
        loading={(loading || noteCreateLoading)}
        buttonTitle={t("crm.notes.notes.addNote")}
        width={500}
    >
        <Grid container spacing={2} my={2}>
            {/* <Grid item xs={12} >
                <InputLabel htmlFor="targetType" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.notes.notes.targetType")}</InputLabel>
                <Controller
                    name="input.targetType"
                    control={control}
                    render={({ field }) => (
                        <Autocomplete
                            id="targetType"
                            size="small"
                            fullWidth
                            isOptionEqualToValue={(option, value) => option._id === value._id}
                            options={Object.values(TargetType).map((targetType) => ({
                                name: localizedTargetTypes[targetType as keyof typeof TargetType],
                                _id: targetType,
                            }))}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => <TextField {...params} />}
                            value={
                                field.value
                                    ? { name: localizedTargetTypes[field.value as keyof typeof TargetType], _id: field.value }
                                    : null
                            }
                            onChange={(e, value) => {
                                field.onChange(value?._id);
                            }}
                        />
                    )}
                />
            </Grid> */}
            <Grid item xs={12} >
                <InputLabel htmlFor="target" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.notes.notes.target")}</InputLabel>
                <Controller
                    name="input.targetId"
                    control={control}
                    render={({ field }) => (
                        <Autocomplete
                            id="input.target"
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            fullWidth
                            size="small"
                            disableClearable
                            getOptionLabel={(option) => option.name}
                            options={targetList}
                            renderInput={(params) => (
                                <TextField {...params} />
                            )}
                            onChange={(e, value) => {
                                field.onChange(value?.id);
                            }}
                            // value={field.value ? { name: field.value, id: field.value } : null}
                            value={field.value ? targetList.find((target) => target.id === field.value) : undefined}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12}>
                <InputLabel htmlFor="input.name" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>
                    {t("crm.notes.notes.title")}*
                </InputLabel>
                <Controller
                    name="input.title"
                    control={control}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            fullWidth
                            size="small"
                            onChange={(e) => field.onChange(e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                            error={!!errors?.input?.title}
                            helperText={touchedFields?.input?.title && errors?.input?.title?.message}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12}>
                <InputLabel htmlFor="input.content" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>
                    {t("crm.notes.notes.content")}*
                </InputLabel>
                <Controller
                    name="input.content"
                    control={control}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            multiline
                            rows={5}
                            fullWidth
                            size="small"
                            onChange={(e) => field.onChange(e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                            error={!!errors?.input?.content}
                            helperText={touchedFields?.input?.content && errors?.input?.content?.message}
                        />
                    )}
                />
            </Grid>
        </Grid>
    </ActionDialog >
})

export default memo(NoteCreateDialog)