
import { demoDomainRoutes } from "../domains/demo/routes/demo.base.route";
import { DemoDomainRouter } from "../domains/demo/routes/DemoDomainRouter";
import { Route } from "react-router-dom";
import { DemoRouter } from "../domains/demo/subdomains/demo/routes/DemoRouter";
import WindowLayout from "../layouts/WindowLayout";

export const WindowRouter = () => {
  return (
    <Route path="/*" element={<WindowLayout />}>
      <Route index element={< DemoRouter />} />
      <Route
        path={`${demoDomainRoutes.base}/*`}
        element={<DemoDomainRouter />}
      />
      <Route path="*" element={<DemoDomainRouter />} />
    </Route>
  );
};
