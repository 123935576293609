import { Grid } from "@mui/material";
import { Outlet } from "react-router-dom";

export const LeadDomainLayout = () => {



  return (
    <Grid container spacing={3} height={"100%"}>
      <Outlet />
    </Grid>
  );
};
