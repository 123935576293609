
import reportsLocale from "../subdomains/reports/locales/en";

const locale = {

  // Todo
  // users: {
  //   ...usersLocale,
  // },
  reports: {
    ...reportsLocale,
  },
};

export default locale;
