import { Outlet } from "react-router";
import MainLayout from 'layouts/MainLayout';
import { demoDomainMenuItem } from "../domains/demo/menu/items";
import { useTranslation } from "react-i18next";


const LeadPlusLayout = () => {

  const { t } = useTranslation();

  const treeItems = [
    {
      itemId: demoDomainMenuItem.path,
      label: t("leadPlus.demoo"),
      href: demoDomainMenuItem.path,
      children: demoDomainMenuItem.children?.map((child) => ({
        itemId: child.title,
        label: child.title,
        href: child.path,
      })),
    },
  ];

  return <MainLayout treeItems={treeItems}>
    <Outlet />
  </MainLayout>

}

export default LeadPlusLayout;
