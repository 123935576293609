import { Route, Routes } from "react-router-dom";

import { List} from "../pages";

import { CustomerCategoriesLayout } from "../layouts/CustomerCategoriesLayout";

export const CustomerCategoriesRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<CustomerCategoriesLayout />}>
        <Route index element={<List />} />
       </Route>
    </Routes>
  );
};
