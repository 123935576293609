/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, Typography, Stack } from "@mui/material";
import { AuthLayout } from "../layouts";
import LoginCo from "assets/images/login-co.png";
import { useNavigate } from "react-router-dom";
import { routes } from "../routes/routes";
import { useTranslation } from 'react-i18next';

const ResendConfirmationSuccessfull = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();


  return <AuthLayout>
    <Stack direction={"column"} justifyContent={"space-between"} alignItems={"center"}>
      <Typography fontSize={14} fontWeight={400} textAlign={"center"} color={"GrayText"} py={2}>
        {/* Optional intro text */}
      </Typography>
      <Grid item xs={12} display={"flex"} height={"100vh"} width={"100%"} justifyContent={'center'} alignItems={'center'}>
        <Box display={{ xs: 'flex', md: 'none' }} height={100} />
        <Box
          sx={{
            width: { xs: '100%', md: '80%', lg: '60%' },
            height: "80vh",
            display: 'flex',
            flexDirection: 'column',
            justifyContent: { xs: 'flex-start', md: 'center' },
            alignItems: 'center',
            gap: 2,
          }}
          px={{ xs: 1, md: 7 }}
        >
          <img src={LoginCo} alt={t("auth.logoAlt")} width={120} />
          <Box display={"flex"} flexDirection={"column"} gap={2} alignItems={"center"} pt={2}>
            <Typography fontSize={32} fontWeight={500} textAlign={"center"}>
              {t("auth.resendConfirmationSuccessTitle")}
            </Typography>
          </Box>
          <Box display={"flex"} flexDirection={"column"} gap={2} alignItems={"center"} pb={2}>
            <Typography fontSize={14} fontWeight={400} textAlign={"center"}>
              {t("auth.resendConfirmationSuccessText")}
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Typography fontSize={14} fontWeight={400} textAlign={"center"} color={"GrayText"} py={2} sx={{ cursor: "pointer" }}>
        {t("auth.rememberPassword")} <span style={{ color: "#000" }} onClick={() => navigate(routes.auth.login)}> {t("auth.login")}</span>
      </Typography>
    </Stack>
  </AuthLayout>
}

export default ResendConfirmationSuccessfull;
