import { IMenuItem } from "interfaces/menu-item.interface";
import { useTranslation } from "react-i18next";
import { baseOrganizationRoute } from "../routes/dashboard.base.route";

export const useDashboardDomainMenuItem = (): IMenuItem => {
  const { t } = useTranslation();

  return {
    title: t("dashboard"),
    path: baseOrganizationRoute(),
  };
};
