/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography } from "@mui/material";
import { IBaseError } from "corede-common";
import { IBaseCustomerEntity } from "corede-common-cocrm";
import { getCurrentLanguage } from "localization";
import { enqueueSnackbar } from "notistack";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { DefaultErrorHandlerUseEffect } from "utils/useEffect.helper";
import { useCustomerDeleteMutation } from "../context/customers.api";
import ActionDialog from "components/dialog/ActionDialog";

export const DeleteDialog = (prop: {
  open: boolean;
  item: IBaseCustomerEntity | undefined;
  onClose: () => void;
}) => {
  // general
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();

  // queries

  // mutations
  const [customerDelete, { data: customerDeleteData, isLoading: customerDeleteIsLoading, error: customerDeleteError }] = useCustomerDeleteMutation();

  // constants

  // // action handlers
  const handleDelete = (_id: string) => {
    customerDelete({ input: { _id: _id } });
  };

  // useEffects.success
  useEffect(() => {
    if (customerDeleteData) {
      prop?.onClose();
      enqueueSnackbar(t("crm.customer.customers.deletedSuccessfully"), {
        variant: "success",
      });
    }
  }, [customerDeleteData]);

  // // useEffects.error
  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      customerDeleteError as IBaseError,
      currentLanguage
    );
  }, [customerDeleteError, currentLanguage]);

  // useEffects.init

  return (
    <ActionDialog
      open={prop?.open}
      onClose={prop?.onClose}
      title={t("crm.customer.customers.delete")}
      buttonTitle={t("delete")}
      buttonColor="error"
      handleClick={() => prop?.onClose()}
      disabled={customerDeleteIsLoading}
      loading={customerDeleteIsLoading}
    >
      <Typography>
        {t("crm.customer.customers.deleteConfirm")}
      </Typography>
    </ActionDialog>
  );
};
