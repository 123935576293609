const locale = {
  reminders: "Reminders",
  createReminder: "Create Reminder",
  title: "Title",
  content: "Content",
  addReminder: "Add Reminder",
  priority: "Priority",
  remindDate: "Remind Date",
  remindUsers: "Remind Users",
  tags: "Tags",
  tagsPlaceholder: "Please add tags and press enter",
  createReminderSuccess: "Reminder created successfully",
  target: "Target",
  updateReminder: "Update Reminder",
  deleteReminder: "Delete Reminder",
  deletedSuccessfully: "Reminder deleted successfully",
  deleteReminderContent: "Are you sure you want to delete this reminder. This action cannot be undone.",
  reminder: "Reminder",
  reminderConversions: "Reminder Conversions",
  emptyStateDesc1: "No reminders added yet.",
  emptyStateDesc2: "Store and manage all reminder details here to streamline your invoicing and maintain clear agreements with your customers.",
  updateReminderSuccess: "Reminder updated successfully",
};

export default locale;
