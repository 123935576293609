import { Button, CircularProgress } from '@mui/material'

type Props = {
    onClick?: (event?: React.MouseEvent<HTMLButtonElement>) => void
    title?: string
    leftIcon?: JSX.Element
    rightIcon?: JSX.Element
    color?: "primary" | "inherit" | "secondary" | "tertiary" | "success" | "error" | "info" | "warning"
    fullWidth?: boolean
    loading?: boolean
    disabled?: boolean
}

export const FilledButton = (props: Props) => {

    return <Button
        variant="contained"
        color={props?.color ?? "primary"}
        disabled={props?.disabled ? props?.disabled : props?.loading ? props?.loading : false}
        sx={{ height: 40, fontSize: { xs: "11px", md: "13px" } }}
        onClick={(e) => props?.onClick?.(e)}
        fullWidth={props?.fullWidth ?? false}
    >
        {props.loading ? <CircularProgress size={"1rem"} sx={{ mr: 1 }} /> : props?.leftIcon}
        {props?.title}
        {props?.rightIcon}
    </Button>
}

