import { createSlice } from "@reduxjs/toolkit";
import { commonInitialState, commonReducers } from "context/slices";

const { actions, reducer } = createSlice({
  name: "preSales",
  initialState: {
    ...commonInitialState,
  },
  reducers: {
    ...commonReducers,
  },
});

export const preSalesActions = actions;
export const preSalesSlice = reducer;
