

export const profileDomainRoutes = {
  base: "account",
  subdomains: {
    profile: {
      base: "profile",
    },
  },
};


export const baseAccountRoute = (): string => `/${profileDomainRoutes.base}/`;
export const listUsersRoute = (): string =>
  `/${profileDomainRoutes.base}/${profileDomainRoutes.subdomains.profile.base}`;


