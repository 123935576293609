/* eslint-disable react-hooks/exhaustive-deps */
//TODO @madraselcuk - country state city
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  InputLabel,
  Stack,
  SwipeableDrawer,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { memo, useEffect, useMemo, useState } from "react";
import { convertEnums, getCurrentLanguage } from "localization";
import { Currency, IGraphqlVariables, unknownError, Language } from "corede-common";
import {
  IInvoiceCreateInput,
  ProductType,
  PriceType,
  IInvoiceProductCreateInput,
  IBaseUserEntity,
  ITotalPriceCreateInput,
  IUserListItemResult,
  CustomerType,
  RecurringFrequency,
} from "corede-common-cocrm";
import { enqueueSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useInvoiceCreateMutation } from "../context/invoices.api";
import { validateCreateInvoicesInput } from "../validations/create.validation";
import { useProductListQuery } from "../../../../08-productsAndServices/subdomains/common/context/productsServices.api";

import ActionDrawer from "components/drawer/ActionDrawer";
import { FilledButton, OutlinedButton } from "components";
import {
  ExpandMore,
  BusinessCenterOutlined,
  Add,
  DeleteOutline,
  RefreshOutlined,
  Close,
} from "@mui/icons-material";
import { useCustomerListQuery } from "apps/crm/domains/04-customer/subdomains/customers";
import { useLeadListQuery } from "apps/crm/domains/03-lead/subdomains/lead";
import { useUserListQuery } from "apps/crm/domains/02-organizationalChart/subdomains/users";
import momentTimezone from "moment-timezone";
import countries from "datas/countries.json";
import Turkey from "datas/turkey.json";
import { ICity, ICountry, IState } from "datas/interfaces";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import ReactQuill from "react-quill-new";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { UploadImageComponent } from "components/uploadFile/UploadImage";
import { useLegalAgreementListQuery } from "apps/crm/domains/05-preSales/subdomains/legalAgreement";

interface IInvoiceCreateDrawer {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const InvoiceCreateDrawer = (props: IInvoiceCreateDrawer) => {
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();
  const timezones = momentTimezone.tz.names();
  const [cityOptions, setCityOptions] = useState<ICity[]>([]);
  const [billingCityOptions, setBillingCityOptions] = useState<ICity[]>([]);
  const [shippingCityOptions, setShippingCityOptions] = useState<ICity[]>([]);
  const [product, setProduct] = useState<IInvoiceProductCreateInput | null>(null);
  const [products, setProducts] = useState<IInvoiceProductCreateInput[]>([]);
  const [service, setService] = useState<IInvoiceProductCreateInput | null>(null);
  const [services, setServices] = useState<IInvoiceProductCreateInput[]>([]);
  const [subTotal, setSubTotal] = useState(0);
  const [taxTotal, setTaxTotal] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [finalPrice, setFinalPrice] = useState(0);
  const [salesAgent, setSalesAgent] = useState<IUserListItemResult | undefined>(undefined);
  const [salesAgentIds, setSalesAgentIds] = useState<string[]>([]);
  const [expandedPanels, setExpandedPanels] = useState<string[]>(["generalInfo"]);
  const [expandedAll, setExpandedAll] = useState(false);
  const [loading, setLoading] = useState(false);
  const [inputValueAllowedPaymentMethod, setInputValueAllowedPaymentMethod] = useState("");
  const localizedCurrencies = convertEnums(Currency);
  const localizedLanguages = convertEnums(Language);
  const localizedCustomerTypes = convertEnums(CustomerType);
  const localizedPriceTypes = convertEnums(PriceType);
  const localizedRecurringFrequencies = convertEnums(RecurringFrequency);

  // queries
  const {
    data: productListData,
    isLoading: productListLoading,
    error: productListError,
  } = useProductListQuery({
    input: {
      filter: {
        types: [ProductType.product],
      },
    },
  });
  const {
    data: serviceListData,
    isLoading: serviceListLoading,
    error: serviceListError,
  } = useProductListQuery({
    input: {
      filter: {
        types: [ProductType.service],
      },
    },
  });

  const {
    data: userListData,
    isLoading: userListLoading,
    error: userListError,
  } = useUserListQuery({});
  const {
    data: legalAgreementListData,
    isLoading: legalAgreementListLoading,
    error: legalAgreementListError,
  } = useLegalAgreementListQuery({
    input: {
      pagination: {
        sort: {
          createdAt: 1,
        },
      },
    },
  });

  const [
    invoiceCreate,
    { data: invoiceCreateData, isLoading: invoiceCreateLoading, error: invoiceCreateError },
  ] = useInvoiceCreateMutation();
  const {
    data: customerListData,
    isLoading: customerListLoading,
    error: customerListError,
  } = useCustomerListQuery({});

  const initialValues = useMemo(
    () => ({
      input: {
        prefix: "Default",
        timezone: "Turkey",
        customerId: "",
        customerName: "",
        customerType: CustomerType.individual,
        customerCity: "",
        customerCountry: "",
        customerContactEmail: "",
        customerContactLastName: "",
        customerContactFirstName: "",
        billingInfo: {
          fullName: "",
          phone: "",
          address: "",
          country: "",
          state: "",
          city: "",
          street: "",
          zip: "",
          vat: "",
          taxOffice: "",
        },
        shippingInfo: {
          fullName: "",
          phone: "",
          address: "",
          country: "",
          state: "",
          city: "",
          street: "",
          zip: "",
        },
        isRecurring: false,
        recurringFrequency: RecurringFrequency.everyday,
        recurringDueDate: new Date(),
        recurringCount: 0,
        language: Language.en,
        date: new Date(),
        expireDate: new Date(),
        sendEmailOnExpiration: true,
        expirationEmailSentDate: new Date(),
        allowedPaymentMethods: ["Bank"],
        paymentTerms: "",
        currency: Currency.TRY,
        products: products,
        totalPrice: {
          shippingTotal: undefined,
          extraDiscount: undefined,
        },
        salesAgentIds: [],
        allowComments: true, useLegalNDA: true,
        legalNDAId: undefined,
        useLegalTermAndCond: true,
        legalTermAndCondId: undefined,
        signerUserId: "",
        signatureImage: undefined,
        assumptionsAndExclusions: undefined,
        costBreakdown: undefined,
        projectTimeline: undefined,
        testimonialCaseStudies: undefined,
        offers: undefined,
        reference: undefined,
      },
    }),
    []
  );

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    reset,
    control,
    formState: { errors, isValid },
  } = useForm<IGraphqlVariables<IInvoiceCreateInput>>({
    defaultValues: initialValues,
    resolver: yupResolver(validateCreateInvoicesInput),
    mode: "onSubmit",
  });


  const onSubmit = async (values: IGraphqlVariables<IInvoiceCreateInput>) => {
    setLoading(true);
    invoiceCreate({ input: values.input });
    setLoading(false);
  };

  const handleAccordionChange = ({
    panel,
    product,
    service,
  }: {
    panel: string;
    product?: IInvoiceProductCreateInput;
    service?: IInvoiceProductCreateInput;
  }) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    if (panel?.includes("product")) {
      setProduct(product ?? null);
      setExpandedPanels((prev) =>
        isExpanded
          ? [...prev.filter((p) => !p.includes("product")), panel]
          : prev.filter((p) => p !== panel)
      );
    } else if (panel?.includes("service")) {
      setService(service ?? null);
      setExpandedPanels((prev) =>
        isExpanded
          ? [...prev.filter((s) => !s.includes("service")), panel]
          : prev.filter((s) => s !== panel)
      );
    } else {
      if (isExpanded) {
        setExpandedPanels((prev) => [...prev, panel]);
      } else {
        setExpandedPanels((prev) => prev.filter((p) => p !== panel));
      }
    }
  };

  const handleExpandAll = () => {
    if (expandedAll) {
      setExpandedPanels([]);
    } else {
      setExpandedPanels(["generalInfo", "customer", "expiration", "detailedInformation", "extraInformation",
        "products", "services", "totally", "legal", "salesAgents", "reminders", "documents", "notes", "signature"]);
    }
    setExpandedAll(!expandedAll);
  };

  useEffect(() => {
    if (invoiceCreateData) {
      props.setOpen(false);
      enqueueSnackbar(t("crm.sales.invoices.invoiceCreateSuccess"), { variant: "success" });
      reset();
    }
  }, [invoiceCreateData]);

  useEffect(() => {
    if (invoiceCreateError) {
      enqueueSnackbar(
        (invoiceCreateError as any)?.error?.message[currentLanguage] ??
        unknownError.message[currentLanguage],
        { variant: "error" }
      );
    }
  }, [invoiceCreateError]);

  const customerId = watch("input.customerId");

  useEffect(() => {
    if (customerId) {
      const customer = customerListData?.data?.find((c) => c._id === customerId);
      setValue("input.customerName", customer?.name || "");
      setValue("input.customerType", customer?.type || CustomerType.individual);
      setValue("input.customerCity", customer?.city || "");
      setValue("input.customerCountry", customer?.country || "");
      setValue("input.customerContactEmail", customer?.primaryContact?.email || "");
      setValue("input.customerContactLastName", customer?.primaryContact?.lastName || "");
      setValue("input.customerContactFirstName", customer?.primaryContact?.firstName || "");
      setValue("input.billingInfo.fullName", customer?.primaryBillingInfo?.fullName || "");
      setValue("input.billingInfo.phone", customer?.primaryBillingInfo?.phone || "");
      setValue("input.billingInfo.address", customer?.primaryBillingInfo?.address || "");
      setValue("input.billingInfo.country", customer?.primaryBillingInfo?.country || "");
      setValue("input.billingInfo.state", customer?.primaryBillingInfo?.state || "");
      setValue("input.billingInfo.city", customer?.primaryBillingInfo?.city || "");
      setValue("input.billingInfo.street", customer?.primaryBillingInfo?.street || "");
      setValue("input.billingInfo.zip", customer?.primaryBillingInfo?.zip || "");
      setValue("input.billingInfo.vat", customer?.primaryBillingInfo?.vat || "");
      setValue("input.billingInfo.taxOffice", customer?.primaryBillingInfo?.taxOffice || "");
      setValue("input.shippingInfo.fullName", customer?.primaryShippingInfo?.fullName || "");
      setValue("input.shippingInfo.phone", customer?.primaryShippingInfo?.phone || "");
      setValue("input.shippingInfo.address", customer?.primaryShippingInfo?.address || "");
      setValue("input.shippingInfo.country", customer?.primaryShippingInfo?.country || "");
      setValue("input.shippingInfo.state", customer?.primaryShippingInfo?.state || "");
      setValue("input.shippingInfo.city", customer?.primaryShippingInfo?.city || "");
      setValue("input.shippingInfo.street", customer?.primaryShippingInfo?.street || "");
      setValue("input.shippingInfo.zip", customer?.primaryShippingInfo?.zip || "");

    } else {
      setValue("input.customerName", "");
      setValue("input.customerType", CustomerType.individual);
      setValue("input.customerCity", "");
      setValue("input.customerCountry", "");
      setValue("input.customerContactEmail", "");
      setValue("input.customerContactLastName", "");
      setValue("input.customerContactFirstName", "");
      setValue("input.billingInfo.fullName", "");
      setValue("input.billingInfo.phone", "");
      setValue("input.billingInfo.address", "");
      setValue("input.billingInfo.country", "");
      setValue("input.billingInfo.state", "");
      setValue("input.billingInfo.city", "");
      setValue("input.billingInfo.street", "");
      setValue("input.billingInfo.zip", "");
      setValue("input.billingInfo.vat", "");
      setValue("input.billingInfo.taxOffice", "");
      setValue("input.shippingInfo.fullName", "");
      setValue("input.shippingInfo.phone", "");
      setValue("input.shippingInfo.address", "");
      setValue("input.shippingInfo.country", "");
      setValue("input.shippingInfo.state", "");
      setValue("input.shippingInfo.city", "");
      setValue("input.shippingInfo.street", "");
      setValue("input.shippingInfo.zip", "");
    }
  }, [customerId]);



  useEffect(() => {
    if (products) {
      setValue("input.products", products);
    }
  }, [products]);

  useEffect(() => {
    if (services) {
      setValue("input.products", services);
    }
  }, [services]);

  useEffect(() => {
    if (products && services) {
      setSubTotal(
        products?.reduce(
          (total: number, product: any) =>
            total + (product?.productUnit || 1) * (product?.discountedPrice || 0),
          0
        ) +
        services?.reduce(
          (total: number, service: any) =>
            total + (service?.productUnit || 1) * (service?.discountedPrice || 0),
          0
        )
      );
    }
  }, [products, services]);

  useEffect(() => {
    if (products && services) {
      setTaxTotal(
        products?.reduce(
          (total: number, product: any) =>
            total + (product?.productUnit || 1) * (product?.tax || 0),
          0
        ) +
        services?.reduce(
          (total: number, service: any) =>
            total + (service?.productUnit || 1) * (service?.tax || 0),
          0
        )
      );
    }
  }, [products, services]);

  useEffect(() => {
    if (products && services) {
      setFinalPrice(subTotal + taxTotal - discount);
    }
  }, [subTotal, taxTotal, discount]);

  useEffect(() => {
    if (salesAgentIds) {
      setValue("input.salesAgentIds", salesAgentIds);
    }
  }, [salesAgentIds]);

  useEffect(() => {
    const selectedState = Turkey.states.find((state) => state.name === watch("input.billingInfo.state"));
    if (selectedState && selectedState.cities) {
      setBillingCityOptions(selectedState.cities);
    } else {
      setBillingCityOptions([]);
    }
    setValue("input.billingInfo.city", watch("input.billingInfo.city") || "");
  }, [watch("input.billingInfo.state")]);



  return <ActionDrawer
    open={props.open}
    setOpen={props.setOpen}
    title={
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Typography variant="h4" gutterBottom>{t("crm.sales.invoices.createInvoice")}</Typography>
        <OutlinedButton
          title={expandedAll ? "Collapse All" : "Expand All"}
          onClick={handleExpandAll}
          leftIcon={expandedAll ? <ExpandMore sx={{ transform: "rotate(180deg)" }} /> : <ExpandMore />}
        />
      </Box>
    }
    handleSubmit={handleSubmit(onSubmit)}
    disabled={
      loading ||
      !isValid ||
      invoiceCreateLoading ||
      !watch("input.prefix")
    }
    loading={loading || invoiceCreateLoading}
    buttonTitle={t("crm.sales.invoices.create")}
    size="large"
  >
    <Grid item xs={12}>
      <Grid container spacing={2}>
        {/* Left Panel */}
        <Grid item xs={12} sm={6}>
          <Grid container gap={2}>

            {/* General Info */}
            <Grid item xs={12}>
              <Accordion expanded={expandedPanels.includes("generalInfo")}
                onChange={handleAccordionChange({ panel: "generalInfo" })} sx={{
                  width: '100%',
                  '&.MuiAccordion-root': {
                    boxShadow: 'none',
                    borderRadius: "16px",
                    border: '1px dashed',
                    borderColor: 'secondary.main',
                    '&.Mui-expanded': {
                      borderStyle: 'solid',
                      borderColor: 'primary.main',
                    },
                  }
                }}>
                <AccordionSummary expandIcon={<ExpandMore />} sx={{
                  borderRadius: "16px",
                  '&.Mui-expanded': {
                    bgcolor: 'primary.lighter', borderBottomLeftRadius: 0, borderBottomRightRadius: 0
                  },
                }}>
                  <BusinessCenterOutlined sx={{ mr: 1 }} fontSize="small" color="secondary" />
                  <Typography fontSize={"small"} fontWeight={500} sx={{ color: 'secondary.main' }}>General Info</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    <Grid item xs={6} >
                      <InputLabel htmlFor="input.prefix" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.prefix")}*</InputLabel>
                      <Controller
                        name="input.prefix"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            id="input.prefix"
                            size="small"
                            fullWidth
                            required
                            onChange={(e) => {
                              const value = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
                              field.onChange(value);
                            }}
                            error={!!errors.input?.prefix}
                            helperText={errors.input?.prefix?.message}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6} >
                      <InputLabel htmlFor="timezone-autocomplete" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.timezone")}*</InputLabel>
                      <Controller
                        name="input.timezone"
                        control={control}
                        render={({ field }) => (
                          <Autocomplete
                            {...field}
                            id="timezone-autocomplete"
                            size="small"
                            isOptionEqualToValue={(option, value) => option._id === value._id}
                            options={timezones.map((timezone) => ({
                              name: timezone,
                              _id: timezone,
                            }))}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => <TextField {...params} />}
                            disableClearable
                            value={
                              field.value
                                ? { name: field.value, _id: field.value }
                                : undefined
                            }
                            onChange={(e, value) => {
                              field.onChange(value?._id);
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <InputLabel htmlFor="language" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.language")}*</InputLabel>
                      <Controller
                        name="input.language"
                        control={control}
                        render={({ field }) => (
                          <Autocomplete
                            id="language"
                            size="small"
                            fullWidth
                            isOptionEqualToValue={(option, value) => option._id === value._id}
                            options={Object.values(Language).map((language) => ({
                              name: localizedLanguages[language as keyof typeof Language],
                              _id: language,
                            }))}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => <TextField {...params} />}
                            value={
                              field.value
                                ? { name: localizedLanguages[field.value as keyof typeof Language], _id: field.value }
                                : null
                            }
                            onChange={(e, value) => {
                              field.onChange(value?._id);
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <InputLabel htmlFor="currency" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.currency")}*</InputLabel>
                      <Controller
                        name="input.currency"
                        control={control}
                        render={({ field }) => (
                          <Autocomplete
                            id="currency"
                            fullWidth
                            disableClearable
                            isOptionEqualToValue={(option, value) => option._id === value._id}
                            size="small"
                            options={Object.values(Currency).map((currency) => ({
                              name: localizedCurrencies[currency as keyof typeof Currency],
                              _id: currency,
                            }))}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => <TextField {...params} />}
                            value={
                              field.value
                                ? { name: localizedCurrencies[field.value as keyof typeof Currency], _id: field.value }
                                : undefined
                            }
                            onChange={(e, value) => {
                              field.onChange(value?._id);
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6} >
                      <InputLabel htmlFor="date" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.date")}*</InputLabel>
                      <Controller
                        name="input.date"
                        control={control}
                        render={({ field }) => (
                          <DatePicker
                            {...field}
                            value={field.value ? moment(field.value) : null}
                            onChange={(newValue) => {
                              field.onChange(moment(newValue).toDate());
                            }}
                            slotProps={{ textField: { size: "small", id: "date" } }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6} >
                      <InputLabel htmlFor="expireDate" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.dueDate")}*</InputLabel>
                      <Controller
                        name="input.expireDate"
                        control={control}
                        render={({ field }) => (
                          <DatePicker
                            {...field}
                            value={field.value ? moment(field.value) : null}
                            onChange={(newValue) => {
                              field.onChange(moment(newValue).toDate());
                            }}
                            slotProps={{ textField: { size: "small", id: "expireDate" } }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.sendEmailOnExpiration")}</InputLabel>
                      <Box sx={{ border: "1px solid #ccc", borderRadius: "14px", p: 0.65, display: "flex", alignItems: "center" }}>
                        <Controller
                          name="input.sendEmailOnExpiration"
                          control={control}
                          render={({ field }) => (
                            <Switch
                              {...field}
                              checked={field.value}
                              color="secondary"
                              size="small"
                              onChange={(e) => field.onChange(e.target.checked)}
                            />
                          )}
                        />
                        <Typography sx={{ ml: 1, fontSize: 'small' }}>{t("crm.sales.invoices.sendEmail")}</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6} >
                      <InputLabel htmlFor="expirationEmailSentDate" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.expirationEmailSentDate")}*</InputLabel>
                      <Controller
                        name="input.expirationEmailSentDate"
                        disabled={!watch("input.sendEmailOnExpiration")}
                        control={control}
                        render={({ field }) => (
                          <DatePicker
                            {...field}
                            value={watch("input.sendEmailOnExpiration") && field.value ? moment(field.value) : null}
                            onChange={(newValue) => {
                              field.onChange(moment(newValue).toDate());
                            }}
                            slotProps={{ textField: { size: "small", id: "expirationEmailSentDate" } }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.allowedPaymentMethods")}</InputLabel>
                      <Controller
                        name="input.allowedPaymentMethods"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <Autocomplete
                            {...field}
                            multiple
                            freeSolo
                            size="small"
                            id="allowedPaymentMethods"
                            options={["Bank", "Credit Card", "PayPal"]}
                            value={field.value || []}
                            inputValue={inputValueAllowedPaymentMethod}
                            onInputChange={(event, newInputValue) => {
                              setInputValueAllowedPaymentMethod(newInputValue);
                            }}
                            onChange={(event, newValue) => {
                              field.onChange(newValue);
                            }}
                            onKeyDown={(event) => {
                              if (event.key === 'Enter' || event.key === ',') {
                                event.preventDefault();
                                const inputValueTrimmed = inputValueAllowedPaymentMethod.trim();
                                if (inputValueTrimmed !== '') {
                                  field.onChange([...(field.value || []), inputValueTrimmed]);
                                  setInputValueAllowedPaymentMethod('');
                                }
                              }
                            }}
                            renderTags={(value, getAllowedPaymentMethodsProps) =>
                              (value || []).map((option, index) => {
                                const { key, ...tagProps } = getAllowedPaymentMethodsProps({ index });
                                return (
                                  <Chip
                                    key={key}
                                    size="small"
                                    variant="outlined"
                                    label={option}
                                    {...tagProps}
                                  />
                                );
                              })
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                placeholder={t("crm.sales.invoices.allowedPaymentMethodsPlaceholder")}
                                error={Boolean(error)}
                                helperText={error ? error.message : ""}
                              />
                            )}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <InputLabel htmlFor="currency" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.currency")}*</InputLabel>
                      <Controller
                        name="input.currency"
                        control={control}
                        render={({ field }) => (
                          <Autocomplete
                            id="currency"
                            fullWidth
                            disableClearable
                            isOptionEqualToValue={(option, value) => option._id === value._id}
                            size="small"
                            options={Object.values(Currency).map((currency) => ({
                              name: localizedCurrencies[currency as keyof typeof Currency],
                              _id: currency,
                            }))}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => <TextField {...params} />}
                            value={
                              field.value
                                ? { name: localizedCurrencies[field.value as keyof typeof Currency], _id: field.value }
                                : undefined
                            }
                            onChange={(e, value) => {
                              field.onChange(value?._id);
                            }}
                          />
                        )}
                      />

                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>


            {/* Customer */}
            <Grid item xs={12} sx={{
              p: 1,
              boxShadow: 'none',
              borderRadius: "16px",
              border: '1px solid',
              borderColor: 'primary.light',
            }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box>
                    <Typography color={"text.primary"} fontSize={14} fontWeight={600}>{t("crm.sales.invoices.customer")}</Typography>
                    <Divider />
                  </Box>
                </Grid>
                {/* Customer */}
                <Grid item xs={12}>
                  <Accordion expanded={expandedPanels.includes("customer")}
                    onChange={handleAccordionChange({ panel: "customer" })} sx={{
                      width: '100%',
                      '&.MuiAccordion-root': {
                        boxShadow: 'none',
                        borderRadius: "16px",
                        border: '1px dashed',
                        borderColor: 'secondary.main',
                        '&.Mui-expanded': {
                          borderStyle: 'solid',
                          borderColor: 'primary.main',
                        },
                      }
                    }}>
                    <AccordionSummary expandIcon={<ExpandMore />} sx={{
                      borderRadius: "16px",
                      '&.Mui-expanded': {
                        bgcolor: 'primary.lighter', borderBottomLeftRadius: 0, borderBottomRightRadius: 0
                      },
                    }}>
                      <BusinessCenterOutlined sx={{ mr: 1 }} fontSize="small" color="secondary" />
                      <Typography fontSize={"small"} fontWeight={500} sx={{ color: 'secondary.main' }}>Customer Info</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={1.5}>
                        <Grid item xs={6}>
                          <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.customer")}*</InputLabel>
                          <Controller
                            name="input.customerId"
                            control={control}
                            render={({ field }) => (
                              <Autocomplete
                                id="input.customerId"
                                isOptionEqualToValue={(option, value) => option._id === value._id}
                                fullWidth
                                size="small"
                                disableClearable
                                getOptionLabel={(option) => option.name}
                                options={[...(customerListData?.data || []), { _id: "", name: "" }]}
                                loading={customerListLoading}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    error={!!errors?.input?.customerId}
                                    helperText={errors?.input?.customerId?.message}
                                  />
                                )}
                                value={customerListData?.data?.find(customer => customer._id === field.value) || { _id: "", name: "" }}
                                onChange={(e, value) => field.onChange(value?._id || "")}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <InputLabel htmlFor="input.customerType" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.customerType")}*</InputLabel>
                          <Controller
                            name="input.customerType"
                            control={control}
                            render={({ field }) => (
                              <Autocomplete
                                id="input.customerType"
                                size="small"
                                fullWidth
                                disableClearable
                                isOptionEqualToValue={(option, value) => option._id === value._id}
                                options={Object.values(CustomerType).map((customerType) => ({
                                  name: localizedCustomerTypes[customerType as keyof typeof CustomerType],
                                  _id: customerType,
                                }))}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => <TextField {...params} />}
                                value={
                                  field.value
                                    ? { name: localizedCustomerTypes[field.value as keyof typeof CustomerType], _id: field.value }
                                    : undefined
                                }
                                onChange={(e, value) => field.onChange(value?._id)}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Controller
                            name="input.customerName"
                            control={control}
                            render={({ field, formState: { errors } }) => (
                              <>
                                <InputLabel htmlFor="input.customerName" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>
                                  {watch("input.customerType") === CustomerType.individual
                                    ? t("crm.sales.invoices.fullName")
                                    : watch("input.customerType") === CustomerType.business
                                      ? t("crm.sales.invoices.businessName")
                                      : watch("input.customerType") === CustomerType.government
                                        ? t("crm.sales.invoices.governmentAgency")
                                        : ""}
                                  *
                                </InputLabel>
                                <TextField
                                  {...field}
                                  id="input.customerName"
                                  size="small"
                                  fullWidth
                                  required
                                  onChange={(e) => field.onChange(e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                                  error={!!errors.input?.customerName}
                                  helperText={errors.input?.customerName?.message}
                                />
                              </>
                            )}
                          />
                        </Grid>
                        <Grid item xs={6} >
                          <InputLabel htmlFor="input.customerCountry" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.country")}*</InputLabel>
                          <Controller
                            name="input.customerCountry"
                            control={control}
                            render={({ field }) => (
                              <Autocomplete
                                options={[...countries, { name: "", id: 0, iso2: "", iso3: "" }]}
                                size="small"
                                fullWidth
                                id="input.customerCountry"
                                isOptionEqualToValue={(option, value) => option.name === value?.name}
                                getOptionLabel={(option) => option.name}
                                onChange={(event, newValue) => {
                                  if (newValue) {
                                    field.onChange(newValue.name);
                                  } else {
                                    field.onChange("");
                                  }
                                }}
                                value={
                                  field.value
                                    ? countries.find((country) => country.name === field.value) || null
                                    : null
                                }
                                renderInput={(params) => <TextField {...params} />}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={6} >
                          <InputLabel htmlFor="input.customerCity" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>
                            {t("crm.sales.invoices.city")}*
                          </InputLabel>
                          <Controller
                            name="input.customerCity"
                            control={control}
                            render={({ field }) => (
                              <Autocomplete
                                options={Turkey?.states || []}
                                size="small"
                                fullWidth
                                id="input.customerCity"
                                getOptionLabel={(option) => option.name}
                                isOptionEqualToValue={(option, value) => option.name === value?.name}
                                onChange={(event, newValue) => {
                                  if (newValue) {
                                    field.onChange(newValue.name);
                                  } else {
                                    field.onChange('');
                                  }
                                }}
                                value={Turkey?.states.find((state) => state.name === field.value) || null}
                                renderInput={(params) => <TextField {...params} />}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={6} >
                          <InputLabel htmlFor="input.customerContactFirstName" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.contactFirstName")}*</InputLabel>
                          <Controller
                            name="input.customerContactFirstName"
                            control={control}
                            render={({ field, formState: { errors } }) => (
                              <TextField
                                {...field}
                                id="input.customerContactFirstName"
                                size="small"
                                fullWidth
                                required
                                onChange={(e) => field.onChange(e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                                error={!!errors.input?.customerContactFirstName}
                                helperText={errors.input?.customerContactFirstName?.message}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={6} >
                          <InputLabel htmlFor="input.customerContactLastName" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.contactLastName")}*</InputLabel>
                          <Controller
                            name="input.customerContactLastName"
                            control={control}
                            render={({ field, formState: { errors } }) => (
                              <TextField
                                {...field}
                                id="input.customerContactLastName"
                                size="small"
                                fullWidth
                                required
                                onChange={(e) => field.onChange(e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                                error={!!errors.input?.customerContactLastName}
                                helperText={errors.input?.customerContactLastName?.message}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={6} >
                          <InputLabel htmlFor="input.customerContactEmail" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.contactEmail")}*</InputLabel>
                          <Controller
                            name="input.customerContactEmail"
                            control={control}
                            render={({ field, formState: { errors } }) => (
                              <TextField
                                {...field}
                                id="input.customerContactEmail"
                                size="small"
                                fullWidth
                                required
                                onChange={(e) => field.onChange(e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                                error={!!errors.input?.customerContactEmail}
                                helperText={errors.input?.customerContactEmail?.message}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
                {/* Billing Info */}
                <Grid item xs={12}>
                  <Accordion expanded={expandedPanels.includes("billingInfo")}
                    onChange={handleAccordionChange({ panel: "billingInfo" })} sx={{
                      width: '100%',
                      '&.MuiAccordion-root': {
                        boxShadow: 'none',
                        borderRadius: "16px",
                        border: '1px dashed',
                        borderColor: 'secondary.main',
                        '&.Mui-expanded': {
                          borderStyle: 'solid',
                          borderColor: 'primary.main',
                        },
                      }
                    }}>
                    <AccordionSummary expandIcon={<ExpandMore />} sx={{
                      borderRadius: "16px",
                      '&.Mui-expanded': {
                        bgcolor: 'primary.lighter', borderBottomLeftRadius: 0, borderBottomRightRadius: 0
                      },
                    }}>
                      <BusinessCenterOutlined sx={{ mr: 1 }} fontSize="small" color="secondary" />
                      <Typography fontSize={"small"} fontWeight={500} sx={{ color: 'secondary.main' }}>Billing Info</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={1.5}>
                        <Grid item xs={6}>
                          <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>
                            {t("crm.sales.invoices.billingInfo.fullName")}*
                          </InputLabel>
                          <Controller
                            name="input.billingInfo.fullName"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                              <TextField
                                {...field}
                                fullWidth
                                size="small"
                                required
                                onChange={(e) => field.onChange(e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                                onBlur={field.onBlur}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>
                            {t("crm.sales.invoices.billingInfo.phone")}*
                          </InputLabel>
                          <Controller
                            name="input.billingInfo.phone"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                              <TextField
                                {...field}
                                fullWidth
                                size="small"
                                required
                                onChange={(e) => field.onChange(e.target.value)}
                                onBlur={field.onBlur}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>
                            {t("crm.sales.invoices.billingInfo.country")}
                          </InputLabel>
                          <Controller
                            name="input.billingInfo.country"
                            control={control}
                            render={({ field }) => (
                              <Autocomplete
                                {...field}
                                size="small"
                                options={countries}
                                fullWidth
                                id="billingInfo.country"
                                getOptionLabel={(option: ICountry) => option.name}
                                onChange={(event, newValue) => {
                                  field.onChange(newValue ? newValue.name : "");
                                }}
                                value={countries.find((country) => country.name === field.value) || null}
                                renderInput={(params) => <TextField {...params} />}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>
                            {t("crm.sales.invoices.billingInfo.state")}
                          </InputLabel>
                          <Controller
                            name="input.billingInfo.state"
                            control={control}
                            render={({ field }) => (
                              <Autocomplete
                                {...field}
                                size="small"
                                options={Turkey.states}
                                fullWidth
                                id="billingInfo.state"
                                getOptionLabel={(option: IState) => option.name}
                                onChange={(event, newValue) => {
                                  field.onChange(newValue ? newValue.name : "");
                                  setValue("input.billingInfo.city", '');
                                }}
                                value={Turkey.states.find((state) => state.name === field.value) || null}
                                renderInput={(params) => <TextField {...params} />}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>
                            {t("crm.sales.invoices.billingInfo.city")}
                          </InputLabel>
                          <Controller
                            name="input.billingInfo.city"
                            control={control}
                            render={({ field }) => (
                              <Autocomplete
                                {...field}
                                size="small"
                                options={billingCityOptions}
                                fullWidth
                                id="billingInfo.city"
                                getOptionLabel={(option: ICity) => option.name}
                                onChange={(event, newValue) => {
                                  field.onChange(newValue ? newValue.name : "");
                                }}
                                value={billingCityOptions.find((city) => city.name === field.value) || null}
                                renderInput={(params) => <TextField {...params} />}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={6} >
                          <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.billingInfo.street")}*</InputLabel>
                          <Controller
                            name="input.billingInfo.street"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                              <TextField
                                {...field}
                                fullWidth
                                size="small"
                                required
                                onChange={(e) => field.onChange(e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                                onBlur={field.onBlur}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={6} >
                          <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.billingInfo.zip")}*</InputLabel>
                          <Controller
                            name="input.billingInfo.zip"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                              <TextField
                                {...field}
                                fullWidth
                                size="small"
                                required
                                onChange={(e) => field.onChange(e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                                onBlur={field.onBlur}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={6} >
                          <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.billingInfo.vat")}*</InputLabel>
                          <Controller
                            name="input.billingInfo.vat"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                              <TextField
                                {...field}
                                fullWidth
                                size="small"
                                required
                                onChange={(e) => field.onChange(e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                                onBlur={field.onBlur}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={6} >
                          <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.billingInfo.taxOffice")}*</InputLabel>
                          <Controller
                            name="input.billingInfo.taxOffice"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                              <TextField
                                {...field}
                                fullWidth
                                size="small"
                                required
                                onChange={(e) => field.onChange(e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                                onBlur={field.onBlur}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} >
                          <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.billingInfo.address")}*</InputLabel>
                          <Controller
                            name="input.billingInfo.address"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                              <TextField
                                {...field}
                                fullWidth
                                size="small"
                                required
                                onChange={(e) => field.onChange(e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                                onBlur={field.onBlur}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
                {/* Shipping Info */}
                <Grid item xs={12}>
                  <Accordion expanded={expandedPanels.includes("shippingInfo")}
                    onChange={handleAccordionChange({ panel: "shippingInfo" })} sx={{
                      width: '100%',
                      '&.MuiAccordion-root': {
                        boxShadow: 'none',
                        borderRadius: "16px",
                        border: '1px dashed',
                        borderColor: 'secondary.main',
                        '&.Mui-expanded': {
                          borderStyle: 'solid',
                          borderColor: 'primary.main',
                        },
                      }
                    }}>
                    <AccordionSummary expandIcon={<ExpandMore />} sx={{
                      borderRadius: "16px",
                      '&.Mui-expanded': {
                        bgcolor: 'primary.lighter', borderBottomLeftRadius: 0, borderBottomRightRadius: 0
                      },
                    }}>
                      <BusinessCenterOutlined sx={{ mr: 1 }} fontSize="small" color="secondary" />
                      <Typography fontSize={"small"} fontWeight={500} sx={{ color: 'secondary.main' }}>Shipping Info</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={1.5}>
                        <Grid item xs={6}>
                          <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>
                            {t("crm.sales.invoices.shippingInfo.fullName")}*
                          </InputLabel>
                          <Controller
                            name="input.shippingInfo.fullName"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                              <TextField
                                {...field}
                                fullWidth
                                size="small"
                                required
                                onChange={(e) => field.onChange(e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                                onBlur={field.onBlur}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>
                            {t("crm.sales.invoices.shippingInfo.phone")}*
                          </InputLabel>
                          <Controller
                            name="input.shippingInfo.phone"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                              <TextField
                                {...field}
                                fullWidth
                                size="small"
                                required
                                onChange={(e) => field.onChange(e.target.value)}
                                onBlur={field.onBlur}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>
                            {t("crm.sales.invoices.shippingInfo.address")}*
                          </InputLabel>
                          <Controller
                            name="input.shippingInfo.address"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                              <TextField
                                {...field}
                                fullWidth
                                size="small"
                                required
                                onChange={(e) => field.onChange(e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                                onBlur={field.onBlur}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>
                            {t("crm.sales.invoices.shippingInfo.country")}
                          </InputLabel>
                          <Controller
                            name="input.shippingInfo.country"
                            control={control}
                            render={({ field }) => (
                              <Autocomplete
                                {...field}
                                size="small"
                                options={countries}
                                fullWidth
                                id="shippingInfo.country"
                                getOptionLabel={(option: ICountry) => option.name}
                                onChange={(event, newValue) => {
                                  field.onChange(newValue ? newValue.name : "");
                                }}
                                value={countries.find((country) => country.name === field.value) || null}
                                renderInput={(params) => <TextField {...params} />}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>
                            {t("crm.sales.invoices.shippingInfo.state")}
                          </InputLabel>
                          <Controller
                            name="input.shippingInfo.state"
                            control={control}
                            render={({ field }) => (
                              <Autocomplete
                                {...field}
                                size="small"
                                options={Turkey.states}
                                fullWidth
                                id="shippingInfo.state"
                                getOptionLabel={(option: IState) => option.name}
                                onChange={(event, newValue) => {
                                  field.onChange(newValue ? newValue.name : "");
                                  setValue("input.shippingInfo.city", '');
                                }}
                                value={Turkey.states.find((state) => state.name === field.value) || null}
                                renderInput={(params) => <TextField {...params} />}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>
                            {t("crm.sales.invoices.shippingInfo.city")}
                          </InputLabel>
                          <Controller
                            name="input.shippingInfo.city"
                            control={control}
                            render={({ field }) => (
                              <Autocomplete
                                {...field}
                                size="small"
                                options={billingCityOptions}
                                fullWidth
                                id="shippingInfo.city"
                                getOptionLabel={(option: ICity) => option.name}
                                onChange={(event, newValue) => {
                                  field.onChange(newValue ? newValue.name : "");
                                }}
                                value={billingCityOptions.find((city) => city.name === field.value) || null}
                                renderInput={(params) => <TextField {...params} />}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={6} >
                          <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.shippingInfo.street")}*</InputLabel>
                          <Controller
                            name="input.shippingInfo.street"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                              <TextField
                                {...field}
                                fullWidth
                                size="small"
                                required
                                onChange={(e) => field.onChange(e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                                onBlur={field.onBlur}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={6} >
                          <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.shippingInfo.zip")}*</InputLabel>
                          <Controller
                            name="input.shippingInfo.zip"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                              <TextField
                                {...field}
                                fullWidth
                                size="small"
                                required
                                onChange={(e) => field.onChange(e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                                onBlur={field.onBlur}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} >
                          <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.shippingInfo.address")}*</InputLabel>
                          <Controller
                            name="input.shippingInfo.address"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                              <TextField
                                {...field}
                                fullWidth
                                size="small"
                                required
                                onChange={(e) => field.onChange(e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                                onBlur={field.onBlur}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </Grid>
            </Grid>

            {/* Detailed Information */}
            <Grid item xs={12}>
              <Accordion expanded={expandedPanels.includes("detailedInformation")}
                onChange={handleAccordionChange({ panel: "detailedInformation" })} sx={{
                  width: '100%',
                  '&.MuiAccordion-root': {
                    boxShadow: 'none',
                    borderRadius: "16px",
                    border: '1px dashed',
                    borderColor: 'secondary.main',
                    '&.Mui-expanded': {
                      borderStyle: 'solid',
                      borderColor: 'primary.main',
                    },
                  }
                }}>
                <AccordionSummary expandIcon={<ExpandMore />} sx={{
                  borderRadius: "16px",
                  '&.Mui-expanded': {
                    bgcolor: 'primary.lighter', borderBottomLeftRadius: 0, borderBottomRightRadius: 0
                  },
                }}>
                  <BusinessCenterOutlined sx={{ mr: 1 }} fontSize="small" color="secondary" />
                  <Typography fontSize={"small"} fontWeight={500} sx={{ color: 'secondary.main' }}>Detailed Information</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{"."}</InputLabel>
                      <Box sx={{ border: "1px solid #ccc", borderRadius: "14px", p: 0.65, display: "flex", alignItems: "center" }}>
                        <Controller
                          name="input.allowComments"
                          control={control}
                          render={({ field }) => (
                            <Switch
                              {...field}
                              checked={field.value}
                              color="secondary"
                              size="small"
                              onChange={(e) => field.onChange(e.target.checked)}
                            />
                          )}
                        />
                        <Typography sx={{ ml: 1, fontSize: 'small' }}>{t("crm.sales.invoices.allowComments")}</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.paymentTerms")}*</InputLabel>
                      <Controller
                        name="input.paymentTerms"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <TextField
                            {...field}
                            fullWidth
                            size="small"
                            required
                            onChange={(e) => field.onChange(e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                            error={!!error}
                            helperText={error ? error.message : ""}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{"."}</InputLabel>
                      <Box sx={{ border: "1px solid #ccc", borderRadius: "14px", p: 1, display: "flex", alignItems: "center" }}>
                        <Controller
                          name="input.isRecurring"
                          control={control}
                          render={({ field }) => (
                            <Switch
                              {...field}
                              checked={field.value}
                              color="secondary"
                              size="small"
                              onChange={(e) => {
                                field.onChange(e.target.checked);
                              }}
                            />
                          )}
                        />
                        <Typography sx={{ ml: 1, fontSize: 'small' }}>{t("crm.sales.invoices.isRecurring")}</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.recurringFrequency")}*</InputLabel>
                      <Controller
                        name="input.recurringFrequency"
                        disabled={!watch("input.isRecurring")}
                        control={control}
                        render={({ field }) => (
                          <Autocomplete
                            {...field}
                            size="small"
                            id="recurringFrequency"
                            fullWidth
                            options={Object.values(RecurringFrequency).map((recurringFrequency) => ({
                              name: localizedRecurringFrequencies[recurringFrequency as keyof typeof RecurringFrequency],
                              _id: recurringFrequency,
                            }))}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => <TextField {...params} />}
                            value={
                              watch("input.isRecurring") && field.value
                                ? {
                                  name: localizedRecurringFrequencies[field.value as keyof typeof RecurringFrequency],
                                  _id: field.value,
                                }
                                : null
                            }
                            onChange={(e, value) => {
                              field.onChange(value?._id);
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.recurringCount")}*</InputLabel>
                      <Controller
                        name="input.recurringCount"
                        disabled={!watch("input.isRecurring")}
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <TextField
                            {...field}
                            fullWidth
                            required
                            size="small"
                            type="number"
                            value={watch("input.isRecurring") && field.value}
                            onChange={(e) => field.onChange(Number(e.target.value))}
                            onBlur={field.onBlur}
                            error={!!error}
                            helperText={error ? error.message : null}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6} >
                      <InputLabel htmlFor="recurringDueDate" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.recurringDueDate")}*</InputLabel>
                      <Controller
                        name="input.recurringDueDate"
                        disabled={!watch("input.isRecurring")}
                        control={control}
                        render={({ field }) => (
                          <DatePicker
                            {...field}
                            value={watch("input.isRecurring") && field.value ? moment(field.value) : null}
                            onChange={(newValue) => {
                              field.onChange(moment(newValue).toDate());
                            }}
                            slotProps={{ textField: { size: "small", id: "recurringDueDate" } }}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>

            {/* Reminders */}
            <Grid item xs={12}>
              <Accordion expanded={expandedPanels.includes("reminders")}
                onChange={handleAccordionChange({ panel: "reminders" })} sx={{
                  width: '100%',
                  '&.MuiAccordion-root': {
                    boxShadow: 'none',
                    borderRadius: "16px",
                    border: '1px dashed',
                    borderColor: 'secondary.main',
                    '&.Mui-expanded': {
                      borderStyle: 'solid',
                      borderColor: 'primary.main',
                    },
                  }
                }}>
                <AccordionSummary expandIcon={<ExpandMore />} sx={{
                  borderRadius: "16px",
                  '&.Mui-expanded': {
                    bgcolor: 'primary.lighter', borderBottomLeftRadius: 0, borderBottomRightRadius: 0
                  },
                }}>
                  <BusinessCenterOutlined sx={{ mr: 1 }} fontSize="small" color="secondary" />
                  <Typography fontSize={"small"} fontWeight={500} sx={{ color: 'secondary.main' }}>Reminders</Typography>
                </AccordionSummary>
                <AccordionDetails>
                </AccordionDetails>
              </Accordion>
            </Grid>

            {/* Notes */}
            <Grid item xs={12}>
              <Accordion expanded={expandedPanels.includes("notes")}
                onChange={handleAccordionChange({ panel: "notes" })} sx={{
                  width: '100%',
                  '&.MuiAccordion-root': {
                    boxShadow: 'none',
                    borderRadius: "16px",
                    border: '1px dashed',
                    borderColor: 'secondary.main',
                    '&.Mui-expanded': {
                      borderStyle: 'solid',
                      borderColor: 'primary.main',
                    },
                  }
                }}>
                <AccordionSummary expandIcon={<ExpandMore />} sx={{
                  borderRadius: "16px",
                  '&.Mui-expanded': {
                    bgcolor: 'primary.lighter', borderBottomLeftRadius: 0, borderBottomRightRadius: 0
                  },
                }}>
                  <BusinessCenterOutlined sx={{ mr: 1 }} fontSize="small" color="secondary" />
                  <Typography fontSize={"small"} fontWeight={500} sx={{ color: 'secondary.main' }}>Notes</Typography>
                </AccordionSummary>
                <AccordionDetails>
                </AccordionDetails>
              </Accordion>
            </Grid>

            {/* Documents */}
            <Grid item xs={12}>
              <Accordion expanded={expandedPanels.includes("documents")}
                onChange={handleAccordionChange({ panel: "documents" })} sx={{
                  width: '100%',
                  '&.MuiAccordion-root': {
                    boxShadow: 'none',
                    borderRadius: "16px",
                    border: '1px dashed',
                    borderColor: 'secondary.main',
                    '&.Mui-expanded': {
                      borderStyle: 'solid',
                      borderColor: 'primary.main',
                    },
                  }
                }}>
                <AccordionSummary expandIcon={<ExpandMore />} sx={{
                  borderRadius: "16px",
                  '&.Mui-expanded': {
                    bgcolor: 'primary.lighter', borderBottomLeftRadius: 0, borderBottomRightRadius: 0
                  },
                }}>
                  <BusinessCenterOutlined sx={{ mr: 1 }} fontSize="small" color="secondary" />
                  <Typography fontSize={"small"} fontWeight={500} sx={{ color: 'secondary.main' }}>Documents (Legal Invoices)</Typography>
                </AccordionSummary>
                <AccordionDetails>
                </AccordionDetails>
              </Accordion>
            </Grid>

          </Grid>
        </Grid>

        {/* Right Panel */}
        <Grid item xs={12} sm={6}>
          <Grid container gap={2}>

            {/* Products */}
            <Grid item xs={12} sx={{
              p: 1,
              boxShadow: 'none',
              borderRadius: "16px",
              border: '1px solid',
              borderColor: 'primary.light',
            }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box>
                    <Typography color={"text.primary"} fontSize={14} fontWeight={600}>{t("crm.sales.invoices.products")}</Typography>
                    <Divider />
                  </Box>
                </Grid>
                {/*Added Products */}
                {products.map((p: IInvoiceProductCreateInput, index: number) => (
                  <Grid item xs={12} key={index}>
                    <Accordion expanded={expandedPanels.includes('product-' + index.toString())}
                      onChange={handleAccordionChange({ panel: 'product-' + index.toString(), product: p })} sx={{
                        width: '100%',
                        '&.MuiAccordion-root': {
                          boxShadow: 'none',
                          borderRadius: "16px",
                          border: '1px dashed',
                          borderColor: 'primary.main',
                          '&.Mui-expanded': {
                            borderStyle: 'solid',
                            borderColor: 'primary.main',
                          },
                        }
                      }}>
                      <AccordionSummary expandIcon={<ExpandMore />} sx={{
                        borderRadius: "16px",
                        '&.Mui-expanded': {
                          bgcolor: 'primary.lighter', borderBottomLeftRadius: 0, borderBottomRightRadius: 0
                        },
                      }}>
                        <BusinessCenterOutlined sx={{ mr: 1 }} fontSize="small" color="primary" />
                        <Typography fontSize={"small"} fontWeight={500} sx={{ color: 'primary.main' }}>{p?.name}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={2}>

                          <Grid item xs={6}  >
                            <InputLabel htmlFor={`products${index}.productId`} sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.product")}</InputLabel>
                            <Autocomplete
                              id={`products${index}.productId`}
                              isOptionEqualToValue={(option, value) => option._id === value._id}
                              fullWidth
                              size="small"
                              disableClearable
                              getOptionLabel={(option) => option.name}
                              options={[...(productListData?.data || []), { _id: "", name: "" }]}
                              loading={productListLoading}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                              value={{ _id: product?.productId ?? "", name: productListData?.data?.filter((p) => p._id === product?.productId)[0]?.name ?? "" }}

                              onChange={(e, value) => {
                                setProduct({
                                  type: ProductType.product,
                                  name: productListData?.data?.filter((p) => p._id === value?._id)[0]?.name ?? "",
                                  description: productListData?.data?.filter((p) => p._id === value?._id)[0]?.description ?? "",
                                  productId: value?._id ?? "",
                                  // productUnit: productListData?.data?.filter((p) => p._id === value?._id)[0]?.productUnit ?? 1,
                                  // quantity: productListData?.data?.filter((p) => p._id === value?._id)[0]?.quantity ?? 1,
                                  productUnit: 0,
                                  quantity: 0,
                                  price: productListData?.data?.filter((p) => p._id === value?._id)[0]?.price ?? 0,
                                  priceType: productListData?.data?.filter((p) => p._id === value?._id)[0]?.priceType ?? PriceType.flat,
                                  discountedPrice: Number(productListData?.data?.filter((p) => p._id === value?._id)[0]?.discountedPrice) ?? 0,
                                  // tax: productListData?.data?.filter((p) => p._id === value?._id)[0]?.tax ?? 0,
                                  tax: 0
                                });
                              }}
                            />
                          </Grid>

                          <Grid item xs={6}>
                            <InputLabel htmlFor={`products${index}.name`} sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.name")}</InputLabel>
                            <TextField
                              id={`products${index}.name`}
                              name={`products${index}.name`}
                              size="small"
                              fullWidth
                              value={product?.name ?? ""}
                              onChange={(e) => setProduct((prevProduct: any) => ({
                                ...prevProduct,
                                name: e.target.value,
                                type: ProductType.product,
                              }))
                              }
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Typography sx={{ ml: 1, mb: 0.5, fontSize: 'small', color: 'grey.600' }}>{t("crm.sales.invoices.description")}</Typography>
                            <Box sx={{ bgcolor: 'background.secondary', borderRadius: 2, height: "160px", mt: 1, border: "1px solid", borderColor: "grey.400" }}>
                              <ReactQuill
                                id={`products${index}.description`}

                                className="react-quill"
                                theme="snow"
                                value={product?.description ?? ""}
                                onChange={(content, delta, source, editor) => setProduct((prevProduct: any) => ({
                                  ...prevProduct,
                                  description: content,
                                  type: ProductType.product,
                                }))}
                                placeholder={t("crm.sales.invoices.description")}
                                style={{ height: 130, boxSizing: "border-box" }}
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={6}>
                            <InputLabel htmlFor={`products${index}.productUnit`} sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.productUnit")}</InputLabel>
                            <TextField
                              id={`products${index}.productUnit`}
                              name={`products${index}.productUnit`}
                              size="small"
                              fullWidth
                              value={product?.productUnit ?? ""}
                              onChange={(e) => setProduct((prevProduct: any) => ({
                                ...prevProduct,
                                productUnit: e.target.value,
                                type: ProductType.product,
                              }))}
                            />
                          </Grid>

                          <Grid item xs={6}>
                            <InputLabel htmlFor={`products${index}.quantity`} sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.quantity")}</InputLabel>
                            <TextField
                              id={`products${index}.quantity`}
                              name={`products${index}.quantity`}
                              size="small"
                              fullWidth
                              value={product?.quantity ?? ""}
                              onChange={(e) => setProduct((prevProduct: any) => ({
                                ...prevProduct,
                                quantity: e.target.value,
                                type: ProductType.product,
                              }))}
                            />
                          </Grid>


                          <Grid item xs={6}>
                            <InputLabel htmlFor={`products${index}.price`} sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.price")}</InputLabel>
                            <TextField
                              id={`products${index}.price`}
                              name={`products${index}.price`}
                              size="small"
                              fullWidth
                              value={product?.price ?? ""}
                              onChange={(e) => setProduct((prevProduct: any) => ({
                                ...prevProduct,
                                price: e.target.value,
                                type: ProductType.product,
                              }))}
                            />
                          </Grid>

                          <Grid item xs={6}>
                            <InputLabel htmlFor={`products${index}.priceType`} sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.priceType")}</InputLabel>
                            <Autocomplete
                              id={`products${index}.priceType`}
                              fullWidth
                              size="small"
                              isOptionEqualToValue={(option, value) => option._id === value?._id}
                              options={Object.values(PriceType).map((priceType) => ({
                                name: localizedPriceTypes[priceType as keyof typeof PriceType],
                                _id: priceType,
                              }))}
                              getOptionLabel={(option) => option.name}
                              renderInput={(params) => <TextField {...params} />}
                              value={
                                product?.priceType
                                  ? { name: localizedPriceTypes[product?.priceType as keyof typeof PriceType], _id: product?.priceType }
                                  : null
                              }
                              onChange={(e, value) => {
                                setProduct((prevProduct: any) => ({
                                  ...prevProduct,
                                  priceType: value?._id,
                                  type: ProductType.product,
                                }));
                              }}
                            />
                          </Grid>

                          <Grid item xs={6}>
                            <InputLabel htmlFor={`products${index}.discountedPrice`} sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.discountedPrice")}</InputLabel>
                            <TextField
                              id={`products${index}.discountedPrice`}
                              name={`products${index}.discountedPrice`}
                              size="small"
                              fullWidth
                              value={product?.discountedPrice ?? ""}
                              onChange={(e) => setProduct((prevProduct: any) => ({
                                ...prevProduct,
                                discountedPrice: e.target.value,
                                type: ProductType.product,
                              }))}
                            />
                          </Grid>

                          <Grid item xs={6}>
                            <InputLabel htmlFor={`products${index}.finalPrice`} sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.finalPrice")}</InputLabel>
                            <TextField
                              id={`products${index}.finalPrice`}
                              name={`products${index}.finalPrice`}
                              size="small"
                              fullWidth
                              value={(product?.productUnit || 0) * (product?.discountedPrice || 0)}
                            />
                          </Grid>


                          <Grid item xs={6}>
                            <InputLabel htmlFor={`products${index}.tax`} sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.tax")}</InputLabel>
                            <TextField
                              id={`products${index}.tax`}
                              name={`products${index}.tax`}
                              size="small"
                              fullWidth
                              value={product?.tax ?? ""}
                              onChange={(e) => setProduct((prevProduct: any) => ({
                                ...prevProduct,
                                tax: e.target.value,
                              }))}
                            />
                          </Grid>

                          <Grid item xs={6}>
                            <Box height={20} />
                          </Grid>
                          <Grid item xs={6}>
                            <OutlinedButton
                              color="secondary"
                              leftIcon={<DeleteOutline fontSize="small" />}
                              onClick={() => setProducts(products.filter((_: any, i: number) => i !== index))}
                              title="Delete"
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <FilledButton
                              color="secondary"
                              leftIcon={<RefreshOutlined fontSize="small" />}
                              title="Update"
                              fullWidth
                              onClick={() => setProducts(products.map((p: any, i: number) => i === index ? product : p))}
                            />
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                ))}
                {/* Add Product */}
                <Grid item xs={12}>
                  <Accordion expanded={expandedPanels.includes("products")}
                    onChange={handleAccordionChange({ panel: "products" })} sx={{
                      width: '100%',
                      '&.MuiAccordion-root': {
                        boxShadow: 'none',
                        borderRadius: "16px",
                        border: '1px dashed',
                        borderColor: 'secondary.main',
                        '&.Mui-expanded': {
                          borderStyle: 'solid',
                          borderColor: 'primary.main',
                        },
                      }
                    }}>
                    <AccordionSummary expandIcon={<Add />} sx={{
                      borderRadius: "16px",
                      '&.Mui-expanded': {
                        bgcolor: 'primary.lighter', borderBottomLeftRadius: 0, borderBottomRightRadius: 0
                      },
                    }}>
                      <BusinessCenterOutlined sx={{ mr: 1 }} fontSize="small" color="secondary" />
                      <Typography fontSize={"small"} fontWeight={500} sx={{ color: 'secondary.main' }}>Add Product</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={2}>

                        <Grid item xs={6}  >
                          <InputLabel htmlFor="products.productId" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.product")}</InputLabel>
                          <Autocomplete
                            id="products.productId"
                            isOptionEqualToValue={(option, value) => option._id === value._id}
                            fullWidth
                            size="small"
                            disableClearable
                            getOptionLabel={(option) => option.name}
                            options={[...(productListData?.data || []), { _id: "", name: "" }]}
                            loading={productListLoading}
                            renderInput={(params) => (
                              <TextField {...params} />
                            )}
                            value={{ _id: product?.productId ?? "", name: productListData?.data?.filter((p) => p._id === product?.productId)[0]?.name ?? "" }}

                            onChange={(e, value) => {
                              setProduct({
                                type: ProductType.product,
                                name: productListData?.data?.filter((p) => p._id === value?._id)[0]?.name ?? "",
                                description: productListData?.data?.filter((p) => p._id === value?._id)[0]?.description ?? "",
                                productId: value?._id ?? "",
                                // productUnit: productListData?.data?.filter((p) => p._id === value?._id)[0]?.productUnit ?? 1,
                                // quantity: productListData?.data?.filter((p) => p._id === value?._id)[0]?.quantity ?? 1,
                                productUnit: 0,
                                quantity: 0,
                                price: productListData?.data?.filter((p) => p._id === value?._id)[0]?.price ?? 0,
                                priceType: productListData?.data?.filter((p) => p._id === value?._id)[0]?.priceType ?? PriceType.flat,
                                discountedPrice: productListData?.data?.filter((p) => p._id === value?._id)[0]?.discountedPrice ?? 0,
                                // tax: productListData?.data?.filter((p) => p._id === value?._id)[0]?.tax ?? 0,
                                tax: 0
                              });
                            }}
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <InputLabel htmlFor="products.name" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.name")}</InputLabel>
                          <TextField
                            id="products.name"
                            name="products.name"
                            size="small"
                            fullWidth
                            value={product?.name ?? ""}
                            onChange={(e) => setProduct((prevProduct: any) => ({
                              ...prevProduct,
                              name: e.target.value,
                              type: ProductType.product,
                            }))
                            }
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography sx={{ ml: 1, mb: 0.5, fontSize: 'small', color: 'grey.600' }}>{t("crm.sales.invoices.description")}</Typography>
                          <Box sx={{ bgcolor: 'background.secondary', borderRadius: 2, height: "160px", mt: 1, border: "1px solid", borderColor: "grey.400" }}>
                            <ReactQuill
                              id="products.description"
                              className="react-quill"
                              theme="snow"
                              value={product?.description ?? ""}
                              onChange={(content, delta, source, editor) => setProduct((prevProduct: any) => ({
                                ...prevProduct,
                                description: content,
                                type: ProductType.product,
                              }))}
                              placeholder={t("crm.sales.invoices.description")}
                              style={{ height: 130, boxSizing: "border-box" }}
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <InputLabel htmlFor="products.productUnit" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.productUnit")}</InputLabel>
                          <TextField
                            id="products.productUnit"
                            name="products.productUnit"
                            size="small"
                            fullWidth
                            value={product?.productUnit ?? ""}
                            onChange={(e) => setProduct((prevProduct: any) => ({
                              ...prevProduct,
                              productUnit: e.target.value,
                              type: ProductType.product,
                            }))}
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <InputLabel htmlFor="products.quantity" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.quantity")}</InputLabel>
                          <TextField
                            id="products.quantity"
                            name="products.quantity"
                            size="small"
                            fullWidth
                            value={product?.quantity ?? ""}
                            onChange={(e) => setProduct((prevProduct: any) => ({
                              ...prevProduct,
                              quantity: e.target.value,
                              type: ProductType.product,
                            }))}
                          />
                        </Grid>


                        <Grid item xs={6}>
                          <InputLabel htmlFor="products.price" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.price")}</InputLabel>
                          <TextField
                            id="products.price"
                            name="products.price"
                            size="small"
                            fullWidth
                            value={product?.price ?? ""}
                            onChange={(e) => setProduct((prevProduct: any) => ({
                              ...prevProduct,
                              price: e.target.value,
                              type: ProductType.product,
                            }))}
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <InputLabel htmlFor="products.priceType" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.priceType")}</InputLabel>
                          <Autocomplete
                            id="products.priceType"
                            fullWidth
                            size="small"
                            isOptionEqualToValue={(option, value) => option._id === value?._id}
                            options={Object.values(PriceType).map((priceType) => ({
                              name: localizedPriceTypes[priceType as keyof typeof PriceType],
                              _id: priceType,
                            }))}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => <TextField {...params} />}
                            value={
                              product?.priceType
                                ? { name: localizedPriceTypes[product?.priceType as keyof typeof PriceType], _id: product?.priceType }
                                : null
                            }
                            onChange={(e, value) => {
                              setProduct((prevProduct: any) => ({
                                ...prevProduct,
                                priceType: value?._id,
                                type: ProductType.product,
                              }));
                            }}
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <InputLabel htmlFor="products.discountedPrice" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.discountedPrice")}</InputLabel>
                          <TextField
                            id="products.discountedPrice"
                            name="products.discountedPrice"
                            size="small"
                            fullWidth
                            value={product?.discountedPrice ?? ""}
                            onChange={(e) => setProduct((prevProduct: any) => ({
                              ...prevProduct,
                              discountedPrice: e.target.value,
                              type: ProductType.product,
                            }))}
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <InputLabel htmlFor="products.finalPrice" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.finalPrice")}</InputLabel>
                          <TextField
                            id="products.finalPrice"
                            name="products.finalPrice"
                            size="small"
                            fullWidth
                            value={(product?.productUnit || 0) * (product?.discountedPrice || 0)}
                          />
                        </Grid>


                        <Grid item xs={6}>
                          <InputLabel htmlFor="products.tax" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.tax")}</InputLabel>
                          <TextField
                            id="products.tax"
                            name="products.tax"
                            size="small"
                            fullWidth
                            value={product?.tax ?? ""}
                            onChange={(e) => setProduct((prevProduct: any) => ({
                              ...prevProduct,
                              tax: e.target.value,
                            }))}
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <Box height={20} />
                          <FilledButton
                            fullWidth
                            title="Add"
                            color="secondary"
                            leftIcon={<Add />}
                            onClick={() => {
                              setProducts((prevProducts: any) => [...prevProducts, product]);
                              setProduct(null);
                            }}
                          />
                        </Grid>

                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>

              </Grid>
            </Grid>

            {/* Services */}
            <Grid item xs={12} sx={{
              p: 1,
              boxShadow: 'none',
              borderRadius: "16px",
              border: '1px solid',
              borderColor: 'primary.light',
            }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box>
                    <Typography color={"text.primary"} fontSize={14} fontWeight={600}>{t("crm.sales.invoices.services")}</Typography>
                    <Divider />
                  </Box>
                </Grid>
                {/* Added Services */}
                {services.map((s: IInvoiceProductCreateInput, index: number) => (
                  <Grid item xs={12} key={index}>
                    <Accordion expanded={expandedPanels.includes('service-' + index.toString())}
                      onChange={handleAccordionChange({ panel: 'service-' + index.toString(), service: s })} sx={{
                        width: '100%',
                        '&.MuiAccordion-root': {
                          boxShadow: 'none',
                          borderRadius: "16px",
                          border: '1px dashed',
                          borderColor: 'primary.main',
                          '&.Mui-expanded': {
                            borderStyle: 'solid',
                            borderColor: 'primary.main',
                          },
                        }
                      }}>
                      <AccordionSummary expandIcon={<ExpandMore />} sx={{
                        borderRadius: "16px",
                        '&.Mui-expanded': {
                          bgcolor: 'primary.lighter', borderBottomLeftRadius: 0, borderBottomRightRadius: 0
                        },
                      }}>
                        <BusinessCenterOutlined sx={{ mr: 1 }} fontSize="small" color="primary" />
                        <Typography fontSize={"small"} fontWeight={500} sx={{ color: 'primary.main' }}>{s?.name}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={2}>

                          <Grid item xs={6}  >
                            <InputLabel htmlFor={`services${index}.serviceId`} sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.services")}</InputLabel>
                            <Autocomplete
                              id={`services${index}.serviceId`}
                              isOptionEqualToValue={(option, value) => option._id === value._id}
                              fullWidth
                              size="small"
                              disableClearable
                              getOptionLabel={(option) => option.name}
                              options={[...(serviceListData?.data || []), { _id: "", name: "" }]}
                              loading={serviceListLoading}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                              value={{ _id: service?.productId ?? "", name: serviceListData?.data?.filter((p) => p._id === service?.productId)[0]?.name ?? "" }}

                              onChange={(e, value) => {
                                setService({
                                  type: ProductType.service,
                                  name: serviceListData?.data?.filter((p) => p._id === value?._id)[0]?.name ?? "",
                                  description: serviceListData?.data?.filter((p) => p._id === value?._id)[0]?.description ?? "",
                                  productId: value?._id ?? "",
                                  // serviceUnit: serviceListData?.data?.filter((p) => p._id === value?._id)[0]?.serviceUnit ?? 1,
                                  // quantity: serviceListData?.data?.filter((p) => p._id === value?._id)[0]?.quantity ?? 1,
                                  productUnit: 0,
                                  quantity: 0,
                                  price: serviceListData?.data?.filter((p) => p._id === value?._id)[0]?.price ?? 0,
                                  priceType: serviceListData?.data?.filter((p) => p._id === value?._id)[0]?.priceType ?? PriceType.flat,
                                  discountedPrice: serviceListData?.data?.filter((p) => p._id === value?._id)[0]?.discountedPrice ?? 0,
                                  // tax: serviceListData?.data?.filter((p) => p._id === value?._id)[0]?.tax ?? 0,
                                  tax: 0
                                });
                              }}
                            />
                          </Grid>

                          <Grid item xs={6}>
                            <InputLabel htmlFor={`services${index}.name`} sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.name")}</InputLabel>
                            <TextField
                              id={`services${index}.name`}
                              name={`services${index}.name`}
                              size="small"
                              fullWidth
                              value={service?.name ?? ""}
                              onChange={(e) => setService((prevService: any) => ({
                                ...prevService,
                                name: e.target.value,
                                type: ProductType.service,
                              }))
                              }
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Typography sx={{ ml: 1, mb: 0.5, fontSize: 'small', color: 'grey.600' }}>{t("crm.sales.invoices.description")}</Typography>
                            <Box sx={{ bgcolor: 'background.secondary', borderRadius: 2, height: "160px", mt: 1, border: "1px solid", borderColor: "grey.400" }}>
                              <ReactQuill
                                id={`services${index}.description`}
                                className="react-quill"
                                theme="snow"
                                value={service?.description ?? ""}
                                onChange={(content, delta, source, editor) => setService((prevService: any) => ({
                                  ...prevService,
                                  description: content,
                                  type: ProductType.service,
                                }))}
                                placeholder={t("crm.sales.invoices.description")}
                                style={{ height: 130, boxSizing: "border-box" }}
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={6}>
                            <InputLabel htmlFor={`services${index}.serviceUnit`} sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.serviceUnit")}</InputLabel>
                            <TextField
                              id={`services${index}.serviceUnit`}
                              name={`services${index}.serviceUnit`}
                              size="small"
                              fullWidth
                              value={service?.productUnit ?? ""}
                              onChange={(e) => setService((prevService: any) => ({
                                ...prevService,
                                serviceUnit: e.target.value,
                                type: ProductType.service,
                              }))}
                            />
                          </Grid>

                          <Grid item xs={6}>
                            <InputLabel htmlFor={`services${index}.quantity`} sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.quantity")}</InputLabel>
                            <TextField
                              id={`services${index}.quantity`}
                              name={`services${index}.quantity`}
                              size="small"
                              fullWidth
                              value={service?.quantity ?? ""}
                              onChange={(e) => setService((prevService: any) => ({
                                ...prevService,
                                quantity: e.target.value,
                                type: ProductType.service,
                              }))}
                            />
                          </Grid>


                          <Grid item xs={6}>
                            <InputLabel htmlFor={`services${index}.price`} sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.price")}</InputLabel>
                            <TextField
                              id={`services${index}.price`}
                              name={`services${index}.price`}
                              size="small"
                              fullWidth
                              value={service?.price ?? ""}
                              onChange={(e) => setService((prevService: any) => ({
                                ...prevService,
                                price: e.target.value,
                                type: ProductType.service,
                              }))}
                            />
                          </Grid>

                          <Grid item xs={6}>
                            <InputLabel htmlFor={`services${index}.priceType`} sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.priceType")}</InputLabel>
                            <Autocomplete
                              id={`services${index}.priceType`}
                              fullWidth
                              size="small"
                              isOptionEqualToValue={(option, value) => option._id === value?._id}
                              options={Object.values(PriceType).map((priceType) => ({
                                name: localizedPriceTypes[priceType as keyof typeof PriceType],
                                _id: priceType,
                              }))}
                              getOptionLabel={(option) => option.name}
                              renderInput={(params) => <TextField {...params} />}
                              value={
                                service?.priceType
                                  ? { name: localizedPriceTypes[service?.priceType as keyof typeof PriceType], _id: service?.priceType }
                                  : null
                              }
                              onChange={(e, value) => {
                                setService((prevService: any) => ({
                                  ...prevService,
                                  priceType: value?._id,
                                  type: ProductType.service,
                                }));
                              }}
                            />
                          </Grid>

                          <Grid item xs={6}>
                            <InputLabel htmlFor={`services${index}.discountedPrice`} sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.discountedPrice")}</InputLabel>
                            <TextField
                              id={`services${index}.discountedPrice`}
                              name={`services${index}.discountedPrice`}
                              size="small"
                              fullWidth
                              value={service?.discountedPrice ?? ""}
                              onChange={(e) => setService((prevService: any) => ({
                                ...prevService,
                                discountedPrice: e.target.value,
                                type: ProductType.service,
                              }))}
                            />
                          </Grid>

                          <Grid item xs={6}>
                            <InputLabel htmlFor={`services${index}.finalPrice`} sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.finalPrice")}</InputLabel>
                            <TextField
                              id={`services${index}.finalPrice`}
                              name={`services${index}.finalPrice`}
                              size="small"
                              fullWidth
                              value={(service?.productUnit || 1) * (service?.discountedPrice || 0)}
                            />
                          </Grid>


                          <Grid item xs={6}>
                            <InputLabel htmlFor={`services${index}.tax`} sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.tax")}</InputLabel>
                            <TextField
                              id={`services${index}.tax`}
                              name={`services${index}.tax`}
                              size="small"
                              fullWidth
                              value={service?.tax ?? ""}
                              onChange={(e) => setService((prevProduct: any) => ({
                                ...prevProduct,
                                tax: e.target.value,
                              }))}
                            />
                          </Grid>

                          <Grid item xs={6}>
                            <Box height={20} />
                          </Grid>
                          <Grid item xs={6}>
                            <OutlinedButton
                              color="secondary"
                              leftIcon={<DeleteOutline fontSize="small" />}
                              onClick={() => setServices(services.filter((_: any, i: number) => i !== index))}
                              title="Delete"
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <FilledButton
                              color="secondary"
                              leftIcon={<RefreshOutlined fontSize="small" />}
                              title="Update"
                              fullWidth
                              onClick={() => setServices(services.map((s: any, i: number) => i === index ? service : s))}
                            />
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                ))}

                {/* Add Service */}
                <Grid item xs={12}>
                  <Accordion expanded={expandedPanels.includes("services")}
                    onChange={handleAccordionChange({ panel: "services" })} sx={{
                      width: '100%',
                      '&.MuiAccordion-root': {
                        boxShadow: 'none',
                        borderRadius: "16px",
                        border: '1px dashed',
                        borderColor: 'secondary.main',
                        '&.Mui-expanded': {
                          borderStyle: 'solid',
                          borderColor: 'primary.main',
                        },
                      }
                    }}>
                    <AccordionSummary expandIcon={<Add />} sx={{
                      borderRadius: "16px",
                      '&.Mui-expanded': {
                        bgcolor: 'primary.lighter', borderBottomLeftRadius: 0, borderBottomRightRadius: 0
                      },
                    }}>
                      <BusinessCenterOutlined sx={{ mr: 1 }} fontSize="small" color="secondary" />
                      <Typography fontSize={"small"} fontWeight={500} sx={{ color: 'secondary.main' }}>Add Service</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={2}>

                        <Grid item xs={6}  >
                          <InputLabel htmlFor="services.productId" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.service")}</InputLabel>
                          <Autocomplete
                            id="services.productId"
                            isOptionEqualToValue={(option, value) => option._id === value._id}
                            fullWidth
                            size="small"
                            disableClearable
                            getOptionLabel={(option) => option.name}
                            options={[...(serviceListData?.data || []), { _id: "", name: "" }]}
                            loading={serviceListLoading}
                            renderInput={(params) => (
                              <TextField {...params} />
                            )}
                            value={{ _id: service?.productId ?? "", name: serviceListData?.data?.filter((s) => s._id === service?.productId)[0]?.name ?? "" }}

                            onChange={(e, value) => {
                              setService({
                                type: ProductType.service,
                                name: serviceListData?.data?.filter((s) => s._id === value?._id)[0]?.name ?? "",
                                description: serviceListData?.data?.filter((s) => s._id === value?._id)[0]?.description ?? "",
                                productId: value?._id ?? "",
                                // productUnit: serviceListData?.data?.filter((s) => s._id === value?._id)[0]?.productUnit ?? 1,
                                // quantity: serviceListData?.data?.filter((s) => s._id === value?._id)[0]?.quantity ?? 1,
                                productUnit: 0,
                                quantity: 0,
                                price: serviceListData?.data?.filter((s) => s._id === value?._id)[0]?.price ?? 0,
                                priceType: serviceListData?.data?.filter((s) => s._id === value?._id)[0]?.priceType ?? PriceType.flat,
                                discountedPrice: serviceListData?.data?.filter((s) => s._id === value?._id)[0]?.discountedPrice ?? 0,
                                // tax: serviceListData?.data?.filter((s) => s._id === value?._id)[0]?.tax ?? 0,
                                tax: 0
                              });
                            }}
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <InputLabel htmlFor="services.name" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.name")}</InputLabel>
                          <TextField
                            id="services.name"
                            name="services.name"
                            size="small"
                            fullWidth
                            value={service?.name ?? ""}
                            onChange={(e) => setService((prevService: any) => ({
                              ...prevService,
                              name: e.target.value,
                              type: ProductType.service,
                            }))
                            }
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.description")}</Typography>
                          <Box sx={{ bgcolor: 'background.secondary', borderRadius: 2, height: "160px", mt: 1, border: "1px solid", borderColor: "grey.400" }}>
                            <ReactQuill
                              id="services.description"
                              className="react-quill"
                              theme="snow"
                              value={service?.description ?? ""}
                              onChange={(content, delta, source, editor) => setService((prevService: any) => ({
                                ...prevService,
                                description: content,
                                type: ProductType.service,
                              }))}
                              placeholder={t("crm.sales.invoices.description")}
                              style={{ height: 130, boxSizing: "border-box" }}
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <InputLabel htmlFor="services.productUnit" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.productUnit")}</InputLabel>
                          <TextField
                            id="services.productUnit"
                            name="services.productUnit"
                            size="small"
                            fullWidth
                            value={service?.productUnit ?? ""}
                            onChange={(e) => setService((prevService: any) => ({
                              ...prevService,
                              productUnit: e.target.value,
                              type: ProductType.service,
                            }))}
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <InputLabel htmlFor="services.quantity" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.quantity")}</InputLabel>
                          <TextField
                            id="services.quantity"
                            name="services.quantity"
                            size="small"
                            fullWidth
                            value={service?.quantity ?? ""}
                            onChange={(e) => setService((prevService: any) => ({
                              ...prevService,
                              quantity: e.target.value,
                              type: ProductType.service,
                            }))}
                          />
                        </Grid>


                        <Grid item xs={6}>
                          <InputLabel htmlFor="services.price" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.price")}</InputLabel>
                          <TextField
                            id="services.price"
                            name="services.price"
                            size="small"
                            fullWidth
                            value={service?.price ?? ""}
                            onChange={(e) => setService((prevService: any) => ({
                              ...prevService,
                              price: e.target.value,
                              type: ProductType.service,
                            }))}
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <InputLabel htmlFor="services.priceType" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.priceType")}</InputLabel>
                          <Autocomplete
                            id="services.priceType"
                            fullWidth
                            size="small"
                            isOptionEqualToValue={(option, value) => option._id === value?._id}
                            options={Object.values(PriceType).map((priceType) => ({
                              name: localizedPriceTypes[priceType as keyof typeof PriceType],
                              _id: priceType,
                            }))}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => <TextField {...params} />}
                            value={
                              service?.priceType
                                ? { name: localizedPriceTypes[service?.priceType as keyof typeof PriceType], _id: service?.priceType }
                                : null
                            }
                            onChange={(e, value) => {
                              setService((prevService: any) => ({
                                ...prevService,
                                priceType: value?._id,
                                type: ProductType.service,
                              }));
                            }}
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <InputLabel htmlFor="services.discountedPrice" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.discountedPrice")}</InputLabel>
                          <TextField
                            id="services.discountedPrice"
                            name="services.discountedPrice"
                            size="small"
                            fullWidth
                            value={service?.discountedPrice ?? ""}
                            onChange={(e) => setService((prevService: any) => ({
                              ...prevService,
                              discountedPrice: e.target.value,
                              type: ProductType.service,
                            }))}
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <InputLabel htmlFor="services.finalPrice" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.finalPrice")}</InputLabel>
                          <TextField
                            id="services.finalPrice"
                            name="services.finalPrice"
                            size="small"
                            fullWidth
                            value={(service?.productUnit || 1) * (service?.discountedPrice || 0)}
                          />
                        </Grid>


                        <Grid item xs={6}>
                          <InputLabel htmlFor="services.tax" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.tax")}</InputLabel>
                          <TextField
                            id="services.tax"
                            name="services.tax"
                            size="small"
                            fullWidth
                            value={service?.tax ?? ""}
                            onChange={(e) => setService((prevService: any) => ({
                              ...prevService,
                              tax: e.target.value,
                            }))}
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <Box height={20} />
                          <FilledButton
                            fullWidth
                            title="Add"
                            color="secondary"
                            leftIcon={<Add />}
                            onClick={() => {
                              setServices((prevServices: any) => [...prevServices, service]);
                              setService(null);
                            }}
                          />
                        </Grid>

                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </Grid>
            </Grid>

            {/* Totally */}
            <Grid item xs={12}>
              <Accordion expanded={expandedPanels.includes("totally")}
                onChange={handleAccordionChange({ panel: "totally" })} sx={{
                  width: '100%',
                  '&.MuiAccordion-root': {
                    boxShadow: 'none',
                    borderRadius: "16px",
                    border: '1px dashed',
                    borderColor: 'secondary.main',
                    '&.Mui-expanded': {
                      borderStyle: 'solid',
                      borderColor: 'primary.main',
                    },
                  }
                }}>
                <AccordionSummary expandIcon={<ExpandMore />} sx={{
                  borderRadius: "16px",
                  '&.Mui-expanded': {
                    bgcolor: 'primary.lighter', borderBottomLeftRadius: 0, borderBottomRightRadius: 0
                  },
                }}>
                  <BusinessCenterOutlined sx={{ mr: 1 }} fontSize="small" color="secondary" />
                  <Typography fontSize={"small"} fontWeight={500} sx={{ color: 'secondary.main' }}>Totally</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <InputLabel htmlFor="subTotal" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.subTotal")}</InputLabel>
                      <TextField
                        id="subTotal"
                        name="subTotal"
                        size="small"
                        fullWidth
                        value={subTotal}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <InputLabel htmlFor="taxTotal" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.taxTotal")}</InputLabel>
                      <TextField
                        id="taxTotal"
                        name="taxTotal"
                        size="small"
                        fullWidth
                        value={taxTotal}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <InputLabel htmlFor="taxTotal" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.discount")}</InputLabel>
                      <TextField
                        id="discount"
                        name="discount"
                        size="small"
                        fullWidth
                        type="number"
                        value={discount}
                        onChange={(e) => setDiscount(Number(e.target.value))}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <InputLabel htmlFor="finalPrice" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.finalPrice")}</InputLabel>
                      <TextField
                        id="finalPrice"
                        name="finalPrice"
                        size="small"
                        fullWidth
                        value={finalPrice}
                      />
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>

            {/* Sales Agents */}
            <Grid item xs={12}>
              <Accordion expanded={expandedPanels.includes("salesAgents")}
                onChange={handleAccordionChange({ panel: "salesAgents" })} sx={{
                  width: '100%',
                  '&.MuiAccordion-root': {
                    boxShadow: 'none',
                    borderRadius: "16px",
                    border: '1px dashed',
                    borderColor: 'secondary.main',
                    '&.Mui-expanded': {
                      borderStyle: 'solid',
                      borderColor: 'primary.main',
                    },
                  }
                }}>
                <AccordionSummary expandIcon={<ExpandMore />} sx={{
                  borderRadius: "16px",
                  '&.Mui-expanded': {
                    bgcolor: 'primary.lighter', borderBottomLeftRadius: 0, borderBottomRightRadius: 0
                  },
                }}>
                  <BusinessCenterOutlined sx={{ mr: 1 }} fontSize="small" color="secondary" />
                  <Typography fontSize={"small"} fontWeight={500} sx={{ color: 'secondary.main' }}>Sales Agents</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      {
                        salesAgentIds?.map((item: any, index: number) => {
                          return (
                            <Stack direction="row" spacing={1} key={item} alignItems={"center"}>
                              <Typography key={index}>
                                {userListData?.data?.find((user: any) => user?._id === item)?.name}{" "}
                                {userListData?.data?.find((user: any) => user?._id === item)?.surname}
                              </Typography>
                              <IconButton
                                size="small"
                                color="error"
                                onClick={() => {
                                  // Filter out the clicked item from the list
                                  const updatedSalesAgentIds = salesAgentIds.filter((id: any) => id !== item);
                                  setSalesAgentIds(updatedSalesAgentIds); // Update state to re-render the UI
                                  setValue("input.salesAgentIds", updatedSalesAgentIds);  // Update the form value
                                }}
                              >
                                <Close fontSize="small" />
                              </IconButton>
                            </Stack>
                          )
                        })
                      }

                    </Grid>
                    <Grid item xs={9}>
                      <InputLabel htmlFor="salesAgent" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>
                        {t("crm.sales.invoices.salesAgent")}
                      </InputLabel>
                      <Autocomplete
                        id="salesAgent"
                        isOptionEqualToValue={(option, value) => option._id === value?._id}
                        fullWidth
                        size="small"
                        disableClearable
                        getOptionLabel={(option) => option?.name || ""}
                        options={userListData?.data?.filter((user: any) => salesAgentIds?.filter((id: any) => id === user?._id).length === 0) || []}
                        loading={userListLoading}
                        renderInput={(params) => <TextField {...params} />}
                        defaultValue={undefined}
                        onChange={(event, newValue) => {
                          setSalesAgent(newValue || undefined);
                        }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Box height={20} />
                      <FilledButton
                        leftIcon={<Add />}
                        color="secondary"
                        title={t("crm.sales.invoices.add")}
                        onClick={() => {
                          setSalesAgentIds([...salesAgentIds, salesAgent?._id || ""]);
                          setSalesAgent(undefined);
                        }}
                      />

                    </Grid>
                  </Grid>





                </AccordionDetails>
              </Accordion>


            </Grid>

            {/* Legal */}
            <Grid item xs={12}>
              <Accordion expanded={expandedPanels.includes("legal")}
                onChange={handleAccordionChange({ panel: "legal" })} sx={{
                  width: '100%',
                  '&.MuiAccordion-root': {
                    boxShadow: 'none',
                    borderRadius: "16px",
                    border: '1px dashed',
                    borderColor: 'secondary.main',
                    '&.Mui-expanded': {
                      borderStyle: 'solid',
                      borderColor: 'primary.main',
                    },
                  }
                }}>
                <AccordionSummary expandIcon={<ExpandMore />} sx={{
                  borderRadius: "16px",
                  '&.Mui-expanded': {
                    bgcolor: 'primary.lighter', borderBottomLeftRadius: 0, borderBottomRightRadius: 0
                  },
                }}>
                  <BusinessCenterOutlined sx={{ mr: 1 }} fontSize="small" color="secondary" />
                  <Typography fontSize={"small"} fontWeight={500} sx={{ color: 'secondary.main' }}>Legal</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    <Grid item xs={6}  >
                      <InputLabel htmlFor="legalNDAId" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.legalNDAId")}</InputLabel>
                      <Controller
                        name="input.legalNDAId"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <Autocomplete
                            {...field}
                            id="legalNDAId"
                            size="small"
                            isOptionEqualToValue={(option, value) => option._id === value?._id}
                            fullWidth
                            getOptionLabel={(option) => option.name}
                            options={legalAgreementListData?.data || []}
                            loading={legalAgreementListLoading}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            )}
                            value={
                              field.value
                                ? legalAgreementListData?.data?.find((legalAgreement) => legalAgreement._id === field.value)
                                : null
                            }
                            onChange={(e, value) => {
                              field.onChange(value?._id || "");
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <InputLabel htmlFor="useLegalNDA" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{"."}</InputLabel>
                      <Box sx={{ border: "1px solid #ccc", borderRadius: "14px", p: 0.7, display: "flex", alignItems: "center" }}>
                        <Controller
                          name="input.useLegalNDA"
                          control={control}
                          render={({ field }) => (
                            <Switch
                              {...field}
                              checked={field.value}
                              id="useLegalNDA"
                              color="secondary"
                              size="small"
                              onChange={(e) => field.onChange(e.target.checked)}
                            />
                          )}
                        />
                        <Typography sx={{ ml: 1, fontSize: 'small' }}>
                          {t("crm.sales.invoices.useLegalNDA")}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <InputLabel htmlFor="legalTermAndCondId" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.legalTermAndCondId")}</InputLabel>
                      <Controller
                        name="input.legalTermAndCondId"
                        control={control}
                        render={({ field }) => (
                          <Autocomplete
                            {...field}
                            id="legalTermAndCondId"
                            size="small"
                            isOptionEqualToValue={(option, value) => option._id === value?._id}
                            fullWidth
                            getOptionLabel={(option) => option.name}
                            options={legalAgreementListData?.data || []}
                            loading={legalAgreementListLoading}
                            renderInput={(params) => <TextField {...params} />}
                            value={
                              field.value
                                ? legalAgreementListData?.data?.find(
                                  (legalAgreement) => legalAgreement._id === field.value
                                ) || null
                                : null
                            }
                            onChange={(e, value) => {
                              field.onChange(value?._id || '');
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <InputLabel htmlFor="useLegalTermAndCond" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{"."}</InputLabel>
                      <Box sx={{ border: "1px solid #ccc", borderRadius: "14px", p: 0.7, display: "flex", alignItems: "center" }}>
                        <Controller
                          name="input.useLegalTermAndCond"
                          control={control}
                          render={({ field }) => (
                            <Switch
                              {...field}
                              checked={field.value}
                              id="useLegalTermAndCond"
                              color="secondary"
                              size="small"
                              onChange={(e) => field.onChange(e.target.checked)}
                            />
                          )}
                        />
                        <Typography sx={{ ml: 1, fontSize: 'small' }}>
                          {t("crm.sales.invoices.useLegalTermAndCond")}
                        </Typography>
                      </Box>
                    </Grid>


                  </Grid>





                </AccordionDetails>
              </Accordion>
            </Grid>

            {/* Signature */}
            <Grid item xs={12}>
              <Accordion expanded={expandedPanels.includes("signature")}
                onChange={handleAccordionChange({ panel: "signature" })} sx={{
                  width: '100%',
                  '&.MuiAccordion-root': {
                    boxShadow: 'none',
                    borderRadius: "16px",
                    border: '1px dashed',
                    borderColor: 'secondary.main',
                    '&.Mui-expanded': {
                      borderStyle: 'solid',
                      borderColor: 'primary.main',
                    },
                  }
                }}>
                <AccordionSummary expandIcon={<ExpandMore />} sx={{
                  borderRadius: "16px",
                  '&.Mui-expanded': {
                    bgcolor: 'primary.lighter', borderBottomLeftRadius: 0, borderBottomRightRadius: 0
                  },
                }}>
                  <BusinessCenterOutlined sx={{ mr: 1 }} fontSize="small" color="secondary" />
                  <Typography fontSize={"small"} fontWeight={500} sx={{ color: 'secondary.main' }}>Signature</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid item xs={12}>
                    <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.signerUserId")}</InputLabel>
                    <Controller
                      name="input.signerUserId"
                      control={control}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          id="signerUserId"
                          size="small"
                          isOptionEqualToValue={(option, value) => option?._id === value?._id}
                          fullWidth
                          getOptionLabel={(option) => option?.name + " " + option?.surname || ""}
                          options={userListData?.data || []}
                          loading={userListLoading}
                          renderInput={(params) => <TextField {...params} />}
                          value={
                            field.value
                              ? userListData?.data?.find((user) => user._id === field.value) || null
                              : null
                          }
                          onChange={(e, value) => {
                            field.onChange(value?._id || "");
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography sx={{ ml: 1, mt: 1, mb: 0.5, fontSize: 'small', color: 'text.secondary' }}>Image</Typography>
                    <UploadImageComponent setImageFile={(imageFile) => setValue("input.signatureImage", imageFile)} />
                  </Grid>

                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>





  </ActionDrawer>;
};


export const colors = [
  "primary.lighter",
  "secondary.lighter",
  "success.lighter",
  "error.lighter",
  "warning.lighter",
  "info.lighter",
  "primary.light",
  "secondary.light",
  "success.light",
  "error.light",
  "warning.light",
  "info.light",
  "primary.main",
  "secondary.main",
  "success.main",
  "error.main",
  "warning.main",
  "info.main",
  "primary.dark",
  "secondary.dark",
  "success.dark",
  "error.dark",
  "warning.dark",
  "info.dark",
  "primary.darker",
  "secondary.darker",
  "success.darker",
  "error.darker",
  "warning.darker",
  "info.darker",
];
