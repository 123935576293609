

const locale = {
  readAllSuccess: "Read all notifications successfully!",
  title: "Notifications",
  markAllAsRead: "Mark all as read.",
};


export default locale;
