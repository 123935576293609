import { useState, lazy, Suspense } from "react";
import { NotificationDrawerProps } from "./NotificationDrawer.props";

const NotificationDrawer = lazy(() => import("./NotificationDrawer"));

const NotificationDrawerWrapper = (props: NotificationDrawerProps) => {
  const [mounted, setMounted] = useState(false);

  if (props.open && !mounted) {
    setMounted(true);
  }

  return (
    <>
      {mounted && (
        <Suspense>
          <NotificationDrawer open={props.open} setOpen={props.setOpen} />
        </Suspense>
      )}
    </>
  );
};

export default NotificationDrawerWrapper;
