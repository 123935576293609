import { getTranslatedText } from "localization";
import { ValidationHelper } from "validations/validation.helper";

export const validateCreateUserInput =
  ValidationHelper.BuildObjectValidationSchema({
    input: ValidationHelper.BuildObjectValidationSchema({
      name: ValidationHelper.RequiredString(
        getTranslatedText("nameIsRequired")
      ).min(2, getTranslatedText("nameIsTooShort2")).max(50, getTranslatedText("nameIsTooLong50")),
      surname: ValidationHelper.RequiredString(
        getTranslatedText("surnameIsRequired")
      ).min(2, getTranslatedText("surnameIsTooShort2")).max(50, getTranslatedText("surnameIsTooLong50")),
      email: ValidationHelper.RequiredEmail(
        getTranslatedText("emailIsInvalid"),
        getTranslatedText("emailIsRequired")
      ),
      roleIds: ValidationHelper.NotRequiredArray(),
      departmentId: ValidationHelper.NotRequiredString()
    }),
  });