

export const demoDomainRoutes = {
  base: "settings",
  payment: "payment",
  integrations: "integrations",
  legalAgreements: "legal-agreements",
  menu: "menu",
  crm: "crm",
  leadPlus: "leadPlus",
  marketing: "marketing",
  window: "window",
  community: "community",
  notification: "notification",
  user: "user"
};

export const baseSettingsRoute = (): string => `/${demoDomainRoutes.base}/`;
export const basePaymentRoute = (): string => `/${demoDomainRoutes.base}/${demoDomainRoutes.payment}/`;
export const baseIntegrationsRoute = (): string => `/${demoDomainRoutes.base}/${demoDomainRoutes.integrations}/`;
export const baseLegalAgreementsRoute = (): string => `/${demoDomainRoutes.base}/${demoDomainRoutes.legalAgreements}/`;
export const baseMenuRoute = (): string => `/${demoDomainRoutes.base}/${demoDomainRoutes.menu}/`;
export const baseUserRoute = (): string => `/${demoDomainRoutes.base}/${demoDomainRoutes.user}/`;
export const baseCrmRoute = (): string => `/${demoDomainRoutes.base}/${demoDomainRoutes.crm}/`;
export const baseLeadPlusRoute = (): string => `/${demoDomainRoutes.base}/${demoDomainRoutes.leadPlus}/`;
export const baseMarketingRoute = (): string => `/${demoDomainRoutes.base}/${demoDomainRoutes.marketing}/`;
export const baseWindowRoute = (): string => `/${demoDomainRoutes.base}/${demoDomainRoutes.window}/`;
export const baseCommunityRoute = (): string => `/${demoDomainRoutes.base}/${demoDomainRoutes.community}/`;
export const baseNotificationRoute = (): string => `/${demoDomainRoutes.base}/${demoDomainRoutes.notification}/`;


