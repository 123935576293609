/* eslint-disable react-hooks/exhaustive-deps */
import { Avatar, Box, Checkbox, Chip, Divider, FormControlLabel, Grid, IconButton, Stack, styled, Tooltip, Typography, useMediaQuery } from "@mui/material";
import { memo, useEffect, useState } from "react";
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { listUsersRoute } from "../../../routes/organizationalChart.base.route";
import { useUserDetailQuery } from "apps/auth/context";
import { ArrowForwardIosSharp, Close, ExpandMore, InfoOutlined, Lock, LockOpen, Update } from "@mui/icons-material";
import { usePermissionListQuery } from "../../permissions";
import { HeaderBase } from "components/contentHeaders/crm/HeaderBase";
import { MyTab, OutlinedButton } from "components";
import moment from "moment";
import { useUserPermissionDetailQuery, useUserPermissionUpdateMutation } from "../context/users.api";
import BgUserDetail from "assets/images/bg-user-detail.jpg";
import DetailBox from "components/box/DetailBox";
import { useLeadListQuery, useLeadUpdateMutation } from "apps/crm/domains/03-lead/subdomains/lead";
import { BaseDataGrid } from "components/baseDataGrid/BaseDataGrid";
import { BaseGridColType } from "components/baseDataGrid/infra/enums";
import EmptyState from "components/emptyState/EmptyState";
import { ILeadListItemResult, LeadQualification, LeadType, ReminderPriority, ReminderStatus } from "corede-common-cocrm";
import { TCellRenderFunc } from "components/baseDataGrid/infra/types";
import { useLeadStatusesQuery } from "apps/crm/domains/03-lead/subdomains/leadStatuses";
import { useLeadSourcesQuery } from "apps/crm/domains/03-lead/subdomains/leadSources";
import { Language } from "corede-common";
import { Default1 } from "assets/images/avatars";

interface IPermission {
  _id: string;
  action: string;
  subject: string;
  actionScope: string;
  description: string;
  domain: string;
  subdomain: string;
}


const UserDetail = memo(() => {
  // general
  const { t } = useTranslation();
  const navigate = useNavigate();
  const downLg = useMediaQuery((theme: any) => theme.breakpoints.down("lg"));
  const downMd = useMediaQuery((theme: any) => theme.breakpoints.down("md"));
  const { id } = useParams();
  if (!id) {
    navigate(listUsersRoute());
  }
  const [selectedTab, setSelectedTab] = useState(t("crm.organizationalChart.users.profile"));
  const [expandedAccordions, setExpandedAccordions] = useState<Record<string, boolean>>({});
  const [selectedPermissionIds, setSelectedPermissionIds] = useState<string[]>([]);
  const [prohibitedPermissionIds, setProhibitedPermissionIds] = useState<string[]>([]);
  const [openedPermissions, setOpenedPermissions] = useState<any>([])
  const [openDetail, setOpenDetail] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedRow, setSelectedRow] = useState<ILeadListItemResult | undefined>(undefined);


  //TODO Skeleton ayarlanacak
  //TODO success ve error mesajları ayarlanacak.

  // queries
  const { data: userData, isLoading: userLoading, isFetching: userFetching, error: userError } = useUserDetailQuery({
    input: {
      _id: id || ""
    }
  });
  const { data: permissionListData, isLoading: permissionListLoading, error: permissionListError } = usePermissionListQuery({
    "input": {
      "filter": {
        "actions": ["manage", "view", "import", "export"]
      }
    }
  } as any);
  const { data: permissionListAllData, isLoading: permissionListAllLoading, error: permissionListAllError } = usePermissionListQuery({
    "input": {
      "filter": {
        "actions": ["import", "export", "list", "create", "update", "delete", "detail"]
      }
    }
  } as any);
  const { data: userPermissionData, isLoading: userPermissionLoading, isFetching: userPermissionFetching, error: userPermissionError } = useUserPermissionDetailQuery({
    input: {
      _id: id || ""
    }
  })
  const { data: leadListData, isLoading: leadListLoading, isFetching: leadListFetching, error: leadListError } = useLeadListQuery({
    input: {
      "filter": {
        assignedIds: [id || ""]
      }
    }
  })
  const { data: leadStatusesData } = useLeadStatusesQuery({})
  const { data: leadSourcesData } = useLeadSourcesQuery({})

  // mutations
  const [userUpdatePermission, { data: userUpdatePermissionData, isLoading: userUpdatePermissionLoading, error: userUpdatePermissionError }] = useUserPermissionUpdateMutation()
  const [leadUpdate] = useLeadUpdateMutation()

  const permissions = permissionListData?.data || [];
  const permissionsAll = permissionListAllData?.data || [];

  const groupBySubject = (permissions: IPermission[]) => {
    return permissions.reduce((acc, permission) => {
      if (!acc[permission.subject]) {
        acc[permission.subject] = [];
      }
      acc[permission.subject].push(permission);
      return acc;
    }, {} as Record<string, IPermission[]>);
  };


  const groupByDomain = (permissions: IPermission[]) => {
    return permissions.reduce((acc, permission) => {
      if (!acc[permission.domain]) {
        acc[permission.domain] = [];
      }
      acc[permission.domain].push(permission);
      return acc;
    }, {} as Record<string, IPermission[]>);
  };


  const mainGroupedPermissions = groupByDomain(permissions as IPermission[]);
  const groupedPermissions = groupBySubject(permissions as IPermission[]);
  const groupedPermissionsAll = groupBySubject(permissionsAll as IPermission[]);

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: downMd ? 0 : theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
  }));



  const isIncludesRole = (permissionId: IPermission['_id']) => {
    const inRolePermissions = userPermissionData?.rolePermissions.some(p => p._id === permissionId);
    return inRolePermissions
  };


  const isProhibited = (permissionId: IPermission['_id']) => {
    const inProhibitedPermissions = userPermissionData?.userProhibitedPermissions.some(p => p._id === permissionId);
    return inProhibitedPermissions;
  };

  const handleCheckboxChange = (permissionId: IPermission['_id']) => {
    if (!isProhibited(permissionId)) {
      setSelectedPermissionIds(prev => {
        if (prev.includes(permissionId)) {
          if (isIncludesRole(permissionId)) {
            setProhibitedPermissionIds(prev => [...prev, permissionId]);
          }
          return prev.filter(id => id !== permissionId);
        } else {
          if (isIncludesRole(permissionId)) {
            setProhibitedPermissionIds(prohibitedPermissionIds.filter(id => id !== permissionId));
          }
          return [...prev, permissionId];
        }
      })
    } else {
      setSelectedPermissionIds(prev => {
        if (prev.includes(permissionId)) {
          if (isIncludesRole(permissionId)) {
            setProhibitedPermissionIds(prev => [...prev, permissionId]);
          }
          return prev.filter(id => id !== permissionId);
        } else {
          if (isIncludesRole(permissionId)) {
            setProhibitedPermissionIds(prohibitedPermissionIds.filter(id => id !== permissionId));
          }
          return [...prev, permissionId];
        }
      })
    }
  }

  const handleUpdatePermissions = () => {
    if (userPermissionData) {
      userUpdatePermission({
        input: {
          permissionIds: selectedPermissionIds.filter(id => !isIncludesRole(id)),
          prohibitedPermissionIds: prohibitedPermissionIds
        },
        filter: {
          _id: id || ""
        }
      })
    }
  }

  const leadInfoCell: TCellRenderFunc = (cellParams) => {
    return (
      <Stack direction={"row"} alignItems={"center"} justifyContent={"flex-start"} gap={1}>
        <Avatar alt={cellParams.value.fullName} src={Default1} sx={{ width: 35, height: 35 }} />
        <Stack direction={"column"} alignItems={"flex-start"} justifyContent={"center"} gap={0.5}>
          <Typography fontSize={"12px"} fontWeight={500} color={"text.primary"}>{cellParams.value.fullName}</Typography>
          <Typography fontSize={"10px"} fontWeight={500} color={"text.secondary"}>  {cellParams.value.email}</Typography>
        </Stack>
      </Stack>
    );
  };

  useEffect(() => {
    if (userPermissionData) {
      // prohibitedta olanları buradan çıkar
      setSelectedPermissionIds(
        userPermissionData?.rolePermissions.map(p => p._id)
          .concat(userPermissionData?.userAllowedPermissions.map(p => p._id))
          .filter(id => !userPermissionData?.userProhibitedPermissions.some(p => p._id === id))
      )
      setProhibitedPermissionIds(userPermissionData?.userProhibitedPermissions.map(p => p._id))
    }
  }, [userPermissionData]);

  return <Grid item xs={12}>

    <HeaderBase
      title={t("crm.organizationalChart.users.userDetail")}
      showBackButton={true}
    />
    <Stack direction={"column"} mt={2} borderRadius={"20px"} sx={{ boxShadow: "0px 0px 20px #ccc" }} >
      <Box
        width={"100%"}
        component={"img"}
        src={BgUserDetail}
        sx={{ borderTopLeftRadius: "20px", borderTopRightRadius: "20px", height: "200px", filter: "brightness(0.8)", }}
      />
      <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} mx={2}>
        <Stack direction={"row"} alignItems={"center"} sx={{ mt: "-120px", }}>
          <Avatar
            sx={{ width: "120px", height: "120px", ml: 2, border: "3px solid #fff", boxShadow: "0px 0px 5px #bbb" }}
            src={userData?.profileImage?.thumbnailPublicUrl}
          />
          <Stack direction={"column"}>
            <Typography fontSize={"24px"} fontWeight={600} ml={4} sx={{ zIndex: 1 }} color={"#fff"}>{userData?.name + " " + userData?.surname} </Typography>
            <Typography fontSize={"14px"} fontWeight={600} ml={4} sx={{ zIndex: 1 }} color={"#fff"}>{(userData as any)?.department?.name} </Typography> todo
          </Stack>
        </Stack>
        <MyTab
          labels={[
            t("crm.organizationalChart.users.profile"),
            t("crm.organizationalChart.users.permissions"),
            t("crm.organizationalChart.users.associatedLeads"),
          ]}
          // labels={["Profile", "Permissions", "Associated Users", "History"]}
          setSelectedTab={setSelectedTab}
          selectedTab={selectedTab}
        />
      </Stack>
    </Stack>

    {selectedTab === t("crm.organizationalChart.users.profile") && <Grid container mt={2} gap={2} mb={2}>
      <Grid item xs={12} width={"100%"}>

        <DetailBox title={t("crm.organizationalChart.users.about")}>

          <Grid container sx={{ mt: 2 }}>
            <Grid item display="flex" flexDirection="column" gap={1}>
              {[
                t("crm.organizationalChart.users.email"),
                t("crm.organizationalChart.users.phoneNumber"),
                t("crm.organizationalChart.users.country"),
                t("crm.organizationalChart.users.city"),
                t("crm.organizationalChart.users.address"),
                t("crm.organizationalChart.users.birthDate"),
                t("crm.organizationalChart.users.gender"),
                t("crm.organizationalChart.users.language"),
                t("crm.organizationalChart.users.description"),
                t("crm.organizationalChart.users.lastLoginDate"),
              ].map((label, index) => (
                <Box display="flex" alignItems="center" minHeight={35} key={index}>
                  <Typography fontSize={"14px"} fontWeight={600}>
                    {label}
                  </Typography>
                </Box>
              ))}
            </Grid>

            <Grid item display="flex" flexDirection="column" gap={1}>
              {[
                userData?.email,
                (userData as any)?.phoneNumber,
                userData?.country,
                userData?.city,
                userData?.address,
                userData?.birthDate ? moment(userData?.birthDate).format("DD/MM/YYYY") : "",
                userData?.gender,
                userData?.language,
                userData?.description,
                userData?.lastLoginDate ? moment((userData as any)?.lastLoginDate).format("DD/MM/YYYY") : "",
              ].map((value, index) => (
                <Box display="flex" alignItems="center" minHeight={35} key={index}>
                  <Typography fontSize={"14px"} fontWeight={400}>
                    : {value}
                  </Typography>
                </Box>
              ))}
            </Grid>
          </Grid>
        </DetailBox>
      </Grid>
    </Grid>}

    {selectedTab === t("crm.organizationalChart.users.permissions") && <Grid container mt={2} gap={2} mb={2}>
      <Grid item xs={12} width={"100%"}>

        <DetailBox title={t("crm.organizationalChart.users.permissions")}
          rightButton={{
            title: t("update"),
            icon: <Update sx={{ mr: 1 }} />,
            onClick: handleUpdatePermissions
          }}
        >
          <Stack width={"100%"}>

            {Object.keys(mainGroupedPermissions).reverse().map((domain, index) => (
              <Accordion key={domain} expanded={expandedAccordions[domain] || false} className="accordion-domain"
                onChange={() => {
                  setExpandedAccordions(prevState => ({
                    ...prevState,
                    [domain]: !prevState[domain]
                  }));
                }}>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls={`panel-${domain}-content`}
                  id={`panel-${domain}-header`}
                >
                  <Stack direction={{ xs: "column", md: "row" }} alignItems="center" justifyContent={"space-between"} sx={{ width: "100%" }}>
                    <Typography fontSize={"large"} fontWeight={"bold"}>
                      {domain?.slice(0, 1).toUpperCase() + domain?.slice(1)}
                    </Typography>
                    {!downLg && <Stack direction="row" spacing={1}>
                      {mainGroupedPermissions[domain].reduce((uniqueSubdomains: string[], permission: IPermission) => {
                        if (!uniqueSubdomains.includes(permission.subdomain)) {
                          uniqueSubdomains.push(permission.subdomain);
                        }
                        return uniqueSubdomains;
                      }, []).map(subdomain => (
                        <Chip
                          key={subdomain}
                          label={subdomain?.slice(0, 1).toUpperCase() + subdomain?.slice(1)}
                          // size="small"
                          sx={{ fontWeight: 'bold', opacity: 0.7 }}
                          variant="outlined"
                          color="primary"
                        />
                      ))}
                    </Stack>}
                  </Stack>
                </AccordionSummary>
                <AccordionDetails
                  sx={{

                  }}>
                  {!downLg && <Stack direction="row" alignItems="center" justifyContent={"flex-end"} sx={{ width: "100%" }}>
                    <Stack direction={"row"} width={"170px"} alignItems={"center"}>
                      <Typography fontSize={"small"} fontWeight={"bold"}>
                        {t("organizationLevel")}
                      </Typography>
                      <Tooltip title={t("organizationLevelDesc")}>
                        <InfoOutlined sx={{ fontSize: "14px", ml: "2px" }} />
                      </Tooltip>
                    </Stack>
                    <Stack direction={"row"} width={"150px"} alignItems={"center"}>
                      <Typography fontSize={"small"} fontWeight={"bold"}>
                        {t("departmentLevel")}
                      </Typography>
                      <Tooltip title={t("departmentLevelDesc")}>
                        <InfoOutlined sx={{ fontSize: "14px", ml: "2px" }} />
                      </Tooltip>
                    </Stack>
                    <Stack direction={"row"} width={"160px"} alignItems={"center"}>
                      <Typography fontSize={"small"} fontWeight={"bold"}>
                        {t("userLevel")}
                      </Typography>
                      <Tooltip title={t("userLevelDesc")}>
                      <InfoOutlined sx={{ fontSize: "14px", ml: "2px" }} />
                    </Tooltip>
                  </Stack>
                  </Stack>}
                {Object.keys(groupedPermissions)
                  .filter((subject) => groupedPermissions[subject][0]?.domain === domain)
                  .map((subject) => (
                    <Accordion key={subject} expanded={false}>
                      <AccordionSummary
                        expandIcon={null}>
                        <Stack direction={{ xs: "column", lg: "row" }} alignItems="center" justifyContent={"space-between"} sx={{ width: "100%" }}>
                          <Typography fontSize={"medium"} fontWeight={"bold"}>
                            {subject?.slice(0, 1).toUpperCase() + subject?.slice(1)}
                          </Typography>
                          {downLg && <Stack direction="row" alignItems="center" justifyContent={"center"} sx={{ width: "100%" }}>
                            <Stack width={{ xs: "75px", sm: "100px", lg: "150px" }}>
                              <Typography fontSize={"10px"} textAlign={"center"} fontWeight={"bold"} sx={{ opacity: 0.5 }}>
                                Organization
                              </Typography>
                            </Stack>
                            <Stack width={{ xs: "75px", sm: "100px", lg: "150px" }}>
                              <Typography fontSize={"10px"} textAlign={"center"} fontWeight={"bold"} sx={{ opacity: 0.5 }}>
                                Department
                              </Typography>
                            </Stack>
                            <Stack width={{ xs: "75px", sm: "100px", lg: "150px" }}>
                              <Typography fontSize={"10px"} textAlign={"center"} fontWeight={"bold"} sx={{ opacity: 0.5 }}>
                                User
                              </Typography>
                            </Stack>
                          </Stack>}
                          {downLg && <Divider sx={{ width: { xs: '80%', sm: '300px' }, mr: { xs: "0px", sm: "50px" }, mt: 0.5, mb: 0.5 }} />}
                          <Stack direction="row" alignItems="center" justifyContent={"flex-end"}>
                            <Stack direction="row">
                              <Stack width={{ xs: "75px", sm: "100px", lg: "150px" }} >
                                {groupedPermissions[subject].filter((permission) => permission.actionScope === 'org').map((permission) => {
                                  return <Stack direction={"row"} alignItems={"center"} key={permission._id}>
                                    <FormControlLabel
                                      key={permission._id}
                                      onClick={(event) => {
                                        event.stopPropagation();
                                      }}
                                      control={
                                        <Checkbox
                                          checked={selectedPermissionIds.includes(permission._id)}
                                          disabled={isIncludesRole(permission._id) && !openedPermissions.includes(permission._id)}
                                          onChange={() => {
                                            handleCheckboxChange(permission._id)
                                          }}
                                          icon={isIncludesRole(permission._id) ? <Close sx={{ fontSize: "15px", m: "2.5px", border: "2px solid", borderRadius: "3px" }} /> : undefined}
                                        />
                                      }
                                      label={permission.action?.slice(0, 1).toUpperCase() + permission.action?.slice(1)}
                                      sx={{
                                        ml: { xs: 0, sm: "10px" },
                                        ".MuiFormControlLabel-label": {
                                          fontSize: { xs: "11px", md: "small" },
                                          fontWeight: "bold",
                                        }
                                      }}
                                    />
                                    {isIncludesRole(permission._id) && <IconButton
                                      size="small"
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        openedPermissions?.includes(permission._id) ? setOpenedPermissions(openedPermissions.filter((id: any) => id !== permission._id)) : setOpenedPermissions([...openedPermissions, permission._id]);
                                      }}
                                      sx={{ ml: -2 }}
                                    >
                                      {openedPermissions?.includes(permission._id) ? <Lock sx={{ fontSize: "16px", color: "primary" }} /> : <LockOpen sx={{ fontSize: "16px", color: "primary" }} />}
                                    </IconButton>}
                                  </Stack>
                                })}
                              </Stack>
                              <Stack width={{ xs: "75px", sm: "100px", lg: "150px" }} >
                                {groupedPermissions[subject].filter((permission) => permission.actionScope === 'dep').map((permission) => {
                                  return <Stack direction={"row"} alignItems={"center"} key={permission._id}>
                                    <FormControlLabel
                                      key={permission._id}
                                      onClick={(event) => {
                                        event.stopPropagation();
                                      }}
                                      control={
                                        <Checkbox
                                          checked={selectedPermissionIds.includes(permission._id)}
                                          disabled={isIncludesRole(permission._id) && !openedPermissions.includes(permission._id)}
                                          onChange={() => {
                                            handleCheckboxChange(permission._id)
                                          }}
                                        />
                                      }
                                      label={permission.action?.slice(0, 1).toUpperCase() + permission.action?.slice(1)}
                                      sx={{
                                        ml: { xs: 0, sm: "10px" },
                                        ".MuiFormControlLabel-label": {
                                          fontSize: { xs: "11px", md: "small" },
                                          fontWeight: "bold",
                                        }
                                      }}
                                    />
                                    {isIncludesRole(permission._id) && <IconButton
                                      size="small"
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        openedPermissions?.includes(permission._id) ? setOpenedPermissions(openedPermissions.filter((id: any) => id !== permission._id)) : setOpenedPermissions([...openedPermissions, permission._id]);
                                      }}
                                      sx={{ ml: -2 }}
                                    >
                                      {openedPermissions?.includes(permission._id) ? <Lock sx={{ fontSize: "16px", color: "primary" }} /> : <LockOpen sx={{ fontSize: "16px", color: "primary" }} />}
                                    </IconButton>}
                                  </Stack>
                                })}
                              </Stack>
                              <Stack width={{ xs: "75px", sm: "100px", lg: "150px" }}>
                                {groupedPermissions[subject].filter((permission) => permission.actionScope === 'own').map((permission) => {
                                  return <Stack direction={"row"} alignItems={"center"} key={permission._id}>
                                    <FormControlLabel
                                      key={permission._id}
                                      onClick={(event) => {
                                        event.stopPropagation();
                                      }}
                                      control={
                                        <Checkbox
                                          checked={selectedPermissionIds.includes(permission._id)}
                                          disabled={isIncludesRole(permission._id) && !openedPermissions.includes(permission._id)}
                                          onChange={() => {
                                            handleCheckboxChange(permission._id)
                                          }}
                                        />
                                      }
                                      label={permission.action?.slice(0, 1).toUpperCase() + permission.action?.slice(1)}
                                      sx={{
                                        ml: { xs: 0, sm: "10px" },
                                        ".MuiFormControlLabel-label": {
                                          fontSize: { xs: "11px", md: "small" },
                                          fontWeight: "bold",
                                        }
                                      }}
                                    />
                                    {isIncludesRole(permission._id) && <IconButton
                                      size="small"
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        openedPermissions?.includes(permission._id) ? setOpenedPermissions(openedPermissions.filter((id: any) => id !== permission._id)) : setOpenedPermissions([...openedPermissions, permission._id]);
                                      }}
                                      sx={{ ml: -2 }}
                                    >
                                      {openedPermissions?.includes(permission._id) ? <Lock sx={{ fontSize: "16px", color: "primary" }} /> : <LockOpen sx={{ fontSize: "16px", color: "primary" }} />}
                                    </IconButton>}
                                  </Stack>
                                })}
                              </Stack>
                            </Stack>
                          </Stack>
                        </Stack>
                      </AccordionSummary>
                    </Accordion>
                  ))}
              </AccordionDetails>
              </Accordion>
            ))}

        </Stack>
      </DetailBox>
    </Grid>
    </Grid>
}

    { selectedTab === t("crm.organizationalChart.users.associatedLeads") && <Grid container mt={2} gap={2} mb={2}>
    <Grid item xs={12}>
      <DetailBox title={t("crm.organizationalChart.users.associatedLeads")}>
        <Grid item xs={12} display="flex" flexDirection="column" gap={1}>
          <Stack direction="row" justifyContent="space-between" minHeight={"400px"} mb={2}>
            {leadListData ?
              leadListData?.count > 0 ?
                <BaseDataGrid
                  rows={
                    leadListData?.data?.map((lead, index) => ({
                      id: (index + 1),
                      _id: lead._id,
                      test: {
                        fullName: lead.fullName ?? "",
                        position: lead.position ?? "",
                        email: lead.email ?? "",
                      },
                      fullName: lead.fullName || "-",
                      position: lead.position || "-",
                      email: lead.email || "-",
                      website: lead.website || "-",
                      phoneNumber: lead.phoneNumber || "-",
                      company: lead.company || "-",
                      sector: lead.sector || "-",
                      status: lead.status || null,
                      source: lead.source || null,
                      type: lead.type || "-",
                      qualification: lead.qualification || "-",
                      country: lead.country || "-",
                      state: lead.state || "-",
                      city: lead.city || "-",
                      language: lead.language || "-",
                      leadValue: lead.leadValue || "-",
                      organization: lead.organization._id || "-",
                      department: lead.department || "-",
                    })) || []
                  }

                  columns={[
                    {
                      config: {
                        baseGridColType: BaseGridColType.text,
                        column: {
                          field: "id",
                          disableColumnMenu: true,
                          filterable: false,
                          width: 40,
                          align: "center",
                        },
                        headerConfig: {
                          name: "#",
                          align: "center",
                        },
                      },
                    },
                    {
                      config: {
                        baseGridColType: BaseGridColType.any,
                        column: {
                          field: "test",
                          width: 180,
                        },
                        headerConfig: {
                          icon: "radix-icons:person",
                          name: `${t("crm.lead.lead.name")}`,
                        },
                        overrideCell: leadInfoCell,
                      },
                    },
                    {
                      config: {
                        baseGridColType: BaseGridColType.text,
                        column: {
                          field: "position",
                          editable: true,
                        },
                        headerConfig: {
                          icon: "radix-icons:crosshair-2",
                          name: t("crm.lead.lead.position"),
                        },
                      },
                    },
                    {
                      config: {
                        baseGridColType: BaseGridColType.text,
                        column: {
                          field: "email",
                          editable: true,
                        },
                        headerConfig: {
                          icon: "radix-icons:envelope-closed",
                          name: t("crm.lead.lead.email"),
                        },
                      },
                    },
                    {
                      config: {
                        baseGridColType: BaseGridColType.text,
                        column: {
                          field: "phoneNumber",
                          filterable: false,
                          editable: true,
                        },
                        headerConfig: {
                          icon: "mi:call",
                          name: t("crm.lead.lead.phone"),
                        },
                      },
                    },
                    {
                      config: {
                        baseGridColType: BaseGridColType.object,
                        column: {
                          field: "status",
                          editable: true,
                        },
                        headerConfig: {
                          icon: "majesticons:chat-status-line",
                          name: t("crm.lead.lead.status"),
                          creatable: false
                        },
                        filterConfig: {
                          customFieldName: `statusIds`,
                          isArrayFilter: true,
                        },
                        valueOptionFields: leadStatusesData?.data,
                        //   changed: ["valueOptions", "cell", "editCell", "header"],
                      },
                    },
                    {
                      config: {
                        baseGridColType: BaseGridColType.object,
                        column: {
                          field: "source",
                          editable: true,
                          width: 130,
                        },
                        headerConfig: {
                          icon: "mdi:source-fork",
                          name: t("crm.lead.lead.source"),
                          creatable: false
                        },
                        filterConfig: {
                          customFieldName: `sourceIds`,
                          isArrayFilter: true,
                        },
                        valueOptionFields: leadSourcesData?.data,
                      },
                      //   changed: ["valueOptions", "cell", "editCell", "header"],
                    },
                    {
                      config: {
                        baseGridColType: BaseGridColType.text,
                        column: {
                          field: "website",
                          width: 140,
                          filterable: false,
                          editable: true,
                        },
                        headerConfig: {
                          icon: "streamline:web",
                          name: t("crm.lead.lead.website"),
                        },
                      },
                    },
                    {
                      config: {
                        baseGridColType: BaseGridColType.text,
                        column: {
                          field: "company",
                          editable: true,
                        },
                        headerConfig: {
                          icon: "material-symbols:business-center-outline",
                          name: t("crm.lead.lead.company"),
                        },
                      },
                    },
                    {
                      config: {
                        baseGridColType: BaseGridColType.text,
                        column: {
                          field: "sector",
                          editable: true,
                        },
                        headerConfig: {
                          icon: "ic:round-business",
                          name: t("crm.lead.lead.sector"),
                        },
                      },
                    },
                    {
                      config: {
                        baseGridColType: BaseGridColType.enum,
                        column: {
                          field: "type",
                          editable: true,
                          width: 90,
                        },
                        headerConfig: {
                          icon: "oui:app-users-roles",
                          name: t("crm.lead.lead.type"),
                          creatable: false,
                        },
                        filterConfig: {
                          customFieldName: `types`,
                          isArrayFilter: true,
                        },
                      },
                      data: LeadType,
                      //   changed: ["valueOptions", "cell", "editCell"],
                    },
                    {
                      config: {
                        baseGridColType: BaseGridColType.enum,
                        column: {
                          field: "qualification",
                          editable: true,
                          width: 150,
                        },
                        headerConfig: {
                          icon: "octicon:ruby-16",
                          name: t("crm.lead.lead.qualification"),
                          creatable: false,
                        },
                        filterConfig: {
                          customFieldName: `qualifications`,
                          isArrayFilter: true,
                        },
                      },
                      data: LeadQualification,
                    },
                    {
                      config: {
                        baseGridColType: BaseGridColType.text,
                        column: {
                          field: "country",
                          editable: true,
                        },
                        headerConfig: {
                          icon: "bi:geo-alt",
                          name: t("crm.lead.lead.country"),
                        },
                      },
                    },
                    {
                      config: {
                        baseGridColType: BaseGridColType.text,
                        column: {
                          field: "state",
                          editable: true,
                        },
                        headerConfig: {
                          icon: "bi:geo",
                          name: t("crm.lead.lead.state"),
                        },
                      },
                    },
                    {
                      config: {
                        baseGridColType: BaseGridColType.text,
                        column: {
                          field: "city",
                          editable: true,
                        },
                        headerConfig: {
                          icon: "hugeicons:city-03",
                          name: t("crm.lead.lead.city"),
                        },
                      },
                    },
                    {
                      config: {
                        baseGridColType: BaseGridColType.enum,
                        column: {
                          field: "language",
                          width: 100,
                          editable: true,
                        },
                        headerConfig: {
                          icon: "clarity:language-line",
                          name: t("crm.lead.lead.language"),
                        },
                        filterConfig: {
                          customFieldName: `languages`,
                          isArrayFilter: true,
                        },
                      },
                      data: Language,
                      //   changed: ["valueOptions", "cell"],
                    },
                    {
                      config: {
                        baseGridColType: BaseGridColType.text,
                        column: {
                          field: "leadValue",
                          width: 150,
                          filterable: false,
                          editable: true,
                        },
                        headerConfig: {
                          icon: "fluent:star-32-regular",
                          name: t("crm.lead.lead.leadValue"),
                        },
                      },
                    },
                    {
                      config: {
                        baseGridColType: BaseGridColType.text,
                        column: {
                          field: "department",
                          width: 150,
                          editable: false,
                        },
                        headerConfig: {
                          icon: "mingcute:department-line",
                          name: t("crm.lead.lead.department"),
                        },
                        filterConfig: {
                          customFieldName: `departmentIds`,
                          isArrayFilter: true,
                        },
                      },
                    },
                  ]}
                  actionColumn={{
                    width: 70,
                    defaultActions: {
                      view: {
                        clickConfig: {
                          setSelectedRow: setSelectedRow,
                          setOpenAction: setOpenDetail,
                        },
                      },
                      edit: {
                        clickConfig: {
                          setSelectedRow: setSelectedRow,
                          setOpenAction: setOpenUpdate,
                        },
                      },
                      delete: {
                        clickConfig: {
                          setSelectedRow: setSelectedRow,
                          setOpenAction: setOpenDelete,
                        },
                      },
                    },
                    actionHeaderName: t("crm.actions"),
                  }}
                  loading={leadListLoading || leadListFetching}
                  listFilter={{
                    filterInput: {},
                    setFilterInput: () => null,
                  }}
                  count={leadListData?.count}
                  config={{
                    columnVisibilityModel: {
                      website: false,
                      company: false,
                      sector: false,
                      country: false,
                      state: false,
                      city: false,
                      language: false,
                      leadValue: false,
                      department: false,
                    },
                    features: [],
                  }}
                  update={{
                    updateQuery: leadUpdate,
                  }}
                  disableColumnFilter={true}
                  hideFooterPagination={true}
                />
                :
                <EmptyState
                  content1={t("crm.lead.lead.emptyState1")}
                /> : null
            }



          </Stack>
        </Grid>
      </DetailBox>
    </Grid>
  </Grid>
    }

  </Grid >
});

export default UserDetail;


const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharp sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: 'rgba(255,255,255, .3)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
  ...theme.applyStyles('dark', {
    backgroundColor: 'rgba(255, 255, 255)',
  }),
}));
