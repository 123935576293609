import { useState, lazy, Suspense } from 'react';


const ChartEditDrawer = lazy(() => import('./ChartEditDrawer'));


const ChartEditWrapper = ({
    open,
    setOpen,
    orgChartData,
    setConvertedChart
}: any) => {

    const [mounted, setMounted] = useState(false);

    if (open && !mounted) {
        setMounted(true);
    }

    return (
        <>
            {mounted && (
                <Suspense>
                    <ChartEditDrawer
                        open={open}
                        setOpen={setOpen}
                        key={orgChartData?.valueOf()?.toString()}
                        orgChartData={orgChartData}
                        setConvertedChart={setConvertedChart}
                    />
                </Suspense>
            )}
        </>
    );
};

export default ChartEditWrapper;