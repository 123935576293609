import {
  Stack,
  Box,
  Select,
  MenuItem,
  TablePaginationProps,
} from "@mui/material";
import { PaginationComponent } from "./PaginationComponent";
import { IPaginationComponentProps } from "./PaginationComponent.interface";
import { defaultPageSize } from "./PaginationComponent.constant";
import { t } from "i18next";

// React.JSXElementConstructor<GridSlotProps["pagination"]> | null

export const CustomPagination = (
  props: IPaginationComponentProps
): JSX.Element => {
  return (
    <Stack
      direction={"row"}
      width={"100%"}
      px={2}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <Box width={"100px"} />
      <PaginationComponent {...props} />
      <Stack
        direction={"row"}
        width={"100px"}
        gap={1}
        justifyContent={"flex-end"}
        alignItems={"center"}
      >
        {t("show")}
        <Select
          name="pageSize"
          value={props.pageSize ?? defaultPageSize}
          size="small"
          onChange={(e) => {
            if (props.onChange) {
              props.onChange({
                page: 1,
                pageSize: Number(e.target.value),
              });
            }
          }}
        >
          <MenuItem sx={{ fontSize: "12px" }} value={1}>
            1
          </MenuItem>
          <MenuItem sx={{ fontSize: "12px" }} value={5}>
            5
          </MenuItem>
          <MenuItem sx={{ fontSize: "12px" }} value={10}>
            10
          </MenuItem>
          <MenuItem sx={{ fontSize: "12px" }} value={20}>
            50
          </MenuItem>
        </Select>
      </Stack>
    </Stack>
  );
};
