// import PropTypes from 'prop-types';
import { FC, forwardRef } from 'react';
// icons
import { Icon } from '@iconify/react';
// @mui
import Box from '@mui/material/Box';
import { IconProps, SxProps } from '@mui/material';


type ComponentPropTypes = {
  icon: IconProps | string | any,
  sx?: SxProps,
  width?: number,
  onClick?: () => void,
}
// ----------------------------------------------------------------------

export const Iconify: FC<ComponentPropTypes> = forwardRef(({ icon, width = 20, sx, ...other }, ref) => (
  <Box
    ref={ref}
    component={Icon}
    className="component-iconify"
    icon={icon}
    sx={{ width, height: width, ...sx }}
    {...other}
    onClick={other.onClick}
  />
));

// Iconify.propTypes = {
//   icon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
//   sx: PropTypes.object,
//   width: PropTypes.number,
// };
