import ReactDOM from "react-dom/client";
import "./index.css";
import { Provider } from 'react-redux'
import { store } from "context";
import Wrapper from "Wrapper";
// import { logCurrentLanguage } from "localization";

// logCurrentLanguage();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(<Provider store={store}>
  <Wrapper />
</Provider>);

const originalConsoleError = console.error;
console.error = function (message, ...args) {
  if (typeof message === 'string' && message.includes('MUI X: Missing license key')) {
    // Bu hatayı görmezden gel
    return;
  }
  originalConsoleError.apply(console, [message, ...args]);
};