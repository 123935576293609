
import appointmentsLocale from "../subdomains/appointments/locales/en";
import remindersLocale from "../subdomains/reminders/locales/en";

const locale = {

  // Todo
  // users: {
  //   ...usersLocale,
  // },
  reminders: {
    ...remindersLocale,
  },
  appointments: {
    ...appointmentsLocale,
  }
};

export default locale;
