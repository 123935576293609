import { Route, Routes } from "react-router-dom";

import { List} from "../pages";

import { EstimatesLayout } from "../layouts/EstimatesLayout";

export const EstimatesRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<EstimatesLayout />}>
        <Route index element={<List />} />
       </Route>
    </Routes>
  );
};
