import { DownloadOutlined } from "@mui/icons-material"
import { Box, ToggleButtonGroup, Typography, ToggleButton as MuiToggleButton, CircularProgress } from "@mui/material"
import { t } from "i18next"


interface IToggleButton {
    onClick?: (event?: React.MouseEvent<HTMLButtonElement>) => void
    title?: string
    icon?: JSX.Element
    color?: "primary" | "secondary" | "tertiary" | "success" | "error" | "info" | "warning"
    loading?: boolean
    disabled?: boolean
    bgcolor?: string
}

export const ToggleButton = (props: IToggleButton) => {
    return <MuiToggleButton value={false}
        onClick={() => props?.onClick?.()}
        color={props.color ?? "primary"}
        disabled={props?.disabled ? props?.disabled : props?.loading ? props?.loading : false}
        sx={{
            borderRadius: "12px", height: "37px",
            borderColor: props.color ?? 'primary.light',
            bgcolor: props.bgcolor ?? "background.thirdWithBlur"
        }}
    >
        {props.loading ? <CircularProgress size={"1rem"} sx={{ mr: 1 }} /> : props?.icon}
        <Typography fontWeight={"bold"} fontSize={"12px"}>
            {props.title}
        </Typography>
    </MuiToggleButton>
}
