

export const expensesDomainRoutes = {
  base: "expenses",
  subdomains: {
    expenses: {
      base: "expenses",
    },
  },
};


export const baseExpensesRoute = (): string => `/${expensesDomainRoutes.base}/`;
export const listExpensesRoute = (): string =>
  `/${expensesDomainRoutes.base}/${expensesDomainRoutes.subdomains.expenses.base}`;