import dashboardLocale from "../domains/01-dashboard/locales/en";
import productsAndServicesLocale from "../domains/08-productsAndServices/locales/en";
import organizationalChartLocale from "../domains/02-organizationalChart/locales/en";
import leadLocale from "../domains/03-lead/locales/en";
import customerLocale from "../domains/04-customer/locales/en";
import preSalesLocale from "../domains/05-preSales/locales/en";
import salesLocale from "../domains/06-sales/locales/en";
import pricingLocale from "../domains/16-pricing/locales/en";
import calendarLocale from "../domains/12-calendar/locales/en";
import contractsLocale from "../domains/07-contracts/locales/en";
import supportLocale from "../domains/15-support/locales/en";
import reportsLocale from "../domains/13-reports/locales/en";
import tasksLocale from "../domains/11-tasks/locales/en";
import projectsLocale from "../domains/10-projects/locales/en";
import expensesLocale from "../domains/09-expenses/locales/en";
import notesLocale from "../domains/14-notes/locales/en";

const locale = {
  lead: {
    ...leadLocale,
  },
  dashboard: {
    ...dashboardLocale,
  },
  organizationalChart: {
    ...organizationalChartLocale,
  },
  productsAndServices: {
    ...productsAndServicesLocale,
  },
  customer: {
    ...customerLocale,
  },
  preSales: {
    ...preSalesLocale,
  },
  sales: {
    ...salesLocale,
  },
  pricing: {
    ...pricingLocale,
  },
  calendar: {
    ...calendarLocale
  },
  contracts: {
    ...contractsLocale
  },
  support: {
    ...supportLocale
  },
  reports: {
    ...reportsLocale
  },
  tasks: {
    ...tasksLocale
  },
  projects: {
    ...projectsLocale
  },
  expenses: {
    ...expensesLocale
  },
  notes: {
    ...notesLocale
  },
  pricingLabel: "Pricing",
  contractsLabel: "Contracts",
  dashboardLabel: "Dashboard",
  leadLabel: "Lead",
  customerLabel: "Customer",
  customers: "Customers",
  customerGroups: "Customer Groups",
  customerSegments: "Customer Segments",
  customerTags: "Customer Tags",
  marketing: "Marketing",
  campaigns: "Campaigns",
  emails: "Emails",
  sms: "SMS",
  socialMedia: "Social Media",
  preSalesLabel: "Pre-Sales",
  proposals: "Proposals",
  estimates: "Estimates",
  salesLabel: "Sales",
  invoices: "Invoices",
  payments: "Payments",
  productsServices: "Products & Services",
  businessIntelligence: "Business Intelligence",
  calendar_: "Calendar",
  supportLabel: "Support",
  accountMenu: "Account Menu",
  profile: "Profile",
  myAccount: "My Account",
  darkMode: "Dark Mode",
  lightMode: "Light Mode",
  languageSelectLabel: "Select Language",
  english: "English",
  turkish: "Turkish",
  settings: "Settings",
  logout: "Logout",
  searchPlaceholder: 'Try searching "insights"',
  mustCreateOrganizationTitle: "Create Organization",
  mustCreateOrganizationContent: "You must create an organization to use the panel.",
  mustCreateOrganizationSuccess: "Organization created successfully.",
  name: "Name",
  cancel: "Cancel",
  create: "Create",
  companyName: "Company Name",
  companyEmail: "Company Email",
  companyPhone: "Company Phone",
  country: "Country",
  city: "City",
  industry: "Industry",
  timezone: "Timezone",
  language: "Language",
  organizationalChartLabel: "Organizational Chart",
  expensesLabel: "Expenses",
  productsAndServicesLabel: "Products & Services",
  projectsLabel: "Projects",
  tasksLabel: "Tasks",
  calendarLabel: "Calendar",
  reportsLabel: "Reports",
  notesLabel: "Notes",
  companyLogo: "Company Logo",
  uploadLogo: "Upload Logo",
  actions: "Actions",
  yes: "Yes",
  no: "No",
};

export default locale;
