const locale = {
  reports: "Reports",
  report: "Report",
  reportConversions: "Report Conversions",
  title: "Reports",
  addReport: "Add Report",
  emptyStateDesc1: "No reports available yet.",
  emptyStateDesc2: " Generate and view detailed insights here to track your CRM activities and make data-driven decisions."
};

export default locale;



