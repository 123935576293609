const locale = {
  leadStatuses: "Leadstatuses",
  title: "Lead Statuses",
  create: "Create Status",
  delete: "Delete Status",
  deleteConfirm: "Are you sure you want to delete this status: ",
  deletedSuccessfully: "Lead status deleted successfully",
  createStatus: "Create Status",
  leadStatusCreateSuccess: "Lead status created successfully",
  name: "Status Name",
  color: "Status Color",
  icon: "Status Icon",
  showMore: "Show More",
  update: "Update",
  updateStatus: "Update Status",
  leadStatusUpdateSuccess: "Lead status updated successfully",
  index: "Index",
};

export default locale;
