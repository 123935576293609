import { Close, InfoOutlined } from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";
import { useState } from "react";

interface IInfoBox {
    content: string;
}

export const InfoBox = ({ content }: IInfoBox) => {

    const [showInfo, setShowInfo] = useState(true);

    return (
        showInfo ? <Box sx={{ bgcolor: "primary.lighter", p: 2, borderRadius: 2, position: 'relative' }}>
            <Box sx={{ position: "absolute", right: 12, top: 8, cursor: "pointer" }} onClick={() => setShowInfo(false)}>
                <Close sx={{ color: "primary.main", fontSize: "14px", }} />
            </Box>
            <Stack direction="row" alignItems={"center"}>
                <InfoOutlined sx={{ color: "secondary.main" }} />
                <Typography variant="body2" sx={{ color: "primary.main", ml: 2 }}>
                    {content}
                </Typography>
            </Stack>
        </Box> : null
    )
}

