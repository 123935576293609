/* eslint-disable react-hooks/exhaustive-deps */
import { Autocomplete, Box, Grid, InputLabel, TextField, Typography } from "@mui/material";
import { IBaseError, IGraphqlVariables } from "corede-common";
import { IBaseLeadEntity, ILeadContactAddInput, ILeadUpdateInput, ProductType } from "corede-common-cocrm";
import { getCurrentLanguage } from "localization";
import { enqueueSnackbar } from "notistack";
import { memo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { DefaultErrorHandlerUseEffect } from "utils/useEffect.helper";
import { useLeadContactAddMutation } from "../context/lead.api";
import ActionDialog from "components/dialog/ActionDialog";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { validateLeadContactAddInput } from "../validations/addContact.validation";
import { useUserListQuery } from "apps/crm/domains/02-organizationalChart/subdomains/users";
import ReactQuill from "react-quill-new";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import DetailBox from "components/box/DetailBox";
import { Update } from "@mui/icons-material";

export interface IContactItem {
  id?: string;
  _id?: string;
  name?: string;
  note?: string;
  contactedDate?: string;
}

export const LeadContactDetailDialog = memo((prop: {
  open: boolean;
  item: IContactItem | undefined;
  onClose: () => void;
}) => {

  // general
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();

  // queries

  // constants

  console.log(prop.item, "prop.item");


  return (
    <ActionDialog
      open={prop?.open}
      onClose={prop?.onClose}
      title={t("crm.lead.lead.contactDetail")}
      buttonTitle={t("add")}
      buttonColor="secondary"
      width={500}
      buttonCancelTitle={t("close")}
    >
      <Grid container mt={2} gap={2} mb={2}>
        <Grid item xs={12}>
          <Grid container sx={{ mt: 2 }}>
            <Grid item display="flex" flexDirection="column" gap={1}>
              {[
                "Contacted Name",
                "Note",
                "Contacted Date"
              ].map((label, index) => (
                <Box display="flex" alignItems="center" minHeight={35} key={index}>
                  <Typography fontSize={"14px"} fontWeight={600}>
                    {label}
                  </Typography>
                </Box>
              ))}
            </Grid>

            <Grid item display="flex" flexDirection="column" gap={1}>
              {[
                prop.item?.name,
                prop.item?.note,
                prop.item?.contactedDate,

              ].map((value, index) => (
                <Box display="flex" alignItems="center" minHeight={35} key={index}>
                  <Typography fontSize={"14px"} fontWeight={400}>
                    : {value}
                  </Typography>
                </Box>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ActionDialog>
  );
});
