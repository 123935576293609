export const commonRoutes = {
  create: "create",
  update: "update",
  updateWVersion: "update-w-version",
  roles: "roles",
  userList: "user-list",
  statuses: "statuses",
  sources: "sources",
  import: "import",

  categories: "categories",
};
