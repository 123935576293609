import invoicesLocale from "../subdomains/invoices/locales/en";
import returnsLocale from "../subdomains/returns/locales/en";
import paymentsLocale from "../subdomains/payments/locales/en";

const locale = {
  invoices: {
    ...invoicesLocale,
  },
  payments: {
    ...paymentsLocale,
  },
  returns: {
    ...returnsLocale,
  },
};

export default locale;
