import {
  // queries
  // IPermissionListInput, IPermissionListRequest, IPermissionListResponse, IPermissionListResult, permissionListQuery,

  IEstimateListInput,
  IEstimateListRequest,
  IEstimateListResponse,
  IEstimateListResult,
  estimateListQuery,
  IEstimateDetailInput,
  IEstimateDetailRequest,
  IEstimateDetailResponse,
  IEstimateDetailResult,
  estimateDetailQuery,

  // mutations
  IEstimateCreateInput,
  IEstimateCreateRequest,
  IEstimateCreateResponse,
  IEstimateCreateResult,
  estimateCreateQuery,
  IEstimateUpdateInput,
  IEstimateUpdateRequest,
  IEstimateUpdateResponse,
  IEstimateUpdateResult,
  estimateUpdateQuery,
  IEstimateUpdateFilterInput,
  IEstimateDeleteInput,
  IEstimateDeleteRequest,
  IEstimateDeleteResponse,
  IEstimateDeleteResult,
  estimateDeleteQuery,
} from "corede-common-cocrm";
import { commonApi } from "context/commonApi";
import { EndpointQueryBuilder } from "apps/helper/endpoint-query.builder";
import { estimatesTags } from "./estimates.tags";
import { IGraphqlVariables } from "corede-common";

export const estimatesApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({
    // estimatesTags: builder.query<
    //     IEstimateTagsResult,
    //     IGraphqlVariables<IEstimateTagsFilterInput>
    // >(
    //     EndpointQueryBuilder.BuildGraphqlQuery<
    //         IEstimateTagsRequest,
    //         IEstimateTagsResponse,
    //         IEstimateTagsResult,
    //         IEstimateTagsFilterInput,
    //     >({
    //         query: estimatesTagsQuery,
    //         providesTags: [estimatesTags.estimatesTags],
    //     })),

    estimateList: builder.query<
      IEstimateListResult,
      IGraphqlVariables<IEstimateListInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IEstimateListRequest,
        IEstimateListResponse,
        IEstimateListResult,
        IEstimateListInput
      >({
        query: estimateListQuery,
        providesTags: [estimatesTags.estimate, estimatesTags.estimates],
      })
    ),

    estimateDetail: builder.query<
      IEstimateDetailResult,
      IGraphqlVariables<IEstimateDetailInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IEstimateDetailRequest,
        IEstimateDetailResponse,
        IEstimateDetailResult,
        IEstimateDetailInput
      >({
        query: estimateDetailQuery,
        providesTags: [estimatesTags.estimate, estimatesTags.estimates],
      })
    ),

    // mutations

    estimateCreate: builder.mutation<
      IEstimateCreateResult,
      IGraphqlVariables<IEstimateCreateInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IEstimateCreateRequest,
        IEstimateCreateResponse,
        IEstimateCreateResult,
        IEstimateCreateInput
      >({
        query: estimateCreateQuery,
        invalidatesTags: [estimatesTags.estimate, estimatesTags.estimates],
      })
    ),

    estimateUpdate: builder.mutation<
      IEstimateUpdateResult,
      IGraphqlVariables<IEstimateUpdateInput, IEstimateUpdateFilterInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IEstimateUpdateRequest,
        IEstimateUpdateResponse,
        IEstimateUpdateResult,
        IEstimateUpdateInput,
        IEstimateUpdateFilterInput
      >({
        query: estimateUpdateQuery,
        invalidatesTags: [estimatesTags.estimate, estimatesTags.estimates],
      })
    ),

    estimateDelete: builder.mutation<
      IEstimateDeleteResult,
      IGraphqlVariables<IEstimateDeleteInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IEstimateDeleteRequest,
        IEstimateDeleteResponse,
        IEstimateDeleteResult,
        IEstimateDeleteInput
      >({
        query: estimateDeleteQuery,
        invalidatesTags: [estimatesTags.estimate, estimatesTags.estimates],
      })
    ),
  }),
});

export const {
  useEstimateListQuery,
  useLazyEstimateListQuery,
  useEstimateDetailQuery,
  useLazyEstimateDetailQuery,
  useEstimateCreateMutation,
  useEstimateUpdateMutation,
  useEstimateDeleteMutation,
} = estimatesApi;
