import { IMenuItem } from "interfaces/menu-item.interface";
import { useTranslation } from "react-i18next";
import {
  baseNotesRoute
} from "../routes/notes.base.route";

export const useNotesDomainMenuItem = (): IMenuItem => {
  const { t } = useTranslation(); 

  return {
    title: t("notes"), 
    path: baseNotesRoute(),
    children: [
    ],
  };
};
