
import tasksLocale from "../subdomains/tasks/locales/en";

const locale = {

  // Todo
  // users: {
  //   ...usersLocale,
  // },
  tasks: {
    ...tasksLocale,
  },
};

export default locale;
