import {
  GridAlignment,
  GridColDef,
  GridColType,
  GridColumnHeaderParams,
  GridSingleSelectColDef,
} from "@mui/x-data-grid-pro";
import { IGridColConfigInput } from "./IGridColConfigInput";
import { Stack } from "@mui/material";
import { Iconify } from "components/iconify";

const DEFAULT_HEADER_NAME: string = "";
const DEFAULT_DISABLE_COLUMN_MENU: boolean = false;
const DEFAULT_WIDTH: number = 120;
const DEFAULT_FILTERABLE: boolean = true;
const DEFAULT_SORTABLE: boolean = true;
const DEFAULT_ALIGN: GridAlignment = "left";
const DEFAULT_HEADER_ALIGN: GridAlignment = "left";
const DEFAULT_EDITABLE: boolean = false;
const DEFAULT_TYPE: GridColType = "string";
// const DEFAULT_VALUE_OPTIONS: any = {};
// const DEFAULT_CELL: any = {};
// const DEFAULT_RENDER_EDITCELL: any = {};
// const DEFAULT_RENDER_HEADER: any = {};

export const generateBaseColDef = (params: {
  config: IGridColConfigInput;
}): GridColDef => {
  const baseColDef: GridColDef = {
    field: params.config.column.field ?? "",
    headerName: params.config.headerConfig.name ?? DEFAULT_HEADER_NAME,
    disableColumnMenu:
      params.config.column.disableColumnMenu ?? DEFAULT_DISABLE_COLUMN_MENU,
    width: params.config.column.width ?? DEFAULT_WIDTH,
    filterable: params.config.column.filterable ?? DEFAULT_FILTERABLE,
    sortable: params.config.column.sortable ?? DEFAULT_SORTABLE,
    align: params.config.column.align ?? DEFAULT_ALIGN,
    headerAlign: params.config.headerConfig.align ?? DEFAULT_HEADER_ALIGN,
    editable: params.config.column.editable ?? DEFAULT_EDITABLE,
    type: DEFAULT_TYPE,
  };

  if (params.config.headerConfig) {
    baseColDef.renderHeader = (headerParams: GridColumnHeaderParams) => (
      <Stack
        direction="row"
        alignItems={params.config.headerConfig.align ?? "center"}
      >
        <Iconify icon={params.config.headerConfig.icon} sx={{ width: '15px', mr: 1, color: 'primary.main' }} />
        <span style={{ fontWeight: 500 }}>
          {headerParams.colDef.headerName}
        </span>


      </Stack>
    );
  }

  return baseColDef
};

export const generateBaseSingleSelectColDef = (params: {
  config: IGridColConfigInput;
}): GridSingleSelectColDef => {
  return {
    field: params.config.column.field ?? "",
    headerName: params.config.headerConfig.name ?? DEFAULT_HEADER_NAME,
    disableColumnMenu:
      params.config.column.disableColumnMenu ?? DEFAULT_DISABLE_COLUMN_MENU,
    width: params.config.column.width ?? DEFAULT_WIDTH,
    filterable: params.config.column.filterable ?? DEFAULT_FILTERABLE,
    sortable: params.config.column.sortable ?? DEFAULT_SORTABLE,
    align: params.config.column.align ?? DEFAULT_ALIGN,
    headerAlign: params.config.headerConfig.align ?? DEFAULT_HEADER_ALIGN,
    editable: params.config.column.editable ?? DEFAULT_EDITABLE,
    type: "singleSelect",
  };
};
