import { createSlice } from '@reduxjs/toolkit';

const { actions, reducer } = createSlice({
    name: 'auth',
    initialState: {
        accessToken: "",
        refreshToken: "",
        email: "",
        name: "",
        surname: "",
        userId: "",
        profileImage: "",
        type: "",
        organization: ""
    },
    reducers: {
        setUser: (state, action) => {
            state.accessToken = action.payload.accessToken ?? "";
            state.email = action.payload.user?.email ?? "";
            state.name = action.payload.user?.name ?? "";
            state.surname = action.payload.user?.surname ?? "";
            state.userId = action.payload.user?._id ?? "";
            state.type = action.payload.user?.type ?? "";
            state.refreshToken = action.payload.refreshToken ?? "";
            state.organization = action.payload.user?.organization?._id ?? "";
            localStorage.setItem('auth', JSON.stringify(action.payload));
        },
        setName: (state, action) => {
            state.name = action.payload;
        },
        setSurname: (state, action) => {
            state.surname = action.payload;
        },
        removeUser: (state) => {
            state.accessToken = "";
            state.refreshToken = "";
            state.email = "";
            state.name = "";
            state.surname = "";
            state.userId = "";
            state.type = "";
            state.organization = "";
            localStorage.removeItem('auth');
        }
    },
});


export const { setUser, removeUser, setName, setSurname } = actions;
export const authSlice = reducer;