import { IMenuItem } from "interfaces/menu-item.interface";
import { useTranslation } from "react-i18next";
import {
  basePreSalesRoute,
  listEstimatesRoute,
  listProposalsRoute,
} from "../routes/preSales.base.route";
import { PermissionSubdomain } from "corede-common-cocrm";

export const usePreSalesDomainMenuItem = (): IMenuItem => {
  const { t } = useTranslation(); 

  return {
    title: t("preSales"),
    path: basePreSalesRoute(),
    children: [
      {
        title: t("estimates"),
        path: listEstimatesRoute(),
        subdomain: PermissionSubdomain.estimate
      },
      {
        title: t("proposals"),
        path: listProposalsRoute(),
        subdomain: PermissionSubdomain.proposal
      },
    ],
  };
};
