const locale = {
  demo: "Demo",
  title: "CoCRM Marketing Nedir?",
  description: "CoCRM olarak, yalnızca mevcut müşteri ilişkilerinizi yönetmenizi kolaylaştırmakla kalmıyor, aynı zamanda pazarlama süreçlerinizi optimize ederek iş hedeflerinize ulaşmanızda sizlere destek sunuyoruz. CoCRM Marketing ile hedef kitlenize daha etkili bir şekilde ulaşarak, pazarlama kampanyalarınızı daha verimli hale getirebilirsiniz.",
  featuresTitle: "CoCRM Marketing İle;  ",
  features1: "Pazarlama kampanyalarınızı daha stratejik bir şekilde yönetmek için hedef kitlenizi ülke, şehir ve sektör bazında özelleştirebilir,  ",
  features2: "Marka bilinirliğinizi artırmak istediğiniz spesifik pazarlara yönelik kişiselleştirilmiş kampanyalar oluşturabilir,  ",
  features3: "Satış ve pazarlama hedeflerinizi desteklemek amacıyla müşteri yolculuğunu daha verimli bir şekilde takip edebilir,  ",
  features4: "Dijital pazarlama araçlarıyla entegre çalışarak sonuç odaklı stratejiler geliştirebilirsiniz.",
  buttonTitle: "Request a Quote",
  formDescription: "Formu doldurarak, bizimle iletişime geçebilirsiniz.",
};

export default locale;
