
import { CustomerDomainLayout } from "../layouts";
import { ContactsRouter } from "../subdomains/contacts/routes/ContactsRouter";
import { CustomerCategoriesRouter } from "../subdomains/customerCategories/routes/CustomerCategoriesRouter";
import { CustomersRouter } from "../subdomains/customers/routes/CustomersRouter";
import { customerDomainRoutes } from "./customer.base.route";
import { Route, Routes } from "react-router-dom";

export const CustomerDomainRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<CustomerDomainLayout />}>
        <Route index element={<CustomersRouter />} />
        <Route
          path={`${customerDomainRoutes.subdomains.customers.base}/*`}
          element={<CustomersRouter />}
        />
        <Route
          path={`${customerDomainRoutes.subdomains.contacts.base}/*`}
          element={<ContactsRouter />}
        />
        <Route
          path={`${customerDomainRoutes.subdomains.customerCategories.base}/*`}
          element={<CustomerCategoriesRouter />}
        />
      </Route>
    </Routes>
  );
};
