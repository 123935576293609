import { UniqueIdentifier } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { DragIndicator } from '@mui/icons-material';
import { Button, Stack, Typography } from '@mui/material';

type ItemsType = {
  id: UniqueIdentifier;
  title: string;
  email?: string;
  setSelectedLead?: any
  setOpenUserDrawer?: any
  leads?: any
};

const Items = ({ id, title, email, setSelectedLead, setOpenUserDrawer, leads }: ItemsType) => {

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: id,
    data: {
      type: 'item',
    },
  });
  return (
    <Stack
      ref={setNodeRef}
      {...attributes}
      style={{
        transition,
        transform: CSS.Translate.toString(transform),
      }}
      onClick={() => {
        setSelectedLead(leads?.filter((lead: any) => lead.email === email)[0]);
        setOpenUserDrawer(true);
      }}
      sx={{
        px: 2,
        py: 2,
        mb: 2,
        width: "90%",
        bgcolor: "background.secondary",
        boxShadow: "12px 12px 24px #e8e8e8, -12px -12px 24px #ffffff",
        borderRadius: 2,
        border: "1px solid #E2E8F0",
        ':hover': {
          border: "1px solid #CBD5E0",
        },
        cursor: 'pointer',
        opacity: isDragging ? 0.5 : 1
      }}>
      <Stack direction={"row"} alignItems={"center"} width={"100%"} justifyContent={"space-between"}>
        <Stack direction={"column"} >
          <Typography fontSize={"16px"} fontWeight={"bold"} color={"text.primary"}>{title}</Typography>
          <Typography fontSize={"12px"} color={"text.secondary"}>{email}</Typography>
        </Stack>
        <Button
          variant='text'
          size='small'
          {...listeners}
          color='secondary'
          sx={{ minWidth: 36, ml: 2, width: 42, height: 42 }}
          onClick={(e: any) => e.stopPropagation()}
        >
          <DragIndicator />
        </Button>
      </Stack>
    </Stack>
  );
};

export default Items;
