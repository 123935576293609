/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography } from "@mui/material";
import { IBaseError } from "corede-common";
import { IBaseDepartmentEntity } from "corede-common-cocrm";
import { getCurrentLanguage } from "localization";
import { enqueueSnackbar } from "notistack";
import { memo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { DefaultErrorHandlerUseEffect } from "utils/useEffect.helper";
import { useDepartmentDeleteMutation } from "../context/departments.api";
import ActionDialog from "components/dialog/ActionDialog";

export const DeleteDialog = memo((prop: {
  open: boolean;
  item: IBaseDepartmentEntity | undefined;
  onClose: () => void;
}) => {
  // general
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();

  // queries

  // mutations
  const [departmentDelete, { data: departmentDeleteData, isLoading: departmentDeleteIsLoading, error: departmentDeleteError }] = useDepartmentDeleteMutation();

  // constants

  // // action handlers
  const handleDelete = (_id: string) => {
    departmentDelete({ input: { _id: _id } });
  };

  // useEffects.success
  useEffect(() => {
    if (departmentDeleteData) {
      prop?.onClose();
      enqueueSnackbar(t("crm.organizationalChart.departments.deletedSuccessfully"), {
        variant: "success",
      });
    }
  }, [departmentDeleteData]);

  // // useEffects.error
  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      departmentDeleteError as IBaseError,
      currentLanguage
    );
  }, [departmentDeleteError, currentLanguage]);

  // useEffects.init

  return <ActionDialog
    open={prop?.open}
    onClose={prop?.onClose}
    title={t("crm.organizationalChart.departments.delete")}
    buttonTitle={t("delete")}
    handleClick={() => handleDelete(prop?.item?._id as string)}
    disabled={departmentDeleteIsLoading}
    loading={departmentDeleteIsLoading}
    buttonColor="error"
  >
    <Typography>
      {t("crm.organizationalChart.departments.deleteConfirm")}
    </Typography>
  </ActionDialog>
});
