import { GridFilterItem, GridFilterModel } from "@mui/x-data-grid-pro";
import { IGridColConfigInput } from "./IGridColConfigInput";
import { IFilter } from "corede-common";
import { getGridColConfig } from "./IGridColConfigInput.helper";

export function convertGridFilterItem(params: {
  gridFilterItem: GridFilterItem;
  config: IGridColConfigInput;
}): IFilter {
  const field =
    params.config.filterConfig?.customFieldName ?? params.gridFilterItem.field;
  const value = params.config.filterConfig?.isArrayFilter
    ? [params.gridFilterItem.value]
    : params.gridFilterItem.value;
  return {
    [field]: value,
  };
}

export function convertGridFilterModel<T extends IFilter>(params: {
  configs: IGridColConfigInput[];
  filterModel: GridFilterModel;
}): T {
  const result = params.filterModel.items
    .map((gridFilterItem) => {
      const config = getGridColConfig(params.configs, gridFilterItem.field);
      if (!gridFilterItem.value || !config || config.column.filterable === false) {
        return {};
      }
      return convertGridFilterItem({
        gridFilterItem: gridFilterItem,
        config: config,
      });
    })
    .reduce((prev, curr) => {
      return {
        ...prev,
        ...curr,
      };
    }, {});

  return result as T;
}
