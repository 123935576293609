import { SwipeableDrawer, Stack, Grid, Typography, Button, CircularProgress, Box } from '@mui/material';

type Props = {
    open: boolean;
    setOpen: (open: boolean) => void;
    size?: "small" | "medium" | "large"
    title: string | JSX.Element;
    children: React.ReactNode;
    handleSubmit?: () => void;
    buttonTitle: string;
    disabled?: boolean;
    loading?: boolean;
}

const ActionDrawer = (props: Props) => {

    return <SwipeableDrawer open={props.open}
        onOpen={() => props.setOpen(true)}
        onClose={() => props.setOpen(false)}
        anchor={'right'}
    >
        <Stack direction={"column"} justifyContent="space-between" height="100%"
            width={props.size ? props.size === "small" ? 400 : props.size === "medium" ? 600 : props.size === "large" ? 900 : 600 : 600}
            bgcolor={"background.firstWithBlur"} sx={{backdropFilter: "blur(5px)"}} >
            <Grid container spacing={3}  bgcolor={"background.firstWithBlur"} sx={{ borderBottom: 2, borderColor: 'divider', position: 'sticky', top: 0, zIndex: 1 }}>
                <Grid item xs={12} width={"100%"}>
                    <Stack pl={3} pb={1} direction="row" alignItems="center" justifyContent="space-between" width={"calc(100%-48px)"}>
                        {typeof props.title === "string" ? <Typography variant="h4" gutterBottom>
                            {props.title}
                        </Typography> :
                            <Box width={"100%"}>{props.title}</Box>}
                    </Stack>
                </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ flexGrow: 1, overflowY: "auto" }}>
                <Grid item xs={12}>
                    <Stack direction="column" gap={1} p={3} pt={5} pb={5}>
                        <Grid container spacing={2}>
                            {props.children}
                        </Grid>
                    </Stack>
                </Grid>
            </Grid>
            {props.handleSubmit && <Grid container spacing={3}  bgcolor={"background.firstWithBlur"} sx={{ borderTop: 2, borderColor: 'divider', position: 'sticky', bottom: 0, zIndex: 1, backdropFilter: "blur(5px)" }}>
                <Grid item xs={12}>
                    <Stack direction={"row"} justifyContent={"center"} alignItems={"center"} sx={{ pb: 3, px: 3 }}>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={() => props.handleSubmit!()}
                            disabled={props?.disabled}
                        >
                            {props.loading ? <CircularProgress size="1rem" /> : props.buttonTitle}
                        </Button>
                    </Stack>
                </Grid>
            </Grid>}
        </Stack>

    </SwipeableDrawer >

}

export default ActionDrawer