import {
  // queries
  // IPermissionListInput, IPermissionListRequest, IPermissionListResponse, IPermissionListResult, permissionListQuery,

  ILegalAgreementListInput,
  ILegalAgreementListRequest,
  ILegalAgreementListResponse,
  ILegalAgreementListResult,
  legalAgreementListQuery,
  ILegalAgreementDetailInput,
  ILegalAgreementDetailRequest,
  ILegalAgreementDetailResponse,
  ILegalAgreementDetailResult,
  legalAgreementDetailQuery,

  // mutations
  ILegalAgreementCreateInput,
  ILegalAgreementCreateRequest,
  ILegalAgreementCreateResponse,
  ILegalAgreementCreateResult,
  legalAgreementCreateQuery,
  ILegalAgreementUpdateInput,
  ILegalAgreementUpdateRequest,
  ILegalAgreementUpdateResponse,
  ILegalAgreementUpdateResult,
  legalAgreementUpdateQuery,
  ILegalAgreementUpdateFilterInput,
  ILegalAgreementDeleteInput,
  ILegalAgreementDeleteRequest,
  ILegalAgreementDeleteResponse,
  ILegalAgreementDeleteResult,
  legalAgreementDeleteQuery,
} from "corede-common-cocrm";
import { commonApi } from "context/commonApi";
import { EndpointQueryBuilder } from "apps/helper/endpoint-query.builder";
import { legalAgreementsTags } from "./legalAgreements.tags";
import { IGraphqlVariables } from "corede-common";

export const legalAgreementApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({
    // legalAgreementsTags: builder.query<
    //     ILegalAgreementTagsResult,
    //     IGraphqlVariables<ILegalAgreementTagsFilterInput>
    // >(
    //     EndpointQueryBuilder.BuildGraphqlQuery<
    //         ILegalAgreementTagsRequest,
    //         ILegalAgreementTagsResponse,
    //         ILegalAgreementTagsResult,
    //         ILegalAgreementTagsFilterInput,
    //     >({
    //         query: legalAgreementsTagsQuery,
    //         providesTags: [legalAgreementsTags.legalAgreementsTags],
    //     })),

    legalAgreementList: builder.query<
      ILegalAgreementListResult,
      IGraphqlVariables<ILegalAgreementListInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ILegalAgreementListRequest,
        ILegalAgreementListResponse,
        ILegalAgreementListResult,
        ILegalAgreementListInput
      >({
        query: legalAgreementListQuery,
        providesTags: [
          legalAgreementsTags.legalAgreementDetail,
          legalAgreementsTags.legalAgreementList,
        ],
      })
    ),

    legalAgreementDetail: builder.query<
      ILegalAgreementDetailResult,
      IGraphqlVariables<ILegalAgreementDetailInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ILegalAgreementDetailRequest,
        ILegalAgreementDetailResponse,
        ILegalAgreementDetailResult,
        ILegalAgreementDetailInput
      >({
        query: legalAgreementDetailQuery,
        providesTags: [
          legalAgreementsTags.legalAgreementDetail,
          legalAgreementsTags.legalAgreementList,
        ],
      })
    ),

    // mutations

    legalAgreementCreate: builder.mutation<
      ILegalAgreementCreateResult,
      IGraphqlVariables<ILegalAgreementCreateInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ILegalAgreementCreateRequest,
        ILegalAgreementCreateResponse,
        ILegalAgreementCreateResult,
        ILegalAgreementCreateInput
      >({
        query: legalAgreementCreateQuery,
        invalidatesTags: [
          legalAgreementsTags.legalAgreementDetail,
          legalAgreementsTags.legalAgreementList,
        ],
      })
    ),

    legalAgreementUpdate: builder.mutation<
      ILegalAgreementUpdateResult,
      IGraphqlVariables<
        ILegalAgreementUpdateInput,
        ILegalAgreementUpdateFilterInput
      >
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ILegalAgreementUpdateRequest,
        ILegalAgreementUpdateResponse,
        ILegalAgreementUpdateResult,
        ILegalAgreementUpdateInput,
        ILegalAgreementUpdateFilterInput
      >({
        query: legalAgreementUpdateQuery,
        invalidatesTags: [
          legalAgreementsTags.legalAgreementDetail,
          legalAgreementsTags.legalAgreementList,
        ],
      })
    ),

    legalAgreementDelete: builder.mutation<
      ILegalAgreementDeleteResult,
      IGraphqlVariables<ILegalAgreementDeleteInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ILegalAgreementDeleteRequest,
        ILegalAgreementDeleteResponse,
        ILegalAgreementDeleteResult,
        ILegalAgreementDeleteInput
      >({
        query: legalAgreementDeleteQuery,
        invalidatesTags: [
          legalAgreementsTags.legalAgreementDetail,
          legalAgreementsTags.legalAgreementList,
        ],
      })
    ),
  }),
});

export const {
  useLegalAgreementListQuery,
  useLazyLegalAgreementListQuery,
  useLegalAgreementDetailQuery,
  useLazyLegalAgreementDetailQuery,
  useLegalAgreementCreateMutation,
  useLegalAgreementUpdateMutation,
  useLegalAgreementDeleteMutation,
} = legalAgreementApi;
