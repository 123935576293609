const locale = {
  lead: "Lead",
  leads: "Leads",
  create: "Create Lead",
  update: "Update Lead",
  leadDetail: "Lead Detail",
  delete: "Delete",
  view: "View Lead",
  list: "List Leads",
  createdSuccessfully: "Lead created successfully",
  updatedSuccessfully: "Lead updated successfully",
  deleteConfirm: "Are you sure you want to delete? This action cannot be undone.",
  deletedSuccessfully: "Lead deleted successfully",
  createInfo: "You can create new leads here.",
  fullName: "Full Name",
  status: "Status",
  source: "Source",
  tags: "Tags",
  assigneds: "Assignees",
  position: "Position",
  email: "Email",
  website: "Website",
  phoneNumber: "Phone Number",
  company: "Company",
  sector: "Sector",
  type: "Type",
  qualification: "Qualification",
  country: "Country",
  state: "State/Province",
  city: "City",
  justOwn: "Just Own",
  isJunk: "Is Junk",
  note: "Note",
  language: "Language",
  listInfo: "List of all leads.",
  importedError: "Imported error",
  importedSuccessfully: "Imported successfully",
  downloadFormError: "Download form error",
  downloadFormSuccessfully: "Download form successfully",
  downloadExampleFormSuccessfully: "Download example form successfully",
  downloadExampleFormError: "Download example form error",
  title: "List Leads",
  emptyState1: "No leads found here.",
  emptyState2: "You can create or import leads immediately.",
  createLead: "Create Lead",
  importLead: "Import Lead",
  importTitle: "Import Leads",
  downloadExample: "Download Example",
  importText1: "You can import leads from this page.",
  importText2: "You can use the sample form below to publish your leads.",
  importExport: "Import/Export",
  exportData: "Export Data",
  importData: "Import Data",
  b2b: "B2B",
  b2c: "B2C",
  viewLead: "View",
  edit: "Edit",
  junk: "Junk",
  unJunk: "Un-Junk",
  addNewRole: "Add New Role",
  actions: "Actions",
  tagsPlaceholder: "Add tags",
  leadValue: "Lead Value",
  department: "Department",
  updateLeadSuccess: "Lead updated successfully",
  createLeadSuccess: "Lead created successfully", 
  addContact: "Add Contact",
  phone: "Phone",
  name: "Name",
  export: "Export",
  contactedDate: "Contacted Date",
  emptyStateContactContent1: "No contacts found here.",
  noEstimateContent1: "Estimates not found",
  noProposalContent1: "Proposals not found",
  emptyStateReminderList1: "Reminders not found",
  content: "Content",
  priority: "Priority",
  remindDate: "Reminder Date",
  remindUsers: "Remind Users",
  remindTitle: "Title",
  emptyStateNoteList1: "Notes not found",
  noteDate: "Note Date",
  noteUsers: "Note Users",
  noteTitle: "Title",
  leadConversions: "Lead Conversions",
  contactDetail: "Contact Detail",
  prefix: "Prefix",
  createdAt: "Created At",
  noDocumentContent1: "Documents not found",
  noActivityContent1: "Activities not found",
  info: "Info",
  contactHistory: "Contact History",
  estimates: "Estimates",
  proposals: "Proposals",
  documents: "Documents",
  notes: "Notes",
  reminders: "Reminders",
  activities: "Activities",
  about: "About",
  companyName: "Company Name",
  localization: "Localization",
  addEstimate: "Add Estimate",
  addProposal: "Add Proposal",
  addDocument: "Add Document",
  addNote: "Add Note",
  addReminder: "Add Reminder",
  addActivity: "Add Activity",
  businessType: "Business Type",
  tracking: "Tracking",
  leadCount: "leads",
  of: "of",
  appointment: "Appointment",
  proposalSent: "Proposal Sent",
  contactDetails: "Contact Details",
  emailAddress: "Email Address",
  assignees: "Assignees",
  noAssignees: "No Assignees",
  hasCreatedAt: "has created at ",
  createdBy: "Created by ",
  lastContacted: "Last Contacted",
  total: "Total",
  leadType: "Lead Type",
  lastContact: "Last Contact",
  noAppointments: "There are no appointments",
  makeAnAppointment: "Make an Appointment",
  appointments: "Appointments",
  contacted: "Contacted",
  onSiteEstimateWith: "On site estimate with",
  prospectName: "Prospect Name: ",
  noEstimate: "There are no estimates",
  noProposal: "There are no proposals",
  noDocument: "There are no documents",
  noActivity: "There are no activities",
  noNote: "There are no notes",
  noReminder: "There are no reminders",
  convertToCustomer: "Convert to Customer",
  firstName: "First Name",
  lastName: "Last Name",
  convert: "Convert",
  customerType: "Customer Type",
  customerCreatedSuccessfully: "Lead converted successfully",
  noProposalSent: "There are no proposals sent",
  tasks: "Tasks",
  noTask: "There are no tasks",
};

export default locale;
 