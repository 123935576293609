const locale = {
  contracts: "Contracts",
  contract: "Contract",
  contractConversions: "Contract Conversions",
  title: "Contracts",
  addContract: "Add Contract",
  emptyStateDesc1: "No contracts added yet.",
  emptyStateDesc2: "Store and manage all contract details here to streamline your invoicing and maintain clear agreements with your customers."
};

export default locale;



