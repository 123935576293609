const locale = {
  customers: "Customers",
  headerTitle: "Müşteriler",
  createCustomer: "Müşteri Oluştur",
  columnId: "#",
  columnName: "Ad",
  columnIcon: "Simge",
  columnDepartment: "Departman",
  columnParentCategory: "Üst Kategori",
  columnActions: "Aksiyon",
  emptyStateContent1: "Müşteriler bulunamadı",
  emptyStateContent2: "Lütfen yeni bir müşteri oluşturun",
  selectIconTitle: "Bir Simge Seç",
  searchIcons: "Simgeleri Ara",
  relatedCustomers: "İlgili Müşteriler",
  b2bB2c: "B2B / B2C",
  createCustomerTitle: "Müşteri Oluştur",
  customerNameLabel: "Müşteri Adı",
  customerEmailLabel: "Müşteri E-posta",
  customerPhoneLabel: "Müşteri Telefonu",
  customerTypeLabel: "Müşteri Türü",
  customerPictureLabel: "Müşteri Resmi",
  departmentLabel: "Departman",
  categoryLabel: "Kategori",
  customerLanguageLabel: "Müşteri Dili",
  countryLabel: "Ülke",
  stateLabel: "İl",
  cityLabel: "Şehir",
  uploadPictureText: "Resim yükle",
  createCustomerButton: "Müşteri Oluştur",
  createSuccess: "Müşteri başarıyla oluşturuldu",
  delete: "Sil",
  deleteConfirm: "Bu müşteriyi silmek istediğinizden emin misiniz? Bu işlem geri alınamaz.",
  deletedSuccessfully: "Müşteri başarıyla silindi",
  cancel: "İptal",
  id: "#",
  name: "Ad",
  email: "E-posta",
  type: "Tür",
  phone: "Telefon",
  language: "Dili",
  category: "Kategori",
  about: "Hakkinda",
  website: "Website",
  country: "Ülke",
  city: "İl",
  actions: "Aksiyon",
  searchForAnIcon: "Bir simge ara",
};

export default locale;
