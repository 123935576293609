import { IMenuItem } from "interfaces/menu-item.interface";
import { useTranslation } from "react-i18next"; // useTranslation hook'unu import ediyoruz
import {
  baseExpensesRoute,
} from "../routes/expenses.base.route";

export const useExpensesDomainMenuItem = (): IMenuItem => {
  const { t } = useTranslation(); // useTranslation hook'unu kullanıyoruz

  return {
    title: t("expenses"), // getTranslatedText yerine t fonksiyonunu kullanıyoruz
    path: baseExpensesRoute(),
    children: [
      // Todo
      // {
      //   title: t("users"),
      //   path: listUsersRoute(),
      // },
    ],
  };
};
