
import demoLocale from "../subdomains/demo/locales/tr";

const locale = {

  // Todo
  // users: {
  //   ...usersLocale,
  // },
  demo: {
    ...demoLocale,
  },
};

export default locale;

