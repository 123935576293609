const locale = {
  categories: "Product Categories",
  title: "Lead Statüleri",
  create: "Statü Oluştur",
  delete: "Statü Sil",
  deleteConfirm: "Statüyü silmek istediğinize emin misiniz: ",
  deletedSuccessfully: "Statü başarıyla silindi!",
  createStatus: "Statü Ekle",
  categoryCreateSuccess: "Statü başarıyla eklendi!",
  name: "Statü Adı",
  color: "Statü Rengi",
  icon: "Statü Simgesi",
  showMore: "Daha fazla",
  createCategory: "Kategori Ekle",
  update: "Güncelle",
  updateCategory: "Kategori Güncelle",
  categoryUpdateSuccess: "Kategori başarıyla güncellendi!",
  en: "İngilizce",
  tr: "Türkçe",
  createSuccess: "Kategori başarıyla oluşturuldu",
  updateSuccess: "Kategori başarıyla güncellendi",
  actions: "Aksiyon",
  createdAt: "Oluşturulma Tarihi",
  emptyStateContent1: "Kategori bulunamadı.",
  emptyStateContent2: "Lütfen yeni bir kategori oluşturun.",
  createProductCategory: "Kategori Oluştur"
};

export default locale;
