import { Add } from "@mui/icons-material";
import { Card, Grid, Stack, Typography } from "@mui/material";
import { HeaderBase } from "components/contentHeaders/crm/HeaderBase";
import { useTranslation } from "react-i18next";
import EmptyState from "components/emptyState/EmptyState";
import { LineChartPro, Gauge, gaugeClasses, BarChart } from "@mui/x-charts-pro";


const List = () => {

  const { t } = useTranslation();

  return (
    <Grid item xs={12} height={"calc(100vh - 260px)"}>
      <HeaderBase
        title={t(`crm.contracts.contracts.title`)}
        rightButton={{
          icon: <Add sx={{ mr: 1, opacity: 0.7 }} fontSize="small" />,
          visibility: true,
          title: t(`crm.contracts.contracts.addContract`),
          onClick: () => null,
          disabled: true
        }}
      />

      <Grid container spacing={2} my={1}>
        <Grid item xs={12} md={4}>
          <Card
            sx={{
              bgcolor: "background.secondWithBlur", display: "flex", p: "5px", flexDirection: "column",
              justifyContent: "space-between", alignItems: "center"
            }}
          >
            <Stack
              direction="row"
              gap={1}
              alignItems="center"
              justifyContent="space-between"
              width={"85%"}
              mb={-5}
            >
              <Typography fontSize={"18px"} fontWeight={"bold"}>
                {t(`crm.contracts.contracts.contracts`)}
              </Typography>
              <Typography fontSize={"44px"} fontWeight={"bold"}>
                25
              </Typography>
            </Stack>
            <LineChartPro
              xAxis={[{ data: [1, 2, 3, 5, 8, 10] }]}
              series={[
                {
                  data: [2, 5.5, 2, 8.5, 1.5, 5],
                  color: "#F43F5E",
                },
              ]}
              height={170}
              sx={{ mb: -5 }}
            />
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card
            sx={{ bgcolor: "background.secondWithBlur", display: "flex", p: "5px", flexDirection: "column", justifyContent: "space-between", alignItems: "center" }}
          >
            <Stack
              direction="row"
              gap={1}
              alignItems="center"
              justifyContent="space-between"
              width={"85%"}
              mb={-2}
            >
              <Typography fontSize={"18px"} fontWeight={"bold"}>
                {t(`crm.contracts.contracts.contractConversions`)}
              </Typography>
              <Typography fontSize={"44px"} fontWeight={"bold"}>
                35
              </Typography>
            </Stack>
            <Gauge
              width={180}
              height={147}
              value={35}
              cornerRadius="50%"
              sx={(theme) => ({
                [`& .${gaugeClasses.valueText}`]: {
                  fontSize: 40,
                },
                [`& .${gaugeClasses.valueArc}`]: {
                  fill: theme.palette.secondary.main,
                },
                [`& .${gaugeClasses.referenceArc}`]: {
                  fill: theme.palette.text.disabled,
                },
              })}
            />
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card
            sx={{ bgcolor: "background.secondWithBlur", display: "flex", p: "5px", flexDirection: "column", justifyContent: "space-between", alignItems: "center" }}
          >
            <Stack
              direction="row"
              gap={1}
              alignItems="center"
              justifyContent="space-between"
              width={"85%"}
              mb={-2}
            >
              <Typography fontSize={"18px"} fontWeight={"bold"}>
                B2B / B2C
              </Typography>
              <Typography fontSize={"44px"} fontWeight={"bold"}>
                &nbsp;
              </Typography>
            </Stack>
            <BarChart
              xAxis={[
                {
                  id: "barCategories",
                  data: ["BTB", "BTC"],
                  scaleType: "band",
                },
              ]}
              series={[
                {
                  data: [2, 5],
                  color: "#F43F5E",
                },
              ]}
              height={150}
              sx={{ mb: -5 }}
            />
          </Card>
        </Grid>
      </Grid>


      <Grid item xs={12} sm={12}>
        <Stack sx={{ height: "400px" }}>
          <EmptyState
            content1={t(`crm.contracts.contracts.emptyStateDesc1`)}
            content2={t(`crm.contracts.contracts.emptyStateDesc2`)}
          />
        </Stack>
      </Grid>
    </Grid>
  );
};

export default List;
