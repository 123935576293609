import { Grid } from "@mui/material";
import { Outlet } from "react-router-dom";

export const ProjectsDomainLayout = () => {
 

  return (
    <Grid container spacing={3}>
      <Outlet />
    </Grid>
  );
};
