/* eslint-disable react-hooks/exhaustive-deps */
import { Autocomplete, Grid, InputLabel, TextField } from "@mui/material";
import { useState, useEffect, memo } from "react";
import { useForm, Controller } from "react-hook-form";
import { getCurrentLanguage } from "localization";
import { IGraphqlVariables, unknownError } from "corede-common";
import { IPermissionListItemResult, IRoleListItemResult, IUserCreateInput, UserType } from "corede-common-cocrm";
import { validateCreateUserInput } from "../validations/create.validation";
import { RequestTransformerHelper } from "validations/request.transformer.helper";
import { useUserCreateMutation, useUserPermissionUpdateMutation } from "../context/users.api";
import { useRoleCreateMutation, useRoleListQuery } from "../../permissions";
import { enqueueSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useDepartmentListQuery } from "../../departments";
import { yupResolver } from "@hookform/resolvers/yup";
import ActionDialog from "components/dialog/ActionDialog";
import { useUserPermissionListOwnQuery } from "apps/account/domains/profile/subdomains/profile";

interface IUserCreateDialog {
    open: boolean;
    setOpen: (open: boolean) => void
    setOpenRoles: (open: boolean) => void
    setOpenDepartments: (open: boolean) => void
}

const UserCreateDialog = memo((props: IUserCreateDialog) => {
    // general
    const { t } = useTranslation();
    const currentLanguage = getCurrentLanguage();
    const [loading, setLoading] = useState(false);

    // queries
    const { data: roleListData, isLoading: roleListLoading, error: roleListError } = useRoleListQuery({});
    const { data: departmentListData, isLoading: departmentListLoading, error: departmentListError } = useDepartmentListQuery({});
    const { data: userPermissionListOwnData } = useUserPermissionListOwnQuery({});

    const roles = roleListData?.data?.filter((role: IRoleListItemResult) => role.name !== "AllOwn");
    const allOwnRoleId = roleListData?.data?.filter((role: IRoleListItemResult) => role.name === "AllOwn")?.[0]?._id;
    const ownPermissionId = userPermissionListOwnData?.filter((permission: IPermissionListItemResult) => permission.actionScope !== "own")?.[0]?._id;

    // mutations
    const [userCreate, { data: userCreateData, isLoading: userCreateLoading, error: userCreateError }] = useUserCreateMutation();
    const [roleCreateForOwn] = useRoleCreateMutation();

    // form setup
    const {
        control,
        handleSubmit,
        reset,
        setValue,
        watch,
        formState: { errors, touchedFields, isValid },
    } = useForm<IGraphqlVariables<IUserCreateInput>>({
        defaultValues: {
            input: {
                type: UserType.org,
                email: "",
                name: "",
                surname: "",
                roleIds: undefined,
                departmentId: "",
            },
        },
        resolver: yupResolver(validateCreateUserInput),
        mode: "onChange",
    });


    const onSubmit = async (values: IGraphqlVariables<IUserCreateInput>) => {
        setLoading(true);
        const transformedValues = RequestTransformerHelper.TransformAllEmptyStringFieldsToUndefined({
            input: {
                input: {
                    ...values.input,
                    roleIds: (values as any)?.input.roleIds ? [...(values as any)?.input.roleIds, allOwnRoleId] : [ownPermissionId],
                }
            }
        });
        await userCreate(transformedValues as IGraphqlVariables<IUserCreateInput>);
        setLoading(false);
    };


    // useEffects.success

    useEffect(() => {
        if (userCreateData) {
            enqueueSnackbar(t("crm.organizationalChart.users.createUserSuccess"), { variant: 'success' });
            reset();
            props.setOpen(false);
        }
    }, [userCreateData, reset]);

    // useEffects.error

    useEffect(() => {
        if (userCreateError) {
            enqueueSnackbar((userCreateError as any)?.error?.message[currentLanguage] ?? unknownError.message[currentLanguage], { variant: 'error' });
        }
    }, [userCreateError]);

    useEffect(() => {
        if (roleListError) {
            enqueueSnackbar((roleListError as any)?.error?.message[currentLanguage] ?? unknownError.message[currentLanguage], { variant: 'error' });
        }
    }, [roleListError]);

    useEffect(() => {
        if (departmentListError) {
            enqueueSnackbar((departmentListError as any)?.error?.message[currentLanguage] ?? unknownError.message[currentLanguage], { variant: 'error' });
        }
    }, [departmentListError]);

    useEffect(() => {
        if (!(roleListData?.data?.filter((role) => role.name === "AllOwn"))) {
            console.log(roleListData?.data?.filter((role) => role.name === "AllOwn"), 'zzz')
            roleCreateForOwn({
                input: {
                    name: "AllOwn",
                    permissionIds: [(ownPermissionId ?? "")]
                }
            })
        }
    }, [roleListData]);



    return <ActionDialog
        open={props.open}
        setOpen={props.setOpen}
        size="small"
        title={t("crm.organizationalChart.users.addUser")}
        handleClick={handleSubmit(onSubmit)}
        disabled={loading || !isValid || userCreateLoading}
        loading={(loading || userCreateLoading)}
        buttonTitle={t("crm.organizationalChart.users.addUser")}
        width={500}
    >
        <Grid container spacing={2} my={2}>
            <Grid item xs={12}>
                <InputLabel htmlFor="input.name" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>
                    {t("crm.organizationalChart.users.name")}*
                </InputLabel>
                <Controller
                    name="input.name"
                    control={control}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            fullWidth
                            size="small"
                            onChange={(e) => field.onChange(e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                            error={!!errors?.input?.name}
                            helperText={touchedFields?.input?.name && errors?.input?.name?.message}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} >
                <InputLabel htmlFor="input.surname" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.organizationalChart.users.surname")}*</InputLabel>
                <Controller
                    name="input.surname"
                    control={control}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            fullWidth
                            size="small"
                            onChange={(e) => field.onChange(e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                            error={!!errors?.input?.surname}
                            helperText={touchedFields?.input?.surname && errors?.input?.surname?.message}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} >
                <InputLabel htmlFor="input.email" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.organizationalChart.users.email")}*</InputLabel>
                <Controller
                    name="input.email"
                    control={control}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            fullWidth
                            type="email"
                            size="small"
                            error={!!errors?.input?.email}
                            helperText={touchedFields?.input?.email && errors?.input?.email?.message}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12}  >
                <InputLabel htmlFor="roleIds" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.organizationalChart.users.roles")}</InputLabel>
                <Controller
                    name="input.roleIds"
                    control={control}
                    render={({ field }) => (
                        <Autocomplete
                            {...field}
                            id="roleIds"
                            isOptionEqualToValue={(option, value) => option._id === value?._id}
                            fullWidth
                            size="small"
                            getOptionLabel={(option) => option.name}
                            options={[...(roles || []), { _id: "add", name: t("crm.organizationalChart.users.addRole") }]}
                            renderOption={(props, option) => (
                                <li {...props} className={`${props.className} ${option._id === "add" ? 'highlight' : ''}`}>
                                    {option.name}
                                </li>
                            )}
                            loading={roleListLoading}
                            renderInput={(params) => <TextField {...params} />}
                            multiple
                            value={
                                field.value && field.value.length > 0
                                    ? roles?.filter((role) => field?.value?.includes(role._id))
                                    : []
                            }
                            onChange={(e, value) => {
                                const addRoleOption = value?.find(v => v._id === "add");

                                if (addRoleOption) {
                                    props.setOpenRoles(true);
                                } else {
                                    field.onChange(value?.map((role) => role._id) || []);
                                }
                            }}
                        />
                    )}
                />

            </Grid>
            <Grid item xs={12} >
                <InputLabel htmlFor="departmentId" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.organizationalChart.users.department")}</InputLabel>
                <Controller
                    name="input.departmentId"
                    control={control}
                    render={({ field }) => (
                        <Autocomplete
                            {...field}
                            id="departmentId"
                            isOptionEqualToValue={(option, value) => option._id === value?._id}
                            fullWidth
                            size="small"
                            getOptionLabel={(option) => option.name}
                            options={[
                                ...(departmentListData?.data || []),
                                { _id: "", name: "" },
                                { _id: "add", name: t("crm.organizationalChart.users.create") }
                            ]}
                            renderOption={(props, option) => (
                                <li {...props} className={`${props.className} ${option._id === "add" ? 'highlight' : ''}`}>
                                    {option.name}
                                </li>
                            )}
                            loading={departmentListLoading}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    error={!!errors?.input?.departmentId}
                                    helperText={errors?.input?.departmentId?.message}
                                />
                            )}
                            value={
                                field.value ? {
                                    _id: field.value,
                                    name: departmentListData?.data?.find((department) => department._id === field.value)?.name || ""
                                } : { _id: "", name: "" }
                            }
                            onChange={(e, value) => {
                                const addCreateOption = value?._id === "add";

                                if (addCreateOption) {
                                    props.setOpenDepartments(true);
                                } else {
                                    field.onChange(value?._id || "");
                                }
                            }}
                        />
                    )}
                />

            </Grid>
        </Grid>
    </ActionDialog >
})

export default memo(UserCreateDialog)