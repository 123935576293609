const locale = {
  pricing: "Fiyatlandırma",
  saveUpTo: "Tasarruf edin",
  monthly: "aylık",
  annually: "yıllık",
  organization: "Kuruluş",
  month: "Ay",
  billedMonthly: "Aylık Fatura",
  billedAnnually: "Yıllık Fatura",
  users: "Kullanıcı",
  startTrialForFree: "Ücretsiz Deneme Başlat",
  buy: "SATIN AL",
  contactUs: "İLETİŞİME GEÇ",
  starterPlan: "Starter Plan",
  enterprisePlan: "Enterprise Plan",
  mostPopular: "En Popüler",
  buyPackage: "Paket Satın Al",
  buyPackageDescription: "Bu paket olan {{name}}, {{storageLimit}} GB depolama sınırı ile {{userLimit}} kullanıcı sınırı içerir.",
  dontHaveBillingInfo: "Kullanmak için fatura bilginiz yok.",
  dontHaveCard: "Kullanmak için herhangi bir kartınız yok.",
  addBillingInfo: "Fatura Bilgisi Ekle",
  addCard: "Kart Ekle",
  manageBillingInfo: "Fatura Bilgisini Yönet",
  manageCards: "Kartları Yönet",
  summary: "Özet",
  iAcceptThe: "Kabul ediyorum",
  preliminaryInformation: "Ön Bilgilendirme",
  distanceSalesAgreement: "Mesafeli Satış Sözleşmesi",
  close: "Kapat",
  cancel: "İptal",
  subscribeSuccess1: "Aboneliğiniz başarıyla başlatıldı.",
  subscribeSuccess2: "Aramıza hoş geldiniz.",
  continue: "Devam Et",
  letUsCall: "Sizi Arayalım",
  billingInfo: "Fatura Bilgisi",
  selectedCard: "Seçilen Kart",
  currentPlan: "Mevcut Plan",
  renewAt: "Yenileme Tarihi",
  active: "Aktif",
  usageLimits: "Kullanım Sınırları",
  storageUsage: "Depolama kullanımı",
  activeCard: "Aktif Kart",
  trialPackage: "Deneme Paketi",
  trialPackageDescription: "Bu paket, {{storageLimit}} GB depolama sınırı ve {{userLimit}} kullanıcı limiti ile 7 günlük bir kullanım süresi sunar.",
  confirmTrial: "Deneme sürecini başlatmak istediğinizden emin misiniz?",
  beginTrial: "Başlat",
  contactFormSuccess: "Talebiniz başarıyla gönderildi. E-posta adresinize cevap vereceğiz.",

  beginTrialSuccess: "Deneme başarıyla başlatıldı.",
  purchaseSuccess: "Satın alma başarıyla tamamlandı.",
  title: "Fiyatlandırma",
  unknownError: "Bilinmeyen bir hata oluştu.",
  contactMessage: "Telefon: {{phone}}, Kuruluş Adı: {{orgName}}",
  detailTitle: "Detay",
  trialPackageTitle: "Deneme Paketi",
  buyPackageTitle: "Paket Satın Al",
  contactUsTitle: "Bize Ulaşın",
  preliminaryInfoTitle: "Ön Bilgilendirme",
  distanceSalesAgreementTitle: "Mesafeli Satış Sözleşmesi",
  welcomeTrial: "Deneme paketi başarıyla başlatıldı. Aramıza hoş geldiniz.",
  packageDescription: "{{packageName}} adlı bu paket, {{storageLimit}} GB depolama sınırı ve {{userLimit}} kullanıcı limiti ile 7 günlük bir kullanım süresi sunar.",
  noBillingInfo: "Kullanılacak faturalandırma bilginiz yok.",
  billingInfoLabel: "Faturalandırma Bilgisi*",
  noCardInfo: "Kullanılacak kart bilginiz yok.",
  selectedCardLabel: "Seçilen Kart*",
  acceptPreliminaryInfo: "Ön Bilgilendirme'yi kabul ediyorum",
  acceptDistanceSalesAgreement: "Mesafeli Satış Sözleşmesi'ni kabul ediyorum",
  preliminaryInfoDescription: "Bu paket, anlaşmada belirtilen depolama sınırı ve kullanıcı izinleri ile 7 günlük bir kullanım süresi sunar.",
  subscriptionSuccess: "Aboneliğiniz başarıyla başlatıldı. Aramıza hoş geldiniz.",
  selectedPeriodLabel: "/{{selectedPeriod}}",
  distanceSalesAgreementDescription: "Bu paket, mesafeli satış sözleşmesinde belirtilen depolama sınırı ve kullanıcı izinleri ile 7 günlük bir kullanım süresi sunar.",
  goCRM: "CRM'e Git",
};

export default locale;
