/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, InputLabel, Stack, TextField, Theme, Typography, useMediaQuery } from "@mui/material";
import { FilledButton, Iconify } from "components";
import Lottie from 'react-lottie-player';
import * as windowAnimation from 'assets/animations/window.json';
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ActionDialog from "components/dialog/ActionDialog";
import { useCreateContactFormMutation, useUserDetailOwnQuery } from "apps/auth/context";
import { ContactFormSource, ContactFormType, ICreateContactFormInput } from "corede-common-cocrm";
import { IGraphqlVariables, Language, unknownError } from "corede-common";
import { Controller, useForm } from "react-hook-form";
import { getCurrentLanguage } from "localization";
import { enqueueSnackbar } from "notistack";

const List = () => {

  const { t } = useTranslation();
  const [openForm, setOpenForm] = useState(false);
  const downLg = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));
  const currentLanguage = getCurrentLanguage();
  // const [message, setMessage] = useState("");
  const { data: userDetailOwnData } = useUserDetailOwnQuery({});
  const [createContactForm, { data: createContactFormData, isLoading: createContactFormLoading, error: createContactFormError }] = useCreateContactFormMutation();


  const initialValues = {
    input: {
      fullName: userDetailOwnData?.name || "",
      email: userDetailOwnData?.email || "",
      subject: "Window Form",
      message: "",
      type: ContactFormType.request,
      source: ContactFormSource.window,
      language: Language[currentLanguage],
      phone: userDetailOwnData?.phoneNumber,
      orgName: userDetailOwnData?.organization?.name,
    }
  }

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<IGraphqlVariables<ICreateContactFormInput & { phone?: string, orgName?: string }>>({
    defaultValues: initialValues,
    mode: "onSubmit",
  });

  const onSubmit = (values: IGraphqlVariables<ICreateContactFormInput & { phone?: string, orgName?: string }>) => {
    createContactForm({
      input: {
        fullName: values.input?.fullName,
        email: values.input?.email || "",
        subject: values.input?.subject || "",
        language: values.input?.language || Language[currentLanguage],
        source: values.input?.source || ContactFormSource.window,
        type: values.input?.type || ContactFormType.request,
        message: "Phone: " + values.input?.phone + ", OrgName: " + values.input?.orgName
      }
    });
  };



  // const handleInputChange = (infoKey: string, value: string) => {
  //   setMessage((prevMessage) => {
  //     const existingMessages = prevMessage ? prevMessage.split(", ") : [];

  //     const filteredMessages = existingMessages.filter((msg) => !msg.startsWith(`${infoKey}:`));

  //     const updatedMessages = [...filteredMessages, `${infoKey}: ${value}`];

  //     return updatedMessages.join(", ");
  //   });
  // };

  // useEffect(() => {
  //   if (message) {
  //     setValue("input.message", message);
  //   }
  // }, [message]);

  useEffect(() => {
    if (createContactFormData) {
      setOpenForm(false);
      enqueueSnackbar(t("window.demo.demo.sendSuccess"), { variant: 'success' });
    }
  }, [createContactFormData]);

  useEffect(() => {
    if (createContactFormError) {
      enqueueSnackbar((createContactFormError as any)?.error?.message[currentLanguage] ?? unknownError.message[currentLanguage], { variant: 'error' });
    }
  }, [createContactFormError]);


  return (
    <Grid container spacing={2} p={4}>
      <Grid item xs={12} xl={10}>

        <Typography variant="h4">{t("window.demo.demo.title")}</Typography>

        <Stack direction={{ xs: "column-reverse", lg: "row" }} gap={2} my={2}>

          <Stack direction={"column"} gap={1} alignItems={"flex-start"}>
            <Box height={"10px"} />
            <Typography fontSize={"18px"}>{t("window.demo.demo.description")}</Typography>
            <Box height={"10px"} />
            <Typography fontSize={"18px"}>{t("window.demo.demo.featuresTitle")}</Typography>
            <Box height={"10px"} />
            <Typography fontSize={"18px"}>• {t("window.demo.demo.features1")}</Typography>
            <Typography fontSize={"18px"}>• {t("window.demo.demo.features2")}</Typography>
            <Typography fontSize={"18px"}>• {t("window.demo.demo.features3")}</Typography>
            <Typography fontSize={"18px"}>• {t("window.demo.demo.features4")}</Typography>
            <Box height={"30px"} />
            <Box
              sx={{
                animation: 'bounce 2s ease-in-out infinite',
                '@keyframes bounce': {
                  '0%, 100%': {
                    transform: 'translateY(0)',
                  },
                  '50%': {
                    transform: 'translateY(-5px)',
                  },
                },
              }}>
              <FilledButton
                title={t("window.demo.demo.buttonTitle")}
                leftIcon={<Iconify icon={"gravity-ui:persons-lock"} sx={{ mr: 1 }} />}
                onClick={() => setOpenForm(true)}
              />
            </Box>
          </Stack>

          <Box>
            <Lottie
              loop
              play
              animationData={windowAnimation}
              style={{
                height: "auto",
                width: downLg ? 200 : 400,
              }}
              rendererSettings={{
                preserveAspectRatio: 'xMidYMid slice'
              }}
            />
          </Box>
        </Stack>

      </Grid>

      <ActionDialog
        open={openForm}
        onClose={() => setOpenForm(false)}
        title={t("window.demo.demo.formTitle")}
        buttonTitle={t("window.demo.demo.formButtonTitle")}
        buttonColor="primary"
        handleClick={() => handleSubmit(onSubmit)()}
        loading={createContactFormLoading}
        disabled={createContactFormLoading}
      >
        <Typography pt={3} variant="body1">{t("window.demo.demo.formDescription")}</Typography>
        <Grid container spacing={2} sx={{ mt: 3 }}>
          <Grid item xs={6}>
            <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("window.demo.demo.fullName")}</InputLabel>
            <Controller
              name="input.fullName"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  size="small"
                  fullWidth
                  id="fullName"
                  error={!!errors.input?.fullName}
                  helperText={errors.input?.fullName?.message}
                />
              )}
            />
          </Grid>

          <Grid item xs={6}>
            <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("window.demo.demo.orgName")}</InputLabel>
            <Controller
              name="input.orgName"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  size="small"
                  fullWidth
                  id="orgName"
                  error={!!errors.input?.orgName}
                  helperText={errors.input?.orgName?.message}
                />
              )}
            />
          </Grid>

          <Grid item xs={6}>
            <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("window.demo.demo.email")}</InputLabel>
            <Controller
              name="input.email"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  size="small"
                  fullWidth
                  id="email"
                  error={!!errors.input?.email}
                  helperText={errors.input?.email?.message}
                />
              )}
            />
          </Grid>

          <Grid item xs={6}>
            <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("window.demo.demo.phone")}</InputLabel>
            <Controller
              name="input.phone"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  size="small"
                  fullWidth
                  id="phone"
                  error={!!errors.input?.phone}
                  helperText={errors.input?.phone?.message}
                />
              )}
            />
          </Grid>

          {/* {["info1", "info2", "info3", "info4", "info5", "info6"].map((infoKey, index) => (
          <Grid item xs={6} key={infoKey}>
            <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>Info {index + 1}*</InputLabel>
            <TextField
              size="small"
              fullWidth
              name={infoKey}
              id={infoKey}
              onChange={(e) => handleInputChange(infoKey, e.target.value)}
            />
          </Grid>
        ))} */}

        </Grid>
      </ActionDialog >
    </Grid >
  );
};

export default List;
