import { Route, Routes } from "react-router-dom";

import { List} from "../pages";

import { ContractsLayout } from "../layouts/ContractsLayout";
import { commonRoutes } from "routes/route.common";

export const ContractsRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<ContractsLayout />}>
        <Route index element={<List />} />
       </Route>
    </Routes>
  );
};
