/* eslint-disable react-hooks/exhaustive-deps */
import {
  Stack,
  Typography,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Box,
  Chip,
  IconButton,
  SvgIcon,
  Button,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Select,
  Pagination,
} from "@mui/material";
import {
  DataGridPro,
  GridCellModes,
  GridCellModesModel,
  GridCellParams,
  GridColDef,
  GridFilterPanel,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import {
  Add,
  FileDownload,
  Publish,
  ArticleOutlined,
  DeleteOutlineRounded,
  EditNoteRounded,
  VisibilityOutlined,
} from "@mui/icons-material";
import React, { memo, useCallback, useRef, useState } from "react";
import {
  CustomerType,
  ICustomerCategoryListResult,
  IRoleListResult,
  LeadBusinessType,
  LeadQualification,
  LeadType,
} from "corede-common-cocrm";
import * as Icons from "@mui/icons-material";
import axios from "axios";
import moment from "moment";
import { enqueueSnackbar } from "notistack";
import { BASE_URL } from "utils";
import { getAccessToken } from "utils/getUserInfo";
import { Language } from "corede-common";
import { convertEnums, getCurrentLanguage } from "localization";
import { trTR } from "@mui/x-data-grid/locales";
import { useTranslation } from "react-i18next";
import { GridFilterPanelProps } from "@mui/x-data-grid/components/panel/filterPanel/GridFilterPanel";
import {
  IBaseLeadEntity,
  ILeadSourcesResult,
  ILeadStatusesResult,
} from "corede-common-cocrm";
import countries from "datas/countries.json";
import Turkey from "datas/turkey.json";

export interface IListView {
  // lead
  leadStatusesData?: ILeadStatusesResult | undefined;
  leadSourcesData?: ILeadSourcesResult | undefined;
  selectedLead?: IBaseLeadEntity | undefined;
  leadStatusesLoading?: boolean;
  leadSourcesLoading?: boolean;
  setOpenLeadCreateDrawer?: (value: boolean) => void | undefined;
  leadStatusCreateOpen?: boolean;
  setLeadStatusCreateOpen?: (value: boolean) => void;
  leadSourceCreateOpen?: boolean;
  setLeadSourceCreateOpen?: (value: boolean) => void;
  setLeadListLoading?: (value: boolean) => void;

  // departments
  roleListData?: IRoleListResult | undefined;
  rolesPopupId?: any;
  openRolesPopup?: any;
  setOpenRolesPopup?: (value: boolean) => void;
  anchorElRolesPopup?: any;
  onCloseRolesPopup?: any;
  handleClickRolesPopup?: any; // (event: React.MouseEvent<HTMLButtonElement>) => void
  setAnchorElRolesPopup?: any;

  //customer
  customerCategoryListData?: ICustomerCategoryListResult | undefined;

  // TODO 1 - ilgili bölüm için ek actionları ekle

  // common
  isEmptyState?: boolean;
  setSelectedRow?: (value: any) => void;
  setOpenDelete?: (value: boolean) => void;
  setOpenUpdate?: (value: boolean) => void;
  setOpenDetail?: (value: boolean) => void;
}

interface IMyDataGrid {
  path: string;
  props: IListView;
  columnVisibilityModel?: any;
  columns: object[];
  rows: any;
  loading: boolean;
  filter: any;
  setFilter: (value: any) => void;
  businessType?: LeadBusinessType;
  setBusinessType?: (value: LeadBusinessType) => void;
  isJunk?: boolean;
  setIsJunk?: (value: boolean) => void;
  count: number;
  rowUpdate: any;
  features: string[];
}

/**
 * Kesinlikle baska specific data olmayacak
 */
export const MyDataGrid = memo(
  ({
    path,
    props,
    columnVisibilityModel,
    columns,
    rows,
    loading,
    filter,
    setFilter,
    businessType,
    setBusinessType,
    isJunk,
    setIsJunk,
    count,
    rowUpdate,
    features,
  }: IMyDataGrid) => {
    const { t } = useTranslation();
    const currentLanguage = getCurrentLanguage();
    const [cellModesModel, setCellModesModel] = useState<GridCellModesModel>(
      {}
    );
    const inputFileRef = useRef<HTMLInputElement | null>(null);
    const [filterModel, setFilterModel] = useState({ items: [] });

    // TODO 2 - localized enums varsa ekle
    const localizedLeadTypes = convertEnums(LeadType);
    const localizedLeadQualifications = convertEnums(LeadQualification);
    const localizedLanguages = convertEnums(Language);
    const localizedCustomerTypes = convertEnums(CustomerType);

    /**
     * Lead.status: (in lead listing)
     * - header
     * -- display name
     * -- add action
     * -- sort
     * - row
     * -- enum
     * -- editable
     * -- data: name, icon, color
     * 
     * User.status: (in user listing)
     * - header
     * -- display name
     * -- sort
     * - row
     * -- enum
     * -- data: name
     * 
     
     * addEnumColumnWithIcon()
     * - header
     * -- display name
     * -- add action
     * -- sort
     * - row
     * -- enum
     * -- editable
     * -- data: name, icon, color 
     * 
     * addEnumColumn()
     * - header
     * -- display name
     * -- sort
     * - row
     * -- enum
     * -- data: name
     */

    // TODO 3 - columnList -> ilgili sütunla ilgili özel olarak neler varsa onları gir.

    const columnsList: GridColDef<any>[] = columns.map((column: any) => {
      let valueOptions = undefined;
      let cell = undefined;
      let editCell = undefined;
      let header = undefined;

      switch (column.field) {
        case "status":
          switch (path) {
            case "leads":
              valueOptions = props.leadStatusesData?.data.map(
                (status: any) => ({
                  label: status.name,
                  value: status._id,
                })
              );
              cell = (params: any) => {
                return (
                  <Box
                    sx={{
                      border: "1px solid",
                      borderColor: props.leadStatusesData?.data.find(
                        (status: any) => status._id === params?.value?._id
                      )?.color,
                      borderRadius: "30px",
                      py: 0.5,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      px: 1,
                    }}
                  >
                    <SvgIcon
                      component={
                        (Icons as any)[
                          props.leadStatusesData?.data.find(
                            (status: any) => status._id === params?.value?._id
                          )?.icon ?? ""
                        ]
                      }
                      fontSize="small"
                      sx={{
                        mr: 0.5,
                        color: props.leadStatusesData?.data.find(
                          (status: any) => status._id === params?.value?._id
                        )?.color,
                      }}
                    />
                    <Typography
                      fontSize={"12px"}
                      textAlign={"center"}
                      fontWeight={"bold"}
                      textTransform={"capitalize"}
                      color={params?.value?.color}
                    >
                      {params?.value?.name}
                    </Typography>
                  </Box>
                );
              };
              editCell = (params: any) => {
                const currentValue =
                  params.value?._id || params.row.status?._id || "";
                return (
                  path === "leads" && (
                    <Select
                      name="status"
                      value={currentValue}
                      label={t("crm.lead.lead.status")}
                      onChange={(e) => {
                        params.api.setEditCellValue({
                          ...params,
                          value: { ...params.value, _id: e.target.value },
                        });
                      }}
                      sx={{ width: "100%" }}
                    >
                      {props.leadStatusesData?.data.map((status: any) => (
                        <MenuItem key={status._id} value={status._id}>
                          <Stack direction="row" alignItems={"center"}>
                            <SvgIcon
                              component={(Icons as any)[status.icon]}
                              sx={{
                                fontSize: "13px",
                                mr: 0.5,
                                color: status.color,
                              }}
                            />
                            <Typography
                              fontSize={"13px"}
                              fontWeight={"bold"}
                              color={status.color}
                            >
                              {" "}
                              {status.name}{" "}
                            </Typography>
                          </Stack>
                        </MenuItem>
                      ))}
                    </Select>
                  )
                );
              };
              header = (params: any) => (
                <Stack direction="row" alignItems={"center"}>
                  <span style={{ fontWeight: 500 }}>
                    {params.colDef.headerName}
                  </span>
                  <IconButton
                    size="small"
                    onClick={(e: any) => {
                      e.stopPropagation();
                      props.setLeadStatusCreateOpen &&
                        props.setLeadStatusCreateOpen(true);
                    }}
                  >
                    <Add fontSize="small" />
                  </IconButton>
                </Stack>
              );
              break;
            case "users":
              cell = (params: any) => (
                <Chip
                  label={params.value}
                  color={getStatusChipColor(params.value)}
                  variant="outlined"
                  sx={{
                    fontWeight: "bold",
                    textTransform: "capitalize",
                  }}
                />
              );
              break;
            default:
              break;
          }
          break;
        case "source":
          valueOptions = props.leadSourcesData?.data.map((source: any) => ({
            label: source.name,
            value: source._id,
          }));
          cell = (params: any) => {
            return (
              <Box
                sx={{
                  border: "1px solid",
                  borderColor: props.leadSourcesData?.data.find(
                    (source: any) => source.name === params.value?.name
                  )?.color,
                  borderRadius: "30px",
                  py: 0.5,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  px: 1,
                }}
              >
                <SvgIcon
                  component={
                    (Icons as any)[
                      (props.leadSourcesData as any)?.data.find(
                        (source: any) => source.name === params.value?.name
                      )?.icon
                    ]
                  }
                  fontSize="small"
                  sx={{
                    mr: 0.5,
                    color: props.leadSourcesData?.data.find(
                      (source: any) => source.name === params.value?.name
                    )?.color,
                  }}
                />
                <Typography
                  fontSize={"12px"}
                  textAlign={"center"}
                  fontWeight={"bold"}
                  textTransform={"capitalize"}
                  color={
                    props.leadSourcesData?.data.find(
                      (source: any) => source.name === params.value?.name
                    )?.color
                  }
                >
                  {
                    props.leadSourcesData?.data.find(
                      (source: any) => source.name === params.value?.name
                    )?.name
                  }
                </Typography>
              </Box>
            );
          };
          editCell = (params: any) => {
            const currentValue =
              params.value?._id || params.row.source?._id || "";
            return (
              <Select
                value={currentValue}
                label={t("crm.lead.lead.source")}
                onChange={(e) => {
                  params.api.setEditCellValue({
                    ...params,
                    value: { ...params.value, _id: e.target.value },
                  });
                }}
                sx={{ width: "100%" }}
              >
                {props.leadSourcesData?.data.map((source: any) => (
                  <MenuItem key={source._id} value={source._id}>
                    <Typography fontSize={"13px"} fontWeight={"bold"}>
                      {source.name}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            );
          };
          header = (params: any) => (
            <Stack direction="row" alignItems={"center"}>
              <span style={{ fontWeight: 500 }}>
                {params.colDef.headerName}
              </span>
              <IconButton
                size="small"
                onClick={(e: any) => {
                  e.stopPropagation();
                  props.setLeadSourceCreateOpen &&
                    props.setLeadSourceCreateOpen(true);
                }}
              >
                <Add fontSize="small" />
              </IconButton>
            </Stack>
          );
          break;
        case "type":
          switch (path) {
            case "leads":
              valueOptions = Object.values(LeadType).map((type) => ({
                value: type,
                label: localizedLeadTypes[type as keyof typeof LeadType],
              }));
              cell = (params: any) => (
                <Chip
                  label={
                    localizedLeadTypes[params.value as keyof typeof LeadType]
                  }
                  color={getStatusChipColor(params.value)}
                  variant="outlined"
                  sx={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    textTransform: "capitalize",
                  }}
                />
              );
              editCell = (params: any) => {
                const currentValue = params.value || params.row.type || "";
                return (
                  <Select
                    value={currentValue}
                    label={t("crm.lead.lead.type")}
                    onChange={(e) => {
                      params.api.setEditCellValue({
                        ...params,
                        value: e.target.value,
                      });
                    }}
                    sx={{ width: "100%" }}
                  >
                    {Object.values(LeadType).map((type) => (
                      <MenuItem key={type} value={type}>
                        <Typography fontSize={"13px"} fontWeight={"bold"}>
                          {localizedLeadTypes[type as keyof typeof LeadType]}
                        </Typography>
                      </MenuItem>
                    ))}
                  </Select>
                );
              };
              break;
            case "customers":
              valueOptions = Object.values(CustomerType).map((type) => ({
                value: type,
                label:
                  localizedCustomerTypes[type as keyof typeof CustomerType],
              }));
              cell = (params: any) => (
                <Chip
                  label={
                    localizedCustomerTypes[
                      params.value as keyof typeof CustomerType
                    ]
                  }
                  color={getStatusChipColor(params.value)}
                  variant="outlined"
                  sx={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    textTransform: "capitalize",
                  }}
                />
              );
              editCell = (params: any) => {
                const currentValue = params.value || params.row.type || "";
                return (
                  <Select
                    value={currentValue}
                    label={t("crm.customer.customers.type")}
                    onChange={(e) => {
                      params.api.setEditCellValue({
                        ...params,
                        value: e.target.value,
                      });
                    }}
                    sx={{ width: "100%" }}
                  >
                    {Object.values(CustomerType).map((type) => (
                      <MenuItem key={type} value={type}>
                        <Typography fontSize={"13px"} fontWeight={"bold"}>
                          {
                            localizedCustomerTypes[
                              type as keyof typeof CustomerType
                            ]
                          }
                        </Typography>
                      </MenuItem>
                    ))}
                  </Select>
                );
              };
              break;
          }

          break;
        case "qualification":
          valueOptions = Object.values(LeadQualification).map((type) => ({
            value: type,
            label:
              localizedLeadQualifications[
                type as keyof typeof LeadQualification
              ],
          }));
          cell = (params: any) => (
            <Chip
              label={
                localizedLeadQualifications[
                  params.value as keyof typeof LeadQualification
                ]
              }
              color={getStatusChipColor(params.value)}
              variant="outlined"
              sx={{
                fontSize: "12px",
                fontWeight: "bold",
                textTransform: "capitalize",
              }}
            />
          );
          editCell = (params: any) => {
            const currentValue = params.value || params.row.qualification || "";
            return (
              <Select
                value={currentValue}
                label={t("crm.lead.lead.qualification")}
                onChange={(e) => {
                  params.api.setEditCellValue({
                    ...params,
                    value: e.target.value,
                  });
                }}
                sx={{ width: "100%" }}
              >
                {Object.values(LeadQualification).map((type) => (
                  <MenuItem key={type} value={type}>
                    <Typography fontSize={"13px"} fontWeight={"bold"}>
                      {
                        localizedLeadQualifications[
                          type as keyof typeof LeadQualification
                        ]
                      }
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            );
          };
          break;
        case "language":
          valueOptions = Object.values(Language).map((type) => ({
            label: localizedLanguages[type as keyof typeof Language],
          }));
          cell = (params: any) => (
            <Typography fontSize={"12px"}>
              {localizedLanguages[params.value as keyof typeof Language]}
            </Typography>
          );
          editCell = (params: any) => {
            const currentValue = params.value || params.row.language || "";
            return (
              <Select
                value={currentValue}
                label={t("crm.customer.customers.language")}
                onChange={(e) => {
                  params.api.setEditCellValue({
                    ...params,
                    value: e.target.value,
                  });
                }}
                sx={{ width: "100%" }}
              >
                {Object.values(Language).map((type) => (
                  <MenuItem key={type} value={type}>
                    <Typography fontSize={"13px"} fontWeight={"bold"}>
                      {localizedLanguages[type as keyof typeof Language]}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            );
          };
          break;
        case "roles":
          cell = (params: any) => {
            return params.row?.roles?.map((role: any) => (
              <Chip
                key={role._id}
                label={
                  props.roleListData?.data?.find(
                    (item: any) => item._id === role._id
                  )?.name
                }
                variant="outlined"
                size="small"
                sx={{ mr: 1, fontSize: "11px", fontWeight: "bold" }}
                color="default"
              />
            ));
          };
          editCell = () => null;
          // NOTE: not working, handle cell click is workin
          // return (
          //   <Button
          //     aria-describedby={props.rolesPopupId || ""}
          //     variant="contained"
          //     onClick={props.handleClickRolesPopup}
          //     sx={{ display: "none" }}
          //   >
          //     {t("crm.organizationalChart.departments.rolesButton")}
          //   </Button>
          // );
          break;
        case "icon":
          cell = (params: any) => {
            return (
              <SvgIcon
                component={(Icons as any)[params.value]}
                fontSize="small"
                sx={{ mt: 2, ml: 0.5 }}
              />
            );
          };
          editCell = () => null;
          break;
        case "color":
          cell = (params: any) => {
            return (
              <Box
                sx={{
                  mt: 2,
                  ml: 0.5,
                  width: 20,
                  height: 20,
                  borderRadius: "50%",
                  backgroundColor: params.value ?? "background.primary",
                }}
              />
            );
          };
          editCell = () => null;
          break;
        case "category":
          valueOptions = props.customerCategoryListData?.data.map(
            (category: any) => ({
              label: category.name,
              value: category._id,
            })
          );
          cell = (params: any) => {
            return (
              <Box
                sx={{
                  border: "1px solid",
                  borderRadius: "30px",
                  py: 0.5,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  px: 1,
                }}
              >
                <SvgIcon
                  component={(Icons as any)[params.value?.icon]}
                  fontSize="small"
                  sx={{ mt: 2, ml: 0.5 }}
                />
                <Typography
                  fontSize={"12px"}
                  textAlign={"center"}
                  fontWeight={"bold"}
                  textTransform={"capitalize"}
                >
                  {params.value?.name}
                </Typography>
              </Box>
            );
          };
          editCell = (params: any) => {
            const currentValue =
              params.value?._id || params.row.category?._id || "";
            return (
              <Select
                value={currentValue}
                label={t("crm.customer.customers.category")}
                onChange={(e) => {
                  params.api.setEditCellValue({
                    ...params,
                    value: { ...params.value, _id: e.target.value },
                  });
                }}
                sx={{ width: "100%" }}
              >
                {props.customerCategoryListData?.data.map((category: any) => (
                  <MenuItem key={category._id} value={category._id}>
                    <Typography fontSize={"13px"} fontWeight={"bold"}>
                      {category.name}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            );
          };
          break;
        case "country":
          valueOptions = countries.map((country: any) => ({
            label: country.name,
            value: country.name,
          }));
          cell = (params: any) => (
            <Typography fontSize={"12px"}>{params.value}</Typography>
          );
          editCell = (params: any) => {
            const currentValue = params.value || params.row.country || "";
            return (
              <Select
                value={currentValue}
                label={t("crm.customer.customers.country")}
                onChange={(e) => {
                  params.api.setEditCellValue({
                    ...params,
                    value: e.target.value,
                  });
                }}
                sx={{ width: "100%" }}
              >
                {countries.map((country: any) => (
                  <MenuItem key={country.name} value={country.name}>
                    <Typography fontSize={"13px"} fontWeight={"bold"}>
                      {country.name}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            );
          };
          break;
        case "state":
          valueOptions = countries.map((state: any) => ({
            label: state.name,
            value: state.name,
          }));
          cell = (params: any) => (
            <Typography fontSize={"12px"}>{params.value}</Typography>
          );
          editCell = (params: any) => {
            const currentValue = params.value || params.row.state || "";
            return (
              <Select
                value={currentValue}
                label={t("crm.customer.customers.state")}
                onChange={(e) => {
                  params.api.setEditCellValue({
                    ...params,
                    value: e.target.value,
                  });
                }}
                sx={{ width: "100%" }}
              >
                {Turkey?.states?.map((state: any) => (
                  <MenuItem key={state.name} value={state.name}>
                    <Typography fontSize={"13px"} fontWeight={"bold"}>
                      {state.name}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            );
          };
          break;
        case "actions":
          cell = (params: any) => (
            <Stack
              direction={{ xs: "column", md: "row" }}
              alignItems={"center"}
              justifyContent={"center"}
              mt={{ xs: 0, md: 1 }}
            >
              {column.changed && column.changed?.includes("view") && (
                <Tooltip title={t("crm.lead.lead.view")}>
                  <IconButton
                    onClick={() => {
                      props.setSelectedRow && props.setSelectedRow(params.row);
                      props.setOpenDetail && props.setOpenDetail(true);
                    }}
                    color="primary"
                    sx={{ p: "4px" }}
                  >
                    <VisibilityOutlined color="primary" fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
              {column.changed && column.changed?.includes("edit") && (
                <Tooltip title={t("crm.lead.lead.edit")}>
                  <IconButton
                    onClick={() => {
                      props.setSelectedRow && props.setSelectedRow(params.row);
                      props.setOpenUpdate && props.setOpenUpdate(true);
                    }}
                    color="info"
                    sx={{ p: "4px" }}
                  >
                    <EditNoteRounded color="info" fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
              {column.changed &&
                column.changed?.includes("junk") &&
                (isJunk ? (
                  <Tooltip title={t("crm.lead.lead.junk")}>
                    <IconButton
                      onClick={() => {
                        rowUpdate({
                          filter: { _id: params.row?._id },
                          input: { isJunk: true },
                        });
                      }}
                      color="error"
                      sx={{ p: "4px" }}
                    >
                      <DeleteOutlineRounded color="warning" fontSize="small" />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip title={t("crm.lead.lead.unJunk")}>
                    <IconButton
                      onClick={() => {
                        rowUpdate({
                          filter: { _id: params.row?._id },
                          input: { isJunk: false },
                        });
                      }}
                      color="error"
                      sx={{ p: "4px" }}
                    >
                      <Icons.AssignmentReturnOutlined
                        color="warning"
                        fontSize="small"
                      />
                    </IconButton>
                  </Tooltip>
                ))}
              {column.changed && column.changed?.includes("delete") && (
                <Tooltip title={t("crm.lead.lead.delete")}>
                  <IconButton
                    onClick={() => {
                      props?.setSelectedRow && props.setSelectedRow(params.row);
                      props.setOpenDelete && props.setOpenDelete(true);
                    }}
                    color="error"
                    sx={{ p: "4px" }}
                  >
                    <Icons.DeleteForeverOutlined
                      color="error"
                      fontSize="small"
                    />
                  </IconButton>
                </Tooltip>
              )}
            </Stack>
          );
          break;

        default:
          break;
      }

      return {
        field: column.field ?? "",
        headerName: column.headerName ?? "",
        disableColumnMenu: column.disableColumnMenu ?? false,
        width: column.width ?? 120,
        filterable: column.filterable ?? true,
        align: column.align ?? "center",
        headerAlign: column.headerAlign ?? "center",
        editable: column.editable ?? true,
        type: column.type ?? "string",
        ...(valueOptions
          ? {
              valueOptions: valueOptions,
            }
          : {}),
        ...(cell
          ? {
              renderCell: cell,
            }
          : {}),
        ...(editCell
          ? {
              renderEditCell: editCell,
            }
          : {}),
        ...(header
          ? {
              renderHeader: header,
            }
          : {}),
      };
    });

    // TODO 4 - handleCellClick için ekstra -> cell clicklerde ekstra olay ekle.
    const handleCellClick = useCallback(
      (params: GridCellParams, event: React.MouseEvent) => {
        if (params.field === "actions") {
          return;
        }
        if (params.field === "roles") {
          props.setAnchorElRolesPopup(event.currentTarget as HTMLButtonElement);
          props.setSelectedRow && props.setSelectedRow(params.row);
        }
        if (params.field === "icon") {
          props.setSelectedRow && props.setSelectedRow(params.row);
          props.setOpenUpdate && props.setOpenUpdate(true);
        }
        if (params.field === "color") {
          props.setSelectedRow && props.setSelectedRow(params.row);
          props.setOpenUpdate && props.setOpenUpdate(true);
        }
        if (params.field === "status" && path === "users") {
          return;
        }

        setCellModesModel((prevModel) => {
          return {
            ...Object.keys(prevModel).reduce(
              (acc, id) => ({
                ...acc,
                [id]: Object.keys(prevModel[id]).reduce(
                  (acc2, field) => ({
                    ...acc2,
                    [field]: { mode: GridCellModes.View },
                  }),
                  {}
                ),
              }),
              {}
            ),
            [params.id]: {
              ...Object.keys(prevModel[params.id] || {}).reduce(
                (acc, field) => ({
                  ...acc,
                  [field]: { mode: GridCellModes.View },
                }),
                {}
              ),
              [params.field]: { mode: GridCellModes.Edit },
            },
          };
        });
      },
      []
    );

    // TODO 5 - handleProcessRowUpdate -> tekli güncellemeler için row ekle.

    function handleProcessRowUpdate(newRow: any, oldRow: any) {
      if (newRow.fullName !== oldRow.fullName && newRow.fullName) {
        rowUpdate({
          filter: { _id: newRow._id },
          input: { fullName: newRow.fullName },
        });
      }
      if (newRow.position !== oldRow.position && newRow.position) {
        rowUpdate({
          filter: { _id: newRow._id },
          input: { position: newRow.position },
        });
      }
      if (newRow.email !== oldRow.email && newRow.email) {
        rowUpdate({
          filter: { _id: newRow._id },
          input: { email: newRow.email },
        });
      }
      if (newRow.website !== oldRow.website && newRow.website) {
        rowUpdate({
          filter: { _id: newRow._id },
          input: { website: newRow.website },
        });
      }
      if (newRow.phoneNumber !== oldRow.phoneNumber && newRow.phoneNumber) {
        rowUpdate({
          filter: { _id: newRow._id },
          input: { phoneNumber: newRow.phoneNumber },
        });
      }
      if (newRow.phone !== oldRow.phone && newRow.phone) {
        rowUpdate({
          filter: { _id: newRow._id },
          input: { phone: newRow.phone },
        });
      }
      if (newRow.company !== oldRow.company && newRow.company) {
        rowUpdate({
          filter: { _id: newRow._id },
          input: { company: newRow.company },
        });
      }
      if (newRow.sector !== oldRow.sector && newRow.sector) {
        rowUpdate({
          filter: { _id: newRow._id },
          input: { sector: newRow.sector },
        });
      }
      if (newRow.type !== oldRow.type && newRow.type) {
        rowUpdate({
          filter: { _id: newRow._id },
          input: { type: newRow.type },
        });
      }
      if (
        newRow.qualification !== oldRow.qualification &&
        newRow.qualification
      ) {
        rowUpdate({
          filter: { _id: newRow._id },
          input: { qualification: newRow.qualification },
        });
      }
      if (newRow.country !== oldRow.country && newRow.country) {
        rowUpdate({
          filter: { _id: newRow._id },
          input: { country: newRow.country },
        });
      }
      if (newRow.state !== oldRow.state && newRow.state) {
        rowUpdate({
          filter: { _id: newRow._id },
          input: { state: newRow.state },
        });
      }
      if (newRow.city !== oldRow.city && newRow.city) {
        rowUpdate({
          filter: { _id: newRow._id },
          input: { city: newRow.city },
        });
      }
      if (newRow.language !== oldRow.language && newRow.language) {
        rowUpdate({
          filter: { _id: newRow._id },
          input: { language: newRow.language },
        });
      }
      if (newRow.status !== oldRow.status && newRow.status) {
        rowUpdate({
          filter: { _id: newRow._id },
          input: { statusId: newRow.status?._id },
        });
      }
      if (newRow.source !== oldRow.source && newRow.source) {
        rowUpdate({
          filter: { _id: newRow._id },
          input: { sourceId: newRow.source?._id },
        });
      }
      if (newRow.name !== oldRow.name && newRow.name) {
        rowUpdate({
          filter: { _id: newRow._id },
          input: { name: newRow.name },
        });
      }
      if (
        newRow.category !== oldRow.name &&
        newRow.category &&
        newRow.category !== null
      ) {
        rowUpdate({
          filter: { _id: newRow._id },
          input: { categoryId: newRow.category?._id },
        });
      }
      if (newRow.firstName !== oldRow.firstName && newRow.firstName) {
        rowUpdate({
          filter: { _id: newRow._id },
          input: { firstName: newRow.firstName },
        });
      }
      if (newRow.lastName !== oldRow.lastName && newRow.lastName) {
        rowUpdate({
          filter: { _id: newRow._id },
          input: { lastName: newRow.lastName },
        });
      }
      if (newRow.surname !== oldRow.surname && newRow.surname) {
        rowUpdate({
          filter: { _id: newRow._id },
          input: { surname: newRow.surname },
        });
      }

      return newRow;
    }

    // Common
    const handleCellModesModelChange = useCallback((newModel: any) => {
      setCellModesModel(newModel);
    }, []);

    const CustomPagination = (props: any) => {
      return (
        <Stack
          direction={"row"}
          width={"100%"}
          px={2}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Box width={"100px"} />
          <PaginationComponent {...props} />
          <Stack
            direction={"row"}
            width={"100px"}
            gap={1}
            justifyContent={"flex-end"}
            alignItems={"center"}
          >
            Show
            <Select
              name="pageSize"
              value={filter?.input?.pagination?.pageSize ?? 10}
              size="small"
              onChange={(e) => {
                setFilter &&
                  setFilter((prevFilter: any) => {
                    return {
                      ...prevFilter,
                      input: {
                        ...prevFilter?.input,
                        pagination: {
                          page: 1,
                          pageSize: Number(e.target.value),
                        },
                      },
                    };
                  });
              }}
            >
              <MenuItem sx={{ fontSize: "12px" }} value={1}>
                1
              </MenuItem>
              <MenuItem sx={{ fontSize: "12px" }} value={5}>
                5
              </MenuItem>
              <MenuItem sx={{ fontSize: "12px" }} value={10}>
                10
              </MenuItem>
              <MenuItem sx={{ fontSize: "12px" }} value={20}>
                50
              </MenuItem>
            </Select>
          </Stack>
        </Stack>
      );
    };

    const PaginationComponent = () => {
      return (
        <Pagination
          count={Math.ceil(
            (count ?? 0) / (filter?.input?.pagination?.pageSize ?? 10)
          )}
          page={filter.input?.pagination?.page}
          size="small"
          sx={{
            "& .MuiPaginationItem-root": {
              fontSize: "12px",
              fontWeight: "bold",
              p: 0,
            },
          }}
          onChange={(e, value) => {
            setFilter &&
              setFilter((prevFilter: any) => {
                return {
                  ...prevFilter,
                  input: {
                    ...prevFilter.input,
                    pagination: {
                      ...prevFilter.input?.pagination,
                      page: value,
                    },
                  },
                };
              });
          }}
          color="primary"
          shape="rounded"
        />
      );
    };

    // Only Lead

    const CustomToolbar = () => {
      const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
      const open = Boolean(anchorEl);
      const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
      };

      const handleClose = () => {
        setAnchorEl(null);
      };

      const handleImportClick = () => {
        if (inputFileRef.current) {
          inputFileRef.current.click();
        }
      };

      return (
        <GridToolbarContainer>
          <GridToolbarContainer>
            <GridToolbarColumnsButton />
            <GridToolbarDensitySelector />
            <Button
              aria-controls={open ? "import-export-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              startIcon={<Icons.ImportExport />}
            >
              {t("crm.lead.lead.importExport")}
            </Button>
            <Menu
              id="import-export-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "import-export-button",
              }}
            >
              <MenuItem onClick={() => handleDownloadForm()}>
                <ListItemIcon>
                  <FileDownload fontSize="small" />
                </ListItemIcon>
                <ListItemText>{t("crm.lead.lead.exportData")}</ListItemText>
              </MenuItem>
              <MenuItem onClick={() => handleImportClick()}>
                <ListItemIcon>
                  <Publish fontSize="small" />
                </ListItemIcon>
                <ListItemText>{t("crm.lead.lead.importData")}</ListItemText>
              </MenuItem>
              <input
                type="file"
                hidden
                ref={inputFileRef}
                onChange={(e) => {
                  handleImportForm(e.target.files![0]);
                }}
              />

              <MenuItem onClick={() => handleDownloadExampleForm()}>
                <ListItemIcon>
                  <ArticleOutlined fontSize="small" />
                </ListItemIcon>
                <ListItemText>
                  {t("crm.lead.lead.downloadExample")}
                </ListItemText>
              </MenuItem>
            </Menu>
            <GridToolbarFilterButton />
            <Box display="flex" alignItems="center">
              <ToggleButtonGroup
                value={businessType}
                id="businessType"
                sx={{ ml: 2 }}
                size="small"
                exclusive
                color="info"
                onChange={(_, value) =>
                  setBusinessType && setBusinessType(value)
                }
              >
                <ToggleButton value={LeadBusinessType.b2b}>
                  <Typography fontWeight={"bold"} fontSize={"12px"}>
                    {t("crm.lead.lead.b2b")}
                  </Typography>
                </ToggleButton>
                <ToggleButton value={LeadBusinessType.b2c}>
                  <Typography fontWeight={"bold"} fontSize={"12px"}>
                    {t("crm.lead.lead.b2c")}
                  </Typography>
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
            <Box display="flex" alignItems="center">
              <ToggleButtonGroup
                value={isJunk}
                id="isJunk"
                sx={{ ml: 2 }}
                color="warning"
                size="small"
                exclusive
                onChange={() => setIsJunk && setIsJunk(!isJunk)}
              >
                <ToggleButton value={false}>
                  <DeleteOutlineRounded fontSize="small" sx={{ mr: 1 }} />
                  <Typography fontWeight={"bold"} fontSize={"12px"}>
                    {t("crm.lead.lead.isJunk")}
                  </Typography>
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
          </GridToolbarContainer>
        </GridToolbarContainer>
      );
    };

    // const CustomFilterPanel = (props: GridFilterPanelProps) => {
    //   return (
    //     <Box>
    //       <GridFilterPanel
    //         {...props}
    //         logicOperators={[]}
    //         disableRemoveAllButton
    //       />
    //       <Box
    //         display="flex"
    //         justifyContent="space-between"
    //         mt={2}
    //         sx={{ position: "absolute", right: 2, bottom: 4 }}
    //       >
    //         <Button
    //           onClick={() => {
    //             setFilter && setFilter({});
    //             setFilterModel({ items: [] });
    //           }}
    //           color="primary"
    //           variant="text"
    //         >
    //           <Icons.Delete fontSize="small" /> Remove all
    //         </Button>
    //       </Box>
    //     </Box>
    //   );
    // };

    function handleFilterChange(newFilterModel: any) {
      setFilterModel(newFilterModel);
      setFilter &&
        setFilter((prevFilter: any) => {
          const newFilter = newFilterModel.items.reduce(
            (acc: any, item: any) => {
              if (item.field && item.value) {
                if (item.field === "status") {
                  acc["statusIds"] = Array.isArray(acc["statusIds"])
                    ? [...acc["statusIds"], item.value]
                    : [item.value];
                } else if (item.field === "type") {
                  acc["types"] = Array.isArray(acc["types"])
                    ? [...acc["types"], item.value]
                    : [item.value];
                } else if (item.field === "qualification") {
                  acc["qualifications"] = Array.isArray(acc["qualifications"])
                    ? [...acc["qualifications"], item.value]
                    : [item.value];
                } else {
                  acc[item.field] = item.value;
                }
              }
              return acc;
            },
            {}
          );

          if (
            JSON.stringify(prevFilter?.input?.filter) ===
            JSON.stringify(newFilter)
          ) {
            return prevFilter;
          }

          return {
            input: {
              filter: {
                ...prevFilter?.input?.filter,
                ...newFilter,
              },
            },
          };
        });
    }

    function handleDownloadForm() {
      let data = JSON.stringify({
        filter: filter.input?.filter,
      });

      let config = {
        method: "post",
        url: `${BASE_URL}/leads/export`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getAccessToken()}`,
        },
        data: data,
        responseType: "blob",
      };

      axios
        .request(config as any)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `lead-forms-${moment().format("DD-MM-YYYY")}.xlsx`
          );
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          enqueueSnackbar(t("crm.lead.lead.downloadFormSuccessfully"), {
            variant: "success",
          });
        })
        .catch((error) => {
          enqueueSnackbar(t("crm.lead.lead.downloadFormError"), {
            variant: "error",
          });
        });
    }

    function handleDownloadExampleForm() {
      let data = JSON.stringify({
        filter: {},
      });

      let config = {
        method: "post",
        url: `${BASE_URL}/leads/import/example`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getAccessToken()}`,
        },
        data: data,
        responseType: "blob",
      };

      axios
        .request(config as any)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `lead-form-example-import.xlsx`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          enqueueSnackbar(t("crm.lead.lead.downloadExampleFormSuccessfully"), {
            variant: "success",
          });
        })
        .catch((error) => {
          enqueueSnackbar(t("crm.lead.lead.downloadExampleFormError"), {
            variant: "error",
          });
        });
    }

    function handleImportForm(file: File) {
      let formData = new FormData();
      formData.append("file", file);

      let config = {
        method: "post",
        url: `${BASE_URL}/leads/import`,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${getAccessToken()}`,
        },
        data: formData,
      };

      axios
        .request(config as any)
        .then((response) => {
          enqueueSnackbar(t("crm.lead.lead.importedSuccessfully"), {
            variant: "success",
          });
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        })
        .catch((error) => {
          enqueueSnackbar(t("crm.lead.lead.importedError"), {
            variant: "error",
          });
        });
    }

    return (
      <DataGridPro
        columns={columnsList}
        rows={rows}
        localeText={
          currentLanguage === Language.tr
            ? trTR.components.MuiDataGrid.defaultProps.localeText
            : undefined
        }
        cellModesModel={cellModesModel}
        onCellModesModelChange={handleCellModesModelChange}
        onCellClick={handleCellClick}
        initialState={{
          columns: { columnVisibilityModel: columnVisibilityModel },
        }}
        showCellVerticalBorder
        showColumnVerticalBorder
        checkboxSelection={false}
        disableRowSelectionOnClick
        // //disableAggregation
        // // disableRowGrouping
        loading={loading}
        scrollbarSize={1}
        scrollEndThreshold={10}
        rowsLoadingMode="server"
        filterMode="server"
        slots={{
          ...(features?.includes("toolbar")
            ? {
                toolbar: CustomToolbar,
              }
            : {}),
          // filterPanel: CustomFilterPanel,
          pagination: CustomPagination,
        }}
        pagination
        filterModel={filterModel}
        onFilterModelChange={handleFilterChange}
        filterDebounceMs={1000}
        processRowUpdate={handleProcessRowUpdate}
        pinnedColumns={{ left: ["id"], right: ["actions"] }}
        sx={[
          tableStyle,
          {
            "& .MuiDataGrid-columnHeader": {
              background: !isJunk && path === "leads" ? "#F6E8D6" : null,
            },
            "& .MuiDataGrid-filler--pinnedRight": {
              background: !isJunk && path === "leads" ? "#F6E8D6" : null,
            },
            "& .MuiDataGrid-filler--pinnedLeft": {
              background: !isJunk && path === "leads" ? "#F6E8D6" : null,
            },
            "& .MuiDataGrid-cell--pinnedRight": {
              background: !isJunk && path === "leads" ? "#F6E8D6" : null,
            },
            "& .MuiDataGrid-cell--pinnedLeft": {
              background: !isJunk && path === "leads" ? "#F6E8D6" : null,
            },
          },
        ]}
      />
    );
  }
);

export const tableStyle = {
  fontSize: "12px",
  "& .MuiDataGrid-footerContainer": {
    height: "20px !important",
    maxHeight: "20px !important",
    m: 0,
  },
  "& .MuiDataGrid-toolbarContainer > button": {
    fontSize: "12px",
  },
  "& .MuiDataGrid-columnHeader .MuiIconButton-root": {
    fontSize: "12px",
    p: "2px",
  },
  "& .MuiDataGrid-columnHeader .MuiSvgIcon-root": {
    width: "16px",
    height: "16px",
  },
  "& .MuiDataGrid-cell": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

function getStatusChipColor(status: any) {
  switch (status) {
    case "cold":
      return "info";
    case "warm":
      return "warning";
    case "hot":
      return "error";
    default:
      return "default";
  }
}
