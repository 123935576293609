import { Box, SvgIcon, Typography } from '@mui/material'
import * as Icons from "@mui/icons-material";

interface IBaseChip {
    borderColor?: string
    icon?: string
    color?: string
    label: string
}

const BaseChip = (props: IBaseChip) => {
    return <Box
        sx={{
            bgcolor: "background.secondWithBlur",
            backdropFilter: "blur(3px)",
            opacity: 0.7,
            border: "1px solid",
            borderColor: props.borderColor ? props.borderColor : props.color ? props.color : "primary.main",
            borderRadius: "10px",
            py: 0.5,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            px: 1,
        }}
    >
        {props.icon && <SvgIcon
            component={(Icons as any)[props.icon ?? ""]}
            sx={{
                fontSize: "12px",
                mr: 0.5,
                color: props.color ?? "primary.main",
            }}
        />}
        <Typography
            fontSize={"12px"}
            textAlign={"center"}
            fontWeight={"bold"}
            textTransform={"capitalize"}
            color={props.color ?? "primary.main"}
        >
            {props.label}
        </Typography>
    </Box>
}

export default BaseChip