import { createSlice } from '@reduxjs/toolkit';

const { actions, reducer } = createSlice({
    name: 'language',
    initialState: {
        language: "en",
    },
    reducers: {
        setLanguage: (state, action) => {
            state.language = action.payload;
            localStorage.setItem('language', action.payload);
        },
    },
});


export const { setLanguage } = actions;
export const languageSlice = reducer;