export enum BaseGridColType {
  enum = "enum",
  text = "text",
  // TODO: number
  // TODO: date
  object = "object",
  any = "any",
  color = "color",
  icon = "icon",
  enumArray = "enumArray",
  textArray = "textArray",
  objectArray = "objectArray",
  colorArray = "colorArray",
  iconArray = "iconArray",
}

export enum BaseDataGridFeature {
  defaultToolbar = "defaultToolbar",
  customToolbar = "customToolbar",
}

export enum GridStringOperatorName {
  equals = "equals",
  contains = "contains",
}

export enum GridNumericOperatorName {
  equals = "=",
}

export enum GridDateOperatorName {
  equals = "=",
}

export enum GridCellClickActionType {
  custom = "custom",
  popup = "popup",
  drawer = "drawer",
}

export enum GridColumnActionType {
  view = "view",
  edit = "edit",
  delete = "delete",
}
