const locale = {
  payments: "Payments",
  payment: "Payment",
  paymentConversions: "Payment Conversions",
  title: "Payments",
  addPayment: "Add Payment",
  emptyStateDesc1: "No payments recorded yet.",
  emptyStateDesc2: "Track and manage payments for your invoices here to keep your finances organized and up-to-date."
};

export default locale;
