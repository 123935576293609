import leadLocale from "../subdomains/lead/locales/tr";
import leadForms from "../subdomains/leadForms/locales/tr";
import leadStatuses from "../subdomains/leadStatuses/locales/tr";
import leadSources from "../subdomains/leadSources/locales/tr";

const locale = {

  lead: {
    ...leadLocale,
  },
  leadForms: {
    ...leadForms,
  },
  leadStatuses: {
    ...leadStatuses,
  },
  leadSources: {
    ...leadSources,
  },
};

export default locale;
