const locale = {
  departments: "Departmanlar",
  delete: "Sil",
  deleteConfirm: "Silmek istediğinize emin misiniz? Bu işlem geri alınamaz.",
  deletedSuccessfully: "Departman başarıyla silindi!",
  createDepartmentTitle: "Departman Oluştur",
  departmentNameLabel: "Departman Adı",
  rolesLabel: "Roller",
  addRole: "Rol Ekle",
  createDepartmentButton: "Departman Oluştur",
  createSuccess: "Departman başarıyla oluşturuldu",
  columnId: "#",
  columnName: "Ad",
  columnRoles: "Roller",
  columnActions: "Aksiyon",
  headerTitle: "Departmanlar",
  createDepartment: "Departman Oluştur",
  rolesButton: "Roller",
  emptyStateContent1: "Departman bulunamadı",
  emptyStateContent2: "Lütfen yeni bir departman oluşturun",
  addNewRole: "Yeni Rol Ekle",
  updateDepartmentTitle: "Departmanı Güncelle",
  updateDepartmentButton: "Güncelle",
  updateSuccess: "Departman başarıyla güncellendi",
};

export default locale;
