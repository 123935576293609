const locale = {
  productCategories: "Categories",
  headerTitle: "Categories",
  create: "Create Category",
  delete: "Delete Category",
  deleteConfirm: "Are you sure you want to delete this status: ",
  deletedSuccessfully: "Category deleted successfully",
  createCategory: "Create Category",
  categoryCreateSuccess: "Category created successfully",
  name: "Category Name",
  icon: "Category Icon",
  showMore: "Show More",
  update: "Update",
  updateCategory: "Update Category",
  categoryUpdateSuccess: "Category updated successfully",
  en: "English",
  tr: "Turkish",
  createSuccess: "Category created successfully",
  actions: "Actions",
  createdAt: "Created At",
  emptyStateContent1: "No categories found",
  emptyStateContent2: "Please create new category",
  createProductCategory: "Create Product Category",
};

export default locale;
