import { getTranslatedText } from "localization";
import { ValidationHelper } from "validations/validation.helper";

export const validateCreateDepartmentInput =
  ValidationHelper.BuildObjectValidationSchema({
    input: ValidationHelper.BuildObjectValidationSchema({
      name: ValidationHelper.RequiredString(
        getTranslatedText("nameIsRequired")
      )
      // TODO: add field validations for create
    }),
  });