import profileLocale from "../domains/profile/locales/en";

const locale = {
  profile: {
    ...profileLocale,
  },
  profileo: "Request for Quote"
};

export default locale;
