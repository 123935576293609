/* eslint-disable react-hooks/exhaustive-deps */
import { Autocomplete, Chip, Grid, InputLabel, TextField } from "@mui/material";
import { useState, useEffect, memo } from "react";
import { useForm, Controller } from "react-hook-form";
import { convertEnums, getCurrentLanguage } from "localization";
import { IGraphqlVariables, unknownError } from "corede-common";
import { IReminderCreateInput, ReminderPriority, ReminderTargetType } from "corede-common-cocrm";
import { validateCreateRemindersInput } from "../validations/create.validation";
import { RequestTransformerHelper } from "validations/request.transformer.helper";
import { enqueueSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import ActionDialog from "components/dialog/ActionDialog";
import { useLazyLeadListQuery, useLeadListQuery } from "apps/crm/domains/03-lead/subdomains/lead";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import moment from "moment";
import { useUserListQuery } from "apps/crm/domains/02-organizationalChart/subdomains/users";
import { useUserDetailOwnQuery } from "apps/auth/context";
import { useReminderCreateMutation } from "../context/reminders.api";


export enum TargetType {
    lead = "Lead",
    customer = "customer",
}

interface IReminderCreateDialog {
    open: boolean;
    setOpen: (open: boolean) => void
    target?: { id: string; name: string }
    targetType: ReminderTargetType
}

const ReminderCreateDialog = memo((props: IReminderCreateDialog) => {
    // general
    const { t } = useTranslation();
    const currentLanguage = getCurrentLanguage();
    const [loading, setLoading] = useState(false);
    const localizedReminderPriorities = convertEnums(ReminderPriority);
    const localizedTargetTypes = convertEnums(TargetType);
    const [inputValueTag, setInputValueTag] = useState('');
    const [targetList, setTargetList] = useState<{ id: string; name: string }[]>([]);
    // queries
    const { data: userListData, isLoading: userListLoading } = useUserListQuery({});
    const { data: userDetailOwnData } = useUserDetailOwnQuery({});
    const { data: leadListData, isLoading: leadListLoading } = useLeadListQuery({});

    // mutations
    const [reminderCreate, { data: reminderCreateData, isLoading: reminderCreateLoading, error: reminderCreateError }] = useReminderCreateMutation();

    // form setup
    const {
        control,
        handleSubmit,
        reset,
        formState: { errors, touchedFields, isValid },
    } = useForm<IGraphqlVariables<IReminderCreateInput>>({
        defaultValues: {
            input: {
                title: undefined,
                content: undefined,
                targetId: props.target?.id,
                priority: undefined,
                remindDate: undefined,
                remindUserIds: [userDetailOwnData?._id],
                targetType: props.targetType,
                tags: undefined,

            },
        },
        resolver: yupResolver(validateCreateRemindersInput),
        mode: "onChange",
    });

    const onSubmit = async (values: IGraphqlVariables<IReminderCreateInput>) => {
        setLoading(true);
        const transformedValues = RequestTransformerHelper.TransformAllEmptyStringFieldsToUndefined({
            input: values,
        });
        await reminderCreate(transformedValues as IGraphqlVariables<IReminderCreateInput>);
        setLoading(false);
    };


    // useEffects.success

    useEffect(() => {
        if (reminderCreateData) {
            enqueueSnackbar(t("crm.calendar.reminders.createReminderSuccess"), { variant: 'success' });
            reset();
            props.setOpen(false);
        }
    }, [reminderCreateData, reset]);

    // useEffects.error

    useEffect(() => {
        if (reminderCreateError) {
            enqueueSnackbar((reminderCreateError as any)?.error?.message[currentLanguage] ?? unknownError.message[currentLanguage], { variant: 'error' });
        }
    }, [reminderCreateError]);

    useEffect(() => {
        if (leadListData) {
            setTargetList(leadListData?.data.map((lead) => ({ id: lead._id, name: lead.fullName })));
        }
    }, [leadListData]);


    return <ActionDialog
        open={props.open}
        setOpen={props.setOpen}
        size="small"
        title={t("crm.calendar.reminders.createReminder")}
        handleClick={handleSubmit(onSubmit)}
        disabled={loading || !isValid || reminderCreateLoading}
        loading={(loading || reminderCreateLoading)}
        buttonTitle={t("crm.calendar.reminders.addReminder")}
        width={500}
    >
        <Grid container spacing={2} my={2}>
            {/* <Grid item xs={12} >
                <InputLabel htmlFor="targetType" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.calendar.reminders.targetType")}</InputLabel>
                <Controller
                    name="input.targetType"
                    control={control}
                    render={({ field }) => (
                        <Autocomplete
                            id="targetType"
                            size="small"
                            fullWidth
                            isOptionEqualToValue={(option, value) => option._id === value._id}
                            options={Object.values(TargetType).map((targetType) => ({
                                name: localizedTargetTypes[targetType as keyof typeof TargetType],
                                _id: targetType,
                            }))}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => <TextField {...params} />}
                            value={
                                field.value
                                    ? { name: localizedTargetTypes[field.value as keyof typeof TargetType], _id: field.value }
                                    : null
                            }
                            onChange={(e, value) => {
                                field.onChange(value?._id);
                            }}
                        />
                    )}
                />
            </Grid> */}
            <Grid item xs={12} >
                <InputLabel htmlFor="target" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.calendar.reminders.target")}</InputLabel>
                <Controller
                    name="input.targetId"
                    control={control}
                    render={({ field }) => (
                        <Autocomplete
                            id="input.target"
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            fullWidth
                            size="small"
                            disableClearable
                            getOptionLabel={(option) => option.name}
                            options={targetList}
                            renderInput={(params) => (
                                <TextField {...params} />
                            )}
                            onChange={(e, value) => {
                                field.onChange(value?.id);
                            }}
                            // value={field.value ? { name: field.value, id: field.value } : null}
                            value={field.value ? targetList.find((target) => target.id === field.value) : undefined}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12}>
                <InputLabel htmlFor="input.name" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>
                    {t("crm.calendar.reminders.title")}*
                </InputLabel>
                <Controller
                    name="input.title"
                    control={control}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            fullWidth
                            size="small"
                            onChange={(e) => field.onChange(e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                            error={!!errors?.input?.title}
                            helperText={touchedFields?.input?.title && errors?.input?.title?.message}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12}>
                <InputLabel htmlFor="input.content" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>
                    {t("crm.calendar.reminders.content")}*
                </InputLabel>
                <Controller
                    name="input.content"
                    control={control}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            multiline
                            rows={3}
                            fullWidth
                            size="small"
                            onChange={(e) => field.onChange(e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                            error={!!errors?.input?.content}
                            helperText={touchedFields?.input?.content && errors?.input?.content?.message}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} >
                <InputLabel htmlFor="priority" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.calendar.reminders.priority")}</InputLabel>
                <Controller
                    name="input.priority"
                    control={control}
                    render={({ field }) => (
                        <Autocomplete
                            id="priority"
                            size="small"
                            fullWidth
                            isOptionEqualToValue={(option, value) => option._id === value._id}
                            options={Object.values(ReminderPriority).map((priority) => ({
                                name: localizedReminderPriorities[priority as keyof typeof ReminderPriority],
                                _id: priority,
                            }))}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => <TextField {...params} />}
                            value={
                                field.value
                                    ? { name: localizedReminderPriorities[field.value as keyof typeof ReminderPriority], _id: field.value }
                                    : null
                            }
                            onChange={(e, value) => {
                                field.onChange(value?._id);
                            }}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} >
                <InputLabel htmlFor="remindDate" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.calendar.reminders.remindDate")}</InputLabel>
                <Controller
                    name="input.remindDate"
                    control={control}
                    render={({ field }) => (
                        <DateTimePicker
                            {...field}
                            value={field.value ? moment(field.value) : null}
                            onChange={(date) => field.onChange(date)}
                            minDate={moment()}
                            slotProps={{
                                textField: {
                                    size: 'small',
                                    fullWidth: true,
                                    error: !!errors.input?.remindDate,
                                    helperText: errors.input?.remindDate?.message,
                                },
                            }}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} >
                <InputLabel htmlFor="remindTime" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.calendar.reminders.remindUsers")}</InputLabel>
                <Controller
                    name="input.remindUserIds"
                    control={control}
                    render={({ field }) => (
                        <Autocomplete
                            id="input.remindUserIds"
                            size="small"
                            isOptionEqualToValue={(option, value) => option._id === value._id}
                            fullWidth
                            getOptionLabel={(option) => option.name + " " + option.surname}
                            options={userListData?.data || []}
                            loading={userListLoading}
                            renderInput={(params) => <TextField {...params} />}
                            multiple
                            value={
                                field.value && field.value?.length > 0
                                    ? userListData?.data?.filter((user) =>
                                        field.value?.includes(user._id)
                                    )
                                    : []
                            }
                            onChange={(e, value) => {
                                field.onChange(value?.map((user) => user._id) || []);
                            }}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} >
                <InputLabel htmlFor="tags" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.calendar.reminders.tags")}</InputLabel>
                <Controller
                    name="input.tags"
                    control={control}
                    render={({ field }) => (
                        <Autocomplete
                            multiple
                            freeSolo
                            size="small"
                            id="tags"
                            options={[]}
                            value={field.value}
                            isOptionEqualToValue={(option, value) => option === value}
                            inputValue={inputValueTag}
                            onInputChange={(event, newInputValue) => {
                                setInputValueTag(newInputValue);
                            }}
                            onChange={(event, newValue) => {
                                field.onChange(newValue);
                            }}
                            onKeyDown={(event) => {
                                if (event.key === 'Enter' || event.key === ',') {
                                    event.preventDefault();
                                    const inputValueTrimmed = inputValueTag.trim();
                                    if (inputValueTrimmed !== '') {
                                        // setFieldValue("input.tags", [...(values as any).input.tags, inputValueTrimmed]);
                                        field.onChange(field.value ? [...field.value, inputValueTrimmed] : [inputValueTrimmed]);
                                        setInputValueTag('');
                                    }
                                }
                            }}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => {
                                    const { key, ...tagProps } = getTagProps({ index });
                                    return (
                                        <Chip
                                            key={key}
                                            size="small"
                                            variant="outlined"
                                            label={option}
                                            {...tagProps}
                                        />
                                    );
                                })
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    placeholder={t("crm.calendar.reminders.tagsPlaceholder")}
                                    error={!!errors.input?.tags}
                                    helperText={errors.input?.tags?.message}
                                />
                            )}
                        />
                    )}
                />
            </Grid>
        </Grid>
    </ActionDialog >
})

export default memo(ReminderCreateDialog)