import { IBaseError, Language, unknownError } from "corede-common";
import { enqueueSnackbar } from "notistack";

export function DefaultErrorHandlerUseEffect(
  error: IBaseError,
  currentLanguage: Language
): void {
  if (error) {
    enqueueSnackbar(
      (error as any).error.message[currentLanguage] ??
        unknownError.message[currentLanguage],
      {
        variant: "error",
      }
    );
  }
}