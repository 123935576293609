import { useState, lazy, Suspense } from 'react';


const UserUpdateDrawer = lazy(() => import('./UserUpdateDialog'));


const UserUpdateWrapper = ({
    open,
    setOpen,
    setOpenRoles,
    setOpenDepartments,
    selectedUser,
    setSelectedUser
}: any) => {

    const [mounted, setMounted] = useState(false);

    if (open && !mounted) {
        setMounted(true);
    }

    return (
        <>
            {mounted && (
                <Suspense>
                    <UserUpdateDrawer
                        open={open}
                        setOpen={setOpen}
                        setOpenRoles={setOpenRoles}
                        setOpenDepartments={setOpenDepartments}
                        selectedUser={selectedUser}
                        setSelectedUser={setSelectedUser}
                    />
                </Suspense>
            )}
        </>
    );
};

export default UserUpdateWrapper;