import { IMenuItem } from "interfaces/menu-item.interface";
import { useTranslation } from "react-i18next";
import {
  baseLeadRoute,
  listFormsRoute,
  listLeadRoute,
  listStatusesRoute,
  listSourcesRoute
} from "../routes/lead.base.route";
import { PermissionSubdomain } from "corede-common-cocrm";

export const useLeadDomainMenuItem = (): IMenuItem => {
  const { t } = useTranslation();

  return {
    title: t("Lead"),
    path: baseLeadRoute(),
    children: [
      {
        title: t("Leads"),
        path: listLeadRoute(),
        subdomain:  PermissionSubdomain.lead
      },
      {
        title: t("leadForms"),
        path: listFormsRoute(),
        // subdomain: PermissionSubdomain.leadForm TODO: add subdomain
      },
      {
        title: t("leadStatuses"),
        path: listStatusesRoute(),
        subdomain: PermissionSubdomain.leadStatus
      },
      {
        title: t("leadSources"),
        path: listSourcesRoute(),
        subdomain: PermissionSubdomain.leadSource
      }
    ],
  };
};
