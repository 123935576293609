import { Route, Routes } from "react-router-dom";
import { List} from "../pages";
import { ProductsLayout } from "../layouts/ProductsLayout";

export const ProductsRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<ProductsLayout />}>
        <Route index element={<List />} />
       </Route>
    </Routes>
  );
};
