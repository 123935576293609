import { Route, Routes } from "react-router-dom";

import { List } from "../pages";

import { DemoLayout } from "../layouts/DemoLayout";

export const DemoRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<DemoLayout />}>
        <Route index element={<List />} />
        <Route path="*" element={<List />} />
      </Route>
    </Routes>
  );
};
