
export const calendarDomainRoutes = {
  base: "calendar",
  subdomains: {
    reminders: {
      base: "reminders",
    },
    appointments: {
      base: "appointments",
    }
  },
};


export const baseCalendarRoute = (): string => `/${calendarDomainRoutes.base}/`;
export const listRemindersRoute = (): string =>
  `/${calendarDomainRoutes.base}/${calendarDomainRoutes.subdomains.reminders.base}/`;
export const listAppointmentsRoute = (): string =>
  `/${calendarDomainRoutes.base}/${calendarDomainRoutes.subdomains.appointments.base}`;