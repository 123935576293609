/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import { useEffect } from "react";

import { IBaseError } from "corede-common"
import { enqueueSnackbar } from "notistack";
import { getCurrentLanguage } from "localization";
import { DefaultErrorHandlerUseEffect } from "utils/useEffect.helper";
import { IBaseProductEntity } from "corede-common-cocrm";
import { useTranslation } from "react-i18next";
import { useProductDeleteMutation } from "../../products";
import ActionDialog from "components/dialog/ActionDialog";

const Delete = (prop: {
  open: boolean;
  item: IBaseProductEntity;
  onClose: () => void;
}) => {
  // general
  const currentLanguage = getCurrentLanguage();
  const { t } = useTranslation();

  // queries

  // mutations
  const [productStatusDelete, { data: productStatusDeleteData, isLoading: productStatusDeleteLoading, error: productStatusDeleteError }] = useProductDeleteMutation();

  // constants

  // action handlers
  const handleDelete = (_id: string) => {
    productStatusDelete({ input: { _id: _id } });
  };

  // useEffects.success
  useEffect(() => {
    if (productStatusDeleteData) {
      prop?.onClose();
      enqueueSnackbar(t("crm.service.services.deletedSuccessfully"), {
        variant: "success",
      });
    }
  }, [productStatusDeleteData]);

  // useEffects.error
  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      productStatusDeleteError as IBaseError,
      currentLanguage
    );
  }, [productStatusDeleteError, currentLanguage]);

  // useEffects.init

  return (
    <ActionDialog
      open={prop?.open}
      onClose={prop?.onClose}
      title={t("crm.service.services.delete")}
      handleClick={prop?.onClose}
      buttonTitle={t("delete")}
      buttonColor="error"
      disabled={productStatusDeleteLoading}
      loading={productStatusDeleteLoading}
    >
      {t("crm.service.services.deleteConfirm")} {prop?.item?.name}?
    </ActionDialog >
  );
};

export default Delete;
