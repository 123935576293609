
export const pricingDomainRoutes = {
  base: "pricing",
  subdomains: {
    pricing: {
      base: "pricing",
    },
  },
};


export const basePricingRoute = (): string => `/${pricingDomainRoutes.base}/`;
export const listPricingRoute = (): string =>
  `/${pricingDomainRoutes.base}/`;

