import { Box, Card, Grid, Skeleton, Stack, Typography } from "@mui/material";
import { memo, useEffect, useState } from "react";
import { Add, FileUpload } from "@mui/icons-material";
import { useLeadListCountQuery, useLeadListQuery, useLeadStatisticsQuery } from "../context/lead.api";
import { useLeadStatusesQuery } from "../../leadStatuses/context/leadStatuses.api";
import { useLeadSourcesQuery } from "../../leadSources/context/leadSources.api";
import { enqueueSnackbar } from "notistack";
import { KanbanView } from "./KanbanView";
import { HeaderBase } from "components/contentHeaders/crm/HeaderBase";
import { useTranslation } from "react-i18next";
import ListView from "./ListView";
import { importLeadRoute } from "../../../routes/lead.base.route";
import { useNavigate } from "react-router-dom";
import { BarChart, Gauge, gaugeClasses, LineChartPro } from "@mui/x-charts-pro";
import LeadCreateDrawerWrapper from "./LeadCreateWrapper";
import LeadUpdateDrawerWrapper from "./LeadUpdateWrapper";
import LeadDetailWrapper from "./LeadDetailWrapper";
import { DeleteDialog } from "./DeleteDialog";
import { LeadStatusCreateDialog } from "../../leadStatuses/pages";
import { LeadSourcesCreateDialog } from "../../leadSources/pages";
import { ILeadDetailResult } from "corede-common-cocrm";

const List = memo(() => {
  // general
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openUserDrawer, setOpenUserDrawer] = useState(false);
  const [openLeadCreateDrawer, setOpenLeadCreateDrawer] = useState(false);
  const [openLeadUpdateDrawer, setOpenLeadUpdateDrawer] = useState(false);
  // const [selectedLead, setSelectedLead] = useState<ILeadDetailResult>();
  const [selectedLead, setSelectedLead] = useState<any>();
  const [leadListType, setLeadListType] = useState("list");
  const [leadStatusCreateOpen, setLeadStatusCreateOpen] = useState(false);
  const [leadSourceCreateOpen, setLeadSourceCreateOpen] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [leadListLoading, setLeadListLoading] = useState(false);

  // queries
  const {
    data: leadStatusesData,
    isLoading: leadStatusesLoading,
    error: leadStatusesError,
  } = useLeadStatusesQuery({});
  const {
    data: leadSourcesData,
    isLoading: leadSourcesLoading,
    error: leadSourcesError,
  } = useLeadSourcesQuery({});
  // const { data: leadListData } = useLeadListQuery({});
  const { data: leadListCountData } = useLeadListCountQuery({});
  const { data: leadStatisticsData, isLoading: leadStatisticsLoading } = useLeadStatisticsQuery({
    input: {
      filter: {
        numberOfPastDays: 7
      }
    }
  });







  let isEmpty = (leadListCountData?.count ?? 0) === 0;

  // success useEffects

  //error useEffects

  useEffect(() => {
    if (leadStatusesError) {
      enqueueSnackbar("Error", { variant: "error" });
    }
  }, [leadStatusesError]);

  useEffect(() => {
    if (leadSourcesError) {
      enqueueSnackbar("Error", { variant: "error" });
    }
  }, [leadSourcesError]);


  return (
    <Grid item xs={12} height={"calc(100vh - 260px)"}>
      <HeaderBase
        title={t(`crm.lead.lead.title`)}
        // toggleButton={
        //   (leadListData as any)?.data?.length > 0
        //     ? {
        //       value: leadListType,
        //       action: (value: any) => setLeadListType(value),
        //     }
        //     : undefined
        // }
        rightButton={{
          visibility: !isEmpty,
          icon: <Add sx={{ mr: 1, opacity: 0.7 }} fontSize="small" />,
          title: t(`crm.lead.lead.create`),
          onClick: () => setOpenLeadCreateDrawer(true),
        }}
        rightButton2={{
          visibility: !isEmpty,
          icon: (
            <FileUpload sx={{ mr: 1, opacity: 0.7 }} fontSize="small" />
          ),
          onClick: () => navigate(importLeadRoute()),
          title: t(`crm.lead.lead.importLead`),
        }}
      />

      {leadListCountData ?
        (!isEmpty && !leadListLoading) && (
          <Grid container spacing={2} my={1}>
            <Grid item xs={12} md={4}>
              <Card
                sx={{
                  bgcolor: "background.secondWithBlur", display: "flex", p: "5px", flexDirection: "column",
                  justifyContent: "space-between", alignItems: "center"
                }}
              >
                <Stack
                  direction="row"
                  gap={1}
                  alignItems="center"
                  justifyContent="space-between"
                  width={"85%"}
                  mb={-5}
                >
                  <Typography fontSize={"18px"} fontWeight={"bold"}>
                    {t(`crm.lead.lead.leads`)}
                  </Typography>
                  <Typography fontSize={"44px"} fontWeight={"bold"}>
                    {leadStatisticsData?.count ?? 0}
                  </Typography>
                </Stack>
                <LineChartPro
                  xAxis={[{ data: [1, 2, 3, 4, 5, 6, 7] }]}
                  series={[
                    {
                      data: leadStatisticsData?.dailyHistory?.dailyTotalCounts ? [...leadStatisticsData?.dailyHistory?.dailyTotalCounts].reverse() : [],
                      color: "#F43F5E",
                    },
                  ]}
                  height={170}
                  sx={{ mb: -5 }}
                />
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card
                sx={{ bgcolor: "background.secondWithBlur", display: "flex", p: "5px", flexDirection: "column", justifyContent: "space-between", alignItems: "center" }}
              >
                <Stack
                  direction="row"
                  gap={1}
                  alignItems="center"
                  justifyContent="space-between"
                  width={"85%"}
                  mb={-2}
                >
                  <Typography fontSize={"18px"} fontWeight={"bold"}>
                    {t(`crm.lead.lead.leadConversions`)}
                  </Typography>
                  <Typography fontSize={"44px"} fontWeight={"bold"}>
                    {leadStatisticsData?.countByConvertedToCustomer ?? 0}
                  </Typography>
                </Stack>
                <Box position="relative" display="inline-flex" alignItems="center">

                  <Gauge
                    width={180}
                    height={147}
                    value={Number((((leadStatisticsData?.countByConvertedToCustomer ?? 0) * 100) / (leadStatisticsData?.count ?? 1)).toFixed(1)) ?? 0}
                    cornerRadius="50%"
                    sx={(theme) => ({
                      [`& .${gaugeClasses.valueText}`]: {
                        fontSize: 40,
                        visibility: "hidden",
                      },
                      [`& .${gaugeClasses.valueArc}`]: {
                        fill: theme.palette.secondary.main,
                      },
                      [`& .${gaugeClasses.referenceArc}`]: {
                        fill: theme.palette.text.disabled,
                      },
                    })}
                  />
                  <Box
                    sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", fontSize: 40 }}
                  >
                    {Number(
                      (
                        ((leadStatisticsData?.countByConvertedToCustomer ?? 0) * 100) /
                        (leadStatisticsData?.count ?? 1)
                      ).toFixed(1)
                    ) ?? 0}
                    %
                  </Box>
                </Box>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card
                sx={{ bgcolor: "background.secondWithBlur", display: "flex", p: "5px", flexDirection: "column", justifyContent: "space-between", alignItems: "center" }}
              >
                <Stack
                  direction="row"
                  gap={1}
                  alignItems="center"
                  justifyContent="space-between"
                  width={"85%"}
                  mb={-2}
                >
                  <Typography fontSize={"18px"} fontWeight={"bold"}>
                    B2B / B2C
                  </Typography>
                  <Typography fontSize={"44px"} fontWeight={"bold"}>
                    &nbsp;
                  </Typography>
                </Stack>
                <BarChart
                  xAxis={[
                    {
                      id: "barCategories",
                      data: ["BTB", "BTC"],
                      scaleType: "band",
                    },
                  ]}
                  series={[
                    {
                      data: [leadStatisticsData?.countByLeadBusinessType?.countByB2b ?? 0, leadStatisticsData?.countByLeadBusinessType?.countByB2c ?? 0],
                      color: "#F43F5E",
                    },
                  ]}
                  height={150}
                  sx={{ mb: -5 }}
                />
              </Card>
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={2} my={1}>
            {Array.from({ length: 3 }).map((_, index) => (
              <Grid item xs={12} md={4} key={index}>
                <Skeleton
                  variant="rectangular"
                  width={"100%"}
                  height={200}
                  sx={{ borderRadius: "16px" }}
                />
              </Grid>
            ))}
          </Grid>
        )}

      {leadListType === "list" ? (
        <ListView
          leadSourcesData={leadSourcesData}
          leadStatusesData={leadStatusesData}
          setSelectedRow={setSelectedLead}
          leadStatusesLoading={leadStatusesLoading}
          leadSourcesLoading={leadSourcesLoading}
          setOpenLeadCreateDrawer={setOpenLeadCreateDrawer}
          selectedLead={selectedLead}
          leadSourceCreateOpen={leadSourceCreateOpen}
          leadStatusCreateOpen={leadStatusCreateOpen}
          setLeadSourceCreateOpen={setLeadSourceCreateOpen}
          setLeadStatusCreateOpen={setLeadStatusCreateOpen}
          setOpenUpdate={setOpenLeadUpdateDrawer}
          setOpenDelete={setOpenDeleteDialog}
          setOpenDetail={setOpenUserDrawer}
          setLeadListLoading={setLeadListLoading}
          isEmptyState={isEmpty}
        />
      ) : (
        <KanbanView
          statuses={leadStatusesData?.data}
          // leads={leadListData?.data}
          leads={[]}
          setOpenLeadStatusesDrawer={() => null}
          setSelectedLead={setSelectedLead}
          setOpenUserDrawer={setOpenUserDrawer}
        />
      )}

      <LeadStatusCreateDialog
        open={leadStatusCreateOpen}
        setOpen={setLeadStatusCreateOpen}
      />

      <LeadSourcesCreateDialog
        open={leadSourceCreateOpen}
        setOpen={setLeadSourceCreateOpen}
      />

      <LeadCreateDrawerWrapper
        open={openLeadCreateDrawer}
        setOpen={setOpenLeadCreateDrawer}
        leadStatusCreateOpen={leadStatusCreateOpen}
        setLeadStatusCreateOpen={setLeadStatusCreateOpen}
        leadSourceCreateOpen={leadSourceCreateOpen}
        setLeadSourceCreateOpen={setLeadSourceCreateOpen}
      />

      <LeadUpdateDrawerWrapper
        open={openLeadUpdateDrawer}
        setOpen={setOpenLeadUpdateDrawer}
        selectedLead={selectedLead}
        leadStatusCreateOpen={leadStatusCreateOpen}
        setLeadStatusCreateOpen={setLeadStatusCreateOpen}
        leadSourceCreateOpen={leadSourceCreateOpen}
        setLeadSourceCreateOpen={setLeadSourceCreateOpen}
      />

      <DeleteDialog
        open={openDeleteDialog}
        item={selectedLead}
        onClose={() => setOpenDeleteDialog(false)}
        key={selectedLead?._id}
      />
    </Grid>
  );
});

export default List;
