/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, IconButton, Modal, TextField, Typography, useMediaQuery, } from "@mui/material";
import { useState } from "react";
import * as Icons from '@mui/icons-material';
import { useTranslation } from "react-i18next";
import { ICustomerContactListInput } from "corede-common-cocrm";
import { IGraphqlVariables } from "corede-common";
import { HeaderBase } from "components/contentHeaders/crm/HeaderBase";
import { Add, } from "@mui/icons-material";
import Delete from "./Delete";
import { useCustomerContactListQuery } from "../context/contacts.api";
import ContactCreateWrapper from "./ContactCreateWrapper";
import { MyDataGrid } from "components/dataGrid/MyDataGrid";
import { useCustomerUpdateMutation } from "../../customers";


const List = () => {

  // general
  const { t } = useTranslation();
  const downMd = useMediaQuery((theme: any) => theme.breakpoints.down('md'));
  const [customerContactListFilter, setCustomerContactListFilter] = useState<IGraphqlVariables<ICustomerContactListInput>>({
    input: {
      pagination: {
        page: 1,
        pageSize: 10,
        sort: {
          createdAt: 1
        }
      }
    }
  })
  const [selectedRow, setSelectedRow] = useState<any>();
  const [openContactCreateDrawer, setOpenContactCreateDrawer] = useState(false);
  const [openContactDeleteDialog, setOpenContactDeleteDialog] = useState<boolean>(false);

  const { data: customerContactListData, isLoading: customerContactListLoading, isFetching: customerContactListFetching } = useCustomerContactListQuery(customerContactListFilter);
  const [customerUpdate] = useCustomerUpdateMutation();


  return (
    <Grid item xs={12} height={"calc(100vh - 260px)"}>
      <HeaderBase
        title={t(`crm.customer.contacts.headerTitle`)}
        rightButton={{
          icon: <Add sx={{ mr: 1, opacity: 0.7 }} fontSize="small" />,
          title: t(`crm.customer.contacts.create`),
          onClick: () => setOpenContactCreateDrawer(true),
        }}
      />
      <Box height={{ xs: "500px", md: "600px" }}>
        <MyDataGrid
          path="customerContacts"
          columns={[
            {
              field: "id",
              headerName: "#",
              disableColumnMenu: true,
              filterable: false,
              align: "center",
              width: 40,
              headerAlign: "center"
            },
            {
              field: "firstName",
              headerName: t("crm.customer.contacts.firstName"),
              width: 100,
              align: "left",
              editable: true
            },
            {
              field: "lastName",
              headerName: t("crm.customer.contacts.lastName"),
              width: 100,
              align: "left",
              editable: true
            },
            {
              field: "position",
              headerName: t("crm.customer.contacts.position"),
              width: 100,
              align: "left",
              editable: true
            },
            {
              field: "email",
              headerName: t("crm.customer.contacts.email"),
              width: 120,
              align: "left",
              editable: true
            },
            {
              field: "phone",
              headerName: t("crm.customer.contacts.phone"),
              width: 100,
              align: "left",
              editable: true
            },
            {
              field: "customer",
              headerName: t("crm.customer.contacts.customer"),
              width: 100,
              align: "left",
              editable: false
            },
            {
              field: "primary",
              headerName: t("crm.customer.contacts.primary"),
              width: 100,
              align: "left",
              editable: false
            },
            {
              field: "actions",
              headerName: t("crm.customer.contacts.actions"),
              sortable: false,
              filterable: false,
              align: "center",
              editable: false,
              headerAlign: "center",
              width: downMd ? 50 : 120,
              changed: ["delete"]
            },
          ]}
          rows={customerContactListData?.data?.map((contact, index) => ({
            _id: contact._id,
            id: (index + 1) * (customerContactListFilter?.input?.pagination?.page || 0),
            firstName: contact.firstName,
            lastName: contact.lastName,
            position: contact.position,
            email: contact.email,
            phone: contact.phone,
            customer: contact.customer?.name,
            isPrimary: contact.isPrimary
          })) || []}
          columnVisibilityModel={{}}
          props={{
            setSelectedRow: setSelectedRow,
            setOpenDelete: setOpenContactDeleteDialog,
          }}
          loading={customerContactListLoading || customerContactListFetching}
          count={customerContactListData?.count || 0}
          filter={customerContactListFilter}
          setFilter={setCustomerContactListFilter}
          rowUpdate={customerUpdate}
          features={[]}
        />
      </Box>


      <ContactCreateWrapper
        open={openContactCreateDrawer}
        setOpen={setOpenContactCreateDrawer}
      />

      <Delete
        open={openContactDeleteDialog}
        onClose={() => setOpenContactDeleteDialog(false)}
        item={selectedRow}
      />

    </Grid >
  );
};

export default List;