import leadLocale from "../subdomains/lead/locales/en";
import leadForms from "../subdomains/leadForms/locales/en";
import leadStatuses from "../subdomains/leadStatuses/locales/en";
import leadSources from "../subdomains/leadSources/locales/en";

const locale = {
  lead: {
    ...leadLocale,
  },
  leadForms: {
    ...leadForms,
  },
  leadStatuses: {
    ...leadStatuses,
  },
  leadSources: {
    ...leadSources,
  },
};

export default locale;
