


export const demoDomainRoutes = {
  base: "community",
  subdomains: {
    demo: {
      base: "demo",
    },
  },
};


export const baseCommunityRoute = (): string => `/${demoDomainRoutes.base}/`;
export const listUsersRoute = (): string =>
  `/${demoDomainRoutes.base}/${demoDomainRoutes.subdomains.demo.base}`;
// Todo
// export const listUsersRoute = (): string =>
//   `/${customerDomainRoutes.base}/${customerDomainRoutes.subdomains.users.base}`;


