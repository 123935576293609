import {
  // queries
  // IPermissionListInput, IPermissionListRequest, IPermissionListResponse, IPermissionListResult, permissionListQuery,

  ILeadListInput,
  ILeadListRequest,
  ILeadListResponse,
  ILeadListResult,
  leadListQuery,
  ILeadDetailInput,
  ILeadDetailRequest,
  ILeadDetailResponse,
  ILeadDetailResult,
  leadDetailQuery,
  INoteListInput,
  INoteListRequest,
  INoteListResponse,
  INoteListResult,
  noteListQuery,
  ILeadListCountRequest,
  ILeadListCountResponse,
  leadListCountQuery,
  ILeadEstimateListInput,
  ILeadEstimateListRequest,
  ILeadEstimateListResponse,
  ILeadEstimateListResult,
  leadEstimateListQuery,
  ILeadProposalListInput,
  ILeadProposalListRequest,
  ILeadProposalListResponse,
  ILeadProposalListResult,
  leadProposalListQuery,
  IReminderListInput,
  ILeadReminderListRequest,
  ILeadReminderListResponse,
  IReminderListResult,
  leadReminderListQuery,
  IReminderDetailInput,
  ILeadReminderDetailRequest,
  ILeadReminderDetailResponse,
  IReminderDetailResult,
  leadReminderDetailQuery,
  ILeadStatisticsInput,
  ILeadStatisticsRequest,
  ILeadStatisticsResponse,
  ILeadStatisticsResult,
  leadStatisticsQuery,
  ILeadNoteListRequest,
  ILeadNoteListResponse,
  leadNoteListQuery,
  INoteDetailInput,
  ILeadNoteDetailRequest,
  ILeadNoteDetailResponse,
  INoteDetailResult,
  leadNoteDetailQuery,

  // mutations
  ILeadCreateInput,
  ILeadCreateRequest,
  ILeadCreateResponse,
  ILeadCreateResult,
  leadCreateQuery,
  ILeadUpdateInput,
  ILeadUpdateRequest,
  ILeadUpdateResponse,
  ILeadUpdateResult,
  leadUpdateQuery,
  ILeadUpdateFilterInput,
  ILeadDeleteInput,
  ILeadDeleteRequest,
  ILeadDeleteResponse,
  ILeadDeleteResult,
  leadDeleteQuery,
  ILeadContactAddInput,
  ILeadContactAddRequest,
  ILeadContactAddResponse,
  leadContactAddQuery,
  IReminderCreateInput,
  ILeadReminderCreateRequest,
  ILeadReminderCreateResponse,
  IReminderCreateResult,
  leadReminderCreateQuery,
  IReminderUpdateInput,
  ILeadReminderUpdateRequest,
  ILeadReminderUpdateResponse,
  IReminderUpdateResult,
  leadReminderUpdateQuery,
  IReminderUpdateFilterInput,
  IReminderDeleteInput,
  ILeadReminderDeleteRequest,
  ILeadReminderDeleteResponse,
  IReminderDeleteResult,
  leadReminderDeleteQuery,
  ILeadNoteCreateRequest,
  ILeadNoteCreateResponse,
  leadNoteCreateQuery,
  ILeadNoteUpdateRequest,
  ILeadNoteUpdateResponse,
  leadNoteUpdateQuery,
  ILeadNoteDeleteRequest,
  ILeadNoteDeleteResponse,
  leadNoteDeleteQuery,

  ICustomerCreateFromLeadInput,
  ICustomerCreateFromLeadResponse,
  ICustomerCreateResult,
  customerCreateFromLeadQuery,

  //TODO move to note subdomain
  INoteCreateInput,
  INoteCreateRequest,
  INoteCreateResponse,
  INoteCreateResult,
  noteCreateQuery,
  INoteUpdateInput,
  INoteUpdateRequest,
  INoteUpdateResponse,
  INoteUpdateResult,
  noteUpdateQuery,
  INoteUpdateFilterInput,
  INoteDeleteInput,
  INoteDeleteRequest,
  INoteDeleteResponse,
  INoteDeleteResult,
  noteDeleteQuery,
  ICustomerCreateFromLeadRequest,

} from "corede-common-cocrm";
import { commonApi } from "context/commonApi";
import { EndpointQueryBuilder } from "apps/helper/endpoint-query.builder";
import { leadTags } from "./lead.tags";
import {
  IBaseResult, ICount, IEntity, IGraphqlVariables,
  IBaseGraphqlRequest,
} from "corede-common";
import { customersTags } from "apps/crm/domains/04-customer/subdomains/customers";

export const organizationApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({
    // leadTags: builder.query<
    //     ILeadTagsResult,
    //     IGraphqlVariables<ILeadTagsFilterInput>
    // >(
    //     EndpointQueryBuilder.BuildGraphqlQuery<
    //         ILeadTagsRequest,
    //         ILeadTagsResponse,
    //         ILeadTagsResult,
    //         ILeadTagsFilterInput,
    //     >({
    //         query: leadTagsQuery,
    //         providesTags: [leadTags.leadTags],
    //     })),

    leadList: builder.query<ILeadListResult, IGraphqlVariables<ILeadListInput>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ILeadListRequest,
        ILeadListResponse,
        ILeadListResult,
        ILeadListInput
      >({
        query: leadListQuery,
        providesTags: [leadTags.lead, leadTags.leads],
      })
    ),

    leadListCount: builder.query<ICount, IGraphqlVariables<undefined>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ILeadListCountRequest,
        ILeadListCountResponse,
        ICount,
        undefined
      >({
        query: leadListCountQuery,
        providesTags: [leadTags.leads],
      })
    ),

    leadDetail: builder.query<
      ILeadDetailResult,
      IGraphqlVariables<ILeadDetailInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ILeadDetailRequest,
        ILeadDetailResponse,
        ILeadDetailResult,
        ILeadDetailInput
      >({
        query: leadDetailQuery,
        providesTags: [leadTags.lead, leadTags.leads],
      })
    ),

    noteList: builder.query<INoteListResult, IGraphqlVariables<INoteListInput>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        INoteListRequest,
        INoteListResponse,
        INoteListResult,
        INoteListInput
      >({
        query: noteListQuery,
        providesTags: [leadTags.note, leadTags.notes],
      })
    ),

    leadEstimateList: builder.query<
      ILeadEstimateListResult,
      IGraphqlVariables<ILeadEstimateListInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ILeadEstimateListRequest,
        ILeadEstimateListResponse,
        ILeadEstimateListResult,
        ILeadEstimateListInput
      >({
        query: leadEstimateListQuery(),
        providesTags: [leadTags.lead, leadTags.leads],
      })
    ),

    leadProposalList: builder.query<
      ILeadProposalListResult,
      IGraphqlVariables<ILeadProposalListInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ILeadProposalListRequest,
        ILeadProposalListResponse,
        ILeadProposalListResult,
        ILeadProposalListInput
      >({
        query: leadProposalListQuery(),
        providesTags: [leadTags.lead, leadTags.leads],
      })
    ),

    leadReminderList: builder.query<
      IReminderListResult,
      IGraphqlVariables<IReminderListInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ILeadReminderListRequest,
        ILeadReminderListResponse,
        IReminderListResult,
        IReminderListInput
      >({
        query: leadReminderListQuery,
        providesTags: [leadTags.lead, leadTags.leads],
      })
    ),

    leadReminderDetail: builder.query<
      IReminderDetailResult,
      IGraphqlVariables<IReminderDetailInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ILeadReminderDetailRequest,
        ILeadReminderDetailResponse,
        IReminderDetailResult,
        IReminderDetailInput
      >({
        query: leadReminderDetailQuery,
        providesTags: [leadTags.lead, leadTags.leads],
      })
    ),

    leadStatistics: builder.query<
      ILeadStatisticsResult,
      IGraphqlVariables<ILeadStatisticsInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ILeadStatisticsRequest,
        ILeadStatisticsResponse,
        ILeadStatisticsResult,
        ILeadStatisticsInput
      >({
        query: leadStatisticsQuery,
        providesTags: [leadTags.lead, leadTags.leads],
      })
    ),

    leadNoteList: builder.query<
      INoteListResult,
      IGraphqlVariables<INoteListInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        INoteListRequest,
        INoteListResponse,
        INoteListResult,
        INoteListInput
      >({
        query: leadNoteListQuery,
        providesTags: [leadTags.note, leadTags.notes],
      })
    ),

    leadNoteDetail: builder.query<
      INoteDetailResult,
      IGraphqlVariables<INoteDetailInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ILeadNoteDetailRequest,
        ILeadNoteDetailResponse,
        INoteDetailResult,
        INoteDetailInput
      >({
        query: leadNoteDetailQuery,
        providesTags: [leadTags.note, leadTags.notes],
      })
    ),



    // mutations

    leadCreate: builder.mutation<
      ILeadCreateResult,
      IGraphqlVariables<ILeadCreateInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ILeadCreateRequest,
        ILeadCreateResponse,
        ILeadCreateResult,
        ILeadCreateInput
      >({
        query: leadCreateQuery,
        invalidatesTags: [leadTags.lead, leadTags.leads],
      })
    ),

    leadUpdate: builder.mutation<
      ILeadUpdateResult,
      IGraphqlVariables<ILeadUpdateInput, ILeadUpdateFilterInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ILeadUpdateRequest,
        ILeadUpdateResponse,
        ILeadUpdateResult,
        ILeadUpdateInput,
        ILeadUpdateFilterInput
      >({
        query: leadUpdateQuery,
        invalidatesTags: [leadTags.lead, leadTags.leads],
      })
    ),

    leadDelete: builder.mutation<
      ILeadDeleteResult,
      IGraphqlVariables<ILeadDeleteInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ILeadDeleteRequest,
        ILeadDeleteResponse,
        ILeadDeleteResult,
        ILeadDeleteInput
      >({
        query: leadDeleteQuery,
        invalidatesTags: [leadTags.lead, leadTags.leads],
      })
    ),

    noteCreate: builder.mutation<
      INoteCreateResult,
      IGraphqlVariables<INoteCreateInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        INoteCreateRequest,
        INoteCreateResponse,
        INoteCreateResult,
        INoteCreateInput
      >({
        query: noteCreateQuery,
        invalidatesTags: [leadTags.lead, leadTags.note, leadTags.notes],
      })
    ),

    noteUpdate: builder.mutation<
      INoteUpdateResult,
      IGraphqlVariables<INoteUpdateInput, INoteUpdateFilterInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        INoteUpdateRequest,
        INoteUpdateResponse,
        INoteUpdateResult,
        INoteUpdateInput,
        INoteUpdateFilterInput
      >({
        query: noteUpdateQuery,
        invalidatesTags: [leadTags.lead, leadTags.note, leadTags.notes],
      })
    ),

    noteDelete: builder.mutation<
      INoteDeleteResult,
      IGraphqlVariables<INoteDeleteInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        INoteDeleteRequest,
        INoteDeleteResponse,
        INoteDeleteResult,
        INoteDeleteInput
      >({
        query: noteDeleteQuery,
        invalidatesTags: [leadTags.lead, leadTags.note, leadTags.notes],
      })
    ),

    leadContactAdd: builder.mutation<
      IBaseResult,
      IGraphqlVariables<ILeadContactAddInput, IEntity>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ILeadContactAddRequest,
        ILeadContactAddResponse,
        IBaseResult,
        ILeadContactAddInput,
        IEntity
      >({
        query: leadContactAddQuery,
        invalidatesTags: [leadTags.lead, leadTags.leads],
      })
    ),


    customerCreateFromLead: builder.mutation<
      ICustomerCreateResult,
      IGraphqlVariables<ICustomerCreateFromLeadInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ICustomerCreateFromLeadRequest,
        ICustomerCreateFromLeadResponse,
        ICustomerCreateResult,
        ICustomerCreateFromLeadInput
      >({
        query: customerCreateFromLeadQuery,
        invalidatesTags: [leadTags.lead, leadTags.leads, customersTags.customer, customersTags.customers],
      })
    ),

  }),
});

export const {
  useLeadListQuery,
  useLazyLeadListQuery,
  useLeadDetailQuery,
  useLazyLeadDetailQuery,
  useLeadListCountQuery,
  useLazyLeadListCountQuery,
  useLeadEstimateListQuery,
  useLazyLeadEstimateListQuery,
  useLeadProposalListQuery,
  useLazyLeadProposalListQuery,
  useLeadStatisticsQuery,
  useLazyLeadStatisticsQuery,
  useLeadNoteListQuery,
  useLazyLeadNoteListQuery,
  useLeadNoteDetailQuery,
  useLazyLeadNoteDetailQuery,

  useNoteListQuery,
  useLazyNoteListQuery,

  useLeadCreateMutation,
  useLeadUpdateMutation,
  useLeadDeleteMutation,

  useNoteCreateMutation,
  useNoteUpdateMutation,
  useNoteDeleteMutation,

  useLeadContactAddMutation,
  useCustomerCreateFromLeadMutation,
} = organizationApi;
