import { Pagination } from "@mui/material";
import { defaultPageSize } from "./PaginationComponent.constant";
import { IPaginationComponentProps } from "./PaginationComponent.interface";

export const PaginationComponent = (props: IPaginationComponentProps) => {
  const pageSize = props.pageSize ?? defaultPageSize;
  return (
    <Pagination
      count={Math.ceil((props.count ?? 0) / pageSize)}
      page={props.page ?? 1}
      size="small"
      sx={{
        "& .MuiPaginationItem-root": {
          fontSize: "12px",
          fontWeight: "bold",
          p: 0,
        },
      }}
      onChange={(_, page) => {
        if (props.onChange) {
          props.onChange({
            page,
            pageSize,
          });
        }
      }}
      color="primary"
      shape="rounded"
    />
  );
};
