
import { profileDomainRoutes } from "../domains/profile/routes/profile.base.route";
import { ProfileDomainRouter } from "../domains/profile/routes/ProfileDomainRouter";
import { Route } from "react-router-dom";
import { ProfileRouter } from "../domains/profile/subdomains/profile/routes/ProfileRouter";
import AccountLayout from "../layouts/AccountLayout";

export const AccountRouter = () => {
  return (
    <Route path="/*" element={<AccountLayout />}>
      <Route index element={< ProfileRouter />} />
      <Route
        path={`${profileDomainRoutes.base}/*`}
        element={<ProfileDomainRouter />}
      />
      <Route path="*" element={<ProfileDomainRouter />} />
    </Route>
  );
};
