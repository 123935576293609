import { IGridColConfigInput } from "./IGridColConfigInput";

/**
 * return config with the specified field name form array of configs
 */
export function getGridColConfig(
  configs: IGridColConfigInput[],
  columnField: string
): IGridColConfigInput | undefined | null {
  const result = configs.filter(
    (config) => config.column.field === columnField
  );
  return result && result.length >= 0 ? result.at(0) : null;
}
