import { GridColDef } from "@mui/x-data-grid-pro";
import { IGridColConfigInput } from "../infra/IGridColConfigInput";
import { SvgIcon } from "@mui/material";
import * as Icons from "@mui/icons-material";
import { generateBaseColDef } from "../infra/GridColDefFactory";

export const BaseIconGridColumnDef = (params: {
  config: IGridColConfigInput;
}): GridColDef => {
  const baseColDef = generateBaseColDef({
    config: params.config,
  });

  baseColDef.renderCell = (cellParams: any) => {
    return (
      <SvgIcon
        component={(Icons as any)[cellParams.value]}
        fontSize="small"
        sx={{ mt: 2, ml: 0.5 }}
      />
    );
  };
  baseColDef.renderEditCell = () => null;

  return baseColDef;
};
