const locale = {
  title: "Kontrol Paneli",
  description: "Aboneliğinizi Aktifleştirin. CRM sisteminin tüm özelliklerini kullanmak ve işinizi büyütmek için uygun bir abonelik planına geçin.",
  featuresTitle: "Abonelikle birlikte:",
  features1: "Tüm verilerinize sınırsız erişim",
  features2: "Özel raporlama araçları",
  features3: "Daha fazla depolama alanı",
  features4: "7/24 teknik destek",
  buttonTitle: "Abonelik Planlarını Keşfedin",
  trialExpired: "Trial aboneliginiz sona erdi. Lütfen farklı bir abonelik seçin.",
  mainFailed: "Abonelik ödemeniz alınamadı. Lütfen abonelik bilgilerinizi kontrol edin.",
  mainCompleted: "Abonelik tamamlandı. Lütfen farklı bir abonelik seçin.",
  welcome: "Hoş geldiniz "
};

export default locale;