/* eslint-disable react-hooks/exhaustive-deps */
import { Outlet } from "react-router";
import { useTranslation } from "react-i18next";
import MainLayout from "layouts/MainLayout";
import {
  useLazyOrganizationDetailOwnQuery,
  useUserDetailOwnQuery,
} from "apps/auth/context";
import { useEffect } from "react";
import { useLazyUserPermissionListOwnQuery } from "apps/account/domains/profile/subdomains/profile";
import { useCrmMenuItem } from "../menu/items";
import { useRestrictedCrmMenuItem } from "../menu/items.restricted";

const CrmLayout = () => {
  const { t } = useTranslation();
  const { data: userDetailOwnData } = useUserDetailOwnQuery({});
  const [getOrganizationDetailOwn, { data: organizationDetailOwnData }] =
    useLazyOrganizationDetailOwnQuery();
  const [getUserPermissionListOwn, { data: userPermissionListOwnData }] =
    useLazyUserPermissionListOwnQuery({});

  const status = organizationDetailOwnData?.status;

  const treeItems = useCrmMenuItem({
    userPermissions: userPermissionListOwnData,
  });

  const restrictedTreeItems = useRestrictedCrmMenuItem();

  useEffect(() => {
    if (userDetailOwnData?.organization) {
      getUserPermissionListOwn({});
      getOrganizationDetailOwn({});
    }
  }, [userDetailOwnData]);

  return (
    <MainLayout
      treeItems={status !== "active" ? restrictedTreeItems : treeItems}
    >
      <Outlet />
    </MainLayout>
  );
};

export default CrmLayout;
