const locale = {
  notes: "Notes",
  createNote: "Create Note",
  title: "Title",
  content: "Content",
  addNote: "Add Note",
  priority: "Priority",
  noteDate: "Note Date",
  noteUsers: "Note Users",
  tags: "Tags",
  tagsPlaceholder: "Please add tags and press enter",
  createNoteSuccess: "Note created successfully",
  target: "Target",
  updateNote: "Update Note",
  deleteNote: "Delete Note",
  deletedSuccessfully: "Note deleted successfully",
  deleteNoteContent: "Are you sure you want to delete this note. This action cannot be undone.",
  note: "Note",
  noteConversions: "Note Conversions",
  emptyStateDesc1: "No notes added yet.",
  emptyStateDesc2: "Create and organize your notes here to keep track of important details and conversations in your CRM.",
  updateNoteSuccess: "Note updated successfully",
};

export default locale;
 
