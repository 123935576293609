import {
  IReminderListInput,
  IReminderListRequest,
  IReminderListResponse,
  IReminderListResult,
  reminderListQuery,

  IReminderDetailInput,
  IReminderDetailRequest,
  IReminderDetailResponse,
  IReminderDetailResult,
  reminderDetailQuery,

  IReminderCreateInput,
  IReminderCreateRequest,
  IReminderCreateResult,
  IReminderCreateResponse,
  reminderCreateQuery,

  IReminderUpdateInput,
  IReminderUpdateRequest,
  IReminderUpdateResult,
  IReminderUpdateResponse,
  IReminderUpdateFilterInput,
  reminderUpdateQuery,

  IReminderDeleteInput,
  IReminderDeleteRequest,
  IReminderDeleteResult,
  IReminderDeleteResponse,
  reminderDeleteQuery,

} from "corede-common-cocrm";
import { commonApi } from "context/commonApi";
import { EndpointQueryBuilder } from "apps/helper/endpoint-query.builder";
import { remindersTags } from "./reminders.tags";
import { IGraphqlVariables } from "corede-common";



export const remindersApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({
    // queries

    reminderList: builder.query<IReminderListResult, IGraphqlVariables<IReminderListInput>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IReminderListRequest,
        IReminderListResponse,
        IReminderListResult,
        IReminderListInput
      >({
        query: reminderListQuery,
        providesTags: [remindersTags.reminder, remindersTags.reminders],
      })
    ),

    reminderDetail: builder.query<IReminderDetailResult, IGraphqlVariables<IReminderDetailInput>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IReminderDetailRequest,
        IReminderDetailResponse,
        IReminderDetailResult,
        IReminderDetailInput
      >({
        query: reminderDetailQuery,
        providesTags: [remindersTags.reminder, remindersTags.reminders],
      })
    ),

    // mutation

    reminderCreate: builder.mutation<
      IReminderCreateResult,
      IGraphqlVariables<IReminderCreateInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IReminderCreateRequest,
        IReminderCreateResponse,
        IReminderCreateResult,
        IReminderCreateInput
      >({
        query: reminderCreateQuery,
        invalidatesTags: [remindersTags.reminder, remindersTags.reminders],
      })
    ),

    reminderUpdate: builder.mutation<
      IReminderUpdateResult,
      IGraphqlVariables<IReminderUpdateInput, IReminderUpdateFilterInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IReminderUpdateRequest,
        IReminderUpdateResponse,
        IReminderUpdateResult,
        IReminderUpdateInput,
        IReminderUpdateFilterInput
      >({
        query: reminderUpdateQuery,
        invalidatesTags: [remindersTags.reminder, remindersTags.reminders],
      })
    ),

    reminderDelete: builder.mutation<
      IReminderDeleteResult,
      IGraphqlVariables<IReminderDeleteInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IReminderDeleteRequest,
        IReminderDeleteResponse,
        IReminderDeleteResult,
        IReminderDeleteInput
      >({
        query: reminderDeleteQuery,
        invalidatesTags: [remindersTags.reminder, remindersTags.reminders],
      })
    ),

  }),
});

export const {

  useReminderListQuery,
  useReminderDetailQuery,
  useReminderCreateMutation,
  useReminderUpdateMutation,
  useReminderDeleteMutation

} = remindersApi;
