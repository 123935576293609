import { Route, Routes } from "react-router-dom";
import { List} from "../pages";

import { ReturnsLayout } from "../layouts/ReturnsLayout";

export const ReturnsRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<ReturnsLayout />}>
        <Route index element={<List />} />
       </Route>
    </Routes>
  );
};
