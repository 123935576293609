const locale = {
  demo: "Demo",
  adjustment: "Düzenleme",
  currency: "Para Birimi",
  exchangeRates: "Döviz Kurları",
  fromCurrency: "Kaynak Para Birimi",
  toCurrency: "Hedef Para Birimi",
  rate: "Kur",
  updatedAt: "Güncelleme Zamanı",
  language: "Dil",
  locale: "Yerel Ayar",
  updateSettings: "Ayarları Güncelle",
  NDA: "Gizlilik Anlaşması",
  followUpMailTemplate: "Takip E-Posta Şablonu",
  emailType: "E-Posta Türü",
  subject: "Konu",
  content: "İçerik",
  variables: "Değişkenler",
  prefix: "Önek",
  termsAndConditions: "Şartlar ve Koşullar",
  paymentMethods: "Ödeme Yöntemleri",
  companyBackground: "Şirket Geçmişi",
  companyReferences: "Şirket Referansları",
  timezone: "Zaman Dilimi",
  updateSuccess: "Ayarlar başarıyla güncellendi",
  name: "Ad",
  surname: "Soyad",
  email: "E-posta",
  phone: "Telefon",
  identityNumber: "Kimlik Numarası",
  vat: "KDV",
  country: "Ülke",
  state: "Eyalet",
  city: "Şehir",
  street: "Cadde",
  address: "Adres",
  taxOffice: "Vergi Dairesi",
  zip: "Posta Kodu",
  fullName: "Tam Ad",
  update: "Güncelle",
  create: "Oluştur",
  updateBillingInfo: "Fatura Bilgisini Güncelle",
  addCard: "Yeni Kart Ekle",
  createCardSuccess: "Kart başarıyla oluşturuldu",
  addCardSuccess: "Kart başarıyla eklendi",
  deleteCardSuccess: "Kart başarıyla silindi",
  deleteCardDesc: "Bu kartı silmek istediğinize emin misiniz?",
  commonSettingsUpdateSuccess: "Ayarlar başarıyla güncellendi",
  save: "Kaydet",
  organizationSettings: "Organizasyon",
  monthly: "aylık",
  trial: "Deneme",
  free: "Ücretsiz",
  trialEnds: "Deneme Sonu",
  browsePackages: "Paketlere Gözat",
  updateOrganizationLogoSuccess: "Organizasyon logosu güncellendi",
  organizationLogo: "Organizasyon Logosu",
  dateFormat: "Tarih Formatı",
  timeFormat: "Zaman Formatı",
  vatNumber: "Vergi Numarası",
  taxRate: "Vergi Oranı",
  general: "Genel",
  localizations: "Yerel Ayarlar",
  finance: "Finans",
  generalInfo: "Genel Bilgiler",
  summary: "Özet",
  socialMedia: "Sosyal Medya",
  exchangeRateMode: "Döviz Kuru",
  billingInfo: "Fatura Bilgileri",
  cards: "Kartlar",
  currentPlan: "Mevcut Plan",
  invoices: "Faturalar",
  cardListSecure: "Kartlarınız iyzico veritabanında saklanmaktadır.",
  noInvoiceContent1: "Henuz fatura kaydedilmedi.",
  noInvoiceContent2: "Faturalarınızı oluşturmadan önce fatura bilgilerini düzenleyebilirsiniz.",
  noLanguageContent1: "Henuz dil kaydedilmedi.",
  noLanguageContent2: "Dil ayarlarınızı oluşturmadan önce dil bilgilerini düzenleyebilirsiniz.",
  noLeadContent1: "Potansiyel müşteri ayarları şu anda kullanılamıyor. CRM ",
  noLeadContent2: "CRM iş akışınızı kolaylaştırmak için potansiyel müşterilerin nasıl yakalandığını, atandığını ve takip edildiğini özelleştirin.",
  noCustomerContent1: "Müşteri ayarları şu anda kullanılamıyor. ",
  noCustomerContent2: "Müşteri bilgilerini ve etkileşimlerini CRM'niz içinde etkili bir şekilde yönetmek için tercihleri ayarlayın.",
  noEstimateContent1: "Faturalandırma ayarları şu anda kullanılamıyor. ",
  noEstimateContent2: "Fatura tercihlerinizi burada özelleştirerek faturalandırmayı kolaylaştırın ve doğru kayıtlar sağlayın.",
  noProposalContent1: "",
  noProposalContent2: "",
  noInvoiceContent3: "",
  noInvoiceContent4: "",
  noProjectContent1: "Proje ayarları şu anda kullanılamıyor. ",
  noProjectContent2: "CRM'nizde görev yönetimini ve iş akışını optimize etmek için proje tercihlerinizi burada özelleştirin.",
  noTaskContent1: "Görev ayarları şu anda kullanılamıyor. ",
  noTaskContent2: "Görev yönetimini kolaylaştırmak ve ekip üretkenliğini artırmak için görev tercihlerinizi burada yapılandırın.",
  noTicketContent1: "Destek ayarları şu anda kullanılamıyor. ",
  noTicketContent2: "Müşteri destek taleplerini yönetmek ve zamanında çözümler sağlamak için tercihlerinizi burada ayarlayın.",
  noFileManagementContent1: "Dosya yönetimi ayarları şu anda kullanılamıyor.",
  noFileManagementContent2: " Belgeleri CRM'nizde etkin bir şekilde düzenlemek ve yönetmek için tercihlerinizi burada yapılandırın.",
  noCalendarContent1: "Takvim ayarları şu anda kullanılamıyor.",
  noCalendarContent2: "Takviminizi özelleştirmek ve CRM'niz içinde planlamayı optimize etmek için tercihlerinizi burada ayarlayın.",
  noNotificationContent1: "Bildirim ayarları şu anda kullanılamıyor. ",
  noNotificationContent2: "CRM'inizdeki önemli etkinlikler hakkında bilgi sahibi olmak ve güncel kalmak için bildirim tercihlerinizi buradan özelleştirin.",
  noLeadPlusContent1: "Etkin bir Lead+ ürün veya plan mevcut değil.",
  noLeadPlusContent2: "Lead+ ayarlarınızı özelleştirmek ve tam erişimi açmak için bir plan satın alın veya yetkilendirilin.",
  noMarketingContent1: "Marketing ayarlarınızı özelleştirmek ve tam erişimi açmak için bir plan satın alın veya yetkilendirilin.",
  noMarketingContent2: "Etkin bir Marketing ürün veya plan mevcut değil.",
  noWindowContent1: "Etkin bir Window ürün veya plan mevcut değil. ",
  noWindowContent2: "Window ayarlarınızı özelleştirmek ve tam erişimi açmak için bir plan satın alın veya yetkilendirilin.",
  noCommunityContent1: "Etkin bir Community ürün veya plan mevcut değil. ",
  noCommunityContent2: "Community ayarlarınızı özelleştirmek ve tam erişimi açmak için bir plan satın alın veya yetkilendirilin.",
  noIntegrationsContent1: "Henüz hiçbir entegrasyon ayarlanmadı. ",
  noIntegrationsContent2: "CoCRM'nin yeteneklerini geliştirmek ve yetkili bağlantılarla iş akışlarını kolaylaştırmak için entegrasyonları burada yapılandırın.",
  noMenuContent1: "Menü ayarları şu anda kullanılamıyor.",
  noMenuContent2: "Menü düzeninizi özelleştirmek ve CRM'nizde gezinmeyi kolaylaştırmak için menü erişimini yetkilendirin.",
  noLegalAgreementsContent1: "Yasal sözleşme ayarları şu anda kullanılamıyor.",
  noLegalAgreementsContent2: "CRM içerisinde yasal sözleşmeleri yönetmek ve özelleştirmek için erişimi yetkilendirin.",
  lead: "Lead",
  customer: "Müşteri",
  estimate: "Tahmin",
  proposal: "Teklif",
  invoice: "Fatura",
  project: "Proje",
  task: "Görev",
  ticket: "Talep",
  fileManagement: "Dosya Yönetimi",
  calendar: "Takvim",
  notification: "Bildirimler",
  leadPlus: "Lead+",
  marketing: "Marketing",
  window: "Window",
  community: "Community",
  integrations: "Entegrasyonlar",
  menu: "Menü",
  legalAgreements: "Yasal Sözleşmeler",
  integration: "Entegrasyon",
  userList: "Kullanıcı Listesi",
  permissions: "Yetkiler",
  noUserListContent1:"",
  noUserListContent2:"",
  noPermissionsContent1: "",
  noPermissionsContent2: "",
};

export default locale;
