import demoLocale from "../domains/demo/locales/en";

const locale = {
  demo: {
    ...demoLocale,
  },
  demoo: "Join Waitlist"
};

export default locale;
