/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Grid, Typography, CircularProgress, Stack, IconButton, Checkbox, TextField, Dialog, DialogContent, DialogActions } from "@mui/material";
import { useFormik } from "formik";
import { registerValidationSchema } from 'utils/validations';
import { AuthLayout } from "../layouts";
import { useRegisterMutation, setUser, useLatestPolicyQuery } from "../context";
import { useSnackbar } from "notistack";
import { useAppDispatch } from "context";
import { useEffect, useState } from "react";
import { Iconify } from "components";
import LoginCo from "assets/images/login-co.png";
import { Policy, Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { routes } from "../routes/routes";
import { useTranslation } from 'react-i18next';
import ActionDialog from "components/dialog/ActionDialog";
import { getCurrentLanguage } from "localization";
import { PolicyDomain, PolicyTarget, PolicyType } from "corede-common-cocrm";
import parse from "html-react-parser";
import { unknownError } from "corede-common";

const Signup = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentLanguage = getCurrentLanguage();
  const [getRegister, { data, isLoading, error }] = useRegisterMutation();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const [visibility, setVisibility] = useState<string[]>([]);
  const [openTermsAndConditions, setOpenTermsAndConditions] = useState<boolean>(false);
  const [openPrivacyPolicy, setOpenPrivacyPolicy] = useState<boolean>(false);
  const [openExplicitPolicy, setOpenExplicitPolicy] = useState<boolean>(false);
  const [openKvkkPolicy, setOpenKvkkPolicy] = useState<boolean>(false);
  const [selectedPolicyType, setSelectedPolicyType] = useState<PolicyType>(PolicyType.terms);

  const { data: latestPolicyData, isLoading: latestPolicyLoading, error: latestPolicyError } = useLatestPolicyQuery({
    input: {
      domain: PolicyDomain.app,
      language: currentLanguage,
      target: PolicyTarget.general,
      type: selectedPolicyType
    }
  });

  const handleChangeVisibility = (field: string) => {
    if (visibility.includes(field)) {
      setVisibility(visibility.filter(item => item !== field));
    } else {
      setVisibility([...visibility, field]);
    }
  };

  const { values, handleSubmit, setFieldValue, errors, handleBlur, touched } = useFormik({
    enableReinitialize: true,
    validationSchema: registerValidationSchema,
    initialValues: {
      name: undefined,
      surname: undefined,
      email: "",
      password: "",
      passwordAgain: "",
      role: "user",
      referrerCode: undefined,
      isChecked: false,
      isChecked2: false
    },
    onSubmit: async (values) => {
      try {
        await getRegister({
          input: {
            name: values.name,
            surname: values.surname,
            email: values.email,
            password: values.password,
            role: values.role,
            referrerCode: values.referrerCode
          }
        });
      } catch (error) {
        enqueueSnackbar(t("auth.somethingWentWrong"), { variant: 'error' });
      }
    }
  });


  useEffect(() => {
    if (error) {
      enqueueSnackbar((error as any)?.error?.message?.[currentLanguage] ?? unknownError.message[currentLanguage], { variant: 'error' });
    }
  }, [error, enqueueSnackbar]);

  useEffect(() => {
    if (data) {
      enqueueSnackbar(t("auth.signupSuccess"), { variant: 'success' });
      dispatch(setUser(data));
      // navigate(routes.auth.confirmation + "?token=" + data?.devMetaData?.token + "&code=" + data?.devMetaData?.code);
      navigate(routes.auth.signupSuccessfull);
    }
  }, [data, dispatch, enqueueSnackbar]);

  console.log(latestPolicyData?.content)
  return (
    <AuthLayout>
      <Stack direction={"column"} justifyContent={"space-between"} alignItems={"center"}>
        <Typography fontSize={14} fontWeight={400} textAlign={"center"} color={"GrayText"} py={2}>

        </Typography>
        <Grid item xs={12} display={"flex"} height={"100vh"} width={"100%"} justifyContent={'center'} alignItems={'center'}>
          <Box display={{ xs: 'flex', md: 'none' }} height={100} />
          <Box
            sx={{
              width: { xs: '100%', md: '80%', lg: '60%' },
              mt: 2,
              minHeight: "80vh",
              display: 'flex',
              flexDirection: 'column',
              justifyContent: { xs: 'flex-start', md: 'center' },
              alignItems: 'center',
              gap: 2,
            }}
            px={{ xs: 1, md: 7 }}
          >
            <img src={LoginCo} alt={t("auth.logoAlt")} width={120} />
            <Box display={"flex"} flexDirection={"column"} gap={2} alignItems={"center"} py={2}>
              <Typography fontSize={14} fontWeight={400} textAlign={"center"}>
                {t("auth.letsRegister")}
              </Typography>
            </Box>

            <Box display={"flex"} sx={{ width: '100%' }} flexDirection={"column"} alignItems={"center"}>
              <form onSubmit={handleSubmit as any} style={{ width: '100%' }}>
                <Box display={"flex"} flexDirection={"column"} gap={2.7} alignItems={"center"}>
                  <Stack direction={"row"} gap={3} width={"100%"} justifyContent={"space-between"}>
                    <TextField
                      placeholder={t("auth.name")}
                      type="text"
                      value={values.name}
                      onChange={(e) => setFieldValue("name", e.target.value.slice(0, 1).toUpperCase() + e.target.value.slice(1))}
                      error={!!errors.name && touched.name}
                      helperText={(errors.name && touched.name) ? errors.name : ""}
                      onBlur={handleBlur("name")}
                      fullWidth
                    />
                    <TextField
                      placeholder={t("auth.surname")}
                      type="text"
                      value={values.surname}
                      onChange={(e) => setFieldValue("surname", e.target.value.slice(0, 1).toUpperCase() + e.target.value.slice(1))}
                      error={!!errors.surname && touched.surname}
                      helperText={(errors.surname && touched.surname) ? errors.surname : ""}
                      onBlur={handleBlur("surname")}
                      fullWidth
                    />
                  </Stack>
                  <TextField
                    placeholder={t("auth.emailAddress")}
                    type="email"
                    value={values.email}
                    onChange={(e) => setFieldValue("email", e.target.value)}
                    error={!!errors.email && touched.email}
                    helperText={(errors.email && touched.email) ? errors.email : ""}
                    onBlur={handleBlur("email")}
                    fullWidth
                  />
                  <TextField
                    placeholder={t("auth.password")}
                    type={visibility.includes("password") ? 'text' : 'password'}
                    value={values.password}
                    onChange={(e) => setFieldValue("password", e.target.value)}
                    error={!!errors.password && touched.password}
                    helperText={touched.password && errors.password ? errors.password.split('. ')[0] : ""}
                    onBlur={handleBlur("password")}
                    fullWidth
                    InputProps={{
                      endAdornment:
                        <IconButton onClick={() => handleChangeVisibility("password")}>
                          {visibility.includes("password") ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    }}
                  />
                  <TextField
                    placeholder={t("auth.passwordAgain")}
                    type={visibility.includes("passwordAgain") ? 'text' : 'password'}
                    value={values.passwordAgain}
                    onChange={(e) => setFieldValue("passwordAgain", e.target.value)}
                    error={!!errors.passwordAgain && touched.passwordAgain}
                    helperText={(errors.passwordAgain && touched.passwordAgain) ? errors.passwordAgain : ""}
                    onBlur={handleBlur("passwordAgain")}
                    fullWidth
                    InputProps={{
                      endAdornment:
                        <IconButton onClick={() => handleChangeVisibility("passwordAgain")}>
                          {visibility.includes("passwordAgain") ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    }}
                  />

                  <Box display={"flex"} flexDirection={"column"} gap={0.7} alignItems={"flex-start"} justifyContent={"center"}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      spacing={0.5}
                      onClick={() => setFieldValue("isChecked", !values.isChecked)}
                      sx={{ cursor: "pointer", flexWrap: "wrap", whiteSpace: "nowrap" }}
                    >
                      <Checkbox
                        checked={values.isChecked}
                        onChange={() => setFieldValue("isChecked", !values.isChecked)}
                        color="secondary"
                      />
                      <Typography fontSize={14} fontWeight={400}>
                        {t("auth.iAccept")}
                      </Typography>
                      <Typography
                        fontSize={14}
                        fontWeight={600}
                        onClick={(e) => {
                          setOpenTermsAndConditions(true);
                          setSelectedPolicyType(PolicyType.terms);
                          e.stopPropagation();
                        }}
                        sx={{ color: "secondary.main", ml: 0.5 }}
                      >
                        {t("auth.termsAndConditions")}
                      </Typography>
                      <Typography fontSize={14} fontWeight={400} sx={{ ml: 0.5 }}>
                        {t("auth.with")}
                      </Typography>
                      <Typography
                        fontSize={14}
                        fontWeight={600}
                        onClick={(e) => {
                          setOpenPrivacyPolicy(true);
                          setSelectedPolicyType(PolicyType.privacy);
                          e.stopPropagation();
                        }}
                        sx={{ color: "secondary.main", ml: 0.5 }}
                      >
                        {t("auth.privacyPolicy")}
                      </Typography>
                    </Stack>
                    {errors.isChecked && touched.isChecked && (
                      <Typography fontSize={12} marginTop={-2} fontWeight={400} color={"error"}>
                        {errors.isChecked}
                      </Typography>
                    )}

                    <Stack
                      direction="row"
                      alignItems="center"
                      spacing={0.5}
                      onClick={() => setFieldValue("isChecked2", !values.isChecked2)}
                      sx={{ cursor: "pointer", flexWrap: "wrap", whiteSpace: "nowrap" }}
                    >
                      <Checkbox
                        checked={values.isChecked2}
                        onChange={() => setFieldValue("isChecked2", !values.isChecked2)}
                        color="secondary"
                      />
                      <Typography fontSize={14} fontWeight={400}>
                        {t("auth.iAccept")}
                      </Typography>
                      <Typography
                        fontSize={14}
                        fontWeight={600}
                        onClick={(e) => {
                          setOpenExplicitPolicy(true);
                          setSelectedPolicyType(PolicyType.explicit);
                          e.stopPropagation();
                        }}
                        sx={{ color: "secondary.main", ml: 0.5 }}
                      >
                        {t("auth.explicitConsent")}
                      </Typography>
                      <Typography fontSize={14} fontWeight={400} sx={{ ml: 0.5 }}>
                        {t("auth.and")}
                      </Typography>
                      <Typography
                        fontSize={14}
                        fontWeight={600}
                        onClick={(e) => {
                          setOpenKvkkPolicy(true);
                          setSelectedPolicyType(PolicyType.kvkk);
                          e.stopPropagation();
                        }}
                        sx={{ color: "secondary.main", ml: 0.5 }}
                      >
                        {t("auth.kvkkPolicy")}
                      </Typography>
                    </Stack>
                    {errors.isChecked2 && touched.isChecked2 && (
                      <Typography fontSize={12} marginTop={-2} fontWeight={400} color={"error"}>
                        {errors.isChecked2}
                      </Typography>
                    )}
                  </Box>

                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handleSubmit as any}
                    disabled={!!(isLoading || !values.isChecked || !values.isChecked2 || errors.email || errors.password || errors.passwordAgain)}
                    sx={{ fontSize: 14, height: 56 }}
                  >
                    {(isLoading) ? <CircularProgress size="1rem" /> : t("auth.signUp")}
                  </Button>
                </Box>
              </form>
            </Box>
            <Stack display={"flex"} flexDirection={"row"} gap={2} alignItems={"center"} width={"100%"}>
              <Box bgcolor={"#aaa"} width={"80%"} height={"1px"} sx={{ borderRadius: 1 }} />
              <Typography width="100%" fontSize={14} fontWeight={400} color={"GrayText"} textAlign={"center"}>
                {t("auth.orContinueWith")}
              </Typography>
              <Box bgcolor={"#aaa"} width={"80%"} height={"1px"} sx={{ borderRadius: 1 }} />
            </Stack>
            <Stack display={"flex"} flexDirection={"row"} gap={2} alignItems={"center"} justifyContent={"center"} width={"100%"}>
              <IconButton sx={{ backgroundColor: "primary.main", p: 1.5, color: "#fff", '&:hover': { backgroundColor: "primary.dark", boxShadow: 2 } }}
                onClick={() => {
                  (!values?.isChecked || !values?.isChecked2) && enqueueSnackbar(t("auth.acceptAllPolicies"), { variant: "warning" });
                  values?.isChecked && values?.isChecked2 && (window.location.href = `${process.env.REACT_APP_BASE_URL}/auth/google?action=register`)
                }}
              >
                <Iconify icon={"ant-design:google-outlined"} width={24} sx={{ color: "#fff" }} />
              </IconButton>
              {/* <IconButton sx={{ backgroundColor: "primary.main", p: 1.5, color: "#fff", '&:hover': { backgroundColor: "primary.dark", boxShadow: 2 } }}>
                <Iconify icon={"ic:baseline-apple"} width={24} sx={{ color: "#fff" }} />
              </IconButton> */}
              <IconButton sx={{ backgroundColor: "primary.main", p: 1.5, color: "#fff", '&:hover': { backgroundColor: "primary.dark", boxShadow: 2 } }}
                onClick={() => {
                  (!values?.isChecked || !values?.isChecked2) && enqueueSnackbar(t("auth.acceptAllPolicies"), { variant: "warning" });
                  values?.isChecked && values?.isChecked2 && (window.location.href = `${process.env.REACT_APP_BASE_URL}/auth/linkedin?action=register`)
                }}
              >
                <Iconify icon={"ri:linkedin-fill"} width={24} sx={{ color: "#fff" }} />
              </IconButton>
            </Stack>
          </Box>
        </Grid>

        <Typography fontSize={14} fontWeight={400} textAlign={"center"} color={"GrayText"} py={2} sx={{ cursor: "pointer" }}>
          {t("auth.alreadyHaveAccount")} <span style={{ color: "#000" }} onClick={() => navigate(routes.auth.login)}>{t("auth.login")}</span>
        </Typography>
      </Stack>

      {!latestPolicyLoading && <ActionDialog
        open={openTermsAndConditions}
        onClose={() => setOpenTermsAndConditions(false)}
        title={t("auth.termsAndConditions")}
        width={800}
      >
        {parse(latestPolicyData?.content || "")}
      </ActionDialog>}
      {!latestPolicyLoading && selectedPolicyType === PolicyType.privacy && <ActionDialog
        open={openPrivacyPolicy}
        onClose={() => setOpenPrivacyPolicy(false)}
        title={t("auth.privacyPolicy")}
        width={800}
      >
        {parse(latestPolicyData?.content || "")}
      </ActionDialog>}
      {!latestPolicyLoading && <ActionDialog
        open={openExplicitPolicy}
        onClose={() => setOpenExplicitPolicy(false)}
        title={t("auth.explicitConsent")}
        width={800}
      >
        {parse(latestPolicyData?.content || "")}
      </ActionDialog>}
      {!latestPolicyLoading && <ActionDialog
        open={openKvkkPolicy}
        onClose={() => setOpenKvkkPolicy(false)}
        title={t("auth.kvkkPolicy")}
        width={800}
      >
        {parse(latestPolicyData?.content || "")}
      </ActionDialog>}
    </AuthLayout >
  );
}

export default Signup;
