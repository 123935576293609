const locale = {
  reports: "Raporlar",
  report: "Rapor",
  reportConversions: "Rapor Dönüştürme",
  title: "Raporlar",
  addReport: "Rapor Ekle",
  emptyStateDesc1: "Henüz herhangi bir rapor mevcut değil.",
  emptyStateDesc2: "CRM faaliyetlerinizi takip etmek ve veri odaklı kararlar almak için burada ayrıntılı içgörüler oluşturun ve görüntüleyin."
};

export default locale;




 