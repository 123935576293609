
import reportsLocale from "../subdomains/reports/locales/tr";


const locale = {
  // Todo
  // users: {
  //   ...usersLocale,
  // },
  reports: {
    ...reportsLocale,
  },
};

export default locale;

