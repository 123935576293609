import { useTranslation } from "react-i18next";
import { useDashboardDomainMenuItem } from "../domains/01-dashboard/menu/items";
import { usePricingDomainMenuItem } from "../domains/16-pricing/menu/items";

export const useRestrictedCrmMenuItem = () => {
  const { t } = useTranslation();

  return [
    {
      itemId: useDashboardDomainMenuItem().path,
      label: t("crm.dashboardLabel"),
      href: useDashboardDomainMenuItem().path,
      children: useDashboardDomainMenuItem().children?.map((child) => ({
        itemId: child.path,
        label: child.title,
        href: child.path,
      })),
    },
    {
      itemId: usePricingDomainMenuItem().path,
      label: t("crm.pricingLabel"),
      href: usePricingDomainMenuItem().path,
      children: usePricingDomainMenuItem().children?.map((child) => ({
        itemId: child.path,
        label: child.title,
        href: child.path,
      })),
    },
  ];
};
