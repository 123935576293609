/* eslint-disable react-hooks/exhaustive-deps */
import {
    Box, Stack, Collapse, TextField, InputAdornment, Typography, useMediaQuery, Theme,
    Tooltip, Avatar, Divider, ListItemIcon, Menu, MenuItem, FormControl, Select, ClickAwayListener,
    Grid, InputLabel, Badge, ToggleButton as MuiToggleButton,
    useTheme
} from '@mui/material'
import LogoMini from "assets/images/logo-mini.png";
import { Iconify, ToggleButton } from 'components';
import { useState, forwardRef, useEffect } from 'react';
import { TransitionProps } from '@mui/material/transitions';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem, TreeItemProps } from '@mui/x-tree-view/TreeItem';
import { useSpring, animated } from '@react-spring/web';
import { styled } from '@mui/system';
import { Settings, Logout, Lock } from '@mui/icons-material';
import { DarkModeSwitch } from 'react-toggle-dark-mode';
import { commonApi, useAppDispatch, useAppSelector } from 'context';
import { removeUser } from 'apps/auth';
import { setRoute, setThemeMode } from 'context/slices';
import { useLocation, useNavigate } from "react-router-dom";
import { changeLanguage, getCurrentLanguage } from 'localization';
import { IGraphqlVariables, Language, unknownError } from "corede-common";
import { useTranslation } from "react-i18next";
import { IOrganizationCreateOwnInput } from "corede-common-cocrm";
import { useFormik } from "formik";
import { RequestTransformerHelper } from "validations/request.transformer.helper";
import { useLazyOrganizationDetailOwnQuery, useLogoutMutation, useOrganizationCreateOwnMutation, useUserDetailOwnQuery } from "apps/auth/context";
import { validateCreateOrganizationOwnInput } from "apps/crm/validations/createOrganizationOwn.validation";
import { enqueueSnackbar } from 'notistack';
import { useOrganizationChartUpdateMutation } from 'apps/crm/domains/02-organizationalChart/subdomains/users';
import ActionDialog from 'components/dialog/ActionDialog';
import { baseLeadPlusRoute } from 'apps/leadPlus/domains/demo/routes/demo.base.route';
import { baseMarketingRoute } from 'apps/marketing/domains/demo/routes/demo.base.route';
import { baseWindowRoute } from 'apps/window/domains/demo/routes/demo.base.route';
import { baseCommunityRoute } from 'apps/community/domains/demo/routes/demo.base.route';
import { baseDiskRoute } from 'apps/disk/domains/demo/routes/demo.base.route';
import { baseSettingsRoute } from 'apps/settings/domains/settings/routes/demo.base.route';
import { baseAccountRoute } from 'apps/account/domains/profile/routes/profile.base.route';
import { baseOrganizationRoute } from 'apps/crm/domains/01-dashboard/routes/dashboard.base.route';
import NotificationDrawerWrapper from 'apps/webNotification/pages/NotificationDrawerWrapper';
import { useLazyWebNotificationUnreadCountOwnQuery } from 'apps/webNotification';
import moment from 'moment';
import { TypeBackground } from '@mui/material/styles';

declare module '@mui/material/styles' {
    interface TypeBackground {
        firstWithBlur?: string;
        secondWithBlur?: string;
        thirdWithBlur?: string;
    }
}

const CustomTextField = styled(TextField)(({ theme }) => ({
    '& .MuiOutlinedInput-root': {
        borderRadius: '25px',
        width: '300px',
        height: "45px",
        backgroundColor: theme.palette.background.secondary,
        '& fieldset': {
            borderColor: 'transparent',
        },
        '&:hover fieldset': {
            borderColor: 'transparent',
        },
        '&.Mui-focused fieldset': {
            borderColor: 'transparent',
        },
    },
    '& .MuiInputBase-input': {
        padding: '10px 10px',
        fontWeight: 600,
        fontSize: '14px',
    },
}));

function TransitionComponent(props: Readonly<TransitionProps>) {
    const style = useSpring({
        to: {
            opacity: props.in ? 1 : 0,
            transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
        },
    });

    return (
        <animated.div style={style}>
            <Collapse {...props} />
        </animated.div>
    );
}

const CustomTreeItem = forwardRef(
    (props: TreeItemProps, ref: React.Ref<HTMLLIElement>) => (
        <TreeItem
            {...props}
            slots={{ groupTransition: TransitionComponent, ...props.slots }}
            ref={ref}

        />
    ),
);



const MainLayout = ({
    children,
    treeItems,
    expandedMenu,
}
    : {
        children: React.ReactNode,
        treeItems: any,
        expandedMenu?: string[],
    }) => {

    const theme = useTheme();
    const { data, isLoading, refetch } = useUserDetailOwnQuery({});
    const { route } = useAppSelector(state => state.route);
    const { organization, refreshToken } = useAppSelector(state => state.auth);
    const { t } = useTranslation();
    const path = useLocation().pathname;
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const currentLanguage = getCurrentLanguage();
    const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
    const downLg = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
    const [isTreeVisible, setTreeVisible] = useState(!downLg);
    const [mainMenuWidth, setMainMenuWidth] = useState(treeItems?.length > 0 ? 360 : 90);
    const [treeMenuTransform, setTreeMenuTransform] = useState(downLg ? "translateX(-450px)" : "translateX(0px)");
    const [treeMenuOpacity, setTreeMenuOpacity] = useState(1);
    const [anchorElAccountMenu, setAnchorElAccountMenu] = useState<null | HTMLElement>(null);
    const openAccountMenu = Boolean(anchorElAccountMenu);
    const [language, setLanguage] = useState<Language>(currentLanguage);
    const [isDarkMode, setDarkMode] = useState(false);
    const [treeMenuDisplay, setTreeMenuDisplay] = useState("block");
    const [contentWidth, setContentWidth] = useState(downLg ? 'calc(100dvw - 50px)' : treeItems?.length > 0 ? 'calc(100dvw - 390px)' : 'calc(100dvw - 115px)');
    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [organizationCreateOwn, { data: organizationCreateOwnData, isLoading: organizationCreateOwnLoading, error: organizationCreateOwnError }] = useOrganizationCreateOwnMutation();
    const [logout] = useLogoutMutation();
    const [organizationChartUpdate, { isLoading: organizationChartUpdateLoading }] = useOrganizationChartUpdateMutation();
    const handleClickAccountMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElAccountMenu(event.currentTarget);
    };
    const [notificationDrawerOpen, setNotificationDrawerOpen] = useState(false);
    // const normalizePath = (path: string) => {
    //     return path.includes("/settings/") ? path :
    //         path === "/" ? "/dashboard/" :
    //             path;
    // };

    const normalizePath = (path: string) => {
        if (path.includes("/settings/")) {
            return path;
        }

        if (path === "/") {
            return "/dashboard/";
        }


        return path;
    };

    const [selectedItems, setSelectedItems] = useState(normalizePath(path));
    const [expandedItems, setExpandedItems] = useState([("/" + (path === "/" ? "dashboard/" : path.split('/')[1] + "/"))]);
    const [getOrganizationDetailOwn, { data: organizationDetailOwn }] = useLazyOrganizationDetailOwnQuery();
    const [getWebNotificationUnreadCountOwn, { data: countResult }] = useLazyWebNotificationUnreadCountOwnQuery();

    const status = organizationDetailOwn?.status;

    const handleCloseAccountMenu = () => {
        setAnchorElAccountMenu(null);
    };

    const toggleDarkMode = (checked: boolean) => {
        setDarkMode(checked);
        dispatch(setThemeMode(checked ? "dark" : "light"));
    };


    const handleLogout = () => {

        logout({
            input: { refreshToken: refreshToken }
        }).then(() => {
            dispatch(removeUser());
            dispatch(commonApi.util.resetApiState());
        }).catch(() => {
            dispatch(removeUser());
            dispatch(commonApi.util.resetApiState());
        })
    };


    const handleButtonClick = (param: string) => {
        (param !== "close" && param !== "clickaway") && dispatch(setRoute(param));
        (param !== "close" && param !== "clickaway") && param === "crm" && navigate(baseOrganizationRoute());
        (param !== "close" && param !== "clickaway") && param === "leadPlus" && navigate(baseLeadPlusRoute());
        (param !== "close" && param !== "clickaway") && param === "marketing" && navigate(baseMarketingRoute());
        (param !== "close" && param !== "clickaway") && param === "window" && navigate(baseWindowRoute());
        (param !== "close" && param !== "clickaway") && param === "community" && navigate(baseCommunityRoute());
        (param !== "close" && param !== "clickaway") && param === "disk" && navigate(baseDiskRoute());
        (param !== "close" && param !== "clickaway") && param === "settings" && navigate(baseSettingsRoute());
        (param !== "close" && param !== "clickaway") && param === "account" && navigate(baseAccountRoute());


        if (param === route && isTreeVisible) {
            setTreeVisible(false);
            setMainMenuWidth(90);
            setTreeMenuOpacity(-1);
            setTreeMenuTransform("translateX(-450px)");
            setContentWidth(downLg ? 'calc(100dvw - 50px)' : 'calc(100dvw - 115px)');
        } else {
            setMainMenuWidth(360);
            setTreeMenuOpacity(1);
            setTreeVisible(true);
            setTreeMenuTransform("translateX(-3px)");
            setContentWidth(downLg ? 'calc(100dvw - 50px)' : 'calc(100dvw - 390px)');
        }
        if (param === "close") {
            setTreeVisible(false);
            setMainMenuWidth(90);
            setTreeMenuOpacity(-1);
            setTreeMenuTransform("translateX(-450px)");
            setContentWidth(downLg ? 'calc(100dvw - 50px)' : 'calc(100dvw - 115px)');
        }
        if (param === "clickaway") {
            setTreeVisible(false);
            setMainMenuWidth(90);
            setTreeMenuOpacity(-1);
            setTreeMenuTransform("translateX(-450px)");
            setContentWidth(downLg ? 'calc(100dvw - 50px)' : 'calc(100dvw - 115px)');
        }


    };

    console.log(theme)
    const mainMenuStyle = useSpring({
        width: downLg ? 40 : mainMenuWidth,
    });

    const treeMenuStyle = useSpring({
        transform: downLg ? treeMenuTransform : "translateY(0px)",
        opacity: !downLg ? treeMenuOpacity : 1,
        backgroundColor: downLg ? theme?.palette?.background?.firstWithBlur : undefined,
        backdropFilter: downLg ? "blur(5px)" : undefined,
        zIndex: 10,
        borderTopRightRadius: downLg ? "30px" : "0px",
        borderBottomRightRadius: downLg ? "30px" : "0px",
        display: treeMenuDisplay,
    });

    const contentStyle = useSpring({
        width: contentWidth,
    });

    const { values, handleSubmit, handleBlur, setFieldValue, errors, touched } =
        useFormik({
            initialValues: {
                input: {
                    name: "",
                },
            },
            enableReinitialize: true,
            validationSchema: validateCreateOrganizationOwnInput,
            onSubmit: async (values: IGraphqlVariables<IOrganizationCreateOwnInput>) => {
                const transformedValues =
                    RequestTransformerHelper.TransformAllEmptyStringFieldsToUndefined({
                        input: values,
                    });
                await organizationCreateOwn(transformedValues as IGraphqlVariables<IOrganizationCreateOwnInput>)
            }
        });

    useEffect(() => {
        const theme = localStorage.getItem("theme");
        if (theme) {
            dispatch(setThemeMode(theme as "light" | "dark"));
            setDarkMode(theme === "dark");
        }
    }, [dispatch]);

    useEffect(() => {
        if (treeMenuOpacity === -1) {
            setTimeout(() => {
                setTreeMenuDisplay("none");
            }, 100);
        } else {
            setTreeMenuDisplay("block");
        }
    }, [treeMenuOpacity]);

    useEffect(() => {
        if (!organization && data && data?.type !== "cocrm") {
            setOpenBackdrop(true);
        }
        if (data?.organization && !isLoading) {
            setOpenBackdrop(false);
            getOrganizationDetailOwn({});
            getWebNotificationUnreadCountOwn({});
        }
    }, [organization, data]);

    useEffect(() => {
        if (organizationCreateOwnData) {
            organizationChartUpdate({
                input: {
                    chart: {
                        user: {
                            _id: data?._id as string
                        },
                        children: []
                    }
                }
            }).then(() => {
                refetch();
                setOpenBackdrop(false);
                enqueueSnackbar(t("crm.mustCreateOrganizationSuccess"), { variant: 'success' });
            })
        }
    }, [organizationCreateOwnData]);


    useEffect(() => {
        const normalizedPath = normalizePath(path);
        if (normalizedPath !== selectedItems) {
            setSelectedItems(normalizedPath);
        }
    }, [path]);


    useEffect(() => {
        if (organization) {
            const interval = setInterval(() => {
                getWebNotificationUnreadCountOwn({});
            }, 10000);

            return () => clearInterval(interval);
        }
    }, [organization]);

    useEffect(() => {
        if (organizationCreateOwnError) {
            enqueueSnackbar((organizationCreateOwnError as any)?.error?.message[currentLanguage] ?? unknownError.message[currentLanguage], { variant: 'error' });
        }
    }, [organizationCreateOwnError]);

    return (
        <Box sx={{
            display: 'flex', width: '100%', bgcolor: 'background.default',
            background:
                !isDarkMode
                    ? 'linear-gradient( 320deg, hsl(219deg 55% 69%) 0%, hsl(219deg 54% 70%) 1%, hsl(219deg 53% 71%) 1%, hsl(220deg 51% 72%) 2%, hsl(220deg 50% 73%) 3%, hsl(220deg 49% 74%) 3%, hsl(220deg 47% 75%) 4%, hsl(220deg 46% 76%) 5%, hsl(221deg 44% 77%) 6%, hsl(221deg 43% 78%) 8%, hsl(221deg 41% 79%) 9%, hsl(221deg 39% 79%) 11%, hsl(221deg 37% 80%) 13%, hsl(221deg 35% 81%) 15%, hsl(221deg 33% 82%) 17%, hsl(221deg 31% 83%) 20%, hsl(222deg 29% 84%) 23%, hsl(222deg 26% 84%) 27%, hsl(222deg 23% 85%) 31%, hsl(222deg 20% 86%) 36%, hsl(222deg 17% 87%) 42%, hsl(222deg 13% 87%) 50%, hsl(222deg 9% 88%) 60%, hsl(222deg 5% 89%) 75%, hsl(0deg 0% 89%) 100% )'
                    : 'linear-gradient(to top, #2b2f3a, #353946, #3f4353, #494d60, #53576d, #5a5d73, #616479, #696b7f, #707185, #77778b, #7e7d91, #858498)'
        }}>
            < ActionDialog
                open={openBackdrop}
                onClose={() => {
                    handleLogout();
                    setOpenBackdrop(false)
                }}
                title={t("crm.mustCreateOrganizationTitle")}
                buttonColor='secondary'
                buttonTitle={t("crm.create")}
                handleClick={() => handleSubmit()}
                disabled={organizationChartUpdateLoading || organizationCreateOwnLoading}
                loading={organizationChartUpdateLoading || organizationCreateOwnLoading}
                buttonCancelTitle={t("crm.logout")}
            >
                <Grid container spacing={2} sx={{ px: { xs: 1, lg: 5 } }}>
                    <Grid item xs={12} sx={{ marginTop: 0, paddingTop: 0 }} >
                        <Typography>
                            {t("crm.mustCreateOrganizationContent")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2} alignItems="stretch" sx={{ cursor: 'pointer' }}>
                            {/* <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.companyLogo")}*</InputLabel>
                                    <Box sx={{ width: 'calc(100% - 12px)', border: '1px dashed', borderColor: "primary.light", borderRadius: '16px', p: 1, flexGrow: 1 }}>
                                        <Stack direction="column" justifyContent="center" alignItems="center" gap={1} sx={{ width: '100%', height: '100%' }}>
                                            <Avatar
                                                sx={{ width: 50, height: 50, padding: 2, backgroundColor: "background.default" }}
                                                src={"https://cdn-icons-png.flaticon.com/128/9261/9261196.png"}
                                            />
                                            <Typography variant="body2"> {t("crm.uploadLogo")}</Typography>
                                        </Stack>
                                    </Box>
                                </Grid> */}
                            <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                                    <Grid item xs={12}>
                                        <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.companyName")}*</InputLabel>
                                        <TextField
                                            fullWidth
                                            name="input.name"
                                            value={(values as any).input.name}
                                            onChange={(e) => setFieldValue("input.name", e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                                            onBlur={handleBlur}
                                            error={!!((errors as any)?.input?.name && (touched as any)?.input?.name)}
                                            helperText={(touched as any)?.input?.name && (errors as any)?.input?.name}
                                        />
                                    </Grid>
                                    {/* <Grid item xs={12}>
                                            <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.companyEmail")}*</InputLabel>
                                            <TextField
                                                fullWidth
                                                name="input.name"
                                                value={(values as any).input.name}
                                                onChange={(e) => setFieldValue("input.email", e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                                                onBlur={handleBlur}
                                                error={!!((errors as any)?.input?.name && (touched as any)?.input?.name)}
                                                helperText={(touched as any)?.input?.name && (errors as any)?.input?.name}
                                            />
                                        </Grid> */}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>


                    {/* <Grid item xs={6} >
                            <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.companyPhone")}*</InputLabel>
                            <TextField
                                fullWidth
                                name="input.name"
                                value={(values as any).input.name}
                                onChange={(e) => setFieldValue("input.name", e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                                onBlur={handleBlur}
                                error={!!((errors as any)?.input?.name && (touched as any)?.input?.name)}
                                helperText={(touched as any)?.input?.name && (errors as any)?.input?.name}
                            />
                        </Grid>
                        <Grid item xs={6} >
                            <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.country")}*</InputLabel>
                            <TextField
                                fullWidth
                                name="input.name"
                                value={(values as any).input.name}
                                onChange={(e) => setFieldValue("input.name", e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                                onBlur={handleBlur}
                                error={!!((errors as any)?.input?.name && (touched as any)?.input?.name)}
                                helperText={(touched as any)?.input?.name && (errors as any)?.input?.name}
                            />
                        </Grid>
                        <Grid item xs={6} >
                            <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.city")}*</InputLabel>
                            <TextField
                                fullWidth
                                name="input.name"
                                value={(values as any).input.name}
                                onChange={(e) => setFieldValue("input.name", e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                                onBlur={handleBlur}
                                error={!!((errors as any)?.input?.name && (touched as any)?.input?.name)}
                                helperText={(touched as any)?.input?.name && (errors as any)?.input?.name}
                            />
                        </Grid>
                        <Grid item xs={6} >
                            <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.industry")}*</InputLabel>
                            <TextField
                                fullWidth
                                name="input.name"
                                value={(values as any).input.name}
                                onChange={(e) => setFieldValue("input.name", e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                                onBlur={handleBlur}
                                error={!!((errors as any)?.input?.name && (touched as any)?.input?.name)}
                                helperText={(touched as any)?.input?.name && (errors as any)?.input?.name}
                            />
                        </Grid>
                        <Grid item xs={6} >
                            <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.timezone")}*</InputLabel>
                            <TextField
                                fullWidth
                                name="input.name"
                                value={(values as any).input.name}
                                onChange={(e) => setFieldValue("input.name", e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                                onBlur={handleBlur}
                                error={!!((errors as any)?.input?.name && (touched as any)?.input?.name)}
                                helperText={(touched as any)?.input?.name && (errors as any)?.input?.name}
                            />
                        </Grid>
                        <Grid item xs={6} >
                            <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.language")}*</InputLabel>
                            <TextField
                                fullWidth
                                name="input.name"
                                value={(values as any).input.name}
                                onChange={(e) => setFieldValue("input.name", e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                                onBlur={handleBlur}
                                error={!!((errors as any)?.input?.name && (touched as any)?.input?.name)}
                                helperText={(touched as any)?.input?.name && (errors as any)?.input?.name}
                            />
                        </Grid> */}
                </Grid>
                {/* </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            handleLogout();
                            setOpenBackdrop(false);
                        }}
                    >
                        {t("crm.cancel")}
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        disabled={organizationChartUpdateLoading || organizationCreateOwnLoading}
                        onClick={() => {
                            handleSubmit();
                        }}
                    >
                        {organizationChartUpdateLoading || organizationCreateOwnLoading ? <CircularProgress size={"1rem"} /> : t("crm.create")}
                    </Button>

                </DialogActions>
            </Dialog> */}
            </ActionDialog >

            <Stack direction={"row"} sx={{ width: '100%' }}>

                {/* Drawer */}


                <ClickAwayListener onClickAway={() => {
                    if (treeMenuDisplay === "block" && downLg && (route === "crm" || route === "leadPlus" || route === "community" || route === "marketing" || route === "window"))
                        handleButtonClick("clickaway")
                }} >
                    <animated.div style={mainMenuStyle}>
                        <Stack direction="row" id={"main-menu"}
                            sx={{
                                p: { xs: "5px", lg: "20px" },
                                height: { xs: 'calc(100dvh - 10px)', lg: 'calc(100dvh - 40px)' },
                                gap: { xs: 1, lg: 2 }
                            }}>
                            {/* Route Menu */}
                            <Stack direction="column" id={"route-menu"} justifyContent={"space-between"} alignItems={"center"} gap={{ xs: 1, lg: 3 }} zIndex={11}>
                                <Stack direction="column" justifyContent={"flex-start"} alignItems={"center"} gap={{ xs: 1, lg: 3 }} my={{ xs: "5px", lg: "10px" }}>
                                    <Box
                                        sx={{ cursor: "pointer" }}
                                        onClick={() => {
                                            navigate("/")
                                            dispatch(setRoute("crm"))
                                        }}>
                                        <img src={LogoMini} alt="logo" style={{ width: downLg ? "30px" : "45px", height: downLg ? "30px" : '45px', marginBottom: downLg ? "5px" : '10px' }} />
                                    </Box>
                                    <Tooltip title="CRM">
                                        <Box
                                            sx={{
                                                width: downLg ? "30px" : "45px", height: downLg ? "30px" : "45px", borderRadius: "23px",
                                                display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer',
                                                bgcolor: route === "crm" ? "secondary.main" : "background.thirdWithBlur",
                                            }}
                                            onClick={() => handleButtonClick("crm")}>
                                            <Iconify icon={"gravity-ui:nodes-up"} width={downLg ? 16 : 24} sx={{ color: route === "crm" ? "text.white" : "text.primary" }} />
                                        </Box>
                                    </Tooltip>
                                    <Tooltip title="Lead +">
                                        <Box sx={{
                                            width: downLg ? "30px" : "45px", height: downLg ? "30px" : "45px", borderRadius: "23px",
                                            display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer',
                                            bgcolor: route === "leadPlus" ? "secondary.main" : "background.thirdWithBlur"
                                        }}
                                            onClick={() => handleButtonClick("leadPlus")}>
                                            <Iconify icon={"gravity-ui:circles-3-plus"} width={downLg ? 16 : 24} sx={{ color: route === "leadPlus" ? "text.white" : "text.primary" }} />
                                        </Box>
                                    </Tooltip>
                                    <Tooltip title="Marketing">
                                        <Box sx={{
                                            width: downLg ? "30px" : "45px", height: downLg ? "30px" : "45px", borderRadius: "23px",
                                            display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer',
                                            bgcolor: route === "marketing" ? "secondary.main" : "background.thirdWithBlur"
                                        }}
                                            onClick={() => handleButtonClick("marketing")}>
                                            <Iconify icon={"nimbus:marketing"} width={downLg ? 16 : 24} sx={{ color: route === "marketing" ? "text.white" : "text.primary" }} />
                                        </Box>
                                    </Tooltip>
                                    <Tooltip title="Window">
                                        <Box sx={{
                                            width: downLg ? "30px" : "45px", height: downLg ? "30px" : "45px", borderRadius: "23px",
                                            display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer',
                                            bgcolor: route === "window" ? "secondary.main" : "background.thirdWithBlur"
                                        }}
                                            onClick={() => handleButtonClick("window")}>
                                            <Iconify icon={"material-symbols:window-outline"} width={downLg ? 16 : 24} sx={{ color: route === "window" ? "text.white" : "text.primary" }} />
                                        </Box>
                                    </Tooltip>
                                    <Tooltip title="Community">
                                        <Box sx={{
                                            width: downLg ? "30px" : "45px", height: downLg ? "30px" : "45px", borderRadius: "23px",
                                            display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer',
                                            bgcolor: route === "community" ? "secondary.main" : "background.thirdWithBlur"
                                        }}
                                            onClick={() => handleButtonClick("community")}>
                                            <Iconify icon={"gravity-ui:persons-lock"} width={downLg ? 16 : 24} sx={{ color: route === "community" ? "text.white" : "text.primary" }} />
                                        </Box>
                                    </Tooltip>
                                </Stack>
                                <Stack direction="column" justifyContent={"flex-start"} alignItems={"center"} gap={{ xs: 1, lg: 3 }} mb={{ xs: "5px", lg: "0px" }}>
                                    <Tooltip title={status !== "active" ? "Disk (locked)" : "Disk"}>
                                        <Box
                                            onClick={() => status === "active" && handleButtonClick("disk")}
                                            sx={{
                                                width: downLg ? "30px" : "45px", height: downLg ? "30px" : "45px", borderRadius: "23px",
                                                bgcolor: "background.thirdWithBlur",
                                                cursor: 'pointer',
                                                position: 'relative',
                                                display: 'flex', justifyContent: 'center', alignItems: 'center'
                                            }}>
                                            <Iconify icon={"clarity:hard-disk-outline-badged"} width={downLg ? 16 : 24} sx={{ color: "text.primary" }} />
                                            {status !== "active" && <Lock fontSize='small' sx={{ position: 'absolute', right: -3, top: -8, color: 'secondary.main' }} />}
                                        </Box>
                                    </Tooltip>
                                    <Tooltip title="Settings">
                                        <Box
                                            onClick={() => {
                                                handleButtonClick("settings")
                                            }}
                                            sx={{
                                                cursor: 'pointer',
                                                width: downLg ? "30px" : "45px", height: downLg ? "30px" : "45px", borderRadius: "23px",
                                                bgcolor: route === "settings" ? "secondary.main" : "background.thirdWithBlur",
                                                display: 'flex', justifyContent: 'center', alignItems: 'center'
                                            }}>
                                            <Iconify icon={"gravity-ui:gear"} width={downLg ? 16 : 24} sx={{ color: route === "settings" ? "text.white" : "text.primary" }} />
                                        </Box>
                                    </Tooltip>
                                </Stack>
                            </Stack>

                            {/* Tree Menu */}

                            {treeItems?.length > 0 && < animated.div style={treeMenuStyle}>
                                <Stack direction="column" id={"tree-menu"} sx={{
                                    overflowY: 'auto', overflowX: 'hidden',
                                    pb: { xs: 5, lg: 0 },
                                    // bgcolor: 'background.default',

                                    bgcolor: 'rgba(255, 255, 255, 0)',
                                    // boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.1)',
                                    borderLeft: downLg ? "1px solid" : "none",
                                    borderColor: 'divider',
                                    borderLeftStyle: 'dashed',
                                    height: { xs: 'calc(100dvh - 50px)', lg: 'calc(100dvh - 40px)' },
                                }}>
                                    <Box mt={"20px"} mb={"30px"} pl={4} pr={2}>
                                        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                                            {!downLg && <Typography fontSize={"16px"} fontWeight={"bold"}>{data?.organization?.name}</Typography>}
                                            {!downLg && <Iconify icon={"tabler:layout-sidebar-left-collapse-filled"} width={24}
                                                onClick={() => handleButtonClick("close")} sx={{ cursor: 'pointer', color: 'text.primary' }} />}
                                            {downLg && <Iconify icon={"tabler:layout-sidebar-left-collapse-filled"} width={24}
                                                onClick={() => handleButtonClick("close")} sx={{ cursor: 'pointer', color: 'text.primary', position: 'absolute', right: 20 }} />}
                                        </Stack>
                                    </Box>
                                    {(status === "active" && !path.includes("settings")) ? <Stack direction="row" alignItems={"center"} gap={1} pl={1} sx={{ cursor: 'pointer', ml: { xs: 0, lg: 2 } }}>
                                        <Iconify icon="gravity-ui:star" width={16} sx={{ color: "text.secondary" }} />
                                        <Typography fontSize={"16px"} fontWeight={"semiBold"} sx={{ color: "text.secondary" }} >Starred</Typography>
                                    </Stack> : <Stack height={"20px"} />}
                                    {(status === "active" && !path.includes("settings")) ? <Stack direction="row" alignItems={"center"} gap={1} pl={1} mt={1} mb={0.5} sx={{ cursor: 'pointer', ml: { xs: 0, lg: 2 } }}>
                                        <Iconify icon="gravity-ui:arrow-rotate-left" width={16} sx={{ color: "text.secondary" }} />
                                        <Typography fontSize={"16px"} fontWeight={"semiBold"} sx={{ color: "text.secondary" }} >Recent</Typography>
                                    </Stack> : <Stack height={"20px"} />}

                                    <Box sx={{ minHeight: 352, minWidth: 250, ml: { xs: 0, lg: 2 } }}>
                                        <SimpleTreeView
                                            sx={{
                                                color: 'text.primary',
                                                '& .MuiTreeItem-content': {
                                                    paddingLeft: 0,
                                                },
                                                '& .MuiTreeItem-iconContainer': {
                                                    borderBottom: "1px solid #ababab",
                                                },
                                                '& .MuiTreeItem-root  > div:first-of-type > div:first-of-type': {
                                                    ml: 1.3,
                                                    color: '#787878',
                                                    width: "3px",
                                                    borderBottom: "0px !important",
                                                },
                                                '& .MuiCollapse-wrapper  .MuiTreeItem-root  > div:first-of-type > div:first-of-type': {
                                                    visibility: 'visible !important',
                                                    width: "10px",
                                                    borderBottom: "1px solid #ababab !important",
                                                    mr: 1,
                                                    ml: 0,
                                                },
                                            }}
                                            // defaultSelectedItems={leadDomainMenuItem.path}
                                            // defaultSelectedItems={selectedItems}
                                            selectedItems={selectedItems}
                                            defaultExpandedItems={expandedMenu ?? expandedItems}
                                        >
                                            {treeItems?.map((item: any) => (
                                                <CustomTreeItem key={item.itemId} itemId={item.itemId} label={item.label} onClick={() => {
                                                    if ('href' in item) {
                                                        navigate(item?.href || "/");
                                                    }
                                                }}>
                                                    {item?.children?.map((child: any) => (
                                                        <CustomTreeItem key={child.itemId} itemId={child.itemId} label={child.label} onClick={() => {
                                                            if ('href' in child) {
                                                                navigate(child?.href);
                                                            }
                                                        }} />
                                                    ))
                                                    }
                                                </CustomTreeItem>
                                            ))}
                                        </SimpleTreeView>

                                    </Box>
                                </Stack>
                            </animated.div>}

                        </Stack>
                    </animated.div>
                </ClickAwayListener >

                {/* Main */}
                < Stack direction="column" sx={{ width: '100%', mr: { xs: "5px", lg: "30px" } }}>

                    {/* Header*/}
                    < Stack direction="row" sx={{
                        height: { xs: "50px", lg: '100px' }, width: '100%', display: 'flex',
                        justifyContent: 'space-between', alignItems: 'center',
                    }}>

                        {/* Search Bar */}
                        {
                            !downLg && <Stack direction={"row"} gap={1} alignItems={"center"}>
                                <Stack direction={"row"} gap={1} alignItems={"center"}>
                                    {!isTreeVisible && <Iconify icon={"tabler:layout-sidebar-right-collapse-filled"} width={24}
                                        onClick={() => handleButtonClick(route)}
                                        sx={{ cursor: 'pointer', color: 'text.primary' }} />}
                                    {/* <CustomTextField
                                    variant="outlined"
                                    placeholder={t("crm.searchPlaceholder")}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Iconify icon={"mynaui:search"} width={24} />
                                            </InputAdornment>
                                        ),
                                    }}
                                /> */}
                                    <CustomTextField
                                        id="outlined-search"
                                        variant="outlined"
                                        placeholder={t("crm.searchPlaceholder")}
                                        sx={{
                                            bgcolor: 'background.thirdWithBlur',
                                            '& .MuiInputBase-input': {
                                                bgcolor: "#00000000",
                                            },
                                            '& .MuiOutlinedInput-root': {
                                                bgcolor: 'background.thirdWithBlur',
                                            },
                                        }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Iconify icon={"mynaui:search"} width={24} />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    {/* <Autocomplete
                                    options={[
                                        { label: 'Lead List', value: '1' },
                                        { label: 'Create Lead', value: '2' },
                                        { label: 'Settings', value: '3' },
                                        { label: 'Estimate List', value: '4' },
                                        { label: 'Estimate Create', value: '5' }
                                    ]}
                                    popupIcon={null}
                                    filterOptions={(options, state) =>
                                        options
                                            .filter(option => option.label.toLowerCase().includes(state.inputValue.toLowerCase()))
                                            .slice(0, 2)
                                    }
                                    getOptionLabel={(option) => option.label}
                                    renderInput={(params) => (
                                        <CustomTextField
                                            {...params}
                                            variant="outlined"
                                            placeholder={t("crm.searchPlaceholder")}
                                            sx={{
                                                bgcolor: 'background.thirdWithBlur',
                                                '& .MuiInputBase-input': {
                                                    bgcolor: "#00000000",
                                                },
                                                '& .MuiOutlinedInput-root': {
                                                    bgcolor: 'background.thirdWithBlur',
                                                },
                                            }}
                                            InputProps={{
                                                ...params.InputProps,
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Iconify icon={"mynaui:search"} width={24} />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    )}
                                /> */}
                                </Stack>
                                {organizationDetailOwn?.activeSubscriptions?.trial && <Stack pt={1} direction={"row"} alignItems={"center"} gap={1}>
                                    <Typography fontSize={"14px"}
                                        fontWeight={600}
                                        color={"secondary.main"}>
                                        {t("expiresIn")}{moment(organizationDetailOwn?.activeSubscriptions?.trial?.dueDate).diff(moment(), 'days')}{t("expiresDays")}
                                    </Typography>
                                    <ToggleButton
                                        title={t("myPlan")}
                                        // color='secondary'
                                        onClick={() => {
                                            dispatch(setRoute("settings"))
                                            navigate(baseSettingsRoute() + "payment")
                                        }}

                                    />
                                </Stack>}
                            </Stack>

                        }

                        {
                            downLg && <Stack direction={"row"} alignItems={"center"} ml={1} gap={1} position={"relative"}>
                                {downLg && <Iconify icon={"tabler:layout-sidebar-right-collapse-filled"} width={24}
                                    onClick={() => handleButtonClick(route)}
                                    sx={{ cursor: 'pointer', color: 'text.primary' }} />}
                                <Typography fontSize={"12px"} fontWeight={"bold"}>My Company</Typography>
                                {/* <Iconify icon={"gravity-ui:layout-split-columns"} width={12} sx={{ bgcolor: '#fff', p: "4px", position: 'absolute', bottom:-30, borderRadius: '6px' }} /> */}
                            </Stack>
                        }

                        {/* Action Buttons */}
                        <Stack direction={"row"} gap={{ xs: 1, lg: 2 }} sx={{ mr: { xs: 1, lg: 0 } }}>
                            {downLg && <Box sx={{
                                width: "30px", height: "30px", borderRadius: "15px",
                                display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer',
                                bgcolor: 'background.thirdWithBlur'
                            }}>
                                <Iconify icon={"mynaui:search"} width={16} sx={{ color: "text.primary" }} />
                            </Box>}


                            <Tooltip title="Account Menu">
                                <Stack direction={"row"} gap={{ xs: 1, lg: 2 }} bgcolor={"background.thirdWithBlur"}
                                    borderRadius={"23px"} px={"10px"}
                                    onClick={handleClickAccountMenu}
                                    aria-controls={openAccountMenu ? 'account-menu' : undefined}
                                    aria-expanded={openAccountMenu ? 'true' : undefined}
                                    sx={{ cursor: 'pointer' }}
                                    alignItems={"center"} justifyContent={"center"}>
                                    <Iconify icon={"gravity-ui:bars"} width={downLg ? 12 : 18} sx={{ color: "text.primary" }} />
                                    <Avatar src={data?.profileImage?.thumbnailPublicUrl} sx={{ width: downLg ? '15px' : '30px', height: downLg ? '15px' : '30px', borderRadius: "15px" }} />
                                </Stack>
                            </Tooltip>


                            <Menu
                                anchorEl={anchorElAccountMenu}
                                id={t("crm.accountMenu")}
                                open={openAccountMenu}
                                onClose={handleCloseAccountMenu}
                                slotProps={{
                                    paper: {
                                        elevation: 0,
                                        sx: {
                                            overflow: 'visible',
                                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                            borderRadius: '15px',
                                            mt: 1.5,
                                            minWidth: "200px",
                                            '& .MuiAvatar-root': {
                                                width: 32,
                                                height: 32,
                                                ml: -0.5,
                                                mr: 1,
                                            },
                                            '&::before': {
                                                content: '""',
                                                display: 'block',
                                                position: 'absolute',
                                                top: 0,
                                                right: 20,
                                                width: 10,
                                                height: 10,
                                                bgcolor: 'background.paper',
                                                transform: 'translateY(-50%) rotate(45deg)',
                                                zIndex: 0,
                                            },
                                        },
                                    },
                                }}
                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                            >
                                <MenuItem onClick={() => {
                                    navigate(baseAccountRoute());
                                    dispatch(setRoute("account"))
                                }} sx={{ fontSize: "14px" }}>
                                    <Avatar src={data?.profileImage?.thumbnailPublicUrl} sx={{ width: downLg ? '15px' : '30px', height: downLg ? '15px' : '30px', borderRadius: "15px" }} />
                                    {t("crm.myAccount")}
                                </MenuItem>
                                <Divider />

                                <MenuItem sx={{ fontSize: "14px" }} onClick={() => toggleDarkMode(!isDarkMode)}>
                                    <MuiToggleButton
                                        value="check"
                                        selected={isDarkMode}
                                        size="small"
                                        fullWidth
                                        onChange={() => toggleDarkMode(!isDarkMode)}
                                        sx={{ borderRadius: "12px", height: "37px", borderColor: '#C4C4C4 !important' }}
                                    >
                                        <Stack direction={"row"} gap={1} alignItems={"center"} sx={{ width: '100%' }} justifyContent={"flex-start"}>
                                            <DarkModeSwitch checked={isDarkMode} size={20} onChange={toggleDarkMode} />
                                            {isDarkMode ? t("crm.darkMode") : t("crm.lightMode")}
                                        </Stack>
                                    </MuiToggleButton>
                                </MenuItem>
                                <MenuItem>
                                    <FormControl fullWidth>
                                        <Select
                                            labelId={t("crm.languageSelectLabel")}
                                            id="demo-simple-select"
                                            value={language}
                                            onChange={(e) => {
                                                setLanguage(e.target.value as Language);
                                                changeLanguage(e.target.value as Language);
                                            }}
                                            size="small"
                                            sx={{
                                                fontSize: "14px",
                                                borderRadius: "12px",
                                                fontWeight: 600,
                                                bgcolor: 'background.thirdWithBlur',
                                            }}
                                        >
                                            <MenuItem value={"en"} sx={{ fontSize: "14px" }}>
                                                <Stack direction={"row"} gap={1} alignItems={"center"}>
                                                    <Iconify icon={"circle-flags:uk"} sx={{ fontSize: "14px" }} />
                                                    {t("crm.english")}
                                                </Stack>
                                            </MenuItem>
                                            <MenuItem value={"tr"} sx={{ fontSize: "14px" }}>
                                                <Stack direction={"row"} gap={1} alignItems={"center"}>
                                                    <Iconify icon={"circle-flags:tr"} sx={{ fontSize: "14px" }} />
                                                    {t("crm.turkish")}
                                                </Stack>
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </MenuItem>
                                <MenuItem onClick={() => {
                                    navigate(baseSettingsRoute());
                                    dispatch(setRoute("settings"))
                                }}
                                    sx={{ fontSize: "14px" }}>
                                    <ListItemIcon>
                                        <Settings fontSize="small" />
                                    </ListItemIcon>
                                    {t("crm.settings")}
                                </MenuItem>
                                <MenuItem onClick={handleLogout} sx={{ fontSize: "14px" }}>
                                    <ListItemIcon>
                                        <Logout fontSize="small" />
                                    </ListItemIcon>
                                    {t("crm.logout")}
                                </MenuItem>
                            </Menu>

                            <Tooltip title="Notification">
                                <Badge badgeContent={countResult?.count ?? 0} color="secondary" anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
                                    <Box sx={{
                                        width: { xs: "30px", lg: "45px" }, height: { xs: "30px", lg: "45px" }, borderRadius: "23px",
                                        display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer',
                                        bgcolor: "background.thirdWithBlur"
                                    }}
                                        onClick={() => {
                                            setNotificationDrawerOpen(true)
                                        }}>
                                        <Iconify icon={"solar:bell-bold-duotone"} width={downLg ? 16 : 24} sx={{ color: "secondary.main" }} />
                                    </Box>
                                </Badge>
                            </Tooltip>

                            {status === "active" && <Tooltip title="New Task">
                                <Box sx={{
                                    width: { xs: "30px", lg: "45px" }, height: { xs: "30px", lg: "45px" }, borderRadius: "23px",
                                    display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer',
                                    bgcolor: "secondary.main"
                                }}
                                    onClick={() => {
                                        dispatch(setRoute("leadPlus"));
                                        navigate(baseLeadPlusRoute())
                                    }}>
                                    <Iconify icon={"gravity-ui:plus"} width={downLg ? 16 : 24} sx={{ color: "#FFF" }} />
                                </Box>
                            </Tooltip>}

                        </Stack>

                    </Stack >

                    {/* Content */}

                    <animated.div style={contentStyle} >
                        {/* <Stack
                            sx={{
                                // background:
                                //     !isDarkMode
                                //         ? 'linear-gradient(to top, #afbedb, #b9c3db, #c3c7da, #cbcdda, #d2d2da, #d5d5da, #d8d7da, #dadada, #dadada, #dadada, #dadada, #dadada)'
                                //         : 'linear-gradient(to top, #2b2f3a, #353946, #3f4353, #494d60, #53576d, #5a5d73, #616479, #696b7f, #707185, #77778b, #7e7d91, #858498)'
                                bgcolor: '#fbfbfb'
                            }}> */}
                        <Stack direction="row"
                            sx={{
                                bgcolor: 'background.secondWithBlur',
                                backdropFilter: 'blur(1px)',
                                boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.1)',
                                overflow: "hidden", borderRadius: "30px",
                                height: { xs: 'calc(100dvh - 60px)', lg: 'calc(100dvh - 120px)' },
                                zIndex: 0
                            }}>
                            <Box width={"100%"} sx={{ borderRadius: "30px" }} overflow={"auto"} p={{ xs: 2, lg: 3 }}>
                                {data?.organization && children}
                            </Box>
                        </Stack>
                        {/* </Stack> */}
                    </animated.div >

                </Stack >

            </Stack >

            <NotificationDrawerWrapper
                open={notificationDrawerOpen}
                setOpen={setNotificationDrawerOpen}
            />
        </Box >
    );
}

export default MainLayout;


