const locale = {
  notes: "Notlar",
  createNote: "Not Oluştur",
  title: "Başlık",
  content: "İçerik",
  addNote: "Not Ekle",
  priority: "Öncelik",
  noteDate: "Hatırlatma Tarihi",
  noteUsers: "Bildirilecek Kullanıcılar",
  tags: "Etiketler",
  tagsPlaceholder: "Lütfen etiket ekleyin ve ardından Enter'a basın",
  createNoteSuccess: "Not oluşturuldu",
  target: "Hedef",
  updateNote: "Not Güncelle",
  deleteNote: "Not Sil",
  deletedSuccessfully: "Not silindi",
  deleteNoteContent: "Bu notu silmek istediğinizden emin misiniz? Bu işlem geri alınamaz.",
  note: "Not",
  noteConversions: "Not Dönüştürme",
  emptyStateDesc1: "Henüz not eklenmedi.",
  emptyStateDesc2: "CRM'nizdeki önemli detayları ve konuşmaları takip etmek için notlarınızı burada oluşturun ve düzenleyin.",
  updateNoteSuccess: "Not başarıyla güncellendi.",
};

export default locale;

