import {
  // queries
  // IPermissionListInput, IPermissionListRequest, IPermissionListResponse, IPermissionListResult, permissionListQuery,

  IProposalListInput,
  IProposalListRequest,
  IProposalListResponse,
  IProposalListResult,
  proposalListQuery,
  IProposalDetailInput,
  IProposalDetailRequest,
  IProposalDetailResponse,
  IProposalDetailResult,
  proposalDetailQuery,

  // mutations
  IProposalCreateInput,
  IProposalCreateRequest,
  IProposalCreateResponse,
  IProposalCreateResult,
  proposalCreateQuery,
  IProposalUpdateInput,
  IProposalUpdateRequest,
  IProposalUpdateResponse,
  IProposalUpdateResult,
  proposalUpdateQuery,
  IProposalUpdateFilterInput,
  IProposalDeleteInput,
  IProposalDeleteRequest,
  IProposalDeleteResponse,
  IProposalDeleteResult,
  proposalDeleteQuery,
} from "corede-common-cocrm";
import { commonApi } from "context/commonApi";
import { EndpointQueryBuilder } from "apps/helper/endpoint-query.builder";
import { proposalsTags } from "./proposals.tags";
import { IGraphqlVariables } from "corede-common";

export const proposalsApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({
    // proposalsTags: builder.query<
    //     IProposalTagsResult,
    //     IGraphqlVariables<IProposalTagsFilterInput>
    // >(
    //     EndpointQueryBuilder.BuildGraphqlQuery<
    //         IProposalTagsRequest,
    //         IProposalTagsResponse,
    //         IProposalTagsResult,
    //         IProposalTagsFilterInput,
    //     >({
    //         query: proposalsTagsQuery,
    //         providesTags: [proposalsTags.proposalsTags],
    //     })),

    proposalList: builder.query<
      IProposalListResult,
      IGraphqlVariables<IProposalListInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IProposalListRequest,
        IProposalListResponse,
        IProposalListResult,
        IProposalListInput
      >({
        query: proposalListQuery,
        providesTags: [proposalsTags.proposal, proposalsTags.proposals],
      })
    ),

    proposalDetail: builder.query<
      IProposalDetailResult,
      IGraphqlVariables<IProposalDetailInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IProposalDetailRequest,
        IProposalDetailResponse,
        IProposalDetailResult,
        IProposalDetailInput
      >({
        query: proposalDetailQuery,
        providesTags: [proposalsTags.proposal, proposalsTags.proposals],
      })
    ),

    // mutations

    proposalCreate: builder.mutation<
      IProposalCreateResult,
      IGraphqlVariables<IProposalCreateInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IProposalCreateRequest,
        IProposalCreateResponse,
        IProposalCreateResult,
        IProposalCreateInput
      >({
        query: proposalCreateQuery,
        invalidatesTags: [proposalsTags.proposal, proposalsTags.proposals],
      })
    ),

    proposalUpdate: builder.mutation<
      IProposalUpdateResult,
      IGraphqlVariables<IProposalUpdateInput, IProposalUpdateFilterInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IProposalUpdateRequest,
        IProposalUpdateResponse,
        IProposalUpdateResult,
        IProposalUpdateInput,
        IProposalUpdateFilterInput
      >({
        query: proposalUpdateQuery,
        invalidatesTags: [proposalsTags.proposal, proposalsTags.proposals],
      })
    ),

    proposalDelete: builder.mutation<
      IProposalDeleteResult,
      IGraphqlVariables<IProposalDeleteInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IProposalDeleteRequest,
        IProposalDeleteResponse,
        IProposalDeleteResult,
        IProposalDeleteInput
      >({
        query: proposalDeleteQuery,
        invalidatesTags: [proposalsTags.proposal, proposalsTags.proposals],
      })
    ),
  }),
});

export const {
  useProposalListQuery,
  useLazyProposalListQuery,
  useProposalDetailQuery,
  useLazyProposalDetailQuery,
  useProposalCreateMutation,
  useProposalUpdateMutation,
  useProposalDeleteMutation,
} = proposalsApi;
