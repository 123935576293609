/* eslint-disable react-hooks/exhaustive-deps */
import { Box, SwipeableDrawer } from "@mui/material";
import List from "./List";
import { memo } from "react";


interface IRolesDrawer {
  open: boolean
  setOpen: (open: boolean) => void
}

export const RolesDrawer = memo((props: IRolesDrawer) => {

  return <SwipeableDrawer
    open={props.open}
    onOpen={() => props.setOpen(true)}
    onClose={() => props.setOpen(false)}
    anchor='right'
    sx={{

      zIndex: 1201

    }}
  >
    <Box sx={{ width: 740 }}>
      <List />
    </Box>
  </SwipeableDrawer>
});

