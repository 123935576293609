import proposalsLocale from "../subdomains/proposals/locales/en";
import estimatesLocale from "../subdomains/estimates/locales/en";
import legalAgreementsLocale from "../subdomains/legalAgreement/locales/en";

const locale = {
  estimates: {
    ...estimatesLocale,
  },
  proposals: {
    ...proposalsLocale,
  },
  legalAgreements: {
    ...legalAgreementsLocale,
  },
};

export default locale;
