/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import { useEffect } from "react";
import { useEstimateDeleteMutation } from "../context/estimates.api";
import { IBaseError } from "corede-common"
import { enqueueSnackbar } from "notistack";
import { getCurrentLanguage } from "localization";
import { DefaultErrorHandlerUseEffect } from "utils/useEffect.helper";
import { IBaseEstimateEntity } from "corede-common-cocrm";
import { useTranslation } from "react-i18next";
import ActionDialog from "components/dialog/ActionDialog";

const Delete = (prop: {
  open: boolean;
  item: IBaseEstimateEntity;
  onClose: () => void;
}) => {
  // general
  const currentLanguage = getCurrentLanguage();
  const { t } = useTranslation();

  // queries

  // mutations
  const [estimateStatusDelete, { data: estimateStatusDeleteData, isLoading: estimateStatusDeleteLoading, error: estimateStatusDeleteError }] = useEstimateDeleteMutation();

  // constants

  // action handlers
  const handleDelete = (_id: string) => {
    estimateStatusDelete({ input: { _id: _id } });
  };

  // useEffects.success
  useEffect(() => {
    if (estimateStatusDeleteData) {
      prop?.onClose();
      enqueueSnackbar(t("crm.preSales.estimates.deletedSuccessfully"), {
        variant: "success",
      });
    }
  }, [estimateStatusDeleteData]);

  // useEffects.error
  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      estimateStatusDeleteError as IBaseError,
      currentLanguage
    );
  }, [estimateStatusDeleteError, currentLanguage]);

  // useEffects.init

  return (
    <ActionDialog
      open={prop?.open}
      onClose={prop?.onClose}
      title={t("crm.preSales.estimates.delete")}
      buttonTitle={t("delete")}
      buttonColor="error"
      handleClick={() => handleDelete(prop?.item?._id as string)}
      disabled={estimateStatusDeleteLoading}
      loading={estimateStatusDeleteLoading}
    >
      {t("crm.preSales.estimates.deleteConfirm")} {prop?.item?.prefix}?
    </ActionDialog >
  );
};

export default Delete;
