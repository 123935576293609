/* eslint-disable react-hooks/exhaustive-deps */
import { ArrowDownward, ArrowUpward, InfoOutlined } from '@mui/icons-material';
import { SwipeableDrawer, Box, Stack, Avatar, Typography, Divider, Grid, IconButton, Skeleton, Chip, Checkbox, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { MyTab } from 'components/tab';
import { useLazyUserDetailQuery } from 'apps/auth/context';
import moment from 'moment';
import { memo, useEffect, useState } from 'react';
import { usePermissionListQuery, useRoleListQuery } from '../../permissions';
import { useAppSelector } from 'context';
import { enqueueSnackbar } from 'notistack';
import { getCurrentLanguage, getTranslatedEnumValue } from 'localization';
import { unknownError } from 'corede-common';
import { useTranslation } from 'react-i18next';
import { OutlinedButton } from 'components';
import { viewUsersRoute } from '../../../routes/organizationalChart.base.route';
import { useNavigate } from 'react-router-dom';

interface IUserDetailDrawer {
    open: boolean;
    setOpen: (open: boolean) => void;
    selectedUser: any;
    handleSelect: any;
}

interface Permission {
    _id: string;
    action: string;
    subject: string;
    actionScope: string;
    description: string;
}

const groupBySubject = (permissions: Permission[]) => {
    return permissions.reduce((acc, permission) => {
        if (!acc[permission.subject]) {
            acc[permission.subject] = [];
        }
        acc[permission.subject].push(permission);
        return acc;
    }, {} as Record<string, Permission[]>);
};

const UserDetailDrawer = memo((props: IUserDetailDrawer) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const currentLanguage = getCurrentLanguage();
    const { userId } = useAppSelector(state => state.auth);
    const [selectedTab, setSelectedTab] = useState(t("crm.organizationalChart.users.details"));
    const { data: roleListData, error: roleListError } = useRoleListQuery({});
    const [lazyUserDetail, { data: userDetailData, isLoading: userDetailLoading, isFetching: userDetailFetching, error: userDetailError }] = useLazyUserDetailQuery();
    const { data: permissionListData, error: permissionListError } = usePermissionListQuery({});
    const permissions = permissionListData?.data || [];
    const groupedPermissions = groupBySubject(permissions as Permission[]);
    const [selectedPermissionIds, setSelectedPermissionIds] = useState<string[]>([]);

    useEffect(() => {
        if (props?.selectedUser?._id) {
            lazyUserDetail({
                input: {
                    _id: props?.selectedUser?._id || ""
                }
            });
        }
    }, [props?.selectedUser, lazyUserDetail]);

    useEffect(() => {
        if (userDetailData && roleListData) {
            setSelectedPermissionIds(roleListData?.data?.find((role) => role._id === userDetailData?.roles?.[0]?._id)?.permissions.map((permission) => permission._id) ?? []);
        }
    }, [userDetailData, roleListData]);

    useEffect(() => {
        if (permissionListError) {
            enqueueSnackbar((permissionListError as any)?.error?.message[currentLanguage] ?? unknownError.message[currentLanguage], { variant: 'error' });
        }
    }, [permissionListError])

    useEffect(() => {
        if (userDetailError) {
            enqueueSnackbar((userDetailError as any)?.error?.message[currentLanguage] ?? unknownError.message[currentLanguage], { variant: 'error' });
        }
    }, [userDetailError])

    useEffect(() => {
        if (roleListError) {
            enqueueSnackbar((roleListError as any)?.error?.message[currentLanguage] ?? unknownError.message[currentLanguage], { variant: 'error' });
        }
    }, [roleListError])

    return (
        <SwipeableDrawer
            open={props.open}
            onOpen={() => props.setOpen(true)}
            onClose={() => props.setOpen(false)}
            anchor="right"
            sx={{ zIndex: 1249 }}
        >
            <Stack direction="column"
                sx={{
                    position: "fixed",
                    bgcolor: "background.primary",
                    borderRadius: 10,
                    top: "10px",
                    right: "410px",
                    zIndex: 1450
                }}
            >
                <IconButton size="small" sx={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }} onClick={() => props.handleSelect("back")}>
                    <ArrowUpward />
                </IconButton>
                <Divider sx={{ width: '100%' }} />
                <IconButton size="small" sx={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }} onClick={() => props.handleSelect("next")}>
                    <ArrowDownward />
                </IconButton>
            </Stack>
            <Box py={5} px={3} minWidth={350} bgcolor={"background.primary"} height={"100dvh"}>
                <Stack direction="column" gap={1} alignItems="center">
                    {(userDetailLoading || userDetailFetching) ? <Skeleton variant="circular" width={100} height={100} /> :
                        <Avatar
                            alt={userDetailData?.name}
                            src={userDetailData?.profileImage?.thumbnailPublicUrl}
                            sx={{ width: 100, height: 100, mb: 2 }}
                        />}
                    {(userDetailLoading || userDetailFetching) ? <Skeleton variant="text" width={"80%"} height={40} /> :
                        <Typography sx={{ fontWeight: "bold", fontSize: "24px", color: "primary.main" }}>
                            {userDetailData?.name} {userDetailData?.surname}
                        </Typography>}
                    {(userDetailLoading || userDetailFetching) ? <Skeleton variant="text" width={"70%"} height={20} /> :
                        <Typography sx={{ fontWeight: 400, fontSize: "14px", color: "primary.main" }}>
                            {(userDetailData as any)?.department?.name}
                        </Typography>}
                    {(userDetailLoading || userDetailFetching) ? <Skeleton width={"30%"} variant='text' height={20} /> :
                        <Chip variant='filled' label={userDetailData?.status && (userDetailData?.status?.charAt(0).toUpperCase() + userDetailData?.status?.slice(1))} size="small" sx={{ fontSize: "12px", p: 1, fontWeight: "bold" }} color={userDetailData?.status === "active" ? "secondary" : "info"} />}
                    <OutlinedButton
                        title={t("crm.organizationalChart.users.userDetail")}
                        onClick={() => navigate(viewUsersRoute(userDetailData?._id ?? ""))}
                        leftIcon={<InfoOutlined sx={{ mr: 1 }} />}
                    />
                    <Stack direction={"column"} gap={0} width={"100%"} borderTop={"1px solid #E0E0E0"} pt={2}>
                        {/* <MyTab
                                setSelectedTab={setSelectedTab}
                                labels={[t("crm.organizationalChart.users.details"), t("crm.organizationalChart.users.rolesAndPermissions")]}
                            /> */}
                        {/* {selectedTab === t("crm.organizationalChart.users.details") &&  */}
                        <Grid container sx={{ mt: 2 }}>
                            <Grid item xs={4} display="flex" flexDirection="column" gap={1}>
                                {[
                                    t("crm.organizationalChart.users.email"),
                                    t("crm.organizationalChart.users.phoneNumber"),
                                    t("crm.organizationalChart.users.country"),
                                    t("crm.organizationalChart.users.city"),
                                    t("crm.organizationalChart.users.address"),
                                    t("crm.organizationalChart.users.birthDate"),
                                    t("crm.organizationalChart.users.gender"),
                                    t("crm.organizationalChart.users.language"),
                                    t("crm.organizationalChart.users.description"),
                                    t("crm.organizationalChart.users.lastLoginDate"),
                                ].map((label) => (
                                    <Box display="flex" alignItems="center" minHeight={35} key={label}>
                                        <Typography fontSize={"14px"} fontWeight={600}>
                                            {label}
                                        </Typography>
                                    </Box>
                                ))}
                            </Grid>

                            <Grid item xs={8} display="flex" flexDirection="column" gap={1}>
                                {[
                                    userDetailData?.email,
                                    (userDetailData as any)?.phoneNumber,
                                    userDetailData?.country,
                                    userDetailData?.city,
                                    userDetailData?.address,
                                    userDetailData?.birthDate ? moment(userDetailData?.birthDate).format("DD/MM/YYYY") : "",
                                    getTranslatedEnumValue(userDetailData?.gender ?? ""),
                                    userDetailData?.language,
                                    userDetailData?.description,
                                    moment((userDetailData as any)?.lastLoginDate).format("DD/MM/YYYY"),
                                ].map((value, index) => (
                                    <Box display="flex" alignItems="center" minHeight={35} key={index}>
                                        <Typography fontSize={"14px"} fontWeight={400}>
                                            : {value}
                                        </Typography>
                                    </Box>
                                ))}
                            </Grid>
                        </Grid>
                        {/* } */}
                        {/* {selectedTab === t("crm.organizationalChart.users.rolesAndPermissions") && <Grid container sx={{ mt: 2 }}>
                                {userId !== userDetailData?._id ? <TableContainer
                                    sx={{
                                        borderRadius: 2,
                                        mt: 1
                                    }}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{
                                                    borderRight: "1px solid",
                                                    borderColor: "primary.light",
                                                }}>{t("crm.organizationalChart.users.subject")}</TableCell>
                                                <TableCell>{t("crm.organizationalChart.users.capabilities")}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {Object.keys(groupedPermissions).map((subject) => {
                                                const allSelected = groupedPermissions[subject].every((permission) =>
                                                    selectedPermissionIds.includes(permission._id)
                                                );
                                                const someSelected = groupedPermissions[subject].some((permission) =>
                                                    selectedPermissionIds.includes(permission._id)
                                                );
                                                return (
                                                    <TableRow key={subject}>
                                                        <TableCell sx={{
                                                            borderRight: "1px solid",
                                                            borderColor: "primary.light",
                                                        }}>{(userDetailLoading || userDetailFetching) ? <Skeleton width={100} variant='text' height={10} /> :
                                                            subject === 'all' ? t("crm.organizationalChart.users.allPermissions") : subject}
                                                        </TableCell>
                                                        <TableCell>
                                                            {(userDetailLoading || userDetailFetching) ? <Skeleton width={130} variant='text' height={25} /> :
                                                                <Box display="flex" alignItems="center">
                                                                    <Checkbox
                                                                        size="small"
                                                                        checked={allSelected}
                                                                        indeterminate={someSelected && !allSelected}
                                                                        onChange={(e) => {
                                                                            const isChecked = e.target.checked;
                                                                            const subjectPermissions = groupedPermissions[subject].map(
                                                                                (permission) => permission._id
                                                                            );
                                                                            if (isChecked) {
                                                                                setSelectedPermissionIds((prevSelectedIds) => [
                                                                                    ...prevSelectedIds,
                                                                                    ...subjectPermissions.filter((id) => !prevSelectedIds.includes(id)),
                                                                                ]);
                                                                            } else {
                                                                                setSelectedPermissionIds((prevSelectedIds) =>
                                                                                    prevSelectedIds.filter((id) => !subjectPermissions.includes(id))
                                                                                );
                                                                            }
                                                                        }}
                                                                    />
                                                                    <Typography>{t("crm.organizationalChart.users.all")}</Typography>
                                                                </Box>}
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer> :
                                    <Typography>{t("crm.organizationalChart.users.ownPermissionsAlert")}</Typography>}
                            </Grid>} */}
                    </Stack>
                </Stack>
            </Box>
        </SwipeableDrawer >
    );
});

export default memo(UserDetailDrawer)