import { IconButton, Menu, Stack } from "@mui/material";
import {
  IBaseGridColumnActions,
  IBaseGridColumnActionsParams,
} from "../infra/IBaseGridColumnActionsParams";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import {
  GridColumnDeleteActionDef,
  GridColumnEditActionDef,
  GridColumnViewActionDef,
} from "./BaseGridActionColumnComponentsDef";
import { IColumnActionConfig } from "../infra/IBaseGridColumnActionComponentsParams";
import { TGridColDefCell } from "../infra/types";
import React from "react";
import { MoreVert } from "@mui/icons-material";

export default function BasicMenu(props: {
  params: IBaseGridColumnActionsParams;
  renderCellParams: GridRenderCellParams;
}) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={(event) => handleClick(event as any)}
        sx={{
          cursor: "pointer",
          opacity: 0.7,
          ":hover": { opacity: 1 },
          mt: -1,
        }}
      >
        <MoreVert />
      </IconButton>
      <Menu
        id="action-menu"
        anchorEl={anchorEl}
        open={open}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={handleClose}
        onClick={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        sx={{
          "& .MuiPaper-root": {
            bgcolor: "background.secondWithBlur",
            backdropFilter: "blur(3px)",
          },
        }}
      >
        {getGridActions({
          renderCellCallBackParams: props.renderCellParams,
          defaultActions: props.params.defaultActions,
          customActions: props.params.customActions,
          customCellItems: props.params.customCellItems,
        })}
      </Menu>
    </div>
  );
}

export const generateBaseGridColumnActions = (
  params?: IBaseGridColumnActionsParams
): GridColDef | undefined => {
  if (!params) {
    return undefined;
  }

  return {
    type: "string",
    field: "actions",
    headerName: params.actionHeaderName ?? "Actions",
    editable: false,
    filterable: false,
    sortable: false,
    width: params.width ?? 120,
    renderCell: (renderCellParams: GridRenderCellParams) => (
      <Stack
        direction={{ xs: "column" }}
        alignItems={"center"}
        justifyContent={"center"}
        mt={{ xs: 0, md: 1 }}
      >
        <BasicMenu params={params} renderCellParams={renderCellParams} />
      </Stack>
    ),
  };
};

function getGridActions(
  params: IBaseGridColumnActionsParams & {
    renderCellCallBackParams: GridRenderCellParams;
  }
): JSX.Element[] {
  return [
    ...getDefaultGridActions({
      renderCellCallBackParams: params.renderCellCallBackParams,
      defaultActions: params.defaultActions,
    }),
    ...getCustomGridActions({
      renderCellCallBackParams: params.renderCellCallBackParams,
      customActions: params.customActions,
    }),
    ...getCustomGridCellItems({
      renderCellCallBackParams: params.renderCellCallBackParams,
      customCellItems: params.customCellItems,
    }),
  ];
}

function getDefaultGridActions(params: {
  renderCellCallBackParams: GridRenderCellParams;
  defaultActions: IBaseGridColumnActions;
}): JSX.Element[] {
  const actionComponents: JSX.Element[] = [];
  if (params.defaultActions.view) {
    let config = params.defaultActions?.view?.config;

    if (config && config.setDisableFunc) {
      config.disabled = config.setDisableFunc(params.renderCellCallBackParams);
    }

    actionComponents.push(
      GridColumnViewActionDef({
        config: params.defaultActions.view.config,
        clickConfig: {
          setOpenAction: params.defaultActions.view.clickConfig.setOpenAction,
          setSelectedRow: params.defaultActions.view.clickConfig.setSelectedRow,
        },
        renderCellCallBackParams: params.renderCellCallBackParams,
      })
    );
  }

  if (params.defaultActions.edit) {
    let config = params.defaultActions?.edit?.config;

    if (config && config.setDisableFunc) {
      config.disabled = config.setDisableFunc(params.renderCellCallBackParams);
    }

    actionComponents.push(
      GridColumnEditActionDef({
        config: params.defaultActions.edit.config,
        clickConfig: {
          setOpenAction: params.defaultActions.edit.clickConfig.setOpenAction,
          setSelectedRow: params.defaultActions.edit.clickConfig.setSelectedRow,
        },
        renderCellCallBackParams: params.renderCellCallBackParams,
      })
    );
  }

  if (params.defaultActions.delete) {
    let config = params.defaultActions?.delete?.config;

    if (config && config.setDisableFunc) {
      config.disabled = config.setDisableFunc(params.renderCellCallBackParams);
    }

    actionComponents.push(
      GridColumnDeleteActionDef({
        config: params.defaultActions.delete.config,
        clickConfig: {
          setOpenAction: params.defaultActions.delete.clickConfig.setOpenAction,
          setSelectedRow:
            params.defaultActions.delete.clickConfig.setSelectedRow,
        },
        renderCellCallBackParams: params.renderCellCallBackParams,
      })
    );
  }

  return actionComponents;
}

function getCustomGridActions(params: {
  renderCellCallBackParams: GridRenderCellParams;
  customActions?: IColumnActionConfig[];
}): JSX.Element[] {
  const actionComponents: JSX.Element[] = [];
  params.customActions?.forEach((action) => {
    actionComponents.push(
      GridColumnViewActionDef({
        config: action.config,
        clickConfig: {
          setOpenAction: action.clickConfig.setOpenAction,
          setSelectedRow: action.clickConfig.setSelectedRow,
        },
        renderCellCallBackParams: params.renderCellCallBackParams,
      })
    );
  });

  return actionComponents;
}

function getCustomGridCellItems(params: {
  renderCellCallBackParams: GridRenderCellParams;
  customCellItems?: TGridColDefCell[];
}): JSX.Element[] {
  const actionComponents: JSX.Element[] = [];
  params.customCellItems?.forEach((item) => {
    actionComponents.push(item(params.renderCellCallBackParams));
  });

  return actionComponents;
}
