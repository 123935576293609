/* eslint-disable react-hooks/exhaustive-deps */
import { Typography } from "@mui/material";
import { IBaseError } from "corede-common";
import { IBaseUserEntity } from "corede-common-cocrm";
import { getCurrentLanguage } from "localization";
import { enqueueSnackbar } from "notistack";
import { memo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { DefaultErrorHandlerUseEffect } from "utils/useEffect.helper";
import { useProductCategoryDeleteMutation } from "../context/productCategories.api";
import ActionDialog from "components/dialog/ActionDialog";

export const DeleteDialog = memo((prop: {
  open: boolean;
  item: IBaseUserEntity | undefined;
  onClose: () => void;
}) => {
  // general
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();

  // queries

  // mutations
  const [userDelete, { data: userDeleteData, isLoading: userDeleteIsLoading, error: userDeleteError }] = useProductCategoryDeleteMutation();

  // constants

  // // action handlers
  const handleDelete = (_id: string) => {
    userDelete({ input: { _id: _id } });
  };

  // useEffects.success
  useEffect(() => {
    if (userDeleteData) {
      prop?.onClose();
      enqueueSnackbar(t("crm.productsAndServices.productCategories.deletedSuccessfully"), {
        variant: "success",
      });
    }
  }, [userDeleteData]);

  // // useEffects.error
  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      userDeleteError as IBaseError,
      currentLanguage
    );
  }, [userDeleteError, currentLanguage]);

  // useEffects.init

  return (
    <ActionDialog
      open={prop?.open}
      onClose={prop?.onClose}
      title={t("crm.productsAndServices.productCategories.delete")}
      buttonTitle={t("delete")}
      buttonColor="error"
      handleClick={() => handleDelete(prop?.item?._id as string)}
      disabled={userDeleteIsLoading}
      loading={userDeleteIsLoading}
    >
      <Typography>
        {t("crm.productsAndServices.productCategories.deleteConfirm")} {prop?.item?.name}
      </Typography>
    </ActionDialog>
  );
});
