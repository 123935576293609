/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useLeadSourceDeleteMutation } from "../context/leadSources.api";
import { IBaseError } from "corede-common";
import { enqueueSnackbar } from "notistack";
import { getCurrentLanguage } from "localization";
import { DefaultErrorHandlerUseEffect } from "utils/useEffect.helper";
import { useTranslation } from "react-i18next";
import ActionDialog from "components/dialog/ActionDialog";
import { ILeadSource } from "corede-common-cocrm";

const Delete = (prop: {
  open: boolean;
  item: ILeadSource;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  // general
  const currentLanguage = getCurrentLanguage();

  // queries

  // mutations
  const [
    leadSourceDelete,
    {
      data: leadSourceDeleteData,
      isLoading: leadSourceDeleteLoading,
      error: leadSourceDeleteError,
    },
  ] = useLeadSourceDeleteMutation();

  // constants

  // action handlers
  const handleDelete = (_id: string) => {
    leadSourceDelete({ input: { _id: _id } });
  };

  // useEffects.success
  useEffect(() => {
    if (leadSourceDeleteData) {
      prop?.onClose();
      enqueueSnackbar(t("crm.lead.leadSources.deletedSuccessfully"), {
        variant: "success",
      });
    }
  }, [leadSourceDeleteData]);

  // useEffects.error
  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      leadSourceDeleteError as IBaseError,
      currentLanguage
    );
  }, [leadSourceDeleteError, currentLanguage]);

  // useEffects.init

  return (
    <ActionDialog
      open={prop?.open}
      onClose={prop?.onClose}
      title={t("crm.lead.leadSources.delete")}
      buttonTitle={t("delete")}
      buttonColor="error"
      handleClick={() => handleDelete(prop?.item?._id as string)}
      disabled={leadSourceDeleteLoading}
      loading={leadSourceDeleteLoading}
    >
      {t("crm.lead.leadSources.deleteConfirm")} {prop?.item?.name}?
    </ActionDialog>
  );
};

export default Delete;
