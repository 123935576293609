
import notesLocale from "../subdomains/notes/locales/en";

const locale = {

  // Todo
  // users: {
  //   ...usersLocale,
  // },
  notes: {
    ...notesLocale
  }
};

export default locale;
