import { IMenuItem } from "interfaces/menu-item.interface";
import { useTranslation } from "react-i18next"; // useTranslation hook'unu import ediyoruz
import {
  baseReportsRoute,
} from "../routes/reports.base.route";

export const useReportsDomainMenuItem = (): IMenuItem => {
  const { t } = useTranslation(); // useTranslation hook'unu kullanıyoruz

  return {
    title: t("reports"), // getTranslatedText yerine t fonksiyonunu kullanıyoruz
    path: baseReportsRoute(),
    children: [
      // Todo
      // {
      //   title: t("users"),
      //   path: listUsersRoute(),
      // },
    ],
  };
};
