/* eslint-disable react-hooks/exhaustive-deps */
import { CheckCircle, HighlightOff, Info, InfoOutlined, KeyboardDoubleArrowUp, PersonOutline, StorageOutlined } from "@mui/icons-material";
import { Autocomplete, Box, Button, Checkbox, Chip, CircularProgress, Grid, InputLabel, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography, useMediaQuery } from "@mui/material";
import { HeaderBase } from "components/contentHeaders/crm/HeaderBase";
import { Dispatch, useEffect, useMemo, useRef, useState } from "react";
import Arrow from "assets/images/arrow.png";
import { useLazyExchangeRateRetrieveQuery, useOrganizationTrialSubscriptionBeginMutation, usePaymentProductListQuery, useSubscriptionPurchaseMutation } from "../context/pricing.api";
import ActionDialog from "components/dialog/ActionDialog";
import { ContactFormSource, ContactFormType, ICreateContactFormInput, IPaymentProductListItemResult, IPaymentProductListResult, ISubscriptionPurchaseInput, PolicyDomain, PolicyTarget, PolicyType, RecurringInterval } from "corede-common-cocrm";
import { Currency, IGraphqlVariables, Language, unknownError } from "corede-common";
import { getCurrentLanguage } from "localization";
import { enqueueSnackbar } from "notistack";
import { useOrganizationCardDetailOwnQuery } from "apps/settings/domains/settings/subdomains/settings";
import { useCreateContactFormMutation, useLatestPolicyQuery, useOrganizationDetailOwnQuery, useUserDetailOwnQuery } from "apps/auth/context";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { setRoute, useAppDispatch } from "context";
import 'react-credit-cards-2/dist/es/styles-compiled.css';
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";



const List = () => {

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentLanguage = getCurrentLanguage();
  const gridContainerRef = useRef<HTMLDivElement>(null);
  const [selectedPeriod, setSelectedPeriod] = useState<RecurringInterval.monthly | RecurringInterval.yearly>(RecurringInterval.yearly);
  const [openDetail, setOpenDetail] = useState(false);
  const [openBeginTrial, setOpenBeginTrial] = useState(false);
  const [openBuyPackage, setOpenBuyPackage] = useState(false);
  const [openContactUs, setOpenContactUs] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState<IPaymentProductListItemResult | undefined>(undefined);
  const [checked1, setChecked1] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [openPrilaminaryDialog, setOpenPrilaminaryDialog] = useState(false);
  const [openSalesDilaog, setOpenSalesDilaog] = useState(false);
  const [organizationTrialSubscriptionBegin, { data: organizationTrialSubscriptionBeginData, isLoading: organizationTrialSubscriptionBeginLoading, error: organizationTrialSubscriptionBeginError }] = useOrganizationTrialSubscriptionBeginMutation()
  const { data: organizationCardDetailOwnData, isLoading: organizationCardDetailOwnLoading } = useOrganizationCardDetailOwnQuery({});
  const { data: organizationDetailOwnData, isLoading: organizationDetailOwnLoading } = useOrganizationDetailOwnQuery({});
  const [subscriptionPurchase, { data: subscriptionPurchaseData, isLoading: subscriptionPurchaseLoading, error: subscriptionPurchaseError }] = useSubscriptionPurchaseMutation()
  const [subscribeSuccess, setSubscribeSuccess] = useState(false);
  const { data: productList } = usePaymentProductListQuery({})
  const { data: userDetailOwnData } = useUserDetailOwnQuery({});
  const [createContactForm, { data: createContactFormData, isLoading: createContactFormLoading, error: createContactFormError }] = useCreateContactFormMutation();
  const [getExchangeRateRetrieve, { data: exchangeRateRetrieveData, isLoading: exchangeRateRetrieveLoading }] = useLazyExchangeRateRetrieveQuery()
  const currency = organizationDetailOwnData?.billingInfo?.country === "Turkey" ? Currency.TRY : Currency.USD


  const { data: preliminaryData } = useLatestPolicyQuery({
    input: {
      domain: PolicyDomain.app,
      language: currentLanguage,
      target: PolicyTarget.general,
      type: PolicyType.preliminary
    }
  });
  const { data: salesData } = useLatestPolicyQuery({
    input: {
      domain: PolicyDomain.app,
      language: currentLanguage,
      target: PolicyTarget.general,
      type: PolicyType.sales
    }
  });

  const dataArray = [
    {
      title: "Startup",
      currency: "$",
      pricePerMonth: "15",
      pricePerYear: "150",
      userPricePerMonth: "~$3/User",
      userPricePerYear: "~$10/User",
      usageTime: "15 months",
      discount: "50% free",
      users: "1-5 users",
      buttonText: t("crm.pricing.pricing.buy"),
      mostPopular: false,
      billingInfo: "One payment of $ 150 / year",
      paymentInfo: "Monthly: $ 15 | Annually: $ 150",
      price: "15",
      storage: "25 GB",
      userLimit: "5 users",
      features: [
        { name: "Collaboration", batteryLevel: 3 },
        { name: "Tasks & projects", batteryLevel: 3 },
        { name: "CRM", batteryLevel: 3 },
        { name: "Drive", batteryLevel: 1 },
        { name: "Contact Center", batteryLevel: 2 },
        { name: "Website builder", batteryLevel: 1 },
      ],
    },
    {
      title: "Basic",
      currency: "$",
      pricePerMonth: "28",
      pricePerYear: "280",
      userPricePerMonth: "~$2.8/User",
      userPricePerYear: "~$8/User",
      usageTime: "15 months",
      discount: "50% free",
      users: "6-10 users",
      buttonText: t("crm.pricing.pricing.buy"),
      mostPopular: false,
      billingInfo: "One payment of $ 280 / year",
      paymentInfo: "Monthly: $ 28 | Annually: $ 280",
      price: "28",
      storage: "50 GB",
      userLimit: "10 users",
      features: [
        { name: "Collaboration", batteryLevel: 3 },
        { name: "Tasks & projects", batteryLevel: 3 },
        { name: "CRM", batteryLevel: 3 },
        { name: "Drive", batteryLevel: 2 },
        { name: "Contact Center", batteryLevel: 2 },
        { name: "Website builder", batteryLevel: 1 },
        { name: "Online store", batteryLevel: 1 },
      ],
    },
    {
      title: "Standart",
      currency: "$",
      pricePerMonth: "49",
      pricePerYear: "490",
      userPricePerMonth: "~$2.5/User",
      userPricePerYear: "~$6/User",
      usageTime: "15 months",
      discount: "50% free",
      users: "11-20 users",
      buttonText: t("crm.pricing.pricing.buy"),
      mostPopular: true,
      billingInfo: "One payment of $ 490 / year",
      paymentInfo: "Monthly: $ 49 | Annually: $ 490",
      price: "49",
      storage: "100 GB",
      userLimit: "20 users",
      features: [
        { name: "Collaboration", batteryLevel: 3 },
        { name: "Tasks & projects", batteryLevel: 3 },
        { name: "CRM", batteryLevel: 3 },
        { name: "Drive", batteryLevel: 2 },
        { name: "Contact Center", batteryLevel: 2 },
        { name: "Website builder", batteryLevel: 1 },
        { name: "Online store", batteryLevel: 2 },
        { name: "Marketing", batteryLevel: 2 },
        { name: "Online documents", batteryLevel: 2 },
      ],
    },
    {
      title: "Professional",
      currency: "$",
      pricePerMonth: "88",
      pricePerYear: "880",
      userPricePerMonth: "~$2.2/User",
      userPricePerYear: "~$4/User",
      usageTime: "15 months",
      discount: "50% free",
      users: "21-40 users",
      buttonText: t("crm.pricing.pricing.buy"),
      mostPopular: false,
      billingInfo: "One payment of $ 880 / year",
      paymentInfo: "Monthly: $ 88 | Annually: $ 880",
      price: "88",
      storage: "200 GB",
      userLimit: "40 users",
      features: [
        { name: "Collaboration", batteryLevel: 3 },
        { name: "Tasks & projects", batteryLevel: 3 },
        { name: "CRM", batteryLevel: 3 },
        { name: "Drive", batteryLevel: 3 },
        { name: "Contact Center", batteryLevel: 2 },
        { name: "Website builder", batteryLevel: 2 },
        { name: "Online store", batteryLevel: 2 },
        { name: "Marketing", batteryLevel: 3 },
        { name: "Online documents", batteryLevel: 3 },
        { name: "e-Signature for HR", batteryLevel: 3 },
        { name: "Sales Intelligence", batteryLevel: 3 },
      ],
    },
    {
      title: "Enterprise",
      currency: "",
      pricePerMonth: "Contact with Us",
      pricePerYear: "Contact with Us",
      userPricePerMonth: "Custom",
      userPricePerYear: "Custom",
      usageTime: "Custom",
      discount: "50% free",
      users: "41+ users",
      buttonText: t("crm.pricing.pricing.contactUs"),
      mostPopular: false,
      billingInfo: "Custom pricing available",
      paymentInfo: "Custom payment plans",
      price: "Contact",
      storage: "Unlimited",
      userLimit: "41+ users",
      features: [
        { name: "Collaboration", batteryLevel: 3 },
        { name: "Tasks & projects", batteryLevel: 3 },
        { name: "CRM", batteryLevel: 3 },
        { name: "Drive", batteryLevel: 3 },
        { name: "Contact Center", batteryLevel: 3 },
        { name: "Website builder", batteryLevel: 3 },
        { name: "Online store", batteryLevel: 3 },
        { name: "Marketing", batteryLevel: 3 },
        { name: "Online documents", batteryLevel: 3 },
        { name: "e-Signature for HR", batteryLevel: 3 },
        { name: "Sales Intelligence", batteryLevel: 3 },
        { name: "Automation", batteryLevel: 3 },
        { name: "HR", batteryLevel: 3 },
      ],
    },
  ];


  const initialValues = useMemo(
    () => ({
      input: {
        productId: selectedPackage?._id ?? "",
        currency: currency,
        recurringInterval: selectedPeriod ?? RecurringInterval.monthly,
        registeredCardToken: organizationCardDetailOwnData?.cardDetails?.[0]?.cardToken
      }
    }),
    []
  );

  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
    reset
  } = useForm<IGraphqlVariables<ISubscriptionPurchaseInput>>({
    defaultValues: initialValues,
    resolver: undefined,
    mode: "onChange",
  });


  const onSubmit = async (values: IGraphqlVariables<ISubscriptionPurchaseInput>) => {
    subscriptionPurchase(values);
  };


  const contactForm = useForm<IGraphqlVariables<ICreateContactFormInput & { phone?: string, orgName?: string }>>({
    defaultValues: {
      input: {
        fullName: userDetailOwnData?.name || "",
        email: userDetailOwnData?.email || "",
        subject: "Enterprise Plan",
        message: "",
        type: ContactFormType.contact,
        source: ContactFormSource.web, //todo app olacak mı?
        language: Language[currentLanguage],
        phone: userDetailOwnData?.phoneNumber,
        orgName: userDetailOwnData?.organization?.name,
      }
    },
    mode: "onSubmit",
  });

  const contactFormOnSubmit = (values: IGraphqlVariables<ICreateContactFormInput & { phone?: string, orgName?: string }>) => {
    createContactForm({
      input: {
        fullName: values.input?.fullName,
        email: values.input?.email || "",
        subject: values.input?.subject || "",
        language: values.input?.language || Language[currentLanguage],
        source: values.input?.source || ContactFormSource.web,//todo app olacak mı?
        type: values.input?.type || ContactFormType.contact,
        message: "Phone: " + values.input?.phone + ", OrgName: " + values.input?.orgName
      }
    });
  };


  const handleTrialBegin = () => {
    organizationTrialSubscriptionBegin({});
  }

  useEffect(() => {
    if (organizationTrialSubscriptionBeginError) {
      enqueueSnackbar((organizationTrialSubscriptionBeginError as any)?.error?.message[currentLanguage] ?? unknownError.message[currentLanguage], { variant: 'error' });
    }
  }, [organizationTrialSubscriptionBeginError]);

  useEffect(() => {
    if (organizationTrialSubscriptionBeginData) {
      enqueueSnackbar(t("crm.pricing.pricing.beginTrialSuccess"), { variant: 'success' });
      setSubscribeSuccess(true);
      // window.location.href = "/dashboard";
    }
  }, [organizationTrialSubscriptionBeginData])

  useEffect(() => {
    if (subscriptionPurchaseData) {
      enqueueSnackbar(t("crm.pricing.pricing.purchaseSuccess"), { variant: 'success' });
      setSubscribeSuccess(true);
      // window.location.href = "/dashboard";
    }
  }, [subscriptionPurchaseData])

  useEffect(() => {
    if (subscriptionPurchaseError) {
      enqueueSnackbar((subscriptionPurchaseError as any)?.error?.message[currentLanguage] ?? unknownError.message[currentLanguage], { variant: 'error' });
    }
  }, [subscriptionPurchaseError])

  useEffect(() => {
    if (selectedPackage) {
      setValue("input.productId", selectedPackage?._id ?? "");
      setValue("input.registeredCardToken", organizationCardDetailOwnData?.cardDetails?.[0]?.cardToken ?? "");
    }
  }, [selectedPackage])


  useEffect(() => {
    if (createContactFormData) {
      enqueueSnackbar(t("crm.pricing.pricing.contactFormSuccess"), { variant: 'success' });
      setOpenContactUs(false);
    }
  }, [createContactFormData])

  useEffect(() => {
    if (createContactFormError) {
      enqueueSnackbar((createContactFormError as any)?.error?.message[currentLanguage] ?? unknownError.message[currentLanguage], { variant: 'error' });
    }
  }, [createContactFormError])

  useEffect(() => {
    if (openBuyPackage && selectedPackage && currency === Currency.TRY) {
      getExchangeRateRetrieve({
        input: {
          fromCurrency: Currency.USD,
          toCurrency: Currency.TRY,
          paymentProductId: selectedPackage?._id ?? "",
          recurringInterval: selectedPeriod ?? RecurringInterval.monthly
        }
      });
    }
  }, [openBuyPackage, selectedPackage, currency])

  return (
    <Grid container p={2}>
      <Grid item xs={12} sm={12}>
        <Box p={2}>
          <HeaderBase
            title={t('crm.pricing.pricing.pricing')}
          />
        </Box>
      </Grid>

      {/* {organizationDetailOwnLoading ?
        <CircularProgress size={"small"} sx={{ p: 2 }} />
        : organizationDetailOwnData?.activeSubscriptions?.main?.status === "ongoing" ?
          null
          : */}
          <PricingTable
            selectedPeriod={selectedPeriod}
            setSelectedPeriod={setSelectedPeriod}
            gridContainerRef={gridContainerRef}
            setOpenBeginTrial={setOpenBeginTrial}
            setOpenBuyPackage={setOpenBuyPackage}
            setOpenContactUs={setOpenContactUs}
            setSelectedPackage={setSelectedPackage}
            setOpenDetail={setOpenDetail}
            organizationDetailOwnData={organizationDetailOwnData}
            productList={productList}
            dataArray={dataArray}
            key={currentLanguage + " "}
          />
      {/* } */}


      <ActionDialog
        open={openDetail}
        onClose={() => setOpenDetail(false)}
        title="Detail"
        width={1200}
      >
        <PricingTableDetail />
      </ActionDialog>

      <ActionDialog
        open={openBeginTrial}
        onClose={() => {
          setOpenBeginTrial(false)
          console.log("trial close")
          subscribeSuccess ? window.location.href = "/dashboard" : setSubscribeSuccess(false)
        }}
        title={t("crm.pricing.pricing.trialPackage")}
        width={600}
        buttonCancelTitle={subscribeSuccess ? t("crm.pricing.pricing.goCRM") : t("crm.pricing.pricing.cancel")}
        buttonTitle={subscribeSuccess ? undefined : t("crm.pricing.pricing.beginTrial")}
        handleClick={subscribeSuccess ? undefined : () => handleTrialBegin()}
        loading={organizationTrialSubscriptionBeginLoading}
      >
        {!subscribeSuccess && <Typography sx={{ textAlign: 'center', mt: 4 }}>
          {t("crm.pricing.pricing.trialPackageDescription", {
            storageLimit: Number(selectedPackage?.recurring?.monthly?.entitlements?.storageLimit) / 1024,
            userLimit: selectedPackage?.recurring?.monthly?.entitlements?.userLimit
          })}
          <br /> <br />
          {t("crm.pricing.pricing.confirmTrial")}
        </Typography>}
        {subscribeSuccess && <Stack direction={"column"} alignItems={"center"} sx={{ mt: 2 }}>
          <CheckCircle sx={{ color: 'secondary.main', fontSize: "70px" }} />
          <Typography sx={{ textAlign: 'center' }}>Your trial package has been successfully started. <br />Welcome aboard.</Typography>
        </Stack>}
      </ActionDialog>

      <ActionDialog
        open={openBuyPackage}
        onClose={() => {
          setOpenBuyPackage(false)
          reset()
          subscribeSuccess ? window.location.href = "/dashboard" : setSubscribeSuccess(false)
        }}
        title={t("crm.pricing.pricing.buyPackage")}
        width={500}
        buttonCancelTitle={subscribeSuccess ? t('crm.pricing.pricing.continue') : t('crm.pricing.pricing.cancel')}
        buttonTitle={subscribeSuccess ? undefined : t("crm.pricing.pricing.buyPackage")}
        handleClick={subscribeSuccess ? undefined : () => handleSubmit(onSubmit)()}
        loading={!!(subscriptionPurchaseLoading)}
        disabled={!(organizationCardDetailOwnData?.cardDetails?.[0]) || !organizationDetailOwnData?.billingInfo || !checked1 || !checked2}
      >
        {!subscribeSuccess && <Typography sx={{ textAlign: 'center', mt: 4 }}>
          {t('crm.pricing.pricing.buyPackageDescription', {
            name: selectedPackage?.name, storageLimit: ((selectedPackage?.recurring?.monthly?.entitlements?.storageLimit ?? 0) / 1024),
            userLimit: selectedPackage?.recurring?.monthly?.entitlements?.userLimit
          })}
          <br /> <br />
        </Typography>}

        {!subscribeSuccess && !organizationDetailOwnData?.billingInfo &&
          <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} sx={{ mt: 4, backgroundColor: '#eee', px: 2, py: 1, borderRadius: 2 }}>
            <Stack direction={"row"} alignItems={"center"} gap={1}>
              <HighlightOff sx={{ color: 'error.main', fontSize: "20px" }} />
              <Typography>{t('crm.pricing.pricing.dontHaveBillingInfo')}</Typography>
            </Stack>
            <Typography fontWeight={700} sx={{ cursor: 'pointer', textAlign: 'right' }} onClick={() => {
              dispatch(setRoute("settings"))
              navigate("/settings/payment/?tab=billingInfo")
            }}>{t('crm.pricing.pricing.addBillingInfo')}</Typography>
          </Stack>}
        {!subscribeSuccess && organizationDetailOwnData?.billingInfo &&
          <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} sx={{ mt: 4, backgroundColor: '#eee', px: 2, py: 1, borderRadius: 2 }}>
            <Stack direction={"row"} alignItems={"center"} gap={1}>
              <CheckCircle sx={{ color: 'success.main', fontSize: "20px" }} />
              <Stack direction={"column"} width={"80%"} alignItems={"flex-start"}>
                <InputLabel htmlFor="input.registeredCardToken" sx={{ ml: 1, mb: 0.5, fontSize: 'small', textAlign: 'left' }}>{t('crm.pricing.pricing.billingInfo')}*</InputLabel>
                <Typography fontSize={"medium"} pl={1}>{organizationDetailOwnData?.billingInfo?.name} {organizationDetailOwnData?.billingInfo?.surname} </Typography>
              </Stack>
            </Stack>
            <Typography fontWeight={700} fontSize={"small"} sx={{ cursor: 'pointer' }} onClick={() => {
              dispatch(setRoute("settings"))
              navigate("/settings/payment/?tab=billingInfo")
            }}>{t('crm.pricing.pricing.manageBillingInfo')}</Typography>
          </Stack>}

        {!subscribeSuccess && !organizationCardDetailOwnData?.cardDetails?.[0] &&
          <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} sx={{ mt: 2, backgroundColor: '#eee', px: 2, py: 1, borderRadius: 2 }}>
            <Stack direction={"row"} alignItems={"center"} gap={1}>
              <HighlightOff sx={{ color: 'error.main', fontSize: "20px" }} />
              <Typography >{t('crm.pricing.pricing.dontHaveCard')}</Typography>
            </Stack>
            <Typography fontWeight={700} sx={{ cursor: 'pointer' }} onClick={() => {
              dispatch(setRoute("settings"))
              navigate("/settings/payment/?tab=cards")
            }}>{t('crm.pricing.pricing.addCard')}</Typography>
          </Stack>}

        {!subscribeSuccess && organizationCardDetailOwnData?.cardDetails?.[0] &&
          <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} sx={{ mt: 2, backgroundColor: '#eee', px: 2, py: 1, borderRadius: 2 }}>
            <Stack direction={"row"} width={"70%"} alignItems={"center"} gap={1}>
              <CheckCircle sx={{ color: 'success.main', fontSize: "20px" }} />
              <Stack direction={"column"} width={"100%"} alignItems={"flex-start"}>
                <InputLabel htmlFor="input.registeredCardToken" sx={{ ml: 1, mb: 0.5, fontSize: 'small', textAlign: 'left' }}>{t('crm.pricing.pricing.selectedCard')}*</InputLabel>
                <Controller
                  name="input.registeredCardToken"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      id="input.registeredCardToken"
                      isOptionEqualToValue={(option, value) => option === value}
                      fullWidth
                      size="small"
                      disableClearable
                      getOptionLabel={(option) => option.binNumber + " **** **** | " + option.cardBankName}
                      options={(organizationCardDetailOwnData?.cardDetails || []).filter(card => card.binNumber)}
                      loading={organizationCardDetailOwnLoading}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={!!errors?.input?.registeredCardToken}
                          helperText={errors?.input?.registeredCardToken?.message}
                        />
                      )}
                      value={organizationCardDetailOwnData?.cardDetails?.find(card => card.cardToken === field.value)}
                      onChange={(e, value) => field.onChange(value?.cardToken || "")}
                    />
                  )}
                />
              </Stack>
            </Stack>
            <Typography fontWeight={700} fontSize={"small"} sx={{ cursor: 'pointer' }} onClick={() => {
              dispatch(setRoute("settings"))
              navigate("/settings/payment/?tab=cards")
            }}>{t('crm.pricing.pricing.manageCards')}</Typography>
          </Stack>}

        {!subscribeSuccess &&
          <Stack direction={"column"} sx={{ mt: 2, backgroundColor: '#eee', px: 2, py: 1, borderRadius: 2 }}>

            <Stack direction={{ xs: "column", md: "row" }} justifyContent={"space-between"} gap={1}>
              <Stack direction={"column"} alignItems={"flex-start"}>
                <Typography fontWeight={700}>{t('crm.pricing.pricing.summary')}</Typography>
                <Stack direction={"column"} alignItems={"flex-start"} mt={1}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={0.5}
                    onClick={() => setChecked1(!checked1)}
                    sx={{ cursor: "pointer" }}
                  >
                    <Checkbox
                      checked={checked1}
                      onChange={() => setChecked1(!checked1)}
                      color="secondary"
                    />
                    <Typography fontSize={14} fontWeight={400} sx={{ alignItems: "center" }}>
                      {t('crm.pricing.pricing.iAcceptThe')}
                      <span
                        onClick={(e) => {
                          setOpenPrilaminaryDialog(true);
                          e.stopPropagation();
                        }}
                        style={{
                          fontWeight: 600,
                          color: "secondary.main",
                          textDecoration: "underline",
                          marginLeft: 4,
                          cursor: "pointer",
                        }}
                      >
                        {t('crm.pricing.pricing.preliminaryInformation')}
                      </span>
                    </Typography>
                  </Stack>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={0.5}
                  onClick={() => setChecked2(!checked2)}
                  sx={{ cursor: "pointer" }}
                >
                  <Checkbox
                    checked={checked2}
                    onChange={() => setChecked2(!checked2)}
                    color="secondary"
                  />
                  <Typography fontSize={14} fontWeight={400} sx={{ alignItems: "center" }}>
                    {t('crm.pricing.pricing.iAcceptThe')}
                    <span
                      onClick={(e) => {
                        setOpenSalesDilaog(true);
                        e.stopPropagation();
                      }}
                      style={{
                        fontWeight: 600,
                        color: "secondary.main",
                        textDecoration: "underline",
                        marginLeft: 4,
                        cursor: "pointer",
                      }}
                    >
                      {t('crm.pricing.pricing.distanceSalesAgreement')}
                    </span>
                  </Typography>
                </Stack>
              </Stack>
              {exchangeRateRetrieveLoading ? <CircularProgress size={"2rem"} /> : <Stack direction={"column"} alignItems={"flex-end"} mt={1} >
                {currency === Currency.TRY && <Typography fontWeight={600} fontSize={"20px"}>
                  {((exchangeRateRetrieveData?.rate ?? 1) * (selectedPeriod === RecurringInterval.monthly ? (selectedPackage as any)?.recurring?.monthly?.pricing?.discountedPrice : (selectedPackage as any)?.recurring?.yearly?.pricing?.discountedPrice)).toFixed(2)}₺
                </Typography>}
                {currency !== Currency.TRY && <Typography fontWeight={600} fontSize={"20px"}>
                  ${(selectedPeriod === RecurringInterval.monthly ? (selectedPackage as any)?.recurring?.monthly?.pricing?.discountedPrice : (selectedPackage as any)?.recurring?.yearly?.pricing?.discountedPrice)}
                </Typography>}
                {currency === Currency.TRY && <Typography fontWeight={600} fontSize={"14px"} mt={-1}>+ KDV</Typography>}
                {/* {currency === Currency.TRY && <Typography fontWeight={400} fontSize={"12px"}>Toplam:</Typography>} */}
                {currency === Currency.TRY && <Typography fontWeight={400} fontSize={"12px"}>
                  Toplam: {(((exchangeRateRetrieveData?.rate ?? 1) *
                    (selectedPeriod === RecurringInterval.monthly ?
                      (selectedPackage as any)?.recurring?.monthly?.pricing?.discountedPrice :
                      (selectedPackage as any)?.recurring?.yearly?.pricing?.discountedPrice)
                  ) * 1.20).toFixed(2)}₺</Typography>}
                <Typography fontWeight={600} fontSize={"14px"}>/{selectedPeriod === RecurringInterval.monthly ? t('crm.pricing.pricing.monthly') : t('crm.pricing.pricing.annually')}</Typography>
              </Stack>}
            </Stack>
          </Stack>
        }



        {subscribeSuccess && <Stack direction={"column"} alignItems={"center"} sx={{ mt: 2 }}>
          <CheckCircle sx={{ color: 'success.main', fontSize: "70px" }} />
          <Typography sx={{ textAlign: 'center' }}>{t('crm.pricing.pricing.subscribeSuccess1')} <br />{t('crm.pricing.pricing.subscribeSuccess2')}</Typography>
        </Stack>}

      </ActionDialog>

      <ActionDialog
        open={openContactUs}
        onClose={() => {
          setOpenContactUs(false)
          reset()
          subscribeSuccess ? window.location.href = "/dashboard" : setSubscribeSuccess(false)
        }}
        title={t('crm.pricing.pricing.contactUs')}
        width={500}
        buttonCancelTitle={subscribeSuccess ? t('crm.pricing.pricing.continue') : t('crm.pricing.pricing.cancel')}
        buttonTitle={subscribeSuccess ? undefined : t('crm.pricing.pricing.letUsCall')}
        handleClick={subscribeSuccess ? undefined : () => contactForm.handleSubmit(contactFormOnSubmit)()}
        loading={createContactFormLoading}
        disabled={createContactFormLoading}
      >
        <Typography pt={3} variant="body1">{t("window.demo.demo.formDescription")}</Typography>
        <Grid container spacing={2} sx={{ mt: 3 }}>
          <Grid item xs={6}>
            <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("window.demo.demo.fullName")}</InputLabel>
            <Controller
              name="input.fullName"
              control={contactForm.control}
              render={({ field }) => (
                <TextField
                  {...field}
                  size="small"
                  fullWidth
                  id="fullName"
                  error={!!contactForm?.formState?.errors?.input?.fullName}
                  helperText={contactForm?.formState?.errors?.input?.fullName?.message}
                />
              )}
            />
          </Grid>

          <Grid item xs={6}>
            <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("window.demo.demo.orgName")}</InputLabel>
            <Controller
              name="input.orgName"
              control={contactForm.control}
              render={({ field }) => (
                <TextField
                  {...field}
                  size="small"
                  fullWidth
                  id="orgName"
                  error={!!contactForm?.formState?.errors.input?.orgName}
                  helperText={contactForm?.formState?.errors.input?.orgName?.message}
                />
              )}
            />
          </Grid>

          <Grid item xs={6}>
            <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("window.demo.demo.email")}</InputLabel>
            <Controller
              name="input.email"
              control={contactForm.control}
              render={({ field }) => (
                <TextField
                  {...field}
                  size="small"
                  fullWidth
                  id="email"
                  error={!!contactForm?.formState?.errors.input?.email}
                  helperText={contactForm?.formState?.errors.input?.email?.message}
                />
              )}
            />
          </Grid>

          <Grid item xs={6}>
            <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("window.demo.demo.phone")}</InputLabel>
            <Controller
              name="input.phone"
              control={contactForm.control}
              render={({ field }) => (
                <TextField
                  {...field}
                  size="small"
                  fullWidth
                  id="phone"
                  error={!!contactForm?.formState?.errors.input?.phone}
                  helperText={contactForm?.formState?.errors.input?.phone?.message}
                />
              )}
            />
          </Grid>

        </Grid>

      </ActionDialog>


      <ActionDialog
        open={openPrilaminaryDialog}
        onClose={() => setOpenPrilaminaryDialog(false)}
        title={t('crm.pricing.pricing.preliminaryInformation')}
        width={600}
        buttonCancelTitle={t('crm.pricing.pricing.close')}
      >
        <Typography sx={{ textAlign: 'left', mt: 4 }}>
          {parse(preliminaryData?.content ?? "")}
        </Typography>
      </ActionDialog>

      <ActionDialog
        open={openSalesDilaog}
        onClose={() => setOpenSalesDilaog(false)}
        title={t('crm.pricing.pricing.distanceSalesAgreement')}
        width={600}
        buttonCancelTitle={t('crm.pricing.pricing.close')}
      >
        <Typography sx={{ textAlign: 'left', mt: 4 }}>
          {parse(salesData?.content ?? "")}
        </Typography>
      </ActionDialog>
    </Grid>
  );
};


interface IPricingTable {
  selectedPeriod: RecurringInterval;
  setSelectedPeriod: any;
  gridContainerRef: any;
  setOpenBeginTrial: (success: boolean) => void;
  setOpenBuyPackage: (success: boolean) => void;
  setOpenContactUs: (success: boolean) => void;
  setSelectedPackage: Dispatch<any>;
  setOpenDetail: (success: boolean) => void;
  organizationDetailOwnData: any;
  productList: IPaymentProductListResult | undefined;
  dataArray: any[]
}

const PricingTable = ({
  selectedPeriod,
  setSelectedPeriod,
  gridContainerRef,
  setOpenBeginTrial,
  setOpenBuyPackage,
  setOpenContactUs,
  setSelectedPackage,
  setOpenDetail,
  organizationDetailOwnData,
  productList,
  dataArray
}: IPricingTable) => {

  const { t } = useTranslation();
  const downLg = useMediaQuery((theme: any) => theme.breakpoints.down('lg'));
  const itemsPerPage = 4;
  const [visibleItems, setVisibleItems] = useState(dataArray);
  const [startIndex, setStartIndex] = useState(0);
  const [lastRow, setLastRow] = useState(1);
  const [containerWidth, setContainerWidth] = useState(0);
  const maxFeaturesLength = dataArray.reduce((max, item) => Math.max(max, item.features.length), 0);

  const handleNext = () => {
    if (startIndex + itemsPerPage < dataArray.length) {
      setStartIndex((prev) => prev + 1);
    }
  };

  const handlePrev = () => {
    if (startIndex > 0) {
      setStartIndex((prev) => prev - 1);
    }
  };


  useEffect(() => {
    setVisibleItems(downLg ? dataArray : dataArray.slice(startIndex, startIndex + itemsPerPage));
  }, [downLg, startIndex]);

  useEffect(() => {
    const handleResize = (entries: ResizeObserverEntry[]) => {
      if (entries[0].contentRect) {
        setContainerWidth(entries[0].contentRect.width);
      }
    };

    const resizeObserver = new ResizeObserver((entries) => handleResize(entries));
    if (gridContainerRef.current) {
      resizeObserver.observe(gridContainerRef.current);
    }

    return () => {
      if (gridContainerRef.current) {
        resizeObserver.unobserve(gridContainerRef.current);
      }
    };
  }, []);

  return <Grid item xs={12} sm={12}>
    <Grid container spacing={2} mt={5}>
      <Grid item xs={12} sm={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: 5, }}>
        <Stack direction={"row"} justifyContent={"center"} alignItems={"center"} gap={{ xs: 0, md: 1 }} position={"relative"}
          sx={{ border: "1px solid", borderColor: "secondary.main", borderRadius: 2, p: { xs: 0.4, md: 1 }, width: "fit-content", }}>
          <Button
            variant={selectedPeriod === RecurringInterval?.monthly ? "contained" : "text"}
            color="secondary"
            onClick={() => setSelectedPeriod(RecurringInterval?.monthly)}
            sx={{ height: "45px", width: { xs: "60px", md: "120px" }, fontSize: { xs: "12px", md: "16px" } }}
          >
            {t('crm.pricing.pricing.monthlyUppercase')}
          </Button>
          <Button
            variant={selectedPeriod === RecurringInterval?.yearly ? "contained" : "text"}
            color="secondary"
            onClick={() => setSelectedPeriod(RecurringInterval?.yearly)}
            sx={{ height: "45px", width: { xs: "60px", md: "120px" }, fontSize: { xs: "12px", md: "16px" } }}
          >
            {t('crm.pricing.pricing.annuallyUppercase')}
          </Button>
          <img src={Arrow} alt="arrow" style={{ position: "absolute", right: "-50px", top: "-10px", width: "50px" }} />
          <Box
            sx={{
              position: "absolute",
              top: "-50px",
              right: "-100px",
              backgroundColor: "#fce76c",
              color: "#333",
              fontSize: { xs: "12px", md: "16px" },
              fontWeight: "700",
              padding: "8px 10px",
              borderRadius: "8px",
              display: "inline-block",
              transform: "skewX(-10deg)",
            }}
          >
            {t('crm.pricing.pricing.saveUpTo')} 30%
          </Box>
        </Stack>
      </Grid>
    </Grid>
    <Grid container spacing={2} p={2} ml={{ xs: 0, md: 0.05 }} ref={gridContainerRef}>
      {visibleItems.map((item, index) => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
          <Box border={item.mostPopular ? "4px solid" : "2px solid"} borderColor={"secondary.main"} borderRadius={3} position={"relative"} bgcolor={"white"}>
            {item.mostPopular && <ShineEffectBox />}
            {/* {productList?.data?.find((product) => product.name === item.title)?.name === "Test 1" && !organizationDetailOwnData?.activeSubscriptions && <TrialPackageBox handleClick={() => setOpenBeginTrial(true)} />} */}
            {!downLg && startIndex > 0 && index === 0 && <SlideLeftBox handlePrev={handlePrev} />}
            {!downLg && startIndex + itemsPerPage < dataArray.length && index === dataArray.length - 2 && <SlideRightBox handleNext={handleNext} />}
            <Stack direction={"column"}>
              <Stack direction={"column"} gap={3} p={2}>
                <Typography fontSize={"28px"} fontWeight={600} color={"secondary.main"}>
                  {productList?.data?.find((product) => product.name === item.title)?.name}
                  {item.title === "Enterprise" && item.title}
                </Typography>
                {item.title !== "Enterprise" ? <Stack direction={"row"} alignItems={"flex-end"}>
                  <Typography fontSize={"24px"} lineHeight={"34px"} fontWeight={400} color={"primary.main"}>
                    {item.currency}
                  </Typography>
                  <Typography fontSize={"42px"} lineHeight={"42px"} fontWeight={400} color={"primary.main"}>
                    {selectedPeriod === RecurringInterval?.monthly && productList?.data?.find((product) => product.name === item.title)?.recurring?.monthly?.pricing?.discountedPrice}
                    {selectedPeriod === RecurringInterval?.yearly && ((productList?.data?.find((product) => product.name === item.title)?.recurring?.yearly?.pricing?.discountedPrice ?? 0) / 12).toFixed(0)}
                  </Typography>
                  <Typography fontSize={"20px"} lineHeight={"34px"} fontWeight={400} color={"primary.main"}>
                    /{t('crm.pricing.pricing.monthly')}
                  </Typography>
                </Stack> : <Stack direction={"row"} alignItems={"flex-end"}>
                  <Typography fontSize={"30px"} lineHeight={"42px"} fontWeight={400} color={"primary.main"}>
                    {t('crm.pricing.pricing.contactUs')}
                  </Typography>
                </Stack>}
                {productList?.data?.find((product) => product.name === item.title)?.recurring?.monthly?.pricing?.discountRate ? <Chip
                  label={"%" + productList?.data?.find((product) => product.name === item.title)?.recurring?.monthly?.pricing?.discountRate + " off"}
                /> : null}
                {selectedPeriod === RecurringInterval?.yearly && item.title !== "Enterprise" ?
                  <Tooltip title={`One payment of $${productList?.data?.find((product) => product.name === item.title)?.recurring?.yearly?.pricing?.discountedPrice}`}>
                    <Typography fontSize={"12px"} fontWeight={300} height={"24px"} color={"primary.main"}>
                      / {t('crm.pricing.pricing.organization')} / {t('crm.pricing.pricing.month')} / {t('crm.pricing.pricing.billedAnnually')} <Info sx={{ fontSize: "14px", color: "#999" }} />
                    </Typography>
                  </Tooltip> :
                  selectedPeriod === RecurringInterval?.yearly && item.title === "Enterprise" ? <Tooltip title={t('crm.pricing.pricing.contactUs')}>
                    <Typography fontSize={"12px"} fontWeight={300} height={"24px"} color={"primary.main"}>
                      / {t('crm.pricing.pricing.organization')} / {t('crm.pricing.pricing.month')} / {t('crm.pricing.pricing.billedAnnually')} <Info sx={{ fontSize: "14px", color: "#999" }} />
                    </Typography>
                  </Tooltip> : null}
                {selectedPeriod === RecurringInterval?.monthly && <Typography fontSize={"12px"} fontWeight={300} height={"24px"} color={"primary.main"}>
                  / {t('crm.pricing.pricing.organization')} / {t('crm.pricing.pricing.month')} / {t('crm.pricing.pricing.billedMonthly')}
                </Typography>}
                <Chip
                  label={item.title === "Enterprise" ? t('crm.pricing.pricing.contactUs') :
                    selectedPeriod === RecurringInterval?.monthly ? productList?.data?.find((product) => product.name === item.title)?.recurring?.monthly?.entitlements?.userLimit + ` ` + t('crm.pricing.pricing.users')
                      : productList?.data?.find((product) => product.name === item.title)?.recurring?.yearly?.entitlements?.userLimit + ` ` + t('crm.pricing.pricing.users')
                  }
                  variant="filled"
                  sx={{
                    backgroundColor: "#f88b9e88",
                    color: "secondary.dark",
                    fontWeight: 600,
                    fontSize: "14px",
                    width: "fit-content",
                  }}
                />
                {(productList?.data?.find((product) => product.name === item.title)?.name === "Startup" && !organizationDetailOwnData?.activeSubscriptions) ?
                  <Button variant="contained" fullWidth
                    sx={{
                      borderRadius: "100px", fontSize: "13px", height: "30px", mb: -2, backgroundColor: '#fce76c', color: '#000',
                      ':hover': { backgroundColor: '#fce76c', color: '#000' }
                    }}
                    onClick={() => {
                      setSelectedPackage(productList?.data?.find((product) => product.name === item.title))
                      setOpenBeginTrial(true)
                    }}
                  >
                    {t('crm.pricing.pricing.startTrialForFree')}
                  </Button> : <Box sx={{ height: "30px", mb: -2 }} />}
                <Button variant="contained" color="primary" fullWidth sx={{ borderRadius: "100px", height: "40px" }}
                  onClick={() => {
                    item.buttonText === t('crm.pricing.pricing.contactUs') ? setOpenContactUs(true)
                      : setOpenBuyPackage(true)
                    setSelectedPackage(productList?.data?.find((product) => product.name === item.title))
                  }}
                >
                  {item.buttonText}
                </Button>
              </Stack>

              {/* Storage & User Section */}
              <Box borderTop={"1px solid"} borderBottom={"1px solid"} borderColor={"secondary.main"} p={2} sx={{ backgroundColor: '#f88b9e22' }}>
                <Stack direction={"column"} gap={2}>
                  <Stack direction={"row"} gap={2} alignItems={"center"}>
                    <StorageOutlined sx={{ fontSize: "20px", color: "secondary.main", ml: "3px", mr: "3px" }} />
                    <Typography fontSize={"16px"} fontWeight={400} color={"primary.main"}>
                      {item.title === "Enterprise" ? t('crm.pricing.pricing.contactUs') :
                        selectedPeriod === RecurringInterval?.monthly
                          ? (Number(productList?.data?.find((product) => product.name === item.title)?.recurring?.monthly?.entitlements?.storageLimit ?? 0) / 1024).toFixed(2) + " GB"
                          : (Number(productList?.data?.find((product) => product.name === item.title)?.recurring?.yearly?.entitlements?.storageLimit ?? 0) / 1024).toFixed(2) + " GB"
                      }
                    </Typography>
                  </Stack>
                  <Stack direction={"row"} gap={2} alignItems={"center"}>
                    <PersonOutline sx={{ fontSize: "20px", color: "secondary.main", ml: "3px", mr: "3px" }} />
                    <Typography fontSize={"16px"} fontWeight={400} color={"primary.main"}>
                      {item.title === "Enterprise" ? t('crm.pricing.pricing.contactUs') :
                        selectedPeriod === RecurringInterval?.monthly ? productList?.data?.find((product) => product.name === item.title)?.recurring?.monthly?.entitlements?.userLimit + ` ` + t('crm.pricing.pricing.users')
                          : productList?.data?.find((product) => product.name === item.title)?.recurring?.yearly?.entitlements?.userLimit + ` ` + t('crm.pricing.pricing.users')
                      }
                    </Typography>
                  </Stack>
                </Stack>
              </Box>

              {/* Battery Section */}
              <Box sx={{ backgroundColor: '#f88b9e22' }} position={"relative"}>
                <Stack p={1} direction={"column"} gap={1}>
                  {Array.from({ length: downLg ? item.features.length : maxFeaturesLength }).map((_, idx) => {
                    const feature = item.features[idx] || { name: "", batteryLevel: null };
                    return (
                      <Stack
                        direction={"row"}
                        gap={2}
                        alignItems={"center"}
                        sx={{
                          position: 'relative',
                          p: 1,
                          ':hover': feature.name && {
                            backgroundColor: '#f88b9e44',
                            borderRadius: 3,
                            cursor: 'pointer',
                          },
                        }}
                        key={idx}
                        onClick={() => setOpenDetail(true)}
                        onMouseEnter={() => setLastRow(idx)}
                      >
                        {index === 0 && lastRow === idx ? (
                          <Box
                            sx={{
                              zIndex: 1,
                              position: 'absolute',
                              top: 0,
                              left: 0,
                              height: '38px',
                              width: containerWidth - 40,
                              border: '2px solid',
                              borderColor: '#f88b9e66',
                              borderRadius: 3,
                              pointerEvents: 'none',
                            }}
                          />
                        ) : null}
                        {feature.batteryLevel ? <Battery level={feature.batteryLevel} /> : <Box height={"24px"} />}
                        {feature.name && <ResizableTypography key={idx} text={feature.name} />}
                      </Stack>
                    );
                  })}
                </Stack>
              </Box>
            </Stack>
          </Box>
        </Grid>
      ))}
    </Grid>
  </Grid>
}

const planData1 = [
  { feature: "Comments in the feed", free: true, basic: true, standard: true, professional: true, enterprise: true },
  { feature: "Share with colleagues", free: true, basic: true, standard: true, professional: true, enterprise: true },
  { feature: "Video announcements", free: true, basic: true, standard: true, professional: true, enterprise: true },
  { feature: "Likes and reactions", free: true, basic: true, standard: true, professional: true, enterprise: true },
  { feature: "Appreciation badges", free: true, basic: true, standard: true, professional: true, enterprise: true },
  { feature: "CoPilot in feed", free: true, basic: true, standard: true, professional: true, enterprise: true },
  { feature: "Announcements", free: false, basic: true, standard: true, professional: true, enterprise: true },
  { feature: "Polls", free: false, basic: true, standard: true, professional: true, enterprise: true },
  { feature: "Event planner", free: false, basic: true, standard: true, professional: true, enterprise: true },
  { feature: "Instant calendar sync", free: true, basic: true, standard: true, professional: true, enterprise: true },
];

const planData2 = [
  { feature: "Instant calendar sync", free: true, basic: true, standard: true, professional: true, enterprise: true },
  { feature: "Open slots", free: false, basic: false, standard: true, professional: true, enterprise: true },
  { feature: "Shared slots", free: false, basic: false, standard: true, professional: true, enterprise: true },
  { feature: "Event email invitations", free: false, basic: false, standard: true, professional: true, enterprise: true },
  { feature: "Custom event location", free: false, basic: true, standard: true, professional: true, enterprise: true },
  { feature: "Meeting room list", free: false, basic: false, standard: true, professional: true, enterprise: true },
  { feature: "Calendar permissions", free: false, basic: false, standard: true, professional: true, enterprise: true },
  { feature: "Online storage", free: "5 GB", basic: "24 GB", standard: "100 GB", professional: "1 TB", enterprise: "3 TB" },
  { feature: "Company drive", free: true, basic: false, standard: true, professional: true, enterprise: true },
];

const planData3 = [
  { feature: "Comments in the feed", free: true, basic: true, standard: true, professional: true, enterprise: true },
  { feature: "Share with colleagues", free: true, basic: true, standard: true, professional: true, enterprise: true },
  { feature: "Video announcements", free: true, basic: true, standard: true, professional: true, enterprise: true },
  { feature: "Announcements", free: false, basic: true, standard: true, professional: true, enterprise: true },
  { feature: "Appreciation badges", free: true, basic: true, standard: true, professional: true, enterprise: true },
  { feature: "CoPilot in feed", free: true, basic: true, standard: true, professional: true, enterprise: true },
  { feature: "Announcements", free: false, basic: true, standard: true, professional: true, enterprise: true },
  { feature: "Polls", free: false, basic: true, standard: true, professional: true, enterprise: true },
  { feature: "Event planner", free: false, basic: true, standard: true, professional: true, enterprise: true },
  { feature: "Instant calendar sync", free: true, basic: true, standard: true, professional: true, enterprise: true },
];

const planData4 = [
  { feature: "Instant calendar sync", free: true, basic: true, standard: true, professional: true, enterprise: true },
  { feature: "Open slots", free: false, basic: false, standard: true, professional: true, enterprise: true },
  { feature: "Shared slots", free: false, basic: false, standard: true, professional: true, enterprise: true },
  { feature: "Announcements", free: false, basic: true, standard: true, professional: true, enterprise: true },
  { feature: "Polls", free: false, basic: true, standard: true, professional: true, enterprise: true },
  { feature: "Event planner", free: false, basic: true, standard: true, professional: true, enterprise: true },
  { feature: "Calendar permissions", free: false, basic: false, standard: true, professional: true, enterprise: true },
  { feature: "Online storage", free: "5 GB", basic: "24 GB", standard: "100 GB", professional: "1 TB", enterprise: "3 TB" },
  { feature: "Company drive", free: true, basic: false, standard: true, professional: true, enterprise: true },
];

const sectionData = [
  { title: "Collaboration" },
  { title: "Section 2" },
  { title: "Section 3" },
  { title: "Section 4" },
]

const PricingTableDetail = () => {

  const [sectionTitle, setSectionTitle] = useState("Collaboration")
  const [activeData, setActiveData] = useState<any>(planData1)

  useEffect(() => {
    if (sectionTitle === "Collaboration") {
      setActiveData(planData1)
    } else if (sectionTitle === "Section 2") {
      setActiveData(planData2)
    } else if (sectionTitle === "Section 3") {
      setActiveData(planData3)
    } else if (sectionTitle === "Section 4") {
      setActiveData(planData4)
    }
  }, [sectionTitle])

  return (
    <TableContainer component={Paper}>
      <Table aria-label="pricing table">
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant="h6">{sectionTitle}</Typography>
            </TableCell>
            <TableCell align="left">Free</TableCell>
            <TableCell align="left">Basic</TableCell>
            <TableCell align="left">Standard</TableCell>
            <TableCell align="left">Professional</TableCell>
            <TableCell align="left">Enterprise</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {activeData.map((row: any) => (
            <TableRow key={row.feature}>
              <TableCell component="th" scope="row">
                <Typography>
                  {row.feature} <InfoOutlined sx={{ fontSize: "16px", color: "#999" }} />
                </Typography>
              </TableCell>
              <TableCell align="left">{row.free === true ? <CheckCircle color="success" /> : row.free || "-"}</TableCell>
              <TableCell align="left">{row.basic === true ? <CheckCircle color="success" /> : row.basic || "-"}</TableCell>
              <TableCell align="left">{row.standard === true ? <CheckCircle color="success" /> : row.standard || "-"}</TableCell>
              <TableCell align="left">{row.professional === true ? <CheckCircle color="success" /> : row.professional || "-"}</TableCell>
              <TableCell align="left">{row.enterprise === true ? <CheckCircle color="success" /> : row.enterprise || "-"}</TableCell>
            </TableRow>
          ))}
          {sectionData.map((row) => (
            <TableRow key={row.title} sx={{ backgroundColor: '#f88b9e44' }}>
              <TableCell component="th" scope="row" onClick={() => setSectionTitle(row.title)} sx={{ cursor: 'pointer' }}>
                <Typography fontWeight={"bold"}>
                  {row.title} <InfoOutlined sx={{ fontSize: "16px", color: "#999" }} />
                </Typography>
              </TableCell>
              <TableCell align="center"><Battery level={0} /></TableCell>
              <TableCell align="center"><Battery level={1} /></TableCell>
              <TableCell align="center"><Battery level={2} /></TableCell>
              <TableCell align="center"><Battery level={3} /></TableCell>
              <TableCell align="center"><Battery level={3} /></TableCell>
            </TableRow>
          ))}

        </TableBody>
      </Table>
    </TableContainer>
  );
};


const Battery = ({ level }: { level: number }) => {
  return (
    <Stack
      direction="row"
      spacing={"1px"}
      sx={{
        width: '20px',
        height: '10px',
        border: '1px solid',
        borderColor: 'secondary.main',
        borderRadius: '50px',
        justifyContent: 'space-between',
        alignItems: 'center',
        p: "2px",
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: '100%',
          backgroundColor: level >= 1 ? 'secondary.main' : 'transparent',
          borderTopLeftRadius: '50px',
          borderBottomLeftRadius: '50px',
        }}
      />
      <Box
        sx={{
          width: '100%',
          height: '100%',
          backgroundColor: level >= 2 ? 'secondary.main' : 'transparent',
        }}
      />
      <Box
        sx={{
          width: '100%',
          height: '100%',
          backgroundColor: level >= 3 ? 'secondary.main' : 'transparent',
          borderTopRightRadius: '50px',
          borderBottomRightRadius: '50px',
        }}
      />
    </Stack>
  );
};

function ShineEffectBox() {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        position: 'absolute',
        display: 'inline-block',
        backgroundColor: 'secondary.main',
        color: '#ffffff',
        fontSize: '14px',
        fontWeight: 600,
        borderTopLeftRadius: '14px',
        borderTopRightRadius: '14px',
        top: -30,
        left: 20,
        padding: '6px 20px',
        overflow: 'hidden',
        outline: 'none',
        transition: 'all .2s ease',
        '&:after': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: '-200%',
          width: '200%',
          height: '200%',
          transform: 'skewX(-20deg)',
          backgroundImage: 'linear-gradient(to right, transparent, rgba(255, 255, 255, 0.3), transparent)',
          animation: 'shine 2.6s ease infinite',
        },
        '@keyframes shine': {
          '100%': { left: '200%' },
        },
      }}
    >
      {t('crm.pricing.pricing.mostPopular')}
    </Box>
  );
}

function SlideLeftBox({ handlePrev }: any) {
  const { t } = useTranslation();
  return (
    <Box
      onClick={handlePrev}
      sx={{
        position: 'absolute',
        display: 'inline-block',
        backgroundColor: 'success.main',
        color: '#ffffff',
        fontSize: '14px',
        fontWeight: 600,
        borderTopLeftRadius: '14px',
        borderTopRightRadius: '14px',
        top: 100,
        left: -104,
        padding: '6px 20px',
        overflow: 'hidden',
        outline: 'none',
        transition: 'all .2s ease',
        rotate: '270deg',
      }}
    >
      <Stack direction={"row"} alignItems={"center"}>
        <KeyboardDoubleArrowUp sx={{ mr: 1 }} fontSize="small" />
        <Typography color={"white"} fontSize={"16px"} fontWeight={600}>{t('crm.pricing.pricing.starterPlan')}</Typography>
      </Stack>
    </Box>
  );
}

function SlideRightBox({ handleNext }: any) {
  const { t } = useTranslation();
  return (
    <Box
      onClick={handleNext}
      sx={{
        position: 'absolute',
        display: 'inline-block',
        backgroundColor: 'info.main',
        color: '#ffffff',
        borderTopLeftRadius: '14px',
        borderTopRightRadius: '14px',
        top: 100,
        right: -116,
        padding: '6px 20px',
        overflow: 'hidden',
        outline: 'none',
        transition: 'all .2s ease',
        rotate: '90deg',
      }}
    >
      <Stack direction={"row"} alignItems={"center"}>
        <KeyboardDoubleArrowUp sx={{ mr: 1 }} fontSize="small" />
        <Typography color={"white"} fontSize={"16px"} fontWeight={600}>{t('crm.pricing.pricing.enterprisePlan')}</Typography>
      </Stack>
    </Box>
  );
}

const ResizableTypography = ({ text }: { text: string }) => {
  const [fontSize, setFontSize] = useState(16);
  const textRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const checkOverflow = () => {
      const element = textRef.current as HTMLDivElement;
      if (element?.scrollWidth > element?.clientWidth) {
        setFontSize(prevFontSize => Math.max(prevFontSize - 1, 10));
      }
    };

    checkOverflow();
    window.addEventListener('resize', checkOverflow);

    return () => {
      window.removeEventListener('resize', checkOverflow);
    };
  }, [text, fontSize]);

  return (
    <Typography
      ref={textRef}
      fontSize={`${fontSize}px`}
      fontWeight={400}
      height={"24px"}
      color={"primary.main"}
      sx={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
      {text}
    </Typography>
  );
};



export default List;
