import {
  IOrganizationSettingsUpdateFilterInput, IOrganizationSettingsUpdateInput, IOrganizationSettingsUpdateOwnRequest, IOrganizationSettingsUpdateOwnResponse,
  IOrganizationSettingsUpdateRequest, IOrganizationSettingsUpdateResponse, IOrganizationSettingsUpdateResult, organizationSettingsUpdateOwnQuery, organizationSettingsUpdateQuery,
  organizationSettingsDetailOwnQuery, IOrganizationSettingsDetailResult, IOrganizationSettingsDetailOwnRequest, IOrganizationSettingsDetailOwnResponse,
  organizationDetailOwnQuery, IOrganizationDetailOwnRequest, IOrganizationDetailOwnResponse, IOrganizationDetailResult,
  organizationCardDetailOwnQuery, IOrganizationCardDetailOwnRequest, IOrganizationCardDetailOwnResponse, IOrganizationCardDetailOwnResult,


  organizationBillingInfoCreateOwnQuery, IOrganizationBillingInfoCreateOwnRequest, IOrganizationBillingInfoCreateOwnResponse, IOrganizationBillingInfoCreateOwnResult, IOrganizationBillingInfoCreateOwnInput,
  organizationBillingInfoUpdateOwnQuery, IOrganizationBillingInfoUpdateOwnRequest, IOrganizationBillingInfoUpdateOwnResponse, IOrganizationBillingInfoUpdateOwnResult, IOrganizationBillingInfoUpdateOwnInput,
  organizationCardCreateOwnQuery, IOrganizationCardCreateOwnRequest, IOrganizationCardCreateOwnResponse, IOrganizationCardCreateOwnInput,
  organizationCardAddOwnQuery, IOrganizationCardAddOwnRequest, IOrganizationCardAddOwnResponse, IOrganizationCardAddOwnInput,
  organizationCardDeleteOwnQuery, IOrganizationCardDeleteOwnRequest, IOrganizationCardDeleteOwnResponse, IOrganizationCardDeleteOwnInput,
  organizationSettingsCommonUpdateOwnQuery, IOrganizationSettingsCommonUpdateOwnRequest, IOrganizationSettingsCommonUpdateOwnResponse, IOrganizationSettingsCommonUpdateOwnResult, IOrganizationSettingsCommonUpdateOwnInput,

  organizationLogoCreateQuery, IOrganizationLogoCreateRequest, IOrganizationLogoCreateResponse,
  organizationLogoAssignQuery, IOrganizationLogoAssignRequest, IOrganizationLogoAssignResponse,
  organizationUpdateOwnQuery, IOrganizationUpdateOwnRequest, IOrganizationUpdateOwnResponse, IOrganizationUpdateInput, IOrganizationUpdateResult,

} from "corede-common-cocrm";
import { commonApi } from "context/commonApi";
import { EndpointQueryBuilder } from "apps/helper/endpoint-query.builder";
import { demoTags } from "./demo.tags";
import { IGraphqlVariables, IBaseResult, IEntity, IDomainFileCreateResult, IDomainFileCreateOwnInputWithMimeType, IEntityFileAssignOwnInput, IEntityFileAssignResult, } from "corede-common";

export const demoApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({
    // queries

    organizationSettingsDetailOwn: builder.query<
      IOrganizationSettingsDetailResult,
      IGraphqlVariables<undefined, undefined>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IOrganizationSettingsDetailOwnRequest,
        IOrganizationSettingsDetailOwnResponse,
        IOrganizationSettingsDetailResult,
        undefined
      >({
        query: organizationSettingsDetailOwnQuery(),
        providesTags: [demoTags.settings],
      })
    ),

    organizationDetailOwn: builder.query<
      IOrganizationDetailResult,
      IGraphqlVariables<undefined, undefined>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IOrganizationDetailOwnRequest,
        IOrganizationDetailOwnResponse,
        IOrganizationDetailResult,
        undefined
      >({
        query: organizationDetailOwnQuery,
        providesTags: [demoTags.settings, demoTags.organizationDetail],
      })
    ),

    organizationCardDetailOwn: builder.query<
      IOrganizationCardDetailOwnResult,
      IGraphqlVariables<undefined>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IOrganizationCardDetailOwnRequest,
        IOrganizationCardDetailOwnResponse,
        IOrganizationCardDetailOwnResult,
        undefined
      >({
        query: organizationCardDetailOwnQuery(),
        providesTags: [demoTags.settings],
      })
    ),



    // mutation
    organizationSettingsUpdate: builder.mutation<
      IOrganizationSettingsUpdateResult,
      IGraphqlVariables<IOrganizationSettingsUpdateInput, IOrganizationSettingsUpdateFilterInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IOrganizationSettingsUpdateRequest,
        IOrganizationSettingsUpdateResponse,
        IOrganizationSettingsUpdateResult,
        IOrganizationSettingsUpdateInput,
        IOrganizationSettingsUpdateFilterInput
      >({
        query: organizationSettingsUpdateQuery,
        invalidatesTags: [demoTags.settings],
      })
    ),

    organizationSettingsUpdateOwn: builder.mutation<
      IOrganizationSettingsUpdateResult,
      IGraphqlVariables<IOrganizationSettingsUpdateInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IOrganizationSettingsUpdateOwnRequest,
        IOrganizationSettingsUpdateOwnResponse,
        IOrganizationSettingsUpdateResult,
        IOrganizationSettingsUpdateInput
      >({
        query: organizationSettingsUpdateOwnQuery,
        invalidatesTags: [demoTags.settings],
      })
    ),

    organizationBillingInfoCreateOwn: builder.mutation<
      IOrganizationBillingInfoCreateOwnResult,
      IGraphqlVariables<IOrganizationBillingInfoCreateOwnInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IOrganizationBillingInfoCreateOwnRequest,
        IOrganizationBillingInfoCreateOwnResponse,
        IOrganizationBillingInfoCreateOwnResult,
        IOrganizationBillingInfoCreateOwnInput
      >({
        query: organizationBillingInfoCreateOwnQuery,
        invalidatesTags: [demoTags.settings, demoTags.organizationDetail],
      })
    ),

    organizationBillingInfoUpdateOwn: builder.mutation<
      IOrganizationBillingInfoUpdateOwnResult,
      IGraphqlVariables<IOrganizationBillingInfoUpdateOwnInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IOrganizationBillingInfoUpdateOwnRequest,
        IOrganizationBillingInfoUpdateOwnResponse,
        IOrganizationBillingInfoUpdateOwnResult,
        IOrganizationBillingInfoUpdateOwnInput
      >({
        query: organizationBillingInfoUpdateOwnQuery,
        invalidatesTags: [demoTags.settings, demoTags.organizationDetail],
      })
    ),

    organizationSettingsCommonUpdateOwn: builder.mutation<
      IOrganizationSettingsCommonUpdateOwnResult,
      IGraphqlVariables<IOrganizationSettingsCommonUpdateOwnInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IOrganizationSettingsCommonUpdateOwnRequest,
        IOrganizationSettingsCommonUpdateOwnResponse,
        IOrganizationSettingsCommonUpdateOwnResult,
        IOrganizationSettingsCommonUpdateOwnInput
      >({
        query: organizationSettingsCommonUpdateOwnQuery,
        invalidatesTags: [demoTags.settings, demoTags.organizationDetail],
      })
    ),

    organizationCardCreateOwn: builder.mutation<
      IEntity,
      IGraphqlVariables<IOrganizationCardCreateOwnInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IOrganizationCardCreateOwnRequest,
        IOrganizationCardCreateOwnResponse,
        IEntity,
        IOrganizationCardCreateOwnInput
      >({
        query: organizationCardCreateOwnQuery,
        invalidatesTags: [demoTags.settings],
      })
    ),

    organizationCardAddOwn: builder.mutation<
      IEntity,
      IGraphqlVariables<IOrganizationCardAddOwnInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IOrganizationCardAddOwnRequest,
        IOrganizationCardAddOwnResponse,
        IEntity,
        IOrganizationCardAddOwnInput
      >({
        query: organizationCardAddOwnQuery,
        invalidatesTags: [demoTags.settings],
      })
    ),

    organizationCardDeleteOwn: builder.mutation<
      IEntity,
      IGraphqlVariables<IOrganizationCardDeleteOwnInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IOrganizationCardDeleteOwnRequest,
        IOrganizationCardDeleteOwnResponse,
        IEntity,
        IOrganizationCardDeleteOwnInput
      >({
        query: organizationCardDeleteOwnQuery,
        invalidatesTags: [demoTags.settings],
      })
    ),

    organizationLogoCreate: builder.mutation<
      IDomainFileCreateResult,
      IGraphqlVariables<IDomainFileCreateOwnInputWithMimeType>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IOrganizationLogoCreateRequest,
        IOrganizationLogoCreateResponse,
        IDomainFileCreateResult,
        IDomainFileCreateOwnInputWithMimeType
      >({
        query: organizationLogoCreateQuery,
        invalidatesTags: [demoTags.settings, demoTags.organizationDetail],
      })
    ),

    organizationLogoAssign: builder.mutation<
      IEntityFileAssignResult,
      IGraphqlVariables<IEntityFileAssignOwnInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IOrganizationLogoAssignRequest,
        IOrganizationLogoAssignResponse,
        IEntityFileAssignResult,
        IEntityFileAssignOwnInput
      >({
        query: organizationLogoAssignQuery,
        invalidatesTags: [demoTags.settings, demoTags.organizationDetail],
      })
    ),

    organizationUpdateOwn: builder.mutation<
      IOrganizationUpdateResult,
      IGraphqlVariables<IOrganizationUpdateInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IOrganizationUpdateOwnRequest,
        IOrganizationUpdateOwnResponse,
        IOrganizationUpdateResult,
        IOrganizationUpdateInput
      >({
        query: organizationUpdateOwnQuery,
        invalidatesTags: [demoTags.settings, demoTags.organizationDetail],
      })
    ),


  }),
});

export const {
  useOrganizationSettingsDetailOwnQuery,
  useLazyOrganizationSettingsDetailOwnQuery,
  useOrganizationDetailOwnQuery,
  useLazyOrganizationDetailOwnQuery,
  useOrganizationCardDetailOwnQuery,
  useLazyOrganizationCardDetailOwnQuery,

  useOrganizationSettingsUpdateMutation,
  useOrganizationSettingsUpdateOwnMutation,
  useOrganizationBillingInfoCreateOwnMutation,
  useOrganizationBillingInfoUpdateOwnMutation,
  useOrganizationSettingsCommonUpdateOwnMutation,
  useOrganizationCardCreateOwnMutation,
  useOrganizationCardAddOwnMutation,
  useOrganizationCardDeleteOwnMutation,
  useOrganizationLogoCreateMutation,
  useOrganizationLogoAssignMutation,
  useOrganizationUpdateOwnMutation
} = demoApi;
