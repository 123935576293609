

import { commonRoutes } from "routes/route.common";

export const organizationalChartDomainRoutes = {
  base: "organizational-chart",
  subdomains: {
    users: {
      base: "users",
    },
    departments: {
      base: "departments",
    },
    permissions: {
      base: "permissions",
    }
    // add subdomain route names here
  },
};

// Organization routes
export const baseOrganizationalChartRoute = (): string => `/${organizationalChartDomainRoutes.base}/`;
export const listUsersRoute = (): string =>
  `/${organizationalChartDomainRoutes.base}/${organizationalChartDomainRoutes.subdomains.users.base}/`;
export const viewUsersRoute = (id: string): string =>
  `/${organizationalChartDomainRoutes.base}/${organizationalChartDomainRoutes.subdomains.users.base}/${id}`;
export const createUsersRoute = (): string =>
  `/${organizationalChartDomainRoutes.base}/${organizationalChartDomainRoutes.subdomains.users.base}/${commonRoutes.create}`;
export const updateUsersRoute = (id: string): string =>
  `/${organizationalChartDomainRoutes.base}/${organizationalChartDomainRoutes.subdomains.users.base}/${id}/${commonRoutes.update}`;
export const rolesUsersRoute = (): string =>
  `/${organizationalChartDomainRoutes.base}/${organizationalChartDomainRoutes.subdomains.users.base}/${commonRoutes.roles}`;
export const userListUsersRoute = (): string =>
  `/${organizationalChartDomainRoutes.base}/${organizationalChartDomainRoutes.subdomains.users.base}/${commonRoutes.userList}`;
export const listDepartmentsRoute = (): string =>
  `/${organizationalChartDomainRoutes.base}/${organizationalChartDomainRoutes.subdomains.departments.base}`;
export const listPermissionsRoute = ({ redirect }: { redirect?: string }): string =>
  `/${organizationalChartDomainRoutes.base}/${organizationalChartDomainRoutes.subdomains.permissions.base}${redirect ? `?redirect=${redirect}` : ''}`;

// add subdomain routes here
