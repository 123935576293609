const locale = {
  customerCategories: "Customer Categories",
  headerTitle: "Customer Categories",
  createCustomerCategory: "Create Customer Category",
  columnId: "ID",
  columnName: "Name",
  columnRoles: "Roles",
  columnActions: "Actions",
  rolesButton: "Edit Roles",
  createCustomerCategoryTitle: "Create New Customer Category",
  customerCategoryNameLabel: "Customer Category Name",
  rolesLabel: "Roles",
  createCustomerCategoryButton: "Create",
  emptyStateContent1: "No customer categories found",
  emptyStateContent2:
    "You can create new customer categories by clicking the button below.",
  addNewRole: "Add New Role",
  deletedSuccessfully: "Customer category deleted successfully",
  deleteConfirm: "Are you sure you want to delete this customer category?",
  delete: "Delete",
  enTranslation: "English Translation",
  trTranslation: "Turkish Translation",
  department: "Department",
  parentCategory: "Parent Category",
  icon: "Icon",
  name: "Name",
  createSuccess: "Customer category created successfully",
  addDepartment: "Add Department",
  showMore: "Show More",
  columnIcon: "Icon",
  columnDepartment: "Department",
  columnParentCategory: "Parent Category",
  selectIconTitle: "Select an Icon",
  searchIcons: "Search Icons",
  updateCustomerCategoryTitle: "Update Customer Category",
  updateCustomerCategoryButton: "Update",
  updateSuccess: "Customer category updated successfully",
  actions: "Actions",
  createdAt: "Created At",
};

export default locale;
