
export const projectsDomainRoutes = {
  base: "projects",
  subdomains: {
    projects: {
      base: "projects",
    },
  },
};


export const baseProjectsRoute = (): string => `/${projectsDomainRoutes.base}/`;
export const listProjectsRoute = (): string =>
  `/${projectsDomainRoutes.base}/${projectsDomainRoutes.subdomains.projects.base}`;