import { Stack, Typography, Box, Theme, useMediaQuery } from '@mui/material'
import { useOrganizationDetailOwnQuery } from 'apps/auth/context'
import { listPricingRoute } from 'apps/crm/domains/16-pricing/routes/pricing.base.route'
import { FilledButton, Iconify } from 'components'
import Logo from 'assets/images/logo.png'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

interface IHomeForRestricted { }

const HomeForRestricted = (props: IHomeForRestricted) => {

    const {t} = useTranslation();
    const navigate = useNavigate();
    const downLg = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));
    const downSm = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

    const { data: organizationDetailOwn } = useOrganizationDetailOwnQuery({});

    const trialStatus = organizationDetailOwn?.activeSubscriptions?.trial?.status;
    const mainStatus = organizationDetailOwn?.activeSubscriptions?.main?.status;

    return <Stack direction={{ xs: "column", lg: "row" }} gap={2} my={2}>

        <Stack direction={"column"} gap={1} alignItems={"flex-start"}>
            <Typography variant="h4">{t("crm.dashboard.dashboard.title")}</Typography>

            {trialStatus === "expired" && <Typography color={"error"}>{t("crm.dashboard.dashboard.trialExpired")}</Typography>}
            {mainStatus === "failed" && <Typography color={"error"}>{t("crm.dashboard.dashboard.mainFailed")}</Typography>}
            {mainStatus === "completed" && <Typography color={"error"}>{t("crm.dashboard.dashboard.mainCompleted")}</Typography>}
            <Box height={"10px"} />
            <Typography fontSize={"18px"}>{t("crm.dashboard.dashboard.description")}</Typography>
            <Box height={"10px"} />
            <Typography fontSize={"18px"}>{t("crm.dashboard.dashboard.featuresTitle")}</Typography>
            <Box height={"10px"} />
            <Typography fontSize={"18px"}>• {t("crm.dashboard.dashboard.features1")}</Typography>
            <Typography fontSize={"18px"}>• {t("crm.dashboard.dashboard.features2")}</Typography>
            <Typography fontSize={"18px"}>• {t("crm.dashboard.dashboard.features3")}</Typography>
            <Typography fontSize={"18px"}>• {t("crm.dashboard.dashboard.features4")}</Typography>
            <Box height={"30px"} />
            <Box
                sx={{
                    animation: 'bounce 2s ease-in-out infinite',
                    '@keyframes bounce': {
                        '0%, 100%': {
                            transform: 'translateY(0)',
                        },
                        '50%': {
                            transform: 'translateY(-5px)',
                        },
                    },
                }}>
                <FilledButton
                    title={t("crm.dashboard.dashboard.buttonTitle")}
                    leftIcon={<Iconify icon={"gravity-ui:persons-lock"} sx={{ mr: 1 }} />}
                    onClick={() => navigate(listPricingRoute())}
                />
            </Box>
        </Stack>

        <Box mt={"100px"}>
            <img
                src={Logo}
                width={downSm ? 200 : downLg ? 200 : 300}
                alt="cocrm-logo"
            />
            {/* <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: crmAnimation,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
          }
        }
        }
        width={downSm ? 200 : downLg ? 350 : 500}
        height={downSm ? 200 : downLg ? 350 : 500}
        speed={0.8}
      /> */}
        </Box>
    </Stack>
}

export default HomeForRestricted


// for trial active 
// <Stack direction={{ xs: "column", lg: "row" }} gap={2} my={2}>

// <Stack direction={"column"} gap={1} alignItems={"flex-start"}>
//   <Typography color={"error"}>Time left until the trial package ends: {moment(organizationDetailOwn?.activeSubscriptions?.trial?.dueDate).fromNow()}</Typography>
// </Stack>
// </Stack>