import React, { useEffect, useState } from 'react'
import { Box, FormControl, Grid, MenuItem, Select, Stack, Typography, useMediaQuery } from '@mui/material'
import BgPattern from "assets/images/bg-pattern.png";
import BgUnderline from "assets/images/bg-underline.png";
import { PageOpening, Slider } from 'components';
import { setThemeMode, useAppDispatch } from 'context';
import { Language } from 'corede-common';
import { changeLanguage, getCurrentLanguage } from 'localization';
import { useTranslation } from 'react-i18next';

export const AuthLayout = ({ children }: { children: React.ReactNode }) => {

  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();
  const dispatch = useAppDispatch();
  const upXl = useMediaQuery('(min-width:1920px)');
  const [language, setLanguage] = useState<Language>(currentLanguage);


  useEffect(() => {
    dispatch(setThemeMode("light"));
  }, [dispatch]);

  return <div style={{ backgroundColor: "#f8f8fd", marginLeft: 'auto', marginRight: 'auto' }}>

    <Box maxWidth={"xl"} m="auto" bgcolor={"#f8f8fd"} height={"calc(100dvh)"}>
      <Grid container spacing={{ xs: 0, md: 1 }} bgcolor={"#f8f8fd"}>
        <Box sx={{ position: 'absolute', top: 0, display: { xs: 'flex', md: 'none' }, width: "100%", height: "5px", bgcolor: 'secondary.main' }} />
        <Box sx={{ position: 'absolute', bottom: 0, display: { xs: 'flex', md: 'none' }, width: "100%", height: "5px", bgcolor: 'secondary.main' }} />

        <Grid item xs={12} md={6} sx={{ display: { xs: 'none', md: 'initial' } }} bgcolor={"#f8f8fd"}>
          <Stack
            direction={"column"}
            alignItems="center"
            justifyContent="center"
            height={"calc(100dvh)"}
          >
            <Box bgcolor={"#000"} width={{ xs: "85%", xl: "80%" }} height={"88%"} borderRadius={5} display={"flex"}
              justifyContent={"center"} alignItems={"center"} position={"relative"} >
              <Box p={{ xs: 7, lg: 10, xl: 12 }} zIndex={2}>
                <Typography variant='h1' color={"#fff"} fontSize={50} fontWeight={500} >
                  {t("auth.growYourBusiness")}
                </Typography>
                <Box position={"relative"}>
                  <Typography variant='h1' color={"#fff"} fontSize={50} fontWeight={500}>
                    {t("auth.business")}
                  </Typography>
                  <Box width={"220px"} height={20} position={"absolute"} bottom={-14} left={0}>
                    <img src={BgUnderline} alt="bg-underline" width="100%" height={"100%"} />
                  </Box>
                </Box>
                <Typography variant='h2' color={"#fff"} fontSize={16} fontWeight={300} mt={5}>
                  {t("auth.manageYourBusiness")}
                </Typography>

                <Box mt={5} display={"grid"}>
                  <Slider />
                </Box>
              </Box>

              <Box width={"150px"} height={"130px"} position={"absolute"} top={0} left={0} zIndex={1} >
                <img src={BgPattern} alt={t("auth.bgPattern")} width="100%" height={"100%"} />
              </Box>
              <Box width={"150px"} height={"130px"} position={"absolute"} bottom={-25} right={80} sx={{ transform: "rotate(200deg)" }}>
                <img src={BgPattern} alt={t("auth.bgPattern")} width="100%" height={"100%"} />
              </Box>
            </Box>

          </Stack>
        </Grid>

        <Grid item xs={12} md={6} bgcolor={"#f8f8fd"}>

          <Stack p={{ xs: 2, sm: 0 }} px={{ xs: 0, sm: 4 }} justifyContent="center" pt={{ xs: 5, sm: 0 }} >
            <PageOpening>
              {upXl ? <Box width={"80%"} mx={"auto"}>
                {children}
              </Box> : children}

              <Stack direction={"row"} width={"100%"} justifyContent={"center"} alignItems={"center"} mt={1} mb={2}>
                <FormControl>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={language}
                    onChange={(e) => {
                      setLanguage(e.target.value as Language);
                      changeLanguage(e.target.value as Language);
                    }}
                    size='small'
                    sx={{
                      fontSize: "14px", borderRadius: "12px", fontWeight: 600,
                      bgcolor: 'background.third'
                    }}
                  >
                    <MenuItem value={Language.en} sx={{ fontSize: "14px" }}>
                      <Stack direction={"row"} gap={1} alignItems={"center"}>
                        English
                      </Stack>
                    </MenuItem>
                    <MenuItem value={Language.tr} sx={{ fontSize: "14px" }}>
                      <Stack direction={"row"} gap={1} alignItems={"center"}>
                        Türkçe
                      </Stack>
                    </MenuItem>
                  </Select>
                </FormControl>
              </Stack>
            </PageOpening>
          </Stack>
        </Grid>

      </Grid>
    </Box>
  </div>
}
