

export const tasksDomainRoutes = {
  base: "tasks",
  subdomains: {
    tasks: {
      base: "tasks",
    },
  },
};


export const baseTasksRoute = (): string => `/${tasksDomainRoutes.base}/`;
export const listTasksRoute = (): string =>
  `/${tasksDomainRoutes.base}/${tasksDomainRoutes.subdomains.tasks.base}`;
