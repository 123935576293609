import { Grid, Stack, Typography } from "@mui/material";
import { HeaderBase } from "components/contentHeaders/crm/HeaderBase";
import { useTranslation } from "react-i18next";
import { OutlinedButton } from "components";
import { listLeadRoute } from "../../../routes/lead.base.route";
import * as Icons from "@mui/icons-material";
import { memo, useRef } from "react";
import { BASE_URL } from "utils";
import { getAccessToken } from "utils/getUserInfo";
import axios from "axios";
import { enqueueSnackbar } from "notistack";
import moment from "moment";
import { unknownError } from "corede-common";
import { getCurrentLanguage } from "localization";

const Import = memo(() => {

  // general
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();
  const inputFileRef = useRef<HTMLInputElement | null>(null);

  const handleDownloadExampleForm = () => {

    let data = JSON.stringify({
      "filter": {}
    });

    let config = {
      method: 'post',
      url: `${BASE_URL}/leads/import/example`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getAccessToken()}`,
      },
      data: data,
      responseType: 'blob'
    };

    axios.request(config as any)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `lead-example-import.xlsx`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        enqueueSnackbar(t("crm.lead.lead.downloadExampleFormSuccessfully"), { variant: "success" });
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data?.error?.message[currentLanguage] ?? unknownError.message[currentLanguage], { variant: 'error' });
      });



  };
  

  const handleDownloadForm = () => {

    let data = JSON.stringify({
      "filter": {}
    });

    let config = {
      method: 'post',
      url: `${BASE_URL}/leads/export`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getAccessToken()}`,
      },
      data: data,
      responseType: 'blob'
    };

    axios.request(config as any)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `leads-${moment().format("DD-MM-YYYY")}.xlsx`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        enqueueSnackbar(t("crm.lead.lead.downloadFormSuccessfully"), { variant: "success" });
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.response.data?.error?.message[currentLanguage] ?? unknownError.message[currentLanguage], { variant: 'error' });

      });



  };

  
  const handleImportForm = (file: File) => {

    let formData = new FormData();
    formData.append("file", file);

    let config = {
      method: 'post',
      url: `${BASE_URL}/leads/import`,
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${getAccessToken()}`,
      },
      data: formData,
    };

    axios.request(config as any)
      .then((response) => {
        enqueueSnackbar(t("crm.lead.lead.importedSuccessfully"), { variant: "success" });
        setTimeout(() => {
          window.location.href = listLeadRoute();
        }, 1500);
      })
      .catch((error) => {
        console.log(error.response.data);
        enqueueSnackbar(error.response.data?.error?.message[currentLanguage] ?? unknownError.message[currentLanguage], { variant: 'error' });

      });

  };



  return (
    <Grid item xs={12} height={"calc(100vh - 260px)"}>
      <HeaderBase
        title={t(`crm.lead.lead.importTitle`)}
        showBackButton={true}
      />

      <Stack
        alignItems="center"
        justifyContent="center"
        bgcolor={"background.default"}
        gap={1}
        borderRadius={3}
        width={"100%"}
        height={"100%"}
      >
        <Icons.UploadFileOutlined sx={{ opacity: 0.2, fontSize: "100px" }} />
        <Typography variant="body2">{t("crm.lead.lead.importText1")}</Typography>
        <Typography variant="body2">{t("crm.lead.lead.importText2")}</Typography>
        <Stack direction="row" spacing={1} mt={2}>
          <OutlinedButton
            title={t("crm.lead.lead.downloadExample")}
            leftIcon={<Icons.ArticleOutlined sx={{ mr: 1 }} fontSize="small" />}
            onClick={handleDownloadExampleForm}
          />
          <OutlinedButton
            title={t("crm.lead.lead.importLead")}
            leftIcon={<Icons.UploadFileOutlined sx={{ mr: 1 }} fontSize="small" />}
            onClick={() => inputFileRef.current?.click()}
          />
          <input
            type="file"
            hidden
            ref={inputFileRef}
            onChange={(e) => {
              handleImportForm(e.target.files![0]);
            }}
          />
        </Stack>
      </Stack>
    </Grid >
  );
});

export default Import;



