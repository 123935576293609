import { createSlice } from "@reduxjs/toolkit";
import { commonInitialState, commonReducers } from "context/slices";
import { PayloadAction } from "@reduxjs/toolkit";

interface ILeadListType {
  leadListType: string;
}

const { actions, reducer } = createSlice({
  name: "lead",
  initialState: {
    ...commonInitialState,
    leadListType: "list",
  },
  reducers: {
    ...commonReducers,
    setListType: (state: ILeadListType, action: PayloadAction<string>) => {
      state.leadListType = action.payload;
    },
  },
});

export const leadActions = actions;
export const leadSlice = reducer;
