const locale = {
  reminders: "Hatırlatıcılar",
  createReminder: "Hatırlatıcı Oluştur",
  title: "Başlık",
  content: "İçerik",
  addReminder: "Hatırlatıcı Ekle",
  priority: "Öncelik",
  remindDate: "Hatırlatma Tarihi",
  remindUsers: "Bildirilecek Kullanıcılar",
  tags: "Etiketler",
  tagsPlaceholder: "Lütfen etiket ekleyin ve ardından Enter'a basın",
  createReminderSuccess: "Hatırlatıcı oluşturuldu",
  target: "Hedef",
  updateReminder: "Hatırlatıcı Güncelle",
  deleteReminder: "Hatırlatıcı Sil",
  deletedSuccessfully: "Hatırlatıcı silindi",
  deleteReminderContent: "Bu hatırlatıcıyı silmek istediğinizden emin misiniz? Bu işlem geri alınamaz.",
  reminder: "Sözleşme",
  reminderConversions: "Sözleşme Dönüştürme",
  emptyStateDesc1: "Henüz herhangi bir sözleşme eklenmedi.",
  emptyStateDesc2: "Faturalandırmanızı kolaylaştırmak ve müşterilerinizle net anlaşmalar sürdürmek için tüm sözleşme ayrıntılarını burada saklayın ve yönetin.",
  updateReminderSuccess: "Hatırlatıcı başarıyla güncellendi.",
};
export default locale;
