
import { IMenuItem } from "interfaces/menu-item.interface";
import {
  baseOrganizationalChartRoute,
  listDepartmentsRoute,
  listUsersRoute,
  listPermissionsRoute,
} from "../routes/organizationalChart.base.route";

import { useTranslation } from 'react-i18next';
import { PermissionSubdomain } from "corede-common-cocrm";

export const useOrganizationalChartDomainMenuItem = (): IMenuItem => {
  const { t } = useTranslation();

  return {
    itemId: baseOrganizationalChartRoute(),
    title: t("organizationalChart"),
    path: baseOrganizationalChartRoute(),
    children: [
      {
        itemId: listUsersRoute(),
        title: t("users"),
        path: listUsersRoute(),
        subdomain: PermissionSubdomain.user,
      },
      {
        itemId: listDepartmentsRoute(),
        title: t("departments"),
        path: listDepartmentsRoute(),
        subdomain: PermissionSubdomain.department,
      },
      {
        title: t("permissions"),
        path: listPermissionsRoute({}),
        subdomain: PermissionSubdomain.permission,
        itemId: listPermissionsRoute({}),
      },
    ],
  };
};

