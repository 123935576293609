
import contractsLocale from "../subdomains/contracts/locales/en";

const locale = {

  // Todo
  // users: {
  //   ...usersLocale,
  // },
  contracts: {
    ...contractsLocale,
  },
};

export default locale;
