/* eslint-disable react-hooks/exhaustive-deps */
import { Autocomplete, Box, Button, CircularProgress, Grid, InputLabel, Stack, SwipeableDrawer, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { convertEnums, getCurrentLanguage } from "localization";
import { FileContentType, Currency, IGraphqlVariables, unknownError, IFileMetadata } from "corede-common";
import { IProductCreateInput, PriceType, ProductType } from "corede-common-cocrm";
import { enqueueSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useProductCreateMutation } from "../../common/context/productsServices.api";
import { validateCreateProductsInput } from "../validations/create.validation";
import { useProductCategoryListQuery } from "../../productCategories/context/productCategories.api"
import ActionDrawer from "components/drawer/ActionDrawer";
import { UploadImageComponent } from "components/uploadFile/UploadImage";
import ReactQuill from "react-quill-new";

interface IProductCreateDrawer {
    open: boolean
    setOpen: (open: boolean) => void
}

export const ProductCreateDrawer = (props: IProductCreateDrawer) => {
    const { t } = useTranslation();
    const currentLanguage = getCurrentLanguage();
    const { data: productCategoryListData, isLoading: productCategoryListLoading, error: productCategoryListError } = useProductCategoryListQuery({
        input: {
            pagination: {
                sort: {
                    createdAt: 1
                }
            }
        }
    });
    const [loading, setLoading] = useState(false);
    const localizedCurrencies = convertEnums(Currency);
    const localizedPriceTypes = convertEnums(PriceType);
    const [productCreate, { data: productCreateData, isLoading: productCreateLoading, error: productCreateError }] = useProductCreateMutation();

    const { values, handleSubmit, handleBlur, setFieldValue, errors, touched, resetForm, isValid } =
        useFormik({
            initialValues: {
                input: {
                    type: ProductType.product,
                    image: undefined,
                    name: '',
                    sku: '',
                    description: '',
                    currency: Currency.USD,
                    price: 0,
                    priceType: PriceType.monthly,
                    discountedPrice: 0,
                    unit: 0,
                    categoryId: "",
                    natTaxRate: 0,
                    intTaxRate: 0,

                },
            },
            enableReinitialize: true,
            validationSchema: validateCreateProductsInput,
            onSubmit: async (values: IGraphqlVariables<IProductCreateInput>) => {
                setLoading(true);
                productCreate({ input: values.input });
                setLoading(false);
            }
        });

    useEffect(() => {
        if (productCreateData) {
            props.setOpen(false);
            enqueueSnackbar(t("crm.productsAndServices.products.productCreateSuccess"), { variant: 'success' });
            resetForm();
        }
    }, [productCreateData, resetForm]);

    useEffect(() => {
        if (productCreateError) {
            enqueueSnackbar((productCreateError as any)?.error?.message[currentLanguage] ?? unknownError.message[currentLanguage], { variant: 'error' });
        }
    }, [productCreateError]);

    return <ActionDrawer
        open={props.open}
        setOpen={props.setOpen}
        title={t("crm.productsAndServices.products.createProduct")}
        loading={loading || productCategoryListLoading || productCreateLoading}
        handleSubmit={handleSubmit}
        disabled={loading || !isValid || productCreateLoading || !(values as any).input?.name}
        buttonTitle={t("crm.productsAndServices.products.createProduct")}
        size="medium"
    >
        <Grid item xs={6} >
            <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.productsAndServices.products.image")}*</InputLabel>
            <UploadImageComponent
                setImageFile={(imageFile) => setFieldValue("input.image", imageFile)}
            />
        </Grid>
        <Grid item xs={6}>
            <Grid container spacing={2} >
                <Grid item xs={12} >
                    <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.productsAndServices.products.name")}*</InputLabel>
                    <TextField
                        fullWidth
                        required
                        size="small"
                        name="input.name"
                        value={(values as any).input.name}
                        onChange={(e) => setFieldValue("input.name", e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                        onBlur={handleBlur}
                        error={!!((errors as any)?.input?.name && (touched as any)?.input?.name)}
                        helperText={(touched as any)?.input?.name && (errors as any)?.input?.name}
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.productsAndServices.products.currency")}*</InputLabel>
                    <Autocomplete
                        id="currency"
                        fullWidth
                        size="small"
                        options={Object.values(Currency).map((currency) => ({
                            name: localizedCurrencies[currency as keyof typeof Currency],
                            _id: currency,
                        }))}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField {...params} />}
                        value={
                            values.input?.currency
                                ? { name: localizedCurrencies[values.input?.currency as keyof typeof Currency], _id: values.input?.currency }
                                : null
                        }
                        onChange={(e, value) => {
                            setFieldValue("input.currency", value?._id);
                        }}
                    />
                </Grid>
            </Grid>
        </Grid>
        <Grid item xs={12} >
            <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.productsAndServices.products.description")}*</InputLabel>
            <Box sx={{ bgcolor: 'background.secondary', borderRadius: 2, height: "160px", mt: 1, border: "1px solid", borderColor: "grey.400" }}>
                <ReactQuill
                    id="input.description"
                    className="react-quill"
                    theme="snow"
                    value={(values as any).input.description}
                    onChange={(content, delta, source, editor) => setFieldValue("input.description", editor.getHTML())}
                    onBlur={() => handleBlur("input.description")}
                    placeholder={t("crm.productsAndServices.products.description")}
                    style={{ height: 130, boxSizing: "border-box" }}
                />
            </Box>
            {(errors as any).input?.description && (
                <Typography color="error" variant="caption">
                    {(errors as any).input?.description}
                </Typography>
            )}
        </Grid>

        <Grid item xs={6} >
            <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.productsAndServices.products.price")}*</InputLabel>
            <TextField
                fullWidth
                required
                size="small"
                type="number"
                name="input.price"
                value={Number((values as any).input.price)}
                onChange={(e) => setFieldValue("input.price", Number(e.target.value))}
                onBlur={handleBlur}
                error={!!((errors as any)?.input?.price && (touched as any)?.input?.price)}
                helperText={(touched as any)?.input?.price && (errors as any)?.input?.price}
            />
        </Grid>

        <Grid item xs={6} >
            <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.productsAndServices.products.discountedPrice")}*</InputLabel>
            <TextField
                fullWidth
                required
                size="small"
                type="number"
                name="input.discountedPrice"
                value={(values as any).input.discountedPrice}
                onChange={(e) => setFieldValue("input.discountedPrice", Number(e.target.value))}
                onBlur={handleBlur}
                error={!!((errors as any)?.input?.discountedPrice && (touched as any)?.input?.discountedPrice)}
                helperText={(touched as any)?.input?.discountedPrice && (errors as any)?.input?.discountedPrice}
            />
        </Grid>

        <Grid item xs={6} >
            <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.productsAndServices.products.unit")}*</InputLabel>
            <TextField
                fullWidth
                required
                size="small"
                type="number"
                name="input.unit"
                value={(values as any).input.unit}
                onChange={(e) => setFieldValue("input.unit", Number(e.target.value))}
                onBlur={handleBlur}
                error={!!((errors as any)?.input?.unit && (touched as any)?.input?.unit)}
                helperText={(touched as any)?.input?.unit && (errors as any)?.input?.unit}
            />
        </Grid>

        <Grid item xs={6} >
            <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.productsAndServices.products.unitPrice")}*</InputLabel>
            <TextField
                fullWidth
                required
                size="small"
                type="number"
                name="input.unit"
                value={(values as any).input.unit * (values as any).input.discountedPrice}
            />
        </Grid>

        <Grid item xs={6}  >
            <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.productsAndServices.products.categories")}</InputLabel>
            <Autocomplete
                id="categoryId"
                isOptionEqualToValue={(option, value) => option._id === value._id}
                fullWidth
                size="small"
                getOptionLabel={(option) => option.name}
                options={productCategoryListData?.data || []}
                loading={productCategoryListLoading}
                renderInput={(params) => <TextField {...params} />}
                value={
                    values.input?.categoryId
                        ? productCategoryListData?.data?.filter((category) =>
                            (values as any).input.categoryId.includes(category._id)
                        )[0]
                        : undefined
                }
                onChange={(e, value) => {
                    setFieldValue(
                        "input.categoryId",
                        value?._id
                    );
                }}
            />

        </Grid>

        <Grid item xs={6} >
            <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.productsAndServices.products.natTaxRate")}*</InputLabel>
            <TextField
                fullWidth
                required
                type="number"
                size="small"
                name="input.natTaxRate"
                value={(values as any).input.natTaxRate}
                onChange={(e) => setFieldValue("input.natTaxRate", Number(e.target.value))}
                onBlur={handleBlur}
                error={!!((errors as any)?.input?.natTaxRate && (touched as any)?.input?.natTaxRate)}
                helperText={(touched as any)?.input?.natTaxRate && (errors as any)?.input?.natTaxRate}
            />
        </Grid>


        <Grid item xs={6} >
            <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.productsAndServices.products.intTaxRate")}*</InputLabel>
            <TextField
                fullWidth
                required
                size="small"
                type="number"
                name="input.intTaxRate"
                value={(values as any).input.intTaxRate}
                onChange={(e) => setFieldValue("input.intTaxRate", Number(e.target.value))}
                onBlur={handleBlur}
                error={!!((errors as any)?.input?.intTaxRate && (touched as any)?.input?.intTaxRate)}
                helperText={(touched as any)?.input?.intTaxRate && (errors as any)?.input?.intTaxRate}
            />
        </Grid>




        <Grid item xs={6} >
            <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.productsAndServices.products.sku")}*</InputLabel>
            <TextField
                fullWidth
                name="input.sku"
                size="small"
                value={(values as any).input.sku}
                onChange={(e) => setFieldValue("input.sku", e.target.value)}
                onBlur={handleBlur}
                error={!!((errors as any)?.input?.sku && (touched as any)?.input?.sku)}
                helperText={(touched as any)?.input?.sku && (errors as any)?.input?.sku}
            />
        </Grid>


        {/* <Grid item xs={6}>
            <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.productsAndServices.products.priceType")}*</InputLabel>
            <Autocomplete
                id="priceType"
                fullWidth
                size="small"
                options={Object.values(PriceType).map((priceType) => ({
                    name: localizedPriceTypes[priceType as keyof typeof PriceType],
                    _id: priceType,
                }))}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => <TextField {...params} />}
                value={
                    values.input?.priceType
                        ? { name: localizedPriceTypes[values.input?.priceType as keyof typeof PriceType], _id: values.input?.priceType }
                        : null
                }
                onChange={(e, value) => {
                    setFieldValue("input.priceType", value?._id);
                }}
            />
        </Grid> */}





    </ActionDrawer >;
};


export const colors = [
    "primary.lighter",
    "secondary.lighter",
    "success.lighter",
    "error.lighter",
    "warning.lighter",
    "info.lighter",
    "primary.light",
    "secondary.light",
    "success.light",
    "error.light",
    "warning.light",
    "info.light",
    "primary.main",
    "secondary.main",
    "success.main",
    "error.main",
    "warning.main",
    "info.main",
    "primary.dark",
    "secondary.dark",
    "success.dark",
    "error.dark",
    "warning.dark",
    "info.dark",
    "primary.darker",
    "secondary.darker",
    "success.darker",
    "error.darker",
    "warning.darker",
    "info.darker",
];
