import { Grid } from "@mui/material";
import { Outlet } from "react-router-dom";


export const CustomerDomainLayout = () => {


  return (
    <Grid container spacing={3}>
      <Outlet />
    </Grid>
  );
};
