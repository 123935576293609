/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import {
  useLeadStatusesQuery,
  useLeadStatusUpdateMutation,
} from "../context/leadStatuses.api";
import * as Icons from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { HeaderBase } from "components/contentHeaders/crm/HeaderBase";
import Delete from "./Delete";
import {
  IBaseError,
  IGraphqlVariables,
  isNullOrUndefinedOrEmptyObject,
} from "corede-common";
import { LeadStatusUpdateDrawer } from "./LeadStatusUpdateDialog";
import LeadStatusCreateWrapper from "./LeadStatusCreateWrapper";
import { ILeadStatus, ILeadStatusesInput } from "corede-common-cocrm";
import { DefaultErrorHandlerUseEffect } from "utils/useEffect.helper";
import { getCurrentLanguage } from "localization";
import { BaseDataGrid } from "components/baseDataGrid/BaseDataGrid";
import { representString } from "utils/representationHelper";
import { BaseGridColType } from "components/baseDataGrid/infra/enums";

const List = () => {
  // init
  const { t } = useTranslation();
  const downMd = useMediaQuery((theme: any) => theme.breakpoints.down("md"));
  const currentLanguage = getCurrentLanguage();

  // states

  const [openLeadStatusCreateDrawer, setOpenLeadStatusCreateDrawer] =
    useState(false);
  const [openLeadStatusUpdateDrawer, setOpenLeadStatusUpdateDrawer] =
    useState(false);
  const [openLeadStatusDeleteDrawer, setOpenLeadStatusDeleteDrawer] =
    useState(false);
  const [selectedLeadStatus, setSelectedLeadStatus] = useState<ILeadStatus>();
  const [leadStatusListFilter, setLeadStatusListFilter] = useState<
    IGraphqlVariables<ILeadStatusesInput>
  >({
    input: {
      filter: {},
      pagination: { page: 1, pageSize: 10, sort: { index: 1 } },
    },
  });

  // queries
  const {
    data: leadStatusListData,
    isLoading: leadStatusListLoading,
    isFetching: leadStatusListFetching,
  } = useLeadStatusesQuery(leadStatusListFilter);
  const [
    leadStatusUpdate,
    { isLoading: leadStatusUpdateLoading, error: leadStatusUpdateError },
  ] = useLeadStatusUpdateMutation();

  const isEmptyState =
    leadStatusListData?.data?.length === 0 &&
    isNullOrUndefinedOrEmptyObject(leadStatusListFilter.filter);

  // use effects
  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      leadStatusUpdateError as IBaseError,
      currentLanguage
    );
  }, [leadStatusUpdateError]);

  return (
    <Grid item xs={12} height={"calc(100vh - 260px)"}>
      <HeaderBase
        title={t(`crm.lead.leadStatuses.title`)}
        rightButton={{
          icon: (
            <Icons.AddCircle sx={{ mr: 1, opacity: 0.7 }} fontSize="small" />
          ),
          title: t(`crm.lead.leadStatuses.create`),
          onClick: () => setOpenLeadStatusCreateDrawer(true),
          visibility: !isEmptyState,
        }}
      />
      <Box
        minHeight={"350px"}
        height={"600px"}
        mt={1}
        overflow={"auto"}
        borderRadius={"16px"}
      >
        <BaseDataGrid
          rows={
            leadStatusListData?.data?.map((leadStatus, index) => ({
              id: leadStatus.index,
              index: leadStatus.index,
              _id: leadStatus._id,
              name: representString(leadStatus.name),
              icon: representString(leadStatus.icon),
              color: representString(leadStatus.color),
            })) ?? []
          }
          columns={[
            {
              config: {
                baseGridColType: BaseGridColType.text,
                column: {
                  field: "id",
                  disableColumnMenu: true,
                  filterable: false,
                  width: 60,
                },
                headerConfig: {
                  name: "Index",
                },
              },
            },
            {
              config: {
                baseGridColType: BaseGridColType.text,
                column: {
                  field: "name",
                  editable: true,
                  align: "left",
                  width: 200,
                },
                headerConfig: {
                  name: t("crm.lead.leadStatuses.name"),
                },
              },
            },
            {
              config: {
                baseGridColType: BaseGridColType.icon,
                column: {
                  field: "icon",
                  editable: false,
                  filterable: false,
                  align: "left",
                  width: 100,
                },
                headerConfig: {
                  name: t("crm.lead.leadStatuses.icon"),
                },
              },
            },
            {
              config: {
                baseGridColType: BaseGridColType.color,
                column: {
                  field: "color",
                  editable: false,
                  filterable: false,
                  align: "left",
                  width: 100,
                },
                headerConfig: {
                  name: t("crm.lead.leadStatuses.color"),
                },
              },
            },
          ]}
          actionColumn={{
            width: downMd ? 50 : 120,
            defaultActions: {
              edit: {
                clickConfig: {
                  setSelectedRow: setSelectedLeadStatus,
                  setOpenAction: setOpenLeadStatusUpdateDrawer,
                },
              },
              delete: {
                clickConfig: {
                  setSelectedRow: setSelectedLeadStatus,
                  setOpenAction: setOpenLeadStatusDeleteDrawer,
                },
              },
            },
            actionHeaderName: t("crm.actions"),
          }}
          loading={
            leadStatusListLoading ||
            leadStatusUpdateLoading ||
            leadStatusListFetching
          }
          listFilter={{
            filterInput: leadStatusListFilter,
            setFilterInput: setLeadStatusListFilter,
          }}
          count={leadStatusListData?.count || 0}
          config={{
            columnVisibilityModel: {},
            features: [],
          }}
          update={{
            updateQuery: leadStatusUpdate,
          }}
        />
      </Box>

      <LeadStatusCreateWrapper
        open={openLeadStatusCreateDrawer}
        setOpen={setOpenLeadStatusCreateDrawer}
      />

      <LeadStatusUpdateDrawer
        open={openLeadStatusUpdateDrawer}
        setOpen={setOpenLeadStatusUpdateDrawer}
        selectedRow={selectedLeadStatus}
      />

      <Delete
        open={openLeadStatusDeleteDrawer || false}
        onClose={() => setOpenLeadStatusDeleteDrawer(false)}
        item={selectedLeadStatus!}
        key={selectedLeadStatus?._id}
      />
    </Grid>
  );
};

export default List;
