/* eslint-disable react-hooks/exhaustive-deps */
import {
  Accordion, AccordionDetails, AccordionSummary, Autocomplete, Box, Chip, Grid, InputLabel, Stack, Switch, TextField, Typography
} from "@mui/material";
import { useFormik } from "formik";
import { memo, useEffect, useMemo, useState } from "react";
import { convertEnums, getCurrentLanguage } from "localization";
import { useTranslation } from "react-i18next";
import { IGraphqlVariables, Language, unknownError } from "corede-common";
import { ILeadCreateInput, LeadBusinessType, LeadQualification, LeadType } from "corede-common-cocrm";
import { validateCreateLeadInput } from "../validations/create.validation";
import { RequestTransformerHelper } from "validations/request.transformer.helper";
import { useLeadCreateMutation } from "../context/lead.api";
import { useLeadStatusesQuery } from "../../leadStatuses/context/leadStatuses.api";
import { useLeadSourcesQuery } from "../../leadSources/context/leadSources.api";
import { enqueueSnackbar } from "notistack";
import { useUserListQuery } from "apps/crm/domains/02-organizationalChart/subdomains/users";
import ReactQuill from "react-quill-new";
import 'react-quill/dist/quill.snow.css';
import { BusinessCenterOutlined, ExpandMore, LanguageOutlined, LocalOfferOutlined, NoteOutlined } from "@mui/icons-material";
import ActionDrawer from "components/drawer/ActionDrawer";
import { OutlinedButton } from "components";
import { useCountryListQuery, useLazyCityListQuery, useLazyStateListQuery } from "apps/auth/context";


interface ILeadCreateDrawer {
  open: any
  setOpen: any
  leadStatusCreateOpen: any
  setLeadStatusCreateOpen: any
  leadSourceCreateOpen: any
  setLeadSourceCreateOpen: any
}


const LeadCreateDrawer = memo((props: ILeadCreateDrawer) => {

  // general
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();
  const [loading, setLoading] = useState(false);
  const localizedLeadTypes = convertEnums(LeadType);
  const localizedLeadQualifications = convertEnums(LeadQualification);
  const localizedLanguages = convertEnums(Language);
  const localizedBusinessType = convertEnums(LeadBusinessType);
  const [expandedPanels, setExpandedPanels] = useState<string[]>([]);
  const [expandedAll, setExpandedAll] = useState(false);
  const [inputValueTag, setInputValueTag] = useState('');

  // queries
  const { data: leadStatusesData, isLoading: leadStatusesLoading, error: leadStatusesError } = useLeadStatusesQuery({});
  const { data: leadSourcesData, isLoading: leadSourcesLoading, error: leadSourcesError } = useLeadSourcesQuery({});
  const { data: userListData, isLoading: userListLoading, error: userListError } = useUserListQuery({});
  const { data: countryListData } = useCountryListQuery({});
  const [getStateList, { data: stateListData }] = useLazyStateListQuery({});
  const [getCityList, { data: cityListData }] = useLazyCityListQuery({});

  // mutations
  const [leadCreate, { data: leadCreateData, isLoading: leadCreateLoading, error: leadCreateError }] = useLeadCreateMutation();

  // constants
  const initialValues = useMemo(() => ({
    input: {
      businessType: LeadBusinessType.b2b,
      fullName: "",
      email: "",
      isJunk: false,
      isPublic: true,
      qualification: LeadQualification.unqualified,
      sourceId: leadSourcesData?.data?.[0]?._id ?? "",
      statusId: leadStatusesData?.data?.[0]?._id ?? "",
      type: LeadType.neutral,
      tags: [],
      company: undefined,
      position: undefined,
      website: undefined,
      phoneNumber: undefined,
      sector: undefined,
      country: undefined,
      state: undefined,
      city: undefined,
      language: Language.en,
      assignedIds: [],
      note: undefined,
    },
  }), []);


  const { values, handleSubmit, handleBlur, setFieldValue, errors, touched, resetForm, isValid } =
    useFormik({
      initialValues: initialValues,
      enableReinitialize: false,
      validationSchema: validateCreateLeadInput,
      validateOnChange: false,
      onSubmit: async (values: IGraphqlVariables<ILeadCreateInput>) => {
        setLoading(true);
        const transformedValues =
          RequestTransformerHelper.TransformAllEmptyStringFieldsToUndefined({
            input: values,
          });

        await leadCreate(transformedValues as IGraphqlVariables<ILeadCreateInput>)
        setLoading(false);
      }
    });

  const handleAccordionChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    if (isExpanded) {
      setExpandedPanels((prev) => [...prev, panel]);
    } else {
      setExpandedPanels((prev) => prev.filter((p) => p !== panel));
    }
  };

  const handleExpandAll = () => {
    if (expandedAll) {
      setExpandedPanels([]);
    } else {
      setExpandedPanels(["panel1", "panel2", "panel3", "panel4"]);
    }
    setExpandedAll(!expandedAll);
  };


  // useEffects.success

  useEffect(() => {
    if (leadCreateData) {
      enqueueSnackbar(t("crm.lead.lead.createLeadSuccess"), { variant: 'success' });
      resetForm();
      props.setOpen(false);
    }
  }, [leadCreateData, resetForm]);


  // useEffects.error

  useEffect(() => {
    if (leadCreateError) {
      enqueueSnackbar((leadCreateError as any)?.error?.message[currentLanguage] ?? unknownError.message[currentLanguage], { variant: 'error' });
    }
  }, [leadCreateError]);

  useEffect(() => {
    if (leadStatusesError) {
      enqueueSnackbar((leadStatusesError as any)?.error?.message[currentLanguage] ?? unknownError.message[currentLanguage], { variant: 'error' });
    }
  }, [leadStatusesError]);

  useEffect(() => {
    if (leadSourcesError) {
      enqueueSnackbar((leadSourcesError as any)?.error?.message[currentLanguage] ?? unknownError.message[currentLanguage], { variant: 'error' });
    }
  }, [leadSourcesError]);

  useEffect(() => {
    if (userListError) {
      enqueueSnackbar((userListError as any)?.error?.message[currentLanguage] ?? unknownError.message[currentLanguage], { variant: 'error' });
    }
  }, [userListError]);


  // useEffects.init

  useEffect(() => {
    if (leadStatusesData) {
      setFieldValue("input.statusId", leadStatusesData?.data?.[0]?._id);
    }
  }, [leadStatusesData])

  useEffect(() => {
    if (leadSourcesData) {
      setFieldValue("input.sourceId", leadSourcesData?.data?.[0]?._id);
    }
  }, [leadSourcesData]);


  useEffect(() => {
    if (values?.input?.country) {
      getStateList({
        input: {
          filter: {
            country_name: values?.input.country
          }
        }
      })
    }
  }, [values?.input?.country])

  useEffect(() => {
    if (values?.input?.state && values?.input?.country) {
      getCityList({
        input: {
          filter: {
            country_name: values?.input.country,
            state_name: values?.input.state
          }
        }
      })
    }
  }, [values?.input?.state])


  return <ActionDrawer
    open={props.open}
    setOpen={props.setOpen}
    size="medium"
    title={
      <Stack direction={"row"} sx={{ justifyContent: "space-between", alignItems: "center", mr:3 }}>
        <Typography variant="h4" gutterBottom>{t("crm.lead.lead.create")}</Typography>
        <OutlinedButton
          title={expandedAll ? t("collapseAll") : t("expandAll")}
          onClick={handleExpandAll}
          leftIcon={expandedAll ? <ExpandMore sx={{ transform: "rotate(180deg)" }} /> : <ExpandMore />}
        />
      </Stack>
    }
    handleSubmit={handleSubmit}
    disabled={loading || leadCreateLoading || !values.input?.fullName || !values.input.email || !isValid}
    loading={loading}
    buttonTitle={t("crm.lead.lead.create")}
  >
    <Grid item xs={12} sm={6}>
      <InputLabel htmlFor="input.businessType" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.lead.lead.businessType")}*</InputLabel>
      <Autocomplete id="input.businessType"
        fullWidth
        size="small"
        disableClearable
        isOptionEqualToValue={(option, value) => option._id === value._id}
        options={Object.values(LeadBusinessType).map((type) => ({
          name: localizedBusinessType[type as keyof typeof LeadBusinessType],
          _id: type,
        }))}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => <TextField {...params} />}
        value={{ name: localizedBusinessType[values.input?.businessType as keyof typeof LeadBusinessType], _id: values.input?.businessType }}
        onChange={(e, value) => {
          setFieldValue("input.businessType", value?._id);
        }}
      />
    </Grid>

    <Grid item xs={12} sm={6}>
      <InputLabel htmlFor="input.fullName" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.lead.lead.fullName")}*</InputLabel>
      <TextField
        id="input.fullName"
        name="input.fullName"
        fullWidth
        size="small"
        value={(values as any).input.fullName}
        onChange={e => setFieldValue("input.fullName", e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
        onBlur={handleBlur}
        error={!!((errors as any)?.input?.fullName && (touched as any)?.input?.fullName)}
        helperText={(touched as any)?.input?.fullName && (errors as any)?.input?.fullName}
      />
    </Grid>

    <Grid item xs={12} sm={6}>
      <InputLabel htmlFor="input.email" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.lead.lead.email")}*</InputLabel>
      <TextField
        id="input.email"
        name="input.email"
        fullWidth
        type="email"
        size="small"
        value={(values as any).input.email}
        onChange={e => setFieldValue("input.email", e.target.value)}
        onBlur={handleBlur}
        error={!!((errors as any)?.input?.email && (touched as any)?.input?.email)}
        helperText={(touched as any)?.input?.email && (errors as any)?.input?.email}
      />
    </Grid>


    <Grid item xs={12} sm={6}>
      <InputLabel htmlFor="input.phoneNumber" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.lead.lead.phoneNumber")}</InputLabel>
      <TextField
        id="input.phoneNumber"
        name="input.phoneNumber"
        fullWidth
        size="small"
        value={(values as any).input.phoneNumber}
        type="tel"
        inputProps={{
          maxLength: 14,
        }}
        onKeyDown={(e) => {
          const key = e.key;
          if (!/[0-9]/.test(key) && key !== 'Backspace' && key !== 'Delete' && key !== 'ArrowLeft' && key !== 'ArrowRight') {
            e.preventDefault();
          }
        }}
        onChange={(e) => setFieldValue("input.phoneNumber", e.target.value)}
        onBlur={handleBlur}
        error={!!((errors as any)?.input?.phoneNumber && (touched as any)?.input?.phoneNumber)}
        helperText={(touched as any)?.input?.phoneNumber && (errors as any)?.input?.phoneNumber}
      />
    </Grid>

    <Grid item xs={12} sm={6}>
      <InputLabel htmlFor="input.qualification" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.lead.lead.qualification")}*</InputLabel>
      <Autocomplete
        id="input.qualification"
        fullWidth
        size="small"
        disableClearable
        isOptionEqualToValue={(option, value) => option._id === value._id}
        options={Object.values(LeadQualification).map((type) => ({
          name: localizedLeadQualifications[type as keyof typeof LeadQualification],
          _id: type,
        }))}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => <TextField {...params} />}
        value={{ name: localizedLeadQualifications[values.input?.qualification as keyof typeof LeadQualification], _id: values.input?.qualification }}
        onChange={(e, value) => {
          setFieldValue("input.qualification", value?._id);
        }}
      />
    </Grid>

    <Grid item xs={12} sm={6}>
      <InputLabel htmlFor="input.sourceId" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.lead.lead.source")}*</InputLabel>
      <Autocomplete
        id="input.sourceId"
        isOptionEqualToValue={(option, value) => option._id === value?._id}
        fullWidth
        size="small"
        disableClearable
        getOptionLabel={(option) => option.name}
        options={[
          ...(leadSourcesData?.data || []),
          { _id: "add", name: "Add new source" },
          { _id: "", name: "" },
        ]}
        renderOption={(props, option) => (
          <li {...props} className={`${props.className} ${option._id === "add" ? 'highlight' : ''}`}>
            {option.name}
          </li>
        )}
        loading={leadSourcesLoading}
        onBlur={handleBlur}
        renderInput={(params) => (
          <TextField
            {...params}
            error={!!((errors as any)?.input?.sourceId && (touched as any)?.input?.sourceId)}
            helperText={(touched as any)?.input?.sourceId && (errors as any)?.input?.sourceId}
          />
        )}
        value={
          values.input?.sourceId ? {
            _id: values.input?.sourceId ?? "",
            name: leadSourcesData?.data?.find((source) => source._id === values.input?.sourceId)?.name || ""
          } : { _id: "", name: "" }
        }
        onChange={(e, value) => {
          if (value?._id === "add") {
            props.setLeadSourceCreateOpen(true);
          } else {
            setFieldValue("input.sourceId", value?._id ?? "");
          }
        }}
      />
    </Grid>

    <Grid item xs={12} sm={6}>
      <InputLabel htmlFor="input.statusId" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.lead.lead.status")}*</InputLabel>
      <Autocomplete
        id="input.statusId"
        isOptionEqualToValue={(option, value) => option._id === value._id}
        fullWidth
        size="small"
        disableClearable
        getOptionLabel={(option) => option.name}
        options={[...(leadStatusesData?.data || []), { _id: "add", name: "Add new status" }, { _id: "", name: "" }]}
        loading={leadStatusesLoading}
        onBlur={handleBlur}
        renderInput={(params) => (
          <TextField
            {...params}
            error={!!((errors as any)?.input?.statusId && (touched as any)?.input?.statusId)}
            helperText={(touched as any)?.input?.statusId && (errors as any)?.input?.statusId}
          />
        )}
        renderOption={(props, option) => (
          <li {...props} className={`${props.className} ${option._id === "add" ? 'highlight' : ''}`}>
            {option.name}
          </li>
        )}
        value={{ _id: values.input?.statusId ?? "", name: leadStatusesData?.data?.filter((status) => status._id === values.input?.statusId)[0]?.name ?? "" }}
        onChange={(e, value) => {
          if (value?._id === "add") {
            props.setLeadStatusCreateOpen(true);
          } else {
            setFieldValue("input.statusId", value?._id ?? "");
          }
        }}
      />
    </Grid>

    <Grid item xs={12} sm={6}>
      <InputLabel htmlFor="input.type" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.lead.lead.type")}*</InputLabel>
      <Autocomplete
        id="input.type"
        fullWidth
        size="small"
        disableClearable
        isOptionEqualToValue={(option, value) => option._id === value._id}
        options={Object.values(LeadType).map((type) => ({
          name: localizedLeadTypes[type as keyof typeof LeadType],
          _id: type,
        }))}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => <TextField {...params} />}
        value={{ name: localizedLeadTypes[values.input?.type as keyof typeof LeadType], _id: values.input?.type }}
        onChange={(e, value) => {
          setFieldValue("input.type", value?._id);
        }}
      />
    </Grid>

    <Grid item xs={12} sm={6}>
      <InputLabel htmlFor="input.tags" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.lead.lead.tags")}</InputLabel>
      <Autocomplete
        multiple
        freeSolo
        size="small"
        id="input.tags"
        options={[]}
        value={(values as any).input.tags}
        inputValue={inputValueTag}
        onInputChange={(event, newInputValue) => {
          setInputValueTag(newInputValue);
        }}
        onChange={(event, newValue) => {
          setFieldValue("input.tags", newValue);
        }}
        onKeyDown={(event) => {
          if (event.key === 'Enter' || event.key === ',') {
            event.preventDefault();
            const inputValueTrimmed = inputValueTag.trim();
            if (inputValueTrimmed !== '') {
              setFieldValue("input.tags", [...(values as any).input.tags, inputValueTrimmed]);
              setInputValueTag('');
            }
          }
        }}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => {
            const { key, ...tagProps } = getTagProps({ index });
            return (
              <Chip
                key={key}
                size="small"
                variant="outlined"
                label={option}
                {...tagProps}
              />
            );
          })
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder={t("crm.lead.lead.tagsPlaceholder")}
            error={(touched as any).input?.tags && Boolean((errors as any).input?.tags)}
            helperText={(touched as any).input?.tags && (errors as any).input?.tags}
          />
        )}
      />

    </Grid>




    <Grid item xs={12} sm={6}>
      <InputLabel htmlFor="input.isPublic" sx={{ ml: 1, mb: 0.4, fontSize: 'small', color: '#00000000' }}>{"_"}</InputLabel>
      <Box sx={{ border: "1px solid #ccc", borderRadius: "16px", p: 0.9, display: "flex", alignItems: "center" }}>
        <Switch
          id="input.isPublic"
          name="input.isPublic"
          checked={(values as any).input.isPublic}
          color="secondary"
          size="small"
          onChange={(e) => setFieldValue("input.isPublic", e.target.checked)}
        />
        <Typography sx={{ ml: 1, fontSize: 'small', fontWeight: 'bold' }}>{t("crm.lead.lead.justOwn")}</Typography>
      </Box>
    </Grid>

    <Grid item xs={12}>
      <Accordion expanded={expandedPanels.includes("panel1")}
        onChange={handleAccordionChange("panel1")} sx={{
          width: '100%',
          '&.MuiAccordion-root': {
            boxShadow: 'none',
            borderRadius: "16px",
            border: '1px dashed',
            borderColor: 'secondary.main',
            '&.Mui-expanded': {
              borderStyle: 'solid',
              borderColor: 'primary.main',
            },
          }
        }}>
        <AccordionSummary expandIcon={<ExpandMore />} sx={{
          borderRadius: "16px",
          '&.Mui-expanded': {
            bgcolor: 'primary.lighter', borderBottomLeftRadius: 0, borderBottomRightRadius: 0
          },
        }}>
          <BusinessCenterOutlined sx={{ mr: 1 }} fontSize="small" color="secondary" />
          <Typography fontSize={"small"} fontWeight={500} sx={{ color: 'secondary.main' }}>{t("crm.lead.lead.company")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor="input.company" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.lead.lead.company")}</InputLabel>
              <TextField
                fullWidth
                size="small"
                id="input.company"
                name="input.company"
                value={(values as any).input.company}
                onChange={(e) => setFieldValue("input.company", e.target.value)}
                onBlur={handleBlur}
                error={!!((errors as any)?.input?.company && (touched as any)?.input?.company)}
                helperText={(touched as any)?.input?.company && (errors as any)?.input?.company}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor="input.sector" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.lead.lead.sector")}</InputLabel>
              <TextField
                fullWidth
                name="input.sector"
                id="input.sector"
                size="small"
                value={(values as any).input.sector}
                onChange={(e) => setFieldValue("input.sector", e.target.value)}
                onBlur={handleBlur}
                error={!!((errors as any)?.input?.sector && (touched as any)?.input?.sector)}
                helperText={(touched as any)?.input?.sector && (errors as any)?.input?.sector}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor="input.position" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.lead.lead.position")}</InputLabel>
              <TextField
                fullWidth
                name="input.position"
                id="input.position"
                size="small"
                value={(values as any).input.position}
                onChange={(e) => setFieldValue("input.position", e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                onBlur={handleBlur}
                error={!!((errors as any)?.input?.position && (touched as any)?.input?.position)}
                helperText={(touched as any)?.input?.position && (errors as any)?.input?.position}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor="input.website" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.lead.lead.website")}</InputLabel>
              <TextField
                fullWidth
                name="input.website"
                id="input.website"
                size="small"
                value={(values as any).input.website}
                onChange={(e) => setFieldValue("input.website", e.target.value)}
                onBlur={handleBlur}
                error={!!((errors as any)?.input?.website && (touched as any)?.input?.website)}
                helperText={(touched as any)?.input?.website && (errors as any)?.input?.website}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>

    <Grid item xs={12}>
      <Accordion expanded={expandedPanels.includes("panel2")}
        onChange={handleAccordionChange("panel2")} sx={{
          width: '100%',
          '&.MuiAccordion-root': {
            boxShadow: 'none',
            borderRadius: "16px",
            border: '1px dashed',
            borderColor: 'secondary.main',
            '&.Mui-expanded': {
              borderStyle: 'solid',
              borderColor: 'primary.main',
            },
          }
        }}>
        <AccordionSummary expandIcon={<ExpandMore />} sx={{
          borderRadius: "16px",
          '&.Mui-expanded': {
            bgcolor: 'primary.lighter', borderBottomLeftRadius: 0, borderBottomRightRadius: 0
          },
        }}>
          <LanguageOutlined sx={{ mr: 1 }} fontSize="small" color="secondary" />
          <Typography fontSize={"small"} fontWeight={500} sx={{ color: 'secondary.main' }}>{t("crm.lead.lead.localization")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor="input.country" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.lead.lead.country")}</InputLabel>
              <Autocomplete
                options={[...countryListData?.data || []]}
                size="small"
                fullWidth
                id="input.country"
                isOptionEqualToValue={(option, value) => option.name === value?.name}
                getOptionLabel={(option) => option.name}
                onChange={(event, newValue) => {
                  if (newValue) {
                    setFieldValue("input.country", newValue.name);
                  } else {
                    setFieldValue("input.country", "");
                  }
                }}
                value={
                  values.input?.country
                    ? countryListData?.data?.find((country) => country.name === values.input?.country) || null
                    : null
                }
                renderInput={(params) => <TextField {...params} />}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor="input.state" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.lead.lead.state")}</InputLabel>
              <Autocomplete
                options={[...stateListData?.data || []]}
                size="small"
                fullWidth
                id="input.state"
                isOptionEqualToValue={(option, value) => option.name === value?.name}
                getOptionLabel={(option) => option.name}
                onChange={(event, newValue) => {
                  if (newValue) {
                    setFieldValue("input.state", newValue.name);
                    setFieldValue("input.city", '');
                  } else {
                    setFieldValue("input.state", '');
                    setFieldValue("input.city", '');
                  }
                }}
                value={
                  values?.input?.state
                    ? stateListData?.data?.find((state) => state.name === (values as any).input.state)
                    : null
                }
                renderInput={(params) => <TextField {...params} />}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor="input.city" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.lead.lead.city")}</InputLabel>
              <Autocomplete
                  options={[...cityListData?.data || []]}
                size="small"
                fullWidth
                id="input.city"
                isOptionEqualToValue={(option, value) => option.name === value?.name}
                getOptionLabel={(option) => option.name}
                onChange={(event, newValue) => {
                  setFieldValue("input.city", newValue ? newValue.name : '');
                }}
                value={
                  values.input?.city
                    ? cityListData?.data?.find((city) => city.name === values.input?.city) || null
                    : null
                }
                renderInput={(params) => <TextField {...params} />}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor="input.language" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.lead.lead.language")}</InputLabel>
              <Autocomplete
                id="input.language"
                fullWidth
                size="small"
                disableClearable
                isOptionEqualToValue={(option, value) => option._id === value._id}
                options={Object.values(Language).map((type) => ({
                  name: localizedLanguages[type as keyof typeof Language],
                  _id: type,
                }))}
                value={{ name: localizedLanguages[values.input?.language as keyof typeof Language], _id: values.input?.language }} //todo default organization langugae
                getOptionLabel={(option) => option.name}
                renderInput={(params) => <TextField {...params} />}
                onChange={(e, value) => {
                  setFieldValue("input.language", value?._id);
                }}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>

    <Grid item xs={12}>
      <Accordion expanded={expandedPanels.includes("panel3")}
        onChange={handleAccordionChange("panel3")} sx={{
          width: '100%',
          '&.MuiAccordion-root': {
            boxShadow: 'none',
            borderRadius: "16px",
            border: '1px dashed',
            borderColor: 'secondary.main',
            '&.Mui-expanded': {
              borderStyle: 'solid',
              borderColor: 'primary.main',
            },
          }
        }}>
        <AccordionSummary expandIcon={<ExpandMore />} sx={{
          borderRadius: "16px",
          '&.Mui-expanded': {
            bgcolor: 'primary.lighter', borderBottomLeftRadius: 0, borderBottomRightRadius: 0
          },
        }}>
          <LocalOfferOutlined sx={{ mr: 1 }} fontSize="small" color="secondary" />
          <Typography fontSize={"small"} fontWeight={500} sx={{ color: 'secondary.main' }}>{t("crm.lead.lead.tracking")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputLabel htmlFor="input.assignedIds" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.lead.lead.assigneds")}</InputLabel>
              <Autocomplete
                id="input.assignedIds"
                size="small"
                isOptionEqualToValue={(option, value) => option._id === value._id}
                fullWidth
                getOptionLabel={(option) => option.name + " " + option.surname}
                options={userListData?.data || []}
                loading={userListLoading}
                renderInput={(params) => <TextField {...params} />}
                multiple
                value={
                  values.input?.assignedIds && values.input?.assignedIds?.length > 0
                    ? userListData?.data?.filter((user) =>
                      (values as any).input.assignedIds.includes(user._id)
                    )
                    : []
                }
                onChange={(e, value) => {
                  setFieldValue(
                    "input.assignedIds",
                    value?.map((user) => user._id) || []
                  );
                }}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>

    <Grid item xs={12}>
      <Accordion expanded={expandedPanels.includes("panel4")}
        onChange={handleAccordionChange("panel4")} sx={{
          width: '100%',
          '&.MuiAccordion-root': {
            boxShadow: 'none',
            borderRadius: "16px",
            border: '1px dashed',
            borderColor: 'secondary.main',
            '&.Mui-expanded': {
              borderStyle: 'solid',
              borderColor: 'primary.main',
            },
          }
        }}>
        <AccordionSummary expandIcon={<ExpandMore />} sx={{
          borderRadius: "16px",
          '&.Mui-expanded': {
            bgcolor: 'primary.lighter', borderBottomLeftRadius: 0, borderBottomRightRadius: 0
          },
        }}>
          <NoteOutlined sx={{ mr: 1 }} fontSize="small" color="secondary" />
          <Typography fontSize={"small"} fontWeight={500} sx={{ color: 'secondary.main' }}>{t("crm.lead.lead.note")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {/* <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.lead.lead.note")}</InputLabel> */}
              <Box sx={{ bgcolor: 'background.firstWithBlur', borderRadius: 2, height: "160px", mt: 1, border: "1px solid", borderColor: "grey.400" }}>
                <ReactQuill
                  id="input.note"
                  className="react-quill"
                  theme="snow"
                  value={(values as any).input.note}
                  onChange={(content, delta, source, editor) => setFieldValue("input.note", editor.getHTML())}
                  onBlur={() => handleBlur("input.note")}
                  placeholder={t("crm.lead.lead.note")}
                  style={{ height: 130, boxSizing: "border-box" }}
                />
              </Box>
              {(errors as any).input?.note && (
                <Typography color="error" variant="caption">
                  {(errors as any).input?.note}
                </Typography>
              )}
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  </ActionDrawer >
});

export default LeadCreateDrawer