const locale = {
  contracts: "Sözleşmeler",
  contract: "Sözleşme",
  contractConversions: "Sözleşme Dönüştürme",
  title: "Sözleşmeler",
  addContract: "Sözleşme Ekle",
  emptyStateDesc1: "Henüz herhangi bir sözleşme eklenmedi.",
  emptyStateDesc2: "Faturalandırmanızı kolaylaştırmak ve müşterilerinizle net anlaşmalar sürdürmek için tüm sözleşme ayrıntılarını burada saklayın ve yönetin."
};

export default locale;



