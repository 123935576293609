/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, CircularProgress, Grid, IconButton, InputLabel, Stack, SvgIcon, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState, useRef, memo } from "react";
import { getCurrentLanguage } from "localization";
import { IGraphqlVariables, unknownError } from "corede-common";
import { IUpdateLeadSourceInput } from "corede-common-cocrm";
import { enqueueSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useLeadSourceUpdateMutation } from "../context/leadSources.api";
import { validateUpdateLeadSourcesInput } from "../validations/update.validation";
import * as Icons from '@mui/icons-material';
import ActionDialog from "components/dialog/ActionDialog";

interface ILeadSourceUpdateDialog {
    open: boolean
    setOpen: (open: boolean) => void
    selectedRow: any
}

export const LeadSourceUpdateDialog = memo((props: ILeadSourceUpdateDialog) => {
    const { t } = useTranslation();
    const currentLanguage = getCurrentLanguage();
    const [loading, setLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [visibleIcons, setVisibleIcons] = useState<string[]>([]);
    const iconsContainerRef = useRef<HTMLDivElement>(null);
    const [showSelectBox, setShowSelectBox] = useState("icon");
    const [leadSourceUpdate, { data: leadSourceUpdateData, isLoading: leadSourceUpdateLoading, error: leadSourceUpdateError }] = useLeadSourceUpdateMutation();

    const filteredIcons = Object.keys(Icons).filter((iconName) =>
        iconName.endsWith('Outlined') && iconName.toLowerCase().includes(searchQuery.toLowerCase())
    );

    useEffect(() => {
        setVisibleIcons(filteredIcons.slice(0, 50));
    }, [searchQuery]);

    const handleShowMoreIcons = () => {
        setVisibleIcons((prevVisibleIcons) => {
            const currentLength = prevVisibleIcons?.length;
            const nextIcons = filteredIcons.slice(0, currentLength + 50);
            return nextIcons;
        });
    };

    const { values, handleSubmit, handleBlur, setFieldValue, errors, touched, resetForm, isValid } =
        useFormik({
            initialValues: {
                input: {
                    index: 0,
                    name: '',
                    color: 'primary.lighter',
                    icon: 'CircleOutlined',
                },
            },
            enableReinitialize: true,
            validationSchema: validateUpdateLeadSourcesInput,
            onSubmit: async (values: IGraphqlVariables<IUpdateLeadSourceInput>) => {
                setLoading(true);
                leadSourceUpdate({
                    input: {
                        name: values?.input?.name,
                        color: values?.input?.color,
                        icon: values?.input?.icon,
                        index: values?.input?.index
                    }, filter: { _id: props?.selectedRow?._id }
                });
                setLoading(false);
            }
        });

    const handleIconSelect = (iconName: string) => {
        setFieldValue("input.icon", iconName);
    };

    const handleColorSelect = (color: string) => {
        setFieldValue("input.color", color);
    };

    useEffect(() => {
        if (leadSourceUpdateData) {
            props.setOpen(false);
            enqueueSnackbar(t("crm.lead.leadSources.leadSourceUpdateSuccess"), { variant: 'success' });
            resetForm();
        }
    }, [leadSourceUpdateData, resetForm]);

    useEffect(() => {
        if (leadSourceUpdateError) {
            enqueueSnackbar((leadSourceUpdateError as any)?.error?.message[currentLanguage] ?? unknownError.message[currentLanguage], { variant: 'error' });
        }
    }, [leadSourceUpdateError]);

    useEffect(() => {
        if (props.selectedRow) {
            setFieldValue("input._id", props.selectedRow._id);
            setFieldValue("input.name", props.selectedRow.name);
            setFieldValue("input.color", props.selectedRow.color);
            setFieldValue("input.icon", props.selectedRow.icon);
            setFieldValue("input.index", props.selectedRow.index);
        }
    }, [props.selectedRow]);

    return <ActionDialog
        open={props.open}
        setOpen={props.setOpen}
        size="small"
        title={t("crm.lead.leadSources.updateSource")}
        buttonTitle={t("crm.lead.leadSources.update")}
        handleClick={handleSubmit}
        disabled={loading || !isValid || leadSourceUpdateLoading || !(values as any).input?.name}
        loading={loading}
    >
        <Grid container spacing={2} my={2}>
            <Grid item xs={3} >
                <InputLabel htmlFor="input.name-source" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.lead.leadSources.index")}*</InputLabel>
                <TextField
                    name="input.index"
                    id="input.index-source"
                    fullWidth
                    value={(values as any).input.index}
                    type="number"
                    onChange={(e) => setFieldValue("input.index", Number(e.target.value))}
                    onBlur={handleBlur}
                    error={!!((errors as any)?.input?.index && (touched as any)?.input?.index)}
                    helperText={(touched as any)?.input?.index && (errors as any)?.input?.index}
                />
            </Grid>
            <Grid item xs={9} >
                <InputLabel htmlFor="input.name-source" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.lead.leadSources.name")}*</InputLabel>
                <TextField
                    fullWidth
                    name="input.name"
                    id="input.name-source"
                    value={(values as any).input.name}
                    onChange={(e) => setFieldValue("input.name", e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                    onBlur={handleBlur}
                    error={!!((errors as any)?.input?.name && (touched as any)?.input?.name)}
                    helperText={(touched as any)?.input?.name && (errors as any)?.input?.name}
                />
            </Grid>
            <Grid item xs={6} >
                <Typography sx={{ ml: 1, mb: 0.5, fontSize: 'small', color: 'grey.600' }}>{t("crm.lead.leadSources.icon")}</Typography>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        height: "80px",
                        border: "1px solid",
                        borderColor: "grey.400",
                        borderRadius: "16px",
                        cursor: "pointer",
                    }}
                    onClick={() => setShowSelectBox("icon")}
                >
                    <SvgIcon component={(Icons as any)[(values as any).input.icon]} sx={{ color: "primary.main", fontSize: "64px", opacity: 0.7 }} />
                </Box>
            </Grid>
            <Grid item xs={6} >
                <Typography sx={{ ml: 1, mb: 0.5, fontSize: 'small', color: 'grey.600' }}>{t("crm.lead.leadSources.color")}*</Typography>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        height: "80px",
                        border: "1px solid",
                        borderColor: "grey.400",
                        borderRadius: "16px",
                        cursor: "pointer",
                    }}
                    onClick={() => setShowSelectBox("color")}
                >
                    <Box sx={{ width: 64, height: 64, borderRadius: '50%', backgroundColor: (values as any).input.color }} />
                </Box>
            </Grid>
            <Grid item xs={12} mt={2} sx={{ ml: "16px", border: '1px solid', borderColor: 'grey.400', borderRadius: '16px' }}>
                {showSelectBox === "icon" && <Box sx={{ height: 300, overflow: 'auto', display: 'flex', flexDirection: "column", alignItems: 'center', justifyContent: 'flex-start' }} ref={iconsContainerRef}>
                    <Typography fontSize={"14px"} fontWeight={"bold"} textAlign={"center"} mt={1}>
                        {t("crm.customer.customers.selectIconTitle")}
                    </Typography>
                    <TextField
                        placeholder={t("crm.customer.customers.searchForAnIcon")}
                        name="search-source"
                        variant="outlined"
                        sx={{
                            width: '70%',
                            '& .MuiOutlinedInput-root': {
                                height: 30,
                                fontSize: '12px',
                            },
                            '& .MuiInputLabel-root': {
                                fontSize: '12px',
                            }
                        }}
                        margin="normal"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    <Box sx={{ maxHeight: 180, ml: 1, overflow: 'auto', mt: 1, display: 'flex', flexDirection: "column", justifyContent: 'center' }}>
                        <Grid container spacing={1}>
                            {visibleIcons.map((iconName) => {
                                const IconComponent = (Icons as any)[iconName];
                                return (
                                    <Grid item xs={2} key={iconName}>
                                        <IconButton onClick={() => handleIconSelect(iconName)}>
                                            <IconComponent />
                                        </IconButton>
                                    </Grid>
                                );
                            })}
                        </Grid>
                        {visibleIcons?.length < filteredIcons?.length && (
                            <Button onClick={handleShowMoreIcons} sx={{ mt: 2, alignSelf: 'center' }}>
                                {t("crm.lead.leadSources.showMore")}
                            </Button>
                        )}
                    </Box>
                </Box>}
                {showSelectBox === "color" && <Box sx={{ height: 300, overflow: 'auto', display: 'flex', flexDirection: "column", alignItems: 'center', justifyContent: 'flex-start' }}>
                    <Typography fontSize={"14px"} fontWeight={"bold"} textAlign={"center"} mt={1}>
                        Select Color
                    </Typography>
                    <Box sx={{ maxHeight: 240, ml: 1, overflow: 'auto', mt: 1, display: 'flex', flexDirection: "column", justifyContent: 'center' }}>
                        <Grid container >
                            {colors.map((color) => (
                                <Grid item xs={2} key={color}>
                                    <IconButton
                                        onClick={() => handleColorSelect(color)}
                                    >
                                        <Box
                                            sx={{
                                                width: 32,
                                                height: 32,
                                                borderRadius: '50%',
                                                backgroundColor: color,
                                            }}
                                        />
                                    </IconButton>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                </Box>}
            </Grid>
        </Grid>
    </ActionDialog >;
});


export const colors = [
    "primary.light",
    "secondary.light",
    "success.light",
    "error.light",
    "warning.light",
    "info.light",
    "primary.main",
    "secondary.main",
    "success.main",
    "error.main",
    "warning.main",
    "info.main",
    "primary.dark",
    "secondary.dark",
    "success.dark",
    "error.dark",
    "warning.dark",
    "info.dark",
];
