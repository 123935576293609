/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Stack, Avatar, Typography, Grid } from "@mui/material";
import { useUserProfileImageCreateMutation } from "apps/account/domains/profile/subdomains/profile";
import axios from "axios";
import { useEffect, useState } from "react";
import { compressImageAndGetUrls } from "utils/imageUpload";
import UploadImage from "assets/icons/UploadImage.png"
import { IFileMetadata, unknownError } from "corede-common";
import { enqueueSnackbar } from "notistack";
import { getCurrentLanguage } from "localization";
import { Male1, Male2, Male3, Male4, Male5, Male6, Male7, Male8, Male9, Male10, Female1, Female2, Female3, Female4, Female5, Female6, Female7, Female8, Female9, Female10, Default1 } from "assets/images/avatars";
import { useTranslation } from "react-i18next";
import { useOrganizationLogoCreateMutation } from "apps/settings/domains/settings/subdomains/settings";

const maleImageData = [Male1, Male2, Male3, Male4, Male5, Male6, Male7, Male8, Male9, Male10];
const femaleImageData = [Female1, Female2, Female3, Female4, Female5, Female6, Female7, Female8, Female9, Female10];
const defaultImageData = [Default1];


interface IUploadImageComponent {
    setImageFile: (file: IFileMetadata) => void;
    type?: "userProfileImage" | "organizationLogo";
    currentImage?: string;
    withAvatarPack?: boolean;
    setLoading?: (loading: boolean) => void;
}


export const UploadImageComponent = (props: IUploadImageComponent) => {

    const { t } = useTranslation();
    const [selectedImageFile, setSelectedImageFile] = useState<File | null>(null);
    const [userProfileImageCreate, { isLoading: userProfileImageCreateLoading, error: userProfileImageCreateError }] = useUserProfileImageCreateMutation();
    const [organizationLogoCreate, { isLoading: organizationLogoCreateLoading, error: organizationLogoCreateError }] = useOrganizationLogoCreateMutation();
    const currentLanguage = getCurrentLanguage();

    const handleImageChange = async ({ e, file }: { e?: any, file?: File }) => {

        const imageFile = file ? file : e.target.files[0];

        if (!imageFile) {
            return;
        }

        setSelectedImageFile(imageFile);

        const compressedImages = await compressImageAndGetUrls({
            file: imageFile,
            quality: 0.6,
            maxWidth: 1000,
            thumbnailQuality: 0.5,
            thumbnailMaxWidth: 200,
        });

        if (compressedImages) {
            const { compressedImageFile, compressedImageThumbnail } = compressedImages;

            const imageCreateFunction = async () => {
                switch (props.type) {
                    case "userProfileImage":
                        return await userProfileImageCreate({
                            input: {
                                tags: [""],
                                size: (compressedImageFile as any)?.size * 0.001,
                                mimeType: (compressedImageFile as any)?.type
                            }
                        })
                    case "organizationLogo":
                        return await organizationLogoCreate({
                            input: {
                                tags: [""],
                                size: (compressedImageFile as any)?.size * 0.001,
                                mimeType: (compressedImageFile as any)?.type
                            }
                        })
                    default:
                        return await userProfileImageCreate({
                            input: {
                                tags: [""],
                                size: (compressedImageFile as any)?.size * 0.001,
                                mimeType: (compressedImageFile as any)?.type
                            }
                        })
                }
            }

            imageCreateFunction().then(async (res) => {
                try {
                    const config = {
                        method: 'PUT',
                        maxBodyLength: Infinity,
                        url: res?.data?.presignedUrl?.uploadPresignedUrl ?? "",
                        headers: {
                            'x-amz-acl': 'public-read',
                            'Content-Type': (compressedImageFile as any)?.type
                        },
                        data: compressedImageFile
                    };
                    const configThumbnail = {
                        method: 'PUT',
                        maxBodyLength: Infinity,
                        url: res?.data?.presignedUrl?.uploadThumbnailPresignedUrl ?? "",
                        headers: {
                            'x-amz-acl': 'public-read',
                            'Content-Type': (compressedImageThumbnail as any)?.type
                        },
                        data: compressedImageThumbnail
                    }
                    const response = await axios(config)
                    const responseThumbnail = await axios(configThumbnail)
                    if (response.status === 200 && responseThumbnail.status === 200) {
                        const fileMetadata = res?.data?.fileMetadata;
                        if (fileMetadata) {
                            props.setImageFile(fileMetadata);
                        }
                    }
                } catch (error) {
                    return error
                }
            })
        }
    }


    const handleAvatarClick = async (avatar: any) => {
        const response = await fetch(avatar);
        const blob = await response.blob();
        const file = new File([blob], 'avatar.png', { type: blob.type });
        // setSelectedAvatar(avatar)
        await handleImageChange({ file: file });
    };

    useEffect(() => {
        if (userProfileImageCreateError) {
            enqueueSnackbar((userProfileImageCreateError as any)?.error?.message[currentLanguage] ?? unknownError.message[currentLanguage], { variant: 'error' });
        }
    }, [userProfileImageCreateError]);

    useEffect(() => {
        props.setLoading && props.setLoading(userProfileImageCreateLoading);
    }, [userProfileImageCreateLoading])

    useEffect(() => {
        props.setLoading && props.setLoading(organizationLogoCreateLoading);
    }, [organizationLogoCreateLoading])

    return <Box>
        <Box
            onClick={() => document.getElementById('profile-image-input')?.click()}
            sx={{ width: 'calc(100% - 20px)', border: '1px dashed', borderColor: "primary.light", borderRadius: '16px', p: 1, flexGrow: 1, cursor: 'pointer' }}>
            <Stack direction="column" justifyContent="center" alignItems="center" gap={1} sx={{ width: '100%', height: '100%' }}>
                <Avatar
                    sx={{
                        width: (selectedImageFile || props.currentImage) ? "60px" : "40px",
                        height: (selectedImageFile || props.currentImage) ? "60px" : "40px",
                        padding: (selectedImageFile || props.currentImage) ? "0px" : "10px",
                        backgroundColor: "background.default",
                        objectFit: "cover",
                        resize: "cover",
                    }}
                    src={selectedImageFile ? URL.createObjectURL(selectedImageFile) : props.currentImage ? props.currentImage : UploadImage}
                />
                <Typography variant="body2"> {t('uploadPicture')}</Typography>
            </Stack>
        </Box>
        <input
            id="profile-image-input"
            type="file"
            accept="image/*"
            style={{ display: 'none' }}
            onChange={(e) => handleImageChange({ e })}
        />


        {props.withAvatarPack && <Grid container spacing={1} p={1} >
            {Array.from({ length: 10 }).map((_, index) => (
                <Grid item xs={2.4} key={index}>
                    <Box
                        onClick={() => handleAvatarClick(femaleImageData[index])}
                        sx={{ width: "40px", height: "40px", borderRadius: "20px", border: "2px solid", borderColor: "background.default", boxShadow: 2, cursor: "pointer", ":hover": { boxShadow: 6 } }}>
                        <img style={{ width: "40px", height: "40px", borderRadius: "20px" }}
                            src={femaleImageData[index]}
                            alt="MyAvatar"
                        />
                    </Box>
                </Grid>
            ))}
            {Array.from({ length: 10 }).map((_, index) => (
                <Grid item xs={2.4} key={index}>
                    <Box
                        onClick={() => handleAvatarClick(maleImageData[index])}
                        sx={{ width: "40px", height: "40px", borderRadius: "20px", border: "2px solid", borderColor: "background.default", boxShadow: 2, cursor: "pointer", ":hover": { boxShadow: 6 } }}>
                        <img style={{ width: "40px", height: "40px", borderRadius: "20px" }}
                            src={maleImageData[index]}
                            alt="MyAvatar"
                        />
                    </Box>
                </Grid>
            ))}

        </Grid>}
    </Box>
}


