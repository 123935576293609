const locale = {
  demo: "Demo",
  title: "What is the CoCRM Lead+?",
  description: "At CoCRM, we don't just support you in managing your business and customer processes; we are also delighted to help you acquire new customers.",
  featuresTitle: "With CoCRM Lead+,",
  features1: "We are pleased to provide you with the potential customer data you need based on",
  features2: "The country, city, and/or industry parameters you target for marketing.",
  features3: "The specific areas you've defined for your sales objectives.",
  features4: "The markets where you wish to increase brand awareness.",
  buttonTitle: "Request a Quote",
  formDescription: "Please fill out the form below and we will get in touch with you as soon as possible.",
  formTitle: "Request a Demo",
  formButtonTitle: "Submit",
  sendSuccess: "Thank you! We will get back to you as soon as possible.",
  fullName: "Full Name",
  email: "Email",
  phone: "Phone",
  orgName: "Organization Name",
};

export default locale;
