const locale = {
  permissions: "İzinler",
  update: "Güncelle",
  delete: "Sil",
  view: "Görüntüle",
  list: "Liste",
  deleteConfirm: "Silmek istediğinize emin misiniz: ",
  deletedSuccessfully: "Organizasyon başarıyla silindi",
  roles: "Roller",
  editChart: "Şemayı Düzenle",
  save: "Kaydet",
  createRole: "Rol Oluştur",
  rolesInfo: "Roller oluşturabilir ve bunlara izinler atayabilirsiniz.",
  createUserSuccess: "Kullanıcı başarıyla oluşturuldu",
  create: "Oluştur",
  new: "Yeni",
  roleName: "Rol Adı",
  addRole: "Ekle",
  updateRole: "Güncelle",
  deleteRole: "Sil",
  roleCreatedSuccess: "Rol başarıyla oluşturuldu",
  roleUpdatedSuccess: "Rol başarıyla güncellendi",
  roleDeletedSuccess: "Rol başarıyla silindi",
  errorFetchingPermissions: "İzinler getirilirken hata oluştu",
  errorFetchingRoles: "Roller getirilirken hata oluştu",
  errorCreatingRole: "Rol oluşturulurken hata oluştu",
  errorUpdatingRole: "Rol güncellenirken hata oluştu",
  errorDeletingRole: "Rol silinirken hata oluştu",
  subject: "Konu",
  capabilities: "Yetkinlikler",
  allPermissions: "Tüm İzinler",
  all: "Tümü",
  advancedOptions: "Gelişmiş Seçenekler",
  id: "Kimlik",
  emailAddress: "E-posta Adresi",
  status: "Durum",
  emailVerified: "E-posta Doğrulandı",
  phoneNumber: "Telefon Numarası",
  country: "Ülke",
  city: "Şehir",
  lastLoginDate: "Son Giriş Tarihi",
  createdAt: "Oluşturulma Tarihi",
  actions: "İşlemler",
  addNewRole: "Yeni Rol Ekle",
  yes: "Evet",
  no: "Hayır",
  details: "Detaylar",
  jobTitle: "İş Ünvanı",
  deleteMessage: "Bu kullanıcıyı silmek istediğinize emin misiniz?",
  cancel: "İptal",
  userDeleteSuccess: "Kullanıcı başarıyla silindi!",
  userDeleteError: "Kullanıcı silinirken bir hata oluştu",
  updateChartSuccessfully: "Organizasyon şeması başarıyla güncellendi!",
  address: "Adres",
  birthDate: "Doğum Tarihi",
  gender: "Cinsiyet",
  language: "Dil",
  description: "Açıklama",
  rolesAndPermissions: "Roller & İzinler",
  ownPermissionsAlert: "Kendi izinlerinizi değiştiremezsiniz.",
  undefinedUser: "Tanımsız Kullanıcı",
  chartUpdateSuccess: "Şema başarıyla güncellendi!",
  actionScope: "Eylem Kapsamı",
  action: "Eylem",
};

export default locale;
