const locale = {
  tasks: "Sözleşmeler",
  task: "Sözleşme",
  taskConversions: "Sözleşme Dönüştürme",
  title: "Sözleşmeler",
  addTask: "Sözleşme Ekle",
  emptyStateDesc1: "Henüz görev atanmadı. ",
  emptyStateDesc2: "Çalışmanızı organize etmek, öncelikleri belirlemek ve CRM etkinliklerinizi takip etmek için burada görevler oluşturun."
};

export default locale;



