const locale = {
  estimate: "Estimate",
  estimates: "Estimates",
  create: "Create Estimate",
  update: "Update Estimate",
  delete: "Delete",
  view: "View Estimate",
  list: "List Estimates",
  createdSuccessfully: "Estimate created successfully",
  updatedSuccessfully: "Estimate updated successfully",
  deleteConfirm:
    "Are you sure you want to delete? This action cannot be undone.",
  deletedSuccessfully: "Estimate deleted successfully",
  createInfo: "You can create new estimates here.",
  prefix: "Prefix",
  estimateId: "Estimate",
  timeZone: "TimeZone",
  prospectTargetEntityId: "ProspectTarget",
  prospectTargetEntityRef: "ProspectTargetRef",
  prospectType: "Prospect Type",
  prospectName: "Prospect Name",
  prospectVAT: "Prospect VAT",
  prospectPhoneNumber: "Prospect PhoneNumber",
  prospectCountry: "Prospect Country",
  prospectState: "Prospect State",
  prospectCity: "Prospect City",
  prospectZIP: "Prospect ZIP",
  prospectAddress: "Prospect Address",
  prospectContactFirstName: "Prospect Contact First Name",
  prospectContactLastName: "Prospect Contact Last Name",
  prospectContactEmail: "Prospect Contact Email",
  language: "Language",
  date: "Date",
  expireDate: "Expire Date",
  sendEmailOnExpiration: "Send Email",
  expirationEmailSentDate: "Expiration Email Sent Date",
  projectOrServiceDesc: "Project or Service Desc",
  scopeOfWork: "Scope Of Work",
  assumptionsAndExclusions: "Assumptions And Exclusions",
  paymentTerms: "Payment Terms",
  currency: "Currency",
  products: "Products",
  addProducts: "Add Products",
  offers: "Offers",
  reference: "Reference",
  salesAgentIds: "Sales Agents",
  allowComments: "Allow Comments",
  useLegalNDA: "Use NDA",
  legalNDAId: "NDA",
  useLegalTermAndCond: "Use Term And Con.",
  legalTermAndCondId: "Term And Cond",
  signerUserId: "Signer User",
  signatureImage: "Signature Image",
  listInfo: "List of all estimates.",
  importedError: "Imported error",
  importedSuccessfully: "Imported successfully",
  downloadFormError: "Download form error",
  downloadFormSuccessfully: "Download form successfully",
  downloadExampleFormSuccessfully: "Download example form successfully",
  downloadExampleFormError: "Download example form error",
  title: "Estimates",
  emptyState1: "No estimates found here.",
  emptyState2: "You can create or import estimates immediately.",
  createEstimate: "Create Estimate",
  importEstimate: "Import Estimate",
  importTitle: "Import Estimates",
  downloadExample: "Download Example",
  importText1: "You can import estimates from this page.",
  importText2: "You can use the sample form below to publish your estimates.",
  importExport: "Import/Export",
  exportData: "Export Data",
  importData: "Import Data",
  b2b: "B2B",
  b2c: "B2C",
  viewEstimate: "View",
  edit: "Edit",
  junk: "Junk",
  unJunk: "Un-Junk",
  addNewRole: "Add New Role",
  actions: "Actions",
  legalAgreements: "Legal Agreements",
  businessName: "Business Name",
  governmentAgency: "Government Agency",
  fullName: "Full Name",
  VAT: "VAT",
  phoneNumber: "Phone Number",
  country: "Country",
  state: "State",
  city: "City",
  ZIP: "ZIP",
  address: "Address",
  contactFirstName: "Contact First Name",
  contactLastName: "Contact Last Name",
  contactEmail: "Contact Email",
  sentDate: "Sent Date",
  name: "Name",
  description: "Description",
  productUnit: "Unit",
  quantity: "Quantity",
  price: "Price",
  priceType: "Price Type",
  discountedPrice: "Discounted Price",
  finalPrice: "Final Price",
  tax: "Tax",
  service: "Service",
  subTotal: "Sub Total",
  taxTotal: "Tax Total",
  discount: "Discount",
  product: "Product",
  services: "Services",
  references: "References",
  add: "Add",
  salesAgent: "Sales Agent",
  estimateCreateSuccess: "Estimate created successfully",
};

export default locale;
