import { IMenuItem } from "interfaces/menu-item.interface";
import { useTranslation } from "react-i18next"; 
import {
  baseContractsRoute,
} from "../routes/contracts.base.route";

export const useContractsDomainMenuItem = (): IMenuItem => {
  const { t } = useTranslation(); 

  return {
    title: t("contracts"), 
    path: baseContractsRoute(),
    children: [
    ],
  };
};
