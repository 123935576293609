const locale = {
  appointments: "Randevular",
  appointment: "Randevu",
  appointmentConversions: "Randevu Dönüştürme",
  title: "Randevular",
  addAppointment: "Randevu Ekle",
  emptyStateDesc1: "Henüz herhangi bir randevu planlanmadı.",
  emptyStateDesc2: "Düzenli kalmak ve müşterilerinizle sorunsuz bir şekilde bağlantı kurmak için randevularınızı burada oluşturun ve yönetin."
};

export default locale;



