import { Route, Routes } from "react-router-dom";

import { List} from "../pages";

import { AppointmentsLayout } from "../layouts/AppointmentsLayout";

export const AppointmentsRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<AppointmentsLayout />}>
        <Route index element={<List />} />
       </Route>
    </Routes>
  );
};
