const locale = {
  leadSources: "Lead Kaynakları",
  title: "Lead Kaynakları",
  create: "Kaynak Oluştur",
  delete: "Kaynak Sil",
  deleteConfirm: "Kaynağı silmek istediğinize emin misiniz: ",
  deletedSuccessfully: "Kaynak başarıyla silindi!",
  createSource: "Kaynak Ekle",
  leadSourceCreateSuccess: "Kaynak başarıyla eklendi!",
  name: "Kaynak Adı",
  color: "Kaynak Rengi",
  icon: "Kaynak Simgesi",
  showMore: "Daha fazla",
  leadSourceUpdateSuccess: "Kaynak başarıyla güncellendi!",
  update: "Güncelle",
  updateSource: "Kaynak Güncelle",
  index: "Index"
};

export default locale;
