import {
  IDepartmentListInput, IDepartmentListRequest, IDepartmentListResponse, IDepartmentListResult, departmentListQuery,
  IDepartmentDetailInput, IDepartmentDetailRequest, IDepartmentDetailResponse, IDepartmentDetailResult, departmentDetailQuery,

  IDepartmentCreateInput, IDepartmentCreateRequest, IDepartmentCreateResponse, IDepartmentCreateResult, departmentCreateQuery,
  IDepartmentUpdateInput, IDepartmentUpdateRequest, IDepartmentUpdateResponse, IDepartmentUpdateResult, departmentUpdateQuery, IDepartmentUpdateFilterInput,
  IDepartmentDeleteInput, IDepartmentDeleteRequest, IDepartmentDeleteResponse, IDepartmentDeleteResult, deleteDepartmentQuery,

} from "corede-common-cocrm";
import { commonApi } from "context/commonApi";
import { EndpointQueryBuilder } from "apps/helper/endpoint-query.builder";
import { departmentsTags } from "./departments.tags";
import { IGraphqlVariables } from "corede-common";


export const departmentsApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({

    //  queries
    departmentList: builder.query<
      IDepartmentListResult,
      IGraphqlVariables<IDepartmentListInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IDepartmentListRequest,
        IDepartmentListResponse,
        IDepartmentListResult,
        IDepartmentListInput
      >({
        query: departmentListQuery,
        providesTags: [departmentsTags.department, departmentsTags.departments]
      })),

    departmentDetail: builder.query<
      IDepartmentDetailResult,
      IGraphqlVariables<IDepartmentDetailInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IDepartmentDetailRequest,
        IDepartmentDetailResponse,
        IDepartmentDetailResult,
        IDepartmentDetailInput
      >({
        query: departmentDetailQuery,
        providesTags: [departmentsTags.department, departmentsTags.departments]
      })),

    //mutations

    departmentCreate: builder.mutation<
      IDepartmentCreateResult,
      IGraphqlVariables<IDepartmentCreateInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IDepartmentCreateRequest,
        IDepartmentCreateResponse,
        IDepartmentCreateResult,
        IDepartmentCreateInput
      >({
        query: departmentCreateQuery,
        invalidatesTags: [departmentsTags.departments]
      })),


    departmentUpdate: builder.mutation<
      IDepartmentUpdateResult,
      IGraphqlVariables<IDepartmentUpdateInput, IDepartmentUpdateFilterInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IDepartmentUpdateRequest,
        IDepartmentUpdateResponse,
        IDepartmentUpdateResult,
        IDepartmentUpdateInput,
        IDepartmentUpdateFilterInput
      >({
        query: departmentUpdateQuery,
        invalidatesTags: [departmentsTags.departments]
      })),

    departmentDelete: builder.mutation<
      IDepartmentDeleteResult,
      IGraphqlVariables<IDepartmentDeleteInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IDepartmentDeleteRequest,
        IDepartmentDeleteResponse,
        IDepartmentDeleteResult,
        IDepartmentDeleteInput
      >({
        query: deleteDepartmentQuery,
        invalidatesTags: [departmentsTags.departments]
      })),







  })
});

export const { 
  useDepartmentListQuery,
  useLazyDepartmentListQuery,
  useDepartmentDetailQuery,
  useLazyDepartmentDetailQuery,

  useDepartmentCreateMutation,
  useDepartmentUpdateMutation,
  useDepartmentDeleteMutation,

} = departmentsApi;
