import { motion } from "framer-motion"


export const PageOpening = ({ children }: any) => {

    return (
        <motion.div
            initial={{ opacity: 0, filter: 'blur(10px)' }}
            animate={{ opacity: 1, filter: 'blur(0)' }}
            transition={{ duration: 1 }}
        >
            {children}
        </motion.div>
    )
}
