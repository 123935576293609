import { MenuItem, Stack, Typography } from "@mui/material";
import {
  DeleteForeverOutlined,
  EditNoteRounded,
  VisibilityOutlined,
} from "@mui/icons-material";
import {
  IColumnActionDefInput,
  IColumnActionParams,
} from "../infra/IBaseGridColumnActionComponentsParams";
import { t } from "i18next";

export const GridColumnViewActionDef = (params: IColumnActionParams) => {
  return BaseColumnActionDef({
    title: params.config?.title ?? t("view"),
    disabled: params.config?.disabled,
    icon: VisibilityOutlined,
    iconConfig: {
      color: "primary",
      onClick: () => {
        params.clickConfig.setSelectedRow &&
          params.clickConfig.setSelectedRow(
            params.renderCellCallBackParams.row
          );
        params.clickConfig.setOpenAction &&
          params.clickConfig.setOpenAction(true);
      },
    },
  });
};

export const GridColumnEditActionDef = (params: IColumnActionParams) => {
  return BaseColumnActionDef({
    title: params.config?.title ?? t("update"),
    disabled: params.config?.disabled,
    icon: EditNoteRounded,
    iconConfig: {
      color: "primary",
      onClick: () => {
        params.clickConfig.setSelectedRow &&
          params.clickConfig.setSelectedRow(
            params.renderCellCallBackParams.row
          );
        params.clickConfig.setOpenAction &&
          params.clickConfig.setOpenAction(true);
      },
    },
  });
};

export const GridColumnDeleteActionDef = (params: IColumnActionParams) => {
  return BaseColumnActionDef({
    title: params.config?.title ?? t("delete"),
    disabled: params.config?.disabled,
    icon: DeleteForeverOutlined,
    iconConfig: {
      color: "error",
      onClick: () => {
        params.clickConfig.setSelectedRow &&
          params.clickConfig.setSelectedRow(
            params.renderCellCallBackParams.row
          );
        params.clickConfig.setOpenAction &&
          params.clickConfig.setOpenAction(true);
      },
    },
  });
};

const BaseColumnActionDef = (params: IColumnActionDefInput) => {
  const Icon = params.icon;
  return (
    <MenuItem key={params.icon + params.title} disabled={params.disabled} onClick={params.iconConfig.onClick}>
      <Stack direction={"row"} width={"100%"} justifyContent={"flex-start"} alignItems={"center"}>
        <Icon color={params.iconConfig.color} fontSize="small" />
        <Typography fontSize={"12px"} fontWeight={"bold"} ml={1}>{params.title}</Typography>
      </Stack>
    </MenuItem >
  );
};
