import { Route, Routes } from "react-router-dom";

import { List} from "../pages";

import { CustomersLayout } from "../layouts/CustomersLayout";
import CustomerDetail from "../pages/CustomerDetail";
export const CustomersRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<CustomersLayout />}>
        <Route index element={<List />} />
        <Route path={`/:id`} element={<CustomerDetail />} />
       </Route>
    </Routes>
  );
};
