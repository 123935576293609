const locale = {
  returns: "İadeler",
  return: "İade",
  returnConversions: "İade Dönüştürme",
  title: "İadeler",
  addReturn: "İade Ekle",
  emptyStateDesc1: "Henüz herhangi bir iade kaydedilmedi.",
  emptyStateDesc2: "Kayıtlarınızı doğru tutmak ve müşteri ihtiyaçlarını karşılamak için herhangi bir ürün veya hizmet iadesini burada yönetin ve inceleyin."
};

export default locale;
