const locale = {
  customerCategories: "Müşteri Kategorileri",
  headerTitle: "Müşteri Kategorileri",
  createCustomerCategory: "Müşteri Kategorisi Oluştur",
  columnId: "ID",
  columnName: "İsim",
  columnRoles: "Roller",
  columnActions: "İşlemler",
  rolesButton: "Rolleri Düzenle",
  createCustomerCategoryTitle: "Yeni Müşteri Kategorisi Oluştur",
  customerCategoryNameLabel: "Müşteri Kategorisi İsmi",
  rolesLabel: "Roller",
  createCustomerCategoryButton: "Oluştur",
  emptyStateContent1: "Müşteri kategorisi bulunamadı",
  emptyStateContent2:
    "Yeni müşteri kategorileri oluşturmak için aşağıdaki butona tıklayabilirsiniz.",
  addNewRole: "Yeni Rol Ekle",
  deletedSuccessfully: "Müşteri kategorisi başarıyla silindi",
  deleteConfirm: "Bu müşteri kategorisini silmek istediğinizden emin misiniz?",
  delete: "Sil",
  enTranslation: "İngilizce Çeviri",
  trTranslation: "Türkçe Çeviri",
  department: "Departman",
  parentCategory: "Üst Kategori",
  icon: "Simge",
  createSuccess: "Müşteri kategorisi başarıyla oluşturuldu",
  addDepartment: "Departman Ekle",
  showMore: "Daha Fazla Göster",
  columnIcon: "Simge",
  columnDepartment: "Departman",
  columnParentCategory: "Üst Kategori",
  selectIconTitle: "Bir Simge Seç",
  searchIcons: "Simgeleri Ara",
  updateCustomerCategoryTitle: "Kategori Güncelle",
  updateCustomerCategoryButton: "Güncelle",
  updateSuccess: "Müşteri kategorisi güncellendi",
  actions: "Aksiyon",
  createdAt: "Oluşturulma Tarihi",
};

export default locale;
