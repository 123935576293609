import {
  getGridDateOperators,
  getGridNumericOperators,
  getGridStringOperators,
  GridFilterOperator,
} from "@mui/x-data-grid-pro";
import { GridNumericOperatorName, GridStringOperatorName } from "./enums";

export const onlyStringEqualsOperator: Array<GridFilterOperator> =
  getGridStringOperators().filter(
    (operator) => operator.value === GridStringOperatorName.equals
  );

export const onlyStringContainsOperator: Array<GridFilterOperator> =
  getGridStringOperators().filter(
    (operator) => operator.value === GridStringOperatorName.contains
  );

export const onlyNumberEqualsOperator: Array<GridFilterOperator> =
  getGridNumericOperators().filter(
    (operator) => operator.value === GridNumericOperatorName.equals
  );

export const onlyDateEqualsOperator: Array<GridFilterOperator> =
  getGridDateOperators().filter(
    (operator) => operator.value === GridNumericOperatorName.equals
  );
