import { IMenuItem } from "interfaces/menu-item.interface";
import { useTranslation } from "react-i18next";
import {
  baseProductsAndServicesRoute,
  listCategoriesRoute,
  listProductsRoute,
  listServicesRoute,
} from "../routes/productsAndServices.base.route";
import { PermissionSubdomain } from "corede-common-cocrm";

export const useProductsAndServicesDomainMenuItem = (): IMenuItem => {
  const { t } = useTranslation(); 

  return {
    title: t("productsAndServices"), 
    path: baseProductsAndServicesRoute(),
    children: [
      {
        title: t("products"),
        path: listProductsRoute(),
        subdomain: PermissionSubdomain.product
      },
      {
        title: t("services"),
        path: listServicesRoute(),
        subdomain: PermissionSubdomain.product
      },
      {
        title: t("categories"),
        path: listCategoriesRoute(),
        subdomain: PermissionSubdomain.productCategory
      }
    ],
  };
};
