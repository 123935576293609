import demoLocale from "../domains/demo/locales/tr";

const locale = {
  demo: {
    ...demoLocale,
  },
  demoo: "Bekleme Listesine Katıl"
};

export default locale;
