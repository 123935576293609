/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography, Grid } from "@mui/material";
import { IBaseError } from "corede-common";
import { IBaseReminderEntity } from "corede-common-cocrm";
import { getCurrentLanguage } from "localization";
import { enqueueSnackbar } from "notistack";
import { memo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { DefaultErrorHandlerUseEffect } from "utils/useEffect.helper";
// import { useReminderDeleteMutation } from "../context/reminders.api";
import ActionDialog from "components/dialog/ActionDialog";
import { useReminderDeleteMutation } from "../context/reminders.api";

export const ReminderDeleteDialog = memo((prop: {
    open: boolean;
    item: IBaseReminderEntity | undefined;
    onClose: () => void;
}) => {
    // general
    const { t } = useTranslation();
    const currentLanguage = getCurrentLanguage();

    // queries

    // mutations
    const [reminderDelete, { data: reminderDeleteData, isLoading: reminderDeleteIsLoading, error: reminderDeleteError }] = useReminderDeleteMutation();

    // constants

    // // action handlers
    const handleDelete = (_id: string) => {
        reminderDelete({ input: { _id: _id } });
    };

    // useEffects.success
    useEffect(() => {
        if (reminderDeleteData) {
            prop?.onClose();
            enqueueSnackbar(t("crm.calendar.reminders.deletedSuccessfully"), {
                variant: "success",
            });
        }
    }, [reminderDeleteData]);

    // // useEffects.error
    useEffect(() => {
        DefaultErrorHandlerUseEffect(
            reminderDeleteError as IBaseError,
            currentLanguage
        );
    }, [reminderDeleteError, currentLanguage]);

    // useEffects.init

    return <ActionDialog
        open={prop?.open}
        onClose={prop?.onClose}
        title={t("crm.calendar.reminders.deleteReminder")}
        buttonTitle={t("delete")}
        handleClick={() => handleDelete(prop?.item?._id as string)}
        disabled={reminderDeleteIsLoading}
        loading={reminderDeleteIsLoading}
        buttonColor="error"
        width={500}
    >
        <Grid container spacing={2} my={2}>
            <Grid item xs={12}>
                <Typography>
                    {t("crm.calendar.reminders.deleteReminderContent")}
                </Typography>
            </Grid>
        </Grid>
    </ActionDialog>
});
