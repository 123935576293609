const locale = {
  supports: "Supports",
  support: "Support",
  supportConversions: "Support Conversions",
  title: "Supports",
  addSupport: "Add Support",
  emptyStateDesc1: "No support tickets yet.",
  emptyStateDesc2: "Manage customer inquiries and track support requests here to ensure timely responses and excellent service."
};

export default locale;

