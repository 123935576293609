import { PermissionActionScope } from "corede-common";
import {
  IPermission,
  PermissionDomain,
  PermissionSubdomain,
} from "corede-common-cocrm";

export function domainIsAllowed(
  userPermissions: IPermission[] | undefined,
  domain: PermissionDomain
): boolean {
  return (
    userPermissions?.some(
      (permission) =>
        permission.actionScope !== PermissionActionScope.own &&
        (permission.domain === domain ||
          permission.domain === PermissionDomain.all)
    ) ?? false
  );
}

export function subdomainIsAllowed(
  userPermissions: IPermission[] | undefined,
  subdomain: PermissionSubdomain | undefined
): boolean {
  return (
    userPermissions?.some(
      (permission) =>
        permission.actionScope !== PermissionActionScope.own &&
        (permission.subdomain === subdomain ||
          permission.subdomain === PermissionDomain.all)
    ) ?? false
  );
}
