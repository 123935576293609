import { Route, Routes } from "react-router-dom";

import { List} from "../pages";

import { NotesLayout } from "../layouts/NotesLayout";

export const NotesRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<NotesLayout />}>
        <Route index element={<List />} />
       </Route>
    </Routes>
  );
};
