import {
  // queries
  // IPermissionListInput, IPermissionListRequest, IPermissionListResponse, IPermissionListResult, permissionListQuery,

  IInvoiceListInput,
  IInvoiceListRequest,
  IInvoiceListResponse,
  IInvoiceListResult,
  invoiceListQuery,
  IInvoiceDetailInput,
  IInvoiceDetailRequest,
  IInvoiceDetailResponse,
  IInvoiceDetailResult,
  invoiceDetailQuery,

  // mutations
  IInvoiceCreateInput,
  IInvoiceCreateRequest,
  IInvoiceCreateResponse,
  IInvoiceCreateResult,
  invoiceCreateQuery,
  IInvoiceUpdateInput,
  IInvoiceUpdateRequest,
  IInvoiceUpdateResponse,
  IInvoiceUpdateResult,
  invoiceUpdateQuery,
  IInvoiceUpdateFilterInput,
  IInvoiceDeleteInput,
  IInvoiceDeleteRequest,
  IInvoiceDeleteResponse,
  IInvoiceDeleteResult,
  invoiceDeleteQuery,
} from "corede-common-cocrm";
import { commonApi } from "context/commonApi";
import { EndpointQueryBuilder } from "apps/helper/endpoint-query.builder";
import { invoicesTags } from "./invoices.tags";
import { IGraphqlVariables } from "corede-common";

export const invoicesApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({
    // invoicesTags: builder.query<
    //     IInvoiceTagsResult,
    //     IGraphqlVariables<IInvoiceTagsFilterInput>
    // >(
    //     EndpointQueryBuilder.BuildGraphqlQuery<
    //         IInvoiceTagsRequest,
    //         IInvoiceTagsResponse,
    //         IInvoiceTagsResult,
    //         IInvoiceTagsFilterInput,
    //     >({
    //         query: invoicesTagsQuery,
    //         providesTags: [invoicesTags.invoicesTags],
    //     })),

    invoiceList: builder.query<
      IInvoiceListResult,
      IGraphqlVariables<IInvoiceListInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IInvoiceListRequest,
        IInvoiceListResponse,
        IInvoiceListResult,
        IInvoiceListInput
      >({
        query: invoiceListQuery,
        providesTags: [invoicesTags.invoice, invoicesTags.invoices],
      })
    ),

    invoiceDetail: builder.query<
      IInvoiceDetailResult,
      IGraphqlVariables<IInvoiceDetailInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IInvoiceDetailRequest,
        IInvoiceDetailResponse,
        IInvoiceDetailResult,
        IInvoiceDetailInput
      >({
        query: invoiceDetailQuery,
        providesTags: [invoicesTags.invoice, invoicesTags.invoices],
      })
    ),

    // mutations

    invoiceCreate: builder.mutation<
      IInvoiceCreateResult,
      IGraphqlVariables<IInvoiceCreateInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IInvoiceCreateRequest,
        IInvoiceCreateResponse,
        IInvoiceCreateResult,
        IInvoiceCreateInput
      >({
        query: invoiceCreateQuery,
        invalidatesTags: [invoicesTags.invoice, invoicesTags.invoices],
      })
    ),

    invoiceUpdate: builder.mutation<
      IInvoiceUpdateResult,
      IGraphqlVariables<IInvoiceUpdateInput, IInvoiceUpdateFilterInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IInvoiceUpdateRequest,
        IInvoiceUpdateResponse,
        IInvoiceUpdateResult,
        IInvoiceUpdateInput,
        IInvoiceUpdateFilterInput
      >({
        query: invoiceUpdateQuery,
        invalidatesTags: [invoicesTags.invoice, invoicesTags.invoices],
      })
    ),

    invoiceDelete: builder.mutation<
      IInvoiceDeleteResult,
      IGraphqlVariables<IInvoiceDeleteInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IInvoiceDeleteRequest,
        IInvoiceDeleteResponse,
        IInvoiceDeleteResult,
        IInvoiceDeleteInput
      >({
        query: invoiceDeleteQuery,
        invalidatesTags: [invoicesTags.invoice, invoicesTags.invoices],
      })
    ),
  }),
});

export const {
  useInvoiceListQuery,
  useLazyInvoiceListQuery,
  useInvoiceDetailQuery,
  useLazyInvoiceDetailQuery,
  useInvoiceCreateMutation,
  useInvoiceUpdateMutation,
  useInvoiceDeleteMutation,
} = invoicesApi;
