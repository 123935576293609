import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import { Box, Stack, Typography } from '@mui/material';
import 'swiper/css';
import 'swiper/css/pagination';
import { useTranslation } from 'react-i18next';
import BillGates from "assets/images/quotes/Bill-Gates.jpg";
import TonyHsieh from "assets/images/quotes/Tony-Hsieh.png";
import MatthewOdgers from "assets/images/quotes/Matthew-Odgers.jpg";
import BobHooey from "assets/images/quotes/Bob-Hooey.jpg";
import BoBennett from "assets/images/quotes/Bo-Bennett.jpg";
import OgMandino from "assets/images/quotes/Og-Mandino.png";
import JimRohn from "assets/images/quotes/Jim-Rohn.png";
import WaltDisney from "assets/images/quotes/Walt-Disney.jpg";
import { memo } from 'react';


const quotes = [
    {
        quote: "auth.quote1",
        quoteName: "auth.quoteName1",
        quoteCompany: "auth.quoteCompany1",
        quoteImage: BillGates,
    },
    {
        quote: "auth.quote2",
        quoteName: "auth.quoteName2",
        quoteCompany: "auth.quoteCompany2",
        quoteImage: TonyHsieh,
    },
    {
        quote: "auth.quote3",
        quoteName: "auth.quoteName3",
        quoteCompany: "auth.quoteCompany3",
        quoteImage: MatthewOdgers,
    },
    {
        quote: "auth.quote4",
        quoteName: "auth.quoteName4",
        quoteCompany: "auth.quoteCompany4",
        quoteImage: BobHooey,
    },
    {
        quote: "auth.quote5",
        quoteName: "auth.quoteName5",
        quoteCompany: "auth.quoteCompany5",
        quoteImage: BoBennett,
    },
    {
        quote: "auth.quote6",
        quoteName: "auth.quoteName6",
        quoteCompany: "auth.quoteCompany6",
        quoteImage: OgMandino,
    },
    {
        quote: "auth.quote7",
        quoteName: "auth.quoteName7",
        quoteCompany: "auth.quoteCompany7",
        quoteImage: JimRohn,
    },
    {
        quote: "auth.quote8",
        quoteName: "auth.quoteName8",
        quoteCompany: "auth.quoteCompany8",
        quoteImage: WaltDisney,
    }
]

export const Slider = memo(() => {
    const { t } = useTranslation();

    const randomQuotes = [...quotes]
        .sort(() => Math.random() - 0.5)
        .slice(0, 3);


    return (
        <Swiper
            pagination={{
                dynamicBullets: true,
            }}
            autoplay={{
                delay: 3000,
                disableOnInteraction: false,
            }}
            loop={true}
            modules={[Pagination, Autoplay]}
            className="mySwiper"
        >
            {randomQuotes.map((item, index) => (
                <SwiperSlide key={index}>
                    <Box bgcolor={"secondary.main"} p={4} borderRadius={4} mr={1} ml={1}>
                        <Typography fontSize={16} fontWeight={400} textAlign={"left"} color={"#fff"}>
                            {t(item.quote ?? "")}
                        </Typography>
                        <Stack direction={"row"} mt={2} alignItems={"center"}>
                            <img src={item.quoteImage} alt={t("auth.logoAlt")} style={{ width: "50px", height: "50px", borderRadius: "50px" }} />
                            <Stack direction={"column"} ml={1} alignItems={"flex-start"}>
                                <Typography fontSize={14} fontWeight={600} color={"#fff"}>
                                    {t(item.quoteName ?? "")}
                                </Typography>
                                <Typography fontSize={12} fontWeight={400} color={"#fff"}>
                                    {t(item.quoteCompany ?? "")}
                                </Typography>
                            </Stack>
                        </Stack>
                    </Box>
                </SwiperSlide>
            ))}
        </Swiper>
    );
})
