/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import {
  useLeadSourcesQuery,
  useLeadSourceUpdateMutation,
} from "../context/leadSources.api";
import * as Icons from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { HeaderBase } from "components/contentHeaders/crm/HeaderBase";
import Delete from "./Delete";
import { ILeadSource, ILeadSourcesInput } from "corede-common-cocrm";
import {
  IBaseError,
  IGraphqlVariables,
  isNullOrUndefinedOrEmptyObject,
} from "corede-common";
import { LeadSourceUpdateDialog } from "./LeadSourcesUpdateDialog";
import LeadSourcesCreateWrapper from "./LeadSourceCreateWraper";
import EmptyState from "components/emptyState/EmptyState";
import { DefaultErrorHandlerUseEffect } from "utils/useEffect.helper";
import { getCurrentLanguage } from "localization";
import { Add } from "@mui/icons-material";
import { representString } from "utils/representationHelper";
import { BaseDataGrid } from "components/baseDataGrid/BaseDataGrid";
import { BaseGridColType } from "components/baseDataGrid/infra/enums";

const List = () => {
  // init
  const { t } = useTranslation();
  const downMd = useMediaQuery((theme: any) => theme.breakpoints.down("md"));
  const currentLanguage = getCurrentLanguage();

  // states
  const [openLeadSourceCreateDrawer, setLeadOpenSourceCreateDrawer] =
    useState(false);
  const [openLeadSourceUpdateDrawer, setOpenLeadSourceUpdateDrawer] =
    useState(false);
  const [openLeadSourceDeleteDrawer, setOpenLeadSourceDeleteDrawer] =
    useState(false);
  const [selectedLeadSource, setSelectedLeadSource] = useState<ILeadSource>();
  const [leadSourceListFilter, setLeadSourceListFilter] = useState<
    IGraphqlVariables<ILeadSourcesInput>
  >({
    input: {
      filter: {},
      pagination: { page: 1, pageSize: 10, sort: { index: 1 } },
    },
  });

  // queries
  const {
    data: leadSourceListData,
    isLoading: leadSourceListLoading,
    isFetching: leadSourceListFetching,
  } = useLeadSourcesQuery(leadSourceListFilter);
  const [
    leadSourceUpdate,
    { isLoading: leadSourceUpdateLoading, error: leadSourceUpdateError },
  ] = useLeadSourceUpdateMutation();

  const isEmptyState =
    leadSourceListData?.data?.length === 0 &&
    isNullOrUndefinedOrEmptyObject(leadSourceListFilter.filter);

  // use effects
  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      leadSourceUpdateError as IBaseError,
      currentLanguage
    );
  }, [leadSourceUpdateError]);

  return (
    <Grid item xs={12} height={"calc(100vh - 260px)"}>
      <HeaderBase
        title={t(`crm.lead.leadSources.title`)}
        rightButton={{
          icon: (
            <Icons.AddCircle sx={{ mr: 1, opacity: 0.7 }} fontSize="small" />
          ),
          title: t(`crm.lead.leadSources.create`),
          onClick: () => setLeadOpenSourceCreateDrawer(true),
          visibility: !isEmptyState,
        }}
      />
      {!isEmptyState ? (
        <Box
          minHeight={"350px"}
          height={"600px"}
          mt={1}
          overflow={"auto"}
          borderRadius={"16px"}
        >
          <BaseDataGrid
            rows={
              leadSourceListData?.data?.map((leadSource, index) => ({
                id: leadSource.index,
                index: leadSource.index,
                _id: leadSource._id,
                name: representString(leadSource.name),
                icon: representString(leadSource.icon),
                color: representString(leadSource.color),
              })) ?? []
            }
            columns={[
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: "id",
                    disableColumnMenu: true,
                    filterable: false,
                    width: 60,
                  },
                  headerConfig: {
                    name: "Index",
                  },
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: "name",
                    editable: true,
                    align: "left",
                    width: 200,
                  },
                  headerConfig: {
                    name: t("crm.lead.leadSources.name"),
                  },
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.icon,
                  column: {
                    field: "icon",
                    editable: false,
                    filterable: false,
                    align: "left",
                    width: 100,
                  },
                  headerConfig: {
                    name: t("crm.lead.leadSources.icon"),
                  },
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.color,
                  column: {
                    field: "color",
                    editable: false,
                    filterable: false,
                    align: "left",
                    width: 100,
                  },
                  headerConfig: {
                    name: t("crm.lead.leadSources.color"),
                  },
                },
              },
            ]}
            actionColumn={{
              width: downMd ? 50 : 120,
              defaultActions: {
                edit: {
                  clickConfig: {
                    setSelectedRow: setSelectedLeadSource,
                    setOpenAction: setOpenLeadSourceUpdateDrawer,
                  },
                },
                delete: {
                  clickConfig: {
                    setSelectedRow: setSelectedLeadSource,
                    setOpenAction: setOpenLeadSourceDeleteDrawer,
                  },
                },
              },
              actionHeaderName: t("crm.actions"),
            }}
            loading={
              leadSourceListLoading ||
              leadSourceUpdateLoading ||
              leadSourceListFetching
            }
            listFilter={{
              filterInput: leadSourceListFilter,
              setFilterInput: setLeadSourceListFilter,
            }}
            count={leadSourceListData?.count || 0}
            config={{
              columnVisibilityModel: {},
              features: [],
            }}
            update={{
              updateQuery: leadSourceUpdate,
            }}
          />
        </Box>
      ) : (
        <EmptyState
          content1={t("crm.lead.leadSources.emptyStateContent1")}
          content2={t("crm.lead.leadSources.emptyStateContent2")}
          button1={{
            title: t("crm.lead.leadSources.createLeadSource"),
            onClick: () => {
              setLeadOpenSourceCreateDrawer(true);
            },
            leftIcon: <Add />,
          }}
        />
      )}

      <LeadSourcesCreateWrapper
        open={openLeadSourceCreateDrawer}
        setOpen={setLeadOpenSourceCreateDrawer}
      />

      <LeadSourceUpdateDialog
        open={openLeadSourceUpdateDrawer}
        setOpen={setOpenLeadSourceUpdateDrawer}
        selectedRow={selectedLeadSource}
      />

      <Delete
        open={openLeadSourceDeleteDrawer}
        onClose={() => {
          setSelectedLeadSource(undefined);
          setOpenLeadSourceDeleteDrawer(false);
        }}
        item={selectedLeadSource!}
        key={selectedLeadSource?._id}
      />
    </Grid>
  );
};

export default List;
