import {
  // queries
  // IPermissionListInput, IPermissionListRequest, IPermissionListResponse, IPermissionListResult, permissionListQuery,

  ICustomerContactListInput,
  ICustomerContactListRequest,
  ICustomerContactListResponse,
  ICustomerContactListResult,
  customerContactListQuery,
} from "corede-common-cocrm";
import { commonApi } from "context/commonApi";
import { EndpointQueryBuilder } from "apps/helper/endpoint-query.builder";
import { contactsTags } from "./contacts.tags";
import { IGraphqlVariables } from "corede-common";

export const customerContactsApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({
    customerContactList: builder.query<
      ICustomerContactListResult,
      IGraphqlVariables<ICustomerContactListInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ICustomerContactListRequest,
        ICustomerContactListResponse,
        ICustomerContactListResult,
        ICustomerContactListInput
      >({
        query: customerContactListQuery,
        providesTags: [contactsTags.customerContactTags],
      })
    ),

    // mutations
  }),
});

export const { useCustomerContactListQuery, useLazyCustomerContactListQuery } =
  customerContactsApi;
