const locale = {
  demo: "Demo",
  title: "What is CoCRM Marketing?",
  description: "At CoCRM, we don’t just make managing your existing customer relationships easier — we also help you optimise your marketing processes to achieve your business goals. With CoCRM Marketing, you can reach your target audience more effectively and make your marketing campaigns more efficient.",
  featuresTitle: "With CoCRM Marketing, you can:",
  features1: "Customise your marketing campaigns by country, city, and industry for more strategic management,",
  features2: "Create personalised campaigns tailored to the specific markets where you want to increase brand awareness,",
  features3: "Track the customer journey more efficiently to support your sales and marketing objectives,",
  features4: "Develop result-oriented strategies by integrating with digital marketing tools.",
  buttonTitle: "Request a Quote",
  formDescription: "Fill out the form and we’ll get back to you as soon as possible.",
  sendSuccess: "Thank you! We will get back to you as soon as possible.",
  formTitle: "Request a Demo",
  formButtonTitle: "Submit",
  fullName: "Full Name",
  email: "Email",
  phone: "Phone",
  orgName: "Organization Name",
};

export default locale;
