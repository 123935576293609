const locale = {
  supports: "Destekler",
  support: "Destek",
  supportConversions: "Destek Dönüştürme",
  title: "Destekler",
  addSupport: "Destek Ekle",
  emptyStateDesc1: "Henüz hernangi bir destek bileti mevcut değil.",
  emptyStateDesc2: "Zamanında yanıt ve mükemmel hizmet sağlamak için müşteri sorgularını yönetin ve destek isteklerini buradan takip edin."
};

export default locale;



