const locale = {
  lead: "Lead",
  leads: "Lead'ler",
  create: "Lead Oluştur",
  leadDetail: "Lead Detayı",
  update: "Lead Güncelle",
  delete: "Sil",
  view: "Lead'i Görüntüle",
  list: "Lead Listesi",
  createdSuccessfully: "Lead başarıyla oluşturuldu",
  updatedSuccessfully: "Lead başarıyla güncellendi",
  deleteConfirm:
    "Kaydı silmek istediğinizden emin misiniz? Bu işlem geri alınamaz.",
  deletedSuccessfully: "Lead başarıyla silindi",
  createInfo: "Bu bölümden yeni kayıt oluşturabilirsiniz.",
  fullName: "Adı Soyadı",
  status: "Durum",
  source: "Kaynak",
  title: "Lead Listesi",
  tags: "Etiketler",
  assigneds: "Atananlar",
  position: "Pozisyon",
  email: "E-posta",
  website: "Web Sitesi",
  phoneNumber: "Telefon Numarası",
  company: "Şirket",
  sector: "Sektör",
  type: "Tip",
  qualification: "Derece",
  country: "Ülke",
  state: "Eyalet/İl",
  city: "İlçe",
  justOwn: "Sadece Kendim",
  isJunk: "Çöp Kutusu",
  note: "Not",
  language: "Dil",
  listInfo: "Tüm kayıtların listesi.",
  importedError: "İçe aktarım hatası",
  importedSuccessfully: "İçe aktarıldı",
  downloadFormError: "Form indirilemedi",
  downloadFormSuccessfully: "Form indirildi",
  downloadExampleFormSuccessfully: "Örnek form indirildi",
  downloadExampleFormError: "Örnek form indirilemedi",
  emptyState1: "Burada hiçbir lead bulunmadı.",
  emptyState2: "Hemen lead oluşturabilir ya da içeriye aktarabilirsiniz.",
  createLead: "Lead Oluştur",
  importLead: "Lead İçe Aktar",
  importTitle: "Leadleri İçe Aktar",
  downloadExample: "Örnek Formu İndir",
  importText1: "Bu sayfadan leadleri içeriye aktarabilirsiniz.",
  importText2:
    "Leadlerinizi yayınlayabilmeniz için aşağıdaki örnek formu kullanabilirsiniz.",
  importExport: "İçeri/Dışarı Aktar",
  exportData: "Veriyi Dışarı Aktar",
  importData: "Veriyi İçe Aktar",
  b2b: "B2B",
  b2c: "B2C",
  viewLead: "Görüntüle",
  edit: "Düzenle",
  junk: "Çöp Kutusu",
  unJunk: "Çöp Kutusundan Kaldır",
  addNewRole: "Yeni Rol Ekle",
  actions: "İşlemler",
  tagsPlaceholder: "Etiketler",
  leadValue: "Lead Değeri",
  department: "Departman",
  updateLeadSuccess: "Lead başarıyla güncellendi!",
  createLeadSuccess: "Lead başarıyla oluşturuldu!",
  phone: "Telefon",
  name: "Adı",
  export: "İndir",
  contactedDate: "Görüşme Tarihi",
  emptyStateContactContent1: "Herhangi bir iletişim bulunamadı.",
  noEstimateContent1: "Herhangi bir estimate bulunamadı.",
  noPorposalContent1: "Herhangi bir teklif bulunamadı.",
  emptyStateReminderList1: "Herhangi bir hatırlatma bulunamadı.",
  content: "İçerik",
  priority: "Öncelik",
  remindDate: "Hatırlatma Tarihi",
  remindUsers: "Bildirilecek Kullanıcılar",
  remindTitle: "Başlık",
  emptyStateNoteList1: "Herhangi bir hatırlatma bulunamadı.",
  noteDate: "Hatırlatma Tarihi",
  noteUsers: "Bildirilecek Kullanıcılar",
  noteTitle: "Başlık",
  leadConversions: "Lead Dönüşüm",
  contactDetail: "İletişim Detay",
  prefix: "Bilgi",
  createdAt: "Oluşturulma Tarihi",
  noDocumentContent1: "Herhangi bir belge bulunamadı.",
  noActivityContent1: "Herhangi bir aktivite bulunamadı.",
  info: "Bilgi",
  contactHistory: "İletişim Geçmişi",
  estimates: "Tahminler",
  proposals: "Teklifler",
  documents: "Belgeler",
  notes: "Notlar",
  reminders: "Hatırlatmalar",
  activities: "Aktiviteler",
  about: "Hakkında",
  companyName: "Şirket Adı",
  localization: "Lokasyon",
  addContact: "İletişim Ekle",
  addEstimate: "Tahmin Ekle",
  addProposal: "Teklif Ekle",
  addDocument: "Belge Ekle",
  addNote: "Not Ekle",
  addReminder: "Hatırlatma Ekle",
  addActivity: "Aktivite Ekle",
  noProposalContent1: "Herhangi bir teklif bulunamadı.",
  businessType: "İşletme Tipi",
  tracking: "Takip",
  leadCount: "lead",
  of: "/",
  appointment: "Randevu",
  proposalSent: "Teklif Gönderildi",
  contactDetails: "İletişim Detayları",
  emailAddress: "E-posta Adresi",
  assignees: "Atananlar",
  noAssignees: "Atanan yok",
  hasCreatedAt: "oluşturdu. ",
  createdBy: "Oluşturan ",
  lastContacted: "Son Görüşme",
  total: "Toplam",
  leadType: "Lead Tipi",
  lastContact: "Son Görüşme",
  noAppointments: "Henüz Randevu Yok",
  makeAnAppointment: "Randevu Oluştur",
  appointments: "Randevular",
  contacted: "Görüşmeyi Yapan",
  onSiteEstimateWith: "Tahmmin Yapıldı: ",
  prospectName: "Müşteri Adı: ",
  noEstimate: "Henüz bir tahmin eklenmedi.",
  noProposal: "Henüz bir teklif eklenmedi.",
  noDocument: "Henüz bir belge eklenmedi.",
  noNote: "Henüz bir not eklenmedi.",
  noReminder: "Henüz bir hatırlatma eklenmedi.",
  noActivity: "Henüz bir aktivite eklenmedi.",
  convertToCustomer: "Müşteriye Dönüştür",
  firstName: "Adı",
  lastName: "Soyadı",
  convert: "Dönüştür",
  customerType: "Müşteri Tipi",
  customerCreatedSuccessfully: "Lead başarıyla dönüştürüldü.",
  noProposalSent: "Henüz bir teklif gönderilmedi.",
  tasks: "Görevler",
  noTask: "Herhangi bir görev bulunamadı.",
};

export default locale;
