const locale = {
  invoice: "Invoice",
  invoices: "Invoices",
  create: "Create Invoice",
  update: "Update Invoice",
  delete: "Delete",
  view: "View Invoice",
  list: "List Invoices",
  createdSuccessfully: "Invoice created successfully",
  updatedSuccessfully: "Invoice updated successfully",
  deleteConfirm:
    "Are you sure you want to delete? This action cannot be undone.",
  deletedSuccessfully: "Invoice deleted successfully",
  createInfo: "You can create new invoices here.",
  prefix: "Prefix",
  invoiceId: "Invoice",
  timeZone: "TimeZone",
  customerId: "Customer",
  customerName: "Customer Name",
  customerType: "Customer Type",
  customerCity: "Customer City",
  customerCountry: "Customer Country",
  customerContactEmail: "Customer Email",
  customerContactLastName: "Customer Last Name",
  customerContactFirstName: "Customer First Name",
  billingInfo: "billingInfo",
  ["billingInfo.fullName"]: "Full Name",
  ["billingInfo.phone"]: "Phone",
  ["billingInfo.address"]: "Address",
  ["billingInfo.country"]: "Country",
  ["billingInfo.state"]: "State",
  ["billingInfo.city"]: "City",
  ["billingInfo.street"]: "Street",
  ["billingInfo.zip"]: "ZIP",
  ["billingInfo.vat"]: "VAT",
  ["billingInfo.taxOffice"]: "Tax Office",
  shippingInfo: "shippingInfo",
  ["shippingInfo.fullName"]: "Full Name",
  ["shippingInfo.phone"]: "Phone",
  ["shippingInfo.address"]: "Address",
  ["shippingInfo.country"]: "Country",
  ["shippingInfo.state"]: "State",
  ["shippingInfo.city"]: "City",
  ["shippingInfo.street"]: "Street",
  ["shippingInfo.zip"]: "ZIP",
  isRecurring: "isRecurring",
  recurringFrequency: "Recurring Frequency",
  recurringDueDate: "Recurring Due Date",
  recurringCount: "Recurring Count",
  language: "Language",
  date: "Date",
  expireDate: "Expire Date",
  sendEmailOnExpiration: "Send Email On Expiration",
  expirationEmailSentDate: "Expiration Email Sent Date",
  allowedPaymentMethods: "Allowed Payment Methods",
  paymentTerms: "Payment Terms",
  currency: "Currency",
  products: "Products",
  addProducts: "Add Products",
  offers: "Offers",
  reference: "Reference",
  salesAgentIds: "Sales Agents",
  allowComments: "Allow Comments",
  useLegalNDA: "Use Legal NDA",
  legalNDAId: "Legal NDA",
  useLegalTermAndCond: " Use Legal Term And Conditions",
  legalTermAndCondId: "Legal Term And Cond",
  signerUserId: "Signer User",
  signatureImage: "Signature Image",
  listInfo: "List of all invoices.",
  importedError: "Imported error",
  importedSuccessfully: "Imported successfully",
  downloadFormError: "Download form error",
  downloadFormSuccessfully: "Download form successfully",
  downloadExampleFormSuccessfully: "Download example form successfully",
  downloadExampleFormError: "Download example form error",
  title: "Invoices",
  emptyState1: "No invoices found here.",
  emptyState2: "You can create or import invoices immediately.",
  createInvoice: "Create Invoice",
  importInvoice: "Import Invoice",
  importTitle: "Import Invoices",
  downloadExample: "Download Example",
  importText1: "You can import invoices from this page.",
  importText2: "You can use the sample form below to publish your invoices.",
  importExport: "Import/Export",
  exportData: "Export Data",
  importData: "Import Data",
  b2b: "B2B",
  b2c: "B2C",
  viewInvoice: "View",
  edit: "Edit",
  junk: "Junk",
  unJunk: "Un-Junk",
  addNewRole: "Add New Role",
  actions: "Actions",
  allowedPaymentMethodsPlaceholder: "Add Payment Method",
  timezone: "Timezone",
  followUp: "Follow Up",
  lastSent: "Last Sent",
  dueDate: "Due Date",
  sendEmail: "Send Email",
  customer: "Customer",
  country: "Country",
  city: "City",
  contactFirstName: "Contact First Name",
  contactLastName: "Contact Last Name",
  contactEmail: "Contact Email",
  services: "Services",
  businessName: "Business Name",
  governmentAgency: "Government Agency",
  fullName: "Full Name",
  VAT: "VAT",
  phoneNumber: "Phone Number",
  state: "State",
  ZIP: "ZIP",
  address: "Address",
  sentDate: "Sent Date",
  name: "Name",
  description: "Description",
  productUnit: "Unit",
  quantity: "Quantity",
  price: "Price",
  priceType: "Price Type",
  discountedPrice: "Discounted Price",
  finalPrice: "Final Price",
  tax: "Tax",
  service: "Service",
  subTotal: "Sub Total",
  taxTotal: "Tax Total",
  discount: "Discount",
  product: "Product",
  references: "References",
  add: "Add",
  salesAgent: "Sales Agent",
  executiveSummary: "Executive Summary",
  companyBackground: "Company Background",
  projectorServiceDesc: "Detailed Project/Service Description",
  costBreakdown: "Cost Breakdown",
  projectTimeline: "Project Timeline",
  testimonialCaseStudies: "Testimonial Case Studies",
  conclusion: "Conclusion",
  
};

export default locale;
