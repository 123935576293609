import { GridColDef } from "@mui/x-data-grid-pro";
import { IGridColConfigInput } from "../infra/IGridColConfigInput";
import { TDefaultObjectType, TEnum, TEnumColorType } from "../infra/types";
import { BaseTextGridColumnDef } from "./BaseTextGridColumnDef";
import { BaseEnumGridColumnDef } from "./BaseEnumGridColumnDef";
import { BaseGridColType } from "../infra/enums";
import { BaseIconGridColumnDef } from "./BaseIconGridColumnDef";
import { BaseColorGridColumnDef } from "./BaseColorGridColumnDef";
import { BaseObjectGridColumnDef } from "./BaseObjectGridColumnDef";
import { BaseTextArrayGridColumnDef } from "./BaseTextArrayGridColumnDef";
import { IBaseGridColumnParams } from "../infra/IBaseGridColumnParams";
import { BaseAnyGridColumnDef } from "./BaseAnyGridColumnDef";

export const BaseGridColumnDef = <T,>(
  params: IBaseGridColumnParams<T>
): GridColDef => {
  switch (params.config.baseGridColType) {
    case BaseGridColType.text:
      return BaseTextGridColumnDef({
        config: params.config,
      });
    case BaseGridColType.enum:
      return BaseEnumGridColumnDef({
        config: params.config,
        enumType: params.data as TEnum,
        enumColor: params.metaData?.enumColor as TEnumColorType | undefined,
      });
    case BaseGridColType.icon:
      return BaseIconGridColumnDef({
        config: params.config,
      });
    case BaseGridColType.color:
      return BaseColorGridColumnDef({
        config: params.config,
      });
    case BaseGridColType.object:
      return BaseObjectGridColumnDef({
        config: params.config as IGridColConfigInput<TDefaultObjectType>,
      });
    case BaseGridColType.any:
      return BaseAnyGridColumnDef({
        config: params.config,
      });
    case BaseGridColType.textArray:
      return BaseTextArrayGridColumnDef({
        config: params.config,
        textValues: params.data as string[],
      });

    default:
      return BaseTextGridColumnDef({
        config: params.config,
      });
  }
};

export const generateBaseGridColumnDefs = (
  columnParamsList: IBaseGridColumnParams[]
): GridColDef[] => {
  const colDefList = columnParamsList.map((colParams) => {
    return BaseGridColumnDef({
      config: colParams.config,
      data: colParams.data,
      metaData: colParams.metaData,
    });
  });

  return colDefList;
};
