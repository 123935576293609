

import { SalesDomainLayout } from "../layouts";
import { InvoicesRouter } from "../subdomains/invoices/routes/InvoicesRouter";
import { PaymentsRouter } from "../subdomains/payments/routes/PaymentsRouter";
import { ReturnsRouter } from "../subdomains/returns/routes/ReturnsRouter";
import { salesDomainRoutes } from "./sales.base.route";
import { Route, Routes } from "react-router-dom";

export const SalesDomainRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<SalesDomainLayout />}>
        <Route index element={<InvoicesRouter />} />
        <Route
          path={`${salesDomainRoutes.subdomains.invoices.base}/*`}
          element={<InvoicesRouter />}
        />
        <Route
          path={`${salesDomainRoutes.subdomains.payments.base}/*`}
          element={<PaymentsRouter />}
        />
        <Route
          path={`${salesDomainRoutes.subdomains.returns.base}/*`}
          element={<ReturnsRouter />}
        />
      </Route>
    </Routes>
  );
};
