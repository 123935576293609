const locale = {
  returns: "Returns",
  return: "Return",
  returnConversions: "Return Conversions",
  title: "Returns",
  addReturn: "Add Return",
  emptyStateDesc1: "No returns recorded yet.",
  emptyStateDesc2: "Manage and review any product or service returns here to keep your records accurate and customer needs met."
};

export default locale;
