const locale = {
  projects: "Projects",
  project: "Project",
  projectConversions: "Project Conversions",
  title: "Projects",
  addProject: "Add Project",
  emptyStateDesc1: "No projects started yet.",
  emptyStateDesc2: "Create and manage your projects here to track progress, assign tasks, and collaborate with your team."
};

export default locale;



 

