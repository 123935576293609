
import { Stack, Typography } from "@mui/material"
import { OutlinedButton } from "components/buttons"
import * as Icons from "@mui/icons-material"

type IEmptyState = {
    content1: string
    content2?: string
    button1?: {
        title: string
        onClick: () => void
        leftIcon: JSX.Element
    }
    button2?: {
        title: string
        onClick: () => void
        leftIcon: JSX.Element
    }
    icon?: JSX.Element
}

const EmptyState = (props: IEmptyState) => {
    return <Stack
        alignItems="center"
        justifyContent="center"
        bgcolor={"background.thirdWithBlur"}
        gap={1}
        pt={2}
        borderRadius={3}
        width={"100%"}
        height={"100%"}
    >
        {props?.icon
            ? props.icon
            : <Icons.ContentPasteOffOutlined sx={{ opacity: 0.2, fontSize: "100px" }} />
        }
        <Typography variant="body2" px={2} textAlign={"center"}>{props.content1}</Typography>
        <Typography variant="body2" px={2} textAlign={"center"}>{props.content2}</Typography>
        <Stack direction="row" spacing={1} mt={2}>
            {props.button1 && <OutlinedButton
                title={props.button1.title}
                onClick={() => props.button1?.onClick()}
                leftIcon={props.button1.leftIcon}
            />}
            {props.button2 && <OutlinedButton
                title={props.button2.title}
                leftIcon={props.button2.leftIcon}
                onClick={() => props.button2?.onClick()}
            />}
        </Stack>
    </Stack>
}

export default EmptyState