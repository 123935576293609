
import projectsLocale from "../subdomains/projects/locales/tr";


const locale = {

  // Todo
  // users: {
  //   ...usersLocale,
  // },
  projects: {
    ...projectsLocale,
  },
};

export default locale;

