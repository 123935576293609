export const leadTags = {
  leads: "leads",
  lead: "lead",
  leadStatuses: "leadStatuses",
  leadStatus: "leadStatus",
  leadSources: "leadSources",
  leadSource: "leadSource",
  leadTags: "leadTags",
  note: "note",
  notes: "notes",
};
