/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import { useEffect } from "react";
import { useProposalDeleteMutation } from "../context/proposals.api";
import { IBaseError } from "corede-common"
import { enqueueSnackbar } from "notistack";
import { getCurrentLanguage } from "localization";
import { DefaultErrorHandlerUseEffect } from "utils/useEffect.helper";
import { IBaseProposalEntity } from "corede-common-cocrm";
import { useTranslation } from "react-i18next";
import ActionDialog from "components/dialog/ActionDialog";

const Delete = (prop: {
  open: boolean;
  item: IBaseProposalEntity;
  onClose: () => void;
}) => {
  // general
  const currentLanguage = getCurrentLanguage();
  const { t } = useTranslation();

  // queries

  // mutations
  const [proposalStatusDelete, { data: proposalStatusDeleteData, isLoading: proposalStatusDeleteLoading, error: proposalStatusDeleteError }] = useProposalDeleteMutation();

  // constants

  // action handlers
  const handleDelete = (_id: string) => {
    proposalStatusDelete({ input: { _id: _id } });
  };

  // useEffects.success
  useEffect(() => {
    if (proposalStatusDeleteData) {
      prop?.onClose();
      enqueueSnackbar(t("crm.proposal.proposals.deletedSuccessfully"), {
        variant: "success",
      });
    }
  }, [proposalStatusDeleteData]);

  // useEffects.error
  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      proposalStatusDeleteError as IBaseError,
      currentLanguage
    );
  }, [proposalStatusDeleteError, currentLanguage]);

  // useEffects.init

  return (
    <ActionDialog
      open={prop?.open}
      onClose={prop?.onClose}
      title={t("crm.proposal.proposals.delete")}
      buttonTitle={t("delete")}
      buttonColor="error"
      handleClick={() => prop?.onClose()}
      disabled={proposalStatusDeleteLoading}
      loading={proposalStatusDeleteLoading}
    >
      {t("crm.proposal.proposals.deleteConfirm")} {prop?.item?.prefix}?

    </ActionDialog >
  );
};

export default Delete;
