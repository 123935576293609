/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from "@mui/material";
import List from "./List";
import { memo } from "react";
import ActionDialog from "components/dialog/ActionDialog";


interface IAddRoleDialog {
  open: boolean
  setOpen: (open: boolean) => void
}

export const AddRoleDialog = memo((props: IAddRoleDialog) => {

  return <ActionDialog
    open={props.open}
    onClose={() => props.setOpen(false)}
    title="Add Role"
    width={700}
  >
      <List type="onlyAdd" />
  </ActionDialog>
});

