import { Route, Routes } from "react-router-dom";

import { List} from "../pages";

import { CategoriesLayout } from "../layouts/CategoriesLayout"; 

export const CategoriesRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<CategoriesLayout/>}>
        <Route index element={<List />} />
       </Route>
    </Routes>
  );
};
