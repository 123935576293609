import { GridColDef } from "@mui/x-data-grid-pro";
import { IGridColConfigInput } from "../infra/IGridColConfigInput";
import { Box } from "@mui/material";
import { generateBaseColDef } from "../infra/GridColDefFactory";

export const BaseColorGridColumnDef = (params: {
  config: IGridColConfigInput;
}): GridColDef => {
  const baseColDef = generateBaseColDef({
    config: params.config,
  });

  baseColDef.renderCell = (cellParams: any) => {
    return (
      <Box
        sx={{
          mt: 2,
          ml: 0.5,
          width: 20,
          height: 20,
          borderRadius: "50%",
          backgroundColor: cellParams.value ?? "background.primary",
        }}
      />
    );
  };
  baseColDef.renderEditCell = () => null;

  return baseColDef;
};
