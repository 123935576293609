import { Stack, Typography, Grid } from "@mui/material"
import { FilledButton, OutlinedButton } from "components/buttons"
import { isValidElement } from "react"

type Props = {
    children: React.ReactNode
    title?: string
    rightButton?: {
        title: string
        onClick: () => void
        icon?: React.ReactNode
    }
    type?: "drawer" | null
}

const DetailBox = (props: Props) => {
    return <Stack direction={"column"} borderRadius={"20px"} py={props.type=== "drawer" ? 0 : 2} px={props.type=== "drawer" ? 2 : 4}
        sx={{ boxShadow: props.type === "drawer" ? undefined : "0px 0px 20px #ccc" }} >
        <Stack direction="row" justifyContent={"space-between"} alignItems={"center"}>
            <Typography fontSize={props.type === "drawer" ? "16px" : "24px"} fontWeight={600}>{props.title}</Typography>
            {props?.rightButton && <OutlinedButton
                title={props.rightButton?.title}
                onClick={props.rightButton?.onClick}
                leftIcon={isValidElement(props.rightButton?.icon) ? props.rightButton?.icon : undefined}
            />}
        </Stack>
        <Grid container sx={{ mt: 2 }}>
            {props.children}
        </Grid>
    </Stack>
}

export default DetailBox