/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useLeadStatusDeleteMutation } from "../context/leadStatuses.api";
import { IBaseError } from "corede-common";
import { enqueueSnackbar } from "notistack";
import { getCurrentLanguage } from "localization";
import { DefaultErrorHandlerUseEffect } from "utils/useEffect.helper";
import { ILeadStatus } from "corede-common-cocrm";
import { useTranslation } from "react-i18next";
import ActionDialog from "components/dialog/ActionDialog";

const Delete = (prop: {
  open: boolean;
  item: ILeadStatus;
  onClose: () => void;
}) => {
  // general
  const currentLanguage = getCurrentLanguage();
  const { t } = useTranslation();

  // queries

  // mutations
  const [
    leadStatusDelete,
    {
      data: leadStatusDeleteData,
      isLoading: leadStatusDeleteLoading,
      error: leadStatusDeleteError,
    },
  ] = useLeadStatusDeleteMutation();

  // constants

  // action handlers
  const handleDelete = (_id: string) => {
    leadStatusDelete({
      input: {
        _id: prop.item._id,
      }
    })
  };

  // useEffects.success
  useEffect(() => {
    if (leadStatusDeleteData) {
      prop?.onClose();
      enqueueSnackbar(t("crm.lead.leadStatuses.deletedSuccessfully"), {
        variant: "success",
      });
    }
  }, [leadStatusDeleteData]);

  // useEffects.error
  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      leadStatusDeleteError as IBaseError,
      currentLanguage
    );
  }, [leadStatusDeleteError, currentLanguage]);

  // useEffects.init

  return (
    <ActionDialog
      open={prop?.open}
      onClose={prop?.onClose}
      title={t("crm.lead.leadStatuses.delete")}
      buttonTitle={t("delete")}
      buttonColor="error"
      handleClick={() => handleDelete(prop?.item?._id as string)}
      disabled={leadStatusDeleteLoading}
      loading={leadStatusDeleteLoading}
    >
      {t("crm.lead.leadStatuses.deleteConfirm")} {prop?.item?.name}?
    </ActionDialog>
  );
};

export default Delete;
