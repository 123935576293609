import servicesLocale from "../subdomains/services/locales/en";
import productsLocale from "../subdomains/products/locales/en";
import categoriesLocale from "../subdomains/productCategories/locales/en";

const locale = {
  products: {
    ...productsLocale,
  },
  productCategories: {
    ...categoriesLocale,
  },
  services: {
    ...servicesLocale,
  },
};

export default locale;
