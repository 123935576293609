

import { CalendarDomainLayout } from "../layouts";
import { AppointmentsRouter } from "../subdomains/appointments/routes/AppointmentsRouter";
import { RemindersRouter } from "../subdomains/reminders/routes/RemindersRouter";
import { calendarDomainRoutes } from "./calendar.base.route";
import { Route, Routes } from "react-router-dom";

export const CalendarDomainRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<CalendarDomainLayout />}>
        <Route index element={<RemindersRouter />} />
        <Route
          path={`${calendarDomainRoutes.subdomains.reminders.base}/*`}
          element={<RemindersRouter />}
        />
        <Route
          path={`${calendarDomainRoutes.subdomains.appointments.base}/*`}
          element={<AppointmentsRouter />}
        />
        {/* Todo 
         <Route index element={<UsersRouter />} />
         <Route
          path={`${customerDomainRoutes.subdomains.users.base}/*`}
          element={<UsersRouter />}
        /> */}
      </Route>
    </Routes>
  );
};
