import { Route, Routes } from "react-router-dom";

import { List, Update, UserDetail, UserList } from "../pages";

import { UsersLayout } from "../layouts/UsersLayout";
import { commonRoutes } from "routes/route.common";

export const UsersRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<UsersLayout />}>
        <Route index element={<List />} />
        <Route path="/:id" element={<UserDetail />} />
        <Route path={`/:id/${commonRoutes.update}`} element={<Update />} />
      </Route>
    </Routes>
  );
};
