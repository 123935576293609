import {

  IPermissionListInput, IPermissionListRequest, IPermissionListResponse, IPermissionListResult, permissionListQuery,
  IRoleListInput, IRoleListRequest, IRoleListResponse, IRoleListResult, roleListQuery,
  IRoleDetailInput, IRoleDetailRequest, IRoleDetailResponse, IRoleDetailResult, roleDetailQuery,

  IRoleCreateInput, IRoleCreateRequest, IRoleCreateResponse, IRoleCreateResult, roleCreateQuery,
  IRoleUpdateInput, IRoleUpdateRequest, IRoleUpdateResponse, IRoleUpdateResult, roleUpdateQuery, IRoleUpdateFilterInput,
  IRoleDeleteInput, IRoleDeleteRequest, IRoleDeleteResponse, IRoleDeleteResult, roleDeleteQuery,

} from "corede-common-cocrm";
import { commonApi } from "context/commonApi";
import { EndpointQueryBuilder } from "apps/helper/endpoint-query.builder";
import { permissionsTags } from "./permissions.tags";
import { IGraphqlVariables } from "corede-common";

export const permissionsApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({
    // queries
    permissionList: builder.query<
    IPermissionListResult,
    IGraphqlVariables<IPermissionListInput>
  >(
    EndpointQueryBuilder.BuildGraphqlQuery<
      IPermissionListRequest,
      IPermissionListResponse,
      IPermissionListResult,
      IPermissionListInput
    >({
      query: permissionListQuery,
      providesTags: [permissionsTags.permissions],
    })),

  roleList: builder.query<
    IRoleListResult,
    IGraphqlVariables<IRoleListInput>
  >(
    EndpointQueryBuilder.BuildGraphqlQuery<
      IRoleListRequest,
      IRoleListResponse,
      IRoleListResult,
      IRoleListInput
    >({
      query: roleListQuery,
      providesTags: [permissionsTags.roles],
    })),

  roleDetail: builder.query<
    IRoleDetailResult,
    IGraphqlVariables<IRoleDetailInput>
  >(
    EndpointQueryBuilder.BuildGraphqlQuery<
      IRoleDetailRequest,
      IRoleDetailResponse,
      IRoleDetailResult,
      IRoleDetailInput
    >({
      query: roleDetailQuery,
      providesTags: [permissionsTags.roles, permissionsTags.role]
    })),

    // mutation

    roleCreate: builder.mutation<
      IRoleCreateResult,
      IGraphqlVariables<IRoleCreateInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IRoleCreateRequest,
        IRoleCreateResponse,
        IRoleCreateResult,
        IRoleCreateInput
      >({
        query: roleCreateQuery,
        invalidatesTags: [permissionsTags.roles, permissionsTags.role],
      })),

    roleUpdate: builder.mutation<
      IRoleUpdateResult,
      IGraphqlVariables<IRoleUpdateInput, IRoleUpdateFilterInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IRoleUpdateRequest,
        IRoleUpdateResponse,
        IRoleUpdateResult,
        IRoleUpdateInput,
        IRoleUpdateFilterInput
      >({
        query: roleUpdateQuery,
        invalidatesTags: [permissionsTags.roles, permissionsTags.role],
      })),

    roleDelete: builder.mutation<
      IRoleDeleteResult,
      IGraphqlVariables<IRoleDeleteInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IRoleDeleteRequest,
        IRoleDeleteResponse,
        IRoleDeleteResult,
        IRoleDeleteInput
      >({
        query: roleDeleteQuery,
        invalidatesTags: [permissionsTags.roles, permissionsTags.role],
      })),

  
  }),
});

export const {
  usePermissionListQuery,
  useLazyPermissionListQuery,
  useRoleListQuery,
  useLazyRoleListQuery,
  useRoleDetailQuery,
  useLazyRoleDetailQuery,


  useRoleCreateMutation,
  useRoleUpdateMutation,
  useRoleDeleteMutation,
} = permissionsApi;
