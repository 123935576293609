import leadLocale from "../domains/03-lead/locales/tr";
import dashboardLocale from "../domains/01-dashboard/locales/tr";
import organizationalChartLocale from "../domains/02-organizationalChart/locales/tr";
import customerLocale from "../domains/04-customer/locales/tr";
import pricingLocale from "../domains/16-pricing/locales/tr";
import productsAndServicesLocale from "../domains/08-productsAndServices/locales/tr";
import preSalesLocale from "../domains/05-preSales/locales/tr";
import salesLocale from "../domains/06-sales/locales/tr";
import calendarLocale from "../domains/12-calendar/locales/tr";
import contractsLocale from "../domains/07-contracts/locales/tr";
import supportLocale from "../domains/15-support/locales/tr";
import reportsLocale from "../domains/13-reports/locales/tr";
import tasksLocale from "../domains/11-tasks/locales/tr";
import projectsLocale from "../domains/10-projects/locales/tr";
import expensesLocale from "../domains/09-expenses/locales/tr";
import notesLocale from "../domains/14-notes/locales/tr";

const locale = {
  lead: {
    ...leadLocale,
  },
  dashboard: {
    ...dashboardLocale,
  },
  organizationalChart: {
    ...organizationalChartLocale,
  },
  customer: {
    ...customerLocale,
  },
  pricing: {
    ...pricingLocale,
  },
  productsAndServices: {
    ...productsAndServicesLocale,
  },
  preSales: {
    ...preSalesLocale,
  },
  sales: {
    ...salesLocale,
  },
  calendar: {
    ...calendarLocale
  },
  contracts: {
    ...contractsLocale
  },
  support: {
    ...supportLocale
  },
  reports: {
    ...reportsLocale
  },
  tasks: {
    ...tasksLocale
  },
  projects: {
    ...projectsLocale
  },
  expenses: {
    ...expensesLocale
  },
  notes: {
    ...notesLocale
  },
  pricingLabel: "Fiyatlandırma",
  contractsLabel: "Sözleşmeler",
  dashboardLabel: "Gösterge Paneli",
  leadLabel: "Lead",
  customerLabel: "Müşteri",
  customers: "Müşteriler",
  customerGroups: "Müşteri Grupları",
  customerSegments: "Müşteri Segmentleri",
  customerTags: "Müşteri Etiketleri",
  marketing: "Pazarlama",
  campaigns: "Kampanyalar",
  emails: "E-postalar",
  sms: "SMS",
  socialMedia: "Sosyal Medya",
  preSales_: "Ön Satış",
  proposals: "Teklifler",
  estimates: "Tahminler",
  sales_: "Satışlar",
  invoices: "Faturalar",
  payments: "Ödemeler",
  productsServices: "Ürünler ve Hizmetler",
  businessIntelligence: "İş Zekası",
  calendar_: "Takvim",
  accountMenu: "Hesap Menüsü",
  profile: "Profil",
  myAccount: "Hesabım",
  darkMode: "Karanlık Mod",
  lightMode: "Aydınlık Mod",
  languageSelectLabel: "Dil Seçimi",
  english: "İngilizce",
  turkish: "Türkçe",
  settings: "Ayarlar",
  logout: "Çıkış Yap",
  searchPlaceholder: '"bir şeyler" aramayı deneyin',
  mustCreateOrganizationTitle: "Organizasyon Oluştur",
  mustCreateOrganizationContent: "Paneli kullanabilmek için bir organizasyon oluşturmanız gerekmektedir.",
  mustCreateOrganizationSuccess: "Organizasyon oluşturuldu",
  name: "Ad",
  cancel: "İptal",
  create: "Oluştur",
  companyName: "Şirket Adı",
  companyEmail: "Şirket E-postası",
  companyPhone: "Şirket Telefonu",
  country: "Ülke",
  city: "İl",
  industry: "Sektör",
  timezone: "Saat Zamanı",
  address: "Adres",
  website: "Web Sitesi",
  organizationalChartLabel: "Organizasyon Şeması",
  preSalesLabel: "Ön Satış",
  salesLabel: "Satış",
  expensesLabel: "Giderler",
  productsAndServicesLabel: "Ürünler ve Hizmetler",
  projectsLabel: "Projeler",
  tasksLabel: "Görevler",
  calendarLabel: "Takvim",
  reportsLabel: "Raporlar",
  supportLabel: "Destek",
  notesLabel: "Notlar",
  companyLogo: "Şirket Logosu",
  actions: "Aksiyon",
  yes: "Evet",
  no: "Hayır",
};

export default locale;
