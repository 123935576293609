const locale = {
  payments: "Ödemeler",
  payment: "Ödeme",
  paymentConversions: "Ödeme Dönüştürme",
  title: "Ödemeler",
  addPayment: "Ödeme Ekle",
  emptyStateDesc1: "Henüz ödeme kaydedilmedi.",
  emptyStateDesc2: "Finansmanınızı düzenli ve güncel tutmak için faturalarınızın ödemelerini buradan takip edin ve yönetin."
};

export default locale;
