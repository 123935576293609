/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography, Grid } from "@mui/material";
import { IBaseError } from "corede-common";
import { IBaseUserEntity, IUser } from "corede-common-cocrm";
import { getCurrentLanguage } from "localization";
import { enqueueSnackbar } from "notistack";
import { memo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { DefaultErrorHandlerUseEffect } from "utils/useEffect.helper";
import { useUserDeleteMutation } from "../context/users.api";
import ActionDialog from "components/dialog/ActionDialog";
import { useUserDetailOwnQuery } from "apps/auth/context";

export const DeleteDialog = memo((prop: {
  open: boolean;
  item: IBaseUserEntity | undefined;
  setItem: (item: IUser | undefined) => void;
  onClose: () => void;
}) => {
  // general
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();

  // queries

  const { data: userDetailOwnData } = useUserDetailOwnQuery({});

  // mutations
  const [userDelete, { data: userDeleteData, isLoading: userDeleteIsLoading, error: userDeleteError }] = useUserDeleteMutation();

  // constants

  // // action handlers
  const handleDelete = (_id: string) => {
    userDelete({ input: { _id: _id } });
  };

  // useEffects.success
  useEffect(() => {
    if (userDeleteData) {
      prop.setItem(undefined);
      prop?.onClose();
      enqueueSnackbar(t("crm.organizationalChart.users.deletedSuccessfully"), {
        variant: "success",
      });
    }
  }, [userDeleteData]);

  // // useEffects.error
  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      userDeleteError as IBaseError,
      currentLanguage
    );
  }, [userDeleteError, currentLanguage]);

  // useEffects.init

  return (
    <ActionDialog
      open={prop?.open}
      onClose={prop?.onClose}
      title={t("crm.organizationalChart.users.removeAccount")}
      buttonTitle={t("remove")}
      buttonColor="error"
      handleClick={() => handleDelete(prop?.item?._id as string)}
      disabled={userDeleteIsLoading || userDetailOwnData?._id === prop?.item?._id}
      loading={userDeleteIsLoading}
      width={400}
    >
      <Grid container spacing={2} my={2}>
        <Grid item xs={12} >
          {userDetailOwnData?._id !== prop?.item?._id ? <Typography>
            {t("crm.organizationalChart.users.deleteConfirm")}{prop?.item?.name}{" "}{prop?.item?.surname}"?
          </Typography> : <Typography>{t("crm.organizationalChart.users.notDeleteOwnConfirm")}</Typography>}
        </Grid>
      </Grid>
    </ActionDialog>
  );
});
