/* eslint-disable react-hooks/exhaustive-deps */
import { Avatar, Box, Button, Grid, IconButton, Stack, Typography } from "@mui/material";
import { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Update } from "@mui/icons-material";
import { HeaderBase } from "components/contentHeaders/crm/HeaderBase";
import { MyTab, } from "components";
import { listCustomersRoute } from "../../../routes/customer.base.route";
import { useCustomerDetailQuery, useCustomerEstimateListQuery, } from "../context/customers.api";
import { useNoteCreateMutation, useNoteDeleteMutation, useNoteListQuery, useNoteUpdateMutation } from "apps/crm/domains/03-lead/subdomains/lead/context/lead.api";
import BgPatternDetail from "assets/images/bg-pattern-detail.png";
import DetailBox from "components/box/DetailBox";
import { ICustomerDetailResult, CustomerType, IEstimateListResult, IProposalListResult, ICustomerEstimateListResult, NoteTargetType } from "corede-common-cocrm";
import { convertEnums, getCurrentLanguage } from "localization";
import { Language } from "corede-common";
import { useEstimateListQuery } from "apps/crm/domains/05-preSales/subdomains/estimates";
import moment from "moment";
import { useProposalListQuery } from "apps/crm/domains/05-preSales/subdomains/proposals";
import { DataGridPro, GridColDef, useGridApiRef } from "@mui/x-data-grid-pro";
import { tableStyle } from "components/dataGrid/MyDataGrid";
import ReactQuill from "react-quill-new";
import { useFormik } from "formik";
import { enqueueSnackbar } from "notistack";
import * as Icons from '@mui/icons-material';
import { trTR } from '@mui/x-data-grid/locales';
import CustomerUpdateWrapper from "./CustomerUpdateWraper";
import EstimateCreateWraper from "apps/crm/domains/05-preSales/subdomains/estimates/pages/EstimateCreateWrapper";
import ProposalCreateWraper from "apps/crm/domains/05-preSales/subdomains/proposals/pages/ProposalCreateWrapper";
import CustomerListView from "./CustomerListView";




const CustomerDetail = memo(() => {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  if (!id) {
    navigate(listCustomersRoute());
  }
  const [selectedTab, setSelectedTab] = useState("Info");
  const [openCustomerUpdateDrawer, setOpenCustomerUpdateDrawer] = useState(false);
  const [openCreateEstimate, setOpenCreateEstimate] = useState(false);
  const [openCreateProposal, setOpenCreateProposal] = useState(false);
  const [openAddCustomerContact, setOpenAddCustomerContact] = useState(false);
  const [openUserDrawer, setOpenUserDrawer] = useState(false);

  // queries
  const { data: customerData } = useCustomerDetailQuery({
    input: {
      _id: id || ""
    }
  });

  const { data: customerEstimateListData } = useCustomerEstimateListQuery({
    input: {
      filter: {
        customerId: id || ""
      }
    }
  });
  
  console.log(customerEstimateListData);

  const { data: estimateListData } = useEstimateListQuery({
    input: {
      filter: {
        prospectEntityId: id
      }
    }
  })

  const { data: proposalListData } = useProposalListQuery({
    input: {
      filter: {
        prospectEntityId: id
      }
    }
  })


  return <Grid item xs={12}>
    <HeaderBase
      title="Customer Detail"
      showBackButton={true}
    />

    <ProfileHeader
      customerData={customerData}
      setSelectedTab={setSelectedTab}
    />

    <InfoSection
      customerData={customerData}
      selectedTab={selectedTab}
      setOpenCustomerUpdateDrawer={setOpenCustomerUpdateDrawer}
    />

    <EstimatesSection
      customerEstimateListData={customerEstimateListData}
      estimateListData={estimateListData}
      selectedTab={selectedTab}
      setOpenCreateEstimate={setOpenCreateEstimate}
    />

    <ProposalsSection
      proposalListData={proposalListData}
      selectedTab={selectedTab}
      setOpenCreateProposal={setOpenCreateProposal}
    />

    {/* <TasksSection selectedTab={selectedTab} /> */}

    <DocumentsSection
      selectedTab={selectedTab}
    />

    <NotesSection
      id={id ?? ""}
      selectedTab={selectedTab}
    />

    <ActivitiesSection
      selectedTab={selectedTab}
    />

    <BillingInfoSection
      customerData={customerData}
      selectedTab={selectedTab}
    />

    <Overlays
      openCustomerUpdateDrawer={openCustomerUpdateDrawer}
      setOpenCustomerUpdateDrawer={setOpenCustomerUpdateDrawer}
      customerData={customerData}
      openAddCustomerContact={openAddCustomerContact}
      setOpenAddCustomerContact={setOpenAddCustomerContact}
      openCreateEstimate={openCreateEstimate}
      setOpenCreateEstimate={setOpenCreateEstimate}
      openCreateProposal={openCreateProposal}
      setOpenCreateProposal={setOpenCreateProposal}
    />
  </Grid >
});
export default CustomerDetail;

const ProfileHeader = memo((props: {
  customerData: ICustomerDetailResult | undefined
  setSelectedTab: (value: string) => void
}) => {
  return <Stack direction={"column"} mt={2} borderRadius={"20px"} sx={{ boxShadow: "0px 0px 20px #ccc" }} >
    <Box
      width={"100%"}
      component={"img"}
      src={BgPatternDetail}
      sx={{ borderTopLeftRadius: "20px", borderTopRightRadius: "20px", height: "200px", brightness: "50%" }}
    />
    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} mx={2}>
      <Stack direction={"row"} alignItems={"center"} sx={{ mt: "-120px", }}>
        <Avatar
          sx={{ width: "120px", height: "120px", ml: 2, border: "3px solid #fff", objectFit: "cover", boxShadow: "0px 0px 5px #bbb", fontSize: "40px" }}
          alt="Remy Sharp"
        >
          {props.customerData?.name?.split(" ")?.map(n => n[0]).join("").toUpperCase()}
        </Avatar>
        <Stack direction={"column"}>
          <Typography fontSize={"24px"} fontWeight={600} ml={4} sx={{ zIndex: 1 }} color={"#fff"}>{props.customerData?.name} </Typography>
          <Typography fontSize={"14px"} fontWeight={600} ml={4} sx={{ zIndex: 1 }} color={"#fff"}>{props.customerData?.department?._id} </Typography>
        </Stack>
      </Stack>
      <MyTab
        labels={["Info", "Estimates", "Proposals", "Notes", "Documents", "Billing Info", //"Activities"
        ]}
        setSelectedTab={props.setSelectedTab}
      />
    </Stack>
  </Stack>
})

const InfoSection = memo((props: {
  customerData: ICustomerDetailResult | undefined;
  selectedTab: string;
  setOpenCustomerUpdateDrawer: (value: boolean) => void;
}) => {

  const localizedCustomerTypes = convertEnums(CustomerType);
  const localizedLanguages = convertEnums(Language);

  return props.selectedTab === "Info" ? <Grid container mt={2} gap={2} mb={2}>
    <Grid item xs={12}>
      <DetailBox
        title="About"
        rightButton={{
          title: "Update",
          icon: <Update sx={{ mr: 1 }} />,
          onClick: () => props.setOpenCustomerUpdateDrawer(true)
        }}
      >
        <Grid item display="flex" flexDirection="column" gap={1}>
          {[
            "Email",
            "Phone Number",
            "Type",

          ].map((label, index) => (
            <Box display="flex" alignItems="center" minHeight={35} key={index}>
              <Typography fontSize={"14px"} fontWeight={600}>
                {label}
              </Typography>
            </Box>
          ))}
        </Grid>
        <Grid item display="flex" flexDirection="column" gap={1}>
          {[
            props.customerData?.email,
            (props.customerData as any)?.phoneNumber,
            localizedCustomerTypes[props.customerData?.type as keyof typeof localizedCustomerTypes],
          ].map((value, index) => (
            <Box display="flex" alignItems="center" minHeight={35} key={index}>
              <Typography fontSize={"14px"} fontWeight={400}>
                : {value}
              </Typography>
            </Box>
          ))}
        </Grid>
      </DetailBox>
    </Grid>
    <Grid item xs={12}>
      <DetailBox
        title="Company"
        rightButton={{
          title: "Update",
          icon: <Update sx={{ mr: 1 }} />,
          onClick: () => props.setOpenCustomerUpdateDrawer(true)
        }}
      >
        <Grid item display="flex" flexDirection="column" gap={1}>
          {[
            "Website"
          ].map((label, index) => (
            <Box display="flex" alignItems="center" minHeight={35} key={index}>
              <Typography fontSize={"14px"} fontWeight={600}>
                {label}
              </Typography>
            </Box>
          ))}
        </Grid>

        <Grid item display="flex" flexDirection="column" gap={1}>
          {[
            props.customerData?.website
          ].map((value, index) => (
            <Box display="flex" alignItems="center" minHeight={35} key={index}>
              <Typography fontSize={"14px"} fontWeight={400}>
                : {value}
              </Typography>
            </Box>
          ))}
        </Grid>
      </DetailBox>
    </Grid>
    <Grid item xs={12}>
      <DetailBox
        title="Localization"
        rightButton={{
          title: "Update",
          icon: <Update sx={{ mr: 1 }} />,
          onClick: () => props.setOpenCustomerUpdateDrawer(true)
        }}>
        <Grid item display="flex" flexDirection="column" gap={1}>
          {[
            "Country",
            "State",
            "City",
            "Language"
          ].map((label, index) => (
            <Box display="flex" alignItems="center" minHeight={35} key={index}>
              <Typography fontSize={"14px"} fontWeight={600}>
                {label}
              </Typography>
            </Box>
          ))}
        </Grid>

        <Grid item display="flex" flexDirection="column" gap={1}>
          {[
            props.customerData?.country,
            props.customerData?.state,
            props.customerData?.city,
            localizedLanguages[props.customerData?.language as keyof typeof localizedLanguages]

          ].map((value, index) => (
            <Box display="flex" alignItems="center" minHeight={35} key={index}>
              <Typography fontSize={"14px"} fontWeight={400}>
                : {value}
              </Typography>
            </Box>
          ))}
        </Grid>
      </DetailBox>
    </Grid>
  </Grid > : null
})


const EstimatesSection = memo((props: {
  estimateListData: IEstimateListResult | undefined,
  selectedTab: string,
  customerEstimateListData: ICustomerEstimateListResult | undefined
  setOpenCreateEstimate: (value: boolean) => void
}) => {


  return props.selectedTab === "Estimates" ? <Grid container mt={2} gap={2} mb={2}>
    <Grid item xs={12}>
      <DetailBox
        title="Estimates"
        rightButton={{
          title: "Add Estimate",
          icon: <Icons.AddCircle sx={{ mr: 1 }} />,
          onClick: () => props.setOpenCreateEstimate(true)
        }}
      >
        <Grid item display="flex" flexDirection="column" gap={1}>
          <ul>
            {props.estimateListData?.data?.map((estimate, index) => (
              <li key={index}>
                <Typography fontSize={"14px"} fontWeight={600}>
                  {estimate?.prefix} | {estimate?.totalPrice?.total} | {moment(estimate?.createdAt).format("DD/MM/YYYY")}
                </Typography>
              </li>
            ))}
          </ul>
        </Grid>
        <CustomerListView
          customerData={props.customerEstimateListData}
          customerStatusData={''}
          setSelectedRow={() => {}}
          customerStatusesLoading={undefined}
          customerSourcesLoading={undefined}
          setOpenLeadCreateDrawer={undefined}
          selectedDetail={undefined}
          customerSourceCreateOpen={undefined}
          customerStatusCreateOpen={undefined}
          setDetailSourceCreateOpen={undefined}
          setDetailStatusCreateOpen={undefined}
          setOpenUpdate={undefined}
          setOpenDelete={undefined}
          setOpenDetail={undefined}
        />
      </DetailBox>
    </Grid>
  </Grid > : null
})

const ProposalsSection = memo((props: {
  proposalListData: IProposalListResult | undefined,
  selectedTab: string,
  setOpenCreateProposal: (value: boolean) => void
}) => {

  return props.selectedTab === "Proposals" ? <Grid container mt={2} gap={2} mb={2}>
    <Grid item xs={12}>
      <DetailBox
        title="Proposals"
        rightButton={{
          title: "Add Proposal",
          icon: <Icons.AddCircle sx={{ mr: 1 }} />,
          onClick: () => props.setOpenCreateProposal(true)
        }}
      >
        <Grid item display="flex" flexDirection="column" gap={1}>
          <ul>
            {props.proposalListData?.data?.map((proposal, index) => (
              <li key={index}>
                <Typography fontSize={"14px"} fontWeight={600}>
                  {proposal?.prefix} | {proposal?.totalPrice?.total} | {moment(proposal?.createdAt).format("DD/MM/YYYY")}
                </Typography>
              </li>
            ))}
          </ul>
        </Grid>
      </DetailBox>
    </Grid>
  </Grid > : null
})

const TasksSection = (props: {
  selectedTab: string,
}) => {
  return props.selectedTab === "Tasks" ? <Grid container mt={2} gap={2} mb={2}>
    <Grid item xs={12}>
      <DetailBox title="Tasks">
        <Grid item display="flex" flexDirection="column" gap={1}>
          <Box height={40} />
        </Grid>
      </DetailBox>
    </Grid>
  </Grid > : null
}

const DocumentsSection = memo((props: {
  selectedTab: string,
}) => {
  return props.selectedTab === "Documents" ? <Grid container mt={2} gap={2} mb={2}>
    <Grid item xs={12}>
      <DetailBox title="Documents">
        <Grid item display="flex" flexDirection="column" gap={1}>
          <Box height={40} />
        </Grid>
      </DetailBox>
    </Grid>
  </Grid > : null
})

const NotesSection = memo((props: {
  id: string,
  selectedTab: string,
}) => {

  const { t } = useTranslation();
  const apiRef = useGridApiRef();
  const currentLanguage = getCurrentLanguage();
  const [selectedNote, setSelectedNote] = useState<any>();

  const [noteCreate, { isLoading: noteCreateLoading }] = useNoteCreateMutation();
  const [noteUpdate, { isLoading: noteUpdateLoading }] = useNoteUpdateMutation();
  const [noteDelete, { isLoading: noteDeleteLoading }] = useNoteDeleteMutation();
  const { data: noteListData, isLoading: noteListLoading, } = useNoteListQuery({
    input: {
      filter: {
        targetIds: [props.id || '']
      }
    }
  })

  const [rows, setRows] = useState<any[]>(noteListData?.data?.map((note, index) => ({
    id: index + 1,
    _id: note._id,
    note: note.content?.replace(/<\/?[^>]+(>|$)/g, "")?.substring(0, 25) + "..." || '-',
  })) || []);

  const columns: GridColDef[] = [
    { field: 'id', headerName: '#', width: 50, disableColumnMenu: true, filterable: false, align: 'center', headerAlign: 'center' },
    { field: 'note', headerName: 'Note', width: 400, editable: false },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      width: 90,
      filterable: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: any) => {
        return (
          <Stack direction={{ xs: "column", md: "row" }} alignItems={"center"} justifyContent={"center"} mt={{ xs: 0, md: 2 }}>
            <IconButton
              onClick={() => setSelectedNote(params.row)}
              color="info"
              sx={{ p: "4px", }}
            >
              <Icons.RemoveRedEyeOutlined color="info" fontSize="small" />
            </IconButton>
            <IconButton
              onClick={() => handleNoteDelete(params.row._id)}
              color="error"
              sx={{ p: "4px", }}
            >
              <Icons.DeleteOutlineRounded color="error" fontSize="small" />
            </IconButton>
          </Stack>
        );
      },
    },
  ];

  function handleProcessRowUpdate(newRow: any, oldRow: any) {
    if (newRow.name !== oldRow.name) {
      console.log({ filter: { _id: newRow._id }, input: { name: newRow.name } });
    }
    if (newRow.icon !== oldRow.icon) {
      console.log({ filter: { _id: newRow._id }, input: { icon: newRow.icon } });
    }
    if (newRow.color !== oldRow.color) {
      console.log({ filter: { _id: newRow._id }, input: { color: newRow.color } });
    }
    return newRow;
  };


  async function handleNoteDelete(id: string) {
    await noteDelete({ input: { _id: id } }).then(() => {
      enqueueSnackbar(t("Note deleted successfully!"), { variant: 'success' });
    }).catch(() => {
      enqueueSnackbar(t("Note delete failed!"), { variant: 'error' });
    })
  }

  const { values, handleSubmit, handleBlur, setFieldValue, errors, resetForm } = useFormik({
    initialValues: {
      input: {
        title: selectedNote?.title || "",
        content: selectedNote?.content || "",
        targetId: props.id || "",
        targetType: "customer",
      }
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      selectedNote ?
        noteUpdate({
          ...values,
          filter: {
            _id: selectedNote?._id
          },
          input: {
            ...values.input,
            title: new DOMParser().parseFromString(values.input.content, 'text/html').body.textContent?.substring(0, 20) + "...",
            targetType: values.input.targetType as NoteTargetType,
          }
        }).then(() => {
          enqueueSnackbar(t("Note updated successfully!"), { variant: 'success' });
          resetForm();
          setSelectedNote(null);
        }).catch(() => {
          enqueueSnackbar(t("Note update failed!"), { variant: 'error' });
        })
        :
        noteCreate({
          ...values,
          input: {
            ...values.input,
            title: new DOMParser().parseFromString(values.input.content, 'text/html').body.textContent?.substring(0, 20) + "...",
            targetType: values.input.targetType as NoteTargetType,
          }
        }).then(() => {
          enqueueSnackbar(t("Note created successfully!"), { variant: 'success' });
          resetForm();
        }).catch(() => {
          enqueueSnackbar(t("Note creation failed!"), { variant: 'error' });
        })

    }
  })

  useEffect(() => {
    if (noteListData) {
      setRows(noteListData?.data?.map((note, index) => ({
        id: index + 1,
        _id: note._id,
        note: note.content?.replace(/<\/?[^>]+(>|$)/g, "")?.substring(0, 25) + "..." || '-',
      })))
    }
  }, [noteListData]);


  return props.selectedTab === "Notes" ? <Grid container mt={2} gap={2} mb={2}>
    <Grid item xs={12}>
      <DetailBox title="Notes">
        <Grid item xs={12} display="flex" flexDirection="column" gap={1}>
          <Box sx={{ overflow: "auto" }}>

            <Stack direction={"column"} sx={{ px: 2, py: 1, mt: 1, mb: 2, position: "relative" }}>
              <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} gap={1}>
                <Typography sx={{ fontWeight: 600, fontSize: "16px", color: "text.primary" }}>
                  {selectedNote ? "Update Note" : "Add New Note"}
                </Typography>
                {selectedNote ?
                  <Stack direction={"row"} gap={1}>
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ height: 40, fontSize: "13px" }}
                      onClick={() => handleSubmit()}
                      disabled={noteUpdateLoading}
                    >
                      <Icons.Update sx={{ mr: 1, fontSize: "16px" }} />
                      Update
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      sx={{ height: 40, fontSize: "13px" }}
                      onClick={() => {
                        setSelectedNote(null)
                        resetForm()
                      }}
                      disabled={noteUpdateLoading}
                    >
                      <Icons.Cancel sx={{ mr: 1, fontSize: "16px" }} />
                      Cancel
                    </Button>
                  </Stack>
                  : <Button
                    variant="contained"
                    color="primary"
                    sx={{ height: 40, fontSize: "13px" }}
                    onClick={() => handleSubmit()}
                    disabled={noteCreateLoading}
                  >
                    <Icons.AddCircle sx={{ mr: 1, fontSize: "16px" }} />
                    Add
                  </Button>}
              </Stack>
              <Box sx={{ bgcolor: 'background.secondary', borderRadius: 2, height: "160px", mt: 1, border: "2px solid", borderColor: "grey.400" }}>
                <ReactQuill
                  theme="snow"
                  id='editor-customer-detail'
                  value={(values as any).input.content}
                  onChange={(content, delta, source, editor) => {
                    setFieldValue("input.content", editor.getHTML())
                  }}
                  onBlur={() => handleBlur("input.content")}
                  placeholder={"Some text here..."}
                  style={{ height: 130, boxSizing: "border-box" }}
                />
              </Box>
              {(errors as any).input?.content && (
                <Typography color="error" variant="caption">
                  {(errors as any).input?.content}
                </Typography>
              )}

              <Typography sx={{ fontWeight: 600, fontSize: "16px", mt: 2, mb: 1, color: "text.primary" }}>
                Note List
              </Typography>

              <DataGridPro
                localeText={currentLanguage === Language.tr ? trTR.components.MuiDataGrid.defaultProps.localeText : undefined}
                apiRef={apiRef || undefined}
                rows={rows}
                columns={columns}
                disableRowSelectionOnClick
                showColumnVerticalBorder
                //disableAggregation
                // disableRowGrouping
                hideFooter
                processRowUpdate={handleProcessRowUpdate}
                loading={noteCreateLoading || noteUpdateLoading || noteDeleteLoading || noteListLoading}
                onCellDoubleClick={(params, event) => {
                  if (params.field === 'icon') {
                    event.stopPropagation();
                  }
                  if (params.field === 'color') {
                    event.stopPropagation();
                  }
                }}
                scrollbarSize={1}
                scrollEndThreshold={10}
                rowsLoadingMode="server"
                sx={[tableStyle, {
                  backgroundColor: 'background.secondary', minHeight: "280px", maxHeight: "280px",
                  '&. .MuiDataGrid-topContainer': {
                    border: "2px solid #000"
                  },
                  '& .MuiDataGrid-row--borderBottom': {
                    background: "none !important",

                  },
                }]}
              />
            </Stack>
          </Box>
        </Grid>
      </DetailBox>
    </Grid>
  </Grid > : null
})

const ActivitiesSection = memo((props: {
  selectedTab: string
}) => {
  return props.selectedTab === "Activities" ? <Grid container mt={2} gap={2} mb={2}>
    <Grid item xs={12}>
      <DetailBox title="Activities">
        <Grid item display="flex" flexDirection="column" gap={1}>
          <Box height={40} />
        </Grid>
      </DetailBox>
    </Grid>
  </Grid > : null
})

const BillingInfoSection = memo((props: {
  customerData: ICustomerDetailResult | undefined;
  selectedTab: string,
}) => {
  return props.selectedTab === "Billing Info" ? <Grid container mt={2} gap={2} mb={2}>
    <Grid item xs={12}>
      <DetailBox title="Billing Information">
      <Grid item display="flex" flexDirection="column" gap={1}>
          {[
            "Name",
            "Phone",
            "Address",
            "City",
            "Country",
            "Vat",
            "Zip"
          ].map((label, index) => (
            <Box display="flex" alignItems="center" minHeight={35} key={index}>
              <Typography fontSize={"14px"} fontWeight={600}>
                {label}
              </Typography>
            </Box>
          ))}
        </Grid>
        <Grid item display="flex" flexDirection="column" gap={1}>
          {[
            props.customerData?.primaryBillingInfo?.fullName,
            props.customerData?.primaryBillingInfo?.phone,
            props.customerData?.primaryBillingInfo?.address,
            props.customerData?.primaryBillingInfo?.city,
            props.customerData?.primaryBillingInfo?.country,
            props.customerData?.primaryBillingInfo?.vat,
            props.customerData?.primaryBillingInfo?.zip,
          ].map((value, index) => (
            <Box display="flex" alignItems="center" minHeight={35} key={index}>
              <Typography fontSize={"14px"} fontWeight={400}>
                : {value}
              </Typography>
            </Box>
          ))}
        </Grid>
      </DetailBox>
    </Grid>
    <Grid item xs={12}>
      <DetailBox title="Other Billing Information">
      <Grid item display="flex" flexDirection="column" gap={1}>
          {[
            "Name",
            "Phone",
            "Address",
            "City",
            "Country",
            "Vat",
            "Zip"
          ].map((label, index) => (
            <Box display="flex" alignItems="center" minHeight={35} key={index}>
              <Typography fontSize={"14px"} fontWeight={600}>
                {label}
              </Typography>
            </Box>
          ))}
        </Grid>
      </DetailBox>
    </Grid>
  </Grid > : null
})

const Overlays = memo(({
  openCustomerUpdateDrawer,
  setOpenCustomerUpdateDrawer,
  customerData,
  openCreateEstimate,
  setOpenCreateEstimate,
  openCreateProposal,
  setOpenCreateProposal
}: any) => {

  const [customerStatusCreateOpen, setCustomerStatusCreateOpen] = useState(false);
  const [customerSourceCreateOpen, setCustomerSourceCreateOpen] = useState(false);

  return <>
    <CustomerUpdateWrapper
      open={openCustomerUpdateDrawer}
      setOpen={setOpenCustomerUpdateDrawer}
      selectedCustomer={customerData}
      customerStatusCreateOpen={customerStatusCreateOpen}
      setCustomerStatusCreateOpen={setCustomerStatusCreateOpen}
      customerSourceCreateOpen={customerSourceCreateOpen}
      setCustomerSourceCreateOpen={setCustomerSourceCreateOpen}
    />

    <EstimateCreateWraper
      open={openCreateEstimate}
      setOpen={setOpenCreateEstimate}
    />

    <ProposalCreateWraper
      open={openCreateProposal}
      setOpen={setOpenCreateProposal}
    />
  </>
});