import { createSlice } from '@reduxjs/toolkit';

const { actions, reducer } = createSlice({
    name: 'theme',
    initialState: {
        themeMode: "light"
    },
    reducers: {
        setThemeMode: (state, action) => {
            state.themeMode = action.payload;
            localStorage.setItem('theme', action.payload);
        },
    },
});


export const { setThemeMode } = actions;
export const themeSlice = reducer;