

import { dashboardDomainRoutes } from "../domains/01-dashboard/routes/dashboard.base.route";
import { DashboardDomainRouter } from "../domains/01-dashboard/routes/DashboardDomainRouter";

import { organizationalChartDomainRoutes } from "../domains/02-organizationalChart/routes/organizationalChart.base.route";
import { OrganizationalChartDomainRouter } from "../domains/02-organizationalChart/routes/OrganizationalChartDomainRouter";

import { leadDomainRoutes } from "../domains/03-lead/routes/lead.base.route";
import { LeadDomainRouter } from "../domains/03-lead/routes/LeadDomainRouter";
import { customerDomainRoutes } from "../domains/04-customer/routes/customer.base.route";
import { CustomerDomainRouter } from "../domains/04-customer/routes/CustomerDomainRouter";
import { preSalesDomainRoutes } from "../domains/05-preSales/routes/preSales.base.route";
import { PreSalesDomainRouter } from "../domains/05-preSales/routes/PreSalesDomainRouter";
import { salesDomainRoutes } from "../domains/06-sales/routes/sales.base.route";
import { SalesDomainRouter } from "../domains/06-sales/routes/SalesDomainRouter";
import { productsAndServicesDomainRoutes } from "../domains/08-productsAndServices/routes/productsAndServices.base.route";
import { ProductsAndServicesDomainRouter } from "../domains/08-productsAndServices/routes/ProductsAndServicesDomainRouter";
import { expensesDomainRoutes } from "../domains/09-expenses/routes/expenses.base.route";
import { ExpensesDomainRouter } from "../domains/09-expenses/routes/ExpensesDomainRouter";
import { projectsDomainRoutes } from "../domains/10-projects/routes/projects.base.route";
import { ProjectsDomainRouter } from "../domains/10-projects/routes/ProjectsDomainRouter";
import { tasksDomainRoutes } from "../domains/11-tasks/routes/tasks.base.route";
import { TasksDomainRouter } from "../domains/11-tasks/routes/TasksDomainRouter";
import { calendarDomainRoutes } from "../domains/12-calendar/routes/calendar.base.route";
import { CalendarDomainRouter } from "../domains/12-calendar/routes/CalendarDomainRouter";
import { reportsDomainRoutes } from "../domains/13-reports/routes/reports.base.route";
import { ReportsDomainRouter } from "../domains/13-reports/routes/ReportsDomainRouter";
import { supportDomainRoutes } from "../domains/15-support/routes/support.base.route";
import { SupportDomainRouter } from "../domains/15-support/routes/SupportDomainRouter";
import { ContractsDomainRouter } from "../domains/07-contracts/routes/ContractsDomainRouter";
import { contractsDomainRoutes } from "../domains/07-contracts/routes/contracts.base.route";


import CrmLayout from "../layouts/CrmLayout";
import { Route } from "react-router-dom";
import { pricingDomainRoutes } from "../domains/16-pricing/routes/pricing.base.route";
import { PricingDomainRouter } from "../domains/16-pricing/routes/PricingDomainRouter";
import { notesDomainRoutes } from "../domains/14-notes/routes/notes.base.route";
import { NotesDomainRouter } from "../domains/14-notes/routes/NotesDomainRouter";

export const CrmRouter = () => {
  return (
    <Route path="/" element={<CrmLayout />}>
      <Route index element={<DashboardDomainRouter />} />
      <Route
        path={`${dashboardDomainRoutes.base}/*`}
        element={<DashboardDomainRouter />}
      />
      <Route
        path={`${organizationalChartDomainRoutes.base}/*`}
        element={<OrganizationalChartDomainRouter />}
      />
      <Route
        path={`${leadDomainRoutes.base}/*`}
        element={<LeadDomainRouter />}
      />
      <Route
        path={`${customerDomainRoutes.base}/*`}
        element={<CustomerDomainRouter />}
      />
      <Route
        path={`${preSalesDomainRoutes.base}/*`}
        element={<PreSalesDomainRouter />}
      />
      <Route
        path={`${salesDomainRoutes.base}/*`}
        element={<SalesDomainRouter />}
      />
      <Route
        path={`${contractsDomainRoutes.base}/*`}
        element={<ContractsDomainRouter />}
      />
      <Route
        path={`${productsAndServicesDomainRoutes.base}/*`}
        element={<ProductsAndServicesDomainRouter />}
      />
      <Route
        path={`${expensesDomainRoutes.base}/*`}
        element={<ExpensesDomainRouter />}
      />
      <Route
        path={`${projectsDomainRoutes.base}/*`}
        element={<ProjectsDomainRouter />}
      />
      <Route
        path={`${tasksDomainRoutes.base}/*`}
        element={<TasksDomainRouter />}
      />
      <Route
        path={`${calendarDomainRoutes.base}/*`}
        element={<CalendarDomainRouter />}
      />
      <Route
        path={`${reportsDomainRoutes.base}/*`}
        element={<ReportsDomainRouter />}
      />
      <Route
        path={`${notesDomainRoutes.base}/*`}
        element={<NotesDomainRouter />}
      />
      <Route
        path={`${supportDomainRoutes.base}/*`}
        element={<SupportDomainRouter />}
      />
      <Route
        path={`${pricingDomainRoutes.base}/*`}
        element={<PricingDomainRouter />}
      />
      {/* Todo For Generation */}
      {/* <Route
        path={`${userDomainRoutes.base}/*`}
        element={<UsersDomainRouter />}
      /> */}

      <Route path="*" element={<DashboardDomainRouter />} />
    </Route>
  );
};
