/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, IconButton, Modal, Stack, TextField, Typography, } from "@mui/material";
import { useAppDispatch, } from "context";
import { preSalesActions } from "../../../context";
import { useEffect, useState } from "react";
import { useProposalListQuery, useProposalUpdateMutation, } from "../context/proposals.api";
import { DeleteOutlineRounded } from "@mui/icons-material";
import { DataGridPro, GridColDef, GridRowModesModel, useGridApiRef } from "@mui/x-data-grid-pro";
import { enqueueSnackbar } from "notistack";
import * as Icons from '@mui/icons-material';
// import { ProposalCreateDrawer } from "./ProposalCreateDrawer";
import { useTranslation } from "react-i18next";
import { IBaseProposalEntity } from "corede-common-cocrm";
import { Language } from "corede-common";
import { trTR } from '@mui/x-data-grid/locales';
import { getCurrentLanguage } from "localization";
import { HeaderBase } from "components/contentHeaders/crm/HeaderBase";
import { Add, } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import Delete from "./Delete";
import { listLegalAgreementsRoute } from "../../../routes/preSales.base.route";
import ProposalCreateWraper from "./ProposalCreateWrapper";

const List = () => {

  // general
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentLanguage = getCurrentLanguage();
  const dispatch = useAppDispatch();
  const apiRef = useGridApiRef();
  const { data: proposalListData, isLoading: proposalsLoading, isFetching: proposalsFetching } = useProposalListQuery({
    input: {
      pagination: {
        sort: {
          createdAt: 1
        }
      }
    }
  });

  const [proposalUpdate, { isLoading: proposalUpdateLoading, error: proposalUpdateError }] = useProposalUpdateMutation();
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});


  const [addProposalAvailable, setAddProposalAvailable] = useState(true);
  const [openSelectIconset, setOpenSelectIconset] = useState(false);
  const [openColorModal, setOpenColorModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>();
  const [searchQuery, setSearchQuery] = useState('');
  const [openProposalCreateDrawer, setOpenProposalCreateDrawer] = useState(false);
  const [selectedItemForDelete, setSelectedItemForDelete] = useState<any>();

  const [rows, setRows] = useState<any[]>(proposalListData?.data?.map((proposal, index) => ({
    id: index + 1,
    _id: proposal._id,
    prefix: proposal.prefix,
    proposalId: proposal.proposalId,
    timeZone: proposal.timeZone,
    prospectTargetEntity: proposal.prospectTargetEntity,
    prospectTargetEntityRef: proposal.prospectTargetEntityRef,
    prospectType: proposal.prospectType,
    prospectName: proposal.prospectName,
    prospectVAT: proposal.prospectVAT,
    prospectPhoneNumber: proposal.prospectPhoneNumber,
    prospectCountry: proposal.prospectCountry,
    prospectState: proposal.prospectState,
    prospectCity: proposal.prospectCity,
    prospectZIP: proposal.prospectZIP,
    prospectAddress: proposal.prospectAddress,
    prospectContactFirstName: proposal.prospectContactFirstName,
    prospectContactLastName: proposal.prospectContactLastName,
    prospectContactEmail: proposal.prospectContactEmail,
    language: proposal.language,
    sendEmailOnExpiration: proposal.sendEmailOnExpiration,
    expirationEmailSentDate: proposal.expirationEmailSentDate,
    scopeOfWork: proposal.scopeOfWork,
    assumptionsAndExclusions: proposal.assumptionsAndExclusions,
    paymentTerms: proposal.paymentTerms,
    currency: proposal.currency,
    products: proposal.products,
    totalPrice: proposal.totalPrice,
    offers: proposal.offers,
    reference: proposal.reference,
    salesAgents: proposal.salesAgents,
    allowComments: proposal.allowComments,
    useLegalNDA: proposal.useLegalNDA,
    legalNDA: proposal.legalNDA,
    useLegalTermAndCond: proposal.useLegalTermAndCond,
    legalTermAndCond: proposal.legalTermAndCond,
    signerUser: proposal.signerUser,
    signatureImage: proposal.signatureImage,
    status: proposal.status,
    statusHistory: proposal.statusHistory,

  })) ?? []);

  const columns: GridColDef[] = [
    { field: 'id', headerName: '#', width: 50, disableColumnMenu: true, filterable: false, align: 'center', headerAlign: 'center' },
    {
      field: 'name', headerName: 'Name', width: 160, editable: true,
    },
    {
      field: 'sku', headerName: 'Sku', width: 160, editable: true,
    },
    {
      field: 'type', headerName: 'Type', width: 160, editable: true,
    },
    {
      field: 'description', headerName: 'Description', width: 160, editable: true,
    },
    {
      field: 'currency', headerName: 'Currency', width: 160, editable: true,
    },
    {
      field: 'price', headerName: 'Price', width: 160, editable: true,
    },
    {
      field: 'priceType', headerName: 'PriceType', width: 160, editable: true,
    },
    {
      field: 'discountedPrice', headerName: 'DiscountedPrice', width: 160, editable: true,
    },
    {
      field: 'unit', headerName: 'Unit', width: 160, editable: true,
    },
    {
      field: 'unitPrice', headerName: 'UnitPrice', width: 160, editable: true,
    },
    {
      field: 'category', headerName: 'Category', width: 160, editable: true,
      renderCell: (params) => {
        return (
          <TextField
            size="small"
            value={params.value?.name}
          />
        )
      }
    },
    {
      field: 'natTaxRate', headerName: 'National Tax Rate', width: 160, editable: true,
    },
    {
      field: 'intTaxRate', headerName: 'International Tax Rate', width: 160, editable: true,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      width: 120,
      filterable: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: any) => {
        return (
          <Stack direction={{ xs: "column", md: "row" }} alignItems={"center"} justifyContent={"center"} mt={{ xs: 0, md: 2 }}>
            <IconButton
              onClick={() => handleDeleteRow(params?.row)}
              color="error"
              sx={{ p: "4px", }}
            >
              <DeleteOutlineRounded color="error" fontSize="small" />
            </IconButton>
          </Stack>
        );
      },
    },
  ];


  const handleIconSelect = (iconName: string) => {
    const updatedRow = {
      ...selectedRow,
      icon: iconName,
    };

    apiRef.current.updateRows([updatedRow]);
    handleProcessRowUpdate(updatedRow, selectedRow);
    setOpenSelectIconset(false);
  };


  const handleDeleteRow = (item: IBaseProposalEntity) => {
    setSelectedItemForDelete(item);
  }

  const filteredIcons = Object.keys(Icons).filter((iconName) =>
    iconName.endsWith('Outlined') && iconName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  function handleProcessRowUpdate(newRow: any, oldRow: any) {
    // TODO
    // if (newRow.name !== oldRow.name && addProposalAvailable) {
    //   proposalUpdate({ filter: { _id: newRow._id }, input: { name: newRow.name } });
    // }
  };

  useEffect(() => {
    if (proposalListData && proposalListData.data) {
      setRows(proposalListData.data.map((proposal, index) => ({
        id: index + 1,
        _id: proposal._id,
        prefix: proposal.prefix,
        proposalId: proposal.proposalId,
        timeZone: proposal.timeZone,
        prospectTargetEntity: proposal.prospectTargetEntity,
        prospectTargetEntityRef: proposal.prospectTargetEntityRef,
        prospectType: proposal.prospectType,
        prospectName: proposal.prospectName,
        prospectVAT: proposal.prospectVAT,
        prospectPhoneNumber: proposal.prospectPhoneNumber,
        prospectCountry: proposal.prospectCountry,
        prospectState: proposal.prospectState,
        prospectCity: proposal.prospectCity,
        prospectZIP: proposal.prospectZIP,
        prospectAddress: proposal.prospectAddress,
        prospectContactFirstName: proposal.prospectContactFirstName,
        prospectContactLastName: proposal.prospectContactLastName,
        prospectContactEmail: proposal.prospectContactEmail,
        language: proposal.language,
        sendEmailOnExpiration: proposal.sendEmailOnExpiration,
        expirationEmailSentDate: proposal.expirationEmailSentDate,
        scopeOfWork: proposal.scopeOfWork,
        assumptionsAndExclusions: proposal.assumptionsAndExclusions,
        paymentTerms: proposal.paymentTerms,
        currency: proposal.currency,
        products: proposal.products,
        totalPrice: proposal.totalPrice,
        offers: proposal.offers,
        reference: proposal.reference,
        salesAgents: proposal.salesAgents,
        allowComments: proposal.allowComments,
        useLegalNDA: proposal.useLegalNDA,
        legalNDA: proposal.legalNDA,
        useLegalTermAndCond: proposal.useLegalTermAndCond,
        legalTermAndCond: proposal.legalTermAndCond,
        signerUser: proposal.signerUser,
        signatureImage: proposal.signatureImage,
        status: proposal.status,
        statusHistory: proposal.statusHistory,
      })));
    }
  }, [proposalListData]);


  useEffect(() => {
    if (proposalUpdateError) {
      enqueueSnackbar('Lead Proposal Update Failed', { variant: 'error' });
    }
  }, [proposalUpdateError])



  //initial useEffects

  useEffect(() => {
    dispatch(preSalesActions.setTitle(t("crm.preSales.proposals.statuses")));
    dispatch(preSalesActions.setBackButton(false));
    dispatch(preSalesActions.setRightButton(null));
  }, [dispatch]);



  return (
    <Grid item xs={12} height={"calc(100vh - 260px)"}>
      <HeaderBase
        title={t(`crm.preSales.proposals.title`)}
        rightButton={{
          icon: <Add sx={{ mr: 1, opacity: 0.7 }} fontSize="small" />,
          title: t(`crm.preSales.proposals.create`),
          onClick: () => setOpenProposalCreateDrawer(true),
        }}
      />
      <Box minHeight={"350px"} maxHeight={"calc(100vh - 200px)"} mt={1} overflow={"auto"} borderRadius={"16px"}>
        <DataGridPro
          localeText={currentLanguage === Language.tr ? trTR.components.MuiDataGrid.defaultProps.localeText : undefined}
          apiRef={apiRef}
          rows={rows}
          columns={columns}
          disableRowSelectionOnClick
          showColumnVerticalBorder
          //disableAggregation
          // disableRowGrouping
          hideFooter
          processRowUpdate={handleProcessRowUpdate}
          loading={proposalsLoading || proposalUpdateLoading || proposalsFetching}
          onCellDoubleClick={(params, event) => {
            if (params.field === 'icon') {
              setOpenSelectIconset(true);
              setSelectedRow(params.row);
              event.stopPropagation();
            }
            if (params.field === 'color') {
              setOpenColorModal(true);
              setSelectedRow(params.row);
              event.stopPropagation();
            }
          }}
          scrollbarSize={1}
          scrollEndThreshold={10}
          pinnedColumns={{ left: ['id'], right: ['actions'] }}
          rowsLoadingMode="server"
          sx={[tableStyle, {
            backgroundColor: 'background.secondary', minHeight: "350px",
            '&. .MuiDataGrid-topContainer': {
              border: "2px solid #000"
            },
          }]}
        />
      </Box>


      <Modal
        open={openSelectIconset}
        onClose={() => setOpenSelectIconset(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-title" variant="h6" component="h2">
          {t("crm.customer.customers.selectIconTitle")}
          </Typography>
          <TextField
            label="Search Icons"
            variant="outlined"
            fullWidth
            margin="normal"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <Box sx={{ maxHeight: 400, overflow: 'auto', mt: 2 }}>
            <Grid container spacing={2}>
              {filteredIcons.map((iconName) => {
                const IconComponent = (Icons as any)[iconName];
                return (
                  <Grid item xs={2} key={iconName}>
                    <IconButton onClick={() => handleIconSelect(iconName)}>
                      <IconComponent />
                    </IconButton>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Box>
      </Modal>
      <ProposalCreateWraper
        open={openProposalCreateDrawer}
        setOpen={setOpenProposalCreateDrawer}
      />

      <Delete
        open={!!selectedItemForDelete}
        onClose={() => setSelectedItemForDelete(null)}
        item={selectedItemForDelete}
      />
    </Grid >
  );
};

export default List;



export const tableStyle = {
  fontSize: "12px",
  borderRadius: "16px",

  '& .MuiDataGrid-toolbarContainer > button': {
    fontSize: "12px",
  },
  '& .MuiDataGrid-columnHeader .MuiIconButton-root': {
    fontSize: "12px",
    p: "2px",
    border: "none !important",
  },
  '& .MuiDataGrid-columnHeader .MuiSvgIcon-root': {
    width: "16px",
    height: "16px",
  },
  '& .MuiDataGrid-footerContainer': {
    height: '20px !important',
    maxHeight: '20px !important',
    m: 0,
  }
};

export const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


export const colors = [
  "primary.lighter",
  "secondary.lighter",
  "success.lighter",
  "error.lighter",
  "warning.lighter",
  "info.lighter",
  "primary.light",
  "secondary.light",
  "success.light",
  "error.light",
  "warning.light",
  "info.light",
  "primary.main",
  "secondary.main",
  "success.main",
  "error.main",
  "warning.main",
  "info.main",
  "primary.dark",
  "secondary.dark",
  "success.dark",
  "error.dark",
  "warning.dark",
  "info.dark",
  "primary.darker",
  "secondary.darker",
  "success.darker",
  "error.darker",
  "warning.darker",
  "info.darker",
]