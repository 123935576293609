import { Route, Routes } from "react-router-dom";

import {  List,} from "../pages";

import { PermissionsLayout } from "../layouts/PermissionsLayout";

export const PermissionsRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<PermissionsLayout />}>
        <Route index element={<List />} />
       </Route>
    </Routes>
  );
};
