import { Route, Routes } from "react-router-dom";

import { List} from "../pages";

import { PricingLayout } from "../layouts/PricingLayout";

export const PricingRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<PricingLayout />}>
        <Route index element={<List />} />
       </Route>
    </Routes>
  );
};
