

export const reportsDomainRoutes = {
  base: "reports",
  subdomains: {
    reports: {
      base: "reports",
    },
  },
};


export const baseReportsRoute = (): string => `/${reportsDomainRoutes.base}/`;
export const listReportsRoute = (): string =>
  `/${reportsDomainRoutes.base}/${reportsDomainRoutes.subdomains.reports.base}`;

