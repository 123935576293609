/* eslint-disable react-hooks/exhaustive-deps */
import { Add } from "@mui/icons-material";
import { Box, Grid, useMediaQuery, } from "@mui/material";
import { HeaderBase } from "components/contentHeaders/crm/HeaderBase";
import { useEffect, useState } from "react";
import { getCurrentLanguage } from "localization";
import { IGraphqlVariables, unknownError } from "corede-common";
import { useProductCategoryListQuery, useProductCategoryUpdateMutation } from "../context/productCategories.api";
import { IProductCategoryListInput } from "corede-common-cocrm";
import EmptyState from "components/emptyState/EmptyState";
import { RolesDrawer } from "../../../../02-organizationalChart/subdomains/permissions";
import { DeleteDialog } from "./DeleteDialog";
import { enqueueSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import ProductCategoryUpdateWraper from "./ProductCategoryUpdateWraper";
import ProductCategoryCreateWraper from "./ProductCategoryCreateWraper";
// import { MyDataGrid } from "components/dataGrid/MyDataGrid";
import moment from "moment";

const List = () => {
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();
  const [openProductCategoryCreateDrawer, setOpenProductCategoryCreateDrawer] = useState(false);
  const [openCategoryDeleteDialog, setOpenCategoryDeleteDialog] = useState<boolean>(false);
  const [openProductCategoryUpdateDrawer, setOpenProductCategoryUpdateDrawer] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>();
  const [openRolesDrawer, setOpenRolesDrawer] = useState(false);


  //general
  const downMd = useMediaQuery((theme: any) => theme.breakpoints.down('md'));
  const [productCategoryListFilter, setProductCategoryListFilter] = useState<IGraphqlVariables<IProductCategoryListInput>>({
    input: {
      pagination: {
        page: 1,
        pageSize: 10,
        sort: {
          createdAt: 1
        }
      }
    }
  })


  //queries
  const { data: productCategoryListData, isLoading: productCategoryListLoading, isFetching: productCategoryListFetching, error: productCategoryListError } = useProductCategoryListQuery(productCategoryListFilter)
  const [productCategoryUpdate, { isLoading: productCategoryUpdateLoading, error: productCategoryUpdateError }] = useProductCategoryUpdateMutation()

  useEffect(() => {
    if (productCategoryListError) {
      enqueueSnackbar((productCategoryListError as any)?.error?.message[currentLanguage] ?? unknownError.message[currentLanguage], { variant: 'error' });
    }
  }, [productCategoryListError]);

  return <Grid item xs={12}>
    <HeaderBase
      title={t("crm.productsAndServices.productCategories.headerTitle")}
      rightButton={{
        title: t("crm.productsAndServices.productCategories.createCategory"),
        onClick: () => {
          setOpenProductCategoryCreateDrawer(true);
        },
        icon: <Add sx={{ mr: 1 }} />,
      }}
    />

    {productCategoryListData?.data?.length !== 0 ? <Box minHeight={"350px"} maxHeight={"calc(100vh - 200px)"} mt={1} overflow={"auto"} borderRadius={"16px"}>
      {/* <MyDataGrid
        path="categories"
        columns={[
          {
            field: "id",
            headerName: "#",
            disableColumnMenu: true,
            filterable: false,
            align: "center",
            width: 40,
            headerAlign: "center"
          },
          {
            field: "name",
            headerName: t("crm.productsAndServices.productCategories.name"),
            width: 140,
            align: "left",
            editable: true,
            filterable: true
          },
          {
            field: "icon",
            headerName: t("crm.productsAndServices.productCategories.icon"),
            width: 100,
            align: "left",
            editable: true
          },
          {
            field: "actions",
            headerName: t("crm.productsAndServices.productCategories.actions"),
            sortable: false,
            filterable: false,
            align: "center",
            editable: false,
            headerAlign: "center",
            width: downMd ? 50 : 120,
            changed: ["delete"]
          },
        ]}
        rows={productCategoryListData?.data?.map((category, index) => ({
          _id: category._id,
          id: (index + 1) * (productCategoryListFilter?.input?.pagination?.page || 0),
          name: category.name,
          icon: category.icon
        })) || []}
        columnVisibilityModel={{}}
        props={{
          setSelectedRow: setSelectedRow,
          setOpenDelete: setOpenCategoryDeleteDialog,
          setOpenUpdate: setOpenProductCategoryUpdateDrawer
        }}
        loading={productCategoryListLoading || productCategoryListFetching}
        count={productCategoryListData?.count || 0}
        filter={productCategoryListFilter}
        setFilter={setProductCategoryListFilter}
        rowUpdate={productCategoryUpdate}
        features={[]}
      /> */}
    </Box> :
      <EmptyState
        content1={t("crm.productsAndServices.productCategories.emptyStateContent1")}
        content2={t("crm.productsAndServices.productCategories.emptyStateContent2")}
        button1={{
          title: t("crm.productsAndServices.productCategories.createProductCategory"),
          onClick: () => {
            setOpenProductCategoryCreateDrawer(true);
          },
          leftIcon: <Add />
        }}
      />
    }


    <ProductCategoryCreateWraper
      open={openProductCategoryCreateDrawer}
      setOpen={setOpenProductCategoryCreateDrawer}
    />

    <ProductCategoryUpdateWraper
      open={openProductCategoryUpdateDrawer}
      setOpen={setOpenProductCategoryUpdateDrawer}
      selectedRow={selectedRow}
    />

    <RolesDrawer
      open={openRolesDrawer}
      setOpen={setOpenRolesDrawer}
    />
    <DeleteDialog
      open={openCategoryDeleteDialog}
      item={selectedRow}
      onClose={() => setOpenCategoryDeleteDialog(false)}
    />
  </Grid >
};

export default List;
