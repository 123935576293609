
import profileLocale from "../subdomains/profile/locales/tr";

const locale = {

  profile: {
    ...profileLocale,
  },
};

export default locale;

