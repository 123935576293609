import React, { useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import countries from './countries.json'; // JSON dosyasının yolu
import { Box } from '@mui/material';

interface Country {
    id: number;
    name: string;
    iso3: string;
    iso2: string;
}

const Countries: React.FC = () => {
    const [selectedCountry, setSelectedCountry] = useState<Country | null>(null);

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <Autocomplete
                options={countries}
                getOptionLabel={(option: Country) => option.name}
                onChange={(event: any, newValue: Country | null) => {
                    setSelectedCountry(newValue);
                }}
                renderInput={(params) => <TextField {...params} label="Ülke seçin" />}
                style={{ width: 300 }}
            />
        </Box>
    );
};

export default Countries;