import { GridColDef } from "@mui/x-data-grid-pro";
import { IGridColConfigInput } from "../infra/IGridColConfigInput";
import { generateBaseColDef } from "../infra/GridColDefFactory";

export const BaseTextArrayGridColumnDef = (params: {
  config: IGridColConfigInput;
  textValues: string[];
}): GridColDef => {
  const baseColDef = generateBaseColDef({
    config: params.config,
  });

  return baseColDef;
};
