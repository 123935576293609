/* eslint-disable react-hooks/exhaustive-deps */
import { Add } from "@mui/icons-material";
import {
  Box,
  Grid,
  useMediaQuery,
} from "@mui/material";
import { HeaderBase } from "components/contentHeaders/crm/HeaderBase";
import { memo, useEffect, useState } from "react";
import { getCurrentLanguage } from "localization";
import {
  IBaseError,
  IGraphqlVariables,
  isNullOrUndefinedOrEmptyObject,
} from "corede-common";
import {
  useDepartmentListQuery,
  useDepartmentUpdateMutation,
} from "../context/departments.api";
import {
  IDepartment,
  IDepartmentListInput,
} from "corede-common-cocrm";
import EmptyState from "components/emptyState/EmptyState";
import {
  AddRoleDialog,
} from "../../permissions";
import { useNavigate } from "react-router-dom";
import { DeleteDialog } from "./DeleteDialog";
import { useTranslation } from "react-i18next";
import DepartmentCreateWrapper from "./DepartmentCreateWrapper";
import DepartmentUpdateWrapper from "./DepartmentUpdateWrapper";
import { BaseDataGrid } from "components/baseDataGrid/BaseDataGrid";
import {
  representArrayOfObjectAsString,
  representString,
} from "utils/representationHelper";
import {
  BaseGridColType,
  GridCellClickActionType,
} from "components/baseDataGrid/infra/enums";
import { DefaultErrorHandlerUseEffect } from "utils/useEffect.helper";
import { DepartmentCreateDialog } from "./DepartmentCreateDialog";

const List = memo(() => {
  // init

  const { t } = useTranslation();
  const navigate = useNavigate();
  const downMd = useMediaQuery((theme: any) => theme.breakpoints.down("md"));
  const currentLanguage = getCurrentLanguage();

  // states
  const [openDepartmentCreateDrawer, setOpenDepartmentCreateDrawer] =
    useState(false);
  const [openDepartmentUpdateDrawer, setOpenDepartmentUpdateDrawer] =
    useState(false);
  const [openDepartmentDeleteDrawer, setOpenDepartmentDeleteDrawer] =
    useState(false);
  const [openRolesDrawer, setOpenRolesDrawer] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState<
    IDepartment | undefined
  >(undefined);

  const [departmentListFilter, setDepartmentListFilter] = useState<
    IGraphqlVariables<IDepartmentListInput>
  >({
    input: {
      filter: {},
      pagination: { page: 1, pageSize: 10 },
    },
  });

  // queries
  const {
    data: departmentListData,
    isLoading: departmentListLoading,
    isFetching: departmentListFetching,
    error: departmentListError,
  } = useDepartmentListQuery(departmentListFilter);
  
  const [
    departmentUpdate,
    { isLoading: departmentUpdateLoading, error: departmentUpdateError },
  ] = useDepartmentUpdateMutation();

  const isEmptyState =
    departmentListData?.data?.length === 0 &&
    isNullOrUndefinedOrEmptyObject(departmentListFilter.filter);

  // handlers

  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      departmentListError as IBaseError,
      currentLanguage
    );
  }, [departmentListError]);

  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      departmentUpdateError as IBaseError,
      currentLanguage
    );
  }, [departmentUpdateError]);

  return (
    <Grid item xs={12}>
      <HeaderBase
        title={t("crm.organizationalChart.departments.headerTitle")}
        rightButton={{
          title: t("crm.organizationalChart.departments.createDepartment"),
          icon: <Add sx={{ mr: 1 }} />,
          onClick: () => {
            setOpenDepartmentCreateDrawer(true);
          },
          visibility: !isEmptyState,
        }}
      />
      {!isEmptyState ? (
        <Box
          minHeight={"350px"}
          height={"600px"}
          mt={1}
          overflow={"auto"}
          borderRadius={"16px"}
        >
          <BaseDataGrid
            rows={
              departmentListData?.data?.map((department, index) => ({
                id:
                  (index + 1) *
                  (departmentListFilter.input?.pagination?.page || 0),
                _id: department._id,
                name: representString(department.name),
                roles: representArrayOfObjectAsString({
                  dataList: department.roles,
                }),
              })) ?? []
            }
            columns={[
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: "id",
                    disableColumnMenu: true,
                    filterable: false,
                    width: 40,
                  },
                  headerConfig: {
                    name: "#",
                  },
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: "name",
                    editable: true,
                    align: "left",
                    width: 150,
                  },
                  headerConfig: {
                    name: t("crm.organizationalChart.departments.columnName"),
                  },
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: "roles",
                    editable: false,
                    sortable: false,
                    width: 300,
                  },
                  headerConfig: {
                    name: t("crm.organizationalChart.departments.columnRoles"),
                  },
                },
              },
            ]}
            actionColumn={{
              width: downMd ? 50 : 120,
              defaultActions: {
                edit: {
                  clickConfig: {
                    setSelectedRow: setSelectedDepartment,
                    setOpenAction: setOpenDepartmentUpdateDrawer,
                  },
                },
                delete: {
                  clickConfig: {
                    setSelectedRow: setSelectedDepartment,
                    setOpenAction: setOpenDepartmentDeleteDrawer,
                  },
                },
              },
              actionHeaderName: t("crm.actions"),
            }}
            loading={
              departmentListLoading ||
              departmentUpdateLoading ||
              departmentListFetching
            }
            listFilter={{
              filterInput: departmentListFilter,
              setFilterInput: setDepartmentListFilter,
            }}
            count={departmentListData?.count ?? 0}
            config={{
              columnVisibilityModel: {
                roles: true,
              },
              features: [],
            }}
            update={{
              updateQuery: departmentUpdate,
            }}
          />
        </Box>
      ) : (
        <EmptyState
          content1={t("crm.organizationalChart.departments.emptyStateContent1")}
          content2={t("crm.organizationalChart.departments.emptyStateContent2")}
          button1={{
            title: t("crm.organizationalChart.departments.createDepartment"),
            onClick: () => {
              setOpenDepartmentCreateDrawer(true);
            },
            leftIcon: <Add />,
          }}
        />
      )}
      <DepartmentCreateDialog
        open={openDepartmentCreateDrawer}
        setOpen={setOpenDepartmentCreateDrawer}
        setOpenRoles={setOpenRolesDrawer}
      />
      <DepartmentUpdateWrapper
        open={openDepartmentUpdateDrawer}
        setOpen={setOpenDepartmentUpdateDrawer}
        setOpenRoles={setOpenRolesDrawer}
        selectedDepartment={selectedDepartment}
        setSelectedDepartment={setSelectedDepartment}
      />
      <AddRoleDialog open={openRolesDrawer} setOpen={setOpenRolesDrawer} />

      <DeleteDialog
        open={openDepartmentDeleteDrawer}
        item={selectedDepartment}
        onClose={() => {
          setSelectedDepartment(undefined);
          setOpenDepartmentDeleteDrawer(false);
        }}
        key={selectedDepartment?._id}
      />
    </Grid>
  );
});

export default List;
