import { Route, Routes } from "react-router-dom";

import { List} from "../pages";

import { LegalAgreementsLayout } from "../layouts/LegalAgreementsLayout";

export const LegalAgreementsRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<LegalAgreementsLayout />}>
        <Route index element={<List />} />
       </Route>
    </Routes>
  );
};
