import usersLocale from "../subdomains/users/locales/en";
import departmentsLocale from "../subdomains/departments/locales/en";
import permissionsLocale from "../subdomains/permissions/locales/en";

const locale = {
  ////////////////////////////////////////////////////////////////
  // domain
  ////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////
  // subDomains
  ////////////////////////////////////////////////////////////////

  users: {
    ...usersLocale,
  },
  departments: {
    ...departmentsLocale,
  },
  permissions: {
    ...permissionsLocale
  }
};

export default locale;
