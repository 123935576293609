import { Route, Routes } from "react-router-dom";

import { Organization } from "../pages";

import { DemoLayout } from "../layouts/DemoLayout";

export const DemoRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<DemoLayout />}>
        <Route index element={<Organization />} />
        <Route path="*" element={<Organization />} />

      </Route>
    </Routes>
  );
};
