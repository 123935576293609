/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Card, Grid, Grow, Stack, Typography } from "@mui/material";
import { MyTab, SettingsTitle } from "components";
import { unknownError } from "corede-common";
import { useEffect, useState } from "react";
import { useOrganizationDetailOwnQuery } from "../context/demo.api";
import { getCurrentLanguage } from "localization";
import { enqueueSnackbar } from "notistack";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import EmptyState from "components/emptyState/EmptyState";

const LegalAgreements = () => {

  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get('tab');
  const [tabLabels, setTabLabels] = useState([""]);
  const [selectedTabName, setSelectedTabName] = useState("");


  // const { data: organizationSettingsDetailOwnData, isLoading: organizationSettingsDetailOwnLoading, error: organizationSettingsDetailOwnError } = useOrganizationSettingsDetailOwnQuery({});
  const { data: organizationDetailOwnData, isLoading: organizationDetailOwnLoading, error: organizationDetailOwnError } = useOrganizationDetailOwnQuery({});

  const status = organizationDetailOwnData?.status;




  //Common

  useEffect(() => {
    switch (status) {
      case "restricted":
        setTabLabels([t("settings.settings.settings.legalAgreements")])
        break;
      case "active":
        setTabLabels([t("settings.settings.settings.legalAgreements")])
        break;
      default:
        break;
    }
  }, [status]);

  useEffect(() => {

    if (tabLabels) {
      switch (tab) {
        case "legalAgreements":
          setSelectedTabName(t("settings.settings.settings.legalAgreements"));
          break;
        default:
          setSelectedTabName(tabLabels[0]);
          break;
      }
    }
  }, [tabLabels, tab]);

  useEffect(() => {
    if (organizationDetailOwnError) {
      enqueueSnackbar((organizationDetailOwnError as any)?.error?.message[currentLanguage] ?? unknownError.message[currentLanguage], { variant: 'error' });
    }
  }, [organizationDetailOwnError])

  return <Stack direction={"column"} width={"100%"} spacing={2} pt={3} >
    <Stack direction={"row"} sx={{
      overflow: "hidden", borderRadius: "30px",
      px: 5
    }}
    >
      <MyTab
        type='settings'
        labels={tabLabels}
        setSelectedTab={setSelectedTabName}
        selectedTab={selectedTabName} />
    </Stack>
    {selectedTabName === t("settings.settings.settings.legalAgreements") && <Grid container spacing={2} px={2}>
      <SettingsTitle selectedTabName={t("settings.settings.settings.legalAgreements")} />
      <Grid item xs={12}>
        <Grow in={selectedTabName === t("settings.settings.settings.legalAgreements")} timeout={500}>
          <Card sx={{ p: 2, border: "1px solid", bgcolor: "background.thirdWithBlur", borderColor: "primary.lighter" }} >
            <Grid container >
              <Grid item xs={12} mb={2}>
                <EmptyState
                  content1={t("settings.settings.settings.noLegalAgreementsContent1")}
                  content2={t("settings.settings.settings.noLegalAgreementsContent2")}
                />
              </Grid>
            </Grid>
          </Card>
        </Grow>
      </Grid>
    </Grid>}

  </Stack >;
};


export default LegalAgreements;