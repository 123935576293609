const locale = {
  demo: "Demo",
  title: "CoCRM Community Nedir?",
  description: "CoCRM olarak; iş ve müşteri takip süreçlerinizi etkin yönetmenize sunduğumuz faydaya ek olarak network ve iş hacminizin genişlemesi için sunduğumuz bir iş birliği alanıdır.",
  featuresTitle: "CoCRM Community ile birlikte;",
  features1: "Referansla yeni iş fırsatları elde edebilir,",
  features2: "Güvenilir tedarikçilere ulaşabilir,",
  features3: "Güvenilir iş ortakları bulabilir,",
  features4: "Networkünüzü artırarak müşterilerinize 360° hizmetler sunabilirsiniz.",
  buttonTitle: "Community’e Katıl!"
};

export default locale;
