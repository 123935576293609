import customersLocale from "../subdomains/customers/locales/tr";
import customerCategoriesLocale from "../subdomains/customerCategories/locales/tr";
import contactsLocale from "../subdomains/contacts/locales/tr";

const locale = {
  customers: {
    ...customersLocale
  },
  contacts: {
    ...contactsLocale,
  },
  customerCategories: {
    ...customerCategoriesLocale,
  },
};

export default locale;
