import crmLocale from "../../apps/crm/locales/tr";
import authLocale from "../../apps/auth/locales/tr";
import leadPlusLocale from "../../apps/leadPlus/locales/tr";
import communityLocale from "../../apps/community/locales/tr";
import marketingLocale from "../../apps/marketing/locales/tr";
import windowLocale from "../../apps/window/locales/tr";
import settingsLocale from "../../apps/settings/locales/tr";
import webNotificationLocale from "../../apps/webNotification/locales/tr";
import accountLocale from "../../apps/account/locales/tr";

const locale = {
  crm: {
    ...crmLocale,
  },
  auth: {
    ...authLocale,
  },
  leadPlus: {
    ...leadPlusLocale,
  },
  community: {
    ...communityLocale,
  },
  marketing: {
    ...marketingLocale,
  },
  window: {
    ...windowLocale,
  },
  settings: {
    ...settingsLocale,
  },
  webNotification: {
    ...webNotificationLocale,
  },
  account: {
    ...accountLocale,
  },
  enums: {
    active: "Aktif",
    pending: "Beklemede",
    passive: "Pasif",
    neutral: "Nötr",
    cold: "Soğuk",
    warm: "Ilık",
    hot: "Sıcak",
    customer: 'Müşteri',
    unqualified: 'Niteliksiz',
    IQL: 'IQL (Bilgi)',
    MQL: 'MQL (Pazarlama)',
    SQL: 'SQL (Satış)',
    en: "İngilizce",
    fr: "Fransızca",
    de: "Almanca",
    it: "İtalyanca",
    pt: "Portekizce",
    ru: "Rusça",
    es: "İspanyolca",
    tr: "Türkçe",
    individual: "Bireysel",
    business: "İşletme",
    government: "Kamu",
    b2b: "B2B",
    b2c: "B2C",
    TRY: "TRY",
    EUR: "EUR",
    USD: "USD",
    IRR: "IRR",
    GBP: "GBP",
    NOK: "NOK",
    RUB: "RUB",
    CHF: "CHF",
    flat: 'Sabit',
    hourly: 'Saatlik',
    daily: 'Günlük',
    monthly: 'Aylık',
    NDA: 'NDA',
    termsAndConditions: 'Şartlar ve Koşullar',
    lead: 'Lead',
    unknown: 'Bilinmeyen',
    everyday: 'Her Gün',
    everyWeek: 'Her Hafta',
    annual: 'Yıllık',
    male: 'Erkek',
    female: 'Kadın',
    other: 'Diğer',
    All: "Hepsi",
    Unread: "Okunmamış",
    TWENTY_FOUR_HOURS: "24 saat",
    TWELVE_HOURS: "12 saat",
    automatic: 'Otomatik',
    manual: 'Manuel',
    high: 'Yüksek',
    low: 'Düşük',
    medium: 'Orta',
    scheduled: 'Planlandı',
    unscheduled: 'Planlanmadı',
    inProgress: 'Devam Ediyor',
    completed: 'Tamamlandı',
    expired: 'Süresi Bitti',
    cancelled: 'İptal Edildi',
  },
  aboutUs: "Hakkımızda",
  termsAndConditions: "Şartlar ve Koşullar",
  privacyPolicy: "Gizlilik Politikası",
  cookiesPolicy: "Çerez Politikası",
  resources: "Kaynaklar",
  rowsPerPage: "Sayfa başına satır",
  chartUpdateSuccess: "Grafik başarıyla güncellendi!",
  uploadPicture: "Resim yükle",
  expiresIn: "Kalan gün: ",
  expiresDays: "",

  ////////////////////////////////////////////////////////////////
  // Validations
  ////////////////////////////////////////////////////////////////

  fullNameIsRequired: "Ad soyad gereklidir",
  emailIsRequired: "Email gereklidir",
  emailIsInvalid: "Email yanliş girildi",
  nameIsRequired: "İsim zorunludur",
  titleIsRequired: "Başlık zorunludur",
  profileImageIsRequired: "Profil resmi gereklidir",
  descriptionIsRequired: "Açıklama zorunludur",
  phoneNumberIsRequired: "Telefon numarası gereklidir",
  quotaYupIsRequired: "Kota gereklidir",
  dateYupIsRequired: "Tarih gereklidir",
  statusYupIsRequired: "Durum gereklidir",
  locationYupIsRequired: "Konum gereklidir",
  detailsIsRequired: "Detaylar gereklidir",
  surnameIsRequired: "Soyadı gereklidir",
  birthDateIsRequired: "Doğum tarihi gereklidir",
  genderIsRequired: "Cinsiyet gereklidir",
  countryIsRequired: "Ülke gereklidir",
  cityIsRequired: "Şehir gereklidir",
  addressIsRequired: "Adres gereklidir",
  contentIsRequired: "İçerik gereklidir",
  readingTimeIsRequired: "Okuma süresi gereklidir",
  tagsIsRequired: "Etiketler gereklidir",
  referencesIsRequired: "Referanslar gereklidir",
  relatedVideoIsRequired: "İlgili video gereklidir",
  authorIsRequired: "Yazar gereklidir",
  categoryIsRequired: "Kategori gereklidir",
  targetCategoryIsRequired: "Hedef kategori gereklidir",
  languageIsRequired: "Dil gereklidir",
  domainIsRequired: "Alan gereklidir",
  targetIsRequired: "Hedef gereklidir",
  titleIsTooShort25: "Başlık çok kısa, minimum uzunluk 25",
  titleIsTooLong50: "Başlık çok uzun, maksimum uzunluk 50",
  contentIsTooShort50: "İçerik çok kısa, minimum uzunluk 50",
  contentIsTooLong250: "İçerik çok uzun, maksimum uzunluk 250",
  descriptionIsTooShort400: "Açıklama çok kısa, minimum uzunluk 400",
  descriptionIsTooLong4000: "Açıklama çok uzun, maksimum uzunluk 4000",
  quotaMustBePositive: "Kota pozitif olmalıdır",
  quotaMustBeMultipleOf10: "Kota 10'un katı olmalıdır",
  participantsIsRequired: "Katılımcılar gereklidir",
  startDateIsRequired: "Başlangıç tarihi gereklidir",
  durationIsRequired: "Süre gereklidir",
  participationLinkIsRequired: "Katılım linki gereklidir",
  lastApplicationDateIsRequired: "Son başvuru tarihi gereklidir",
  statusIsRequired: "Durum gereklidir",
  locationIsRequired: "Konum gereklidir",
  typeIsRequired: "Tip gereklidir",
  durationMustBeMultipleOf5: "Süre 5'in katı olmalıdır",
  durationMustBePositive: "Süre pozitif olmalıdır",
  stateIsRequired: "İlçe gereklidir",
  positionIsRequired: "Pozisyon gereklidir",
  companyIsRequired: "Şirket adı gereklidir",
  sectorIsRequired: "Bölüm gereklidir",
  qualificationIsRequired: "Derece gereklidir",
  websiteIsInvalid: "Web sitesi yanlış girildi",

  ////////////////////////////////////////////////////////////////
  // Buttons
  ////////////////////////////////////////////////////////////////

  cancel: "İptal",
  save: "Kaydet",
  update: "Güncelle",
  delete: "Sil",
  create: "Oluştur",
  add: "Ekle",
  close: "Kapat",
  submit: "Gönder",
  view: "Göster",
  edit: "Düzenle",
  remove: "Sil",
  myPlan: "Planım",
  expandAll: "Genişlet",
  collapseAll: "Daralt",
  show: "Göster",

  ////////////////////////////////////////////////////////////////
  // Tables
  ////////////////////////////////////////////////////////////////

  actions: "İşlemler",
  title: "Başlık",
  content: "İçerik",
  category: "Kategori",
  target: "Hedef",
  status: "Durum",
  date: "Tarih",
  location: "Konum",
  details: "Detaylar",
  name: "İsim",
  surname: "Soyisim",
  birthDate: "Doğum Tarihi",
  fullName: "Ad Soyad",
  email: "E-posta",
  subject: "Konu",
  createdAt: "Oluşturulma Tarihi",
  page: "Sayfa",
  news: "Haber",
  offer: "Teklif",
  products: "Ürünler",
  updatedAt: "Güncellenme Tarihi",
  readingTime: "Okuma Süresi",
  tags: "Etiketler",
  references: "Referanslar",
  version: "Versiyon",
  domain: "Alan",
  type: "Tip",
  language: "Dil",
  duration: "Süre",
  startDate: "Başlangıç Tarihi",
  lastApplicationDate: "Son Başvuru Tarihi",
  participationLink: "Katılım Linki",
  question: "Soru",
  answer: "Cevap",

  ////////////////////////////////////////////////////////////////
  // Menus
  ////////////////////////////////////////////////////////////////
  dashboard: "Panel",
  blog: "Blog",
  blogs: "Bloglar",
  categories: "Kategoriler",
  authors: "Yazarlar",
  faq: "SSS",
  faqs: "SSS'ler",
  faqCategory: "SSS Kategorileri",
  form: "Form",
  contactForm: "İletişim Formları",
  subscriptionForm: "Abonelik Formları",
  blogTargetCategories: "Hedef Kategoriler",
  users: "Kullanıcılar",
  departments: "Departmanlar",
  permissions: "Izinler",
  leadStatuses: "Lead Durumları",
  leadSources: "Lead Kaynakları",
  leadForms: "Lead Formları",
  customers: "Müşteriler",
  contacts: "İletişim",
  customerCategories: "Müşteri Kategorileri",
  estimates: "Tahminler",
  proposals: "Teklifler",
  invoices: "Faturalar",
  payments: "Ödemeler",
  contracts: "Sozlesmeler",
  returns: "İadeler",
  services: "Hizmetler",
  reminders: "Hatırlatmalar",
  appointments: "Randevular",

  ////////////////////////////////////////////////////////////////
  // Permissions
  ////////////////////////////////////////////////////////////////

  organizationLevel: "Organizasyon Seviyesi",
  organizationLevelDesc:"Kullanıcının bulunduğu organizasyonun seviyesi.",
  departmentLevel: "Departman Seviyesi",
  departmentLevelDesc:"Kullanıcının bulunduğu departman seviyesi.",
  userLevel: "Kullanıcı Seviyesi",
  userLevelDesc:"Kullanıcının seviyesi.",

  ////////////////////////////////////////////////////////////////
  // Abbreviations
  ////////////////////////////////////////////////////////////////
  min: "dk",
};

export default locale;
