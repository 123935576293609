const locale = {
  appointments: "Appointments",
  appointment: "Appointment",
  appointmentConversions: "Appointment Conversions",
  title: "Appointments",
  addAppointment: "Add Appointment",
  emptyStateDesc1: "No appointments scheduled yet.",
  emptyStateDesc2: " Set up and manage your appointments here to stay organized and connect with your customers seamlessly."
};

export default locale;




