import { IMenuItem } from "interfaces/menu-item.interface";
import { useTranslation } from "react-i18next";
import {
  baseSalesRoute,
  listInvoicesRoute,
  listPaymentsRoute,
  listReturnsRoute
} from "../routes/sales.base.route";
import { PermissionSubdomain } from "corede-common-cocrm";

export const useSalesDomainMenuItem = (): IMenuItem => {
  const { t } = useTranslation(); 

  return {
    title: t("sales"), 
    path: baseSalesRoute(),
    children: [
      {
        title: t("invoices"),
        path: listInvoicesRoute(),
        subdomain: PermissionSubdomain.invoice
      },
      {
        title: t("payments"),
        path: listPaymentsRoute(),
        subdomain: PermissionSubdomain.invoicePayment
      },
      {
        title: t("returns"),
        path: listReturnsRoute(),
        // subdomain: PermissionSubdomain.return TODO: add subdomain
      },
    ],
  };
};
