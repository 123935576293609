


import { IMenuItem } from "interfaces/menu-item.interface";
import { getTranslatedText } from "localization";
import {
  baseLeadPlusRoute,
} from "../routes/demo.base.route";

export const demoDomainMenuItem: IMenuItem = {
  title: getTranslatedText("demo"),
  path: baseLeadPlusRoute(),
  children: [
    // Todo
    // {
    //   title: getTranslatedText("users"),
    //   path: listUsersRoute(),
    // },
  ],
};
