import { Typography, Dialog, DialogActions, DialogContent, DialogTitle, Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { OutlinedButton, FilledButton } from 'components/buttons';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
    open: boolean;
    setOpen?: (open: boolean) => void;
    onClose?: () => void;
    size?: "small" | "medium";
    title: string;
    children: React.ReactNode;
    handleClick?: () => void;
    buttonTitle?: string;
    disabled?: boolean;
    loading?: boolean;
    width?: number;
    leftIcon?: JSX.Element;
    buttonColor?: "primary" | "inherit" | "secondary" | "tertiary" | "success" | "error" | "info" | "warning";
    sx?: any;
    buttonCancelTitle?: string;
}

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ActionDialog = (props: Props) => {

    const { t } = useTranslation();

    return <Dialog
        open={props.open}
        onClose={() => props?.buttonCancelTitle !== t("crm.logout") ? props.setOpen ? props.setOpen(false) : props.onClose ? props.onClose() : null : null}
        TransitionComponent={Transition}
        sx={{
            '& .MuiDialog-paper': {
                width: props.width ?? "auto",
                maxWidth: props.width ?? "auto",
            }
        }}
    >
        <DialogTitle bgcolor={"background.paper"} sx={{ borderBottom: 2, borderColor: 'divider' }}>
            <Typography fontSize={"20px"} fontWeight={700}>{props.title}</Typography>
        </DialogTitle>
        <DialogContent sx={{ bgcolor: "background.drawer", mt: 0 }}>
            {props.children}
        </DialogContent>
        <DialogActions sx={{ borderTop: 2, pt: 2, borderColor: 'divider' }}>
            <OutlinedButton title={props?.buttonCancelTitle ?? t("cancel")} onClick={() => props.setOpen ? props.setOpen(false) : props.onClose ? props.onClose() : null} />
            {props.handleClick && <FilledButton
                title={props.buttonTitle ?? t("submit")}
                onClick={() => props.handleClick && props.handleClick()}
                leftIcon={props.leftIcon ?? undefined}
                color={props.buttonColor ?? "primary"}
                loading={props.loading}
                disabled={props.disabled}
            />}
        </DialogActions>
    </Dialog>

}

export default ActionDialog