import {
  ILeadStatusesInput,
  ILeadStatusesRequest,
  ILeadStatusesResponse,
  ILeadStatusesResult,
  leadStatusesQuery,
  IUpdateLeadStatusInput,
  IUpdateLeadStatusRequest,
  IUpdateLeadStatusResponse,
  IUpdateLeadStatusResult,
  updateLeadStatusQuery,
  IUpdateLeadStatusFilterInput,
  ICreateLeadStatusInput,
  ICreateLeadStatusRequest,
  ICreateLeadStatusResponse,
  ICreateLeadStatusResult,
  createLeadStatusQuery,
  IDeleteLeadStatusInput,
  IDeleteLeadStatusRequest,
  IDeleteLeadStatusResponse,
  IDeleteLeadStatusResult,
  deleteLeadStatusQuery,
} from "corede-common-cocrm";
import { commonApi } from "context/commonApi";
import { EndpointQueryBuilder } from "apps/helper/endpoint-query.builder";
import { leadStatusesTags } from "./leadStatuses.tags";
import { IGraphqlVariables } from "corede-common";

export const leadStatusesApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({
    // queries
    leadStatuses: builder.query<
      ILeadStatusesResult,
      IGraphqlVariables<ILeadStatusesInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ILeadStatusesRequest,
        ILeadStatusesResponse,
        ILeadStatusesResult,
        ILeadStatusesInput
      >({
        query: leadStatusesQuery,
        providesTags: [
          leadStatusesTags.leadStatuses,
          leadStatusesTags.leadStatus,
        ],
      })
    ),

    // mutation
    leadStatusUpdate: builder.mutation<
      IUpdateLeadStatusResult,
      IGraphqlVariables<IUpdateLeadStatusInput, IUpdateLeadStatusFilterInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IUpdateLeadStatusRequest,
        IUpdateLeadStatusResponse,
        IUpdateLeadStatusResult,
        IUpdateLeadStatusInput,
        IUpdateLeadStatusFilterInput
      >({
        query: updateLeadStatusQuery,
        invalidatesTags: [
          leadStatusesTags.leadStatus,
          leadStatusesTags.leadStatuses,
          leadStatusesTags.leadStatuses,
          leadStatusesTags.leadStatus,
        ],
      })
    ),

    leadStatusCreate: builder.mutation<
      ICreateLeadStatusResult,
      IGraphqlVariables<ICreateLeadStatusInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ICreateLeadStatusRequest,
        ICreateLeadStatusResponse,
        ICreateLeadStatusResult,
        ICreateLeadStatusInput
      >({
        query: createLeadStatusQuery,
        invalidatesTags: [
          leadStatusesTags.leadStatus,
          leadStatusesTags.leadStatuses,
          leadStatusesTags.leadStatuses,
          leadStatusesTags.leadStatus,
        ],
      })
    ),

    leadStatusDelete: builder.mutation<
      IDeleteLeadStatusResult,
      IGraphqlVariables<IDeleteLeadStatusInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IDeleteLeadStatusRequest,
        IDeleteLeadStatusResponse,
        IDeleteLeadStatusResult,
        IDeleteLeadStatusInput
      >({
        query: deleteLeadStatusQuery,
        invalidatesTags: [
          leadStatusesTags.leadStatus,
          leadStatusesTags.leadStatuses,
          leadStatusesTags.leadStatuses,
          leadStatusesTags.leadStatus,
        ],
      })
    ),
  }),
});

export const {
  useLeadStatusesQuery,
  useLazyLeadStatusesQuery,

  useLeadStatusUpdateMutation,
  useLeadStatusCreateMutation,
  useLeadStatusDeleteMutation,
} = leadStatusesApi;
