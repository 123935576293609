/* eslint-disable react-hooks/exhaustive-deps */
import { Add } from "@mui/icons-material";
import { Box, Grid, useMediaQuery, } from "@mui/material";
import { HeaderBase } from "components/contentHeaders/crm/HeaderBase";
import { useEffect, useState } from "react";
import { getCurrentLanguage } from "localization";
import { IGraphqlVariables, unknownError } from "corede-common";
import { useCustomerCategoryListQuery, useCustomerCategoryUpdateMutation } from "../context/customerCategories.api";
import { ICustomerCategoryListInput } from "corede-common-cocrm";
import EmptyState from "components/emptyState/EmptyState";
import { RolesDrawer } from "../../../../02-organizationalChart/subdomains/permissions";
import { DeleteDialog } from "./DeleteDialog";
import { enqueueSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import CustomerCategoryUpdateWraper from "./CustomerCategoryUpdateWraper";
import CustomerCategoryCreateWraper from "./CustomerCategoryCreateWraper";
import { MyDataGrid } from "components/dataGrid/MyDataGrid";
import moment from "moment";

const List = () => {
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();
  const [openCustomerCategoryCreateDrawer, setOpenCustomerCategoryCreateDrawer] = useState(false);
  const [openCategoryDeleteDialog, setOpenCategoryDeleteDialog] = useState<boolean>(false);
  const [openCustomerCategoryUpdateDrawer, setOpenCustomerCategoryUpdateDrawer] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>();
  const [openRolesDrawer, setOpenRolesDrawer] = useState(false);


  //general
  const downMd = useMediaQuery((theme: any) => theme.breakpoints.down('md'));
  const [customerCategoryListFilter, setCustomerCategoryListFilter] = useState<IGraphqlVariables<ICustomerCategoryListInput>>({
    input: {
      pagination: {
        page: 1,
        pageSize: 10,
        sort: {
          createdAt: 1
        }
      }
    }
  })


  //queries
  const { data: customerCategoryListData, isLoading: customerCategoryListLoading, isFetching: customerCategoryListFetching, error: customerCategoryListError } = useCustomerCategoryListQuery(customerCategoryListFilter)
  const [customerCategoryUpdate, { isLoading: customerCategoryUpdateLoading, error: customerCategoryUpdateError }] = useCustomerCategoryUpdateMutation()

  useEffect(() => {
    if (customerCategoryListError) {
      enqueueSnackbar((customerCategoryListError as any)?.error?.message[currentLanguage] ?? unknownError.message[currentLanguage], { variant: 'error' });
    }
  }, [customerCategoryListError]);

  return <Grid item xs={12}>
    <HeaderBase
      title={t("crm.customer.customerCategories.headerTitle")}
      rightButton={{
        title: t("crm.customer.customerCategories.createCustomerCategory"),
        onClick: () => {
          setOpenCustomerCategoryCreateDrawer(true);
        },
        icon: <Add sx={{ mr: 1 }} />,
      }}
    />

    {customerCategoryListData?.data?.length !== 0 ? <Box minHeight={"350px"} height={"600px"} mt={1} overflow={"auto"} borderRadius={"16px"}>
      <MyDataGrid
        path="categories"
        columns={[
          {
            field: "id",
            headerName: "#",
            disableColumnMenu: true,
            filterable: false,
            align: "center",
            width: 40,
            headerAlign: "center"
          },
          {
            field: "name",
            headerName: t("crm.customer.customerCategories.name"),
            width: 140,
            align: "left",
            editable: true,
            filterable: true
          },
          {
            field: "icon",
            headerName: t("crm.customer.customerCategories.icon"),
            width: 100,
            align: "left",
            editable: true
          },
          {
            field: "actions",
            headerName: t("crm.customer.customerCategories.actions"),
            sortable: false,
            filterable: false,
            align: "center",
            editable: false,
            headerAlign: "center",
            width: downMd ? 50 : 120,
            changed: ["delete"]
          },
        ]}
        rows={customerCategoryListData?.data?.map((category, index) => ({
          _id: category._id,
          id: (index + 1) * (customerCategoryListFilter?.input?.pagination?.page || 0),
          name: category.name,
          icon: category.icon
        })) || []}
        columnVisibilityModel={{}}
        props={{
          setSelectedRow: setSelectedRow,
          setOpenDelete: setOpenCategoryDeleteDialog,
          setOpenUpdate: setOpenCustomerCategoryUpdateDrawer
        }}
        loading={customerCategoryListLoading || customerCategoryListFetching}
        count={customerCategoryListData?.count || 0}
        filter={customerCategoryListFilter}
        setFilter={setCustomerCategoryListFilter}
        rowUpdate={customerCategoryUpdate}
        features={[]}
      />
    </Box> :
      <EmptyState
        content1={t("crm.customer.customerCategories.emptyStateContent1")}
        content2={t("crm.customer.customerCategories.emptyStateContent2")}
        button1={{
          title: t("crm.customer.customerCategories.createCustomerCategory"),
          onClick: () => {
            setOpenCustomerCategoryCreateDrawer(true);
          },
          leftIcon: <Add />
        }}
      />
    }


    <CustomerCategoryCreateWraper
      open={openCustomerCategoryCreateDrawer}
      setOpen={setOpenCustomerCategoryCreateDrawer}
    />

    <CustomerCategoryUpdateWraper
      open={openCustomerCategoryUpdateDrawer}
      setOpen={setOpenCustomerCategoryUpdateDrawer}
      selectedRow={selectedRow}
    />

    <RolesDrawer
      open={openRolesDrawer}
      setOpen={setOpenRolesDrawer}
    />
    <DeleteDialog
      open={openCategoryDeleteDialog}
      item={selectedRow}
      onClose={() => setOpenCategoryDeleteDialog(false)}
    />
  </Grid >
};

export default List;
