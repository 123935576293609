import { useState, lazy, Suspense } from 'react';


const UserDetailDrawer = lazy(() => import('./UserDetailDrawer'));


const UserDetailWrapper = ({ 
  open, 
  setOpen,
  selectedUser,
  handleSelect
  }: any) => {

    const [mounted, setMounted] = useState(false);

    if (open && !mounted) {
        setMounted(true);
    }

    return (
        <>
            {mounted && (
                <Suspense>
                    < UserDetailDrawer 
                    open={open} 
                    setOpen={setOpen} 
                    selectedUser={selectedUser}
                    handleSelect={handleSelect}
                    />
                </Suspense>
            )}
        </>
    );
};

export default UserDetailWrapper;