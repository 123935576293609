import {
  BaseQueryFn,
  createApi,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
import {
  IBaseGraphqlError,
  IBaseGraphqlResponse,
  Language,
  unknownError,
} from "corede-common";
import { BASE_URL } from 'utils';
import { getAccessToken } from 'utils/getUserInfo';
// import { BASE_URL, getAccessToken } from 'utils'

const customBaseQuery: BaseQueryFn = async (args, api, extraOptions) => {
  const baseQuery = fetchBaseQuery({
    baseUrl: `${BASE_URL}/graphql`,
    method: "POST",
    headers: {
      Authorization: "Bearer " + getAccessToken(),
      "Content-Type": "application/json",
      accept: "application/json",
      "Accept-Language": Language.tr,
    },
  });

  const response = await baseQuery(args, api, extraOptions);

  if (response.error) {
    // If fetchBaseQuery reports an error (e.g., network issues), return it directly.
    return response;
  }

  if (response.data && (response.data as IBaseGraphqlResponse).errors) {
    // If GraphQL reports an error in the `errors` field, treat it as an error.
    const responseError: IBaseGraphqlError = (
      response.data as IBaseGraphqlResponse
    ).errors?.at(0)?.extensions?.custom ?? {
      statusCode: 500,
      error: unknownError,
    };
    return {
      error: responseError,
    };
  }

  return response; // Return the response if everything is okay.
};

export const commonApi = createApi({
  reducerPath: "commonApi",
  baseQuery: customBaseQuery,

  tagTypes: ["users", "user"],

  endpoints: (builder) => ({}),
});
