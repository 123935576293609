/* eslint-disable react-hooks/exhaustive-deps */
import { ArrowDownward, ArrowUpward, Close, NoteAdd } from '@mui/icons-material'
import { SwipeableDrawer, Box, Stack, Avatar, Typography, Button, Divider, Grid, IconButton, SvgIcon, InputLabel, TextField, Autocomplete, MenuItem, Menu, useMediaQuery } from '@mui/material'
import { useCustomerCreateFromLeadMutation, useLeadDetailQuery, useNoteListQuery, useNoteUpdateMutation } from 'apps/crm/domains/03-lead/subdomains/lead'
import { MyTab } from 'components/tab'
import { useNavigate } from 'react-router-dom'
import * as Icons from '@mui/icons-material';
import moment from 'moment'
import { memo, useEffect, useState } from 'react'
import { convertEnums, getCurrentLanguage } from 'localization'
import { IGraphqlVariables, Language, NameFactory, unknownError } from 'corede-common'
import { useTranslation } from 'react-i18next'
import { CustomerType, EstimateProspectTargetEntityRef, ICustomerCreateFromLeadInput, ILeadContactAddInput, ILeadDetailResult, INoteListItemResult, IReminderListItemResult, LeadQualification, LeadType, NoteTargetType, noteUpdateQuery, ProposalProspectTargetEntityRef, ReminderPriority, ReminderStatus, ReminderTargetType } from 'corede-common-cocrm'
import { FilledButton, Iconify, OutlinedButton } from 'components'
import { skipToken } from '@reduxjs/toolkit/query'
import { viewLeadRoute } from '../../../routes/lead.base.route'
import NoteCreateDialog from 'apps/crm/domains/14-notes/subdomains/notes/pages/NoteCreateDialog'
import { NoteDeleteDialog } from 'apps/crm/domains/14-notes/subdomains/notes/pages/NoteDeleteDialog'
import NoteUpdateDialog from 'apps/crm/domains/14-notes/subdomains/notes/pages/NoteUpdateDialog'
import { BaseDataGrid } from 'components/baseDataGrid/BaseDataGrid'
import { BaseGridColType } from 'components/baseDataGrid/infra/enums'
import DetailBox from 'components/box/DetailBox'
import EmptyState from 'components/emptyState/EmptyState'
import { representString, representDateTimeAsString } from 'utils/representationHelper'
import parse from 'html-react-parser';
import { AddLeadContactDialog } from './AddLeadContactDialog'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { validateCustomerCreateFromLeadInput } from '../validations/customerCreateFromLead'
import { enqueueSnackbar } from 'notistack'
import ActionDialog from 'components/dialog/ActionDialog'
import React from 'react'
import { useEstimateListQuery } from 'apps/crm/domains/05-preSales/subdomains/estimates'
import { useProposalListQuery } from 'apps/crm/domains/05-preSales/subdomains/proposals'
import { useReminderListQuery, useReminderUpdateMutation } from 'apps/crm/domains/12-calendar/subdomains/reminders'
import ReminderCreateDialog from 'apps/crm/domains/12-calendar/subdomains/reminders/pages/ReminderCreateDialog'
import { ReminderDeleteDialog } from 'apps/crm/domains/12-calendar/subdomains/reminders/pages/ReminderDeleteDialog'
import ReminderUpdateDialog from 'apps/crm/domains/12-calendar/subdomains/reminders/pages/ReminderUpdateDialog'

interface ILeadDetailDrawer {
    open: any
    setOpen: any
    selectedLead: any
    setSelectedLead: any
    handleSelect: any
    count: any
    currentPage: number
    currentPageSize: number
}

const LeadDetailDrawer = memo((props: ILeadDetailDrawer) => {

    const { t } = useTranslation();
    const { data: leadDetailData, } = useLeadDetailQuery(
        props?.selectedLead?._id ? {
            input: {
                _id: props?.selectedLead?._id
            }
        } : skipToken
    )
    const [selectedTabName, setSelectedTabName] = useState(t("crm.lead.lead.info"));


    return (
        <SwipeableDrawer
            open={props.open}
            onClose={() => props.setOpen(false)}
            onOpen={() => props.setOpen(true)}
            anchor="right"
            className='user-detail-nav'
            sx={{ zIndex: 1249 }}
        >
            <Stack direction="column"
                sx={{
                    position: "fixed",
                    bgcolor: "background.primary",
                    borderRadius: 10,
                    top: "10px",
                    right: "1010px",
                    zIndex: 1450
                }}
            >
                <IconButton size="small" sx={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
                    disabled={Number(props?.selectedLead?.id) === 1}
                    onClick={() => props.handleSelect("back")}>
                    <ArrowUpward />
                </IconButton>
                <Divider sx={{ width: '100%' }} />
                <IconButton size="small" sx={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
                    disabled={Number(props?.selectedLead?.id) === props.count}
                    onClick={() => props.handleSelect("next")}>
                    <ArrowDownward />
                </IconButton>
            </Stack>
            <Box minWidth={260} width={{ xs: "100%", sm: 580, md: 850, lg: 1000 }} bgcolor={"background.primary"} height={{ xs: '1920px', lg: "calc(100dvh)" }}>
                <Stack direction="column" alignItems="center">
                    <Box sx={{ py: 1, height: 20, width: '100%', borderBottom: "2px solid", borderColor: "grey.400" }}>
                        <Stack direction="row" alignItems="center" gap={1} px={2} justifyContent={"space-between"}>
                            <Typography fontSize={"small"} fontWeight={"bold"}>
                                {props?.selectedLead?.id}
                                {/* {(props.selectedLead?.id) + (props.currentPageSize * (props.currentPage - 1))} */}
                                {t("crm.lead.lead.of")} {props.count} {t("crm.lead.lead.leadCount")}
                            </Typography>
                            <Stack direction="row" alignItems="center" gap={0.5} onClick={() => props.setOpen(false)} sx={{ cursor: 'pointer' }}>
                                <Typography fontSize={"small"} fontWeight={"bold"} color={"secondary.main"}>
                                    {t("close")}
                                </Typography>
                                <Close sx={{ fontSize: "16px" }} color='secondary' />
                            </Stack>
                        </Stack>
                    </Box>
                    <Stack direction={{ xs: "column", lg: "row" }} width={"100%"}>
                        <Stack>
                            <InfoContent
                                leadDetailData={leadDetailData}
                                setOpen={props.setOpen}
                            />
                        </Stack>
                        <Stack direction={"column"} gap={0} width={"100%"}>
                            <LastContact
                                leadDetailData={leadDetailData}
                            />

                            <Box sx={{ px: 1 }}>
                                <MyTab
                                    labels={[
                                        t("crm.lead.lead.info"),
                                        t("crm.lead.lead.estimates"),
                                        t("crm.lead.lead.proposals"),
                                        t("crm.lead.lead.tasks"),
                                        t("crm.lead.lead.documents"),
                                        t("crm.lead.lead.notes"),
                                        t("crm.lead.lead.reminders"),
                                        t("crm.lead.lead.activities"),
                                    ]}
                                    setSelectedTab={setSelectedTabName}
                                    selectedTab={selectedTabName}
                                />
                            </Box>

                            <Divider sx={{ width: "100%", borderBottomWidth: 2, borderColor: "grey.400" }} />

                            {selectedTabName === t("crm.lead.lead.info") && <InfoTab
                                selectedTabName={t("crm.lead.lead.info")}
                                leadDetailData={leadDetailData}
                            />}

                            {selectedTabName === t("crm.lead.lead.proposals") && <ProposalsTab
                                selectedTabName={t("crm.lead.lead.proposals")}
                                leadDetailData={leadDetailData}
                            />}

                            {selectedTabName === t("crm.lead.lead.activities") && <ActivitiesTab
                                selectedTabName={t("crm.lead.lead.activities")}
                                leadDetailData={leadDetailData}
                            />}


                            {selectedTabName === t("crm.lead.lead.estimates") && <EstimatesTab
                                selectedTabName={t("crm.lead.lead.estimates")}
                                leadDetailData={leadDetailData}
                            />}

                            {selectedTabName === t("crm.lead.lead.tasks") && <TasksTab
                                selectedTabName={t("crm.lead.lead.estimates")}
                                leadDetailData={leadDetailData}
                            />}

                            {selectedTabName === t("crm.lead.lead.documents") && <DocumentsTab
                                selectedTabName={t("crm.lead.lead.documents")}
                                leadDetailData={leadDetailData}
                            />}

                            {selectedTabName === t("crm.lead.lead.notes") && <NotesTab
                                selectedTabName={t("crm.lead.lead.notes")}
                                leadDetailData={leadDetailData}
                            />}

                            {selectedTabName === t("crm.lead.lead.reminders") && <RemindersTab
                                selectedTabName={t("crm.lead.lead.reminders")}
                                leadDetailData={leadDetailData}
                            />}
                        </Stack>
                    </Stack>
                </Stack >
            </Box >
        </SwipeableDrawer >
    )
});


interface IInfoContent {
    leadDetailData: ILeadDetailResult | undefined
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const InfoContent = (props: IInfoContent) => {

    const { t } = useTranslation();
    const downLg = useMediaQuery((theme: any) => theme.breakpoints.down("lg"));
    const currentLanguage = getCurrentLanguage();
    const navigate = useNavigate();
    const localizedLeadQualifications = convertEnums(LeadQualification);
    const [openConvertForm, setOpenConvertForm] = useState(false);
    const [customerCreateFromLead, { data: customerCreateFromLeadData, isLoading: customerCreateFromLeadLoading, error: customerCreateFromLeadError }] = useCustomerCreateFromLeadMutation();
    const localizedCustomerType = convertEnums(CustomerType);

    const {
        control,
        handleSubmit,
        reset,
        setValue,
        formState: { errors, touchedFields, isValid },
    } = useForm<IGraphqlVariables<ICustomerCreateFromLeadInput>>({
        defaultValues: {
            input: {
                leadId: props.leadDetailData?._id,
                type: CustomerType.business,
                primaryContact: {
                    email: props.leadDetailData?.email,
                    firstName: props.leadDetailData?.fullName.split(" ")[0],
                    lastName: props.leadDetailData?.fullName.split(" ")[1],
                    phone: props.leadDetailData?.phoneNumber,
                    position: props.leadDetailData?.position
                }
            },
        },
        resolver: yupResolver(validateCustomerCreateFromLeadInput),
        mode: "onChange",
    });

    const handleConvertLead = async (values: IGraphqlVariables<ICustomerCreateFromLeadInput>) => {
        customerCreateFromLead(values);
    };

    const DrawerMenu = () => {
        const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
        const open = Boolean(anchorEl);
        const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(event.currentTarget);
        };
        const handleClose = () => {
            setAnchorEl(null);
        };

        return (
            <div>
                <Button
                    id="lead-detail-button"
                    aria-controls={open ? 'lead-detail-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                    variant='outlined'
                    sx={{
                        height: 40, width: 40, fontSize: "13px", minWidth: 0, borderRadius: 100
                    }}
                >
                    <Icons.MoreVert />
                </Button>
                <Menu
                    id="lead-detail-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'lead-detail-button',
                    }}
                    sx={{
                        "& .MuiPaper-root": {
                            bgcolor: "background.secondWithBlur",
                            backdropFilter: "blur(3px)",
                            borderRadius: 2
                        },
                    }}
                >
                    <MenuItem onClick={() => setOpenConvertForm(true)}>
                        <Icons.Refresh sx={{ mr: 1 }} />
                        <Typography fontSize={"small"} fontWeight={"bold"}>{t("crm.lead.lead.convertToCustomer")}</Typography>
                    </MenuItem>
                </Menu>
            </div>
        );
    }

    useEffect(() => {
        if (customerCreateFromLeadData) {
            enqueueSnackbar(t("crm.lead.lead.customerCreatedSuccessfully"), { variant: "success" });
            reset();
            props.setOpen(false);
            setOpenConvertForm(false);
        }
    }, [customerCreateFromLeadData])

    useEffect(() => {
        if (customerCreateFromLeadError) {
            enqueueSnackbar((customerCreateFromLeadError as any)?.error?.message[currentLanguage] ?? unknownError.message[currentLanguage], { variant: 'error' });
        }
    }, [customerCreateFromLeadError])

    useEffect(() => {
        if (props.leadDetailData) {
            setValue("input.leadId", props.leadDetailData._id);
            setValue("input.primaryContact.email", props.leadDetailData.email);
            setValue("input.primaryContact.firstName", props.leadDetailData.fullName.split(" ")[0]);
            setValue("input.primaryContact.lastName", props.leadDetailData.fullName.split(" ")[1]);
            setValue("input.primaryContact.phone", props.leadDetailData.phoneNumber ?? "");
            setValue("input.primaryContact.position", props.leadDetailData.position ?? "");
        }
    }, [props.leadDetailData])

    return <Stack sx={{ height: { xs: 'auto', lg: '100%' }, borderRight: "2px solid", borderColor: "grey.400", p: 2 }}>

        <Stack direction={"column"} alignItems={"flex-start"} gap={1} height={{ xs: 'auto', lg: "calc(100dvh - 70px)" }}>
            {/* Personal */}
            <Stack direction={{ xs: "row", lg: "column" }} alignItems={{ xs: "center", lg: "flex-start" }} gap={1}>
                {downLg ? null : <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} gap={1} width={{ xs: 'auto', lg: "100%" }}>
                    <Box
                        sx={{
                            border: "2px solid",
                            borderColor: "secondary.main",
                            borderRadius: '10px',
                            py: 0.5,
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            px: 1,
                        }}
                    >
                        <Typography fontSize={"12px"} textAlign={"center"} fontWeight={"bold"} color={"secondary.main"}>
                            {props.leadDetailData?.businessType?.toString().toUpperCase()}
                        </Typography>
                    </Box>
                    {props.leadDetailData?.isJunk && <Box
                        sx={{
                            border: "1px solid",
                            borderColor: "warning.main",
                            borderRadius: '10px',
                            backgroundColor: "warning.lighter",
                            py: 0.5,
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            px: 1,
                        }}
                    >
                        <Typography fontSize={"12px"} textAlign={"center"} fontWeight={"bold"}>
                            JUNK
                        </Typography>
                    </Box>}
                </Stack>}

                <Typography sx={{ fontWeight: "bold", fontSize: "24px", color: "primary.main" }}>
                    {props.leadDetailData?.fullName}
                </Typography>
                {downLg ? null : <Typography sx={{ fontWeight: 400, fontSize: "14px", color: "primary.main" }}>
                    {localizedLeadQualifications[(props.leadDetailData as any)?.qualification as keyof typeof localizedLeadQualifications] ?? ''}
                </Typography>}
                <Stack direction={"row"} gap={1} alignItems={"center"}>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled
                        sx={{ height: 40, fontSize: "13px", borderRadius: 100 }}
                        onClick={() => navigate("/")}
                    >
                        <NoteAdd sx={{ mr: 1 }} /> {t("crm.lead.lead.appointment")}
                    </Button>
                    <DrawerMenu />
                </Stack>
                {downLg ? null :
                    false ? <Box sx={{ mt: 2, width: '100%', p: 2, bgcolor: "background.fourth", borderRadius: 2, boxSizing: 'border-box' }}>
                        <Typography sx={{ fontWeight: 600, fontSize: "14px", color: "text.secondary" }}>
                            {t("crm.lead.lead.proposalSent")}
                        </Typography>
                        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"flex-end"}>
                            <Typography sx={{ fontWeight: 400, fontSize: "20px", color: "primary.main" }}>
                                $312,000
                            </Typography>
                            <Typography sx={{ fontWeight: 600, fontSize: "12px", color: "secondary.main", cursor: "pointer" }}>
                                {t("view")}
                            </Typography>
                        </Stack>
                    </Box> :
                        <Box sx={{ mt: 2, width: '100%', p: 2, bgcolor: "background.fourth", borderRadius: 2, boxSizing: 'border-box' }}>
                            <Typography sx={{ fontWeight: 600, fontSize: "13px", color: "text.secondary", textAlign: "center" }}>
                                {t("crm.lead.lead.noProposalSent")}
                            </Typography>
                        </Box>
                }
            </Stack>


            {/* Contact Details */}
            <Stack direction={{ xs: "row", lg: "column" }} width={"100%"} gap={2} mt={2}>
                {downLg ? null : <Stack direction={"column"} width={"100%"} gap={0.5}>
                    <Typography sx={{ fontWeight: 600, fontSize: "16px", color: "text.primary" }}>
                        {t("crm.lead.lead.contactDetails")}
                    </Typography>
                    <Divider />
                </Stack>}
                <Stack direction={"row"} gap={1} alignItems={"center"} width={"100%"}>
                    <Avatar alt={props.leadDetailData?.fullName} src={""} sx={{ width: 40, height: 40 }} />
                    <Typography sx={{ fontWeight: 600, fontSize: "12px", color: "text.primary" }}>
                        {props.leadDetailData?.fullName}
                    </Typography>
                </Stack>
                <Stack direction={"row"} gap={1} alignItems={"center"} width={"100%"}>
                    <Box sx={{ width: 40, height: 40, bgcolor: "background.fourth", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Icons.EmailOutlined sx={{ color: "text.secondary" }} />
                    </Box>
                    <Stack direction={"column"}>
                        <Typography sx={{ fontWeight: 600, fontSize: "12px", color: "text.secondary" }}>
                            {t("crm.lead.lead.emailAddress")}
                        </Typography>
                        <Typography sx={{ fontWeight: 600, fontSize: "12px", color: "text.primary" }}>
                            {props.leadDetailData?.email}
                        </Typography>
                    </Stack>
                </Stack>
            </Stack>
            <Stack direction={{ xs: "row", lg: "column" }} width={"100%"} gap={2} mt={2}>
                <Stack direction={"row"} gap={1} alignItems={"center"} width={"100%"}>
                    <Box sx={{ width: 40, height: 40, bgcolor: "background.fourth", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Icons.PhoneOutlined sx={{ color: "text.secondary" }} />
                    </Box>
                    <Stack direction={"column"}>
                        <Typography sx={{ fontWeight: 600, fontSize: "12px", color: "text.secondary" }}>
                            {t("crm.lead.lead.phoneNumber")}
                        </Typography>
                        <Typography sx={{ fontWeight: 600, fontSize: "12px", color: "text.primary" }}>
                            {props.leadDetailData?.phoneNumber ?? "-"}
                        </Typography>
                    </Stack>
                </Stack>
                <Stack direction={"row"} gap={1} alignItems={"center"} width={"100%"}>
                    <Box sx={{ width: 40, height: 40, bgcolor: "background.fourth", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Icons.PublicOutlined sx={{ color: "text.secondary" }} />
                    </Box>
                    <Stack direction={"column"}>
                        <Typography sx={{ fontWeight: 600, fontSize: "12px", color: "text.secondary" }}>
                            {t("crm.lead.lead.website")}
                        </Typography>
                        <Typography sx={{ fontWeight: 600, fontSize: "12px", color: "text.primary" }}>
                            {props.leadDetailData?.website ?? "-"}
                        </Typography>
                    </Stack>
                </Stack>
                {downLg ? null : <Stack direction={"row"} gap={1} alignItems={"center"} width={"100%"}>
                    <Box sx={{ width: 40, height: 40, bgcolor: "background.fourth", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Icons.Tag sx={{ color: "text.secondary" }} />
                    </Box>
                    <Stack direction={"column"}>
                        <Typography sx={{ fontWeight: 600, fontSize: "12px", color: "text.secondary" }}>
                            {t("crm.lead.lead.tags")}
                        </Typography>
                        <Typography sx={{ fontWeight: 600, fontSize: "12px", color: "text.primary" }}>
                            {props.leadDetailData?.tags ? props.leadDetailData?.tags?.length > 0 ? props.leadDetailData?.tags?.map((tag) => tag).join(", ") : "-" : "-"}
                        </Typography>
                    </Stack>
                </Stack>}
            </Stack>


            {/* Assigneds */}
            {downLg ? null : <Stack direction={"column"} width={"100%"} gap={2} mt={2}>
                <Stack direction={"column"} width={"100%"} gap={0.5}>
                    <Typography sx={{ fontWeight: 600, fontSize: "16px", color: "text.primary" }}>
                        {t("crm.lead.lead.assignees")}
                    </Typography>
                    <Divider />
                </Stack>
                <Stack direction={"column"} width={"100%"} gap={1}>
                    {props.leadDetailData?.assigned && props.leadDetailData?.assigned?.length > 0 ? props.leadDetailData?.assigned?.map((assignee) => (
                        <Stack direction={"row"} gap={1} alignItems={"center"} width={"100%"} key={assignee?._id}>
                            <Avatar alt={assignee?.surname} src={assignee?.profileImage?.thumbnailPublicUrl} sx={{ width: 40, height: 40 }} />
                            <Typography sx={{ fontWeight: 600, fontSize: "12px", color: "text.primary" }}>
                                {assignee?.name} {assignee?.surname}
                            </Typography>
                        </Stack>
                    )) : <Typography sx={{ fontWeight: 600, fontSize: "12px", color: "text.secondary" }}>{t("crm.lead.lead.noAssignees")}</Typography>}
                </Stack>

            </Stack>}
            <Divider />
            <FilledButton
                title={t("crm.lead.lead.leadDetail")}
                onClick={() => navigate(viewLeadRoute(props.leadDetailData?._id ?? ""))}
                leftIcon={<Icons.OpenInBrowser sx={{ mr: 1 }} />}
            />




            {downLg ? null : <Stack direction={"column"} position={"absolute"} bottom={10} left={10} gap={0.5} alignItems={"flex-end"}>
                <Typography sx={{ fontWeight: 600, fontSize: "12px", color: "text.secondary" }}>
                    {/* {props.leadDetailData?.createdBy?.name?.slice(0, 1)}. {props.leadDetailData?.createdBy?.surname} {t("crm.lead.lead.hasCreatedAt")} {moment(props.leadDetailData?.createdAt).format("DD/MM/YYYY")}. */}
                    {t("crm.lead.lead.createdBy")}
                    {NameFactory.getFullName({
                        name: props.leadDetailData?.createdBy?.name,
                        surname: props.leadDetailData?.createdBy?.surname
                    })}
                    . {moment(props.leadDetailData?.createdAt).format("DD/MM/YYYY")}
                </Typography>
            </Stack>}
        </Stack>


        <ActionDialog
            open={openConvertForm}
            onClose={() => setOpenConvertForm(false)}
            title={t("crm.lead.lead.convertToCustomer")}
            handleClick={handleSubmit(handleConvertLead)}
            loading={customerCreateFromLeadLoading}
            buttonTitle={t("crm.lead.lead.convert")}
            width={500}
        >
            <Grid container spacing={2} my={1}>
                <Grid item xs={12}>
                    <InputLabel htmlFor="input.email" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>
                        {t("crm.lead.lead.email")}
                    </InputLabel>
                    <Controller
                        name="input.primaryContact.email"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                id="input.email"
                                size="small"
                                fullWidth
                                onChange={(e) => {
                                    setValue("input.primaryContact.email", e.target.value);
                                }}
                                error={!!(errors as any)?.input?.primaryContact?.email && touchedFields.input?.primaryContact?.email}
                                helperText={(touchedFields as any)?.input?.primaryContact?.email && (errors as any)?.input?.primaryContact?.email}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} >
                    <InputLabel htmlFor="type" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.lead.lead.customerType")}</InputLabel>
                    <Controller
                        name="input.type"
                        control={control}
                        render={({ field }) => (
                            <Autocomplete
                                id="type"
                                size="small"
                                fullWidth
                                isOptionEqualToValue={(option, value) => option._id === value._id}
                                options={Object.values(CustomerType).map((type) => ({
                                    name: localizedCustomerType[type as keyof typeof CustomerType],
                                    _id: type,
                                }))}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => <TextField {...params} />}
                                value={
                                    field.value
                                        ? { name: localizedCustomerType[field.value as keyof typeof CustomerType], _id: field.value }
                                        : null
                                }
                                onChange={(e, value) => {
                                    field.onChange(value?._id);
                                }}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputLabel htmlFor="input.primaryContact.firstName" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>
                        {t("crm.lead.lead.firstName")}
                    </InputLabel>
                    <Controller
                        name="input.primaryContact.firstName"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                id="input.primaryContact.firstName"
                                size="small"
                                fullWidth
                                onChange={(e) => {
                                    setValue("input.primaryContact.firstName", e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1));
                                }}
                                error={!!(errors as any)?.input?.primaryContact?.firstName && touchedFields.input?.primaryContact?.firstName}
                                helperText={(touchedFields as any)?.input?.primaryContact?.firstName && (errors as any)?.input?.primaryContact?.firstName}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputLabel htmlFor="input.primaryContact.lastName" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>
                        {t("crm.lead.lead.lastName")}
                    </InputLabel>
                    <Controller
                        name="input.primaryContact.lastName"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                id="input.primaryContact.lastName"
                                size="small"
                                fullWidth
                                onChange={(e) => {
                                    setValue("input.primaryContact.lastName", e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1));
                                }}
                                error={!!(errors as any)?.input?.primaryContact?.lastName && touchedFields.input?.primaryContact?.lastName}
                                helperText={(touchedFields as any)?.input?.primaryContact?.lastName && (errors as any)?.input?.primaryContact?.lastName}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputLabel htmlFor="input.primaryContact.phone" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>
                        {t("crm.lead.lead.phone")}
                    </InputLabel>
                    <Controller
                        name="input.primaryContact.phone"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                id="input.primaryContact.phone"
                                size="small"
                                fullWidth
                                onChange={(e) => {
                                    setValue("input.primaryContact.phone", e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1));
                                }}
                                error={!!(errors as any)?.input?.primaryContact?.phone && touchedFields.input?.primaryContact?.phone}
                                helperText={(touchedFields as any)?.input?.primaryContact?.phone && (errors as any)?.input?.primaryContact?.phone}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputLabel htmlFor="input.primaryContact.position" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>
                        {t("crm.lead.lead.position")}
                    </InputLabel>
                    <Controller
                        name="input.primaryContact.position"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                id="input.primaryContact.position"
                                size="small"
                                fullWidth
                                onChange={(e) => {
                                    setValue("input.primaryContact.position", e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1));
                                }}
                                error={!!(errors as any)?.input?.primaryContact?.position && touchedFields.input?.primaryContact?.position}
                                helperText={(touchedFields as any)?.input?.primaryContact?.position && (errors as any)?.input?.primaryContact?.position}
                            />
                        )}
                    />
                </Grid>
            </Grid>
        </ActionDialog>
    </Stack >
}

interface ILastContact {
    leadDetailData: ILeadDetailResult | undefined
}

const LastContact = (props: ILastContact) => {

    const { t } = useTranslation();
    const downLg = useMediaQuery((theme: any) => theme.breakpoints.down("lg"));
    const downSm = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));

    const localizedLeadTypes = convertEnums(LeadType);

    return <Stack direction={"column"} gap={1} width={"100%"} sx={{ borderBottom: "2px solid", borderColor: "grey.400", p: 2, boxSizing: "border-box" }}>
        {downLg ? null : <Stack direction="row" spacing={0} sx={{ borderRadius: '16px', overflow: 'hidden', mb: 1 }}>
            {/* {leadStatusesData?.data?.map((status, index) => (
            <Box
                key={status.name}
                sx={{
                    backgroundColor: status.name === leadDetailData?.status.name ? status?.color : 'primary.light',
                    display: 'flex',
                    alignItems: 'center',
                    position: 'relative',
                    justifyContent: 'center',
                    padding: '4px 16px',
                    pl: index !== 0 ? 3 : 1,
                    ...(index === 0 && { borderTopLeftRadius: '16px', borderBottomLeftRadius: '16px' }),
                    ...(index === leadStatusesData?.data?.length - 1 && { borderTopRightRadius: '16px', borderBottomRightRadius: '16px' }),
                }}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        display: index === leadStatusesData?.data?.length - 1 ? 'none' : 'block',
                        top: 0,
                        right: -6,
                        width: 0,
                        height: 0,
                        borderTop: '12px solid transparent',
                        borderBottom: '12px solid transparent',
                        borderLeft: `8px solid`,
                        borderLeftColor: 'background.secondary',
                        zIndex: 2
                    }}
                />
                <SvgIcon component={(Icons as any)[status.icon]} sx={{ fontSize: '16px', color: 'white', mr: 1 }} />
                <Typography variant="body2" fontSize={'12px'} sx={{ color: 'white', textTransform: 'capitalize' }}>
                    {status.name}
                </Typography>
            </Box>
        ))} */}

            {props.leadDetailData?.lastContactDate &&
                <Typography fontSize={"14px"} fontWeight={600} color={"text.primary"}>
                    {t("crm.lead.lead.lastContacted")}: {moment(props.leadDetailData?.lastContactDate).format("DD/MM/YYYY")} ({t("crm.lead.lead.total")}: {props.leadDetailData?.contactCount})
                </Typography>}
        </Stack>}
        <Grid container sx={{ mb: 2 }}>
            {downSm ? null : <Grid item xs={4}>
                <Stack direction={"row"} gap={1} alignItems={"center"} mt={1}>
                    <Box sx={{ width: 40, height: 40, bgcolor: "background.fourth", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Iconify icon={"fluent-mdl2:party-leader"} sx={{ color: "text.secondary" }} width={24} />
                    </Box>
                    <Stack direction={"column"} gap={0.5}>
                        <Typography fontSize={"12px"} fontWeight={600} color={"text.secondary"}>
                            {t("crm.lead.lead.leadType")}
                        </Typography>
                        <Box
                            sx={{
                                border: "1px solid",
                                borderRadius: '30px',
                                py: 0.5,
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                                px: 1,
                            }}
                        >
                            <Typography fontSize={"12px"} fontWeight={600} color={"text.primary"}>
                                {localizedLeadTypes[(props.leadDetailData as any)?.type as keyof typeof localizedLeadTypes] ?? ''}
                            </Typography>
                        </Box>
                    </Stack>
                </Stack>
            </Grid>}
            <Grid item xs={6} sm={4}>
                <Stack direction={"row"} gap={1} alignItems={"center"} mt={1}>
                    <Box sx={{ width: 40, height: 40, bgcolor: "background.fourth", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Iconify icon={"f7:status"} sx={{ color: "text.secondary" }} width={24} />                                                </Box>
                    <Stack direction={"column"} gap={0.5}>
                        <Typography fontSize={"12px"} fontWeight={600} color={"text.secondary"}>
                            {t("crm.lead.lead.status")}
                        </Typography>
                        <Box
                            sx={{
                                border: "1px solid",
                                borderColor: props.leadDetailData?.status?.color,
                                borderRadius: '30px',
                                py: 0.5,
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "flex-start",
                                px: 1,
                            }}
                        >
                            <SvgIcon component={(Icons as any)[(props.leadDetailData as any)?.status?.icon]} fontSize="small" sx={{ mr: 0.5, color: props.leadDetailData?.status?.color }} />
                            <Typography fontSize={"12px"} textAlign={"center"} fontWeight={"bold"} textTransform={"capitalize"} color={props.leadDetailData?.status.color}>
                                {props.leadDetailData?.status?.name}
                            </Typography>
                        </Box>
                    </Stack>
                </Stack>
            </Grid>
            <Grid item xs={6} sm={4}>
                <Stack direction={"row"} gap={1} alignItems={"center"} mt={1}>
                    <Box sx={{ width: 40, height: 40, bgcolor: "background.fourth", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Iconify icon={"tabler:source-code"} sx={{ color: "text.secondary" }} width={24} />
                    </Box>
                    <Stack direction={"column"} gap={0.5}>
                        <Typography fontSize={"12px"} fontWeight={600} color={"text.secondary"}>
                            {t("crm.lead.lead.source")}
                        </Typography>
                        <Box
                            sx={{
                                border: "1px solid",
                                borderColor: props.leadDetailData?.source?.color,
                                borderRadius: '30px',
                                py: 0.5,
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "flex-start",
                                px: 1,
                            }}
                        >
                            <SvgIcon component={(Icons as any)[(props.leadDetailData as any)?.source?.icon]} fontSize="small" sx={{ mr: 0.5, color: props.leadDetailData?.source?.color }} />
                            <Typography fontSize={"12px"} textAlign={"center"} fontWeight={"bold"} textTransform={"capitalize"} color={props.leadDetailData?.source?.color}>
                                {props.leadDetailData?.source?.name}
                            </Typography>
                        </Box>
                    </Stack>
                </Stack>
            </Grid>
        </Grid>
    </Stack>
}

interface IInfoTab {
    leadDetailData: ILeadDetailResult | undefined
    selectedTabName: string
}

const InfoTab = (props: IInfoTab) => {

    const { t } = useTranslation();
    const localizedLanguages = convertEnums(Language);
    const [openAddLeadContact, setOpenAddLeadContact] = useState(false);


    return <Box sx={{ overflow: "auto" }}>

        <Stack direction={"column"} sx={{ px: 2, py: 1, mt: 1, mb: 2, position: "relative" }}>
            <Typography sx={{ fontWeight: 600, fontSize: "16px", color: "text.primary" }}>
                {t("crm.lead.lead.company")}
            </Typography>
            <Grid container>
                <Grid item xs={6}>
                    <Stack direction={"row"} gap={2} mt={1} alignItems={"flex-start"} justifyContent={"space-between"} sx={{ width: "100%", zIndex: 1 }}>
                        <Stack direction={"row"} gap={2} alignItems={"center"} justifyContent={"flex-start"}>
                            <Box sx={{ width: 30, height: 30, bgcolor: "secondary.main", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Icons.BusinessCenterOutlined sx={{ color: "primary.lighter", fontSize: "medium" }} />
                            </Box>
                            <Stack direction={"column"}>
                                <Typography fontSize={"12px"} fontWeight={600} color={"text.primary"}>
                                    {t("crm.lead.lead.companyName")}
                                </Typography>
                                <Typography fontSize={"11px"} fontWeight={400} color={"text.secondary"}>
                                    {props.leadDetailData?.company ?? '-'}
                                </Typography>
                            </Stack>
                        </Stack>
                    </Stack>
                    <Stack direction={"row"} gap={2} mt={2} alignItems={"flex-start"} justifyContent={"space-between"} sx={{ width: "100%", zIndex: 1 }}>
                        <Stack direction={"row"} gap={2} alignItems={"flex-start"} justifyContent={"flex-start"}>
                            <Box sx={{ width: 30, height: 30, bgcolor: "primary.light", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Icons.KeyboardDoubleArrowRight sx={{ color: "primary.lighter", fontSize: "medium" }} />
                            </Box>
                            <Stack direction={"column"}>
                                <Typography fontSize={"12px"} fontWeight={600} color={"text.primary"}>
                                    {t("crm.lead.lead.sector")}
                                </Typography>
                                <Typography fontSize={"11px"} fontWeight={400} color={"text.secondary"}>
                                    {props.leadDetailData?.sector ?? '-'}
                                </Typography>
                            </Stack>
                        </Stack>
                    </Stack>
                </Grid>
                <Grid item xs={6}>
                    <Stack direction={"row"} gap={2} mt={1} alignItems={"flex-start"} justifyContent={"space-between"} sx={{ width: "100%", zIndex: 1 }}>
                        <Stack direction={"row"} gap={2} alignItems={"center"} justifyContent={"flex-start"}>
                            <Box sx={{ width: 30, height: 30, bgcolor: "secondary.main", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Icons.RadarOutlined sx={{ color: "primary.lighter", fontSize: "medium" }} />
                            </Box>
                            <Stack direction={"column"}>
                                <Typography fontSize={"12px"} fontWeight={600} color={"text.primary"}>
                                    {t("crm.lead.lead.position")}
                                </Typography>
                                <Typography fontSize={"11px"} fontWeight={400} color={"text.secondary"}>
                                    {props.leadDetailData?.position ?? '-'}
                                </Typography>
                            </Stack>
                        </Stack>
                    </Stack>
                    <Stack direction={"row"} gap={2} mt={2} alignItems={"flex-start"} justifyContent={"space-between"} sx={{ width: "100%", zIndex: 1 }}>
                        <Stack direction={"row"} gap={2} alignItems={"flex-start"} justifyContent={"flex-start"}>
                            <Box sx={{ width: 30, height: 30, bgcolor: "primary.light", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Icons.PublicOutlined sx={{ color: "primary.lighter", fontSize: "medium" }} />
                            </Box>
                            <Stack direction={"column"}>
                                <Typography fontSize={"12px"} fontWeight={600} color={"text.primary"}>
                                    {t("crm.lead.lead.website")}
                                </Typography>
                                <Typography fontSize={"11px"} fontWeight={400} color={"text.secondary"}>
                                    {props.leadDetailData?.website ?? '-'}
                                </Typography>
                            </Stack>
                        </Stack>
                    </Stack>
                </Grid>
            </Grid>
        </Stack>

        {/* Localization */}

        <Stack direction={"column"} sx={{ px: 2, py: 1, mt: 1, mb: 2, position: "relative" }}>
            <Typography sx={{ fontWeight: 600, fontSize: "16px", color: "text.primary" }}>
                {t("crm.lead.lead.localization")}
            </Typography>
            <Grid container>
                <Grid item xs={6}>
                    <Stack direction={"row"} gap={2} mt={1} alignItems={"flex-start"} justifyContent={"space-between"} sx={{ width: "100%", zIndex: 1 }}>
                        <Stack direction={"row"} gap={2} alignItems={"center"} justifyContent={"flex-start"}>
                            <Box sx={{ width: 30, height: 30, bgcolor: "secondary.main", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Icons.RoomOutlined sx={{ color: "primary.lighter", fontSize: "medium" }} />
                            </Box>
                            <Stack direction={"column"}>
                                <Typography fontSize={"12px"} fontWeight={600} color={"text.primary"}>
                                    {t("crm.lead.lead.country")}
                                </Typography>
                                <Typography fontSize={"11px"} fontWeight={400} color={"text.secondary"}>
                                    {props.leadDetailData?.country ?? '-'}
                                </Typography>
                            </Stack>
                        </Stack>
                    </Stack>
                    <Stack direction={"row"} gap={2} mt={2} alignItems={"flex-start"} justifyContent={"space-between"} sx={{ width: "100%", zIndex: 1 }}>
                        <Stack direction={"row"} gap={2} alignItems={"flex-start"} justifyContent={"flex-start"}>
                            <Box sx={{ width: 30, height: 30, bgcolor: "primary.light", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Icons.GpsFixedOutlined sx={{ color: "primary.lighter", fontSize: "medium" }} />
                            </Box>
                            <Stack direction={"column"}>
                                <Typography fontSize={"12px"} fontWeight={600} color={"text.primary"}>
                                    {t("crm.lead.lead.state")}
                                </Typography>
                                <Typography fontSize={"11px"} fontWeight={400} color={"text.secondary"}>
                                    {props.leadDetailData?.state ?? '-'}
                                </Typography>
                            </Stack>
                        </Stack>
                    </Stack>
                </Grid>
                <Grid item xs={6}>
                    <Stack direction={"row"} gap={2} mt={1} alignItems={"flex-start"} justifyContent={"space-between"} sx={{ width: "100%", zIndex: 1 }}>
                        <Stack direction={"row"} gap={2} alignItems={"center"} justifyContent={"flex-start"}>
                            <Box sx={{ width: 30, height: 30, bgcolor: "secondary.main", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Icons.PinDropOutlined sx={{ color: "primary.lighter", fontSize: "medium" }} />
                            </Box>
                            <Stack direction={"column"}>
                                <Typography fontSize={"12px"} fontWeight={600} color={"text.primary"}>
                                    {t("crm.lead.lead.city")}
                                </Typography>
                                <Typography fontSize={"11px"} fontWeight={400} color={"text.secondary"}>
                                    {props.leadDetailData?.city ?? '-'}
                                </Typography>
                            </Stack>
                        </Stack>
                    </Stack>
                    <Stack direction={"row"} gap={2} mt={2} alignItems={"flex-start"} justifyContent={"space-between"} sx={{ width: "100%", zIndex: 1 }}>
                        <Stack direction={"row"} gap={2} alignItems={"flex-start"} justifyContent={"flex-start"}>
                            <Box sx={{ width: 30, height: 30, bgcolor: "primary.light", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Icons.LanguageOutlined sx={{ color: "primary.lighter", fontSize: "medium" }} />
                            </Box>
                            <Stack direction={"column"}>
                                <Typography fontSize={"12px"} fontWeight={600} color={"text.primary"}>
                                    {t("crm.lead.lead.language")}
                                </Typography>
                                <Typography fontSize={"11px"} fontWeight={400} color={"text.secondary"}>
                                    {localizedLanguages[(props.leadDetailData as any)?.language as keyof typeof localizedLanguages] ?? ''}
                                </Typography>
                            </Stack>
                        </Stack>
                    </Stack>
                </Grid>
            </Grid>
        </Stack>

        {/* Last Contact */}
        <Stack direction={"column"} sx={{ px: 2, py: 1, mb: 2, position: "relative" }}>
            <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                <Typography sx={{ fontWeight: 600, fontSize: "16px", color: "text.primary" }}>
                    {t("crm.lead.lead.lastContact")}
                </Typography>
                <OutlinedButton
                    title={t("crm.lead.lead.addContact")}
                    leftIcon={<Icons.Add sx={{ mr: 1 }} />}
                    onClick={() => setOpenAddLeadContact(true)}
                />
            </Stack>
            {props.leadDetailData?.lastContact ? <Box sx={{ width: "100%", border: "2px solid", borderColor: "grey.400", borderRadius: 2, p: 2, mt: 1, boxSizing: "border-box" }}>
                <Grid container>
                    <Grid item xs={4}>
                        <Stack direction={"column"} gap={1}>
                            <Typography fontSize={"12px"} fontWeight={600} color={"secondary.main"}>
                                {moment(props.leadDetailData?.lastContact?.contactedDate).format("dddd")}
                            </Typography>
                            <Typography fontSize={"15px"} fontWeight={500} color={"text.primary"}>
                                {moment(props.leadDetailData?.lastContact?.contactedDate).format("DD MMMM YYYY")}
                            </Typography>
                            <Typography fontSize={"12px"} fontWeight={500} color={"text.secondary"}>
                                {moment(props.leadDetailData?.lastContact?.contactedDate).format("HH:mm")}
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={8} sx={{ borderLeft: "1px solid", borderColor: "grey.400", pl: 2 }}>
                        <Stack direction={"column"} gap={1}>
                            <Stack direction={"row"} alignItems={"center"} gap={0.5}>
                                <Icons.Circle color='secondary' sx={{ fontSize: "10px", ml: 0.5 }} />
                                <Typography fontSize={"12px"} fontWeight={400}>
                                    {parse(props.leadDetailData?.lastContact?.note ?? "")}
                                </Typography>
                            </Stack>
                            <Stack direction={"row"} alignItems={"center"} gap={0.5}>
                                <Avatar alt={props.leadDetailData?.fullName} src={props?.leadDetailData?.lastContact?.contacted?.profileImage?.thumbnailPublicUrl} sx={{ width: 15, height: 15 }} />
                                <Typography fontSize={"12px"} fontWeight={400}>
                                    {props.leadDetailData?.lastContact?.contacted?.name} {" "} {props.leadDetailData?.lastContact?.contacted?.surname}
                                </Typography>
                            </Stack>
                        </Stack>
                    </Grid>
                </Grid>
            </Box> :
                <Box sx={{ width: "100%", p: 2, mt: 1, boxSizing: "border-box", display: 'flex', flexDirection: 'column', gap: 1, justifyContent: "center", alignItems: "center" }}>
                    <Typography fontSize={"14px"} fontWeight={400} textAlign={"center"} color={"text.secondary"}>
                        {t("crm.lead.lead.emptyStateContactContent1")}
                    </Typography>
                    <OutlinedButton
                        title={t("crm.lead.lead.addContact")}
                        onClick={() => setOpenAddLeadContact(true)}
                        color='secondary'
                    />
                </Box>
            }
        </Stack>


        {/* Appointments */}
        <Stack direction={"column"} sx={{ px: 2, py: 1, mb: 2, position: "relative" }}>
            <Typography sx={{ fontWeight: 600, fontSize: "16px", color: "text.primary" }}>
                {t("crm.lead.lead.appointments")}
            </Typography>
            {false ? <Box sx={{ width: "100%", border: "2px solid", borderColor: "grey.400", borderRadius: 2, p: 2, mt: 1, boxSizing: "border-box" }}>
                {/* <Grid container>
                    <Grid item xs={4}>
                        <Stack direction={"column"} gap={1}>
                            <Typography fontSize={"12px"} fontWeight={600} color={"secondary.main"}>
                                Monday
                            </Typography>
                            <Typography fontSize={"15px"} fontWeight={500} color={"text.primary"}>
                                September 3, 2024
                            </Typography>
                            <Typography fontSize={"12px"} fontWeight={500} color={"text.secondary"}>
                                10 AM | 10.30 AM
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={8} sx={{ borderLeft: "1px solid", borderColor: "grey.400", pl: 2 }}>
                        <Stack direction={"column"} gap={1}>
                            <Stack direction={"row"} alignItems={"center"} gap={0.5}>
                                <Icons.Circle color='secondary' sx={{ fontSize: "10px", ml: 0.5 }} />
                                <Typography fontSize={"12px"} fontWeight={400}>
                                    On site estimate with <strong>User One</strong>
                                </Typography>
                            </Stack>
                            <Stack direction={"row"} alignItems={"center"} gap={0.5}>
                                <Icons.LocationOnOutlined sx={{ fontSize: "15px", color: "primary.light" }} />
                                <Typography fontSize={"12px"} fontWeight={400}>
                                    4517 Bornova, İzmir Türkiye
                                </Typography>
                            </Stack>
                            <Stack direction={"row"} alignItems={"center"} gap={0.5}>
                                <Avatar alt={props.leadDetailData?.fullName} src={"https://loremflickr.com/480/480"} sx={{ width: 15, height: 15 }} />
                                <Typography fontSize={"12px"} fontWeight={400}>
                                    Selçuk Madra
                                </Typography>
                            </Stack>
                        </Stack>
                    </Grid>
                </Grid> */}
            </Box> :
                <Box sx={{ width: "100%", p: 2, mt: 1, boxSizing: "border-box", display: 'flex', flexDirection: 'column', gap: 1, justifyContent: "center", alignItems: "center" }}>
                    <Typography fontSize={"14px"} fontWeight={400} textAlign={"center"} color={"text.secondary"}>
                        {t("crm.lead.lead.noAppointments")}
                    </Typography>
                    <OutlinedButton
                        title={t("crm.lead.lead.makeAnAppointment")}
                        onClick={() => { }}
                        disabled
                        color='secondary'
                    />
                </Box>
            }
        </Stack>
        <AddLeadContactDialog
            open={openAddLeadContact}
            onClose={() => setOpenAddLeadContact(false)}
            item={props?.leadDetailData}
        />
    </Box>

}


interface IEstimatesTab {
    selectedTabName: string
    leadDetailData: ILeadDetailResult | undefined
}

const EstimatesTab = (props: IEstimatesTab) => {

    const { t } = useTranslation()
    const { data: estimateListData } = useEstimateListQuery(
        {
            input: {
                filter: {
                    prospectTargetEntityId: props.leadDetailData?._id,
                    prospectTargetEntityRef: EstimateProspectTargetEntityRef.Lead,
                },
                pagination: {
                    page: 1,
                    pageSize: 1
                }
            }
        },
        { skip: props.selectedTabName !== t("crm.lead.lead.estimates") })

    return <Grid container mt={2} gap={2} mb={2}>
        <Grid item xs={12}>
            <DetailBox title={t("crm.lead.lead.estimates")}
                type="drawer">
                <Grid item xs={12} display="flex" flexDirection="column" gap={1}>
                    {estimateListData ? estimateListData?.data?.[0] ? <Box sx={{ width: "100%", border: "2px solid", borderColor: "grey.400", borderRadius: 2, p: 2, mt: 1, boxSizing: "border-box" }}>
                        <Grid container>
                            <Grid item xs={7} >
                                <Stack direction={"column"} gap={1}>
                                    <Stack direction={"row"} alignItems={"center"} gap={0.5}>
                                        <Icons.Circle color='secondary' sx={{ fontSize: "10px", ml: 0.5 }} />
                                        <Typography fontSize={"12px"} fontWeight={400}>
                                            {t("crm.lead.lead.prospectName")} <strong>{estimateListData?.data?.[0]?.prospectName}</strong>
                                        </Typography>
                                    </Stack>
                                    <Stack direction={"row"} alignItems={"center"} gap={0.5}>
                                        <Icons.LocationOnOutlined sx={{ fontSize: "15px", color: "primary.light" }} />
                                        <Typography fontSize={"12px"} fontWeight={400}>
                                            {estimateListData?.data?.[0]?.prospectCountry}, {estimateListData?.data?.[0]?.prospectState}, {estimateListData?.data?.[0]?.prospectCity}
                                        </Typography>
                                    </Stack>
                                    {estimateListData?.data?.[0]?.salesAgents?.[0] && estimateListData?.data?.[0]?.salesAgents?.length > 0 && estimateListData?.data?.[0]?.salesAgents?.map((salesAgent: any) =>
                                        <Stack direction={"row"} alignItems={"center"} gap={0.5}>
                                            <Avatar alt={salesAgent?.name} src={salesAgent?.profileImage?.thumbnailPublicUrl} sx={{ width: 15, height: 15 }} />
                                            <Typography fontSize={"12px"} fontWeight={400}>
                                                {salesAgent?._id}
                                                {/* todo name surname kime göre gelsin?  */}
                                            </Typography>
                                        </Stack>)
                                    }
                                </Stack>
                            </Grid>
                            <Grid item xs={5} sx={{ borderLeft: "1px solid", borderColor: "grey.400", pl: 2 }}>
                                <Stack direction={"column"} gap={1}>
                                    <Typography fontSize={"12px"} fontWeight={600} color={"secondary.main"}>
                                        {moment(estimateListData?.data?.[0]?.date).format("dddd")}
                                    </Typography>
                                    <Typography fontSize={"15px"} fontWeight={500} color={"text.primary"}>
                                        {moment(estimateListData?.data?.[0]?.date).format("MMMM DD, YYYY")}
                                    </Typography>
                                    <Typography fontSize={"12px"} fontWeight={500} color={"text.secondary"}>
                                        {moment(estimateListData?.data?.[0]?.date).format("hh:mm A")}
                                    </Typography>
                                </Stack>
                            </Grid>
                        </Grid> </Box>
                        :
                        <Box sx={{ width: "100%", boxSizing: "border-box", display: 'flex', flexDirection: 'column', gap: 1, justifyContent: "center", alignItems: "center" }}>
                            <EmptyState content1={t("crm.lead.lead.noEstimate")} />
                        </Box>
                        : null}
                </Grid>
            </DetailBox>
        </Grid>
    </Grid>
}


interface IProposalsTab {
    selectedTabName: string
    leadDetailData: ILeadDetailResult | undefined
}

const ProposalsTab = (props: IProposalsTab) => {

    const { t } = useTranslation()
    const { data: proposalListData } = useProposalListQuery(
        {
            input: {
                filter: {
                    prospectTargetEntityId: props.leadDetailData?._id,
                    prospectTargetEntityRef: ProposalProspectTargetEntityRef.Lead
                },
                pagination: {
                    page: 1,
                    pageSize: 1
                }
            }
        },
        { skip: props.selectedTabName !== t("crm.lead.lead.proposals") })

    return <Grid container mt={2} gap={2} mb={2}>
        <Grid item xs={12}>
            <DetailBox title={t("crm.lead.lead.proposals")}
                type="drawer">
                <Grid item xs={12} display="flex" flexDirection="column" gap={1}>
                    {proposalListData ? proposalListData?.data?.[0] ? <Box sx={{ width: "100%", border: "2px solid", borderColor: "grey.400", borderRadius: 2, p: 2, mt: 1, boxSizing: "border-box" }}>
                        <Grid container>
                            <Grid item xs={7} >
                                <Stack direction={"column"} gap={1}>
                                    <Stack direction={"row"} alignItems={"center"} gap={0.5}>
                                        <Icons.Circle color='secondary' sx={{ fontSize: "10px", ml: 0.5 }} />
                                        <Typography fontSize={"12px"} fontWeight={400}>
                                            {t("crm.lead.lead.prospectName")} <strong>{proposalListData?.data?.[0]?.prospectName}</strong>
                                        </Typography>
                                    </Stack>
                                    <Stack direction={"row"} alignItems={"center"} gap={0.5}>
                                        <Icons.LocationOnOutlined sx={{ fontSize: "15px", color: "primary.light" }} />
                                        <Typography fontSize={"12px"} fontWeight={400}>
                                            {proposalListData?.data?.[0]?.prospectCountry}, {proposalListData?.data?.[0]?.prospectState}, {proposalListData?.data?.[0]?.prospectCity}
                                        </Typography>
                                    </Stack>
                                    {proposalListData?.data?.[0]?.salesAgents?.[0] && proposalListData?.data?.[0]?.salesAgents?.length > 0 && proposalListData?.data?.[0]?.salesAgents?.map((salesAgent: any) =>
                                        <Stack direction={"row"} alignItems={"center"} gap={0.5}>
                                            <Avatar alt={salesAgent?.name} src={salesAgent?.profileImage?.thumbnailPublicUrl} sx={{ width: 15, height: 15 }} />
                                            <Typography fontSize={"12px"} fontWeight={400}>
                                                {salesAgent?._id}
                                                {/* todo name surname kime göre gelsin?  */}
                                            </Typography>
                                        </Stack>)
                                    }
                                </Stack>
                            </Grid>
                            <Grid item xs={5} sx={{ borderLeft: "1px solid", borderColor: "grey.400", pl: 2 }}>
                                <Stack direction={"column"} gap={1}>
                                    <Typography fontSize={"12px"} fontWeight={600} color={"secondary.main"}>
                                        {moment(proposalListData?.data?.[0].updatedAt).format("dddd")}
                                    </Typography>
                                    <Typography fontSize={"15px"} fontWeight={500} color={"text.primary"}>
                                        {moment(proposalListData?.data?.[0]?.updatedAt).format("MMMM DD, YYYY")}
                                    </Typography>
                                    <Typography fontSize={"12px"} fontWeight={500} color={"text.secondary"}>
                                        {moment(proposalListData?.data?.[0]?.updatedAt).format("hh:mm A")}
                                    </Typography>
                                </Stack>
                            </Grid>
                        </Grid> </Box>
                        :
                        <Box sx={{ width: "100%", boxSizing: "border-box", display: 'flex', flexDirection: 'column', gap: 1, justifyContent: "center", alignItems: "center" }}>
                            <EmptyState content1={t("crm.lead.lead.noProposal")} />
                        </Box> : null}
                </Grid>
            </DetailBox>
        </Grid>
    </Grid>
}

interface IDocumentsTab {
    selectedTabName: string
    leadDetailData: ILeadDetailResult | undefined
}

const DocumentsTab = (props: IDocumentsTab) => {

    const { t } = useTranslation()

    return <Grid container mt={2} gap={2} mb={2}>
        <Grid item xs={12}>
            <DetailBox title={t("crm.lead.lead.documents")}
                type="drawer">
                <Grid item xs={12} display="flex" flexDirection="column" gap={1}>
                    <Stack direction="row" justifyContent="space-between" height={"100%"} mb={2}>
                        <EmptyState content1={t("crm.lead.lead.noDocument")} />
                    </Stack>
                </Grid>
            </DetailBox>
        </Grid>
    </Grid >
}

interface IActivitiesTab {
    selectedTabName: string
    leadDetailData: ILeadDetailResult | undefined
}

const ActivitiesTab = (props: IActivitiesTab) => {

    const { t } = useTranslation()

    return <Grid container mt={2} gap={2} mb={2}>
        <Grid item xs={12}>
            {true ? <DetailBox title={t("crm.lead.lead.activities")}
                type="drawer">
                <Grid item xs={12} display="flex" flexDirection="column" gap={1}>
                    <Stack direction="row" justifyContent="space-between" height={"100%"} mb={2}>
                        <EmptyState content1={t("crm.lead.lead.noActivity")} />
                    </Stack>
                </Grid>
            </DetailBox> :
                <Stack direction={"column"} sx={{ px: 2, py: 1, mt: 1, mb: 2, position: "relative" }}>
                    <Box height={"45%"} borderLeft={"1px dashed"} borderColor={"grey.400"} position={"absolute"} top={60} left={30} zIndex={0} />
                    <Typography sx={{ fontWeight: 600, fontSize: "16px", color: "text.primary" }}>
                        Latest Activity
                    </Typography>
                    <Stack direction={"row"} gap={2} mt={1} alignItems={"flex-start"} justifyContent={"space-between"} sx={{ width: "100%", zIndex: 1 }}>
                        <Stack direction={"row"} gap={2} alignItems={"flex-start"} justifyContent={"flex-start"}>
                            <Box sx={{ width: 30, height: 30, bgcolor: "secondary.main", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Icons.EmailRounded sx={{ color: "primary.lighter", fontSize: "medium" }} />
                            </Box>
                            <Stack direction={"column"} gap={0.5}>
                                <Typography fontSize={"12px"} fontWeight={600} mt={0.5} color={"text.primary"}>
                                    Email delivered: Proposal sent
                                </Typography>
                                <Typography fontSize={"11px"} fontWeight={400} color={"text.secondary"}>
                                    Proposal has been sent to the client. We'll be in touch soon!
                                </Typography>
                            </Stack>
                        </Stack>
                        <Typography fontSize={"11px"} fontWeight={400} mt={0.5} color={"text.secondary"}>
                            1 day ago
                        </Typography>
                    </Stack>
                    <Stack direction={"row"} gap={2} mt={2} alignItems={"flex-start"} justifyContent={"space-between"} sx={{ width: "100%", zIndex: 1 }}>
                        <Stack direction={"row"} gap={2} alignItems={"flex-start"} justifyContent={"flex-start"}>
                            <Box sx={{ width: 30, height: 30, bgcolor: "primary.light", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Icons.KeyboardDoubleArrowRight sx={{ color: "primary.lighter", fontSize: "medium" }} />
                            </Box>
                            <Stack direction={"column"} gap={0.5}>
                                <Typography fontSize={"12px"} fontWeight={600} mt={0.5} color={"text.primary"}>
                                    Stage request: Request recieved
                                </Typography>
                                <Typography fontSize={"11px"} fontWeight={400} color={"text.secondary"}>
                                    Proposal has been sent to the client.
                                </Typography>
                            </Stack>
                        </Stack>
                        <Typography fontSize={"11px"} fontWeight={400} mt={0.5} color={"text.secondary"}>
                            2 day ago
                        </Typography>
                    </Stack>
                </Stack>
            }
        </Grid>
    </Grid >
}

interface INotesTab {
    selectedTabName: string
    leadDetailData: ILeadDetailResult | undefined
}

const NotesTab = (props: INotesTab) => {

    const { t } = useTranslation();
    const [openNoteCreateDialog, setOpenNoteCreateDialog] = useState(false);
    const [openNoteUpdateDialog, setOpenNoteUpdateDialog] = useState(false);
    const [openNoteDeleteDialog, setOpenNoteDeleteDialog] = useState(false);
    const [selectedLeadNote, setSelectedLeadNote] = useState<INoteListItemResult>();
    const { data: noteListData, isLoading: noteListLoading } = useNoteListQuery({
        input: {
            filter: {
                targetIds: [props.leadDetailData?._id ?? ""],
                targetType: NoteTargetType.Lead
            }
        }
    });
    const [noteUpdate] = useNoteUpdateMutation();

    return <Grid container mt={2} gap={2} mb={2}>
        <Grid item xs={12}>
            <DetailBox title={t("crm.lead.lead.notes")}
                type="drawer"
                rightButton={{
                    title: t("crm.lead.lead.addNote"),
                    onClick: () => setOpenNoteCreateDialog(true),
                    icon: <Icons.AddCircle sx={{ mr: 1 }} />,
                }}>
                <Grid item xs={12} display="flex" flexDirection="column" gap={1}>
                    <Stack direction="row" justifyContent="space-between" height={"100%"} mb={2}>
                        {noteListData ?
                            noteListData?.count > 0 ? <BaseDataGrid
                                rows={
                                    noteListData?.data?.map((note, index) => ({
                                        id: (index + 1),
                                        _id: note?._id,
                                        title: representString(note?.title),
                                        content: representString(note?.content),
                                        createdAt: representDateTimeAsString(note?.createdAt),
                                    }))
                                }
                                columns={[
                                    {
                                        config: {
                                            baseGridColType: BaseGridColType.text,
                                            column: {
                                                field: "id",
                                                disableColumnMenu: true,
                                                filterable: false,
                                                width: 40,
                                                align: "center",
                                            },
                                            headerConfig: {
                                                name: "#",
                                                align: "center",
                                            },
                                        },
                                    },
                                    {
                                        config: {
                                            baseGridColType: BaseGridColType.text,
                                            column: {
                                                field: "title",
                                                editable: false,
                                                filterable: false,
                                            },
                                            headerConfig: {
                                                name: t("crm.lead.lead.noteTitle"),
                                            },
                                        },
                                    },
                                    {
                                        config: {
                                            baseGridColType: BaseGridColType.text,
                                            column: {
                                                field: "content",
                                                editable: false,
                                                filterable: false,
                                                width: 250,
                                            },
                                            headerConfig: {
                                                name: t("crm.lead.lead.content"),
                                            },
                                        },
                                    },
                                    {
                                        config: {
                                            baseGridColType: BaseGridColType.text,
                                            column: {
                                                field: "createdAt",
                                                editable: false,
                                                filterable: false,
                                            },
                                            headerConfig: {
                                                name: t("crm.lead.lead.createdAt"),
                                            },
                                        },
                                    },
                                ]}
                                actionColumn={{
                                    width: 70,
                                    defaultActions: {
                                        edit: {
                                            clickConfig: {
                                                setSelectedRow: setSelectedLeadNote,
                                                setOpenAction: setOpenNoteUpdateDialog,
                                            },
                                        },
                                        delete: {
                                            clickConfig: {
                                                setSelectedRow: setSelectedLeadNote,
                                                setOpenAction: setOpenNoteDeleteDialog,
                                            },
                                        },
                                    },
                                    actionHeaderName: t("crm.actions"),
                                }}
                                loading={noteListLoading}
                                listFilter={{
                                    filterInput: {},
                                    setFilterInput: () => null,
                                }}
                                count={noteListData?.count}
                                config={{
                                    columnVisibilityModel: {},
                                    features: []
                                }}
                                update={{
                                    updateQuery: noteUpdateQuery,
                                }}
                                disableColumnFilter={true}
                                hideFooterPagination={true}
                            /> :
                                <EmptyState content1={t("crm.lead.lead.emptyStateNoteList1")} />
                            : null
                        }
                    </Stack>
                </Grid>
            </DetailBox>
        </Grid>

        <NoteCreateDialog
            open={openNoteCreateDialog}
            setOpen={setOpenNoteCreateDialog}
            target={{ id: props.leadDetailData?._id ?? "", name: props.leadDetailData?.fullName ?? "" }}
            targetType={NoteTargetType.Lead}
        />

        <NoteUpdateDialog
            open={openNoteUpdateDialog}
            setOpen={setOpenNoteUpdateDialog}
            target={{ id: props.leadDetailData?._id ?? "", name: props.leadDetailData?.fullName ?? "" }}
            selectedNote={selectedLeadNote}
        />

        <NoteDeleteDialog
            open={openNoteDeleteDialog}
            onClose={() => {
                setSelectedLeadNote(undefined);
                setOpenNoteDeleteDialog(false);
            }}
            item={selectedLeadNote}
        />

    </Grid >

}

interface IRemindersTab {
    selectedTabName: string
    leadDetailData: ILeadDetailResult | undefined
}

const RemindersTab = (props: IRemindersTab) => {

    const { t } = useTranslation();
    const [openReminderCreateDialog, setOpenReminderCreateDialog] = useState(false);
    const [openReminderUpdateDialog, setOpenReminderUpdateDialog] = useState(false);
    const [openReminderDeleteDialog, setOpenReminderDeleteDialog] = useState(false);
    const [selectedLeadReminder, setSelectedLeadReminder] = useState<IReminderListItemResult>();
    const { data: reminderListData, isLoading: reminderListLoading } = useReminderListQuery({
        input: {
            filter: {
                targetIds: [props.leadDetailData?._id ?? ""],
                targetTypes: [ReminderTargetType.Lead]
            }
        }
    });
    const [reminderUpdate] = useReminderUpdateMutation();

    return <Grid container mt={2} gap={2} mb={2}>
        <Grid item xs={12}>
            <DetailBox title={t("crm.lead.lead.reminders")}
                type="drawer"
                rightButton={{
                    title: t("crm.lead.lead.addReminder"),
                    onClick: () => setOpenReminderCreateDialog(true),
                    icon: <Icons.AddCircle sx={{ mr: 1 }} />,
                }}>

                <Grid item xs={12} display="flex" flexDirection="column" gap={1}>
                    <Stack direction="row" justifyContent="space-between" height={"100%"} mb={2}>
                        {reminderListData ?
                            reminderListData?.count > 0 ? <BaseDataGrid
                                rows={
                                    reminderListData?.data?.map((reminder, index) => ({
                                        id: (index + 1),
                                        _id: reminder?._id,
                                        title: representString(reminder?.title),
                                        content: representString(reminder?.content),
                                        priority: reminder?.priority,
                                        remindDate: representDateTimeAsString(reminder?.remindDate),
                                        remindUsers: reminder?.remindUsers,
                                        status: reminder?.status,
                                        tags: reminder?.tags,
                                    }))
                                }
                                columns={[
                                    {
                                        config: {
                                            baseGridColType: BaseGridColType.text,
                                            column: {
                                                field: "id",
                                                disableColumnMenu: true,
                                                filterable: false,
                                                width: 40,
                                                align: "center",
                                            },
                                            headerConfig: {
                                                name: "#",
                                                align: "center",
                                            },
                                        },
                                    },
                                    {
                                        config: {
                                            baseGridColType: BaseGridColType.text,
                                            column: {
                                                field: "title",
                                                editable: false,
                                                filterable: false,
                                            },
                                            headerConfig: {
                                                name: t("crm.lead.lead.remindTitle"),
                                            },
                                        },
                                    },
                                    {
                                        config: {
                                            baseGridColType: BaseGridColType.text,
                                            column: {
                                                field: "content",
                                                editable: false,
                                                filterable: false,
                                                width: 150,
                                            },
                                            headerConfig: {
                                                name: t("crm.lead.lead.content"),
                                            },
                                        },
                                    },
                                    {
                                        config: {
                                            baseGridColType: BaseGridColType.enum,
                                            column: {
                                                field: "priority",
                                                editable: true,
                                                filterable: false,
                                                width: 90,
                                            },
                                            headerConfig: {
                                                name: t("crm.lead.lead.priority"),
                                                creatable: false,
                                            },
                                        },
                                        data: ReminderPriority,
                                    },
                                    {
                                        config: {
                                            baseGridColType: BaseGridColType.text,
                                            column: {
                                                field: "remindDate",
                                                filterable: false,
                                                editable: false,
                                                width: 120,
                                            },
                                            headerConfig: {
                                                name: t("crm.lead.lead.remindDate"),
                                            },
                                        },
                                    },
                                    {
                                        config: {
                                            baseGridColType: BaseGridColType.enum,
                                            column: {
                                                field: "status",
                                                editable: true,
                                                filterable: false,
                                                width: 120,
                                            },
                                            headerConfig: {
                                                name: t("crm.lead.lead.status"),
                                                creatable: false,
                                            },
                                        },
                                        data: ReminderStatus,
                                    },
                                ]}
                                actionColumn={{
                                    width: 70,
                                    defaultActions: {
                                        edit: {
                                            clickConfig: {
                                                setSelectedRow: setSelectedLeadReminder,
                                                setOpenAction: setOpenReminderUpdateDialog,
                                            },
                                        },
                                        delete: {
                                            clickConfig: {
                                                setSelectedRow: setSelectedLeadReminder,
                                                setOpenAction: setOpenReminderDeleteDialog,
                                            },
                                        },
                                    },
                                    actionHeaderName: t("crm.actions"),
                                }}
                                loading={reminderListLoading}
                                listFilter={{
                                    filterInput: {},
                                    setFilterInput: () => null,
                                }}
                                count={reminderListData?.count}
                                config={{
                                    columnVisibilityModel: {},
                                    features: []
                                }}
                                update={{
                                    updateQuery: reminderUpdate,
                                }}
                                disableColumnFilter={true}
                                hideFooterPagination={true}
                            /> :
                                <EmptyState content1={t("crm.lead.lead.emptyStateReminderList1")} />
                            : null
                        }
                    </Stack>
                </Grid>
            </DetailBox>
        </Grid>

        <ReminderCreateDialog
            open={openReminderCreateDialog}
            setOpen={setOpenReminderCreateDialog}
            target={{ id: props.leadDetailData?._id ?? "", name: props.leadDetailData?.fullName ?? "" }}
            targetType={ReminderTargetType.Lead}
        />

        <ReminderUpdateDialog
            open={openReminderUpdateDialog}
            setOpen={setOpenReminderUpdateDialog}
            target={{ id: props.leadDetailData?._id ?? "", name: props.leadDetailData?.fullName ?? "" }}
            selectedReminder={selectedLeadReminder}
            targetType={ReminderTargetType.Lead}
        />

        <ReminderDeleteDialog
            open={openReminderDeleteDialog}
            onClose={() => {
                setSelectedLeadReminder(undefined);
                setOpenReminderDeleteDialog(false);
            }}
            item={selectedLeadReminder}
        />

    </Grid >

}

interface ITasksTab {
    selectedTabName: string
    leadDetailData: ILeadDetailResult | undefined
}

const TasksTab = (props: ITasksTab) => {

    const { t } = useTranslation()

    return <Grid container mt={2} gap={2} mb={2}>
        <Grid item xs={12}>
            <DetailBox title={t("crm.lead.lead.tasks")}
                type="drawer">
                <Grid item xs={12} display="flex" flexDirection="column" gap={1}>
                    <Stack direction="row" justifyContent="space-between" height={"100%"} mb={2}>
                        <EmptyState content1={t("crm.lead.lead.noTask")} />
                    </Stack>
                </Grid>
            </DetailBox>
        </Grid>
    </Grid >
}





export default LeadDetailDrawer