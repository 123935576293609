import {
  ILeadSourcesInput, ILeadSourcesRequest, ILeadSourcesResponse, ILeadSourcesResult, leadSourcesQuery,
  ICreateLeadSourceInput, ICreateLeadSourceRequest, ICreateLeadSourceResponse, ICreateLeadSourceResult, createLeadSourceQuery,
  IUpdateLeadSourceInput, IUpdateLeadSourceRequest, IUpdateLeadSourceResponse, IUpdateLeadSourceResult, updateLeadSourceQuery, IUpdateLeadSourceFilterInput,
  IDeleteLeadSourceInput, IDeleteLeadSourceRequest, IDeleteLeadSourceResponse, IDeleteLeadSourceResult, deleteLeadSourceQuery,
} from "corede-common-cocrm";
import { commonApi } from "context/commonApi";
import { EndpointQueryBuilder } from "apps/helper/endpoint-query.builder";
import { leadSourcesTags } from "./leadSources.tags";
import { IGraphqlVariables } from "corede-common";

export const leadSourcesApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({
    // queries
    leadSources: builder.query<
      ILeadSourcesResult,
      IGraphqlVariables<ILeadSourcesInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ILeadSourcesRequest,
        ILeadSourcesResponse,
        ILeadSourcesResult,
        ILeadSourcesInput
      >({
        query: leadSourcesQuery,
        providesTags: [leadSourcesTags.leadSources],
      })),

    // mutation

    leadSourceCreate: builder.mutation<
      ICreateLeadSourceResult,
      IGraphqlVariables<ICreateLeadSourceInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ICreateLeadSourceRequest,
        ICreateLeadSourceResponse,
        ICreateLeadSourceResult,
        ICreateLeadSourceInput
      >({
        query: createLeadSourceQuery,
        invalidatesTags: [leadSourcesTags.leadSource, leadSourcesTags.leadSources],
      })),

    leadSourceUpdate: builder.mutation<
      IUpdateLeadSourceResult,
      IGraphqlVariables<IUpdateLeadSourceInput, IUpdateLeadSourceFilterInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IUpdateLeadSourceRequest,
        IUpdateLeadSourceResponse,
        IUpdateLeadSourceResult,
        IUpdateLeadSourceInput,
        IUpdateLeadSourceFilterInput
      >({
        query: updateLeadSourceQuery,
        invalidatesTags: [leadSourcesTags.leadSource, leadSourcesTags.leadSources],
      })),


    leadSourceDelete: builder.mutation<
      IDeleteLeadSourceResult,
      IGraphqlVariables<IDeleteLeadSourceInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IDeleteLeadSourceRequest,
        IDeleteLeadSourceResponse,
        IDeleteLeadSourceResult,
        IDeleteLeadSourceInput
      >({
        query: deleteLeadSourceQuery,
        invalidatesTags: [leadSourcesTags.leadSource, leadSourcesTags.leadSources],
      })),


  }),
});

export const {
  useLeadSourcesQuery,
  useLazyLeadSourcesQuery,
  useLeadSourceCreateMutation,
  useLeadSourceUpdateMutation,
  useLeadSourceDeleteMutation,
} = leadSourcesApi;
