const locale = {
  demo: "Demo",
  title: "What is the CoCRM Window?",
  description: "CoCRM Window is a business development area designed exclusively for CoCRM user companies, where they can provide transfers directly to members within the CoCRM ecosystem regarding their products, services or campaigns, and capture business opportunities by defining special discounts for individuals and institutions visiting our website.",
  featuresTitle: "With CoCRM Window;",
  features1: "You can make offers directly to visitors to our website with 1M+ monthly traffic,",
  features2: "You can make various campaign announcements to companies within our ecosystem,",
  features3: "You can provide detailed information about your products and services by taking part in the blog campaigns we will run,",
  features4: "You can make an additional 36.2% contribution to your brand awareness!",
  buttonTitle: "Request a Quote!",
  formDescription: "Fill out the form and we will get back to you as soon as possible.",
  sendSuccess: "Thank you! We will get back to you as soon as possible.",
  formTitle: "Request a Demo",
  formButtonTitle: "Submit",
  fullName: "Full Name",
  email: "Email",
  phone: "Phone",
  orgName: "Organization Name",
};

export default locale;
