import { Route, Routes } from "react-router-dom";

import { List} from "../pages";

import { ProposalsLayout } from "../layouts/ProposalsLayout";

export const ProposalsRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<ProposalsLayout />}>
        <Route index element={<List />} />
       </Route>
    </Routes>
  );
};
