import { Route, Routes } from "react-router-dom";

import { List } from "../pages";

import { DashboardLayout } from "../layouts/DashboardLayout";

export const DashboardRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<DashboardLayout />}>
        <Route index element={<List />} />
        <Route path="*" element={<List />} />
      </Route>
    </Routes>
  );
};
