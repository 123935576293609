
export const notesDomainRoutes = {
  base: "notes",
  subdomains: {

  },
};


export const baseNotesRoute = (): string => `/${notesDomainRoutes.base}/`;
