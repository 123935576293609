import { IMenuItem } from "interfaces/menu-item.interface";
import { useTranslation } from "react-i18next"; 
import {
  baseCustomerRoute,
  listCustomersRoute,
  listContactsRoute,
  listCustomerCategoriesRoute
} from "../routes/customer.base.route";
import { PermissionSubdomain } from "corede-common-cocrm";

export const useCustomerDomainMenuItem = (): IMenuItem => {
  const { t } = useTranslation(); 

  return {
    title: t("customer"),
    path: baseCustomerRoute(),
    children: [
      {
        itemId: listCustomersRoute(),
        title: t("customers"),
        path: listCustomersRoute(),
        subdomain: PermissionSubdomain.customer
      },
      {
        itemId: listContactsRoute(),
        title: t("contacts"),
        path: listContactsRoute(),
        // subdomain: PermissionSubdomain.customerContact  TODO: add subdomain
      },
      {
        itemId: listCustomerCategoriesRoute(),
        title: t("customerCategories"),
        path: listCustomerCategoriesRoute(),
        subdomain: PermissionSubdomain.customerCategory
      },
    ],
  };
};
