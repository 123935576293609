const locale = {
  expenses: "Giderler",
  expense: "Gider",
  expenseConversions: "Gider Dönüştürme",
  title: "Giderler",
  addExpense: "Gider Ekle",
  emptyStateDesc1: "Henüz herhangi bir gider eklenmedi.",
  emptyStateDesc2: "Faturalandırmanızı kolaylaştırmak ve müşterilerinizle net anlaşmalar sürdürmek için tüm gider ayrıntılarını burada saklayın ve yönetin."
};

export default locale;



