/* eslint-disable react-hooks/exhaustive-deps */
import { Add } from "@mui/icons-material";
import { Box, Grid, Stack, Typography, useMediaQuery, } from "@mui/material";
import { HeaderBase } from "components/contentHeaders/crm/HeaderBase";
import { useEffect, useState } from "react";
import { getCurrentLanguage } from "localization";
import { IGraphqlVariables, unknownError } from "corede-common";
import { useCustomerListQuery, useCustomerUpdateMutation } from "../context/customers.api";
import { ICustomerListInput } from "corede-common-cocrm";
import EmptyState from "components/emptyState/EmptyState";
import { RolesDrawer } from "../../../../02-organizationalChart/subdomains/permissions";
import { DeleteDialog } from "./DeleteDialog";
import { enqueueSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { BarChart, Gauge, gaugeClasses, LineChartPro } from '@mui/x-charts-pro';
import { useCustomerCategoryListQuery } from "../../customerCategories";
import { CustomerDetailDrawer } from "./DetailDrawer";
import CustomerCreateWraper from "./CustomerCreateWraper";
import CustomerUpdateWraper from "./CustomerUpdateWraper";
import { MyDataGrid } from "components/dataGrid/MyDataGrid";

const List = () => {
  const { t } = useTranslation();
  const downMd = useMediaQuery((theme: any) => theme.breakpoints.down('md'));
  const currentLanguage = getCurrentLanguage();
  const [openCustomerCreateDrawer, setOpenCustomerCreateDrawer] = useState(false);
  const [openDepartmentsDrawer, setOpenDepartmentsDrawer] = useState(false);
  const [selectedItemForDelete, setSelectedItemForDelete] = useState<any>();
  const [selectedRow, setSelectedRow] = useState<any>();
  const [searchQuery, setSearchQuery] = useState('');
  const [openRolesDrawer, setOpenRolesDrawer] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openDetailDrawer, setOpenDetailDrawer] = useState(false);
  const [openCustomerUpdateDrawer, setOpenCustomerUpdateDrawer] = useState(false);
  const [customerListFilter, setCustomerListFilter] = useState<IGraphqlVariables<ICustomerListInput>>({
    input: {
      filter: {},
      pagination: { page: 1, pageSize: 10 }
    }
  })


  const { data: customerCategoryListData, isLoading: customerCategoryListLoading, isFetching: customerCategoryListFetching, error: customerCategoryListError } = useCustomerCategoryListQuery({
    input: {
      filter: {}
    }
  })
  const { data: customerListData, isLoading: customerListLoading, isFetching: customerListFetching, error: customerListError } = useCustomerListQuery(customerListFilter)
  const [customerUpdate, { isLoading: customerUpdateLoading, error: customerUpdateError }] = useCustomerUpdateMutation()


  function handleSelectCustomer(direction: 'next' | 'back') {
    if (!selectedRow || !customerListData?.data?.length) return;

    const currentIndex = customerListData.data.findIndex(customer => customer._id === selectedRow._id);

    let newIndex = currentIndex;
    if (direction === 'next') {
      newIndex = currentIndex + 1;
    } else if (direction === 'back') {
      newIndex = currentIndex - 1;
    }

    // Ensure the new index is within the valid range
    if (newIndex >= 0 && newIndex < customerListData.data?.length) {
      const newSelectedCustomer = { ...customerListData.data[newIndex], id: newIndex + 1 }; // index bilgisini ekle
      setSelectedRow(newSelectedCustomer);
    }
  }

  useEffect(() => {
    if (customerListError) {
      enqueueSnackbar((customerListError as any)?.error?.message[currentLanguage] ?? unknownError.message[currentLanguage], { variant: 'error' });
    }
  }, [customerListError]);

  useEffect(() => {
    if (customerUpdateError) {
      enqueueSnackbar((customerUpdateError as any)?.error?.message[currentLanguage] ?? unknownError.message[currentLanguage], { variant: 'error' });
    }
  }, [customerUpdateError]);

  return <Grid item xs={12}>
    <HeaderBase
      title={t("crm.customer.customers.headerTitle")}
      rightButton={{
        title: t("crm.customer.customers.createCustomer"),
        onClick: () => {
          setOpenCustomerCreateDrawer(true);
        },
        icon: <Add sx={{ mr: 1 }} />,
      }}
    />
    <Grid container spacing={2} my={1}>
      <Grid item xs={12} md={4}>
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="space-between" sx={{ border: "1px solid", borderRadius: "14px" }}>
          <Stack direction="row" gap={1} alignItems="center" justifyContent="space-between" width={"85%"} mb={-5}>
            <Typography fontSize={"18px"} fontWeight={"bold"}>Customers</Typography>
            <Typography fontSize={"44px"} fontWeight={"bold"}>35</Typography>
          </Stack>
          <LineChartPro
            xAxis={[{ data: [1, 2, 3, 5, 8, 10] }]}
            series={[{
              data: [2, 5.5, 2, 8.5, 1.5, 5],
              color: "#F43F5E"
            }]}
            height={170}
            sx={{ mb: -5 }}
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={4}>
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="space-between" sx={{ border: "1px solid", borderRadius: "14px" }}>
          <Stack direction="row" gap={1} alignItems="center" justifyContent="space-between" width={"85%"} mb={-2}>
            <Typography fontSize={"18px"} fontWeight={"bold"}>Related Customers</Typography>
            <Typography fontSize={"44px"} fontWeight={"bold"}>3</Typography>
          </Stack>
          <Gauge
            width={180}
            height={147}
            value={3}
            cornerRadius="50%"
            sx={(theme) => ({
              [`& .${gaugeClasses.valueText}`]: {
                fontSize: 40,
              },
              [`& .${gaugeClasses.valueArc}`]: {
                fill: theme.palette.secondary.main,
              },
              [`& .${gaugeClasses.referenceArc}`]: {
                fill: theme.palette.text.disabled,
              },
            })}
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={4}>
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="space-between" sx={{ border: "1px solid", borderRadius: "14px" }}>
          <Stack direction="row" gap={1} alignItems="center" justifyContent="space-between" width={"85%"} mb={-2}>
            <Typography fontSize={"18px"} fontWeight={"bold"}>B2B / B2C</Typography>
            <Typography fontSize={"44px"} fontWeight={"bold"}>&nbsp;</Typography>
          </Stack>
          <BarChart
            xAxis={[
              {
                id: 'barCategories',
                data: ['BTB', 'BTC',],
                scaleType: 'band',
              },
            ]}
            series={[
              {
                data: [2, 5],
                color: "#F43F5E"
              },
            ]}
            height={150}
            sx={{ mb: -5 }}
          />
        </Box>
      </Grid>
    </Grid>

    {customerListData?.data?.length !== 0 ? <Box height={{ xs: "500px", md: "calc(100vh - 500px)" }} mt={1} overflow={"auto"} borderRadius={"16px"}>
      <MyDataGrid
        path="customers"
        columns={[
          {
            field: "id",
            headerName: "#",
            disableColumnMenu: true,
            filterable: false,
            align: "center",
            width: 40,
            headerAlign: "center",
          },
          {
            field: "name",
            headerName: t("crm.customer.customers.name"),
            width: 120,
            editable: true,
          },
          {
            field: "email",
            headerName: t("crm.customer.customers.email"),
            width: 130,
            editable: true
          },
          {
            field: "type",
            headerName: t("crm.customer.customers.type"),
            width: 120,
            editable: true,
            type: 'singleSelect',
            changed: ["valueOptions", "cell", "editCell"],
          },
          {
            field: "phone",
            headerName: t("crm.customer.customers.phone"),
            width: 120,
            editable: true,
            filterable: false
          },
          {
            field: "language",
            headerName: t("crm.customer.customers.language"),
            width: 100,
            editable: true,
            type: 'singleSelect',
            changed: ["valueOptions", "cell", "editCell"],
          },
          {
            field: "category",
            headerName: t("crm.customer.customers.category"),
            width: 120,
            editable: true,
            type: 'singleSelect',
            changed: ["valueOptions", "cell", "editCell"],
          },
          {
            field: "about",
            headerName: t("crm.customer.customers.about"),
            width: 120,
            editable: true,
            filterable: false
          },
          {
            field: "website",
            headerName: t("crm.customer.customers.website"),
            width: 120,
            editable: true,
            filterable: false
          },
          {
            field: "country",
            headerName: t("crm.customer.customers.country"),
            width: 120,
            editable: true,
            filterable: false
          },
          {
            field: "state",
            headerName: t("crm.customer.customers.state"),
            width: 120,
            editable: true,
            filterable: false
          },
          {
            field: "actions",
            headerName: t("crm.customer.customers.actions"),
            sortable: false,
            filterable: false,
            align: "center",
            editable: false,
            headerAlign: "center",
            width: downMd ? 50 : 120,
            changed: ["view", "edit", "delete"]
          },
        ]}
        rows={customerListData?.data?.map((customer, index) => ({
          _id: customer._id,
          id: (index + 1) * (customerListFilter?.input?.pagination?.page || 0),
          name: customer.name,
          email: customer.email,
          type: customer.type,
          phone: customer.phone,
          language: customer.language,
          category: customer.category,
          about: customer.about,
          website: customer.website,
          country: customer.country,
          state: customer.state
        })) || []}
        columnVisibilityModel={{
          about: false
        }}
        props={{
          customerCategoryListData,
          setSelectedRow: setSelectedRow,
          setOpenDelete: setOpenDeleteDialog,
          setOpenDetail: setOpenDetailDrawer
        }}
        loading={customerListLoading || customerCategoryListLoading || customerUpdateLoading}
        count={customerListData?.count || 0}
        filter={customerListFilter}
        setFilter={setCustomerListFilter}
        rowUpdate={customerUpdate}
        features={[]}
      />
    </Box> :
      <EmptyState
        content1={t("crm.customer.customers.emptyStateContent1")}
        content2={t("crm.customer.customers.emptyStateContent2")}
        button1={{
          title: t("crm.customer.customers.createCustomer"),
          onClick: () => {
            setOpenCustomerCreateDrawer(true);
          },
          leftIcon: <Add />
        }}
      />
    }

    <CustomerDetailDrawer
      open={openDetailDrawer}
      setOpen={setOpenDetailDrawer}
      selectedCustomer={selectedRow}
      setSelectedCustomer={setSelectedRow}
      handleSelect={handleSelectCustomer}
      count={customerListData?.count}
    />

    <CustomerCreateWraper
      open={openCustomerCreateDrawer}
      setOpen={setOpenCustomerCreateDrawer}
      setOpenDepartments={setOpenDepartmentsDrawer}
    />

    <CustomerUpdateWraper
      open={openCustomerUpdateDrawer}
      setOpen={setOpenCustomerUpdateDrawer}
      selectedCustomer={selectedRow}
      setSelectedCustomer={setSelectedRow}
      setOpenDepartments={setOpenDepartmentsDrawer}
    />

    <RolesDrawer
      open={openRolesDrawer}
      setOpen={setOpenRolesDrawer}
    />


    <DeleteDialog
      open={openDeleteDialog}
      onClose={() => setOpenDeleteDialog(false)}
      item={selectedRow}
    />

  </Grid>;
};

export default List;
