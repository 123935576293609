const locale = {
  tasks: "Tasks",
  task: "Task",
  taskConversions: "Task Conversions",
  title: "Tasks",
  addTask: "Add Task",
  emptyStateDesc1: "No tasks assigned yet.",
  emptyStateDesc2: "Create tasks here to organize your work, set priorities, and stay on track with your CRM activities."
};

export default locale;



 

