import { IGraphqlVariables } from "corede-common";
import {
  webNotificationUnreadCountOwnQuery,
  IWebNotificationUnreadCountOwnResult,
  IWebNotificationUnreadCountOwnRequest,
  IWebNotificationUnreadCountOwnResponse,
  IWebNotificationDetailResult,
  IWebNotificationDetailOwnRequest,
  IWebNotificationDetailOwnResponse,
  IWebNotificationDetailInput,
  webNotificationDetailOwnQuery,
  webNotificationListOwnQuery,
  IWebNotificationListInput,
  IWebNotificationListOwnRequest,
  IWebNotificationListOwnResponse,
  IWebNotificationListResult,
  webNotificationReadOwnQuery,
  IWebNotificationReadOwnRequest,
  IWebNotificationReadOwnInput,
  IWebNotificationReadOwnResponse,
  IWebNotificationReadOwnResult,
  IWebNotificationReadAllOwnRequest,
  IWebNotificationReadAllOwnResponse,
  IWebNotificationReadAllOwnResult,
  webNotificationReadAllOwnQuery,
} from "corede-common-cocrm";
import { commonApi } from "context/commonApi";
import { EndpointQueryBuilder } from "apps/helper/endpoint-query.builder";

const notificationApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({
    // queries

    webNotificationUnreadCountOwn: builder.query<
      IWebNotificationUnreadCountOwnResult,
      IGraphqlVariables<undefined>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IWebNotificationUnreadCountOwnRequest,
        IWebNotificationUnreadCountOwnResponse,
        IWebNotificationUnreadCountOwnResult,
        undefined
      >({
        query: webNotificationUnreadCountOwnQuery,
        providesTags: ["webNotifications"],
      })
    ),

    webNotificationDetailOwn: builder.query<
      IWebNotificationDetailResult,
      IGraphqlVariables<IWebNotificationDetailInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IWebNotificationDetailOwnRequest,
        IWebNotificationDetailOwnResponse,
        IWebNotificationDetailResult,
        IWebNotificationDetailInput
      >({
        query: webNotificationDetailOwnQuery,
        providesTags: ["webNotifications"],
      })
    ),

    webNotificationListOwn: builder.query<
      IWebNotificationListResult,
      IGraphqlVariables<IWebNotificationListInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IWebNotificationListOwnRequest,
        IWebNotificationListOwnResponse,
        IWebNotificationListResult,
        IWebNotificationListInput
      >({
        query: webNotificationListOwnQuery,
        providesTags: ["webNotifications"],
      })
    ),

    // mutations

    webNotificationReadOwn: builder.mutation<
      IWebNotificationReadOwnResult,
      IGraphqlVariables<IWebNotificationReadOwnInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IWebNotificationReadOwnRequest,
        IWebNotificationReadOwnResponse,
        IWebNotificationReadOwnResult,
        IWebNotificationReadOwnInput
      >({
        query: webNotificationReadOwnQuery,
        invalidatesTags: ["webNotifications"],
      })
    ),

    webNotificationReadAllOwn: builder.mutation<
      IWebNotificationReadAllOwnResult,
      IGraphqlVariables<undefined>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IWebNotificationReadAllOwnRequest,
        IWebNotificationReadAllOwnResponse,
        IWebNotificationReadAllOwnResult,
        undefined
      >({
        query: webNotificationReadAllOwnQuery,
        invalidatesTags: ["webNotifications"],
      })
    ),
  }),
});

export const {
  useWebNotificationDetailOwnQuery,
  useLazyWebNotificationDetailOwnQuery,
  useWebNotificationListOwnQuery,
  useLazyWebNotificationListOwnQuery,
  useWebNotificationUnreadCountOwnQuery,
  useLazyWebNotificationUnreadCountOwnQuery,

  useWebNotificationReadOwnMutation,
  useWebNotificationReadAllOwnMutation,
} = notificationApi;
