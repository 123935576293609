/* eslint-disable react-hooks/exhaustive-deps */
import {
  Stack,
  Box,
  useMediaQuery,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  Tooltip,
  IconButton,
  Avatar,
  MenuItem,
  Card,
} from "@mui/material";
import {
  Add,
  AssignmentReturnOutlined,
  DeleteOutlineRounded,
  DownloadOutlined,
  FileUpload,
} from "@mui/icons-material";
import { memo, useEffect, useState } from "react";
import {
  ILeadListInput,
  ILeadSourceResult,
  ILeadStatusResult,
  LeadBusinessType,
  LeadQualification,
  LeadType,
} from "corede-common-cocrm";
import { importLeadRoute } from "../../../routes/lead.base.route";
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import {
  useLazyLeadListQuery,
  useLeadListCountQuery,
  useLeadListQuery,
  useLeadUpdateMutation,
} from "../context/lead.api";
import { IGraphqlVariables, isNullOrUndefinedOrEmptyObject, Language, unknownError } from "corede-common";
import { getCurrentLanguage } from "localization";
import { useTranslation } from "react-i18next";
import EmptyState from "components/emptyState/EmptyState";
import { IListView } from "interfaces/data-grid.interface";
import { BaseDataGrid } from "components/baseDataGrid/BaseDataGrid";
import { BaseGridColType } from "components/baseDataGrid/infra/enums";
import { IGridColConfigInput } from "components/baseDataGrid/infra/IGridColConfigInput";
import {
  TCellRenderFunc,
  TGridColDefCell,
} from "components/baseDataGrid/infra/types";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { OutlinedButton } from "components";
import { BASE_URL } from "utils";
import { getAccessToken } from "utils/getUserInfo";
import axios from "axios";
import moment from "moment";
import LeadDetailWrapper from "./LeadDetailWrapper";

const ListView = memo((props: IListView) => {
  // init

  const { t } = useTranslation();
  const downMd = useMediaQuery((theme: any) => theme.breakpoints.down("md"));
  const currentLanguage = getCurrentLanguage();
  const navigate = useNavigate();

  // states

  const [selectedLead, setSelectedLead] = useState<any>();
  const [openUserDrawer, setOpenUserDrawer] = useState(false);
  const [pendingNavigation, setPendingNavigation] = useState<null | { direction: "next" | "back"; page: number; }>(null);

  const [leadListFilter, setLeadListFilter] = useState<
    IGraphqlVariables<ILeadListInput>
  >({
    input: {
      filter: {},
      pagination: { page: 1, pageSize: 10 },
    },
  });
  const [isJunk, setIsJunk] = useState<boolean | undefined>(undefined);
  const [businessType, setBusinessType] = useState<LeadBusinessType | undefined>(
    undefined
  );

  const handleDownloadList = () => {

    let data = JSON.stringify({
      "filter": leadListFilter?.input?.filter ?? {},
    });

    let config = {
      method: 'post',
      url: `${BASE_URL}/leads/export`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getAccessToken()}`,
      },
      data: data,
      responseType: 'blob'
    };

    axios.request(config as any)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `leads-${moment().format("DD-MM-YYYY")}.xlsx`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        enqueueSnackbar(t("crm.lead.lead.downloadFormSuccessfully"), { variant: "success" });
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.response.data?.message[currentLanguage] ?? unknownError.message[currentLanguage], { variant: 'error' });

      });



  };

  const currentPage = leadListFilter?.input?.pagination?.page ?? 1
  const currentPageSize = leadListFilter?.input?.pagination?.pageSize ?? 10


  const handleSelectLead = async (direction: "next" | "back") => {
    if (!selectedLead || !leadListData?.data?.length) return;

    const currentIndex = leadListData.data.findIndex(
      (lead) => lead._id === selectedLead._id
    );

    let newIndex = currentIndex;
    const totalLeads = leadListData?.count || 0;
    const maxPage = Math.ceil(totalLeads / currentPageSize);

    if (direction === "next") {
      newIndex = currentIndex + 1;

      if (newIndex >= leadListData.data.length) {
        if (currentPage >= maxPage) return;
        const nextPage = currentPage + 1;
        setLeadListFilter({
          ...leadListFilter,
          input: {
            ...leadListFilter.input,
            pagination: {
              ...leadListFilter.input?.pagination,
              page: nextPage,
            },
          },
        });
        setPendingNavigation({
          direction: "next",
          page: nextPage,
        });
        return;
      }
    } else if (direction === "back") {

      newIndex = currentIndex - 1;
      if (newIndex < 0) {
        if (currentPage <= 1) return;
        const previousPage = currentPage - 1;
        setLeadListFilter({
          ...leadListFilter,
          input: {
            ...leadListFilter.input,
            pagination: {
              ...leadListFilter.input?.pagination,
              page: previousPage,
            },
          },
        });
        setPendingNavigation({
          direction: "back",
          page: previousPage,
        });
        return;
      }
    }

    const globalId = (currentPage - 1) * currentPageSize + (newIndex + 1);
    setSelectedLead({
      ...leadListData.data[newIndex],
      id: globalId,
    });
  };



  // queries

  const {
    data: leadListData,
    isLoading: leadListLoading,
    isFetching: leadListFetching,
    error: leadListError,
    refetch,
  } = useLeadListQuery(leadListFilter);

  const [leadUpdate, { isLoading: leadUpdateLoading, error: leadUpdateError }] =
    useLeadUpdateMutation();

  // effects

  useEffect(() => {

    if (
      pendingNavigation && leadListData &&
      leadListData?.data?.length > 0 && !leadListLoading && !leadListFetching &&
      leadListFilter?.input?.pagination?.page === pendingNavigation.page
    ) {
      const { direction, page } = pendingNavigation;

      let newSelectedLead;
      if (direction === "next") {
        newSelectedLead = {
          ...leadListData.data[0],
          id: (page - 1) * currentPageSize + 1,
        };
      } else if (direction === "back") {
        const lastIndex = leadListData.data.length - 1;
        newSelectedLead = {
          ...leadListData.data[lastIndex],
          id: (page - 1) * currentPageSize + (lastIndex + 1),
        };
      }

      setSelectedLead(newSelectedLead);
      setPendingNavigation(null);
    }
  }, [
    leadListData?.data,
    pendingNavigation,
    leadListFilter?.input?.pagination?.page,
  ]);





  useEffect(() => {
    props.setLeadListLoading && props.setLeadListLoading(leadListLoading);
  }, [leadListLoading]);

  useEffect(() => {
    refetch();
  }, [leadListFilter]);

  useEffect(() => {
    if (isJunk || businessType) {
      const filter = {
        // ...leadListFilter,
        input: {
          ...leadListFilter?.input,
          filter: {
            isJunk: isJunk,
            businessTypes: businessType ? [businessType] : undefined,
          },
          pagination: {
            page: 1, pageSize: 10
          },
        },
      };
      setLeadListFilter(filter);
    }
  }, [isJunk, businessType]);

  useEffect(() => {
    if (leadListError) {
      enqueueSnackbar(
        (leadListError as any)?.error?.message[currentLanguage] ??
        unknownError.message[currentLanguage],
        { variant: "error" }
      );
    }
  }, [leadListError]);

  useEffect(() => {
    if (leadUpdateError) {
      enqueueSnackbar(
        (leadUpdateError as any)?.error?.message[currentLanguage] ??
        unknownError.message[currentLanguage],
        { variant: "error" }
      );
    }
  }, [leadUpdateError]);


  const isEmptyState =
    leadListData?.data?.length === 0 && !leadListLoading && !leadListFetching &&
    isNullOrUndefinedOrEmptyObject(leadListFilter?.input?.filter);

  // custom views

  const junkActionButton = (renderCellParams: GridRenderCellParams) => {
    return !isJunk ? (
      <MenuItem onClick={() => {
        leadUpdate({
          filter: { _id: renderCellParams.row?._id },
          input: { isJunk: true },
        });
      }}>
        <Stack direction={"row"} width={"100%"} justifyContent={"flex-start"} alignItems={"center"}>
          <DeleteOutlineRounded color="warning" fontSize="small" />
          <Typography fontSize={"12px"} fontWeight={"bold"} ml={1}>{t("crm.lead.lead.junk")}</Typography>
        </Stack>
      </MenuItem >
    ) : (
      <MenuItem onClick={() => {
        leadUpdate({
          filter: { _id: renderCellParams.row?._id },
          input: { isJunk: false },
        });
      }}>
        <Stack direction={"row"} width={"100%"} justifyContent={"flex-start"} alignItems={"center"}>
          <AssignmentReturnOutlined color="warning" fontSize="small" />
          <Typography fontSize={"12px"} fontWeight={"bold"} ml={1}>{t("crm.lead.lead.unJunk")}</Typography>
        </Stack>
      </MenuItem >
    );
  };

  const leadInfoCell: TCellRenderFunc = (cellParams) => {
    return (
      <Stack direction={"row"} alignItems={"center"} justifyContent={"flex-start"} gap={1}>
        <Avatar alt={cellParams.value.fullName} src={"https://loremflickr.com/480/480"} sx={{ width: 35, height: 35 }} />
        <Stack direction={"column"} alignItems={"flex-start"} justifyContent={"center"} gap={0.5}>
          <Typography fontSize={"12px"} fontWeight={500} color={"text.primary"}>{cellParams.value.fullName}</Typography>
          <Typography fontSize={"10px"} fontWeight={500} color={"text.secondary"}>  {cellParams.value.email}</Typography>
        </Stack>
      </Stack>
    );
  };



  return (
    <Stack
      direction="column"
      justifyContent="space-between"
      mt={2}
      height={{ xs: "700px", md: "calc(100% - 200px)" }}
      sx={{ overflow: "auto" }}
    >
      <Card sx={{ bgcolor: "background.secondWithBlur", p: "0px", height: "100%" }}>
        {leadListData ?
          !isEmptyState ? (
            <BaseDataGrid
              rows={
                leadListData?.data?.map((lead, index) => ({
                  id: ((leadListFilter.input?.pagination?.page || 1) - 1) * (leadListFilter.input?.pagination?.pageSize || 0) + index + 1,
                  _id: lead._id,
                  leadInfo: {
                    fullName: lead.fullName ?? "",
                    position: lead.position ?? "",
                    email: lead.email ?? "",
                  },
                  fullName: lead.fullName || "-",
                  position: lead.position || "-",
                  email: lead.email || "-",
                  website: lead.website || "-",
                  phoneNumber: lead.phoneNumber || "-",
                  company: lead.company || "-",
                  sector: lead.sector || "-",
                  status: lead.status || null,
                  source: lead.source || null,
                  type: lead.type || "-",
                  qualification: lead.qualification || "-",
                  country: lead.country || "-",
                  state: lead.state || "-",
                  city: lead.city || "-",
                  language: lead.language || "-",
                  leadValue: lead.leadValue || "-",
                  organization: lead.organization._id || "-",
                  department: lead.department || "-",
                })) || []
              }
              columns={[
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: "id",
                      disableColumnMenu: true,
                      filterable: false,
                      width: 40,
                      align: "center",
                    },
                    headerConfig: {
                      name: "#",
                      align: "center",
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.any,
                    column: {
                      field: "leadInfo",
                      width: 180,
                    },
                    headerConfig: {
                      icon: "radix-icons:person",
                      name: `${t("crm.lead.lead.name")}`,
                    },
                    overrideCell: leadInfoCell,
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: "fullName",
                      filterable: true,
                      editable: true,
                    },
                    headerConfig: {
                      icon: "radix-icons:text",
                      name: t("crm.lead.lead.fullName"),
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: "position",
                      editable: true,
                    },
                    headerConfig: {
                      icon: "radix-icons:crosshair-2",
                      name: t("crm.lead.lead.position"),
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: "email",
                      editable: true,
                    },
                    headerConfig: {
                      icon: "radix-icons:envelope-closed",
                      name: t("crm.lead.lead.email"),
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: "phoneNumber",
                      filterable: false,
                      editable: true,
                    },
                    headerConfig: {
                      icon: "mi:call",
                      name: t("crm.lead.lead.phone"),
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.object,
                    column: {
                      field: "status",
                      editable: true,
                      width: 150,
                    },
                    headerConfig: {
                      icon: "majesticons:chat-status-line",
                      name: t("crm.lead.lead.status"),
                      creatable: true,
                      createFunc: props.setLeadStatusCreateOpen,
                    },
                    filterConfig: {
                      customFieldName: `statusIds`,
                      isArrayFilter: true,
                    },
                    valueOptionFields: props.leadStatusesData?.data,
                    //   changed: ["valueOptions", "cell", "editCell", "header"],
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.object,
                    column: {
                      field: "source",
                      editable: true,
                      width: 150,
                    },
                    headerConfig: {
                      icon: "mdi:source-fork",
                      name: t("crm.lead.lead.source"),
                      creatable: true,
                      createFunc: props.setLeadSourceCreateOpen,
                    },
                    filterConfig: {
                      customFieldName: `sourceIds`,
                      isArrayFilter: true,
                    },
                    valueOptionFields: props.leadSourcesData?.data,
                  },
                  //   changed: ["valueOptions", "cell", "editCell", "header"],
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: "website",
                      width: 140,
                      filterable: false,
                      editable: true,
                    },
                    headerConfig: {
                      icon: "streamline:web",
                      name: t("crm.lead.lead.website"),
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: "company",
                      editable: true,
                    },
                    headerConfig: {
                      icon: "material-symbols:business-center-outline",
                      name: t("crm.lead.lead.company"),
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: "sector",
                      editable: true,
                    },
                    headerConfig: {
                      icon: "ic:round-business",
                      name: t("crm.lead.lead.sector"),
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.enum,
                    column: {
                      field: "type",
                      editable: true,
                      width: 90,
                    },
                    headerConfig: {
                      icon: "oui:app-users-roles",
                      name: t("crm.lead.lead.type"),
                      creatable: false,
                    },
                    filterConfig: {
                      customFieldName: `types`,
                      isArrayFilter: true,
                    },
                  },
                  data: LeadType,
                  //   changed: ["valueOptions", "cell", "editCell"],
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.enum,
                    column: {
                      field: "qualification",
                      editable: true,
                      width: 150,
                    },
                    headerConfig: {
                      icon: "octicon:ruby-16",
                      name: t("crm.lead.lead.qualification"),
                      creatable: false,
                    },
                    filterConfig: {
                      customFieldName: `qualifications`,
                      isArrayFilter: true,
                    },
                  },
                  data: LeadQualification,
                  //   changed: ["valueOptions", "cell", "editCell"],
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: "country",
                      editable: true,
                    },
                    headerConfig: {
                      icon: "bi:geo-alt",
                      name: t("crm.lead.lead.country"),
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: "state",
                      editable: true,
                    },
                    headerConfig: {
                      icon: "bi:geo",
                      name: t("crm.lead.lead.state"),
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: "city",
                      editable: true,
                    },
                    headerConfig: {
                      icon: "hugeicons:city-03",
                      name: t("crm.lead.lead.city"),
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.enum,
                    column: {
                      field: "language",
                      width: 100,
                      editable: true,
                    },
                    headerConfig: {
                      icon: "clarity:language-line",
                      name: t("crm.lead.lead.language"),
                    },
                    filterConfig: {
                      customFieldName: `languages`,
                      isArrayFilter: true,
                    },
                  },
                  data: Language,
                  //   changed: ["valueOptions", "cell"],
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: "leadValue",
                      width: 150,
                      filterable: false,
                      editable: true,
                    },
                    headerConfig: {
                      icon: "fluent:star-32-regular",
                      name: t("crm.lead.lead.leadValue"),
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: "department",
                      width: 150,
                      editable: false,
                    },
                    headerConfig: {
                      icon: "mingcute:department-line",
                      name: t("crm.lead.lead.department"),
                    },
                    filterConfig: {
                      customFieldName: `departmentIds`,
                      isArrayFilter: true,
                    },
                  },
                },
              ]}
              actionColumn={{
                width: 80,
                defaultActions: {
                  view: {
                    clickConfig: {
                      setSelectedRow: setSelectedLead,
                      setOpenAction: setOpenUserDrawer,
                    },
                  },
                  edit: {
                    clickConfig: {
                      setSelectedRow: setSelectedLead,
                      setOpenAction: props.setOpenUpdate,
                    },
                  },
                  delete: {
                    clickConfig: {
                      setSelectedRow: setSelectedLead,
                      setOpenAction: props.setOpenDelete,
                    },
                  },
                },
                customCellItems: [junkActionButton],
                actionHeaderName: t("crm.actions"),
              }}
              loading={
                leadListLoading ||
                props.leadStatusesLoading ||
                leadUpdateLoading ||
                props.leadSourcesLoading ||
                leadListFetching
              }
              listFilter={{
                filterInput: leadListFilter,
                setFilterInput: setLeadListFilter,
              }}
              update={{
                updateQuery: leadUpdate,
              }}
              count={leadListData?.count ?? 0}
              config={{
                columnVisibilityModel: {
                  fullName: false,
                  website: false,
                  company: false,
                  sector: false,
                  country: false,
                  state: false,
                  city: false,
                  language: false,
                  leadValue: false,
                  department: false,
                },
                features: [],
              }}
              toolbar={{
                customActions: [
                  <Box display="flex" alignItems="center">
                    <Box display="flex" alignItems="center">
                      <ToggleButtonGroup
                        id="export"
                        sx={{ ml: 2 }}
                        color="warning"
                        size="small"
                        exclusive
                      >
                        <ToggleButton value={false}
                          onClick={() => handleDownloadList()}
                        >
                          <DownloadOutlined fontSize="small" sx={{ mr: 1 }} />
                          <Typography fontWeight={"bold"} fontSize={"12px"}>
                            {t("crm.lead.lead.export")}
                          </Typography>
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </Box>
                    <ToggleButtonGroup
                      value={businessType}
                      id="businessType"
                      sx={{ ml: 2 }}
                      size="small"
                      exclusive
                      color="info"
                      onChange={(_, value) =>
                        setBusinessType && setBusinessType(value)
                      }
                    >
                      <ToggleButton value={LeadBusinessType.b2b}>
                        <Typography fontWeight={"bold"} fontSize={"12px"}>
                          {t("crm.lead.lead.b2b")}
                        </Typography>
                      </ToggleButton>
                      <ToggleButton value={LeadBusinessType.b2c}>
                        <Typography fontWeight={"bold"} fontSize={"12px"}>
                          {t("crm.lead.lead.b2c")}
                        </Typography>
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Box>,
                  <Box display="flex" alignItems="center">
                    <ToggleButtonGroup
                      value={!isJunk}
                      id="isJunk"
                      sx={{ ml: 2 }}
                      color="warning"
                      size="small"
                      exclusive
                      onChange={() => setIsJunk && setIsJunk(!isJunk)}
                    >
                      <ToggleButton value={false}>
                        <DeleteOutlineRounded fontSize="small" sx={{ mr: 1 }} />
                        <Typography fontWeight={"bold"} fontSize={"12px"}>
                          {t("crm.lead.lead.isJunk")}
                        </Typography>
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Box>,
                ],
              }}
              customStyle={{
                "& .MuiDataGrid-columnHeader": {
                  background: isJunk ? "#F6E8D6" : null,
                },
                "& .MuiDataGrid-filler--pinnedRight": {
                  background: isJunk ? "#F6E8D6" : null,
                },
                "& .MuiDataGrid-filler--pinnedLeft": {
                  background: isJunk ? "#F6E8D6" : null,
                },
                "& .MuiDataGrid-cell--pinnedRight": {
                  background: isJunk ? "#F6E8D6" : null,
                },
                "& .MuiDataGrid-cell--pinnedLeft": {
                  background: isJunk ? "#F6E8D6" : null,
                },
              }}
            />
          ) : (
            <EmptyState
              content1={t("crm.lead.lead.emptyState1")}
              content2={t("crm.lead.lead.emptyState2")}
              button1={{
                title: t("crm.lead.lead.createLead"),
                onClick: () => (props?.setOpenLeadCreateDrawer ?? (() => { }))(true),
                leftIcon: <Add sx={{ mr: 1 }} fontSize="small" />,
              }}
              button2={{
                title: t("crm.lead.lead.importLead"),
                onClick: () => navigate(importLeadRoute()),
                leftIcon: <FileUpload sx={{ mr: 1 }} fontSize="small" />,
              }}
            />
          ) : null}
      </Card>
      <Box sx={{ width: "100%", height: "10px" }} />

      <LeadDetailWrapper
        open={openUserDrawer}
        setOpen={setOpenUserDrawer}
        selectedLead={selectedLead}
        count={leadListData?.count}
        currentPage={currentPage}
        currentPageSize={currentPageSize}
        setSelectedLead={setSelectedLead}
        handleSelect={handleSelectLead}
      />
    </Stack>
  );
});

export default ListView;
