
import expensesLocale from "../subdomains/expenses/locales/en";

const locale = {

  // Todo
  // users: {
  //   ...usersLocale,
  // },
  expenses: {
    ...expensesLocale,
  },
};

export default locale;
