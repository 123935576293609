/* eslint-disable react-hooks/exhaustive-deps */
import { Autocomplete, Box, Button, CircularProgress, Grid, InputLabel, Stack, SwipeableDrawer, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { convertEnums, getCurrentLanguage } from "localization";
import { IGraphqlVariables, unknownError } from "corede-common";
import { ILegalAgreementCreateInput, LegalAgreementType } from "corede-common-cocrm";
import { enqueueSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useLegalAgreementCreateMutation } from "../context/legalAgreements.api";
import { validateCreateLegalAgreementsInput } from "../validations/create.validation";

interface ILegalAgreementCreateDrawer {
    open: boolean
    setOpen: (open: boolean) => void
}

export const LegalAgreementCreateDrawer = (props: ILegalAgreementCreateDrawer) => {
    const { t } = useTranslation();
    const currentLanguage = getCurrentLanguage();
    const [loading, setLoading] = useState(false);
    const localizedLegalAgreements = convertEnums(LegalAgreementType);
    const [legalAgreementCreate, { data: legalAgreementCreateData, isLoading: legalAgreementCreateLoading, error: legalAgreementCreateError }] = useLegalAgreementCreateMutation();

    const { values, handleSubmit, handleBlur, setFieldValue, errors, touched, resetForm, isValid } =
        useFormik({
            initialValues: {
                input: {
                    type: LegalAgreementType.NDA,
                    name: '',
                    content: '',
                    description: '',
                },
            },
            enableReinitialize: true,
            validationSchema: validateCreateLegalAgreementsInput,
            onSubmit: async (values: IGraphqlVariables<ILegalAgreementCreateInput>) => {
                setLoading(true);
                legalAgreementCreate({ input: values.input });
                setLoading(false);
            }
        });

    useEffect(() => {
        if (legalAgreementCreateData) {
            props.setOpen(false);
            enqueueSnackbar(t("crm.preSales.legalAgreements.legalAgreementCreateSuccess"), { variant: 'success' });
            resetForm();
        }
    }, [legalAgreementCreateData, resetForm]);

    useEffect(() => {
        if (legalAgreementCreateError) {
            enqueueSnackbar((legalAgreementCreateError as any)?.error?.message[currentLanguage] ?? unknownError.message[currentLanguage], { variant: 'error' });
        }
    }, [legalAgreementCreateError]);

    return (
        <SwipeableDrawer
            open={props.open}
            onOpen={() => props.setOpen(true)}
            onClose={() => props.setOpen(false)}
            anchor='right'
        >
            <Grid item xs={12} p={3} width={400}>
                <Typography variant="h4" gutterBottom>
                    {t("crm.preSales.legalAgreements.createLegalAgreement")}
                </Typography>
                <Box sx={{ mt: 4, width: 400 }} />
                <Stack direction={"column"} justifyContent={"space-between"} height={"calc(100dvh - 120px)"}>
                    <Stack direction="column" gap={1} mt={1}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} >
                                <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.preSales.legalAgreements.name")}*</InputLabel>
                                <TextField
                                    fullWidth
                                    required
                                    size="small"
                                    name="input.name"
                                    value={(values as any).input.name}
                                    onChange={(e) => setFieldValue("input.name", e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                                    onBlur={handleBlur}
                                    error={!!((errors as any)?.input?.name && (touched as any)?.input?.name)}
                                    helperText={(touched as any)?.input?.name && (errors as any)?.input?.name}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.preSales.legalAgreements.type")}*</InputLabel>
                                <Autocomplete
                                    id="type"
                                    fullWidth
                                    size="small"
                                    options={Object.values(LegalAgreementType).map((type) => ({
                                        name: localizedLegalAgreements[type as keyof typeof LegalAgreementType],
                                        _id: type,
                                    }))}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => <TextField {...params} />}
                                    value={
                                        values.input?.type
                                            ? { name: localizedLegalAgreements[values.input?.type as keyof typeof LegalAgreementType], _id: values.input?.type }
                                            : null
                                    }
                                    onChange={(e, value) => {
                                        setFieldValue("input.type", value?._id);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} >
                                <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.preSales.legalAgreements.content")}*</InputLabel>
                                <TextField
                                    fullWidth
                                    size="small"
                                    required
                                    name="input.content"
                                    value={(values as any).input.content}
                                    onChange={(e) => setFieldValue("input.content", e.target.value)}
                                    onBlur={handleBlur}
                                    error={!!((errors as any)?.input?.content && (touched as any)?.input?.content)}
                                    helperText={(touched as any)?.input?.content && (errors as any)?.input?.content}
                                />
                            </Grid>
                            <Grid item xs={12} >
                                <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.preSales.legalAgreements.description")}*</InputLabel>
                                <TextField
                                    fullWidth
                                    size="small"
                                    required
                                    name="input.description"
                                    value={(values as any).input.description}
                                    onChange={(e) => setFieldValue("input.description", e.target.value)}
                                    onBlur={handleBlur}
                                    error={!!((errors as any)?.input?.description && (touched as any)?.input?.description)}
                                    helperText={(touched as any)?.input?.description && (errors as any)?.input?.description}
                                />
                            </Grid>
                        </Grid>
                    </Stack>
                    <Grid container spacing={5} pt={1.5}>
                        <Grid item xs={12} >
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={() => handleSubmit()}
                                disabled={loading || !isValid || legalAgreementCreateLoading || !(values as any).input?.name}
                            >
                                {loading ? <CircularProgress size="1rem" /> : t("crm.preSales.legalAgreements.create")}
                            </Button>
                        </Grid>
                    </Grid>
                </Stack>
            </Grid>
        </SwipeableDrawer>
    );
};


export const colors = [
    "primary.lighter",
    "secondary.lighter",
    "success.lighter",
    "error.lighter",
    "warning.lighter",
    "info.lighter",
    "primary.light",
    "secondary.light",
    "success.light",
    "error.light",
    "warning.light",
    "info.light",
    "primary.main",
    "secondary.main",
    "success.main",
    "error.main",
    "warning.main",
    "info.main",
    "primary.dark",
    "secondary.dark",
    "success.dark",
    "error.dark",
    "warning.dark",
    "info.dark",
    "primary.darker",
    "secondary.darker",
    "success.darker",
    "error.darker",
    "warning.darker",
    "info.darker",
];
