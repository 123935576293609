/* eslint-disable react-hooks/exhaustive-deps */
import { Autocomplete,Grid, InputLabel, TextField } from "@mui/material";
import { useFormik } from "formik";
import { memo, useEffect, useState } from "react";
import { getCurrentLanguage } from "localization";
import { IGraphqlVariables, unknownError } from "corede-common";
import { IDepartmentCreateInput } from "corede-common-cocrm";
import { validateCreateDepartmentInput } from "../validations/create.validation";
import { RequestTransformerHelper } from "validations/request.transformer.helper";
import { useRoleListQuery } from "../../permissions";
import { enqueueSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useDepartmentCreateMutation } from "../context/departments.api";
import ActionDialog from "components/dialog/ActionDialog";

interface IDepartmentCreateDialog {
  open: boolean
  setOpen: (open: boolean) => void
  setOpenRoles: (open: boolean) => void
}

export const DepartmentCreateDialog = memo((props: IDepartmentCreateDialog) => {
  // general
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();
  const [loading, setLoading] = useState(false);

  // queries
  const { data: roleListData, isLoading: roleListLoading, error: roleListError } = useRoleListQuery({});
  const [departmentCreate, { data: departmentCreateData, isLoading: departmentCreateLoading, error: departmentCreateError }] = useDepartmentCreateMutation();
  // mutations

  // constants
  const { values, handleSubmit, handleBlur, setFieldValue, errors, touched, resetForm, isValid } =
    useFormik({
      initialValues: {
        input: {
          name: "",
          roleIds: undefined,
        },
      },
      enableReinitialize: true,
      validationSchema: validateCreateDepartmentInput,
      onSubmit: async (values: IGraphqlVariables<IDepartmentCreateInput>) => {
        setLoading(true);
        const transformedValues =
          RequestTransformerHelper.TransformAllEmptyStringFieldsToUndefined({
            input: values,
          });
        await departmentCreate(transformedValues as IGraphqlVariables<IDepartmentCreateInput>)
        setLoading(false);
      }
    });

  // useEffects.success

  useEffect(() => {
    if (departmentCreateData) {
      enqueueSnackbar(t("crm.organizationalChart.departments.createSuccess"), { variant: 'success' });
      resetForm();
      props.setOpen(false);
    }
  }, [departmentCreateData, resetForm]);

  // useEffects.error

  useEffect(() => {
    if (departmentCreateError) {
      enqueueSnackbar((departmentCreateError as any)?.error?.message[currentLanguage] ?? unknownError.message[currentLanguage], { variant: 'error' });
    }
  }, [departmentCreateError]);

  useEffect(() => {
    if (roleListError) {
      enqueueSnackbar((roleListError as any)?.error?.message[currentLanguage] ?? unknownError.message[currentLanguage], { variant: 'error' });
    }
  }, [roleListError]);


  return <ActionDialog
    open={props.open}
    setOpen={props.setOpen}
    title={t("crm.organizationalChart.departments.createDepartmentTitle")}
    size="small"
    handleClick={handleSubmit}
    width={500}
    disabled={loading || !isValid || departmentCreateLoading || !(values as any).input?.name}
    loading={(loading || departmentCreateLoading)}
    buttonTitle={t("crm.organizationalChart.departments.createDepartmentButton")}
  >
    <Grid container spacing={2} my={2}>
      <Grid item xs={12} >
        <InputLabel htmlFor="input.name" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>
          {t("crm.organizationalChart.departments.departmentNameLabel")} *
        </InputLabel>
        <TextField
          fullWidth
          id="input.name"
          name="input.name"
          size="small"
          value={(values as any).input.name}
          onChange={(e) => setFieldValue("input.name", e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
          onBlur={handleBlur}
          error={!!((errors as any)?.input?.name && (touched as any)?.input?.name)}
          helperText={(touched as any)?.input?.name && (errors as any)?.input?.name}
        />
      </Grid>
      <Grid item xs={12}  >
        <InputLabel htmlFor="roleIds" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>
          {t("crm.organizationalChart.departments.rolesLabel")}
        </InputLabel>
        <Autocomplete
          id="roleIds"
          isOptionEqualToValue={(option, value) => option._id === value._id}
          fullWidth
          size="small"
          getOptionLabel={(option) => option.name}
          options={[...(roleListData?.data || [])]}
          renderOption={(props, option) => (
            <li {...props} className={`${props.className} ${option._id === "add" ? 'highlight' : ''}`}>
              {option.name}
            </li>
          )}
          loading={roleListLoading}
          renderInput={(params) => <TextField {...params} />}
          multiple
          value={
            values.input?.roleIds && values.input.roleIds?.length > 0
              ? roleListData?.data?.filter((role) =>
                (values as any).input.roleIds.includes(role._id)
              )
              : []
          }
          onChange={(e, value) => {
            setFieldValue(
              "input.roleIds",
              value?.map((role) => role._id) || []
            );

          }}
        />
      </Grid>
    </Grid>
  </ActionDialog >
});
