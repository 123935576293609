import { Add, PersonAdd } from "@mui/icons-material"
import { Stack, Typography, Box, AvatarGroup, Avatar, IconButton, Button, Theme, useMediaQuery, Tooltip, Fade, Grow } from "@mui/material"
import { useTranslation } from "react-i18next"


type Props = {
    userListData: any
    setOpenCreateUserDrawer: any
    setSelectedUser: any
    setOpenUserDetail: any
}

export const OrganizationalChartHeader = (props: Props) => {

    const { t } = useTranslation();
    const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    return <Stack direction={"row"} justifyContent="space-between" alignItems={"center"}>
        <Stack
            direction={{ xs: "column", md: "row" }}
            gap={2}
            alignItems="center"
        >
            <Grow in={true} timeout={500} >
                <Stack direction={"column"} alignItems={{ xs: "center", md: "flex-start" }}>
                    <Typography sx={{ fontSize: { xs: "20px", md: "24px" }, fontWeight: "bold" }}>
                        {t("crm.organizationalChart.users.organizationChart")}
                    </Typography>
                    <Typography sx={{ fontSize: { xs: "12px", md: "14px" }, fontWeight: "medium" }}>
                        {props.userListData?.count} {(props.userListData as any)?.count > 1 ? t("crm.organizationalChart.users.users") : t("crm.organizationalChart.users.user")}
                        {/* User Users */}
                    </Typography>
                </Stack>
            </Grow>
            <Box display="flex" alignItems="center">
                <Grow in={true} timeout={500} >
                    <AvatarGroup
                        max={5}
                        sx={{
                            "& .MuiAvatar-root": {
                                border: "2px solid white",
                                width: 35,
                                height: 35,
                                fontSize: "14px",
                                backgroundColor: "background.main",

                            },
                            "& .MuiAvatarGroup-avatar:nth-of-type(1)": {
                                zIndex: 4,
                            },
                            "& .MuiAvatarGroup-avatar:nth-of-type(2)": {
                                zIndex: 3,
                            },
                            "& .MuiAvatarGroup-avatar:nth-of-type(3)": {
                                zIndex: 2,
                            },
                            "& .MuiAvatarGroup-avatar:nth-of-type(4)": {
                                zIndex: 1,
                            },
                        }}
                    >
                        {props.userListData?.data?.map((user: any, index: number) => (
                            <Tooltip title={user?.name + " " + user?.surname} key={index}
                                sx={{ cursor: "pointer" }}
                                onClick={() => {
                                    props.setSelectedUser(user)
                                    props.setOpenUserDetail(true)
                                }}
                            >
                                <Avatar alt={user?.name} src={user?.profileImage?.thumbnailPublicUrl} />
                            </Tooltip>
                        ))}
                    </AvatarGroup>
                </Grow>
                <Grow in={true} timeout={500}>
                    <Tooltip title={t("crm.organizationalChart.users.addUser")}>
                        <IconButton
                            sx={{ border: "2px solid", borderColor: "primary.light", width: 35, height: 35, ml: 1, color: "primary.main" }}
                            onClick={() => props.setOpenCreateUserDrawer(true)}
                        >
                            <Add />
                        </IconButton>
                    </Tooltip>
                </Grow>
            </Box>
        </Stack>
    </Stack >
}
