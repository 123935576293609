const locale = {
  expenses: "Expenses",
  expense: "Expense",
  expenseConversions: "Expense Conversions",
  title: "Expenses",
  addExpense: "Add Expense",
  emptyStateDesc1: "No expenses added yet.",
  emptyStateDesc2: "Store and manage all expense details here to streamline your invoicing and maintain clear agreements with your customers."
};

export default locale;



