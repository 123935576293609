import { Route, Routes } from "react-router-dom";

import List from "../pages/List";

import { LeadSourcesLayout } from "../layouts/LeadSourcesLayout";

export const LeadSourcesRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<LeadSourcesLayout />}>
        <Route index element={<List />} />
      </Route>
    </Routes>
  );
};
