

import { PricingDomainLayout } from "../layouts";
import { PricingRouter } from "../subdomains/pricing/routes/PricingRouter";
import { pricingDomainRoutes } from "./pricing.base.route";
import { Route, Routes } from "react-router-dom";


export const PricingDomainRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<PricingDomainLayout />}>
        <Route index element={<PricingRouter />} />
        <Route path="*" element={<PricingRouter />} />
      </Route>
    </Routes>
  );
};